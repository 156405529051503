import React from 'react';
import { ssabGray } from '../../utils/constants/colors';
import { Route } from '../../types';
import { Start, TermsOfUseText } from '../../view/Start';
import { Sliding } from '../../view/sliding/Sliding';
import { Impact } from '../../view/impact/Impact';
import { Erosion } from '../../view/erosion/Erosion';
import styled from 'styled-components';
import { Tipping } from '../../view/tipping/Tipping';
import { Mining } from '../../view/mining/Mining';
import { ApplicationStateSubscribe, ApplicationStateUnsubscribe } from '../../state/applicationState';
import { FlexColumn } from './Column';
import { smallSizeMaxWidth } from '../../utils/constants/layout';
import { WrappedComponentProps, injectIntl } from 'react-intl';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-sizing: border-box;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
`;

export const CalculationPageWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: fit-content;

  @media (max-width: ${smallSizeMaxWidth}) {
    flex-basis: 100vh;
    flex-direction: column;
    flex-shrink: 1;
  }

  & > div {
    flex-shrink: 1;
  }
`;

export const PropertiesColumn = styled(FlexColumn)`
  width: 355px;
  min-width: 355px;
  min-height: fit-content;

  @media (max-width: ${smallSizeMaxWidth}) {
    width: unset;
  }
`;

const renderChildRoute = (currentRoute: Route) => {
  switch (currentRoute) {
    case 'start':
      return <Start />;
    case 'sliding':
      return <Sliding />;
    case 'impact':
      return <Impact />;
    case 'erosion':
      return <Erosion />;
    case 'tipping':
      return <Tipping />;
    case 'mining':
      return <Mining />;
    default:
      return null;
  }
};

type State = {
  height: number;
  currentRoute: Route;
  hidden: boolean;
};

export const Container = injectIntl(
  class Container extends React.Component<
    WrappedComponentProps & { currentRoute: Route; subscribe: ApplicationStateSubscribe },
    State
  > {
    container: HTMLElement | null = null;
    state: State = {
      height: 0,
      currentRoute: this.props.currentRoute,
      hidden: true,
    };
    unsubscribe: undefined | ApplicationStateUnsubscribe;

    measureHeight = (forceRender?: unknown) => {
      if (typeof forceRender !== 'boolean') forceRender = false;
      const container = this.container;
      if (container) {
        const height = container.getBoundingClientRect().height;
        if (height !== this.state.height) {
          this.setState({ height });
        } else if (forceRender && this.state.hidden) {
          this.setState({ hidden: false });
        }
      }
    };

    componentDidMount() {
      window.addEventListener('resize', this.measureHeight);
      let oldChart: string = '';
      this.unsubscribe = this.props.subscribe(({ state: { selectedChart } }) => {
        if (selectedChart !== oldChart) {
          oldChart = selectedChart;
          this.measureHeight();
        }
      });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.measureHeight);
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }

    componentDidUpdate(prevProps: this['props']) {
      if (this.props.currentRoute !== prevProps.currentRoute) {
        if (prevProps.currentRoute === 'start') {
          this.setState({ hidden: true });
        } else {
          this.setState({ currentRoute: this.props.currentRoute }, () => {
            this.measureHeight();
          });
        }
      }
    }

    render() {
      const { height, currentRoute, hidden } = this.state;
      const { intl } = this.props;

      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexShrink: 0,
              position: 'relative',
              width: 1346,
              maxWidth: '100%',
            }}
            ref={e => {
              this.container = e;
              this.measureHeight();
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 1,
                zIndex: -1,
                backgroundColor: `${currentRoute === 'start' ? '#FFF' : ssabGray}`,
                transform: `scaleY(${height})`,
                transformOrigin: 'center top',
                transition: `transform 250ms`,
              }}
              onTransitionEnd={() => {
                if (this.state.hidden) this.setState({ hidden: false });
              }}
            />
            <PageWrapper
              style={{
                opacity: hidden ? 0 : 1,
                transition: `opacity ${hidden ? 1 : 200}ms ease-out`,
              }}
              onTransitionEnd={() => {
                if (this.state.currentRoute !== this.props.currentRoute)
                  this.setState({ currentRoute: this.props.currentRoute }, () => {
                    this.measureHeight(true);
                  });
              }}
            >
              {renderChildRoute(currentRoute)}
            </PageWrapper>
          </div>
          <TermsOfUseText
            text={intl.formatMessage({ id: 'contentIsSubject' })}
            linkText={intl.formatMessage({ id: 'readFullDisclosure' })}
          />
        </>
      );
    }
  },
);
