import { Component, createRef } from 'react';
import styled from 'styled-components';
import { fontWeightRegular } from '../../utils/constants/typography';
import { neutralLighter, grayHardox80, ssabWhite } from '../../utils/constants/colors';
import { Row } from '../containers/Row';
import { Column } from '../containers/Column';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { themeHardox } from '../../utils/themes/themes';

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 24px;
  font-weight: ${fontWeightRegular};
  color: ${ssabWhite};
  background-color: ${(props: { color?: string }) => (props.color ? props.color : grayHardox80)};
`;

export const ModalBodyContainer = styled.div`
  padding: 20px;
`;

export const ModalFooter = styled.div`
  padding: 15px 10px 15px 10px;
  text-align: right;
  background-color: ${neutralLighter};
`;

export const ModalFooterRow = ({
  disableUse,
  cancelButtonText,
  submitButtonText,
  closeModal,
  onSubmit,
}: {
  disableUse: boolean;
  cancelButtonText: string;
  submitButtonText: string;
  closeModal: () => void;
  onSubmit: () => void;
}) => {
  const transitionDuration = disableUse ? 100 : 0;
  return (
    <Row>
      <Column $size={12}>
        <ModalFooter>
          <DefaultButton
            text={cancelButtonText}
            onClick={closeModal}
            style={{ marginRight: 10 }}
            styles={{
              label: {
                paddingTop: 2,
              },
            }}
          />
          <PrimaryButton
            disabled={disableUse}
            text={submitButtonText}
            onClick={() => {
              onSubmit();
              closeModal();
            }}
            iconProps={{ iconName: 'PageRight' }}
            theme={themeHardox}
            styles={{
              root: {
                transition: `background-color ${transitionDuration}ms ease-out, color ${transitionDuration}ms ease-out`,
              },
              flexContainer: {
                flexDirection: 'row-reverse',
              },
              label: {
                paddingTop: 2,
              },
            }}
          />
        </ModalFooter>
      </Column>
    </Row>
  );
};

// Modal controller

export type ModalControllerRenderProps = {
  showModal: boolean;
  openModal: () => void;
  closeModal: () => void;
};

type ModalControllerProps = {
  onEnterPressed?: (_props: ModalControllerRenderProps) => void;
  children: (_args: ModalControllerRenderProps) => JSX.Element;
};

type ModalControllerState = {
  showModal: boolean;
};

export class ModalController extends Component<ModalControllerProps, ModalControllerState> {
  inputRef = createRef<this>();

  constructor(props: ModalControllerProps) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  // Lifecycles

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(_: ModalControllerProps, prevState: ModalControllerState) {
    if (prevState.showModal === false && this.state.showModal === true) {
      // (findDOMNode(this) as HTMLElement).focus();
      //TODO investigate findDoomNode strict error
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  // Methods

  handleKeyDown = (event: KeyboardEvent) => {
    if (this.props.onEnterPressed !== undefined && this.state.showModal && event.key === 'Enter') {
      this.props.onEnterPressed(this.getRenderProps());
    }
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    if (this.state.showModal) {
      this.setState({ showModal: false });
    }
  };

  getRenderProps = () => {
    const { showModal } = this.state;

    return {
      showModal,
      openModal: this.openModal,
      closeModal: this.closeModal,
    };
  };

  render() {
    const { children } = this.props;
    return children(this.getRenderProps());
  }
}
