export type TimeUnit = {
  id: string;
  name: string;
};

export const timeUnits = [
  { id: 'hours', name: 'Hours' },
  { id: 'days', name: 'Days' },
  { id: 'months', name: 'Months' },
  { id: 'years', name: 'Years' },
];
