import { PrimaryButton } from '@fluentui/react';
import { themeHardox } from '../../utils/themes/themes';

export const ToggleSteelsButton = ({
  onClick,
  style,
  steelsTranslation,
}: {
  onClick?: () => void;
  color?: string;
  colorHover?: string;
  style?: React.CSSProperties;
  steelsTranslation: string;
}) => {
  return (
    <PrimaryButton
      text={steelsTranslation}
      style={style}
      ariaLabel="Info"
      className={'material-icon-large'}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
      tabIndex={-1}
      theme={themeHardox}
    />
  );
};
