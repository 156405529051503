import { Column } from '../containers/Column';
import { Row } from '../containers/Row';
import { CardHeader } from '../card/CardHeader';
import { FormattedMessage } from 'react-intl';

type ChartCardHeaderProps = {
  messageId: string;
  titleSuffix?: string;
  rightSideComponent?: React.JSX.Element | null | string;
  chartHeaderDescription?: string;
};

export const ChartCardHeader = ({ messageId, titleSuffix, rightSideComponent = null }: ChartCardHeaderProps) => {
  return (
    <Row>
      <Column $size={8}>
        <CardHeader style={{ fontSize: '14px' }}>
          <FormattedMessage id={messageId} />
          {titleSuffix !== undefined ? (
            <span style={{ whiteSpace: 'pre', textTransform: 'none' }}>{' ' + titleSuffix}</span>
          ) : (
            ''
          )}
        </CardHeader>
      </Column>
      <Column $size={4}>{rightSideComponent}</Column>
    </Row>
  );
};
