import { Component } from 'react';
import styled from 'styled-components';
import { Overlay, IconButton, Callout, DirectionalHint, BaseButton } from '@fluentui/react'; //TODO change button to base button
import { redHardox, ssabDarkGray, ssabPrimary } from '../../../utils/constants/colors';
import { SettingsCalloutContent } from './SettingsCalloutContent';
import { ApplicationStateConsumer } from '../../../state/applicationState';
import { ClearStateCalloutContent } from './ClearStateCalloutContent';
import { WrappedComponentProps, injectIntl } from 'react-intl';

type SettingsButtonProps = {
  showCallout: boolean;
  onClick: () => void;
};

const SettingsButton = ({ showCallout, onClick }: SettingsButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={false}
      checked={false}
      iconProps={{ iconName: 'Settings' }}
      title="Settings"
      ariaLabel="Settings"
      className={'material-icon-large'}
      styles={{
        root: {
          width: 48,
          height: 48,
          cursor: 'pointer',
        },
        icon: {
          color: ssabDarkGray,
          transform: `rotate(${showCallout ? 33 : 0}deg)`,
          fontSize: 22,
          transition: 'color 200ms linear 0s, transform 200ms linear 0s',
          height: 22,
        },
        iconHovered: {
          color: redHardox,
        },
        rootHovered: {
          backgroundColor: 'transparent',
        },
        rootPressed: {
          backgroundColor: 'transparent',
        },
        rootFocused: {
          backgroundColor: 'transparent',
        },
      }}
    />
  );
};

const buttonStyles = {
  fontWeight: 400,
  fontSize: 14,
  padding: '0 16px',
  minWidth: 80,
  height: 32,
  cursor: 'pointer',
  marginLeft: 20,
};

const techSupportButtonStyles = {
  ...buttonStyles,
  backgroundColor: ssabPrimary,
  color: '#fff',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
};
const clearButtonStyles = {
  ...buttonStyles,
  ...{ backgroundColor: 'rgb(244, 244, 244)', color: 'rgb(51, 51, 51)', border: '1px solid transparent' },
};

const StyledOverlay = styled(Overlay)`
  height: 100vh;
  z-index: 20;
`;

const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Settings = injectIntl(
  class Settings extends Component<WrappedComponentProps, { showCallout: boolean; showClearState: boolean }> {
    // Init

    buttonWrapper: HTMLDivElement | null = null;

    constructor(props: WrappedComponentProps) {
      super(props);

      this.state = {
        showCallout: false,
        showClearState: false,
      };
    }

    // Methods

    toggleClearState = () => {
      this.setState({
        ...this.state,
        showClearState: !this.state.showClearState,
      });
    };

    closeClearState = () => {
      this.setState({ ...this.state, showClearState: false });
    };

    openCallout = () => {
      this.setState({ ...this.state, showCallout: true });
    };

    closeCallout = () => {
      this.setState({ ...this.state, showCallout: false });
    };

    // Render

    render() {
      const { showCallout, showClearState } = this.state;
      const isElectron = import.meta.env.VITE_REACT_APP_IS_ELECTRON === 'true';
      const { intl } = this.props;

      return (
        <ApplicationStateConsumer>
          {({ clearState }) => (
            <SettingsWrapper>
              {!isElectron && (
                <BaseButton
                  title={intl.formatMessage({ id: 'needHelp' })}
                  style={techSupportButtonStyles}
                  href="https://www.ssab.com/en/contact/tech-support?utm_source=applications&utm_medium=referral&utm_campaign=wear-calc-tech-form&utm_content=lead-to-tech-contact-form_hl_account_"
                  target="_blank"
                >
                  {intl.formatMessage({ id: 'techSupport' })}
                </BaseButton>
              )}

              <BaseButton style={clearButtonStyles} onClick={() => this.toggleClearState()}>
                {intl.formatMessage({ id: 'clear' })}
              </BaseButton>
              <div ref={buttonWrapper => (this.buttonWrapper = buttonWrapper)}>
                <SettingsButton showCallout={showCallout} onClick={this.openCallout} />
              </div>
              {showClearState ? (
                <StyledOverlay onClick={this.closeCallout}>
                  <Callout
                    gapSpace={5}
                    target={this.buttonWrapper}
                    isBeakVisible={false}
                    directionalHint={DirectionalHint.bottomRightEdge}
                  >
                    <ClearStateCalloutContent clearState={clearState} closeClearState={this.closeClearState} />
                  </Callout>
                </StyledOverlay>
              ) : null}
              {showCallout ? (
                <StyledOverlay onClick={this.closeCallout}>
                  <Callout
                    gapSpace={5}
                    target={this.buttonWrapper}
                    isBeakVisible={false}
                    directionalHint={DirectionalHint.bottomRightEdge}
                  >
                    <SettingsCalloutContent />
                  </Callout>
                </StyledOverlay>
              ) : null}
            </SettingsWrapper>
          )}
        </ApplicationStateConsumer>
      );
    }
  },
);
