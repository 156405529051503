import { mergeDeepRight } from 'ramda';
import {
  DeepPartial,
  TipperType,
  CurrentMiningSteelState,
  UpgradeMiningSteelState,
  Abrasive,
  UnitSystemOption,
  Steel,
} from '../types';
import { TimeUnit } from './timeUnits';
import { ApplicationState } from './applicationState';

import {
  validateCurrentMiningInputs,
  validateUpgradeMiningInputs,
} from '../utils/validation/validateMiningCalculations';
import { calculateNumberOfTippingsUntilWornOut, convertHoursToSelectedTimeUnit } from '../miningAndTippingCalculations';
import { roundTo } from '../utils/numberFormatting';
import { getSlidingServiceLifeDataPoints } from '../view/sliding/Sliding';

export type MiningPropertiesState = {
  currentMiningSteelsState: CurrentMiningSteelState[];
  upgradeMiningSteelsState: UpgradeMiningSteelState[];

  currentServiceLife: number;
  currentTippings: number;
  upgradeServiceLife: number;
  upgradeTippings: number;

  serviceLifeTimeUnit: TimeUnit;

  wornOutThickness: number;

  unloadsPerHour: number;
  tipperType: TipperType;
  rockSizeId: number;
  tipperLength: number;
  heatMapTippings: number;
};

export type MiningProperty = DeepPartial<MiningPropertiesState>;

export const createNextMiningPropertiesState = (
  property: MiningProperty,
  currentState: MiningPropertiesState,
  applicationState: ApplicationState,
): MiningPropertiesState => {
  const updatedState = mergeDeepRight(currentState, property);

  if (!validateCurrentMiningInputs(updatedState as MiningPropertiesState, applicationState.selectedUnitSystem)) {
    return updatedState as MiningPropertiesState;
  }

  if (
    updatedState.upgradeMiningSteelsState.every(s => s.steel) &&
    !validateUpgradeMiningInputs(updatedState as MiningPropertiesState, applicationState.selectedUnitSystem)
  ) {
    return updatedState as MiningPropertiesState;
  }

  const miningSteels = applicationState.steels.filter(steel => steel.includeInMining);

  if (applicationState.selectedMiningAbrasive !== undefined) {
    const { serviceLife: currentServiceLife, tippings: currentTippings } = calculateMiningSteelStateLowestServiceLife(
      applicationState.selectedMiningAbrasive,
      updatedState.currentMiningSteelsState as CurrentMiningSteelState[],
      updatedState as MiningPropertiesState,
      applicationState.selectedUnitSystem,
      miningSteels,
    );

    updatedState.currentServiceLife = currentServiceLife;
    updatedState.currentTippings = currentTippings;

    const { serviceLife: upgradeServiceLife, tippings: upgradeTippings } = calculateMiningSteelStateLowestServiceLife(
      applicationState.selectedMiningAbrasive,
      updatedState.upgradeMiningSteelsState as CurrentMiningSteelState[],
      updatedState as MiningPropertiesState,
      applicationState.selectedUnitSystem,
      miningSteels,
    );

    updatedState.upgradeServiceLife = upgradeServiceLife;
    updatedState.upgradeTippings = upgradeTippings;

    const maxTippings = Math.max(upgradeTippings, currentTippings);
    if (updatedState.heatMapTippings > maxTippings) {
      updatedState.heatMapTippings = maxTippings;
    }
  } else {
    updatedState.currentServiceLife = 0;
    updatedState.currentTippings = 0;
    updatedState.upgradeServiceLife = 0;
    updatedState.upgradeTippings = 0;
  }

  return updatedState as MiningPropertiesState;
};

const calculateMiningSteelStateLowestServiceLife = (
  selectedMiningAbrasive: Abrasive,
  miningSteelState: Array<CurrentMiningSteelState | UpgradeMiningSteelState>,
  miningProperties: MiningPropertiesState,
  selectedUnitSystem: UnitSystemOption,
  miningSteels: Steel[],
) => {
  const { data: slidingData, reference: slidingRef } = getSlidingServiceLifeDataPoints(
    miningSteels,
    selectedMiningAbrasive,
    miningSteels[1], // Hardox 450
  );

  /* Normalize sliding data to Hardox 450. */
  const normalizedSlidingData = slidingData.map(d => (slidingRef ? d / slidingRef : d));

  const tippingCounts = miningSteelState.map((s, sectionIndex) => {
    if (s.steel && s.steelThickness) {
      // Caluclate number of tippings for hardox 450
      const hardox450Tippings = calculateNumberOfTippingsUntilWornOut(
        selectedMiningAbrasive,
        miningSteels[1],
        s.steelThickness,
        miningProperties.currentMiningSteelsState[sectionIndex].wearProtection,
        miningProperties,
        selectedUnitSystem,
        sectionIndex,
      );

      const indexOfSteel = miningSteels.map(s => s.id).indexOf(s.steel.id);

      return roundTo(hardox450Tippings * normalizedSlidingData[indexOfSteel], 0);
    }

    return 0;
  });

  const lowestTippingCount = Math.min(...tippingCounts);

  const lowestTippingConvertedToHours = convertHoursToSelectedTimeUnit(
    lowestTippingCount / miningProperties.unloadsPerHour,
    miningProperties.serviceLifeTimeUnit,
  );

  return {
    serviceLife: roundTo(lowestTippingConvertedToHours, 1),
    tippings: roundTo(lowestTippingCount, 0),
  };
};
