import { Component } from 'react';
import { Steel } from '../../types';
import { Checkbox } from '../checkbox/Checkbox';
import { Callout, DirectionalHint } from '@fluentui/react';
import styled from 'styled-components';
import { CUSTOM_STEEL_ID } from '../../utils/constants/values';
import { ToggleSteelsButton } from '../buttons/ToggleSteelsButton';

const CalloutBody = styled.div`
  display: flex;
  padding: 20px;
  height: 100%;
  flex-direction: column;
`;
type SteelToggleProps = {
  steelsTranslation: string;
  steels: Array<Steel>;
  setCalculationSteels: (steels: Array<Steel>) => void;
};
export class SteelToggle extends Component<
  SteelToggleProps,
  {
    showCallout: boolean;
  }
> {
  infoButtonWrapper: HTMLDivElement | null = null;

  constructor(props: SteelToggleProps) {
    super(props);
    this.state = {
      showCallout: false,
    };
  }

  render() {
    const { showCallout } = this.state;

    const { steelsTranslation, steels, setCalculationSteels } = this.props;

    return (
      <>
        <div
          ref={element => {
            this.infoButtonWrapper = element;
          }}
        >
          <ToggleSteelsButton
            steelsTranslation={steelsTranslation}
            onClick={() => {
              this.setState({ showCallout: !showCallout });
            }}
          />
        </div>
        {showCallout && (
          <Callout
            gapSpace={5}
            target={this.infoButtonWrapper}
            isBeakVisible={false}
            directionalHint={DirectionalHint.bottomRightEdge}
            onDismiss={() => {
              this.setState({ showCallout: false });
            }}
          >
            <CalloutBody>
              <p
                style={{
                  fontSize: '21px',
                  marginTop: '0',
                  marginBottom: '5px',
                }}
              >
                {steelsTranslation}
              </p>
              {steels
                .filter(s => s.id !== CUSTOM_STEEL_ID)
                .map((s, index) => {
                  return (
                    <div key={index} style={{ flex: '1 1 auto' }}>
                      <Checkbox
                        label={s.name}
                        checked={!s.hiddenInCharts}
                        disabled={false}
                        onClick={() => {
                          const updatedSteels = steels.map(steel => {
                            if (steel.id === s.id) {
                              return {
                                ...steel,
                                hiddenInCharts: !steel.hiddenInCharts,
                              };
                            } else {
                              return steel;
                            }
                          });

                          setCalculationSteels(updatedSteels);
                        }}
                      />
                    </div>
                  );
                })}
            </CalloutBody>
          </Callout>
        )}
      </>
    );
  }
}
