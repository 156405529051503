import React, { useState } from 'react';
import styled from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { redHardox, grayHardox100, ssabPrimary } from '../utils/constants/colors';
import { RouteButton } from '../components/buttons/RouteButton';
import sliding from '../images/routeImages/sliding.svg';
import impact from '../images/routeImages/impact.svg';
import erosion from '../images/routeImages/erosion.svg';
import tippers from '../images/routeImages/tippers.svg';
import mining from '../images/routeImages/mining.svg';
import { APP_VERSION } from './WearCalc';
import startPageTruckA from '../images/startPageTruckA.jpg';
import startPageTruckB from '../images/startPageTruckB.jpg';
import { TermsOfUseModal } from '../components/modal/TermsOfUseModal';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Button = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  height: 150px;
  z-index: 10;

  margin: 20px;

  color: ${redHardox};
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 900px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StartPageLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  background-color: white;
  padding-bottom: 20px;
`;

const StartPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  flex-direction: column;
  background-color: white;
  margin-top: 10px;
`;

const StartPageLinksHeader = styled.div`
  text-align: center;
`;

const StartPageLinksHeaderText = styled.div`
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: ${grayHardox100};
  font-weight: bold;
  letter-spacing: 0.14px;
`;

const StartPageTextWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StartPageText = styled.p`
  font-size: 16px;
  color: ${redHardox};
  max-width: 940px;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 0.14px;
  line-height: 1.4;
  font-family: 'Roboto', 'Calibri', sans-serif !important;
`;

const StartPageImage = styled.div<{ $backgroundImage: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${props => props.$backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
`;

const StartPageImageTitle = styled.div`
  color: #fff;
  font-size: 20px;
  margin-top: 64px;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0px;
  opacity: 1;
`;

const StartPageImageText = styled.div`
  color: #fff;
  font-size: 50px;
  max-width: 780px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.2;
  font-family: 'Roboto', 'Calibri', sans-serif !important;
`;
const StartPageImageTextWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StartPageTermsOfUseWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
`;

const StartPageTermsOfUseText = styled.p`
  font-size: 13px;
  max-width: 940px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 0.14px;
  line-height: 1.4;
  font-family: 'Roboto', 'Calibri', sans-serif !important;
  margin: 0 0 0 0;
`;

const StartPageTermsOfUseLink = styled.a`
  margin-left: 3px;
  font-family: 'Roboto', 'Calibri', sans-serif !important;
  color: ${ssabPrimary};
`;

export const TermsOfUseText = ({ text, linkText }: { text: string; linkText: string }) => {
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);

  return (
    <StartPageTermsOfUseText>
      {text}
      <StartPageTermsOfUseLink
        onClick={e => {
          e.preventDefault();
          setShowTermsOfUseModal(true);
        }}
        href="#"
      >
        {linkText}
      </StartPageTermsOfUseLink>
      {showTermsOfUseModal && <TermsOfUseModal closeModal={() => setShowTermsOfUseModal(false)} />}
    </StartPageTermsOfUseText>
  );
};

export const Start = injectIntl(
  class StartClass extends React.Component<
    WrappedComponentProps,
    { backgroundImage: string; showTermsOfUseModal: boolean }
  > {
    constructor(props: WrappedComponentProps) {
      super(props);

      this.state = {
        backgroundImage: Math.random() < 0.5 ? startPageTruckA : startPageTruckB,
        showTermsOfUseModal: false,
      };
    }

    openTermsOfUseModal = () => {
      this.setState({ ...this.state, showTermsOfUseModal: true });
    };

    closeTermsOfUseModal = () => {
      this.setState({ ...this.state, showTermsOfUseModal: false });
    };

    render() {
      const { intl } = this.props;
      const { backgroundImage } = this.state;
      const isElectron = import.meta.env.VITE_REACT_APP_IS_ELECTRON === 'true';

      return (
        <Column>
          <StartPageImage $backgroundImage={backgroundImage}>
            <StartPageImageTextWrapper>
              <StartPageImageTitle>Hardox® WearCalc {APP_VERSION}</StartPageImageTitle>
              <StartPageImageText>{intl.formatMessage({ id: 'startPageImageText' })}</StartPageImageText>
            </StartPageImageTextWrapper>
          </StartPageImage>
          <StartPageTextWrapper>
            <StartPageText>
              {intl.formatMessage({ id: 'startPageDescriptionText' }, { version: APP_VERSION })}
            </StartPageText>
          </StartPageTextWrapper>
          <StartPageWrapper>
            <StartPageLinksHeader>
              <StartPageLinksHeaderText>{intl.formatMessage({ id: 'chooseCalculationType' })}</StartPageLinksHeaderText>
            </StartPageLinksHeader>
            <StartPageLinksWrapper>
              <RouteButton route="sliding" image={sliding} style={{ marginLeft: 20, marginRight: 20 }} />
              <RouteButton route="impact" image={impact} style={{ marginRight: 20 }} />
              <RouteButton route="erosion" image={erosion} style={{ marginRight: 20 }} />
              <RouteButton route="tipping" image={tippers} style={{ marginRight: 20 }} />
              <RouteButton route="mining" image={mining} style={{ marginRight: 20 }} />
            </StartPageLinksWrapper>
          </StartPageWrapper>
          <StartPageTermsOfUseWrapper>
            {!isElectron && (
              <StartPageTermsOfUseText>
                {intl.formatMessage({ id: 'needHelp' })}
                <StartPageTermsOfUseLink
                  href="https://www.ssab.com/en/contact/tech-support?utm_source=applications&utm_medium=referral&utm_campaign=wear-calc-tech-form&utm_content=lead-to-tech-contact-form_hl_account_"
                  target="_blank"
                >
                  {intl.formatMessage({ id: 'contactTechSupport' })}
                </StartPageTermsOfUseLink>
              </StartPageTermsOfUseText>
            )}
          </StartPageTermsOfUseWrapper>
        </Column>
      );
    }
  },
);
