import { Steel } from '../types';
import { roundTo } from './numberFormatting';
import { CalculationModelState } from '../state/calculationModels';
import { ApplicationState } from '../state/applicationState';
import { IntlShape } from 'react-intl';
import { CUSTOM_STEEL_ID } from './constants/values';
import { isDuroxite } from './duroxite';

export const getHardox450ReferenceSteel = () => ({
  id: 5,
  name: 'Reference 450',
  hardness: 450,
  hardnessHV: 477,
});

export function constructSteelArray(steels: Steel[], currentSteel: Steel) {
  if (steels.some(steel => steel.id === currentSteel.id)) return steels;
  return [...steels, currentSteel].sort((a, b) => a.hardnessHV - b.hardnessHV);
}

export const getHarderSteels = (steels: Steel[], currentSteel: Steel) => {
  return steels.filter(steel => steel.hardness >= currentSteel.hardness);
};

export const getTranslatedSteels = (intl: IntlShape, steels: Array<Steel>) => {
  return steels.map(s => {
    return {
      ...s,
      name:
        s.id === CUSTOM_STEEL_ID
          ? intl.formatMessage({ id: 'customSteel' })
          : s.id === 1
            ? intl.formatMessage({ id: 'mildSteel' })
            : s.name,
    };
  });
};

export const translateSteel = (intl: IntlShape, steel: Steel) => {
  return {
    ...steel,
    name:
      steel.id === CUSTOM_STEEL_ID
        ? intl.formatMessage({ id: 'customSteel' })
        : steel.id === 1
          ? intl.formatMessage({ id: 'mildSteel' })
          : steel.name,
  };
};

export const thicknessFromServiceLife =
  <T extends CalculationModelState>(
    getWearRateForSteel: (steel: Steel, calculationModelState: T, applicationState: ApplicationState) => null | number,
  ) =>
  (steel: Steel | undefined, serviceLife: number, calculationModelState: T, applicationState: ApplicationState) => {
    if (!steel) return null;

    const currentUsableThickness = Math.max(
      0,
      calculationModelState.currentSteelState.currentThickness -
        calculationModelState.currentSteelState.wornOutThickness,
    );

    // TODO: Handle duroxite!

    const usableThicknessPerServiceLifeForCurrentSteel =
      currentUsableThickness / calculationModelState.currentSteelState.currentServiceLife;
    const wearRateForCurrentSteel = getWearRateForSteel(
      calculationModelState.currentSteelState.currentSteel,
      calculationModelState,
      applicationState,
    );
    const wearRateForUpgradeSteel = getWearRateForSteel(steel, calculationModelState, applicationState);
    if (wearRateForCurrentSteel === null || wearRateForUpgradeSteel === null) return null;

    const wearRateRatio = wearRateForCurrentSteel / wearRateForUpgradeSteel;
    const usableThicknessPerServiceLifeForUpgradeSteel = usableThicknessPerServiceLifeForCurrentSteel * wearRateRatio;
    const thicknessForUpgradeSteel =
      serviceLife * usableThicknessPerServiceLifeForUpgradeSteel +
      calculationModelState.currentSteelState.wornOutThickness;

    return roundTo(thicknessForUpgradeSteel, 1);
  };

export const serviceLifeFromThickness =
  <T extends CalculationModelState>(
    getWearRateForSteel: (steel: Steel, calculationModelState: T, applicationState: ApplicationState) => null | number,
  ) =>
  (steel: Steel | undefined, thickness: number, calculationModelState: T, applicationState: ApplicationState) => {
    if (!steel) return null;

    const currentSteelUsableThickness = Math.max(
      0,
      calculationModelState.currentSteelState.currentThickness -
        calculationModelState.currentSteelState.wornOutThickness,
    );

    /* If steel is Duroxite, ignore wornOutThickness and use entire thickness (Overlay thickness). */
    const upgradeSteelUsableThickness = isDuroxite(steel)
      ? Math.max(0, thickness)
      : Math.max(0, thickness - calculationModelState.currentSteelState.wornOutThickness);

    const serviceLifePerUsableThicknessForCurrentSteel =
      calculationModelState.currentSteelState.currentServiceLife / currentSteelUsableThickness;
    const wearRateForCurrentSteel = getWearRateForSteel(
      calculationModelState.currentSteelState.currentSteel,
      calculationModelState,
      applicationState,
    );
    const wearRateForUpgradeSteel = getWearRateForSteel(steel, calculationModelState, applicationState);
    if (wearRateForCurrentSteel === null || wearRateForUpgradeSteel === null) return null;

    const wearRateRatio = wearRateForCurrentSteel / wearRateForUpgradeSteel;
    const serviceLifePerUsableThicknessForUpgradeSteel = serviceLifePerUsableThicknessForCurrentSteel / wearRateRatio;
    const serviceLifeForUpgradeSteel = serviceLifePerUsableThicknessForUpgradeSteel * upgradeSteelUsableThickness;

    return roundTo(serviceLifeForUpgradeSteel, 1);
  };
