import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { ModalFooterRow } from '../../modal/Modal';

const ClearStateText = styled.div`
  padding-top: 20px;
  display: flex;
  font-size: 17px;
  justify-content: center;
`;

export const ClearStateCalloutContent = injectIntl(
  (
    props: WrappedComponentProps & {
      clearState: () => void;
      closeClearState: () => void;
    },
  ) => (
    <>
      <ClearStateText>{props.intl.formatMessage({ id: 'clearAllCalculations' })}</ClearStateText>
      <ModalFooterRow
        disableUse={false}
        cancelButtonText={props.intl.formatMessage({ id: 'cancel' })}
        submitButtonText={props.intl.formatMessage({ id: 'confirm' })}
        closeModal={props.closeClearState}
        onSubmit={() => {
          props.clearState();
        }}
      />
    </>
  ),
);
