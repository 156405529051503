import styled from 'styled-components';
import { smallSizeMaxWidth } from '../../utils/constants/layout';

type ColumnProps = {
  $size: number;
  $sizeSmall?: number;
};

export const Column = styled.div<ColumnProps>`
  flex-basis: ${props => `${(100 * props.$size) / 12}%`};
  max-width: ${props => `${(100 * props.$size) / 12}%`};

  @media (max-width: ${smallSizeMaxWidth}) {
    flex-basis: ${props => (props.$sizeSmall ? `${(100 * props.$sizeSmall) / 12}%` : `${(100 * props.$size) / 12}%`)};
    max-width: ${props => (props.$sizeSmall ? `${(100 * props.$sizeSmall) / 12}%` : `${(100 * props.$size) / 12}%`)};
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
