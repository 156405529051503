// TODO  react-hero-transition

import { CSSProperties } from 'react';
import styled from 'styled-components';
import { ssabGray, white, neutralLighterAlt, grayHardox100, redHardox } from '../../../utils/constants/colors';
import { fontSizeMPlus } from '../../../utils/constants/typography';
import { RouteStateConsumer } from '../../../state/routing/routeState';
import { routes } from '../../../state/routing/routes';
import { NavbarIconButton } from '../Navbar';
// import { smallSizeMaxWidth } from "../../../utils/constants/layout";
import { Route } from '../../../types';
import { FormattedMessage } from 'react-intl';

const LinksRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  height: 64px;
`;

const ButtonBackground = styled.div<{ selected?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => (props.selected ? ssabGray : white)};
  :focus,
  :hover {
    background-color: ${props => (props.selected ? ssabGray : neutralLighterAlt)};
  }
`;

const LinkButton = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  -ms-flex-preferred-size: auto;
  padding-left: 24px;
  padding-right: 24px;
  height: 64px;
  z-index: 100;

  color: ${grayHardox100};
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 900px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const LinkItem = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 60px;
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;

  color: ${redHardox};
  background-color: ${props => (props.selected ? ssabGray : white)};

  font-size: ${fontSizeMPlus};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  user-select: none;
  transition: background-color 100ms ease-in 0s;
  cursor: pointer;

  :hover {
    background-color: ${(props: { selected?: boolean }) => (props.selected ? ssabGray : neutralLighterAlt)};
  }
`;

const ButtonText = styled.div`
  z-index: 1;
  pointer-events: none;
  font-size: ${fontSizeMPlus};
  text-align: center;
`;

const RouteButton = ({ route, style }: { route: Route; style?: CSSProperties }) => (
  <RouteStateConsumer>
    {({ state: { currentRoute }, setRoute }) => (
      <LinkButton
        href="#"
        onClick={e => {
          e.preventDefault();
          setRoute(route);
        }}
        style={style}
      >
        {/* <Hero id={`route-background-${route}`}> */}
        <ButtonBackground selected={currentRoute === route} />
        {/* </Hero> */}
        {/* <Hero id={`route-text-${route}`}> */}
        <ButtonText>
          <FormattedMessage id={route === 'tipping' ? 'tippers' : route} />
        </ButtonText>
        {/* </Hero> */}
      </LinkButton>
    )}
  </RouteStateConsumer>
);

export const Links = () => {
  return (
    <RouteStateConsumer>
      {({ state: { currentRoute }, setRoute }) => {
        return (
          <LinksRow>
            <LinkItem
              onClick={() => setRoute('start')}
              style={{
                zIndex: currentRoute === 'sliding' ? 1 : 101,
              }}
            >
              <NavbarIconButton icon="Home" label="Home" />
            </LinkItem>
            {/* <WithMedia query={`(max-width: ${smallSizeMaxWidth})`}> */}
            {/* {({ matches: isPortraitMode }) =>
                isPortraitMode
                  ? null
                  :
              } */}
            {routes.map(route => (
              <RouteButton route={route} key={route} />
            ))}
            {/* </WithMedia> */}
          </LinksRow>
        );
      }}
    </RouteStateConsumer>
  );
};
