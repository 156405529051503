import {
  uShapedDataMatrix,
  boxShapedDataMatrix,
  boxShapedSideDataMatrix,
  dumperDataMatrix,
  dumperSideDataMatrix,
} from './wearMatrices';

export const getDumperSideWearMatrix = (wearValues: number[], dumperSection: number, side: 'left' | 'right') => {
  const dumperSideMatrixLength = dumperSideDataMatrix.length;

  const dumperSide = dumperSideDataMatrix.slice(0, (dumperSideMatrixLength / 3) * 2);

  const dumperSideLength = dumperSide.length;

  const dumperLengthRatio = Math.floor(dumperSideLength / 3);
  const sectionDataMatrix = dumperSide.slice(
    dumperSection * dumperLengthRatio,
    (dumperSection + 1) * dumperLengthRatio,
  );

  const shortenedSectionData = sectionDataMatrix.map(row => {
    return [...row.slice((row.length / 10) * 6, row.length)];
  });

  if (side === 'left') {
    return mergeMatrices(shortenedSectionData, wearValues);
  } else {
    const leftSideMatrix = shortenedSectionData.map(row => {
      return [...row].reverse();
    });
    return mergeMatrices(leftSideMatrix, wearValues);
  }
};

export const getDumperWearMatrix = (wearValues: number[], dumperSection: number) => {
  const dumperMatrixLength = dumperDataMatrix.length;
  const sectionDataMatrix = dumperDataMatrix.slice(
    dumperSection * Math.floor(dumperMatrixLength / 3),
    (dumperSection + 1) * Math.floor(dumperMatrixLength / 3),
  );

  return mergeMatrices(sectionDataMatrix, wearValues);
};

export const getBoxShapedWearMatrix = (wearValues: number[]) => {
  return mergeMatrices(boxShapedDataMatrix, wearValues);
};

export const getBoxShapedSideWearMatrix = (wearValues: number[], side: 'left' | 'right') => {
  if (side === 'right') {
    const rightSideMatrix = boxShapedSideDataMatrix;
    return mergeMatrices(rightSideMatrix, wearValues);
  } else {
    const leftSideMatrix = boxShapedSideDataMatrix.map(row => {
      return [...row].reverse();
    });
    return mergeMatrices(leftSideMatrix, wearValues);
  }
};

export const getUShapedWearMatrix = (wearValues: number[]) => {
  return mergeMatrices(uShapedDataMatrix, wearValues);
};

const mergeMatrices = (dataMatrix: number[][], wearValues: number[]) => {
  const mergedMatrix = [];

  const stepSize = wearValues.length / dataMatrix.length;

  for (let i = 0; i < dataMatrix.length; i++) {
    const matrixValues = dataMatrix[i];
    const wearValue = wearValues[Math.floor(stepSize * i)];
    const innerArray = [];

    for (let k = 0; k < matrixValues.length; k++) {
      innerArray.push(matrixValues[k] * wearValue);
    }
    mergedMatrix.push(innerArray);
  }
  return mergedMatrix;
};
