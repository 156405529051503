import { IntlShape } from 'react-intl';
import { UnitSystemOption } from '../../types';

export const getLengthUnit = (intl: IntlShape, selectedUnitSystem: UnitSystemOption) => {
  return selectedUnitSystem === 'imperial'
    ? intl.formatMessage({ id: 'inches' })
    : intl.formatMessage({ id: 'millimeters' });
};

export const getLengthUnitShort = (intl: IntlShape, selectedUnitSystem: UnitSystemOption) => {
  return selectedUnitSystem === 'imperial'
    ? intl.formatMessage({ id: 'inchesShort' })
    : intl.formatMessage({ id: 'millimetersShort' });
};
