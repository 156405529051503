export const uShapedDataMatrix = [
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0.20114940305071588, 0.46264375598916113, 0.5488505969492842, 0.6264365925977514,
    0.6896550501122706, 0.7643675381339482, 0.8218389803948879, 0.8678160697173825, 0.899425298474642,
    0.9252875119783222, 0.9396550501122706, 0.9425285577390602, 0.9540225882462188, 0.9655166187533775,
    0.9770119389856827, 0.982758954239262, 0.9885059694928413, 0.9971264923732104, 1, 1,
  ],
  [
    0, 0, 0, 0, 0.040146048173292155, 0.2007298313549834, 0.2773723614151734, 0.361314024048152, 0.4489052529675249,
    0.5364964818868979, 0.613139011947088, 0.6824819999230118, 0.7445258553261473, 0.8083944938724799,
    0.864963795090285, 0.9014600722214443, 0.9270078552491592, 0.9452556866811306, 0.9543796023971165,
    0.9562043855403136, 0.9616793492371211, 0.9708034697088456, 0.9799273854248314, 0.985401734854423,
    0.9890513011408173, 0.9927008674272115, 0.9927008674272115, 0.9981752168568029,
  ],
  [
    0, 0.01656628949182788, 0.08734943423774182, 0.1310239823714699, 0.18975916237444407, 0.2635542983060929,
    0.34638574576523234, 0.43524100661069876, 0.521084614240716, 0.5993977369509673, 0.6701808816968813,
    0.7319278839004475, 0.7831327479687986, 0.8373496032228847, 0.8840364805126334, 0.9186748861040137,
    0.9397595003447297, 0.9548194562734045, 0.9608434386448743, 0.959337443052007, 0.9653615944086197,
    0.9728920793283855, 0.9789160616998553, 0.9834340484784578, 0.9879520352570601, 0.9879520352570601,
    0.9924700220356626, 0.99397601762853,
  ],
  [
    0, 0.02167636524958393, 0.11127161912717942, 0.1618496425620344, 0.2283237968979236, 0.30924837495767954,
    0.3959536738085077, 0.48410386909896574, 0.5693641093626565, 0.6445084530738626, 0.7109824452622444,
    0.7630055272842369, 0.8092483749576795, 0.8554912226311222, 0.8988437909827826, 0.9277454491680537,
    0.9465315350958552, 0.9580920037929544, 0.9638727245840267, 0.9609822831147368, 0.966763003905809,
    0.9739882968414961, 0.9797685311900459, 0.9841037069514582, 0.9884388827128705, 0.9884388827128705,
    0.9898839413000078, 0.9942191170614202,
  ],
  [
    0, 0.02442529847464207, 0.12643675381339484, 0.1767241045760738, 0.2456897385598155, 0.3261494030507159,
    0.410919490197444, 0.4956895773441722, 0.5775859956484674, 0.650862052288037, 0.7097700871467281,
    0.7586205228803691, 0.8045976122028634, 0.850574701525358, 0.8908044531629864, 0.9181032592644182,
    0.9396550501122706, 0.9525858344328239, 0.9597702483623715, 0.9597702483623715, 0.9640805098025559,
    0.9698275250561352, 0.9741377864963197, 0.9784480479365043, 0.9827583093766887, 0.9827583093766887,
    0.9841950631900835, 0.9885053246302681,
  ],
  [
    0, 0.02442529847464207, 0.1307470152535793, 0.18390803485869125, 0.2557471764692226, 0.33908034858691266,
    0.4238505969492841, 0.508620522880369, 0.5905172636159508, 0.6623562440108388, 0.7198275250561353,
    0.7701147146031709, 0.810344788672086, 0.8548849629655424, 0.8922413681920245, 0.922413681920246,
    0.9454020653658498, 0.9597700871467281, 0.9669540174293455, 0.9683907712427404, 0.97126427886953,
    0.9755745403097145, 0.9770112941231094, 0.9784480479365043, 0.9827583093766887, 0.9827583093766887,
    0.9885053246302681, 0.9899422396593062,
  ],
  [
    0, 0.025714271974763246, 0.1314284477728692, 0.18857132781493044, 0.262857216134596, 0.34857129575604473,
    0.43428569596635097, 0.5214286401261837, 0.6014285439495265, 0.6699999358822284, 0.7257142719747632,
    0.7714286401261838, 0.8128572161345959, 0.8528570878990529, 0.8914285760084122, 0.9228571840757103,
    0.9457142078569917, 0.965714304033649, 0.971428479831755, 0.971428479831755, 0.9771426556298609, 0.9771426556298609,
    0.9771426556298609, 0.9785711995793873, 0.9828568314279669, 0.9871429441598328, 0.9885716484037881,
    0.994285824201894,
  ],
  [
    0, 0.025862052288036896, 0.13793110675184012, 0.1982758954239262, 0.27442529847464203, 0.3620688932481599,
    0.4511494030507158, 0.5344827363840492, 0.6120688932481599, 0.6781608583894686, 0.7298849629655424,
    0.7715516296322091, 0.8089080348586912, 0.8477010326829248, 0.885057437909407, 0.9166665054510234,
    0.9425285577390602, 0.9626435947735177, 0.9741377864963197, 0.9741377864963198, 0.9770112941231094,
    0.9784480479365043, 0.9827583093766887, 0.9827583093766887, 0.9870685708168733, 0.9928160697173825,
    0.9942529847464207, 0.9985632461866052,
  ],
  [
    0, 0.02844306348108925, 0.14670658380886537, 0.2110777628393646, 0.2919160228255371, 0.3832334564912557,
    0.4715567685457489, 0.5568861269621785, 0.6332334564912557, 0.6991016123407866, 0.7470058783887746,
    0.7874249243952799, 0.8248500167637215, 0.8652692307433888, 0.9026944910849924, 0.9326345313851134,
    0.9580834732549773, 0.9775446758218759, 0.9880235135550982, 0.9880235135550982, 0.9880235135550982,
    0.9940114208312253, 0.9940114208312253, 0.9940114208312253, 0.9940114208312253, 0.995508565623419,
    0.995508565623419, 1,
  ],
  [
    0, 0.030645105245669754, 0.16129024668684444, 0.2306449966591549, 0.3209676216729997, 0.41935460519029066,
    0.5145160262837278, 0.6016127269186695, 0.6854836841522325, 0.7483867663442613, 0.7999997466314653,
    0.8451610591383878, 0.8822576511868144, 0.9209674768909799, 0.9564513782162423, 0.9838705590830086,
    1.0016126907231646, 1.0161287170068924, 1.019354460408271, 1.0161287170068924, 1.012902973605514, 1.012902973605514,
    1.012902973605514, 1.0080643585034461, 1.006451486802757, 1.006451486802757, 1.0032257434013785, 1.0032257434013785,
  ],
  [
    0, 0.03187913524520413, 0.17114097750306118, 0.24664417274010214, 0.3422817667409184, 0.44966428548836745,
    0.5486575184838777, 0.642617292987347, 0.7281875934736737, 0.7969796989865308, 0.8489930447303063,
    0.8926171047221432, 0.9278520672272451, 0.9664426687270411, 0.9966439844748982, 1.0201338339681634,
    1.0302007509522455, 1.038590413234592, 1.036912405472041, 1.0285229314548985, 1.0201338339681636,
    1.0184560144708166, 1.0134225559787757, 1.0083890974867349, 1.0067112779893879, 1.005033458492041, 1, 1,
  ],
  [
    0, 0.03267966546442333, 0.16993470043899728, 0.24673208845655714, 0.3431373124214374, 0.4460785428165349,
    0.5457516783248579, 0.6356208958595209, 0.7173205178789084, 0.7843138045794764, 0.8316994386760559,
    0.8709151105706965, 0.9052289151501729, 0.9395427197296493, 0.9705884294223512, 0.991830221141393,
    1.0032679115434429, 1.013072379547098, 1.011438240432045, 1.0032679115434429, 1, 1, 1, 1, 1, 1.0016339557717213,
    1.0016339557717213, 1.0049018673151644,
  ],
  [
    0, 0.03067477166403705, 0.16411036398831402, 0.23312881535144597, 0.32515330243879603, 0.421779099928133,
    0.512269874246567, 0.5920244870873499, 0.664110363988314, 0.7208589411048789, 0.760736161477651, 0.792944645910604,
    0.8236195896698799, 0.855828074102833, 0.8880369027262638, 0.914110536083553, 0.9325152614057839, 0.946319020516506,
    0.9509205030137318, 0.9509205030137318, 0.9524542157826479, 0.9585890668583117, 0.9677913434718081,
    0.9769936200853041, 0.984662528120362, 0.9938651489243359, 0.995398861693252, 0.998466287231084,
  ],
  [
    0, 0.029940040300121012, 0.15718558951524964, 0.22604786697600604, 0.31137722539243573, 0.4041916358771861,
    0.48952082632045385, 0.5643710110573373, 0.6302393348800303, 0.6811375545660816, 0.7170656701154917,
    0.7470057104156127, 0.7754487738967019, 0.8068859589890166, 0.8398201209003631, 0.8652693987165507,
    0.8862272421561572, 0.9011975142659607, 0.9071854215420878, 0.9116763519991831, 0.9221553577055672,
    0.9386226066344023, 0.9550895196169138, 0.971556768545749, 0.9880235135550982, 0.9985028552078064,
    1.005987907276127, 1.0044909304570955,
  ],
  [
    0, 0.03067477166403705, 0.16104293845048198, 0.23159510258252997, 0.3174847385942159, 0.411042938450482,
    0.4969325744621679, 0.5720858769009639, 0.636503017862109, 0.687116571807771, 0.7239263666427109, 0.753067425537832,
    0.7822086565281918, 0.814417140961145, 0.8435581998562661, 0.868098120444639, 0.8880369027262638,
    0.8987728921086761, 0.9033740304154242, 0.9079751687221722, 0.9156444209477079, 0.9325152614057839,
    0.9509203309184929, 0.9708587690096401, 0.9892638385223489, 1.0015337127689161, 1.006134851075664,
    1.004601138306748,
  ],
  [
    0, 0.03205119905861977, 0.17147426560761392, 0.24358968826130206, 0.3333333333333333, 0.4326923561046941,
    0.5208332883789746, 0.5977563099736101, 0.6682691996469825, 0.7211536870845767, 0.7580126649015788,
    0.7884613309798828, 0.817307643895306, 0.8477563099736101, 0.8749997302738476, 0.8990382644308885,
    0.9166666666666666, 0.9246795113856804, 0.9294871103266276, 0.9294871103266276, 0.9374997752282064,
    0.9487176859078517, 0.9631410221829981, 0.9807688849664713, 0.993589688261302, 1.0048075989409473,
    1.006410131921263, 1.006410131921263,
  ],
  [
    0, 0.0350000673236457, 0.17999986535270857, 0.2566665370063119, 0.3499999251959492, 0.45333330590518134,
    0.5449998728331137, 0.6266664472414509, 0.6949999476371644, 0.7516666342515779, 0.7883331488166747,
    0.8183331263754595, 0.848333290944371, 0.876666447241451, 0.9033329692869527, 0.9249999625979745,
    0.9433329393653324, 0.9516666716536033, 0.9566667614184643, 0.9600000299216203, 0.9649999326763542,
    0.9699998354310881, 0.9833329094437122, 0.989999820470278, 1, 1.006666537006312, 1, 1,
  ],
  [
    0, 0.03620696993263765, 0.18965498562601324, 0.26896553725435574, 0.3672412392195099, 0.47241374640650324,
    0.5620689254912886, 0.6431033882369328, 0.7155171346434361, 0.7706896418304295, 0.808620522880369,
    0.8379310745087115, 0.865517134643436, 0.8965515973890804, 0.9275860601347248, 0.9465515973890803,
    0.9620689254912884, 0.9724135529477312, 0.9793099712520265, 0.9862071633914096, 0.9879312679674833,
    0.9965517908478523, 1.0034482091521477, 1.008620522880369, 1.0103446274564427, 1.0086205228803689,
    1.0051723137282216, 1,
  ],
  [
    0, 0.03846156864068247, 0.19580408194651616, 0.27622385029985697, 0.37412559702646103, 0.47902080206145287,
    0.5699298751374132, 0.6503494473263179, 0.7202793224637313, 0.7744752405172151, 0.8111885917041275,
    0.8426570943652943, 0.8706289659544851, 0.898601033708112, 0.926573101461739, 0.9458036896176442,
    0.9615380390304455, 0.9755241709894769, 0.9790206058970168, 0.9860134757120965, 0.9912581280734064,
    1.00174821745377, 1.0069928698150796, 1.0069928698150796, 1.0069928698150796, 1.0069928698150796, 1.00349643490754,
    1,
  ],
  [
    0, 0.03873245001295902, 0.20246490002591805, 0.2852113900285098, 0.3820423175149029, 0.4823945025317496,
    0.5739438400414689, 0.6496480125291577, 0.7165495350557238, 0.7676058925602595, 0.8028170850427647,
    0.8345072175408209, 0.8626762900544278, 0.8908453625680348, 0.9190142375356374, 0.9383806600881213,
    0.9542254300181428, 0.9683100650479484, 0.9788736400933049, 0.9806341700855294, 0.991197350038877,
    1.0017607275382292, 1.0070429101529164, 1.0070429101529164, 1.0123245001295902, 1.008803440145141,
    1.0035214550764582, 1,
  ],
  [
    0, 0.039007142993563663, 0.20921984122436768, 0.29432619033976964, 0.3865248015304596, 0.487588690578506,
    0.57446825442299, 0.6453900793878162, 0.7074468254422991, 0.7570924610223011, 0.7943263892867828,
    0.8262412702050587, 0.8581561511233344, 0.8900710320416102, 0.9166666666666666, 0.9326242065993111,
    0.946808730749887, 0.9609928570064364, 0.9716309516988483, 0.9769505958860941, 0.9858158737434506,
    0.9982269842179313, 1.0035460315641374, 1.0070920631282747, 1.0070920631282747, 1.0070920631282747,
    1.0017730157820686, 1,
  ],
  [
    0, 0.041666666666666664, 0.2155795687384135, 0.30072446092301686, 0.39492735063583434, 0.4963766790255959,
    0.5797100123589293, 0.6503622304615084, 0.7101446889127394, 0.7608693531076203, 0.7971013432204768,
    0.8315215712101992, 0.8659417991999219, 0.8949271473639704, 0.9239129020717467, 0.9420284905844472,
    0.9565215712101993, 0.9673911275896835, 0.9782604806973038, 0.9800720395485738, 0.989129833804924, 1,
    1.00181155885127, 1.00724623540508, 1.00724623540508, 1.00181155885127, 1, 0.992752951507464,
  ],
  [
    0, 0.04323308746421849, 0.22368408841839202, 0.3120299261211539, 0.41165416229723817, 0.5150375131082856,
    0.6015036880367226, 0.6691728133945376, 0.7293230767413668, 0.7819546889908962, 0.81578946258349,
    0.8496240252623977, 0.8834583770276188, 0.913533825071563, 0.9417291885138094, 0.9586465753101063,
    0.9755635402790304, 0.9849618541506548, 0.9924813489027005, 0.9962406744513501, 1.001879662774325,
    1.0056389883229748, 1.0075186510972995, 1.0075186510972995, 1.0075186510972995, 1, 1, 1,
  ],
  [
    0, 0.044573660267729, 0.2364339332156184, 0.329457583480477, 0.4263566936599198, 0.5310078109370514,
    0.6201551314725094, 0.6899225429905973, 0.75, 0.7984495550897215, 0.8333334783024694, 0.8643410717858169,
    0.8953491001765724, 0.9263566936599199, 0.9496124974992824, 0.9689921890629484, 0.9825584733010342,
    0.9922482103560152, 1, 1, 1.0038758948219924, 1.0038758948219924, 1.0058138422329888, 1.007751789643985,
    1.007751789643985, 1, 1, 1,
  ],
  [
    0, 0.04761900521312917, 0.24206320228971603, 0.337301657978118, 0.4365078446284465, 0.5436506063579871,
    0.6289679535929983, 0.7003966840437639, 0.7599204405601754, 0.8115076219973747, 0.8452377877951865,
    0.8769838654809632, 0.9007933680875277, 0.9325392231422327, 0.952380772155799, 0.9682536996831514,
    0.9821423164673968, 0.9920627570275723, 1, 1, 1.00396817622407, 1.0079363524481402, 1.0079363524481402,
    1.0079363524481402, 1.0079363524481402, 1.0079363524481402, 1.00396817622407, 1,
  ],
  [
    0, 0.04838708217922395, 0.24596759233704552, 0.34274220913955095, 0.4395163734979989, 0.5443549007382983,
    0.6290325207739689, 0.7016129178207762, 0.7620969967668348, 0.81048385272403, 0.842742209139551, 0.8709681578921176,
    0.8971775634801635, 0.9233874215122672, 0.9455646456593876, 0.9637099145876109, 0.9737908206339829,
    0.9858873649567601, 0.9919356371181487, 1, 1.002016090720463, 1.0080643628818515, 1.0141133137093266,
    1.0161296306518182, 1.0161296306518182, 1.0161296306518182, 1.0100806798243431, 1.0080643628818515,
  ],
  [
    0, 0.04878046555500516, 0.24593480916295152, 0.3373982961091847, 0.43495922721919505, 0.5406505400033024,
    0.6280488361124871, 0.6971545716691434, 0.7540649627758513, 0.8008131750041279, 0.8313008519454075,
    0.8577235661108358, 0.8821140269495354, 0.906504031665841, 0.934959683341589, 0.9512195344449947,
    0.9674796136095974, 0.9796751861207429, 0.9878051116724457, 0.9918700744482971, 0.9979675186120742,
    1.0020324813879258, 1.0081299255517027, 1.0081299255517027, 1.0101624069396284, 1.012194888327554,
    1.0081299255517027, 1,
  ],
  [
    0, 0.04958673854037517, 0.2499997681692698, 0.3429750188478384, 0.4442145713959826, 0.5495867385403752,
    0.6363635309860317, 0.7066114878618066, 0.7603303773369696, 0.803718889475163, 0.8326441778494083,
    0.8553717034829321, 0.8739666145202077, 0.9049586738540375, 0.9297515794627647, 0.9504132614596248,
    0.9669418651987762, 0.9793383180031399, 0.987603083534176, 0.9917356981304243, 0.9917356981304243, 1,
    1.0082643018695758, 1.0082643018695758, 1.0082643018695758, 1.0082643018695758, 1.0082643018695758,
    1.002066075467394,
  ],
  [
    0, 0.05042016014362386, 0.25840343859966736, 0.3571431938959858, 0.4579835141832336, 0.5630254359067199,
    0.647059114721823, 0.7142859163375915, 0.7689079136446441, 0.8109246351886006, 0.8361347152604125,
    0.8571429581687957, 0.8760508718134399, 0.903361752603371, 0.9243702312389444, 0.9495800755835662,
    0.9621855870738523, 0.9789919928188068, 0.9873951956912841, 0.9852943949731647, 0.9915967971275227,
    0.9936975978456419, 1, 1, 1.0021010364453093, 1.0084041457812376, 1.0084041457812376, 1,
  ],
  [
    0, 0.05172410457607379, 0.26724128758420296, 0.36637947711963104, 0.4698276862717786, 0.5732758954239262,
    0.6616379477119632, 0.7284482091521476, 0.7823274444483137, 0.8232756536004613, 0.849137947711963,
    0.8685341241927933, 0.8857756536004612, 0.9116377058884981, 0.9331897385598156, 0.9525861568641107,
    0.9698272026248487, 0.9849140849593542, 0.991379477119631, 0.991379477119631, 0.9956897385598156,
    1.0021551307200922, 1.008620522880369, 1.008620522880369, 1.008620522880369, 1.008620522880369, 1.0021551307200922,
    1,
  ],
  [
    0, 0.05357141068142177, 0.2767857053407109, 0.3772324760585195, 0.4799105957644192, 0.5870536675873579,
    0.6763394355430927, 0.7433036988948699, 0.7968751095762916, 0.8348215672189813, 0.8616072725596922,
    0.8839287771636497, 0.9017855801106632, 0.9308038867399412, 0.9553572412521802, 0.9732147955794795,
    0.9866073977897397, 1.0044642007367535, 1.0133931031304506, 1.0111607523019785, 1.0178578047873943,
    1.0178578047873943, 1.0178578047873943, 1.0178578047873943, 1.0178578047873943, 1.0133931031304506,
    1.0089284014735067, 1,
  ],
  [
    0, 0.055555497836397356, 0.28703682540012365, 0.38657397547051214, 0.4953699422866137, 0.5995366305979648,
    0.6851849062092539, 0.7569440476252318, 0.8101847763411479, 0.8541664285751391, 0.8749993506594702,
    0.8958330519524371, 0.9143514646524988, 0.9421289538344857, 0.9652774242479338, 0.9907401443094392,
    1.0046295382409625, 1.020832922084331, 1.0277772294457748, 1.0277772294457748, 1.0324067676867372, 1.0370363059277,
    1.0347215368072185, 1.0277772294457748, 1.0277772294457748, 1.0231476912048123, 1.0115738456024062, 1,
  ],
  [
    0, 0.05952373319312914, 0.2952380265404829, 0.3976191468489321, 0.49999999999999994, 0.6119047466386258,
    0.7023810668769729, 0.7690475198177344, 0.8214285332632313, 0.8619047466386258, 0.8833334401962859,
    0.8999997862740949, 0.9190475732492108, 0.942857173389415, 0.9642853326323124, 0.9904763736698233,
    1.0047618131650884, 1.0238096001402042, 1.0333337607851436, 1.0333337607851436, 1.0380955739502318,
    1.0380955739502318, 1.0357146673676876, 1.0285719476200552, 1.0261907738801297, 1.016666346077809,
    1.0095236263301766, 1,
  ],
  [
    0, 0.061274463968071846, 0.3039216602991504, 0.4093140463411441, 0.5147061573680395, 0.6274513470789547,
    0.7156866411966016, 0.7843141838486933, 0.8333337000201312, 0.8750001375075491, 0.8970596485747067,
    0.9093143213562425, 0.9215689941377783, 0.9460788897310463, 0.9705887853243143, 0.9877453272184643,
    1.004902419142811, 1.0245104456234646, 1.0343141838486933, 1.0392160529613077, 1.0392160529613077,
    1.044117922073922, 1.0392160529613077, 1.0343141838486933, 1.0269613801797717, 1.0171576419545434,
    1.009804838285622, 1,
  ],
  [
    0, 0.05999997755877637, 0.3050000729339768, 0.40750008134943566, 0.5150002749049895, 0.6300000448824472,
    0.7200002917359072, 0.7900000785442827, 0.8400002468534599, 0.8775000925700475, 0.8974997110692458,
    0.912500547004826, 0.9275002608792247, 0.9499998316908227, 0.9750004768760021, 0.987500238438001,
    1.0049999046247995, 1.0250000841545885, 1.030000549809979, 1.030000549809979, 1.0350004544347786, 1.040000359059578,
    1.030000549809979, 1.0275003169822838, 1.0199996184991982, 1.0124997615619988, 1.0024999523123999, 1,
  ],
  [
    0, 0.060606026260760164, 0.30808082239134993, 0.41161616447827, 0.5202021976527391, 0.6287876641297511,
    0.7171717830002097, 0.7853534208692006, 0.8333331444341809, 0.8737375397396592, 0.8964643745643512,
    0.9116158811295414, 0.9267679543921886, 0.9494947892168808, 0.9747473946084404, 0.9924243883917693,
    1.010100815477641, 1.0252520386941024, 1.030302446432923, 1.0277772425635128, 1.0252520386941024, 1.030302446432923,
    1.020201630955282, 1.0176764270858718, 1.010100815477641, 1.0025252038694101, 1, 1,
  ],
  [
    0, 0.06122444306285945, 0.31122444306285946, 0.4158162476685753, 0.5204080522742911, 0.6275508276342953,
    0.7142855507200081, 0.7780609645371557, 0.8316324953371506, 0.8698979153714308, 0.89285693840001,
    0.9107137336800272, 0.9234691599314399, 0.9515304107085814, 0.9744891474971749, 0.9923470877371356,
    1.0102038830171527, 1.0255097075428816, 1.0306116490514579, 1.0280606782971697, 1.0204077660343054,
    1.0204077660343054, 1.0102038830171527, 1.0076529122628644, 1, 1, 1, 1,
  ],
  [
    0, 0.06443287938080768, 0.31443287938080766, 0.4175256599046132, 0.5206181512375634, 0.6262884898569199,
    0.7113398096661465, 0.7731957093331143, 0.8195874171901985, 0.8582467399514853, 0.8814433168571663,
    0.8994841991900343, 0.9175250815229021, 0.9458761962379739, 0.9716488852849281, 0.992267325713347,
    1.0077318067140861, 1.0154636134281725, 1.018040882332868, 1.0103090756187816, 1.005154537809391, 1, 1,
    0.9974224419044491, 0.992267325713347, 1, 1, 1,
  ],
  [
    0, 0.06770823593215268, 0.3281252922035419, 0.4244794101696183, 0.523437646101771, 0.6223961742374656,
    0.703125292203542, 0.7630214664410075, 0.8072920562713893, 0.8463547023731602, 0.8697917640678474,
    0.8880217586445496, 0.906250584407084, 0.9348961742374657, 0.9583341125427786, 0.976562938305313,
    0.9895835281356946, 1, 1, 0.9973958820339237, 0.9895835281356946, 0.9895835281356946, 0.9791670562713893,
    0.9843752922035419, 0.9869794101696182, 0.9947917640678474, 1, 1.007813230508855,
  ],
  [
    0, 0.07031264610177099, 0.3333335281356946, 0.42708382033923664, 0.515625292203542, 0.6119794101696183,
    0.6901044101696182, 0.7447920562713893, 0.7916670562713893, 0.8255214664410075, 0.8489588203392366,
    0.8645835281356946, 0.8802088203392366, 0.9088547023731602, 0.9322917640678474, 0.9505217586445496,
    0.9661464664410075, 0.9739588203392366, 0.9765629383053129, 0.968750584407084, 0.968750584407084,
    0.9583341125427786, 0.9583341125427786, 0.9661464664410075, 0.968750584407084, 0.976562938305313,
    0.9869794101696182, 0.9973958820339237,
  ],
  [
    0, 0.07446815494948338, 0.3457446427548272, 0.4361705365839683, 0.5212767862258636, 0.6143616068870681,
    0.6941491076032773, 0.7499999999999999, 0.7925535724517274, 0.8271276786225863, 0.84840476326897, 0.861702381634485,
    0.8803190473462059, 0.9069151793387956, 0.9308511908172424, 0.9521273802020664, 0.965425893829141,
    0.9787238106151759, 0.9787238106151759, 0.9787238106151759, 0.9680857159227639, 0.9680857159227639,
    0.9680857159227639, 0.9680857159227639, 0.970745239595867, 0.9840428579613819, 0.9893619053075879,
    0.9973404763268969,
  ],
  [
    0, 0.07608699629232121, 0.3478260074153576, 0.43478235681529903, 0.5271739925846424, 0.6249996950922041,
    0.7038040296614304, 0.7635866913845253, 0.8043479851692849, 0.8342387062152404, 0.8559783271538687,
    0.8695653234461899, 0.8858693531076202, 0.9157606839691677, 0.9402173382769052, 0.9619560444921456,
    0.975542735876671, 0.99456532344619, 0.997282661723095, 0.9891306468923797, 0.978260074153576, 0.978260074153576,
    0.978260074153576, 0.978260074153576, 0.978260074153576, 0.9864130037076787, 0.9891306468923797, 1,
  ],
  [
    0, 0.07500015584188906, 0.3555557633447409, 0.4444445483390371, 0.5333336450171114, 0.63055591918663,
    0.7138892525199634, 0.7722227416951857, 0.8138895642037414, 0.8472225858532967, 0.8638889408361853,
    0.8777788167237047, 0.8972228975370747, 0.9277781933561485, 0.9555563867122971, 0.9777781933561487,
    0.9888890966780742, 1, 1, 1, 0.9888890966780742, 0.9972222741695186, 0.9972222741695186, 0.9888890966780742,
    0.9888890966780742, 0.9972222741695186, 0.9944445483390372, 1,
  ],
  [
    0, 0.07670467223702011, 0.35227268380445026, 0.4431819485866492, 0.5340912133688482, 0.6306818688948079,
    0.7159091053985165, 0.7755683303347949, 0.8125000796918411, 0.8465912930606894, 0.8721589858607546,
    0.8835224048830059, 0.903409663241405, 0.9374999203081588, 0.9659094241658814, 0.9829548714666231,
    0.9971591452444372, 1, 1, 1, 0.9971591452444372, 1.0085225642666884, 1.0113634190222511, 1, 1, 1.0056817095111255,
    1, 0.9943182904888743,
  ],
  [
    0, 0.07647039410720298, 0.3617644729286436, 0.4470585517500842, 0.5411759852680075, 0.6352937488036856,
    0.7235289458572872, 0.7823523005537699, 0.8176467093929654, 0.855881906446567, 0.8794115123393639,
    0.8999993399644901, 0.9205874976073714, 0.952940788214406, 0.9823519705360149, 0.9970585517500842,
    1.0088233546964829, 1.0117644729286437, 1.0117644729286437, 1.0117644729286437, 1.0117644729286437,
    1.0205878276251261, 1.0235289458572872, 1.0235289458572872, 1.0235289458572872, 1.0205878276251263,
    1.0088233546964829, 1,
  ],
  [
    0, 0.07831312271025131, 0.36144603966419275, 0.4457834827161996, 0.5421688905111463, 0.6385546362763785,
    0.7289157237295697, 0.7921685525408606, 0.8313257898365577, 0.8644580308499277, 0.8885549742466643,
    0.909638912546809, 0.9307228508469535, 0.9668680969569156, 0.9939760176285302, 1.0060239823714698, 1.01204796474294,
    1.018072623054981, 1.01204796474294, 1.01204796474294, 1.01204796474294, 1.0240972813670222, 1.0240972813670222,
    1.0240972813670222, 1.01204796474294, 1.01204796474294, 1, 1,
  ],
  [
    0, 0.08124980714584262, 0.37499999999999994, 0.4687499123390194, 0.5624998246780387, 0.6718747808475484,
    0.7562495266307047, 0.828124167220684, 0.8624998948068232, 0.8999995090985086, 0.9218751314914709,
    0.9406247633153524, 0.9624993337765473, 0.9937497720814503, 1.021874570461195, 1.0312493863731356,
    1.0374992636477627, 1.0374992636477627, 1.0374992636477627, 1.0249995090985085, 1.028124447735822,
    1.0374992636477627, 1.0374992636477627, 1.0374992636477627, 1.0312493863731356, 1.021874570461195,
    1.0124997545492542, 1,
  ],
  [
    0, 0.08333321345495714, 0.38461574425051315, 0.47756452213841927, 0.5769234365582054, 0.6794877788790605,
    0.775641265397778, 0.8397437096219659, 0.8814110356197016, 0.9166667865450429, 0.9391037029471379,
    0.9551290442768384, 0.9775645221384192, 1.0032057875361973, 1.0288470529339753, 1.0384622577317957,
    1.0384622577317957, 1.0384622577317957, 1.0384622577317957, 1.0256419846680351, 1.0288470529339753,
    1.0384622577317957, 1.0384622577317957, 1.0384622577317957, 1.0320521211999154, 1.0256419846680351,
    1.0128217116042746, 1.0064108558021374,
  ],
  [
    0, 0.08552615066622375, 0.3947370169416454, 0.486842357804599, 0.5789473295685232, 0.687499723175728,
    0.7828948436865611, 0.8486841619606541, 0.8848684161960655, 0.9210526704314768, 0.9374997231757282,
    0.9539475141180379, 0.9769741261580484, 1.0065788210977007, 1.0296050640386816, 1.0427638135311705,
    1.0526320451777214, 1.0394744029823202, 1.0361846233344407, 1.026315284390802, 1.026315284390802, 1.026315284390802,
    1.0394744029823202, 1.0394744029823202, 1.0361846233344407, 1.026315284390802, 1.0197364632931016,
    1.0065788210977007,
  ],
  [
    0, 0.08783762268745612, 0.40202668381094187, 0.49324299735709265, 0.5945942462558813, 0.69594549515467,
    0.7804051851323957, 0.8445942462558813, 0.8817560549567021, 0.918918621806487, 0.9358105598020321,
    0.9527021187230951, 0.9729723685028528, 0.9999999999999999, 1.0202698707052755, 1.0405397414105513,
    1.0540529885474017, 1.0405397414105513, 1.0405397414105513, 1.0270264942737009, 1.0270264942737009,
    1.0270264942737009, 1.0270264942737009, 1.0405397414105513, 1.0270264942737009, 1.0270264942737009,
    1.0135132471368504, 1.006756623568425,
  ],
  [
    0, 0.09027764790953689, 0.4097223520904631, 0.4965282323166208, 0.5902780375142596, 0.6909725468928243,
    0.7777780375142596, 0.8402780375142596, 0.878472936497547, 0.9131945093785648, 0.9305552958190737,
    0.9444454833903715, 0.9583341125427787, 0.9930556854237964, 1.0208345021475012, 1.0381952885880101,
    1.0451396031642137, 1.055556075028519, 1.041667445876112, 1.0347231312999083, 1.0277788167237047,
    1.0277788167237047, 1.0277788167237047, 1.0277788167237047, 1.0277788167237047, 1.0138901875712976,
    1.0069450937856488, 0.9930556854237964,
  ],
  [
    0, 0.09285695966350994, 0.41428576008412255, 0.5, 0.5964287202733982, 0.6928570398107243, 0.7821426396004181,
    0.84285695966351, 0.8821427197476325, 0.9142857600841225, 0.9321425594532038, 0.9428566390746524,
    0.9571424793059893, 0.9857145605047353, 1.014285439495265, 1.0392861608201946, 1.0464292813038991,
    1.0571433609253478, 1.0535720010515315, 1.0428579214300828, 1.0428579214300828, 1.0428579214300828,
    1.02857087899053, 1.02857087899053, 1.014285439495265, 1.0107140796214489, 1, 0.9964286401261838,
  ],
  [
    0, 0.09558799263400371, 0.42647039410720294, 0.5147055911608044, 0.6066175985268007, 0.7058819889510055,
    0.790440788214406, 0.8529407882144059, 0.889704766116417, 0.926470394107203, 0.9448523830582085, 0.9558815764288119,
    0.9705871675896164, 0.9963231896876051, 1.0257347845314078, 1.0404403756922123, 1.0588223646432178,
    1.0588223646432178, 1.0588223646432178, 1.0477931712726145, 1.0441167734824133, 1.0441167734824133,
    1.0294111823216088, 1.0147055911608045, 1.0147055911608045, 1.0147055911608045, 1, 1,
  ],
  [
    0, 0.09328353834688462, 0.4253732530584561, 0.5186567914053407, 0.6082094738869397, 0.708955980003852,
    0.7947765505807091, 0.8432843757065223, 0.8843288143824893, 0.9253740904180937, 0.9477621563685389,
    0.9552247054587475, 0.9738810781842695, 1.0000004186798188, 1.026120596535006, 1.0410456947154234,
    1.0485082438056321, 1.0597020674409452, 1.0447769692605278, 1.0335831456252147, 1.0261205965350058,
    1.026120596535006, 1.0149267728996927, 1.0149267728996927, 1, 1, 0.9888061763646869, 0.9850749018195826,
  ],
  [
    0, 0.09615374656260843, 0.4346153315000578, 0.5230769164375072, 0.6153850137495662, 0.7115387603121747,
    0.7923081836244651, 0.8461537465626084, 0.8846158493744939, 0.9230766575002892, 0.9423074931252168,
    0.9538470302490458, 0.9730774343119435, 1.007692161625159, 1.0307690780626664, 1.0500007768116544,
    1.0615390192493928, 1.0615390192493928, 1.0576929384368134, 1.0423081836244652, 1.030768646500636,
    1.030768646500636, 1.0153843232503181, 1.0153843232503181, 1, 1, 0.9884617575622614, 0.9846156767496819,
  ],
  [
    0, 0.0992061866503285, 0.44047602085251675, 0.5277776788306346, 0.6190475130328228, 0.7142855234590811,
    0.7976190052131291, 0.8452375651641146, 0.8809520417050334, 0.9126983420218817, 0.9365073993663029,
    0.9484119280385134, 0.9722214306450778, 1, 1.0317454097925611, 1.051586290912912, 1.0634908195851223,
    1.0634908195851223, 1.0595226433610523, 1.0357135860166313, 1.027777233568491, 1.027777233568491,
    1.0158727048962806, 1.0158727048962806, 1, 1, 0.9960318237759298, 0.9880954713277894,
  ],
  [
    0, 0.0983606858925543, 0.4467218316465616, 0.5286889166032058, 0.6147547466899173, 0.7090171473138572,
    0.7950825175391159, 0.8442633203468459, 0.8852461730329887, 0.9139355494976474, 0.9344274357021716,
    0.9467222915080145, 0.9672137178510859, 0.9959021745928388, 1.0286898363261119, 1.0450829774005688,
    1.0532795479377974, 1.06557440374364, 1.049181262669183, 1.032788121594726, 1.016394980520269, 1.016394980520269,
    1.016394980520269, 1.016394980520269, 1.016394980520269, 1.0122962353902016, 1, 1,
  ],
  [
    0, 0.09745767546945901, 0.4449153509389181, 0.5211865132041885, 0.6101697735718442, 0.7118646565922049,
    0.7881358188574754, 0.8432204679185573, 0.8855934943269343, 0.9194911547341476, 0.9364404603874344,
    0.9491535093891803, 0.9618651320418852, 0.9915255848981968, 1.012711622652705, 1.0296604528563114,
    1.038135343407795, 1.0423730264083768, 1.033897660407213, 1.0084744151018032, 1, 1, 1, 1.0169488302036067,
    1.0169488302036067, 1.0169488302036067, 1.0169488302036067, 1.0042372075509016,
  ],
  [
    0, 0.09649129714130482, 0.4473679807241818, 0.5175435142934759, 0.6008766835828769, 0.7017543514293477,
    0.7763157635720108, 0.8245609200108664, 0.8684206900081497, 0.8991228242853264, 0.9166663385788022,
    0.9298239742989092, 0.942981610019016, 0.9692973735910269, 0.9956141214266311, 1.0131576357201069,
    1.021929392866845, 1.030701150013583, 1.017543514293476, 1, 0.9956141214266311, 1, 1.0131576357201069,
    1.017543514293476, 1.021929392866845, 1.017543514293476, 1.017543514293476, 1.004385878573369,
  ],
  [
    0, 0.09722248195870398, 0.4444444444444445, 0.5277782972507413, 0.615741173634877, 0.7083341125427787,
    0.7824073208285802, 0.8333343722792604, 0.8703707166856794, 0.9027775180412959, 0.91666718613963,
    0.9305568542379643, 0.9537045694919761, 0.9814818277967905, 1.0138886291524072, 1.0324083597745073,
    1.0416679653490755, 1.05092705145068, 1.0416679653490755, 1.0370384222982731, 1.0231482347269754,
    1.0370384222982731, 1.0370384222982731, 1.0370384222982731, 1.0416679653490755, 1.0370384222982731,
    1.0277782972507414, 1.0092590861016046,
  ],
  [
    0, 0.09803892449413779, 0.4509802627381353, 0.5392154597917368, 0.6372543842858746, 0.735293858809604,
    0.8137247783930776, 0.8627450656845339, 0.9019605254762706, 0.9362735715181477, 0.946077298958684,
    0.9656853038693481, 0.9901962725594637, 1.0196074548810725, 1.0490186372026815, 1.0735279558040223,
    1.0784298195242905, 1.0833322332741502, 1.0833322332741502, 1.0784298195242905, 1.0784298195242905,
    1.0784298195242905, 1.0784298195242905, 1.0588223646432178, 1.0490186372026815, 1.0392149097621453,
    1.0196074548810725, 1.004901863720268,
  ],
  [
    0, 0.09375, 0.44791705627138934, 0.5520835281356947, 0.6666670562713893, 0.7708341125427786, 0.8541670562713892,
    0.9166676406784732, 0.9583341125427787, 0.9947917640678473, 1, 1.0052082359321526, 1.0364594047463205,
    1.0677094047463207, 1.093750584407084, 1.1145835281356948, 1.125, 1.125, 1.125, 1.1145835281356948,
    1.1041670562713894, 1.1041670562713894, 1.0885423484749315, 1.0729176406784733, 1.0520846969498625,
    1.0364594047463205, 1.0104164718643052, 1,
  ],
  [
    0, 0.09042559540862125, 0.4468083328558604, 0.5585107144903455, 0.6595744045913786, 0.7659577388796577,
    0.845745239595867, 0.898935713057927, 0.9468095265379399, 0.97340476326897, 0.994680952653794, 1, 1.021276189384824,
    1.0531916671441395, 1.079788097557249, 1.0957452395958671, 1.106383334288279, 1.106383334288279, 1.085107144903455,
    1.074469050211043, 1.063830955518631, 1.063830955518631, 1.0478720229568936, 1.0372333314234419, 1.0106380946924118,
    1, 0.9840428579613819, 0.9787238106151759,
  ],
  [
    0, 0.09444443059184936, 0.44999975065328834, 0.5611105154495222, 0.6555549460413715, 0.7611102661028106,
    0.8388883624902754, 0.8944440565717819, 0.9333321697153457, 0.9722221529592467, 0.9944445552652051,
    1.0055554447347947, 1.033332668408769, 1.0611098920827433, 1.0888871157567173, 1.105555320061439,
    1.1111113881630128, 1.1111113881630128, 1.0833316710219225, 1.066665336817538, 1.0444435578783586,
    1.0444435578783586, 1.0444435578783586, 1.033332668408769, 1.0111108894695895, 1, 0.9888891105304103,
    0.9777782210608206,
  ],
  [
    0, 0.0965908511332803, 0.4431810647326438, 0.5511353203990452, 0.6477268090662424, 0.7386353203990452,
    0.8124987249321663, 0.8522719158659241, 0.903408511332803, 0.943180427198727, 0.9715889385315299,
    0.9829529805985678, 1.017045106799682, 1.0511353203990454, 1.0738621294652875, 1.0909072362649694,
    1.0909072362649694, 1.0909072362649694, 1.068180427198727, 1.0511353203990452, 1.0454536181324847,
    1.0227268090662425, 1.0227268090662425, 1.0227268090662425, 1.0113634045331212, 1, 0.9829529805985678,
    0.9772706407980905,
  ],
  [
    0, 0.09523831786936532, 0.4642857142857143, 0.5595240321550795, 0.6547623500244449, 0.744047062469444,
    0.8095247000488898, 0.8571428571428572, 0.9047630179182551, 0.9464295732692867, 0.9702386518162704,
    0.9821431910897622, 1.0119045392734918, 1.041667891138652, 1.071429907216192, 1.0892867161264297,
    1.0952389857631755, 1.0952389857631755, 1.071429907216192, 1.0595253679427001, 1.0476208286692081,
    1.0476208286692081, 1.0476208286692081, 1.0476208286692081, 1.0238090785469836, 1.0238090785469836,
    1.005952269636746, 1,
  ],
  [
    0, 0.100000140257569, 0.4624993337765473, 0.5625001753219612, 0.6624996142916852, 0.7437494214375279,
    0.8124994740341163, 0.8624998948068232, 0.9062490357292131, 0.9374998246780387, 0.950000981802983,
    0.9625007363522373, 0.9812503681761187, 1, 1.0249995090985085, 1.0499990181970171, 1.0749985272955254,
    1.0812491058579976, 1.0749985272955254, 1.0749985272955254, 1.0749985272955254, 1.0624987727462711,
    1.0499990181970171, 1.0499990181970171, 1.04374914092239, 1.0249995090985085, 1.0249995090985085,
    1.0249995090985085,
  ],
  [
    0, 0.09615406746745306, 0.44871754297633604, 0.5512817377544414, 0.6538452132633245, 0.7307688987988203,
    0.7948701719053441, 0.8333323743077032, 0.8782047040207148, 0.897435085952672, 0.9230755951952816,
    0.9230755951952816, 0.9358958498165864, 0.9615377975976407, 0.9935898726893476, 1.0128202546213048,
    1.0256405092426095, 1.0512810184852188, 1.0512810184852188, 1.0512810184852188, 1.0512810184852188,
    1.0256405092426095, 1.0256405092426095, 1.0128202546213048, 1.0064101273106525, 1, 1, 1,
  ],
  [
    0, 0.10135178165276724, 0.4729734647460197, 0.581081880212282, 0.6621622441243367, 0.7432448824867325,
    0.7972979529946931, 0.8445943896891585, 0.8648642911296438, 0.8918938589840788, 0.8918938589840788,
    0.9189203942380592, 0.9459469294920394, 0.9729734647460198, 1, 1.0202721758908264, 1.0472994692949205,
    1.0540561031084155, 1.0540561031084155, 1.0337862016679302, 1.0270295678544352, 1.0270295678544352,
    1.0270295678544352, 1.0135147839272176, 1, 1, 1, 1,
  ],
  [
    0, 0.10714320063091888, 0.5000000000000001, 0.5928569596635099, 0.6785711995793875, 0.7571427197476325,
    0.8142860806729801, 0.84285695966351, 0.8714278386540398, 0.8999987176445696, 0.9142841571398346,
    0.9285711995793875, 0.9500009617665728, 0.9785718407571027, 1.0214281592428973, 1.0499990382334272,
    1.0714288004206127, 1.0642852792008362, 1.0571417579810596, 1.0357135987381623, 1.02857087899053, 1.02857087899053,
    1, 1.0071427197476326, 1, 1, 1, 1,
  ],
  [
    0, 0.10937483563531553, 0.539063568370449, 0.6406260409763349, 0.7343761505527913, 0.8203138970998181,
    0.8671898284996967, 0.8984392806174151, 0.9218763697057037, 0.9375010957645631, 0.9687505478822814,
    0.976562910911711, 1, 1.0390653215937502, 1.078127136740898, 1.0937518627997571, 1.1250013149174758,
    1.1250013149174758, 1.1093765888586165, 1.078127136740898, 1.0625024106820389, 1.0546900476526093,
    1.0312529585643204, 1.0312529585643204, 1.0156264792821603, 1, 1, 1,
  ],
  [
    0, 0.11666635498288856, 0.5500009350513343, 0.6583331774914443, 0.7583341125427785, 0.8499990649486657,
    0.89166775755989, 0.9333345800684457, 0.9666672900342228, 0.9916668225085556, 1, 1, 1.033332709965777,
    1.0583322424401098, 1.0916677575598899, 1.1000018701026684, 1.116668225085557, 1.1083350475941127,
    1.1000018701026684, 1.0833336450171114, 1.0666654199315542, 1.033332709965777, 1.033332709965777, 1.033332709965777,
    1.0166663549828885, 1, 1, 1,
  ],
  [
    0, 0.12068925491288553, 0.5689661176306717, 0.6724133594889593, 0.758621490174229, 0.8448267189779186,
    0.8879293333797635, 0.9310358169570484, 0.9482768627177863, 0.9655179084785243, 0.9827589542392621, 1, 1,
    1.025861568641107, 1.034482091521476, 1.0689641830429517, 1.0689641830429517, 1.0689641830429517,
    1.0689641830429517, 1.051723137282214, 1.034482091521476, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0, 0.11607110655162879, 0.5535713570114731, 0.6696414617237247, 0.75, 0.830356534597521, 0.8660701047122517,
    0.8928552822982997, 0.910712067355665, 0.9374982467810901, 0.9642864298852694, 0.9642864298852694,
    0.9642864298852694, 0.9821432149426346, 1, 1, 1.0178567850573654, 1.0267851775860481, 1.0178567850573654,
    1.0357135701147306, 1.0357135701147306, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0, 0.125, 0.5769249857584284, 0.6826934695920869, 0.7692315161663416, 0.836541283295068, 0.8557717205544717,
    0.8846173764435774, 0.9038478137029811, 0.9326934695920869, 0.9423086882217887, 0.9615391254811925,
    0.9615391254811925, 0.9807695627405963, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0, 0.14130402966143038, 0.6304346765538101, 0.7608693531076203, 0.8478241779685824, 0.9021746024002342,
    0.9130451751390379, 0.9565225875695189, 0.9782612937847595, 1, 1, 1, 1.0108693531076203, 1.043477412430481,
    1.043477412430481, 1.043477412430481, 1.043477412430481, 1.043477412430481, 1.043477412430481, 1.043477412430481,
    1.043477412430481, 1.043477412430481, 1.0326080593228608, 1.043477412430481, 1.043477412430481, 1.043477412430481,
    1.043477412430481, 1.0326080593228608,
  ],
  [
    0, 0.15476126867426104, 0.6904747910833744, 0.833331552287931, 0.9285676120455664, 0.9880941567462438, 1, 1,
    1.0357135224091132, 1.0476180298788176, 1.0238090149394088, 1.0476180298788176, 1.0476180298788176,
    1.0476180298788176, 1.0714270448182264, 1.0952360597576354, 1.0952360597576354, 1.0833315522879308,
    1.0476180298788176, 1.0476180298788176, 1.0357135224091132, 1, 1.0119045074697044, 1.0476180298788176,
    1.0476180298788176, 1.0476180298788176, 1.0476180298788176, 1.0357135224091132,
  ],
  [
    0, 0.15000028051592487, 0.7125020337404553, 0.8500025246433239, 0.9750004207738874, 1.0375035765780423,
    1.050004768770723, 1.050004768770723, 1.087504137609892, 1.1000039272229483, 1.050004768770723, 1.050004768770723,
    1.050004768770723, 1.050004768770723, 1.0750043479968356, 1.1000039272229483, 1.050004768770723, 1.0750043479968356,
    1.050004768770723, 1.050004768770723, 1.0375035765780423, 1, 1.0125011921926808, 1.050004768770723,
    1.050004768770723, 1.050004768770723, 1.050004768770723, 1.0375035765780423,
  ],
  [
    0, 0.15000028051592487, 0.7000008415477745, 0.8500025246433239, 0.9500008415477748, 1.0250023843853615,
    1.050004768770723, 1.050004768770723, 1.087504137609892, 1.1000039272229483, 1.050004768770723, 1.050004768770723,
    1.050004768770723, 1.050004768770723, 1.050004768770723, 1.050004768770723, 1.050004768770723, 1.050004768770723,
    1.050004768770723, 1.050004768770723, 1.0375035765780423, 1, 1, 1.050004768770723, 1.050004768770723,
    1.050004768770723, 1.050004768770723, 1.0250023843853615,
  ],
  [
    0, 0.15000028051592487, 0.7000008415477745, 0.8250029454172113, 0.9250012623216619, 0.9875002103869437, 1, 1,
    1.0375035765780423, 1.050004768770723, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.0125011921926808,
    1.050004768770723, 1.0125011921926808, 1,
  ],
  [
    0, 0.1578948922524523, 0.6973678771164169, 0.8421080605441413, 0.9473693535147137, 1.0263153232426432,
    1.0526306464852861, 1.0526306464852861, 1.0526306464852861, 1.0526306464852861, 1.0526306464852861,
    1.0526306464852861, 1.0526306464852861, 1.0526306464852861, 1.0526306464852861, 1.0526306464852861,
    1.0526306464852861, 1.0526306464852861, 1.0526306464852861, 1.0526306464852861, 1.0394729848639648, 1, 1, 1,
    1.0131576616213216, 1.0526306464852861, 1.0131576616213216, 1,
  ],
  [
    0, 0.15277803751425953, 0.7222211832762951, 0.8333348917522239, 0.9305568542379644, 0.9861113708475928, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
];

export const boxShapedDataMatrix = [
  [
    0.220000058666682, 0.286666676444447, 0.350000048888901, 0.410000176000047, 0.473333303999992, 0.536666920888956,
    0.593333558222282, 0.646666705777788, 0.693333607111184, 0.726667038222321, 0.74666651022218, 0.760000469333458,
    0.760000469333458, 0.760000469333458, 0.760000469333458, 0.760000469333458, 0.760000469333458, 0.760000469333458,
    0.760000469333458, 0.760000469333458, 0.766666960000078, 0.773333450666698, 0.786666431999937, 0.793333411555576,
    0.800000391111215, 0.806666881777835, 0.813333372444454, 0.813333372444454, 0.813333372444454, 0.820000352000093,
    0.826667331555732, 0.826667331555732, 0.826667331555732, 0.830000576889042, 0.846666803555592, 0.876666989333419,
    0.913333421333356, 0.966667057777882,
  ],
  [
    0.250000199275434, 0.312500149456575, 0.375000099637717, 0.434782729993742, 0.494565360349768, 0.554347990705793,
    0.608695981411587, 0.65760893391628, 0.701087246770741, 0.736413201598986, 0.758152158750782, 0.771739554978099,
    0.771739554978099, 0.771739554978099, 0.771739554978099, 0.766304516776998, 0.760869478575898, 0.760869478575898,
    0.75271751910055, 0.760869478575898, 0.760869478575898, 0.771739554978099, 0.782608834278563, 0.790761391580214,
    0.801630870156112, 0.804348189981228, 0.80978282963146, 0.815217469281691, 0.815217469281691, 0.815217469281691,
    0.815217469281691, 0.815217469281691, 0.815217469281691, 0.820652507482792, 0.839674144809472, 0.875000298913151,
    0.918478611767612, 0.96739176354774,
  ],
  [
    0.270000022000005, 0.330000088000023, 0.395000022000005, 0.455000088000023, 0.515000337333423, 0.569999985333329,
    0.622500249333399, 0.675000146666705, 0.715000190666717, 0.747500249333399, 0.770000205333388, 0.785000176000046,
    0.790000410666776, 0.790000410666776, 0.782500058666682, 0.777500007333335, 0.770000205333388, 0.76250040333344,
    0.760000469333458, 0.760000469333458, 0.760000469333458, 0.770000205333388, 0.782500058666682, 0.792500344666758,
    0.802500080666688, 0.809999882666635, 0.809999882666635, 0.809999882666635, 0.809999882666635, 0.809999882666635,
    0.809999882666635, 0.809999882666635, 0.809999882666635, 0.815000117333364, 0.837500256666735, 0.875000183333382,
    0.917500161333376, 0.970000425333446,
  ],
  [
    0.287878703703703, 0.353535185185185, 0.419191851851851, 0.482323148148148, 0.542929259259259, 0.598484814814814,
    0.651515185185185, 0.699494814814814, 0.739899074074074, 0.743, 0.746100925925926, 0.749201851851852,
    0.752302777777778, 0.755403703703704, 0.75850462962963, 0.761605555555556, 0.764706481481482, 0.767807407407408,
    0.777777777777777, 0.777777777777777, 0.785353333333333, 0.792929259259259, 0.805555555555555, 0.815656296296296,
    0.825757592592592, 0.828282962962962, 0.828282962962962, 0.828282962962962, 0.828282962962962, 0.828282962962962,
    0.828282962962962, 0.828282962962962, 0.828282962962962, 0.835858518518518, 0.85606074074074, 0.883838333333333,
    0.926767592592592, 0.974747592592592,
  ],
  [
    0.305850947638432, 0.377659698958822, 0.44680875132039, 0.515957608646469, 0.574468060208235, 0.630319202882156,
    0.683510841632743, 0.728723383506863, 0.765957608646469, 0.79787243586843, 0.8, 0.80212756413157, 0.80425512826314,
    0.80638269239471, 0.80851025652628, 0.81063782065785, 0.81276538478942, 0.819149052361567, 0.819149052361567,
    0.819149052361567, 0.824468060208235, 0.835106465972548, 0.845744871736861, 0.853723383506862, 0.861701895276864,
    0.861701895276864, 0.861701895276864, 0.861701895276864, 0.861701895276864, 0.861701895276864, 0.861701895276864,
    0.861701895276864, 0.864361594235687, 0.875000195035488, 0.888298104723135, 0.912234225139606, 0.941489548438233,
    0.973404375660195,
  ],
  [
    0.323033636114947, 0.398876598914336, 0.469101320329983, 0.53932604174563, 0.603932727770105, 0.660112463704108,
    0.713483490931383, 0.758427156083043, 0.79494387114423, 0.82584316879196, 0.848314898371505, 0.862359883853148,
    0.870786627951049, 0.87640445734965, 0.865168798552449, 0.862359883853148, 0.853933139755248, 0.853933139755248,
    0.853933139755248, 0.856742054454548, 0.870786627951049, 0.882022698733392, 0.893258769515736, 0.898876598914336,
    0.910112257711538, 0.904494428312937, 0.898876598914336, 0.898876598914336, 0.898876598914336, 0.898876598914336,
    0.901685513613637, 0.910112257711538, 0.918539619787152, 0.926966569877623, 0.938202640659967, 0.95224762614161,
    0.971910441021855, 0.994382170601399,
  ],
  [
    0.318181875, 0.392045416666666, 0.465909166666666, 0.534090833333333, 0.599431875, 0.65625, 0.710227291666666,
    0.755681875, 0.798295416666666, 0.829545625, 0.857954583333333, 0.872159166666666, 0.880681666666666,
    0.886363333333333, 0.875, 0.872159166666666, 0.863636666666666, 0.863636666666666, 0.863636666666666,
    0.872159166666666, 0.889204375, 0.903409166666666, 0.9147725, 0.920454166666666, 0.920454166666666,
    0.920454166666666, 0.9147725, 0.909090833333333, 0.909090833333333, 0.9147725, 0.923295208333333, 0.934659166666666,
    0.946022708333333, 0.957386666666666, 0.965909166666666, 0.9772725, 0.988636666666666, 0.997159166666666,
  ],
  [
    0.306818125, 0.375, 0.448863541666666, 0.517045416666666, 0.582386458333333, 0.64488625, 0.69886375, 0.755681875,
    0.795454375, 0.832386458333333, 0.857954583333333, 0.877840833333333, 0.886363333333333, 0.886363333333333,
    0.886363333333333, 0.875, 0.869318333333333, 0.863636666666666, 0.8664775, 0.877840833333333, 0.892045416666666,
    0.911931666666666, 0.920454166666666, 0.931818333333333, 0.931818333333333, 0.92613625, 0.920454166666666,
    0.920454166666666, 0.920454166666666, 0.923295208333333, 0.934659166666666, 0.94886375, 0.963068333333333,
    0.974431666666666, 0.985795625, 0.988636666666666, 0.997159166666666, 1,
  ],
  [
    0.295454583333333, 0.36363625, 0.431818125, 0.505681666666666, 0.568181875, 0.63636375, 0.696022708333333,
    0.744318125, 0.789772708333333, 0.832386458333333, 0.857954583333333, 0.877840833333333, 0.886363333333333,
    0.886363333333333, 0.886363333333333, 0.880681666666666, 0.875, 0.863636666666666, 0.869318333333333,
    0.877840833333333, 0.892045416666666, 0.911931666666666, 0.920454166666666, 0.931818333333333, 0.931818333333333,
    0.92613625, 0.920454166666666, 0.920454166666666, 0.920454166666666, 0.92613625, 0.940340833333333,
    0.957386666666666, 0.971590833333333, 0.982954583333333, 0.9914775, 1, 1, 0.997159166666666,
  ],
  [
    0.293103128550765, 0.362068892852446, 0.431034446426223, 0.5, 0.568965342845871, 0.635057199982467,
    0.698275571409786, 0.752873274981371, 0.798850310697222, 0.839080374994521, 0.867815864270999, 0.887930685691743,
    0.899424839256754, 0.908045928568297, 0.899424839256754, 0.893677657110296, 0.885057199982467, 0.873563257145361,
    0.873563257145361, 0.885057199982467, 0.899424839256754, 0.91379289998685, 0.925286842823956, 0.931033814242509,
    0.931033814242509, 0.931033814242509, 0.931033814242509, 0.925286842823956, 0.928160328533232, 0.93390751067969,
    0.94540208570051, 0.959769935702701, 0.974137785704893, 0.988505214251275, 0.997126303562818, 1, 1,
    0.997126303562818,
  ],
  [
    0.294117545559408, 0.364706049826976, 0.435294122722029, 0.505882195617082, 0.576470484198393, 0.647058772779704,
    0.708823552249133, 0.767647018223763, 0.817646975086511, 0.855882325028837, 0.888235263667822, 0.908823379700127,
    0.92058798662055, 0.929411927104946, 0.923529300115348, 0.917646673125751, 0.905882281891585, 0.897058988465961,
    0.89411789065742, 0.902941184083044, 0.914705575317209, 0.926470613610147, 0.941176318339112, 0.949999611764736,
    0.952940709573277, 0.949999611764736, 0.941176318339112, 0.941176318339112, 0.941176318339112, 0.944117416147653,
    0.955882023068076, 0.970588159169556, 0.985294295271035, 0.997058902191458, 1, 1, 1, 1,
  ],
  [
    0.29761885015125, 0.369047671012829, 0.440476055366644, 0.51190443972046, 0.580356932398042, 0.648809425075625,
    0.714285371315329, 0.767856877834572, 0.824404276030049, 0.863095014644835, 0.892856768707631, 0.913690192980082,
    0.928570742630657, 0.934523398998651, 0.931547070814654, 0.928570742630657, 0.916666302910197, 0.907737973119852,
    0.904761863189737, 0.910714083049967, 0.922618522770427, 0.934523398998651, 0.946428275226874, 0.952380495087105,
    0.952380495087105, 0.952380495087105, 0.946428275226874, 0.940476055366644, 0.940476055366644, 0.943452165296759,
    0.95535660501722, 0.970237591175559, 0.982142467403782, 0.991071015448009, 1, 1, 1, 1,
  ],
  [
    0.301204787342134, 0.367469858228089, 0.439758865824717, 0.512048315188484, 0.584337543668681, 0.653614582909973,
    0.716867464557022, 0.777108598732305, 0.825301196835533, 0.867470079111659, 0.89759042531573, 0.921687055692699,
    0.933734929114044, 0.939758865824717, 0.939758865824717, 0.939758865824717, 0.927710992403372, 0.921687055692699,
    0.915663118982027, 0.924699024048036, 0.933734929114044, 0.942771055063623, 0.95180762278034, 0.963855496201686,
    0.963855496201686, 0.957831559491013, 0.95180762278034, 0.939758865824717, 0.939758865824717, 0.945783244302529,
    0.957831559491013, 0.969879432912358, 0.981927748100843, 0.990964094933991, 1, 1, 1, 1,
  ],
  [
    0.304877917906029, 0.378048922268467, 0.451219479476507, 0.518292639302009, 0.591463420087248, 0.661585278604019,
    0.725609516161054, 0.786585278604019, 0.835365575550312, 0.871951077731532, 0.902438958953014, 0.923780520523492,
    0.939024237557034, 0.951219032322108, 0.951219032322108, 0.945121634939571, 0.939024237557034, 0.932926840174497,
    0.92682944279196, 0.935975538865766, 0.942072936248303, 0.951219032322108, 0.96341472139598, 0.96341472139598,
    0.96341472139598, 0.96341472139598, 0.957316876859044, 0.951219032322108, 0.951219032322108, 0.951219032322108,
    0.960365799127512, 0.972560817469785, 0.984755612234859, 0.993902155463064, 1, 1, 1, 1,
  ],
  [
    0.308642020017292, 0.382716412640499, 0.456790352588622, 0.530864292536745, 0.601852120103753, 0.66975338261456,
    0.734568080069168, 0.79629666514266, 0.845679216328895, 0.882716412640498, 0.913580705177244, 0.935185755220475,
    0.947531336432648, 0.950617675151306, 0.956790352588622, 0.950617675151306, 0.950617675151306, 0.938272320276675,
    0.938272320276675, 0.941358658995332, 0.950617675151306, 0.962963030025938, 0.969136160138337, 0.975309290250736,
    0.975309290250736, 0.972222725194537, 0.962963030025938, 0.953704013869964, 0.950617675151306, 0.956790352588622,
    0.962963030025938, 0.975309290250736, 0.987654645125368, 0.996913661281342, 1, 1, 1, 1,
  ],
  [
    0.312499942708328, 0.38750024062502, 0.46250008020834, 0.53749991979166, 0.612499988541665, 0.678125077343756,
    0.743749936979161, 0.806250108854175, 0.85312500859375, 0.89062492838541, 0.918750326562527, 0.940624974218747,
    0.95312532942711, 0.96250008020834, 0.96250008020834, 0.96250008020834, 0.950000412500034, 0.950000412500034,
    0.950000412500034, 0.950000412500034, 0.956250246354187, 0.965624997135416, 0.974999747916645, 0.98125004010417,
    0.987500332291694, 0.974999747916645, 0.974999747916645, 0.96250008020834, 0.96250008020834, 0.96250008020834,
    0.974999747916645, 0.98125004010417, 0.99062524921877, 1, 1, 1, 1, 1,
  ],
  [
    0.306250108854175, 0.381249948437495, 0.456249788020815, 0.531250085937507, 0.606250154687513, 0.674999931249994,
    0.740625020052085, 0.793750211979184, 0.843750028645835, 0.884374865364572, 0.912500263541688, 0.931249994270832,
    0.94375012031251, 0.950000412500034, 0.950000412500034, 0.950000412500034, 0.940624974218747, 0.937499828124985,
    0.937499828124985, 0.937499828124985, 0.950000412500034, 0.959375163281263, 0.968749914062492, 0.974999747916645,
    0.974999747916645, 0.974999747916645, 0.968749914062493, 0.96250008020834, 0.96250008020834, 0.96250008020834,
    0.968749914062492, 0.978124894010408, 0.987500332291694, 0.996875083072923, 1, 1, 0.993750166145847,
    0.987500332291694,
  ],
  [
    0.310126588153606, 0.379746823692787, 0.455696002777383, 0.531645645996882, 0.607594825081478, 0.674050588847952,
    0.740506120546973, 0.791139061315005, 0.841772002083037, 0.879746359557883, 0.908227533782058, 0.930379764460819,
    0.943037651551649, 0.949366595097064, 0.949366595097064, 0.949366595097064, 0.936708708006234, 0.936708708006234,
    0.936708708006234, 0.936708708006234, 0.949366595097064, 0.962025410457702, 0.971518825775824, 0.977847769321239,
    0.987341184639362, 0.981012241093947, 0.974683297548532, 0.971518825775824, 0.962025410457702, 0.968354354003117,
    0.974683297548532, 0.984176712866655, 0.990505888479522, 1, 1, 1, 0.99683529615984, 0.987341184639362,
  ],
  [
    0.307692199211008, 0.384615601577983, 0.461538533859327, 0.532051378479103, 0.605769266929663, 0.676282346592255,
    0.740384868507647, 0.801282346592255, 0.839743812732927, 0.878205513916415, 0.903846334648319, 0.926282346592255,
    0.935898183213054, 0.94871835853619, 0.94871835853619, 0.939103227043838, 0.935898183213054, 0.935898183213054,
    0.935898183213054, 0.935898183213054, 0.94871835853619, 0.961538533859327, 0.974359649353726, 0.983974780846079,
    0.987179824676863, 0.987179824676863, 0.97756469318451, 0.974359649353726, 0.974359649353726, 0.974359649353726,
    0.974359649353726, 0.983974780846079, 0.990384868507647, 1, 1, 1, 0.993589912338431, 0.987179824676863,
  ],
  [
    0.308441428571428, 0.383117142857142, 0.457792142857142, 0.532467619047619, 0.607142857142857, 0.675324761904761,
    0.740259523809523, 0.798701428571428, 0.840909047619047, 0.876623333333333, 0.902597142857142, 0.91883119047619,
    0.935064761904761, 0.935064761904761, 0.935064761904761, 0.935064761904761, 0.935064761904761, 0.925324761904761,
    0.931818095238095, 0.935064761904761, 0.94805238095238, 0.964285714285714, 0.974025714285714, 0.987013333333333,
    0.987013333333333, 0.987013333333333, 0.983766428571428, 0.974025714285714, 0.974025714285714, 0.974025714285714,
    0.974025714285714, 0.987013333333333, 0.990259999999999, 1, 1, 1, 0.993506666666666, 0.987013333333333,
  ],
  [
    0.302631604339787, 0.381578769621484, 0.453947165281696, 0.529605186980636, 0.605262726223604, 0.67434197830106,
    0.740131363111802, 0.796052352262332, 0.838815560941908, 0.874999517544028, 0.901315560941908, 0.917762484995619,
    0.930920747922544, 0.934210373961272, 0.934210373961272, 0.934210373961272, 0.930920747922544, 0.921051869806361,
    0.927631121883816, 0.934210373961272, 0.94736791320424, 0.963815802169893, 0.976973341412862, 0.986841495845089,
    0.990131121883816, 0.986841495845089, 0.986841495845089, 0.983552111034346, 0.97368395660212, 0.97368395660212,
    0.983552111034346, 0.986841495845089, 0.996710373961272, 1, 1, 1, 1, 0.986841495845089,
  ],
  [
    0.29934197830106, 0.371710373961272, 0.447368395660211, 0.519736550092438, 0.592105186980636, 0.664473582640848,
    0.73026296745159, 0.782894330563392, 0.828947165281696, 0.86184197830106, 0.888157539242968, 0.90460494575265,
    0.917762484995619, 0.921051869806361, 0.921051869806361, 0.921051869806361, 0.921051869806361, 0.921051869806361,
    0.921051869806361, 0.924341495845089, 0.937499758772014, 0.950657539242968, 0.963815802169893, 0.97368395660212,
    0.986841495845089, 0.986841495845089, 0.980262726223604, 0.97368395660212, 0.97368395660212, 0.97368395660212,
    0.97368395660212, 0.986841495845089, 0.986841495845089, 0.986841495845089, 0.996710373961272, 0.986841495845089,
    0.986841495845089, 0.980262726223604,
  ],
  [
    0.296666656888886, 0.373333499555599, 0.446666607999984, 0.520000205333388, 0.593333313777772, 0.663333421333356,
    0.726666793777811, 0.780000185777827, 0.830000332444533, 0.86666700888898, 0.889999970666658, 0.910000420444556,
    0.920000156444486, 0.929999892444415, 0.933333137777725, 0.929999892444415, 0.920000156444486, 0.920000156444486,
    0.920000156444486, 0.926666647111105, 0.936666627555545, 0.950000342222313, 0.963333568000062, 0.973334037333521,
    0.98666701866676, 0.98666701866676, 0.98666701866676, 0.973334037333521, 0.973334037333521, 0.973334037333521,
    0.98333377333345, 0.98666701866676, 0.98666701866676, 0.99666675466669, 1, 0.98666701866676, 0.98333377333345,
    0.98666701866676,
  ],
  [
    0.304794290048269, 0.380136721398757, 0.452054588102927, 0.530821584099733, 0.606164015450221, 0.678081882154391,
    0.746574933070806, 0.801369474260514, 0.849314886157586, 0.883561286045074, 0.91095830549849, 0.931506697942147,
    0.945204956527417, 0.945204956527417, 0.945204956527417, 0.945204956527417, 0.945204956527417, 0.938355827234782,
    0.931506697942147, 0.945204956527417, 0.948629521173734, 0.962328030900442, 0.972602478263708, 0.986300736848978,
    0.993150368424489, 1, 0.989725552636733, 0.986300736848978, 0.986300736848978, 0.986300736848978, 0.993150368424489,
    1, 1, 1, 1, 1, 1, 0.993150368424489,
  ],
  [
    0.309027742412544, 0.385416645447526, 0.458333418209892, 0.538194451517491, 0.611111224279856, 0.684027997042221,
    0.753472384902293, 0.812500127314838, 0.861111478909533, 0.89583354552473, 0.923611096965018, 0.940972512217132,
    0.951389157664658, 0.958333418209892, 0.958333418209892, 0.958333418209892, 0.944444897119425, 0.944444897119425,
    0.944444897119425, 0.944444897119425, 0.947917027392042, 0.958333418209892, 0.972221939300359, 0.986111478909533,
    0.986111478909533, 0.986111478909533, 0.986111478909533, 0.986111478909533, 0.986111478909533, 0.986111478909533,
    0.986111478909533, 1, 1, 1, 1, 1, 1, 0.989583609182149,
  ],
  [
    0.309859096740075, 0.390844903127717, 0.464788645110113, 0.545774451497755, 0.619718193480151, 0.693661935462547,
    0.764084387026406, 0.823943483766482, 0.869718193480151, 0.904929548370037, 0.93309832242285, 0.950703999867793,
    0.957746580704867, 0.957746580704867, 0.957746580704867, 0.957746580704867, 0.950703999867793, 0.943661419030719,
    0.943661419030719, 0.943661419030719, 0.95422529028633, 0.96126761290749, 0.971830709515359, 0.985914838325851,
    0.985914838325851, 0.985914838325851, 0.985914838325851, 0.985914838325851, 0.985914838325851, 0.985914838325851,
    0.989436128744389, 1, 1, 1, 1, 1, 1, 0.985914838325851,
  ],
  [
    0.314285863945649, 0.392857329932061, 0.471428795918474, 0.550000261904886, 0.628571727891299, 0.703571858843742,
    0.771428795918474, 0.835714659864123, 0.878571989796185, 0.914286125850536, 0.939286256802979, 0.957142931972824,
    0.971429319728247, 0.971429319728247, 0.971429319728247, 0.957142931972824, 0.957142931972824, 0.942857591836948,
    0.942857591836948, 0.950000261904886, 0.957142931972824, 0.971429319728247, 0.982143324830154, 0.989285994898092, 1,
    1, 0.996428664966031, 0.985714659864123, 0.985714659864123, 0.989285994898092, 1, 1, 1, 1, 1, 1, 1,
    0.985714659864123,
  ],
  [
    0.314285863945649, 0.389285994898092, 0.467857460884505, 0.546428926870917, 0.62500039285733, 0.696428926870917,
    0.764286125850536, 0.821428795918474, 0.875000654762216, 0.910714790816567, 0.93571492176901, 0.953571596938855,
    0.957142931972824, 0.967857722789391, 0.957142931972824, 0.953571596938855, 0.942857591836948, 0.942857591836948,
    0.942857591836948, 0.942857591836948, 0.957142931972824, 0.967857722789391, 0.978571989796185, 0.985714659864123,
    0.985714659864123, 0.985714659864123, 0.985714659864123, 0.985714659864123, 0.985714659864123, 0.985714659864123,
    0.985714659864123, 0.992857329932062, 1, 1, 0.985714659864123, 0.985714659864123, 0.982143324830154,
    0.971429319728247,
  ],
  [
    0.318840610516001, 0.391304313169498, 0.467391373661002, 0.547101526290002, 0.623188321080997, 0.6992753815725,
    0.764492899951004, 0.829710152629, 0.876811678919002, 0.913043663096006, 0.942029197297507, 0.9601449236855,
    0.97101420009799, 0.97101420009799, 0.97101420009799, 0.956521831548003, 0.952898739410506, 0.942029462998015,
    0.942029462998015, 0.956521831548003, 0.963768015822996, 0.978260915774001, 0.985507631450012, 1, 1, 1, 1,
    0.992753815725006, 0.985507631450012, 0.985507631450012, 0.996376907862503, 1, 0.996376907862503, 0.985507631450012,
    0.985507631450012, 0.981884273612007, 0.97101420009799, 0.97101420009799,
  ],
  [
    0.315217518378504, 0.391304313169498, 0.463768015822996, 0.5398550763145, 0.615942136806003, 0.688405839459501,
    0.757246449975502, 0.818840610516001, 0.862318778967997, 0.905797478821012, 0.934782747322004, 0.952898739410506,
    0.967391107960493, 0.97101420009799, 0.963768015822996, 0.956521831548003, 0.942029462998015, 0.942029462998015,
    0.942029462998015, 0.945652555135512, 0.956521831548003, 0.97101420009799, 0.985507631450012, 0.992753815725006, 1,
    1, 0.992753815725006, 0.985507631450012, 0.985507631450012, 0.985507631450012, 0.985507631450012, 0.974637557935995,
    0.97101420009799, 0.97101420009799, 0.960144923685499, 0.956521831548003, 0.956521831548003, 0.956521831548003,
  ],
  [
    0.316176304065792, 0.393382261750315, 0.466911578359339, 0.540440894968364, 0.61764712226065, 0.687499797794177,
    0.757352742935467, 0.816176304065792, 0.867646583045122, 0.911764658304512, 0.941176169261911, 0.963235206891605,
    0.970587949827073, 0.970587949827073, 0.970587949827073, 0.966911578359339, 0.955882463956138, 0.944852540729644,
    0.941175899654147, 0.955882463956138, 0.963235206891605, 0.977940692762541, 0.988970076773506, 1, 1, 1, 1, 1,
    0.988970076773506, 0.985293435698009, 0.985293435698009, 0.974264321294807, 0.970587949827073, 0.963235206891605,
    0.955882463956138, 0.955882463956138, 0.955882463956138, 0.955882463956138,
  ],
  [
    0.316176304065792, 0.389705620674817, 0.463235206891605, 0.53676452350063, 0.610294109717418, 0.680146785250945,
    0.742646987456768, 0.808823291522561, 0.860293840109654, 0.900735274293546, 0.933823156718679, 0.955882463956138,
    0.970587949827073, 0.970587949827073, 0.970587949827073, 0.955882463956138, 0.948529181805142, 0.941175899654147,
    0.941175899654147, 0.941175899654147, 0.955882463956138, 0.970587949827073, 0.985293435698009, 0.996323358924502, 1,
    1, 1, 0.992646717849004, 0.985293435698009, 0.985293435698009, 0.970587949827073, 0.970587949827073,
    0.955882463956138, 0.955882463956138, 0.941175899654147, 0.941175899654147, 0.941175899654147, 0.941175899654147,
  ],
  [
    0.312499932598059, 0.386029249207083, 0.459558835423871, 0.533087882425132, 0.602941097174187, 0.672793772707713,
    0.742646987456768, 0.801470278979329, 0.856617199034157, 0.897058633218049, 0.930146785250945, 0.948529181805142,
    0.955882463956138, 0.966911578359339, 0.955882463956138, 0.948529181805142, 0.941175899654147, 0.930146785250945,
    0.926470413783211, 0.941175899654147, 0.948529181805142, 0.963235206891605, 0.977940692762541, 0.988970076773506, 1,
    1, 1, 0.985293435698009, 0.985293435698009, 0.974264321294807, 0.970587949827073, 0.963235206891605,
    0.955882463956138, 0.941175899654147, 0.937499528186413, 0.926470413783211, 0.926470413783211, 0.926470413783211,
  ],
  [
    0.313432811316541, 0.388059905695484, 0.45895548117631, 0.533582301923304, 0.608209396302247, 0.679104698151123,
    0.750000273631949, 0.813433084948491, 0.865671886834581, 0.90671695292217, 0.936567735947357, 0.955223962278194,
    0.966418518972544, 0.970150037870661, 0.955223962278194, 0.951492717012026, 0.940298981213525, 0.940298981213525,
    0.940298981213525, 0.940298981213525, 0.955223962278194, 0.970150037870661, 0.98507501893533, 1, 1, 1, 1, 1,
    0.98507501893533, 0.98507501893533, 0.977612528402996, 0.970150037870661, 0.95895548117631, 0.951492717012026,
    0.940298981213525, 0.93283649068119, 0.925374000148855, 0.925374000148855,
  ],
  [
    0.305970320784207, 0.3805971415312, 0.455224235910143, 0.529850783025187, 0.60447787740413, 0.675373452884956,
    0.738806264201498, 0.809701839682324, 0.858209122670297, 0.895522669859769, 0.925373726516906, 0.940298981213525,
    0.955223962278194, 0.951492717012026, 0.940298981213525, 0.93283649068119, 0.925374000148855, 0.925374000148855,
    0.925374000148855, 0.929105245415023, 0.944030226479692, 0.958955481176311, 0.973881283136829, 0.98507501893533,
    0.996268754733832, 1, 0.98507501893533, 0.98507501893533, 0.98507501893533, 0.970150037870661, 0.970150037870661,
    0.962687000074428, 0.955223962278194, 0.940298981213525, 0.925374000148855, 0.917910962352621, 0.910447924556387,
    0.910447924556387,
  ],
  [
    0.310606111111111, 0.382575555555555, 0.458333333333333, 0.534090833333333, 0.606060555555555, 0.681818333333333,
    0.75, 0.810606111111111, 0.867424166666666, 0.901515, 0.931818333333333, 0.94697, 0.954545555555555,
    0.954545555555555, 0.939394444444444, 0.935606388888888, 0.924242222222222, 0.924242222222222, 0.924242222222222,
    0.931818333333333, 0.94697, 0.962121111111111, 0.981060833333333, 0.992424444444444, 1, 1, 1, 0.988636666666666,
    0.984848888888888, 0.984848888888888, 0.984848888888888, 0.969696666666666, 0.962121111111111, 0.94697,
    0.931818333333333, 0.920454444444444, 0.909091111111111, 0.909091111111111,
  ],
  [
    0.311538487573953, 0.388461173964646, 0.461538374753487, 0.53846106114418, 0.615384311637205, 0.688461230374879,
    0.761538149112554, 0.823076636686508, 0.873076411045574, 0.915384085996272, 0.934614898619528, 0.953846275345117,
    0.953846275345117, 0.953846275345117, 0.946153611834415, 0.938460948323713, 0.926922799211159, 0.923076749506974,
    0.923076749506974, 0.938460948323713, 0.949999943589766, 0.969230474161856, 0.984614672978595, 0.996153668244648, 1,
    1, 1, 1, 1, 1, 0.992307336489297, 0.984614672978595, 0.969230474161856, 0.953846275345117, 0.938460948323713,
    0.923076749506974, 0.911537754240921, 0.90769142248557,
  ],
  [
    0.311538487573953, 0.388461173964646, 0.461538374753487, 0.53846106114418, 0.615384311637205, 0.684615180670695,
    0.761538149112554, 0.823076636686508, 0.865384311637205, 0.907691704536736, 0.930768848915344, 0.942307280079064,
    0.953846275345117, 0.942307280079064, 0.938460948323713, 0.926922799211159, 0.923076749506974, 0.911537754240921,
    0.919230417751623, 0.926922799211159, 0.942307280079064, 0.957692325049302, 0.969230474161856, 0.984614672978595,
    0.984614672978595, 0.996153668244648, 0.996153668244648, 0.984614672978595, 0.984614672978595, 0.984614672978595,
    0.984614672978595, 0.98076862327441, 0.965384424457672, 0.946153611834415, 0.930768848915344, 0.915384085996272,
    0.90769142248557, 0.90769142248557,
  ],
  [
    0.316406505127032, 0.390625017903651, 0.468750250651119, 0.542968763427738, 0.617187849121202, 0.695312795410248,
    0.757812580566431, 0.82031293863946, 0.875000143229211, 0.906250179036514, 0.933594067789812, 0.949218942464252,
    0.953125089518257, 0.953125089518257, 0.937500501302239, 0.933594067789812, 0.921874767252531, 0.921874767252531,
    0.921874767252531, 0.929687634277385, 0.941406648356244, 0.960937956543111, 0.972656970621969, 0.984375411783982,
    0.992187705891991, 1, 1, 1, 1, 1, 0.992187705891991, 0.984375411783982, 0.972656970621969, 0.957031523030684,
    0.937500501302239, 0.921874767252531, 0.906250179036514, 0.906250179036514,
  ],
  [
    0.317460548416873, 0.392857101284962, 0.468253945158312, 0.5476189367599, 0.62301578063325, 0.694444476778362,
    0.769841029646452, 0.829364991601588, 0.876983928361489, 0.912698421936674, 0.940475747039603, 0.952381063240099,
    0.952381063240099, 0.952381063240099, 0.940475747039603, 0.932539160577866, 0.920634717393151, 0.920634717393151,
    0.920634717393151, 0.928571012849628, 0.944444185773102, 0.960317358696575, 0.97222209288655, 0.984127409087046,
    0.988095556815284, 1, 1, 1, 1, 1, 0.996031852271761, 0.984127409087046, 0.976190531620049, 0.960317358696575,
    0.944444185773102, 0.928571012849628, 0.920634717393151, 0.920634717393151,
  ],
  [
    0.31854829647941, 0.395160889698446, 0.471774074315013, 0.548386963232815, 0.620967408082038, 0.701612445369653,
    0.76612859347925, 0.830644741588848, 0.883064148890242, 0.919354371314854, 0.943547557232496, 0.951611853972121,
    0.951611853972121, 0.951611853972121, 0.939515408862683, 0.931451112123058, 0.919354667013619, 0.919354667013619,
    0.919354667013619, 0.927418963753245, 0.943547557232496, 0.959676742109278, 0.971773778616248, 0.983870223725686,
    0.983870223725686, 0.991935111862843, 0.987902667794264, 0.983870223725686, 0.983870223725686, 0.983870223725686,
    0.983870223725686, 0.983870223725686, 0.979838075355873, 0.967741630246435, 0.951611853972121, 0.939515408862683,
    0.93548326049287, 0.93548326049287,
  ],
  [
    0.325000122222195, 0.404166493518557, 0.479166615740752, 0.558333292592601, 0.637499969444451, 0.708333231481504,
    0.791666462963008, 0.845833200925955, 0.899999938888902, 0.94166640185191, 0.962499786111158, 0.979166004629776,
    0.979166004629776, 0.966666340740813, 0.958333231481504, 0.945833262037053, 0.933332681481626, 0.933332681481626,
    0.933332681481626, 0.945833262037052, 0.958333231481504, 0.974999450000122, 0.987499419444573, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0.991666279629715, 0.983332559259431, 0.970832895370467, 0.962499786111158, 0.950000122222195,
    0.950000122222195,
  ],
  [
    0.326271370774785, 0.398305253279804, 0.483051289859485, 0.559322349899632, 0.635593409939779, 0.716102268984332,
    0.788135840754712, 0.851695678924112, 0.90254305228421, 0.940678271569645, 0.966102579718971, 0.983051289859485,
    0.983051289859485, 0.974576934789228, 0.966102579718971, 0.949152626639902, 0.944915449104773, 0.932203916499387,
    0.944915449104773, 0.949152626639902, 0.966102579718971, 0.978814112324357, 0.991525644929742, 1, 1, 1, 1, 1,
    0.987288467394614, 0.983051289859485, 0.983051289859485, 0.983051289859485, 0.983051289859485, 0.974576934789228,
    0.966102579718971, 0.949152626639902, 0.949152626639902, 0.936441094034516,
  ],
  [
    0.317797015704528, 0.398305253279804, 0.474576624054589, 0.550847684094736, 0.631356232404651, 0.703390425644308,
    0.771186819879559, 0.838983524849449, 0.885593720674418, 0.92372956142913, 0.953390114909669, 0.966102579718971,
    0.966102579718971, 0.966102579718971, 0.949152626639902, 0.940678271569645, 0.932203916499387, 0.932203916499387,
    0.932203916499387, 0.944915449104773, 0.957627603179436, 0.966102579718971, 0.983051289859485, 0.983051289859485, 1,
    0.983051289859485, 0.983051289859485, 0.974576934789228, 0.966102579718971, 0.966102579718971, 0.966102579718971,
    0.966102579718971, 0.953390114909669, 0.949152626639902, 0.932203916499387, 0.92372956142913, 0.915255206358873,
    0.915255206358873,
  ],
  [
    0.318965582639722, 0.400862172512893, 0.474137985533101, 0.556034575406273, 0.629310388426481, 0.698275971066203,
    0.775862014466898, 0.836206820253657, 0.883620934898958, 0.922413956599305, 0.948275971066203, 0.965516892588148,
    0.965516892588148, 0.961206662207662, 0.948275971066203, 0.939655510305231, 0.931035049544258, 0.931035049544258,
    0.935345279924744, 0.948275971066203, 0.961206662207662, 0.974137985533101, 0.982759078478055, 0.995689769619513, 1,
    0.987069308858541, 0.982759078478055, 0.974137985533101, 0.965516892588148, 0.965516892588148, 0.965516892588148,
    0.965516892588148, 0.956896431827176, 0.948275971066203, 0.935345279924744, 0.922413956599305, 0.913792863654352,
    0.913792863654352,
  ],
  [
    0.324561318867373, 0.394736791320424, 0.48245597106808, 0.55263144352113, 0.627192762388504, 0.710526095721837,
    0.776315400123251, 0.833333011696019, 0.877192762388504, 0.916666345029352, 0.938596220375595, 0.947368556478869,
    0.947368556478869, 0.947368556478869, 0.934210052323958, 0.929823884272321, 0.929823884272321, 0.929823884272321,
    0.929823884272321, 0.942982388427232, 0.956140249307515, 0.969297788550483, 0.982455327793452, 0.991227663896726, 1,
    0.986841495845089, 0.982455327793452, 0.978069481379129, 0.96491194213616, 0.96491194213616, 0.96491194213616,
    0.96491194213616, 0.96491194213616, 0.956140249307514, 0.942982388427232, 0.929823884272321, 0.916666345029352,
    0.912280498615029,
  ],
  [
    0.336363666666666, 0.409091, 0.481818, 0.554545666666666, 0.627272666666666, 0.7, 0.772727333333333, 0.831818,
    0.881818, 0.913636333333333, 0.931818666666666, 0.945454666666666, 0.945454666666666, 0.940909333333333,
    0.927273333333333, 0.918182, 0.909090666666666, 0.909090666666666, 0.922727666666666, 0.936364, 0.959090666666666,
    0.977273, 0.990909333333333, 1, 1, 1, 0.986364, 0.981818666666666, 0.981818666666666, 0.981818666666666,
    0.981818666666666, 0.981818666666666, 0.981818666666666, 0.963636, 0.954545333333333, 0.940909333333333,
    0.927273333333333, 0.927273333333333,
  ],
  [
    0.330188992524148, 0.405660436098277, 0.481132225584487, 0.556604015070697, 0.632075804556907, 0.702830391005179,
    0.764151263201734, 0.816038075234493, 0.863208030141394, 0.896226375934481, 0.915094496262074, 0.92452890233795,
    0.92452890233795, 0.910377293224135, 0.900943233060339, 0.886792661682765, 0.886792661682765, 0.886792661682765,
    0.900943233060339, 0.92452890233795, 0.948113533879321, 0.971698857244851, 0.985849428622426, 1, 1,
    0.985849428622425, 0.981132571496567, 0.966982000118993, 0.962265142993135, 0.962265142993135, 0.962265142993135,
    0.962265142993135, 0.962265142993135, 0.962265142993135, 0.952830736917259, 0.943396330841382, 0.938679473715524,
    0.92452890233795,
  ],
  [
    0.31854829647941, 0.395160889698446, 0.471774074315013, 0.548386963232815, 0.620967408082038, 0.701612445369653,
    0.76612859347925, 0.830644741588848, 0.883064148890242, 0.919354371314854, 0.943547557232496, 0.951611853972121,
    0.951611853972121, 0.951611853972121, 0.939515408862683, 0.931451112123058, 0.919354667013619, 0.919354667013619,
    0.919354667013619, 0.927418963753245, 0.943547557232496, 0.959676742109278, 0.971773778616248, 0.983870223725686,
    0.983870223725686, 0.991935111862843, 0.987902667794264, 0.983870223725686, 0.983870223725686, 0.983870223725686,
    0.983870223725686, 0.983870223725686, 0.979838075355873, 0.967741630246435, 0.951611853972121, 0.939515408862683,
    0.93548326049287, 0.93548326049287,
  ],
  [
    0.325000122222195, 0.404166493518557, 0.479166615740752, 0.558333292592601, 0.637499969444451, 0.708333231481504,
    0.791666462963008, 0.845833200925955, 0.899999938888902, 0.94166640185191, 0.962499786111158, 0.979166004629776,
    0.979166004629776, 0.966666340740813, 0.958333231481504, 0.945833262037053, 0.933332681481626, 0.933332681481626,
    0.933332681481626, 0.945833262037052, 0.958333231481504, 0.974999450000122, 0.987499419444573, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0.991666279629715, 0.983332559259431, 0.970832895370467, 0.962499786111158, 0.950000122222195,
    0.950000122222195,
  ],
  [
    0.326271370774785, 0.398305253279804, 0.483051289859485, 0.559322349899632, 0.635593409939779, 0.716102268984332,
    0.788135840754712, 0.851695678924112, 0.90254305228421, 0.940678271569645, 0.966102579718971, 0.983051289859485,
    0.983051289859485, 0.974576934789228, 0.966102579718971, 0.949152626639902, 0.944915449104773, 0.932203916499387,
    0.944915449104773, 0.949152626639902, 0.966102579718971, 0.978814112324357, 0.991525644929742, 1, 1, 1, 1, 1,
    0.987288467394614, 0.983051289859485, 0.983051289859485, 0.983051289859485, 0.983051289859485, 0.974576934789228,
    0.966102579718971, 0.949152626639902, 0.949152626639902, 0.936441094034516,
  ],
  [
    0.317797015704528, 0.398305253279804, 0.474576624054589, 0.550847684094736, 0.631356232404651, 0.703390425644308,
    0.771186819879559, 0.838983524849449, 0.885593720674418, 0.92372956142913, 0.953390114909669, 0.966102579718971,
    0.966102579718971, 0.966102579718971, 0.949152626639902, 0.940678271569645, 0.932203916499387, 0.932203916499387,
    0.932203916499387, 0.944915449104773, 0.957627603179436, 0.966102579718971, 0.983051289859485, 0.983051289859485, 1,
    0.983051289859485, 0.983051289859485, 0.974576934789228, 0.966102579718971, 0.966102579718971, 0.966102579718971,
    0.966102579718971, 0.953390114909669, 0.949152626639902, 0.932203916499387, 0.92372956142913, 0.915255206358873,
    0.915255206358873,
  ],
  [
    0.318965582639722, 0.400862172512893, 0.474137985533101, 0.556034575406273, 0.629310388426481, 0.698275971066203,
    0.775862014466898, 0.836206820253657, 0.883620934898958, 0.922413956599305, 0.948275971066203, 0.965516892588148,
    0.965516892588148, 0.961206662207662, 0.948275971066203, 0.939655510305231, 0.931035049544258, 0.931035049544258,
    0.935345279924744, 0.948275971066203, 0.961206662207662, 0.974137985533101, 0.982759078478055, 0.995689769619513, 1,
    0.987069308858541, 0.982759078478055, 0.974137985533101, 0.965516892588148, 0.965516892588148, 0.965516892588148,
    0.965516892588148, 0.956896431827176, 0.948275971066203, 0.935345279924744, 0.922413956599305, 0.913792863654352,
    0.913792863654352,
  ],
  [
    0.324561318867373, 0.394736791320424, 0.48245597106808, 0.55263144352113, 0.627192762388504, 0.710526095721837,
    0.776315400123251, 0.833333011696019, 0.877192762388504, 0.916666345029352, 0.938596220375595, 0.947368556478869,
    0.947368556478869, 0.947368556478869, 0.934210052323958, 0.929823884272321, 0.929823884272321, 0.929823884272321,
    0.929823884272321, 0.942982388427232, 0.956140249307515, 0.969297788550483, 0.982455327793452, 0.991227663896726, 1,
    0.986841495845089, 0.982455327793452, 0.978069481379129, 0.96491194213616, 0.96491194213616, 0.96491194213616,
    0.96491194213616, 0.96491194213616, 0.956140249307514, 0.942982388427232, 0.929823884272321, 0.916666345029352,
    0.912280498615029,
  ],
  [
    0.336363666666666, 0.409091, 0.481818, 0.554545666666666, 0.627272666666666, 0.7, 0.772727333333333, 0.831818,
    0.881818, 0.913636333333333, 0.931818666666666, 0.945454666666666, 0.945454666666666, 0.940909333333333,
    0.927273333333333, 0.918182, 1, 1, 1, 0.994047774943384, 0.976191099773539, 0.958334424603694, 0.952382199547079, 1,
    1, 1, 0.986364, 0.981818666666666, 0.981818666666666, 0.981818666666666, 0.981818666666666, 0.981818666666666,
    0.981818666666666, 0.963636, 0.954545333333333, 0.940909333333333, 0.927273333333333, 0.927273333333333,
  ],
  [
    0.369047556689312, 0.45238132653079, 0.529761998299364, 0.601190445011323, 0.67261976473957, 0.738095549886769,
    0.797619109977354, 0.845238656462852, 0.892857329932061, 0.922619328231426, 0.952382199547079, 0.892857329932061,
    0.922619328231426, 0.952382199547079, 0.976191099773539, 0.994047774943384, 1, 1, 1, 1, 0.994047774943384,
    0.976191099773539, 0.958334424603694, 0.952382199547079, 0.9345242148528, 0.928571553288041, 0.928571553288041,
    0.880952006802542, 0.880952006802542, 0.880952006802542, 0.880952006802542, 0.898809991496821, 0.90476265306158,
    0.90476265306158, 0.90476265306158, 0.916667103174811, 0.928571553288041, 0.94047687641756,
  ],
  [
    0.369047556689312, 0.45238132653079, 0.529761998299364, 0.601190445011323, 0.67261976473957, 0.738095549886769,
    0.797619109977354, 0.845238656462852, 0.892857329932061, 0.922619328231426, 0.952382199547079, 0.976191099773539,
    0.994047774943384, 1, 1, 1, 1, 0.994047774943384, 0.976191099773539, 0.958334424603694, 0.952382199547079,
    0.9345242148528, 0.928571553288041, 0.928571553288041, 0.910714878118196, 0.90476265306158, 0.90476265306158,
    0.880952006802542, 0.880952006802542, 0.880952006802542, 0.880952006802542, 0.898809991496821, 0.90476265306158,
    0.90476265306158, 0.90476265306158, 0.916667103174811, 0.928571553288041, 0.94047687641756,
  ],
  [
    0.360464660176954, 0.43604655128896, 0.511627589688316, 0.587208628087672, 0.656976724355519, 0.726743967910717,
    0.784883195421274, 0.837208628087672, 0.877906471065754, 0.912790092843353, 0.93604655128896, 0.959301730665593,
    0.976743115198068, 0.982557336398551, 1, 1, 0.976743115198068, 0.976743115198068, 0.953487935821435,
    0.953487935821435, 0.930232756444802, 0.930232756444802, 0.930232756444802, 0.924418535244319, 0.90697587164287,
    0.90697587164287, 0.883720692266237, 0.872092249865271, 0.860463807464305, 0.84302242293183, 0.837208628087672,
    0.837208628087672, 0.837208628087672, 0.837208628087672, 0.837208628087672, 0.837208628087672, 0.837208628087672,
    0.860463807464305,
  ],
  [
    0.371951077731532, 0.451219479476507, 0.524390036684546, 0.603658438429522, 0.67682944279196, 0.737804758080527,
    0.804877917906029, 0.853658438429522, 0.902438511798616, 0.939024237557034, 0.96341472139598, 0.981707807852389, 1,
    1, 1, 1, 1, 0.993902602617463, 0.975610410469852, 0.975610410469852, 0.951219032322108, 0.951219032322108,
    0.951219032322108, 0.951219032322108, 0.945121634939571, 0.92682944279196, 0.920731598255024, 0.902438064644217,
    0.878048475114069, 0.871951077731532, 0.853658885583921, 0.853658885583921, 0.853658885583921, 0.841463196510049,
    0.829267507436177, 0.829267507436177, 0.853658885583921, 0.853658885583921,
  ],
  [
    0.368749484375429, 0.443749713541905, 0.518749484375429, 0.593749255208953, 0.656249369792191, 0.724999770833524,
    0.78749942708381, 0.849999083334097, 0.88749942708381, 0.931248682292764, 0.956249369792191, 0.974998854167621,
    0.993749713541905, 1, 1, 1, 0.981249140625716, 0.974998854167621, 0.962499197917335, 0.949999541667048,
    0.949999541667048, 0.949999541667048, 0.949999541667048, 0.943749255208954, 0.92499839583467, 0.92499839583467,
    0.912498739584383, 0.899999083334097, 0.881249598958667, 0.874999770833524, 0.874999770833524, 0.868749484375429,
    0.849998625001145, 0.849998625001145, 0.849998625001145, 0.849998625001145, 0.849998625001145, 0.849998625001145,
  ],
  [
    0.365384398422016, 0.442308270874622, 0.512820645408767, 0.570512844619775, 0.641025689239551, 0.69871788845056,
    0.769231203155966, 0.820513314705406, 0.871794956169215, 0.916666980057087, 0.948717418364929, 0.974359649353726,
    0.980769737015294, 1, 1, 0.993589912338431, 0.974359649353726, 0.974359649353726, 0.948717418364929,
    0.948717418364929, 0.948717418364929, 0.948717418364929, 0.948717418364929, 0.923077067718655, 0.923077067718655,
    0.923077067718655, 0.897436717072381, 0.897436717072381, 0.897436717072381, 0.897436717072381, 0.871794486083584,
    0.871794486083584, 0.871794486083584, 0.871794486083584, 0.871794486083584, 0.871794486083584, 0.871794486083584,
    0.871794486083584,
  ],
  [
    0.36184197830106, 0.434210373961272, 0.499999999999999, 0.552631604339787, 0.618420747922544, 0.677631121883816,
    0.73684197830106, 0.802631121883816, 0.855263208679575, 0.907894330563392, 0.940789143582756, 0.967104222068694,
    0.980262243767633, 1, 1, 1, 0.980262243767633, 0.973682991690178, 0.973682991690178, 0.953946682825725,
    0.94736791320424, 0.94736791320424, 0.94736791320424, 0.940789143582756, 0.921052834718303, 0.921052834718303,
    0.901315078485937, 0.894735826408481, 0.894735826408481, 0.894735826408481, 0.894735826408481, 0.894735826408481,
    0.894735826408481, 0.868420747922544, 0.868420747922544, 0.868420747922544, 0.868420747922544, 0.874999517544028,
  ],
  [
    0.358108295593059, 0.432432686876294, 0.493243912832353, 0.554054643292471, 0.608108295593059, 0.66216293888553,
    0.722973173849706, 0.783784895301707, 0.837838547602295, 0.891892695398824, 0.932433182372236, 0.96621708668206,
    0.979730252009235, 1, 1, 1, 1, 0.979730252009236, 0.972973669345648, 0.972973669345648, 0.972973669345648,
    0.972973669345648, 0.959460504018472, 0.945947338691296, 0.939190260531766, 0.918919026053176, 0.918919026053176,
    0.898649278062412, 0.891892695398824, 0.891892695398824, 0.891892695398824, 0.891892695398824, 0.891892695398824,
    0.891892695398824, 0.87162294740806, 0.864866364744472, 0.864866364744472, 0.891892695398824,
  ],
  [
    0.368055484825089, 0.437499618055484, 0.500000509259353, 0.548610842335341, 0.597222703189389, 0.652777806069964,
    0.715277678755125, 0.777778060699641, 0.833333672839569, 0.888889284979497, 0.930555357510251, 0.958333418209892,
    0.979167218364299, 1, 1, 1, 1, 1, 1, 1, 1, 0.972222957819066, 0.972222957819066, 0.972222957819066,
    0.944443878600718, 0.944443878600718, 0.916666836419784, 0.916666836419784, 0.916666836419784, 0.916666836419784,
    0.916666836419784, 0.916666836419784, 0.909722575874551, 0.88888979423885, 0.88888979423885, 0.88888979423885,
    0.88888979423885, 0.88888979423885,
  ],
  [
    0.371428406802815, 0.44285660408194, 0.5, 0.55714234829961, 0.599999895238155, 0.657142243537765, 0.7142851156466,
    0.771427987755435, 0.828571383673495, 0.878570545578735, 0.921428616326504, 0.95714192925223, 0.971427254422521,
    0.99285681360563, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.971427254422521, 0.971427254422521, 0.94285660408194,
    0.94285660408194, 0.94285660408194, 0.94285660408194, 0.94285660408194, 0.94285660408194, 0.94285660408194,
    0.914285953741359, 0.914285953741359, 0.89285639455825, 0.885713208163881, 0.885713208163881, 0.885713208163881,
  ],
  [
    0.375, 0.441176375432451, 0.500000539216109, 0.551471412918755, 0.602941747405292, 0.647059331026926,
    0.705882955594474, 0.764706040945914, 0.823530204729572, 0.867648327567315, 0.911764832756731, 0.941177993080778,
    0.970588996540389, 0.970588996540389, 0.985294498270194, 1, 1, 1, 1, 1, 1, 1, 1, 0.985294498270194,
    0.970588996540389, 0.970588996540389, 0.948530743945681, 0.941177993080778, 0.963236245675486, 0.970588996540389,
    0.970588996540389, 0.955883494810584, 0.941177993080778, 0.933824702999767, 0.911764832756731, 0.911764832756731,
    0.882353829297121, 0.882353829297121,
  ],
  [
    0.378787777777777, 0.454545, 0.5, 0.553031111111111, 0.598484444444444, 0.651515555555555, 0.704545,
    0.757575555555555, 0.818181666666666, 0.863636666666666, 0.909091111111111, 0.939393333333333, 0.962121666666666,
    0.969697777777777, 0.969697777777777, 0.984848888888888, 1, 1, 1, 1, 1, 1, 1, 0.992424444444444, 0.969697777777777,
    0.969697777777777, 0.969697777777777, 0.969697777777777, 0.969697777777777, 0.969697777777777, 0.969697777777777,
    0.969697777777777, 0.969697777777777, 0.946969444444444, 0.939393333333333, 0.909091111111111, 0.909091111111111,
    0.909091111111111,
  ],
  [
    0.371212222222222, 0.439393333333333, 0.5, 0.545455, 0.590908888888888, 0.64394, 0.696969444444444,
    0.757575555555555, 0.80303, 0.856060555555555, 0.89394, 0.916666666666666, 0.939393333333333, 0.939393333333333,
    0.939393333333333, 0.954545555555555, 0.969697777777777, 0.969697777777777, 0.969697777777777, 0.984848888888888, 1,
    0.969697777777777, 0.969697777777777, 0.969697777777777, 0.939393333333333, 0.939393333333333, 0.939393333333333,
    0.939393333333333, 0.939393333333333, 0.969697777777777, 0.969697777777777, 0.969697777777777, 0.946969444444444,
    0.939393333333333, 0.924242222222222, 0.909091111111111, 0.909091111111111, 0.901515555555555,
  ],
  [
    0.316176304065792, 0.389705620674817, 0.463235206891605, 0.53676452350063, 0.610294109717418, 0.680146785250945,
    0.742646987456768, 0.808823291522561, 0.860293840109654, 0.900735274293546, 0.933823156718679, 0.955882463956138,
    0.970587949827073, 0.970587949827073, 0.970587949827073, 0.955882463956138, 0.948529181805142, 0.941175899654147,
    0.941175899654147, 0.941175899654147, 0.955882463956138, 0.970587949827073, 0.985293435698009, 0.996323358924502, 1,
    1, 1, 0.992646717849004, 0.985293435698009, 0.985293435698009, 0.970587949827073, 0.970587949827073,
    0.955882463956138, 0.955882463956138, 0.941175899654147, 0.941175899654147, 0.941175899654147, 0.941175899654147,
  ],
  [
    0.312499932598059, 0.386029249207083, 0.459558835423871, 0.533087882425132, 0.602941097174187, 0.672793772707713,
    0.742646987456768, 0.801470278979329, 0.856617199034157, 0.897058633218049, 0.930146785250945, 0.948529181805142,
    0.955882463956138, 0.966911578359339, 0.955882463956138, 0.948529181805142, 0.941175899654147, 0.930146785250945,
    0.926470413783211, 0.941175899654147, 0.948529181805142, 0.963235206891605, 0.977940692762541, 0.988970076773506, 1,
    1, 1, 0.985293435698009, 0.985293435698009, 0.974264321294807, 0.970587949827073, 0.963235206891605,
    0.955882463956138, 0.941175899654147, 0.937499528186413, 0.926470413783211, 0.926470413783211, 0.926470413783211,
  ],
  [
    0.313432811316541, 0.388059905695484, 0.45895548117631, 0.533582301923304, 0.608209396302247, 0.679104698151123,
    0.750000273631949, 0.813433084948491, 0.865671886834581, 0.90671695292217, 0.936567735947357, 0.955223962278194,
    0.966418518972544, 0.970150037870661, 0.955223962278194, 0.951492717012026, 0.940298981213525, 0.940298981213525,
    0.940298981213525, 0.940298981213525, 0.955223962278194, 0.970150037870661, 0.98507501893533, 1, 1, 1, 1, 1,
    0.98507501893533, 0.98507501893533, 0.977612528402996, 0.970150037870661, 0.95895548117631, 0.951492717012026,
    0.940298981213525, 0.93283649068119, 0.925374000148855, 0.925374000148855,
  ],
  [
    0.305970320784207, 0.3805971415312, 0.455224235910143, 0.529850783025187, 0.60447787740413, 0.675373452884956,
    0.738806264201498, 0.809701839682324, 0.858209122670297, 0.895522669859769, 0.925373726516906, 0.940298981213525,
    0.955223962278194, 0.951492717012026, 0.940298981213525, 0.93283649068119, 0.925374000148855, 0.925374000148855,
    0.925374000148855, 0.929105245415023, 0.944030226479692, 0.958955481176311, 0.973881283136829, 0.98507501893533,
    0.996268754733832, 1, 0.98507501893533, 0.98507501893533, 0.98507501893533, 0.970150037870661, 0.970150037870661,
    0.962687000074428, 0.955223962278194, 0.940298981213525, 0.925374000148855, 0.917910962352621, 0.910447924556387,
    0.910447924556387,
  ],
  [
    0.310606111111111, 0.382575555555555, 0.458333333333333, 0.534090833333333, 0.606060555555555, 0.681818333333333,
    0.75, 0.810606111111111, 0.867424166666666, 0.901515, 0.931818333333333, 0.94697, 0.954545555555555,
    0.954545555555555, 0.939394444444444, 0.935606388888888, 0.924242222222222, 0.924242222222222, 0.924242222222222,
    0.931818333333333, 0.94697, 0.962121111111111, 0.981060833333333, 0.992424444444444, 1, 1, 1, 0.988636666666666,
    0.984848888888888, 0.984848888888888, 0.984848888888888, 0.969696666666666, 0.962121111111111, 0.94697,
    0.931818333333333, 0.920454444444444, 0.909091111111111, 0.909091111111111,
  ],
  [
    0.311538487573953, 0.388461173964646, 0.461538374753487, 0.53846106114418, 0.615384311637205, 0.688461230374879,
    0.761538149112554, 0.823076636686508, 0.873076411045574, 0.915384085996272, 0.934614898619528, 0.953846275345117,
    0.953846275345117, 0.953846275345117, 0.946153611834415, 0.938460948323713, 0.926922799211159, 0.923076749506974,
    0.923076749506974, 0.938460948323713, 0.949999943589766, 0.969230474161856, 0.984614672978595, 0.996153668244648, 1,
    1, 1, 1, 1, 1, 0.992307336489297, 0.984614672978595, 0.969230474161856, 0.953846275345117, 0.938460948323713,
    0.923076749506974, 0.911537754240921, 0.90769142248557,
  ],
  [
    0.311538487573953, 0.388461173964646, 0.461538374753487, 0.53846106114418, 0.615384311637205, 0.684615180670695,
    0.761538149112554, 0.823076636686508, 0.865384311637205, 0.907691704536736, 0.930768848915344, 0.942307280079064,
    0.953846275345117, 0.942307280079064, 0.938460948323713, 0.926922799211159, 0.923076749506974, 0.911537754240921,
    0.919230417751623, 0.926922799211159, 0.942307280079064, 0.957692325049302, 0.969230474161856, 0.984614672978595,
    0.984614672978595, 0.996153668244648, 0.996153668244648, 0.984614672978595, 0.984614672978595, 0.984614672978595,
    0.984614672978595, 0.98076862327441, 0.965384424457672, 0.946153611834415, 0.930768848915344, 0.915384085996272,
    0.90769142248557, 0.90769142248557,
  ],
  [
    0.316406505127032, 0.390625017903651, 0.468750250651119, 0.542968763427738, 0.617187849121202, 0.695312795410248,
    0.757812580566431, 0.82031293863946, 0.875000143229211, 0.906250179036514, 0.933594067789812, 0.949218942464252,
    0.953125089518257, 0.953125089518257, 0.937500501302239, 0.933594067789812, 0.921874767252531, 0.921874767252531,
    0.921874767252531, 0.929687634277385, 0.941406648356244, 0.960937956543111, 0.972656970621969, 0.984375411783982,
    0.992187705891991, 1, 1, 1, 1, 1, 0.992187705891991, 0.984375411783982, 0.972656970621969, 0.957031523030684,
    0.937500501302239, 0.921874767252531, 0.906250179036514, 0.906250179036514,
  ],
  [
    0.317460548416873, 0.392857101284962, 0.468253945158312, 0.5476189367599, 0.62301578063325, 0.694444476778362,
    0.769841029646452, 0.829364991601588, 0.876983928361489, 0.912698421936674, 0.940475747039603, 0.952381063240099,
    0.952381063240099, 0.952381063240099, 0.940475747039603, 0.932539160577866, 0.920634717393151, 0.920634717393151,
    0.920634717393151, 0.928571012849628, 0.944444185773102, 0.960317358696575, 0.97222209288655, 0.984127409087046,
    0.988095556815284, 1, 1, 1, 1, 1, 0.996031852271761, 0.984127409087046, 0.976190531620049, 0.960317358696575,
    0.944444185773102, 0.928571012849628, 0.920634717393151, 0.920634717393151,
  ],
  [
    0.31854829647941, 0.395160889698446, 0.471774074315013, 0.548386963232815, 0.620967408082038, 0.701612445369653,
    0.76612859347925, 0.830644741588848, 0.883064148890242, 0.919354371314854, 0.943547557232496, 0.951611853972121,
    0.951611853972121, 0.951611853972121, 0.939515408862683, 0.931451112123058, 0.919354667013619, 0.919354667013619,
    0.919354667013619, 0.927418963753245, 0.943547557232496, 0.959676742109278, 0.971773778616248, 0.983870223725686,
    0.983870223725686, 0.991935111862843, 0.987902667794264, 0.983870223725686, 0.983870223725686, 0.983870223725686,
    0.983870223725686, 0.983870223725686, 0.979838075355873, 0.967741630246435, 0.951611853972121, 0.939515408862683,
    0.93548326049287, 0.93548326049287,
  ],
  [
    0.325000122222195, 0.404166493518557, 0.479166615740752, 0.558333292592601, 0.637499969444451, 0.708333231481504,
    0.791666462963008, 0.845833200925955, 0.899999938888902, 0.94166640185191, 0.962499786111158, 0.979166004629776,
    0.979166004629776, 0.966666340740813, 0.958333231481504, 0.945833262037053, 0.933332681481626, 0.933332681481626,
    0.933332681481626, 0.945833262037052, 0.958333231481504, 0.974999450000122, 0.987499419444573, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0.991666279629715, 0.983332559259431, 0.970832895370467, 0.962499786111158, 0.950000122222195,
    0.950000122222195,
  ],
  [
    0.31854829647941, 0.395160889698446, 0.471774074315013, 0.548386963232815, 0.620967408082038, 0.701612445369653,
    0.76612859347925, 0.830644741588848, 0.883064148890242, 0.919354371314854, 0.943547557232496, 0.951611853972121,
    0.951611853972121, 0.951611853972121, 0.939515408862683, 0.931451112123058, 0.919354667013619, 0.919354667013619,
    0.919354667013619, 0.927418963753245, 0.943547557232496, 0.959676742109278, 0.971773778616248, 0.983870223725686,
    0.983870223725686, 0.991935111862843, 0.987902667794264, 0.983870223725686, 0.983870223725686, 0.983870223725686,
    0.983870223725686, 0.983870223725686, 0.979838075355873, 0.967741630246435, 0.951611853972121, 0.939515408862683,
    0.93548326049287, 0.93548326049287,
  ],
  [
    0.325000122222195, 0.404166493518557, 0.479166615740752, 0.558333292592601, 0.637499969444451, 0.708333231481504,
    0.791666462963008, 0.845833200925955, 0.899999938888902, 0.94166640185191, 0.962499786111158, 0.979166004629776,
    0.979166004629776, 0.966666340740813, 0.958333231481504, 0.945833262037053, 0.933332681481626, 0.933332681481626,
    0.933332681481626, 0.945833262037052, 0.958333231481504, 0.974999450000122, 0.987499419444573, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0.991666279629715, 0.983332559259431, 0.970832895370467, 0.962499786111158, 0.950000122222195,
    0.950000122222195,
  ],
  [
    0.31854829647941, 0.395160889698446, 0.574999908333241, 0.675001008334341, 0.73749940416607, 0.800001466668133,
    0.83750050416717, 0.862500779167445, 0.900002566669233, 0.919354371314854, 0.943547557232496, 0.951611853972121,
    0.951611853972121, 0.951611853972121, 0.939515408862683, 0.931451112123058, 0.919354667013619, 0.919354667013619,
    0.919354667013619, 0.927418963753245, 0.943547557232496, 0.959676742109278, 0.971773778616248, 0.983870223725686,
    0.983870223725686, 0.991935111862843, 0.987902667794264, 0.983870223725686, 0.983870223725686, 0.983870223725686,
    0.983870223725686, 0.983870223725686, 0.979838075355873, 0.967741630246435, 0.951611853972121, 0.939515408862683,
    0.93548326049287, 0.93548326049287,
  ],
  [
    0.325000122222195, 0.404166493518557, 0.605262243767633, 0.710526417359151, 0.776314113573995, 0.828947165281696,
    0.868420747922544, 0.894735826408481, 0.934211338873214, 0.94166640185191, 0.962499786111158, 0.979166004629776,
    0.979166004629776, 0.966666340740813, 0.958333231481504, 0.945833262037053, 0.933332681481626, 0.933332681481626,
    0.933332681481626, 0.945833262037052, 0.958333231481504, 0.974999450000122, 0.987499419444573, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0.991666279629715, 0.983332559259431, 0.970832895370467, 0.962499786111158, 0.950000122222195,
    0.950000122222195,
  ],
  [
    0.317460548416873, 0.392857101284962, 0.468253945158312, 0.5476189367599, 0.62301578063325, 0.694444476778362,
    0.769841029646452, 0.829364991601588, 0.876983928361489, 0.912698421936674, 0.940475747039603, 0.952381063240099,
    0.952381063240099, 0.952381063240099, 0.940475747039603, 0.932539160577866, 0.920634717393151, 0.920634717393151,
    0.920634717393151, 0.928571012849628, 0.944444185773102, 0.960317358696575, 0.97222209288655, 0.984127409087046,
    0.988095556815284, 1, 1, 1, 1, 1, 0.996031852271761, 0.984127409087046, 0.976190531620049, 0.960317358696575,
    0.944444185773102, 0.928571012849628, 0.920634717393151, 0.920634717393151,
  ],
  [
    0.31854829647941, 0.395160889698446, 0.574999908333241, 0.675001008334341, 0.73749940416607, 0.800001466668133,
    0.83750050416717, 0.862500779167445, 0.900002566669233, 0.919354371314854, 0.943547557232496, 0.951611853972121,
    0.951611853972121, 0.951611853972121, 0.939515408862683, 0.931451112123058, 0.919354667013619, 0.919354667013619,
    0.919354667013619, 0.927418963753245, 0.943547557232496, 0.959676742109278, 0.971773778616248, 0.983870223725686,
    0.983870223725686, 0.991935111862843, 0.987902667794264, 0.983870223725686, 0.983870223725686, 0.983870223725686,
    0.983870223725686, 0.983870223725686, 0.979838075355873, 0.967741630246435, 0.951611853972121, 0.939515408862683,
    0.93548326049287, 0.93548326049287,
  ],
  [
    0.325000122222195, 0.404166493518557, 0.605262243767633, 0.710526417359151, 0.776314113573995, 0.828947165281696,
    0.868420747922544, 0.894735826408481, 0.934211338873214, 0.94166640185191, 0.962499786111158, 0.979166004629776,
    0.979166004629776, 0.966666340740813, 0.958333231481504, 0.945833262037053, 0.933332681481626, 0.933332681481626,
    0.933332681481626, 0.945833262037052, 0.958333231481504, 0.974999450000122, 0.987499419444573, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0.991666279629715, 0.983332559259431, 0.970832895370467, 0.962499786111158, 0.950000122222195,
    0.950000122222195,
  ],
  [
    0.534090833333333, 0.574999908333241, 0.675001008334341, 0.73749940416607, 0.800001466668133, 0.83750050416717,
    0.862500779167445, 0.900002566669233, 0.90909, 0.90909, 0.90909, 0.90909, 0.90909, 0.90909, 0.90909, 0.90909,
    0.90909, 0.90909, 0.90909, 0.90909, 0.90909, 0.90909, 0.90909, 0.90909, 0.920454166666666, 0.954546666666666,
    0.954546666666666, 0.954546666666666, 0.954546666666666, 0.954546666666666, 0.954546666666666, 0.954546666666666,
    0.954546666666666, 0.977273333333333, 1, 1, 1, 1,
  ],
  [
    0.547617717310835, 0.605262243767633, 0.710526417359151, 0.776314113573995, 0.828947165281696, 0.868420747922544,
    0.894735826408481, 0.934211338873214, 0.904761073319272, 0.928569931974689, 0.952378790630107, 0.952378790630107,
    0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107,
    0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107,
    0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107,
    0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.952378790630107, 0.976189395315053, 1,
    1,
  ],
  [
    0.574999908333241, 0.675001008334341, 0.73749940416607, 0.800001466668133, 0.83750050416717, 0.862500779167445,
    0.900002566669233, 0.925001925001925, 0.950001283334616, 0.950001283334616, 0.950001283334616, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.605262243767633, 0.710526417359151, 0.776314113573995, 0.828947165281696, 0.868420747922544, 0.894735826408481,
    0.934211338873214, 0.947369843028125, 0.960527382271093, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.638886965024138, 0.749998981483367, 0.819441445478804, 0.861108960909331, 0.888887983540771, 0.930553461938033,
    0.94444195473712, 0.94444195473712, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1,
  ],
  [
    0.647059331026926, 0.750001078432218, 0.838235167243268, 0.882351672432684, 0.911764832756731, 0.941177993080778,
    0.941177993080778, 0.941177993080778, 0.941177993080778, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.66666870370823, 0.783333740741646, 0.866669925933168, 0.91666870370823, 0.933334962966584, 0.933334962966584,
    0.966667481483292, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.625003055562345, 0.75, 0.833337407416461, 0.91666870370823, 0.91666870370823, 0.91666870370823, 0.937501527781172,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
];

export const boxShapedSideDataMatrix = [
  [
    0.262456158877198, 0.360233962729056, 0.44257323801953, 0.524912513310003, 0.607251593044869, 0.658713420101356,
    0.67929838559068, 0.643274891539971, 0.555789765988382, 0.447719479391861, 0.33964919279534, 0.288187170183245,
    0.267602204693921, 0.247017239204597, 0.247017239204597, 0.236361856749192,
  ],
  [
    0.314285514868853, 0.416326502124122, 0.518367179175384, 0.62857118483971, 0.726530507788446, 0.783673342773875,
    0.795918335693469, 0.742857009912569, 0.636734513452772, 0.514285514868853, 0.368, 0.296, 0.28, 0.264, 0.264,
    0.242422511661959,
  ],
  [
    0.271428610204087, 0.375000067857152, 0.5, 0.625000203571458, 0.732142983163283, 0.789285762755109,
    0.785714305102043, 0.721428610204086, 0.610714372959196, 0.485714305102043, 0.385714440816348, 0.324999932142847,
    0.296428813775545, 0.285714440816348, 0.285714440816348, 0.248483166574726,
  ],
  [
    0.267857288265326, 0.371428610204086, 0.503571457653065, 0.635714305102043, 0.746428678061239, 0.796428542346934,
    0.789285762755109, 0.714285830612262, 0.600000135714305, 0.478571389795913, 0.382142983163283, 0.324999932142847,
    0.30000027142861, 0.285714440816348, 0.285714440816348, 0.254543821487494,
  ],
  [
    0.265454673586814, 0.37818191365292, 0.498181941289292, 0.632727544066194, 0.738181996562035, 0.792727488793451,
    0.774545796231504, 0.701818335074424, 0.592727488793451, 0.469091067371946, 0.37818191365292, 0.327272677024778,
    0.305454867041415, 0.290909291900885, 0.290909291900885, 0.260604476400261,
  ],
  [
    0.278431419300278, 0.392156947481754, 0.517647128950417, 0.650980511956962, 0.756862838600556, 0.8,
    0.776470693425626, 0.701960874894289, 0.596078548250695, 0.478431419300278, 0.400000149019637, 0.345098036293732,
    0.329412080276878, 0.313725677201113, 0.313725677201113, 0.266665131313028,
  ],
  [
    0.297872323223176, 0.412765898958799, 0.544680888909011, 0.668085171751929, 0.757446949569953, 0.8,
    0.765957656677247, 0.685106424264376, 0.582978747487553, 0.48085123241287, 0.408510707107294, 0.352, 0.34, 0.328,
    0.311200000000004, 0.272725786225795,
  ],
  [
    0.284000094240034, 0.400000152000054, 0.528000158080057, 0.656000316160114, 0.756000392160141, 0.796000118560042,
    0.768000340480122, 0.6880002796801, 0.584000170240061, 0.480000060800022, 0.392000237120085, 0.352000054720019,
    0.336000376960135, 0.336000376960135, 0.316800000000003, 0.278786441138562,
  ],
  [
    0.265454673586814, 0.37818191365292, 0.505454590677698, 0.643636587239734, 0.745454784132301, 0.796363744396726,
    0.774545796231504, 0.694545685686018, 0.581818307438053, 0.465454673586814, 0.37818191365292, 0.330909070809911,
    0.357192298669747, 0.305454867041415, 0.322400000000003, 0.28484709605133,
  ],
  [
    0.270370352126205, 0.385185070507574, 0.514814577640665, 0.651851690260673, 0.755555352263427, 0.796296267626894,
    0.774073873388255, 0.692592464883434, 0.577777605761361, 0.470370211385501, 0.392592535253787, 0.351851760631025,
    0.362392501336333, 0.329629366392386, 0.328000000000003, 0.290907750964097,
  ],
  [
    0.293877503540203, 0.408163173511059, 0.538775345606038, 0.669387672803018, 0.75918351203669, 0.8,
    0.771428349854282, 0.693877503540203, 0.595918180591466, 0.502040677051262, 0.440816177759303, 0.361297345269423,
    0.367592704002918, 0.351224976066138, 0.333600000000003, 0.296968405876864,
  ],
  [
    0.315555420444498, 0.439999797333414, 0.568888652444538, 0.688888551111246, 0.768888652444538, 0.8,
    0.76444417422233, 0.684444241777858, 0.599999662222357, 0.524444208000094, 0.484444072889037, 0.36774876837025,
    0.372792906669504, 0.357418548178534, 0.339200000000003, 0.303029060789631,
  ],
  [
    0.331818170041325, 0.4545453603306, 0.5818180404959, 0.704545230785175, 0.786363530371925, 0.8, 0.763636080991799,
    0.681817954132283, 0.609090807024816, 0.540908977066141, 0.504545230785174, 0.374200191471076, 0.37799310933609,
    0.36361212029093, 0.344800000000003, 0.309089715702398,
  ],
  [
    0.328888855111124, 0.457777541333428, 0.586666396444552, 0.711110773333468, 0.786666565333374, 0.8,
    0.768888652444538, 0.702221985777872, 0.613333096888983, 0.542221952000108, 0.502221985777872, 0.380651614571902,
    0.383193312002675, 0.369805692403326, 0.350400000000003, 0.315150370615166,
  ],
  [
    0.323404282842918, 0.442553212132188, 0.578723393933906, 0.69361713137167, 0.770212848528753, 0.8, 0.7744682020824,
    0.719149090991412, 0.634042666727035, 0.553191434314164, 0.49361713137167, 0.387103037672729, 0.388393514669261,
    0.375999264515722, 0.356000000000003, 0.321211025527933,
  ],
  [
    0.319149090991412, 0.442553212132188, 0.565957333272964, 0.689361777818023, 0.765957656677247, 0.8,
    0.782978747487553, 0.723404282842918, 0.642553212132188, 0.557446787867811, 0.502127838478965, 0.393554460773555,
    0.393593717335846, 0.382192836628118, 0.361600000000003, 0.3272716804407,
  ],
  [
    0.331818170041325, 0.459090850206625, 0.577272550619874, 0.695454423760358, 0.77272706074385, 0.8,
    0.795454510123975, 0.736363487190116, 0.663636167355416, 0.586363357644691, 0.5272726801653, 0.400005883874382,
    0.398793920002432, 0.388386408740514, 0.367200000000002, 0.333332335353467,
  ],
  [
    0.339534933044886, 0.455813867171448, 0.581395377609517, 0.688372088912926, 0.762790578474857, 0.795348800216334,
    0.79069760043267, 0.734883733261222, 0.660465066955113, 0.586046400649004, 0.544186132828552, 0.406457306975208,
    0.403994122669018, 0.39457998085291, 0.372800000000002, 0.339392990266234,
  ],
  [
    0.347619163945594, 0.466666666666666, 0.590476293877566, 0.700000090476203, 0.77142870068029, 0.8,
    0.795238056462579, 0.747619344898002, 0.671428610204087, 0.595238056462579, 0.547619163945594, 0.412908730076034,
    0.409194325335603, 0.400773552965306, 0.378400000000002, 0.345453645179002,
  ],
  [
    0.338095276870754, 0.457142960544232, 0.580952406802724, 0.685714440816348, 0.761904994557856, 0.795238056462579,
    0.780952406802724, 0.728571570748319, 0.647619073469391, 0.576190463265304, 0.538095276870754, 0.419360153176861,
    0.414394528002189, 0.406967125077702, 0.384000000000002, 0.351514300091769,
  ],
  [
    0.346341571921512, 0.468292881856106, 0.585365916954215, 0.687805054372457, 0.765854115169698, 0.8,
    0.790244191790699, 0.731707488875727, 0.653658798810321, 0.580488012849565, 0.546341571921512, 0.425811576277687,
    0.419594730668774, 0.413160697190098, 0.389600000000002, 0.357574955004536,
  ],
  [
    0.346341571921512, 0.468292881856106, 0.585365916954215, 0.687805054372457, 0.765854115169698, 0.8,
    0.785366102320132, 0.731707488875727, 0.653658798810321, 0.580488012849565, 0.546341571921512, 0.432262999378514,
    0.42479493333536, 0.419354269302494, 0.395200000000002, 0.363635609917304,
  ],
  [
    0.346341571921512, 0.468292881856106, 0.585365916954215, 0.687805054372457, 0.76097602569913, 0.785366287686049,
    0.770732204640265, 0.712195316359376, 0.634146440928053, 0.580488012849565, 0.546341571921512, 0.43871442247934,
    0.429995136001946, 0.42554784141489, 0.400800000000002, 0.369696264830071,
  ],
  [
    0.354999848000061, 0.479999848000061, 0.599999620000152, 0.704999658000137, 0.769999772000091, 0.8,
    0.779999848000061, 0.714999734000106, 0.649999620000152, 0.579999848000061, 0.559999696000122, 0.445165845580166,
    0.435195338668531, 0.431741413527286, 0.406400000000002, 0.375756919742838,
  ],
  [
    0.354999848000061, 0.479999848000061, 0.599999620000152, 0.709999696000121, 0.769999772000091, 0.8,
    0.769999772000091, 0.709999696000121, 0.639999544000182, 0.579999848000061, 0.559999696000122, 0.451617268680993,
    0.440395541335117, 0.437934985639682, 0.412000000000002, 0.381817574655606,
  ],
  [
    0.354999848000061, 0.489999734000106, 0.604999658000137, 0.709999696000121, 0.779999848000061, 0.8,
    0.769999772000091, 0.709999696000121, 0.639999544000182, 0.579999848000061, 0.559999696000122, 0.458068691781819,
    0.445595744001702, 0.444128557752078, 0.417600000000002, 0.387878229568373,
  ],
  [
    0.36499992400003, 0.489999734000106, 0.619999772000091, 0.724999620000152, 0.78999992400003, 0.8, 0.779999848000061,
    0.709999696000121, 0.644999582000167, 0.579999848000061, 0.559999696000122, 0.464520114882646, 0.450795946668288,
    0.450322129864474, 0.423200000000002, 0.39393888448114,
  ],
  [
    0.356097750862646, 0.492683143843024, 0.614634268411701, 0.726829584771078, 0.790244191790699, 0.8,
    0.765854115169698, 0.702439137418242, 0.634146440928053, 0.565853929803781, 0.546341571921512, 0.470971537983472,
    0.455996149334874, 0.456515701976871, 0.428800000000002, 0.399999539393907,
  ],
  [
    0.360975840333214, 0.492683143843024, 0.619512357882268, 0.731707488875727, 0.79512209589535, 0.8,
    0.765854115169698, 0.712195316359376, 0.634146440928053, 0.565853929803781, 0.546341571921512, 0.477422961084298,
    0.461196352001459, 0.462709274089267, 0.434400000000002, 0.406060194306674,
  ],
  [
    0.360975840333214, 0.492683143843024, 0.614634268411701, 0.726829584771078, 0.790244191790699, 0.8,
    0.765854115169698, 0.707317226888809, 0.634146440928053, 0.565853929803781, 0.546341571921512, 0.483874384185125,
    0.466396554668045, 0.468902846201663, 0.440000000000002, 0.412120849219442,
  ],
  [
    0.36499992400003, 0.494999772000091, 0.619999772000091, 0.729999658000137, 0.78999992400003, 0.8, 0.769999772000091,
    0.709999696000121, 0.639999544000182, 0.579999848000061, 0.559999696000122, 0.490325807285951, 0.47159675733463,
    0.475096418314059, 0.445600000000002, 0.418181504132209,
  ],
  [
    0.374358969362262, 0.502563927679194, 0.620512590664083, 0.728204958316931, 0.774358774490507, 0.789743509796202,
    0.769230529388609, 0.712820417882991, 0.651281671531965, 0.594871754898102, 0.574358774490507, 0.496777230386778,
    0.476796960001216, 0.481289990426455, 0.451200000000002, 0.424242159044976,
  ],
  [
    0.3736842, 0.5052632, 0.6263158, 0.7263158, 0.773684, 0.8, 0.773684, 0.7263158, 0.6631578, 0.6105264, 0.552,
    0.503228653487604, 0.481997162667802, 0.487483562538851, 0.456800000000002, 0.430302813957743,
  ],
  [
    0.3789474, 0.5157894, 0.631578799999999, 0.7263158, 0.773684, 0.7947368, 0.773684, 0.7210528, 0.6631578, 0.6105264,
    0.536, 0.509680076588431, 0.487197365334387, 0.493677134651247, 0.462400000000002, 0.43636346887051,
  ],
  [
    0.38918930577066, 0.518919074360881, 0.643243393133706, 0.740540873630459, 0.78918910036521, 0.8, 0.783783650547816,
    0.740540873630459, 0.675675681227174, 0.627027249086972, 0.52, 0.516131499689257, 0.492397568000973,
    0.499870706763643, 0.468000000000002, 0.442424123783278,
  ],
  [
    0.394444608642048, 0.527778012345783, 0.644444608642048, 0.738889217284096, 0.788889217284096, 0.8,
    0.783333825926145, 0.733333614814939, 0.672222409876627, 0.627778012345783, 0.512, 0.522582922790083,
    0.497597770667558, 0.506064278876039, 0.473600000000001, 0.448484778696045,
  ],
  [
    0.405714112000069, 0.548571254857212, 0.662856752000156, 0.754285366857282, 0.79428567085716, 0.8,
    0.765714025142961, 0.714285280000174, 0.657142640000086, 0.617142336000208, 0.52, 0.52903434589091,
    0.502797973334144, 0.512257850988435, 0.479200000000001, 0.454545433608812,
  ],
  [
    0.417142770285749, 0.554285584000052, 0.674285410285835, 0.765714025142961, 0.8, 0.79428567085716,
    0.754285366857282, 0.697142509714424, 0.634285323428727, 0.58285722971425, 0.528, 0.535485768991736,
    0.50799817600073, 0.518451423100831, 0.484800000000001, 0.460606088521579,
  ],
  [
    0.405555813580362, 0.544444608642048, 0.661111416049518, 0.75000042222241, 0.783333825926145, 0.777778434568193,
    0.744444819753253, 0.683333614814939, 0.61666680740747, 0.561111627160723, 0.533333614814939, 0.541937192092562,
    0.513198378667315, 0.524644995213227, 0.490400000000001, 0.466666743434346,
  ],
  [
    0.405714112000069, 0.542856925714372, 0.657142422857316, 0.748571037714442, 0.78857134171432, 0.8,
    0.777142683428641, 0.719999609143013, 0.651428310857246, 0.59428567085716, 0.54, 0.548388615193389,
    0.518398581333901, 0.530838567325624, 0.496000000000001, 0.472727398347114,
  ],
  [
    0.411764574394486, 0.547058856401378, 0.658823542560551, 0.735293834948146, 0.788235202076141, 0.8,
    0.782352803114211, 0.729411435986217, 0.658823319031178, 0.600000223529372, 0.536, 0.554840038294215,
    0.523598784000486, 0.53703213943802, 0.501600000000001, 0.478788053259881,
  ],
  [
    0.411764574394486, 0.541176457439448, 0.647058744636692, 0.735293834948146, 0.776470404152282, 0.788235202076141,
    0.764705606228422, 0.705882287197243, 0.635293946712833, 0.564705829757794, 0.529411435986217, 0.561291461395042,
    0.528798986667072, 0.543225711550416, 0.507200000000001, 0.484848708172648,
  ],
  [
    0.411764574394486, 0.541176457439448, 0.647058744636692, 0.735293834948146, 0.788235202076141, 0.79411760103807,
    0.758823207266493, 0.699999888235314, 0.623529372318346, 0.558823430795865, 0.5117644626298, 0.567742884495868,
    0.533999189333658, 0.549419283662812, 0.512800000000001, 0.490909363085415,
  ],
  [
    0.4, 0.529411659515589, 0.647058744636692, 0.729411435986217, 0.782352803114211, 0.79411760103807,
    0.758823207266493, 0.699999888235314, 0.623529372318346, 0.564705829757794, 0.523529037024287, 0.574194307596694,
    0.539199392000243, 0.555612855775208, 0.518400000000001, 0.496970017998182,
  ],
  [
    0.412121309825534, 0.539393911478418, 0.654545663911858, 0.739393911478418, 0.793939345087233, 0.8,
    0.775757380348932, 0.715151522130395, 0.642424354086324, 0.581818265564743, 0.551514991000908, 0.580645730697521,
    0.544399594666829, 0.561806427887604, 0.524, 0.50303067291095,
  ],
  [
    0.412121309825534, 0.545454566391186, 0.642424354086324, 0.733333256565651, 0.775757380348932, 0.781818035261698,
    0.751514991000908, 0.696969787695139, 0.630303274563834, 0.581818265564743, 0.557575645913675, 0.587097153798347,
    0.549599797333414, 0.568, 0.5296, 0.509091327823717,
  ],
  [
    0.438710009573554, 0.580645485536088, 0.683871442247934, 0.774194062435262, 0.8, 0.8, 0.774194062435262,
    0.722580961498622, 0.658065259521763, 0.606451913423691, 0.593548576899174, 0.593548576899174, 0.5548,
    0.574193572112396, 0.5352, 0.541936211446832,
  ],
  [
    0.45333323200004, 0.599999746666767, 0.706666464000081, 0.786666565333374, 0.8, 0.8, 0.766666413333434,
    0.719999645333475, 0.666666666666667, 0.613332928000162, 0.58666605866691, 0.6, 0.560000202666586,
    0.560000202666586, 0.5408, 0.533333333333333,
  ],
  [
    0.468965535315098, 0.613792949821662, 0.724137858739606, 0.786206788109408, 0.786206788109408, 0.744827414506563,
    0.696551959096281, 0.648275717479211, 0.620689293698028, 0.593103394054704, 0.579310706301971, 0.558620888466084,
    0.551724282520788, 0.551724282520788, 0.5464, 0.551724282520788,
  ],
  [
    0.485714440816348, 0.642857220408174, 0.750000135714305, 0.8, 0.771428610204086, 0.692857356122479,
    0.614285830612261, 0.571428881632697, 0.571428881632697, 0.571428881632697, 0.571428881632697, 0.571428881632697,
    0.571428881632697, 0.571428881632697, 0.552, 0.548,
  ],
  [
    0.473991763511662, 0.629732153310355, 0.744844346838241, 0.785472285799198, 0.744844346838241, 0.636503262045638,
    0.555247898743427, 0.514619702472618, 0.514619702472618, 0.528162434562888, 0.541705166653158, 0.541705166653158,
    0.541705166653158, 0.541705166653158, 0.541705166653158, 0.541705166653158,
  ],
  [
    0.462500282031338, 0.612500103906283, 0.73750019296881, 0.787499896093717, 0.73750019296881, 0.618750155859423,
    0.518750274609461, 0.475000148437546, 0.475000148437546, 0.487500252343829, 0.500000356250111, 0.500000356250111,
    0.500000356250111, 0.500000356250111, 0.500000356250111, 0.500000356250111,
  ],
  [
    0.455565279904979, 0.607420373206638, 0.721311633073581, 0.75927570694551, 0.714984287428259, 0.594765681915996,
    0.512511150275661, 0.480874422049054, 0.480874422049054, 0.499856458985018, 0.506183804630339, 0.531493187211626,
    0.531493187211626, 0.531493187211626, 0.531493187211626, 0.506183804630339,
  ],
  [
    0.446906556640974, 0.582332848304263, 0.656817360181043, 0.677131458316447, 0.650045994135908, 0.575561482259129,
    0.521391068517754, 0.514619702472618, 0.514619702472618, 0.534933800608023, 0.548476532698292, 0.568790630833697,
    0.568790630833697, 0.568790630833697, 0.568790630833697, 0.541705166653158,
  ],
  [
    0.494035065076017, 0.625263633403635, 0.702456080654954, 0.741052890947437, 0.717894804771947, 0.687017356537962,
    0.640702357520628, 0.617544271345138, 0.617544271345138, 0.617544271345138, 0.617544271345138, 0.617544271345138,
    0.617544271345138, 0.617544271345138, 0.617544271345138, 0.617544271345138,
  ],
  [
    0.481481606584418, 0.607407751440482, 0.696296546502169, 0.755556306173173, 0.79259281152273, 0.8,
    0.785185623045462, 0.748148836214298, 0.696296546502169, 0.659259196707791, 0.622222972839839, 0.600000562963213,
    0.592593093004338, 0.592593093004338, 0.592593093004338, 0.592593093004338,
  ],
  [
    0.393939345087233, 0.496969787695138, 0.593939575390277, 0.67272716804407, 0.745454566391186, 0.787878690174466,
    0.787878690174466, 0.745454566391186, 0.67272716804407, 0.600000230303044, 0.545454336088142, 0.509091327823717,
    0.484848708172649, 0.484848708172649, 0.484848708172649, 0.484848708172649,
  ],
  [
    0.393939345087233, 0.496969787695138, 0.581818265564743, 0.660606088521581, 0.733333256565651, 0.787878690174466,
    0.8, 0.763636070523398, 0.696969787695139, 0.618181964738301, 0.551514991000908, 0.515151752433439,
    0.490909363085416, 0.484848708172649, 0.484848708172649, 0.484848708172649,
  ],
  [
    0.441379111533915, 0.558620888466084, 0.634482505588619, 0.689655353150986, 0.731034464684901, 0.772413576218817,
    0.793103394054704, 0.765516970273521, 0.717241514863239, 0.662068929369802, 0.606896343876366, 0.579310706301971,
    0.551724282520788, 0.551724282520788, 0.551724282520788, 0.551724282520788,
  ],
  [
    0.547826316824256, 0.686956909640933, 0.756521710396968, 0.8, 0.8, 0.8, 0.8, 0.8, 0.765217103969679,
    0.739130923251545, 0.721739805671254, 0.695652633648513, 0.695652633648513, 0.695652633648513, 0.695652633648513,
    0.695652633648513,
  ],
  [
    0.563636740496107, 0.700000518182147, 0.781818715702818, 0.8, 0.763637431405638, 0.745455456198926,
    0.727273480992215, 0.727273480992215, 0.727273480992215, 0.727273480992215, 0.727273480992215, 0.727273480992215,
    0.727273480992215, 0.727273480992215, 0.727273480992215, 0.727273480992215,
  ],
  [
    0.590475759637516, 0.733332850794018, 0.8, 0.8, 0.752380607710013, 0.723808972336021, 0.685713458504031,
    0.714285093878023, 0.723808972336021, 0.752380607710013, 0.76190448616801, 0.76190448616801, 0.76190448616801,
    0.76190448616801, 0.76190448616801, 0.76190448616801,
  ],
  [
    0.590475759637516, 0.733332850794018, 0.8, 0.790476121542002, 0.742856729252015, 0.695237336962029,
    0.685713458504031, 0.685713458504031, 0.714285093878023, 0.733332850794018, 0.76190448616801, 0.76190448616801,
    0.76190448616801, 0.76190448616801, 0.76190448616801, 0.76190448616801,
  ],
  [
    0.590475759637516, 0.733332850794018, 0.8, 0.8, 0.752380607710013, 0.704761215420026, 0.685713458504031,
    0.685713458504031, 0.714285093878023, 0.733332850794018, 0.76190448616801, 0.76190448616801, 0.76190448616801,
    0.76190448616801, 0.76190448616801, 0.76190448616801,
  ],
  [
    0.563636740496107, 0.709091505785503, 0.781818715702818, 0.79090935785141, 0.745455456198926, 0.700000518182147,
    0.654545580165369, 0.654545580165369, 0.681818542975436, 0.700000518182147, 0.727273480992215, 0.727273480992215,
    0.727273480992215, 0.727273480992215, 0.727273480992215, 0.727273480992215,
  ],
  [
    0.572727728099463, 0.718182493388859, 0.79090935785141, 0.8, 0.781818715702818, 0.709091505785503,
    0.663636567768725, 0.654545580165369, 0.681818542975436, 0.700000518182147, 0.727273480992215, 0.727273480992215,
    0.727273480992215, 0.727273480992215, 0.727273480992215, 0.727273480992215,
  ],
  [
    0.556521710396968, 0.686956909640933, 0.756521710396968, 0.791304275992419, 0.747826316824256, 0.686956909640933,
    0.643478289603032, 0.626086841587871, 0.652174013610612, 0.669565461625772, 0.695652633648513, 0.695652633648513,
    0.695652633648513, 0.695652633648513, 0.695652633648513, 0.695652633648513,
  ],
  [
    0.556521710396968, 0.695652633648513, 0.765217434404548, 0.8, 0.747826316824256, 0.695652633648513,
    0.643478289603032, 0.626086841587871, 0.652174013610612, 0.660869737618192, 0.695652633648513, 0.695652633648513,
    0.695652633648513, 0.695652633648513, 0.695652633648513, 0.695652633648513,
  ],
  [
    0.556521710396968, 0.695652633648513, 0.773912827977259, 0.8, 0.773912827977259, 0.713044081663673,
    0.652174013610612, 0.626086841587871, 0.652174013610612, 0.660869737618192, 0.695652633648513, 0.695652633648513,
    0.695652633648513, 0.695652633648513, 0.695652633648513, 0.695652633648513,
  ],
  [
    0.533333122222239, 0.67500027708331, 0.749999604166699, 0.8, 0.766666402777799, 0.70000015833332, 0.64166667986111,
    0.60833308263891, 0.62499988125001, 0.633333280555559, 0.66666687777776, 0.66666687777776, 0.66666687777776,
    0.66666687777776, 0.66666687777776, 0.66666687777776,
  ],
  [
    0.511999635200146, 0.655999817600073, 0.735999513600194, 0.783999878400048, 0.759999696000121, 0.695999817600073,
    0.623999878400048, 0.607999756800097, 0.607999756800097, 0.607999756800097, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64,
  ],
  [
    0.519999696000122, 0.655999817600073, 0.751999635200146, 0.8, 0.783999878400048, 0.711999635200146,
    0.648000060799975, 0.607999756800097, 0.607999756800097, 0.631999939200024, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64,
  ],
  [
    0.499999561538765, 0.630768871006166, 0.738461088757707, 0.792307636094713, 0.8, 0.753845816568281,
    0.715383997041848, 0.676922177515415, 0.646153891124229, 0.646153891124229, 0.62307679940837, 0.615384435503082,
    0.615384435503082, 0.615384435503082, 0.615384435503082, 0.615384435503082,
  ],
  [
    0.448275717479211, 0.593103394054704, 0.68275874720569, 0.758620364328226, 0.8, 0.8, 0.765516970273521,
    0.717241514863239, 0.662068929369802, 0.620689293698028, 0.586207050178338, 0.56551749441138, 0.551724282520788,
    0.551724282520788, 0.551724282520788, 0.551724282520788,
  ],
  [
    0.419355004786777, 0.554839057648485, 0.65806501436033, 0.729032629760882, 0.787097153798347, 0.793548576899174,
    0.761290971072177, 0.696774533611019, 0.638710254734986, 0.587096908636914, 0.548387634547658, 0.529032874922314,
    0.516129538397796, 0.516129538397796, 0.516129538397796, 0.516129538397796,
  ],
  [
    0.464285694897956, 0.585714440816348, 0.692857356122479, 0.757143051020435, 0.8, 0.792857084693869,
    0.757143051020435, 0.714286102040871, 0.664285694897956, 0.621428474489782, 0.60000054285722, 0.578571796938828,
    0.571428881632697, 0.571428881632697, 0.571428881632697, 0.571428881632697,
  ],
  [
    0.511999635200146, 0.64, 0.719999392000243, 0.767999756800097, 0.8, 0.775999817600073, 0.759999696000121,
    0.735999513600194, 0.703999270400291, 0.68, 0.672000243199902, 0.64, 0.64, 0.64, 0.64, 0.64,
  ],
  [
    0.539130592816676, 0.669565461625772, 0.747826316824256, 0.791304275992419, 0.8, 0.8, 0.8, 0.765217103969679,
    0.765217103969679, 0.730435529678834, 0.721739805671254, 0.695652633648513, 0.695652633648513, 0.695652633648513,
    0.695652633648513, 0.695652633648513,
  ],
  [
    0.539130592816676, 0.678261185633353, 0.756521710396968, 0.8, 0.8, 0.8, 0.765217103969679, 0.747826316824256,
    0.730435529678834, 0.704348357656093, 0.695652633648513, 0.695652633648513, 0.695652633648513, 0.695652633648513,
    0.695652633648513, 0.695652633648513,
  ],
  [
    0.52500003958333, 0.65833347847221, 0.749999604166699, 0.783333201388899, 0.8, 0.766666402777799, 0.733332805555599,
    0.70000047499996, 0.67500027708331, 0.66666687777776, 0.66666687777776, 0.66666687777776, 0.66666687777776,
    0.66666687777776, 0.66666687777776, 0.66666687777776,
  ],
  [
    0.511999635200146, 0.648000060799975, 0.735999513600194, 0.775999817600073, 0.783999878400048, 0.759999696000121,
    0.719999392000243, 0.68, 0.648000060799975, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64,
  ],
  [
    0.519999696000122, 0.655999817600073, 0.74399957440017, 0.8, 0.8, 0.775999817600073, 0.727999452800218, 0.68,
    0.648000060799975, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64,
  ],
  [
    0.519999696000122, 0.655999817600073, 0.751999635200146, 0.8, 0.8, 0.767999756800097, 0.719999392000243,
    0.672000243199902, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64, 0.64,
  ],
  [
    0.533333122222239, 0.66666687777776, 0.749999604166699, 0.783333201388899, 0.766666402777799, 0.72499972291669,
    0.68333367638886, 0.65000007916666, 0.633333280555559, 0.65833347847221, 0.66666687777776, 0.66666687777776,
    0.66666687777776, 0.66666687777776, 0.66666687777776, 0.66666687777776,
  ],
  [
    0.590475759637516, 0.723808972336021, 0.790476121542002, 0.8, 0.76190448616801, 0.714285093878023,
    0.685713458504031, 0.685713458504031, 0.714285093878023, 0.723808972336021, 0.76190448616801, 0.76190448616801,
    0.76190448616801, 0.76190448616801, 0.76190448616801, 0.76190448616801,
  ],
  [
    0.619999772000091, 0.739999544000182, 0.8, 0.779999848000061, 0.739999544000182, 0.699999240000303,
    0.679999088000365, 0.699999240000303, 0.739999544000182, 0.759999696000122, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8,
  ],
  [
    0.619999772000091, 0.739999544000182, 0.8, 0.779999848000061, 0.729999468000213, 0.689999164000334,
    0.679999088000365, 0.699999240000303, 0.729999468000212, 0.759999696000122, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8,
  ],
  [
    0.619999772000091, 0.739999544000182, 0.8, 0.779999848000061, 0.729999468000213, 0.689999164000334,
    0.679999088000365, 0.699999240000303, 0.729999468000212, 0.759999696000122, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8,
  ],
  [
    0.619999772000091, 0.739999544000182, 0.8, 0.779999848000061, 0.729999468000213, 0.689999164000334,
    0.679999088000365, 0.699999240000303, 0.729999468000212, 0.759999696000122, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8,
  ],
  [
    0.609999696000121, 0.739999544000182, 0.8, 0.769999772000091, 0.729999468000213, 0.689999164000334,
    0.679999088000365, 0.699999240000303, 0.729999468000212, 0.759999696000122, 0.78999992400003, 0.8, 0.8, 0.8, 0.8,
    0.8,
  ],
  [
    0.609999696000121, 0.739999544000182, 0.78999992400003, 0.769999772000091, 0.729999468000213, 0.689999164000334,
    0.679999088000365, 0.689999164000334, 0.719999392000243, 0.759999696000122, 0.779999848000061, 0.8, 0.8, 0.8, 0.8,
    0.8,
  ],
  [
    0.609999696000121, 0.739999544000182, 0.8, 0.8, 0.759999696000122, 0.729999468000213, 0.719999392000243,
    0.699999240000303, 0.719999392000243, 0.749999620000152, 0.759999696000122, 0.8, 0.8, 0.8, 0.8, 0.8,
  ],
  [
    0.579999848000061, 0.719999392000243, 0.779999848000061, 0.779999848000061, 0.759999696000122, 0.739999544000182,
    0.719999392000243, 0.719999392000243, 0.719999392000243, 0.729999468000212, 0.759999696000122, 0.759999696000122,
    0.759999696000122, 0.759999696000122, 0.759999696000122, 0.759999696000122,
  ],
  [
    0.574999346875571, 0.699999287500623, 0.749999643750311, 0.749999643750311, 0.749999643750311, 0.749999643750311,
    0.749999643750311, 0.749999643750311, 0.749999643750311, 0.749999643750311, 0.749999643750311, 0.749999643750311,
    0.8, 0.8, 0.8, 0.8,
  ],
];

export const dumperDataMatrix = [
  [
    0.119834676729716, 0.144628136966981, 0.177685931516509, 0.212810038294342, 0.25413216715613, 0.29958669182429,
    0.349173840949063, 0.402892699929484, 0.460743954716277, 0.518595209503069, 0.578512776518169, 0.636364031304961,
    0.690082890285382, 0.739669810759914, 0.780992168271943, 0.814049734171229, 0.845041902443173, 0.867768821801892,
    0.884297719076655, 0.89669490649577, 0.907025324386097, 0.915289658698358, 0.919421825854489, 0.925620076588684,
    0.925620076588684, 0.92768616016675, 0.933884410900946, 0.933884410900946, 0.935950494479011, 0.942148745213207,
    0.942148745213207, 0.950413994126432, 0.950413994126432, 0.958678328438693, 0.958678328438693, 0.966942662750955,
    0.966942662750955, 0.975206997063216, 0.975206997063216, 0.975206997063216, 0.983471331375477, 0.983471331375477,
    0.983471331375477, 0.983471331375477, 0.983471331375477, 0.983471331375477, 0.983471331375477, 0.983471331375477,
    0.983471331375477, 0.983471331375477, 0.983471331375477, 0.983471331375477, 0.983471331375477, 0.983471331375477,
    0.987603498531608, 0.991735665687739, 0.991735665687739, 0.991735665687739, 0.997933916421934, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 0.993801749265804, 0.991735665687739, 0.985537414953543, 0.981405247797412, 0.975206997063216, 1,
  ],
  [
    0.137324034219367, 0.170774580655317, 0.202464592524703, 0.239436597765001, 0.278168942736255, 0.322183086242813,
    0.3697182988825, 0.422535115221882, 0.473591396994699, 0.528168747900646, 0.584506828208767, 0.640844908516887,
    0.693661724856269, 0.739436208093782, 0.778168747900646, 0.81338002373877, 0.838027702506287, 0.859154701811893,
    0.87676024231315, 0.887323449712539, 0.897887046783147, 0.904929574720625, 0.908450643853754, 0.915492782120014,
    0.915492782120014, 0.920774385819708, 0.922534920386273, 0.926055989519402, 0.929577058652532, 0.933098127785662,
    0.936619196918791, 0.941900800618486, 0.94366133518505, 0.950704252793747, 0.952464787360312, 0.957746391060006,
    0.959506925626571, 0.964788529326266, 0.964788529326266, 0.971830667592525, 0.971830667592525, 0.971830667592525,
    0.971830667592525, 0.971830667592525, 0.978872805858784, 0.978872805858784, 0.978872805858784, 0.978872805858784,
    0.978872805858784, 0.978872805858784, 0.978872805858784, 0.978872805858784, 0.978872805858784, 0.980633340425349,
    0.985914944125043, 0.985914944125043, 0.985914944125043, 0.991196547824738, 0.992957082391303, 0.992957082391303,
    0.992957082391303, 0.992957082391303, 0.992957082391303, 0.992957082391303, 0.998239270597825, 1, 0.992957082391303,
    0.992957082391303, 0.992957082391303, 0.991196547824738, 0.985914944125043, 0.982393874991914, 0.977112271292219,
    0.97007013302596, 0.963027994759701, 1,
  ],
  [
    0.158064532251132, 0.190322447062049, 0.224193596753396, 0.261290323732223, 0.301612984987883, 0.3451614020257,
    0.391935574845673, 0.438709747665646, 0.49032273265353, 0.541935717641414, 0.598387336302532, 0.651613020686819,
    0.698387193506792, 0.741935610544608, 0.78064539390919, 0.809677552937951, 0.835484313173906, 0.858064603649002,
    0.872581040152734, 0.883871363884958, 0.893548631231427, 0.90000014279574, 0.904838776468975, 0.90967741014221,
    0.912903522913718, 0.916129635685226, 0.920968269358461, 0.922581147249539, 0.929032658813852, 0.932258414596009,
    0.935484170378166, 0.941935681942479, 0.943548559833557, 0.948387193506792, 0.951612949288948, 0.954838705071105,
    0.959677874228367, 0.961290930614121, 0.964516686396278, 0.967742442178434, 0.967742442178434, 0.970968197960591,
    0.974193953742747, 0.974193953742747, 0.974193953742747, 0.974193953742747, 0.98064546530706, 0.98064546530706,
    0.98064546530706, 0.98064546530706, 0.98064546530706, 0.98064546530706, 0.985484098980295, 0.987096976871374,
    0.987096976871374, 0.988709854762452, 0.993548488435687, 0.993548488435687, 0.993548488435687, 0.998387122108921, 1,
    1, 1, 1, 1, 1, 1, 0.995161366326765, 0.993548488435687, 0.991935610544608, 0.987096976871374, 0.98064546530706,
    0.972581075851669, 0.964516686396278, 0.958064817842613, 1,
  ],
  [
    0.174382708669916, 0.208333475651618, 0.243827257481102, 0.280864395722253, 0.322530920070635, 0.364197615200959,
    0.407407495961007, 0.456790290021894, 0.50308654204139, 0.555555707361726, 0.604938501422613, 0.654321124701558,
    0.700617376721055, 0.743827257481103, 0.779321210092529, 0.810185435032841, 0.833333731824531, 0.851852130163165,
    0.867283986460407, 0.876543612584579, 0.885802897144867, 0.891975639663764, 0.896605196552937, 0.90123475344211,
    0.905864310331282, 0.908950681590731, 0.913580238479904, 0.918210307714903, 0.922840035386018, 0.925926406645466,
    0.932099149164364, 0.935185520423812, 0.938271891683261, 0.944444634202158, 0.944444634202158, 0.950617376721055,
    0.950617376721055, 0.956790119239952, 0.956790119239952, 0.961420188474951, 0.962963544886618, 0.962963544886618,
    0.969136287405514, 0.969136287405514, 0.969136287405514, 0.975309029924411, 0.975309029924411, 0.975309029924411,
    0.975309029924411, 0.981481772443309, 0.981481772443309, 0.981481772443309, 0.981481772443309, 0.986111329332481,
    0.987654514962206, 0.990740886221654, 0.993827257481103, 0.993827257481103, 0.993827257481103, 1, 1, 1, 1, 1, 1, 1,
    0.993827257481103, 0.993827257481103, 0.992284071851378, 0.987654514962206, 0.981481772443309, 0.973765844294687,
    0.966049916146066, 0.956790290021894, 0.947531005461606, 1,
  ],
  [
    0.187499831300845, 0.222560913890553, 0.259146362036482, 0.297256007039478, 0.338414551854071, 0.379573096668664,
    0.422256007039478, 0.466463282966513, 0.512195093148925, 0.560975465744624, 0.609756007039478, 0.658536379635178,
    0.701219290005992, 0.742377834820585, 0.778963282966514, 0.807926565933027, 0.830792724072964, 0.84908511074762,
    0.862804738151921, 0.873475634443778, 0.881097462224885, 0.88719492444977, 0.891768021118435, 0.896341117787099,
    0.900914214455763, 0.903963114267359, 0.908536717033486, 0.914634179258371, 0.919207275927035, 0.923780372595699,
    0.928353469264363, 0.932926565933027, 0.937499662601692, 0.939024028157913, 0.945121490382798, 0.945121490382798,
    0.949695093148925, 0.9512196274043, 0.952743992960521, 0.957317089629185, 0.957317089629185, 0.963414551854071,
    0.963414551854071, 0.967987648522735, 0.969512014078956, 0.971036379635178, 0.975609476303842, 0.975609476303842,
    0.975609476303842, 0.980182572972506, 0.981706938528727, 0.981706938528727, 0.98475566964117, 0.987804400753612,
    0.990853469264363, 0.993902537775114, 0.995426903331336, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.998475634443778,
    0.993902537775114, 0.992378003519739, 0.986280035197391, 0.980182572972506, 0.971036379635178, 0.960365820741628,
    0.948170558893549, 0.93597529704547, 1,
  ],
  [
    0.195454585087232, 0.230303012011021, 0.269696987988978, 0.306060542038573, 0.346969645142337, 0.386363621120295,
    0.428787851349864, 0.471212081579434, 0.51515143893481, 0.559090796290185, 0.606060575573924, 0.653030187180911,
    0.696969544536286, 0.736363520514244, 0.771212115114784, 0.801514992984404, 0.824242570578498, 0.842424096088168,
    0.854545280771366, 0.865151673682262, 0.872727309311291, 0.878787817814514, 0.883333199191932, 0.886363453443544,
    0.890908834820961, 0.896969343324184, 0.901515227731856, 0.906060776786025, 0.910606158163443, 0.916666666666666,
    0.921212048044084, 0.925757429421501, 0.928787683673113, 0.933333065050531, 0.936363319302142, 0.939393573553754,
    0.939393573553754, 0.945454752763983, 0.945454752763983, 0.950000134141401, 0.951515261267207, 0.954545515518818,
    0.95757576977043, 0.960606024022042, 0.963636278273653, 0.965151405399459, 0.969696786776877, 0.969696786776877,
    0.971211913902683, 0.9757572952801, 0.9757572952801, 0.978787549531712, 0.981817803783324, 0.984848393388438,
    0.987878982993553, 0.99242436437097, 0.993939491496776, 0.993939491496776, 0.998484872874194, 1, 1,
    0.995454618622582, 0.993939491496776, 0.993939491496776, 0.993939491496776, 0.993939491496776, 0.99242436437097,
    0.987878982993553, 0.984848393388438, 0.978787549531712, 0.971211913902683, 0.960606024022042, 0.948485007015595,
    0.936363319302142, 0.924242302295695, 1,
  ],
  [
    0.199999966464649, 0.236363520514244, 0.275757664168953, 0.313636345344354, 0.353030321322312, 0.39242429730027,
    0.433333232727282, 0.474242335831046, 0.516666566060615, 0.560605923415991, 0.606060575573924, 0.649999932929299,
    0.693939290284674, 0.734848393388438, 0.769696987988979, 0.796969611606987, 0.821212148650135, 0.840908968962362,
    0.854545280771366, 0.865151673682262, 0.872727309311291, 0.878787817814514, 0.883333199191932, 0.886363453443544,
    0.890908834820961, 0.896969343324184, 0.901515227731856, 0.906060776786025, 0.912121285289249, 0.916666666666666,
    0.92272717516989, 0.927272556547307, 0.931817937924725, 0.933333065050531, 0.939393573553754, 0.939393573553754,
    0.943939457961426, 0.945454752763983, 0.946969879889789, 0.951515261267207, 0.951515261267207, 0.95757576977043,
    0.95757576977043, 0.963636278273653, 0.963636278273653, 0.969696786776877, 0.969696786776877, 0.971211913902683,
    0.9757572952801, 0.9757572952801, 0.980302676657518, 0.981817803783324, 0.987878982993553, 0.989394110119359,
    0.993939491496776, 0.995454618622582, 1, 1, 1, 1, 1, 1, 0.998484872874194, 0.993939491496776, 0.993939491496776,
    0.993939491496776, 0.99242436437097, 0.987878982993553, 0.983333098585881, 0.977272422405906, 0.969696786776877,
    0.960606024022042, 0.948485007015595, 0.936363319302142, 0.924242302295695, 1,
  ],
  [
    0.201807166666666, 0.237951833333333, 0.2771085, 0.314759, 0.353915666666666, 0.393072333333333, 0.432229,
    0.472891499999999, 0.513554166666666, 0.557228833333333, 0.602409666666666, 0.646084333333333, 0.691264999999999,
    0.731927666666666, 0.766566333333333, 0.796686666666666, 0.820783333333333, 0.838855333333333, 0.852409666666666,
    0.864458, 0.870482, 0.878012, 0.88253, 0.885542, 0.891566, 0.894578333333333, 0.899096666666666, 0.905120666666666,
    0.909638666666666, 0.915662666666666, 0.921686666666666, 0.926204666666666, 0.929216666666666, 0.933734666666666,
    0.936747, 0.939759333333333, 0.941265333333333, 0.945783333333333, 0.945783333333333, 0.951807333333333,
    0.951807333333333, 0.956325333333333, 0.957831333333333, 0.960843333333333, 0.963855333333333, 0.965361333333333,
    0.969879333333333, 0.969879333333333, 0.974397333333333, 0.975903333333333, 0.981928, 0.983433999999999,
    0.987951999999999, 0.990964, 0.993976, 0.996988, 1, 1, 1, 1, 0.995482, 0.993976, 0.993976, 0.993976, 0.989458,
    0.987951999999999, 0.987951999999999, 0.983434, 0.980421833333333, 0.974397333333333, 0.966867333333333,
    0.959337333333333, 0.948795333333333, 0.939759166666666, 0.930722666666666, 1,
  ],
  [
    0.204819333333333, 0.240963833333333, 0.2786145, 0.316265166666666, 0.355421666666666, 0.394578333333333, 0.432229,
    0.472891499999999, 0.513554166666666, 0.555722833333333, 0.602409666666666, 0.644578333333333, 0.689759,
    0.730421666666666, 0.766566333333333, 0.798192666666666, 0.822289333333333, 0.840361333333333, 0.855421833333333,
    0.865964, 0.875, 0.879518, 0.885542, 0.89006, 0.893072166666666, 0.897590666666666, 0.903614666666666,
    0.906626666666666, 0.911144666666666, 0.917168666666666, 0.921686666666666, 0.926204666666666, 0.929216666666666,
    0.933734666666666, 0.938253166666666, 0.939759333333333, 0.944277333333333, 0.945783333333333, 0.950301333333333,
    0.951807333333333, 0.954819333333333, 0.957831333333333, 0.957831333333333, 0.962349333333333, 0.963855333333333,
    0.965361333333333, 0.969879333333333, 0.971385333333333, 0.975903333333333, 0.978915666666666, 0.981928,
    0.987951999999999, 0.990964, 0.993976, 0.998494, 1, 1, 1, 1, 0.998494, 0.993976, 0.993976, 0.99247,
    0.987951999999999, 0.987951999999999, 0.987951999999999, 0.987951999999999, 0.981928, 0.981928, 0.975903333333333,
    0.971385333333333, 0.965361333333333, 0.959337333333333, 0.950301333333333, 0.942771333333333, 1,
  ],
  [
    0.206060642644624, 0.242424196694219, 0.280303045546371, 0.318181894398523, 0.356060575573924, 0.395454551551881,
    0.433333232727282, 0.474242335831046, 0.513636311809004, 0.557575669164379, 0.599999899393949, 0.64545438387513,
    0.689393908907257, 0.730302844334269, 0.766666566060615, 0.796969611606987, 0.821212148650135, 0.842424096088168,
    0.857575702699729, 0.868181927933873, 0.875757563562902, 0.883333199191932, 0.887878580569349, 0.890908834820961,
    0.896969343324184, 0.898484638126742, 0.903030522534414, 0.907575903911831, 0.910606158163443, 0.91515153954086,
    0.919696920918278, 0.92272717516989, 0.927272556547307, 0.931817937924725, 0.934848192176337, 0.939393573553754,
    0.942424163158869, 0.945454752763983, 0.950000134141401, 0.951515261267207, 0.953030388393012, 0.95757576977043,
    0.95757576977043, 0.95757576977043, 0.963636278273653, 0.963636278273653, 0.966666532525265, 0.969696786776877,
    0.9757572952801, 0.978787549531712, 0.983333098585881, 0.987878982993553, 0.993939491496776, 0.995454618622582, 1,
    1, 1, 1, 1, 0.993939491496776, 0.993939491496776, 0.990909237245164, 0.987878982993553, 0.987878982993553,
    0.987878982993553, 0.987878982993553, 0.986363688190996, 0.981817803783324, 0.981817803783324, 0.981817803783324,
    0.9757572952801, 0.972727041028489, 0.969696786776877, 0.963636278273653, 0.959090896896236, 1,
  ],
  [
    0.20858895288996, 0.245398678246606, 0.282208573337352, 0.322085903107627, 0.358895628464273, 0.398772958234548,
    0.435582683591193, 0.475460013361468, 0.516871060471507, 0.558282107581547, 0.60122687203135, 0.645705353820918,
    0.688650118270721, 0.730060995646659, 0.766870890737406, 0.794478481789567, 0.819018128959896, 0.840490680918899,
    0.855827854316541, 0.868097932502858, 0.875766858669882, 0.883435445368703, 0.888036597387995, 0.891104032067524,
    0.895705184086816, 0.897238901426581, 0.901840053445873, 0.904907488125402, 0.90797492280493, 0.912576584026527,
    0.914110471100393, 0.920245340459449, 0.921779057799214, 0.926380209818506, 0.930981361837799, 0.934048796517327,
    0.93864994853662, 0.943251100555913, 0.944784817895677, 0.950919687254734, 0.950919687254734, 0.950919687254734,
    0.957055235550196, 0.957055235550196, 0.957055235550196, 0.961656387569489, 0.963190104909253, 0.96932497426831,
    0.973926126287602, 0.978527278306895, 0.983128430326188, 0.98772958234548, 0.992330734364773, 0.993864451704537, 1,
    1, 1, 1, 0.993864451704537, 0.993864451704537, 0.989263299685245, 0.98772958234548, 0.98772958234548,
    0.98772958234548, 0.98772958234548, 0.98772958234548, 0.98772958234548, 0.98772958234548, 0.98772958234548,
    0.984662147665952, 0.981594712986424, 0.981594712986424, 0.980060995646659, 0.975459843627367, 0.975459843627367, 1,
  ],
  [
    0.20937495460937, 0.246875049713546, 0.287500095104175, 0.325000017291668, 0.365625062682297, 0.40312498486979,
    0.440625079973965, 0.478125002161458, 0.520312522695314, 0.560937568085943, 0.603125088619799, 0.646875084296882,
    0.690625079973965, 0.731250125364594, 0.76718757240886, 0.796875118880219, 0.821875067005214, 0.842187589700529,
    0.856250211822936, 0.868750012968751, 0.878124863828112, 0.884375110234385, 0.88906288149743, 0.893750306927111,
    0.896875257213565, 0.900000207500019, 0.901562682643245, 0.906250108072926, 0.906250108072926, 0.912500008645834,
    0.912500008645834, 0.918749909218741, 0.920312384361968, 0.924999809791649, 0.929687753971377, 0.93437535231774,
    0.937500302604194, 0.943750203177101, 0.946875153463555, 0.950000103750009, 0.950000103750009, 0.956250004322917,
    0.956250004322917, 0.956250004322917, 0.95937495460937, 0.962499904895824, 0.967187330325505, 0.971875101588551,
    0.976562872851596, 0.981250298281277, 0.987500198854184, 0.990625149140638, 0.993750099427092, 1, 1, 1, 1, 1,
    0.996875049713546, 0.993750099427092, 0.992187624283865, 0.987500198854184, 0.987500198854184, 0.987500198854184,
    0.987500198854184, 0.990625149140638, 0.993750099427092, 0.993750099427092, 0.993750099427092, 0.993750099427092,
    0.993750099427092, 0.993750099427092, 0.993750099427092, 0.993750099427092, 0.990625149140638, 1,
  ],
  [
    0.210691757143046, 0.248427698126392, 0.286163639109738, 0.327044009836108, 0.364779950819455, 0.40251571779861,
    0.440251484777765, 0.477987425761111, 0.517295668618065, 0.556603737470828, 0.599056584074997, 0.643081732552773,
    0.683962277283335, 0.723270346136097, 0.759433811241645, 0.789308068852762, 0.814465594847248, 0.831760915456931,
    0.849056758079188, 0.858490743325025, 0.869496856440277, 0.875786063934708, 0.88050296955553, 0.885219875176353,
    0.88679217704996, 0.893082080561156, 0.893082080561156, 0.897798986181979, 0.899371288055586, 0.902515891802801,
    0.905660495550016, 0.908805099297231, 0.911949703044446, 0.916666608665269, 0.919811212412484, 0.924528118033307,
    0.930817325527737, 0.935534753161134, 0.93867953091254, 0.943396436533363, 0.943396436533363, 0.946541040280578,
    0.949685644027793, 0.949685644027793, 0.955974851522223, 0.957547153395831, 0.962264059016653, 0.966980964637476,
    0.971697870258298, 0.976414949883312, 0.981132377516709, 0.985849283137532, 0.987421585011139, 0.992138490631962,
    0.993710792505569, 0.993710792505569, 0.993710792505569, 0.992138490631962, 0.987421585011139, 0.987421585011139,
    0.981132377516709, 0.981132377516709, 0.981132377516709, 0.981132377516709, 0.985849283137532, 0.987421585011139,
    0.990566188758354, 0.993710792505569, 0.993710792505569, 0.996855396252784, 1, 1, 1, 1, 0.993710792505569, 1,
  ],
  [
    0.2120252277947, 0.25, 0.287974772205299, 0.325949369305132, 0.363923966404965, 0.401898738610264,
    0.439873335710098, 0.477848107915397, 0.51582270501523, 0.55379747722053, 0.596518833293292, 0.637658111480544,
    0.680379642658773, 0.718354239758606, 0.753164330876486, 0.781645585135927, 0.806961983202482, 0.824367116314156,
    0.84018964622392, 0.851265767371687, 0.859177382537502, 0.867088647492384, 0.87341765945629, 0.878164418429219,
    0.881328924411173, 0.886075683384102, 0.887657936375078, 0.892404695348008, 0.89398712344445, 0.898734407733778,
    0.900316660724754, 0.905063419697683, 0.90664567268866, 0.911392431661589, 0.916139190634519, 0.920885949607448,
    0.925632708580378, 0.930379467553307, 0.936708479517213, 0.936708479517213, 0.943038191902983, 0.943038191902983,
    0.946202697884936, 0.949367203866889, 0.952531709848842, 0.955696215830794, 0.9620252277947, 0.96677198676763,
    0.969936492749583, 0.974683251722512, 0.979430010695441, 0.981012263686418, 0.981012263686418, 0.987341976072188,
    0.987341976072188, 0.987341976072188, 0.987341976072188, 0.981012263686418, 0.981012263686418, 0.979430010695441,
    0.974683251722512, 0.974683251722512, 0.974683251722512, 0.981012263686418, 0.981012263686418, 0.987341976072188,
    0.990506482054141, 0.993670988036094, 0.998417747009023, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.211783354186124, 0.248407492907114, 0.286624138720989, 0.324840784534865, 0.363057254127971, 0.401273723721077,
    0.43630570779072, 0.474522177383826, 0.512738823197702, 0.550955292790808, 0.592356600348917, 0.633757731686256,
    0.673566708372248, 0.711783177965355, 0.74522283116288, 0.773885139302518, 0.797770454825805, 0.815286623081396,
    0.831210108023331, 0.842356424128147, 0.85031807848873, 0.856687754418735, 0.86305743034874, 0.86783442296509,
    0.87261141558144, 0.87738840819779, 0.878980739069906, 0.885350062558373, 0.886942393430489, 0.891719386046839,
    0.894904047791072, 0.898088709535305, 0.902866230813963, 0.906051068778966, 0.910828061395316, 0.915605054011665,
    0.920382046628015, 0.925159039244365, 0.929936031860715, 0.933120693604948, 0.936305355349181, 0.939490017093414,
    0.942674678837647, 0.945859693023419, 0.950637038081308, 0.955414030697658, 0.960191023314007, 0.963375685058241,
    0.96815267767459, 0.971337339418824, 0.974522001163057, 0.974522001163057, 0.974522001163057, 0.974522001163057,
    0.974522001163057, 0.974522001163057, 0.971337339418823, 0.96815267767459, 0.96815267767459, 0.96815267767459,
    0.96815267767459, 0.96815267767459, 0.96815267767459, 0.971337339418824, 0.976114332035173, 0.980891324651523,
    0.987260648139989, 0.992038169418647, 0.993630676511533, 0.998407669127883, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.212903273021172, 0.251612877891078, 0.29032266125566, 0.325806510343409, 0.364516293707991, 0.40000014279574,
    0.437097048269244, 0.472580897356993, 0.509677624335821, 0.548387229205727, 0.588709890461387, 0.629032373222371,
    0.667742156586953, 0.706451761456859, 0.738709854762452, 0.769354891682291, 0.790322839750335, 0.808064675046872,
    0.822580933055928, 0.835484313173906, 0.843548702629298, 0.850000214193611, 0.856451725757924, 0.861290359431159,
    0.864516115213315, 0.870968340756332, 0.874194096538488, 0.877419852320645, 0.883871363884958, 0.887097119667114,
    0.890322875449271, 0.896774387013584, 0.90000014279574, 0.903225898577897, 0.90967741014221, 0.914516579299472,
    0.919355391467383, 0.924194025140618, 0.929032658813852, 0.933871292487087, 0.935484170378166, 0.9403228040514,
    0.943548559833557, 0.948387193506792, 0.953225827180026, 0.958064817842613, 0.961290930614121, 0.967742442178434,
    0.967742442178434, 0.967742442178434, 0.967742442178434, 0.967742442178434, 0.967742442178434, 0.966129564287356,
    0.961290930614121, 0.961290930614121, 0.959677874228367, 0.954838705071105, 0.954838705071105, 0.954838705071105,
    0.954838705071105, 0.954838705071105, 0.961290930614121, 0.966129564287356, 0.972581075851669, 0.979032587415982,
    0.985484098980295, 0.99032273265353, 0.993548488435687, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.21428573995053, 0.25, 0.288961141620304, 0.324675401669773, 0.363636363636363, 0.397727452380986,
    0.435065063389016, 0.469155972479925, 0.506493583487955, 0.542207843537424, 0.581168985157728, 0.623376828695153,
    0.662337790661743, 0.696428699752652, 0.730519429189848, 0.756493763141669, 0.782467558132348, 0.800324957637654,
    0.814935116264697, 0.826298752628333, 0.834416046382277, 0.840909450216518, 0.84740285405076, 0.85064955596788,
    0.857142959802122, 0.860389661719242, 0.865259714594924, 0.870129767470604, 0.875000359307427, 0.879870591836822,
    0.884740644712503, 0.889610697588184, 0.894480750463865, 0.899350803339546, 0.904220856215228, 0.910714260049469,
    0.91558431292515, 0.922078435374247, 0.925325137291368, 0.928571839208488, 0.93506524304273, 0.93831194495985,
    0.941558646876971, 0.948052050711213, 0.952922103586894, 0.956168805504014, 0.961038858379695, 0.964285919604244,
    0.967532980828792, 0.967532980828792, 0.964285919604244, 0.961038858379695, 0.956168805504015, 0.952922103586894,
    0.948052050711213, 0.943181997835532, 0.941558646876971, 0.941558646876971, 0.939935295918411, 0.939935295918411,
    0.941558646876971, 0.943181997835532, 0.949675401669773, 0.956168805504014, 0.964285919604244, 0.972403033704473,
    0.978896437538715, 0.985389841372956, 0.991883245207198, 0.993506596165758, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.214052317128742, 0.251633960926706, 0.287581824625867, 0.326797429350538, 0.362745112221796, 0.398692975920956,
    0.433006697865507, 0.468954199908862, 0.503267921853413, 0.54084974647928, 0.578431390277245, 0.617647175829819,
    0.65686278055449, 0.692810463425748, 0.727124185370299, 0.754902063608023, 0.777777697409821, 0.79738577101401,
    0.812091600182274, 0.823529326669222, 0.833333453885268, 0.8398696592479, 0.844771542028021, 0.849673424808141,
    0.852941346661555, 0.856209268514968, 0.862745112221796, 0.866013034075209, 0.87091491685533, 0.87581679963545,
    0.882353366653888, 0.888889210360715, 0.893791093140835, 0.900326936847663, 0.905228819627783, 0.911764663334611,
    0.916666546114731, 0.921568428894852, 0.928104995913289, 0.933006878693409, 0.936274800546823, 0.941176683326944,
    0.947712527033771, 0.950980448887185, 0.955882331667305, 0.960784214447426, 0.965686097227546, 0.967320058154253,
    0.967320058154253, 0.967320058154253, 0.964052136300839, 0.960784214447426, 0.954248370740598, 0.947712527033771,
    0.941176683326944, 0.936274800546823, 0.934640839620116, 0.928104995913289, 0.928104995913289, 0.928104995913289,
    0.934640839620116, 0.93790876147353, 0.944444605180357, 0.952614409813891, 0.962418175374133, 0.970588341663472,
    0.978758507952811, 0.986928312586345, 0.993464156293172, 0.998366039073293, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.212418356202035, 0.248366039073293, 0.287581824625867, 0.323529507497124, 0.359477190368382, 0.393790912312933,
    0.429738595184191, 0.464052317128742, 0.5, 0.53594786369916, 0.575163468423831, 0.614379073148503,
    0.653594858701077, 0.687908399817725, 0.722222302590178, 0.748366039073293, 0.774509775556407, 0.792483707405987,
    0.807189717402154, 0.820261404815808, 0.828431209449343, 0.836601737394487, 0.843137581101314, 0.844771542028021,
    0.849673424808141, 0.851307385734848, 0.856209268514968, 0.861111151295089, 0.864379073148503, 0.87091491685533,
    0.87745094139006, 0.883987327580595, 0.890523171287422, 0.895425054067542, 0.90196089777437, 0.908496741481197,
    0.915032585188024, 0.919934467968145, 0.92647085415868, 0.931372917766703, 0.934640839620116, 0.941176683326944,
    0.944444605180357, 0.949346487960478, 0.954248370740598, 0.959150253520719, 0.960784214447426, 0.965686097227546,
    0.967320058154253, 0.964052136300839, 0.960784214447426, 0.955882331667305, 0.949346487960478, 0.94281064425365,
    0.934640839620116, 0.928104995913289, 0.923202570649461, 0.921568428894852, 0.921568428894852, 0.921568428894852,
    0.92483671240407, 0.929738956839996, 0.93790876147353, 0.946078566107064, 0.957516292594012, 0.965686097227546,
    0.975490586099397, 0.983660390732931, 0.990196234439759, 0.993464156293172, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.212171112505769, 0.25, 0.286184176996768, 0.322368353993537, 0.360197423505308, 0.394736890004616,
    0.429276356503923, 0.465460533500692, 0.5, 0.53782906951177, 0.575657957006, 0.615131554997692, 0.654605152989383,
    0.690789329986152, 0.723684268005539, 0.753289420994922, 0.774671021496999, 0.794407729484075, 0.809210670013847,
    0.822368353993537, 0.832236616978305, 0.838815823003231, 0.843750318530696, 0.848684450023079, 0.848684450023079,
    0.853618581515463, 0.855263292012924, 0.858552713007847, 0.861842134002769, 0.868420975992614, 0.874999817982459,
    0.881578659972304, 0.889802940529773, 0.896381782519617, 0.902960624509462, 0.909539466499307, 0.914473597991691,
    0.921052439981536, 0.92763128197138, 0.930921067001384, 0.935855562528849, 0.940789694021233, 0.945723825513617,
    0.949013246508539, 0.953947378000923, 0.958881509493307, 0.960526219990768, 0.96381564098569, 0.967105061980613,
    0.96381564098569, 0.960526219990768, 0.955592088498384, 0.949013246508539, 0.942434404518694, 0.935855562528849,
    0.92763128197138, 0.921052439981536, 0.921052439981536, 0.914473597991691, 0.919407729484075, 0.921052439981536,
    0.92763128197138, 0.935855562528849, 0.944079115016155, 0.955592088498384, 0.96381564098569, 0.975328796485459,
    0.981908184527926, 0.988487026517771, 0.993421158010155, 0.998355289502538, 1, 1, 1, 1, 1,
  ],
  [
    0.213333190696329, 0.249999815555598, 0.286666624859268, 0.324999889333358, 0.360000059022208, 0.395000044266656,
    0.431666484681523, 0.466666469925971, 0.501666639614821, 0.539999904088911, 0.579999808177822, 0.619999896711135,
    0.656666521570404, 0.693333146429672, 0.726666492059299, 0.756666374014882, 0.77666660272594, 0.798332917718614,
    0.813333227585209, 0.824999889333359, 0.834999727022285, 0.84333292509639, 0.846666204326032, 0.851666676503701,
    0.853333500562924, 0.853333500562924, 0.853333500562924, 0.860000059022208, 0.861666698637029, 0.866666617481492,
    0.873333175940777, 0.881666374014882, 0.888332932474166, 0.896666499437075, 0.903333426785163, 0.909999985244447,
    0.914999904088911, 0.919999822933374, 0.926666381392658, 0.931666300237121, 0.934999579466763, 0.939999498311227,
    0.944999970488895, 0.948333434162939, 0.953333353007402, 0.956666632237045, 0.959999911466687, 0.961666551081508,
    0.966666469925971, 0.96499983031115, 0.959999911466687, 0.958333271851866, 0.951666713392581, 0.944999970488895,
    0.938332858696406, 0.931666300237121, 0.924999741777837, 0.919999822933374, 0.919999822933374, 0.919999822933374,
    0.921666462548195, 0.928333021007479, 0.934999579466763, 0.944999970488895, 0.954999992622223, 0.963333190696329,
    0.971666388770434, 0.97999958684454, 0.986666145303824, 0.993333441540715, 0.993333441540715, 1, 1, 1,
    0.996666720770357, 1,
  ],
  [
    0.214285714285714, 0.251700653385158, 0.289115780693255, 0.326530719792699, 0.362245005506985, 0.399659944606429,
    0.435374230320715, 0.469387862649842, 0.505102148364128, 0.54421774084873, 0.581632679948174, 0.622449114026588,
    0.659864053126032, 0.697279180434129, 0.731292624554604, 0.758503455134445, 0.78061232555881, 0.801020542598017,
    0.816326423064443, 0.82823156138651, 0.838435669906113, 0.845238283446747, 0.850340243602223, 0.85374155037254,
    0.857142857142857, 0.857142857142857, 0.857142857142857, 0.857142857142857, 0.862244817298332, 0.865646312277302,
    0.872449490443893, 0.879252103984527, 0.88775537091032, 0.894557984450953, 0.903061251376746, 0.908163211532221,
    0.91496620149016, 0.918367884677783, 0.925170498218416, 0.928571804988733, 0.93197311175905, 0.938775725299684,
    0.940476378684843, 0.945578338840318, 0.950680298995793, 0.952380952380952, 0.959183565921586, 0.959183565921586,
    0.964286090703019, 0.96598693229683, 0.962585249109208, 0.959183565921586, 0.955782259151269, 0.950680298995793,
    0.94387768545516, 0.937075071914526, 0.93197311175905, 0.925170498218416, 0.925170498218416, 0.925170498218416,
    0.925170498218416, 0.930272458373892, 0.937075071914526, 0.945578338840318, 0.95408160576611, 0.962585249109208,
    0.971088892452306, 0.977891505992939, 0.984694119533573, 0.989796079689049, 0.993197386459366, 0.993197386459366,
    0.996598693229683, 0.996598693229683, 0.993197386459366, 1,
  ],
  [
    0.216783176194445, 0.253496253199078, 0.293706163822224, 0.330419627773147, 0.368881121587037, 0.403845975255558,
    0.438811022397225, 0.475524486348147, 0.510489340016669, 0.548950833830559, 0.587412327644449, 0.627622238267595,
    0.666083732081484, 0.701048779223151, 0.730768962883338, 0.758741116680558, 0.784964660195377, 0.804195697312039,
    0.818181677474076, 0.832167464162969, 0.842656804179639, 0.849650084470374, 0.853146918088887, 0.860139811433334,
    0.860139811433334, 0.860139811433334, 0.860139811433334, 0.860139811433334, 0.863636258105557, 0.86713270477778,
    0.874125598122227, 0.881118491466673, 0.88811138481112, 0.895105052048145, 0.902097945392591, 0.909090838737038,
    0.914335508745373, 0.917831955417596, 0.923076625425931, 0.928321295434266, 0.930069518770377, 0.937062412114824,
    0.93881082892408, 0.944056079351849, 0.949300749360184, 0.952797196032407, 0.958041866040742, 0.961538312712965,
    0.965034759385188, 0.965034759385188, 0.965034759385188, 0.965034759385188, 0.961538312712965, 0.958041866040742,
    0.952797196032407, 0.947552526024072, 0.942307662542593, 0.937062412114824, 0.937062412114824, 0.935314188778712,
    0.937062412114824, 0.937062412114824, 0.944056079351849, 0.949300749360184, 0.95629364270463, 0.963286536049077,
    0.970279429393523, 0.97727232273797, 0.982516992746304, 0.987761856227784, 0.993007106655553, 0.993007106655553,
    0.998251776663888, 1, 1, 1,
  ],
  [
    0.217625817659535, 0.258992662560588, 0.296762641478188, 0.336331152907906, 0.374100733743941, 0.410071981108641,
    0.446043228473341, 0.480215943325923, 0.517985723202741, 0.553956771526658, 0.593525282956376, 0.633093595345311,
    0.670863375222129, 0.706834423546046, 0.737410272415176, 0.766186991649841, 0.785971446405482, 0.805755304038776,
    0.8219424947294, 0.834532620395788, 0.845323815468494, 0.852517945516964, 0.856115010541199, 0.86330914058967,
    0.86330914058967, 0.86330914058967, 0.86330914058967, 0.865107673101787, 0.87050327063814, 0.87230200219104,
    0.877698196849741, 0.883093794386094, 0.890287924434565, 0.895683521970917, 0.902877652019388, 0.908273249555741,
    0.913668847092093, 0.919065041750794, 0.920863773303694, 0.928057903352165, 0.928057903352165, 0.935252033400635,
    0.93884909842487, 0.942446163449106, 0.949640293497576, 0.953237358521811, 0.956834423546047, 0.964028553594517,
    0.964028553594517, 0.967626016700317, 0.971223479806118, 0.971223479806118, 0.971223479806118, 0.964028553594517,
    0.964028553594517, 0.958632956058164, 0.956834423546047, 0.949640293497576, 0.949640293497576, 0.946043228473341,
    0.944244695961223, 0.949640293497576, 0.949640293497576, 0.955035891033929, 0.960431488570282, 0.965827285147417,
    0.971223479806118, 0.976619077342471, 0.982014674878823, 0.985611739903059, 0.992805869951529, 0.992805869951529,
    0.998201467487882, 1, 1, 1,
  ],
  [
    0.220588103662089, 0.259191026888737, 0.29963235892445, 0.338235282151099, 0.375, 0.411764514417584,
    0.448529435697802, 0.483455744737638, 0.520220462586539, 0.558823385813188, 0.597426309039836, 0.636029232266485,
    0.674632155493133, 0.709558667964287, 0.738970360870881, 0.766543848399728, 0.788602719795331, 0.808823385813188,
    0.823529028835168, 0.834558261101654, 0.845588307093406, 0.852941128604396, 0.860293950115386, 0.863970360870881,
    0.867646771626376, 0.867646771626376, 0.867646771626376, 0.873161387759619, 0.874999593137366, 0.876837798515114,
    0.882352414648356, 0.886028825403852, 0.889705236159346, 0.897058871395603, 0.902573487528846, 0.906249898284341,
    0.911764514417584, 0.917279130550827, 0.919117335928574, 0.924631952061817, 0.926470157439564, 0.933822978950555,
    0.937499796568683, 0.941176614186812, 0.948529435697801, 0.952205846453297, 0.955882257208792, 0.963235078719782,
    0.963235078719782, 0.966911489475277, 0.970587900230772, 0.970587900230772, 0.970587900230772, 0.970587900230772,
    0.970587900230772, 0.966911489475277, 0.963235078719782, 0.963235078719782, 0.955882257208792, 0.955882257208792,
    0.955882257208792, 0.955882257208792, 0.955882257208792, 0.959558667964287, 0.963235078719782, 0.966911489475277,
    0.970587900230772, 0.977940721741762, 0.97977892711951, 0.985293543252752, 0.990808769679945, 0.99264717848901,
    0.994485383866757, 1, 1, 1,
  ],
  [
    0.219924663444296, 0.259398555674912, 0.298872239885439, 0.338345924095965, 0.375939938775527, 0.413533953455088,
    0.449248298603686, 0.486842105263157, 0.522556450411754, 0.562030342642371, 0.601503818832807, 0.640977503043333,
    0.678571517722895, 0.712406193340528, 0.746240452917981, 0.77067698890088, 0.791353353741491, 0.812030342642371,
    0.827067906910177, 0.838345924095965, 0.851503610812717, 0.859022496956666, 0.864662129609829, 0.872180807733687,
    0.872180807733687, 0.877819816326581, 0.879699485857546, 0.879699485857546, 0.88533849445044, 0.887218163981404,
    0.887218163981404, 0.894736842105263, 0.894736842105263, 0.902255520229121, 0.904135189760086, 0.90977419835298,
    0.913533953455088, 0.917293708557197, 0.922932717150091, 0.924812386681056, 0.932331064804914, 0.936090403866843,
    0.939849742928773, 0.947368421052631, 0.95112776011456, 0.95488709917649, 0.960526731829653, 0.962406609380707,
    0.966165948442636, 0.969925287504565, 0.969925287504565, 0.969925287504565, 0.969925287504565, 0.969925287504565,
    0.969925287504565, 0.969925287504565, 0.969925287504565, 0.969925287504565, 0.966165948442636, 0.962406609380707,
    0.962406609380707, 0.962406609380707, 0.962406609380707, 0.969925287504565, 0.969925287504565, 0.97180495703553,
    0.977443965628424, 0.979323635159389, 0.984962643752282, 0.988721982814212, 0.992481321876141, 0.99624066093807, 1,
    1, 1, 1,
  ],
  [
    0.219465505370712, 0.257633676456301, 0.29770979146515, 0.335877962550739, 0.374045711244493, 0.410305516014986,
    0.446564898393643, 0.484732647087397, 0.52099245185789, 0.559160411747561, 0.597328160441315, 0.637404486646082,
    0.675572657731671, 0.713740406425424, 0.744274689858794, 0.76717550803178, 0.790076326204767, 0.809159989355725,
    0.824427553464246, 0.835877962550739, 0.84923652675641, 0.856869992016794, 0.864503457277177, 0.870228556222465,
    0.874045711244493, 0.87786286626652, 0.879771232581616, 0.885496331526904, 0.885496331526904, 0.885496331526904,
    0.891221430472191, 0.893129796787287, 0.893129796787287, 0.896946529417479, 0.900763262047671, 0.904579994677862,
    0.908396727308054, 0.912213459938246, 0.916030192568438, 0.921755291513725, 0.925572235339835, 0.931297967872876,
    0.93893143313326, 0.942748165763452, 0.946564898393643, 0.952289997338931, 0.954198363654027, 0.954198363654027,
    0.96183182891441, 0.96183182891441, 0.96183182891441, 0.96183182891441, 0.96183182891441, 0.96183182891441,
    0.96183182891441, 0.96183182891441, 0.96183182891441, 0.96183182891441, 0.96183182891441, 0.96183182891441,
    0.96183182891441, 0.96183182891441, 0.96183182891441, 0.965648561544602, 0.969465294174794, 0.969465294174794,
    0.977099604218849, 0.977099604218849, 0.980916336849041, 0.984733069479233, 0.988549802109424, 0.992366534739616,
    0.992366534739616, 0.992366534739616, 1, 1,
  ],
  [
    0.218749864908911, 0.257812371663466, 0.29687487841802, 0.335937385172575, 0.373046799011262, 0.41015621284995,
    0.447265410542897, 0.482421731465718, 0.519531145304406, 0.55859343591322, 0.599609035583641, 0.638671758483936,
    0.677734049092749, 0.714843246785696, 0.746093598022525, 0.769530713012924, 0.791015383524679, 0.812499621744953,
    0.828124365071885, 0.839843354858567, 0.853515437561115, 0.861327809224581, 0.867187087972181, 0.874999459635647,
    0.87890564546738, 0.882811831299113, 0.886718017130846, 0.890624202962579, 0.890624202962579, 0.890624202962579,
    0.89843743920901, 0.89843743920901, 0.89843743920901, 0.89843743920901, 0.902343625040743, 0.906249810872476,
    0.908202903788343, 0.914062182535942, 0.917968368367675, 0.921874554199409, 0.929686925862875, 0.935546204610475,
    0.941405483358074, 0.945311669189807, 0.953124905436238, 0.953124905436238, 0.958984184183837, 0.960937277099704,
    0.960937277099704, 0.960937277099704, 0.953124905436238, 0.953124905436238, 0.953124905436238, 0.953124905436238,
    0.957031091267971, 0.960937277099704, 0.960937277099704, 0.960937277099704, 0.960937277099704, 0.960937277099704,
    0.960937277099704, 0.960937277099704, 0.96874964876317, 0.96874964876317, 0.97460892751077, 0.976562020426637,
    0.98046820625837, 0.984374392090103, 0.984374392090103, 0.990233670837703, 0.992186763753569, 0.992186763753569,
    0.994140072815177, 1, 1, 1,
  ],
  [
    0.218254030990185, 0.257936602040834, 0.295634857898703, 0.333333333333333, 0.371032028344725, 0.408730284202593,
    0.444444444444444, 0.480159043839817, 0.519841395313704, 0.557539651171573, 0.597222441798983, 0.63888910846565,
    0.676587364323519, 0.710317648526112, 0.742063617535927, 0.769841395313704, 0.793650762282684, 0.811508281557133,
    0.827381046485278, 0.839285620181388, 0.851190413454258, 0.861111550264633, 0.867063837112688, 0.873016123960743,
    0.880952506424816, 0.880952506424816, 0.888888888888888, 0.888888888888888, 0.892857080120925, 0.896825271352961,
    0.896825271352961, 0.896825271352961, 0.896825271352961, 0.898809366968979, 0.904761653817034, 0.904761653817034,
    0.908730284202593, 0.912698914588153, 0.918651201436207, 0.922619392668244, 0.928571679516298, 0.936508061980371,
    0.944444444444444, 0.950396731292499, 0.952380826908517, 0.958333113756571, 0.96031720937259, 0.96031720937259,
    0.96031720937259, 0.956349018140553, 0.952380826908517, 0.952380826908517, 0.952380826908517, 0.952380826908517,
    0.952380826908517, 0.952380826908517, 0.952380826908517, 0.952380826908517, 0.952380826908517, 0.96031720937259,
    0.96031720937259, 0.964285839758149, 0.968254470143708, 0.974206756991763, 0.976190852607781, 0.984127235071854,
    0.984127235071854, 0.990079521919908, 0.992063617535927, 0.992063617535927, 0.992063617535927, 0.992063617535927,
    0.998015904383981, 1, 1, 1,
  ],
  [
    0.216000099157339, 0.253999936255995, 0.291999773354652, 0.332000021248001, 0.370000079680005, 0.403999980522665,
    0.444000007082667, 0.478000129258674, 0.515999966357331, 0.555999992917332, 0.596000240810682, 0.636000046037336,
    0.67599985126399, 0.708000226645347, 0.739999938026662, 0.768000155818676, 0.791999773354652, 0.810000150506676,
    0.826000116864007, 0.837999925631995, 0.849999734399983, 0.857999828245322, 0.864000396629358, 0.870000301013352,
    0.87200026914135, 0.880000141653342, 0.880000141653342, 0.888000014165334, 0.888000014165334, 0.895999886677326,
    0.895999886677326, 0.895999886677326, 0.899999822933322, 0.903999759189318, 0.903999759189318, 0.903999759189318,
    0.911999631701309, 0.911999631701309, 0.920000389546691, 0.926000293930685, 0.932000198314679, 0.938000102698673,
    0.945999975210664, 0.951999879594659, 0.957999783978652, 0.95999975210665, 0.95999975210665, 0.95999975210665,
    0.95999975210665, 0.95999975210665, 0.95999975210665, 0.953999847722657, 0.951999879594659, 0.951999879594659,
    0.951999879594659, 0.951999879594659, 0.951999879594659, 0.951999879594659, 0.951999879594659, 0.95999975210665,
    0.95999975210665, 0.965999656490644, 0.972000003541333, 0.976000382464024, 0.984000254976016, 0.99000015936001,
    0.992000127488008, 0.992000127488008, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.215725910813382, 0.254032193288245, 0.292338475763109, 0.330645204474502, 0.366935613423508, 0.405241895898372,
    0.439516208203255, 0.477822713796384, 0.516128996271247, 0.554435501864376, 0.59677420074575, 0.637096802983002,
    0.675403085457865, 0.713709814169259, 0.743951263831101, 0.770161412677758, 0.792338475763109, 0.810483791796744,
    0.826613011186257, 0.836693494406871, 0.848790074271608, 0.854838364203976, 0.862902750780467, 0.864919070542855,
    0.870968029830019, 0.870968029830019, 0.87903241640651, 0.881048513050633, 0.887096802983002, 0.89314509291537,
    0.895161189559493, 0.901209479491861, 0.903225576135984, 0.903225576135984, 0.911289962712475, 0.911289962712475,
    0.915322156000721, 0.919354349288966, 0.92540330857613, 0.931451821626764, 0.937500111559132, 0.945564498135623,
    0.951612788067992, 0.959677174644483, 0.963709367932729, 0.967741561220974, 0.967741561220974, 0.967741561220974,
    0.967741561220974, 0.967741561220974, 0.967741561220974, 0.967741561220974, 0.961693271288606, 0.959677174644483,
    0.959677174644483, 0.959677174644483, 0.959677174644483, 0.959677174644483, 0.959677174644483, 0.959677174644483,
    0.963709367932729, 0.967741561220974, 0.975805947797465, 0.983871226847017, 0.989919516779385, 0.995967806711754, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.215447264194571, 0.25406497481659, 0.290650198030313, 0.329268358516743, 0.367886294070967, 0.402438804944189,
    0.443089452838914, 0.477642188644342, 0.516259899266361, 0.556910547161085, 0.597560970123604, 0.638211168153918,
    0.678861591116437, 0.711382064445776, 0.743902087910703, 0.772357811355657, 0.794715172846904, 0.813007784453765,
    0.825203383700152, 0.839430795558218, 0.845528257783104, 0.853658207416284, 0.853658207416284, 0.861788157049465,
    0.861788157049465, 0.861788157049465, 0.869918106682645, 0.873983531363647, 0.878048956044648, 0.886178905677828,
    0.892276367902714, 0.896341342719304, 0.902438804944189, 0.904471292352485, 0.91056875457737, 0.912601241985665,
    0.918698704210551, 0.922763679027141, 0.928861366184232, 0.934959503205734, 0.943089452838914, 0.949186915063799,
    0.955284377288685, 0.959349352105275, 0.967479301738456, 0.969511789146751, 0.975609251371636, 0.975609251371636,
    0.975609251371636, 0.975609251371636, 0.975609251371636, 0.973576763963341, 0.967479301738456, 0.967479301738456,
    0.963414326921865, 0.959349352105275, 0.959349352105275, 0.959349352105275, 0.959349352105275, 0.959349352105275,
    0.959349352105275, 0.965446814330161, 0.971544276555046, 0.979674226188227, 0.987804625685818, 0.993902537775114, 1,
    1, 1, 1, 1, 1, 1, 1, 0.991870050366819, 1,
  ],
  [
    0.219008517678779, 0.256198479384436, 0.293388441090094, 0.334711027252364, 0.367768821801891, 0.40909095066368,
    0.442149202513689, 0.481405247797412, 0.518595209503069, 0.55991779566534, 0.601239924527128, 0.642562282039157,
    0.681818555973121, 0.714876350522648, 0.747934145072175, 0.776860001115813, 0.799587149124772, 0.81818190132736,
    0.830578860096234, 0.840909735287043, 0.849174069599304, 0.851240153177369, 0.851240153177369, 0.8553723203335,
    0.85950448748963, 0.85950448748963, 0.85950448748963, 0.867768821801892, 0.873967072536088, 0.882231406848349,
    0.888430114883027, 0.894628822917705, 0.900827073651901, 0.907025324386097, 0.909091407964162, 0.917355742276423,
    0.919421825854489, 0.925620076588684, 0.933884410900946, 0.938016578057076, 0.944215057441513, 0.950413994126432,
    0.958678328438693, 0.964876579172889, 0.966942662750955, 0.975206997063216, 0.975206997063216, 0.975206997063216,
    0.981405247797412, 0.983471331375477, 0.975206997063216, 0.975206997063216, 0.975206997063216, 0.966942662750955,
    0.966942662750955, 0.958678328438693, 0.958678328438693, 0.954546161282563, 0.950413994126432, 0.950413994126432,
    0.954546161282563, 0.958678328438693, 0.964876579172889, 0.971074829907085, 0.979339164219347, 0.985537414953543,
    0.991735665687739, 0.991735665687739, 0.997933916421934, 1, 0.995867832843869, 0.991735665687739, 0.991735665687739,
    0.991735665687739, 0.991735665687739, 1,
  ],
  [
    0.224358913726354, 0.262820306669605, 0.303418653858341, 0.339743565490541, 0.380341912679277, 0.414529870065993,
    0.452991499476461, 0.491452892419712, 0.527777804051913, 0.568376151240648, 0.611110979740434, 0.653846044707438,
    0.694444391896173, 0.730769067061157, 0.762820543136822, 0.788461156476033, 0.811965997905846, 0.829059740131986,
    0.841880046801591, 0.852563635692929, 0.856837307716682, 0.863248170453137, 0.863248170453137, 0.863248170453137,
    0.863248170453137, 0.863248170453137, 0.865384888231404, 0.871795041566207, 0.880341912679277, 0.88675206601408,
    0.89529893712715, 0.901709090461952, 0.908119243796755, 0.914529397131558, 0.920940259868013, 0.923077214113498,
    0.931624085226568, 0.935897520783103, 0.942307674117906, 0.948717827452708, 0.955127980787511, 0.961538134122314,
    0.965811569678849, 0.974358440791919, 0.974358440791919, 0.982906257773859, 0.982906257773859, 0.985042975552127,
    0.991453128886929, 0.991453128886929, 0.989316411108662, 0.982906257773859, 0.982906257773859, 0.974358440791919,
    0.972221723013651, 0.965811569678849, 0.963674851900581, 0.957264698565779, 0.957264698565779, 0.957264698565779,
    0.957264698565779, 0.957264698565779, 0.963674851900581, 0.970085005235384, 0.976495395037404, 0.982906257773859,
    0.989316411108662, 0.991453128886929, 0.991453128886929, 0.995726564443465, 1, 1, 1, 1, 0.995726564443465, 1,
  ],
  [
    0.23230091745635, 0.269911437256876, 0.311946911321693, 0.349557675960058, 0.384955841047357, 0.429203669825401,
    0.464602079750539, 0.499999999999999, 0.535398654762977, 0.577433883989955, 0.617256758503707, 0.659292477406364,
    0.701327706633342, 0.738938226433868, 0.769911926932554, 0.796460428329108, 0.818583975461372, 0.836283792518539,
    0.849557920797897, 0.858407339650802, 0.865044403790481, 0.867256758503707, 0.867256758503707, 0.867256758503707,
    0.867256758503707, 0.867256758503707, 0.876106177356613, 0.878318532069839, 0.884955596209518, 0.891593394862714,
    0.900443058553458, 0.907080122693137, 0.91150483211959, 0.920354250972495, 0.924778960398948, 0.929203669825401,
    0.93584073396508, 0.940265443391532, 0.946902507531211, 0.953540306184407, 0.957965260448699, 0.964602324588378,
    0.969027034014831, 0.973451743441283, 0.980088807580962, 0.982301162294189, 0.984513517007415, 0.991150581147094,
    0.991150581147094, 0.991150581147094, 0.991150581147094, 0.986725871720641, 0.982301162294189, 0.980088807580962,
    0.973451743441283, 0.971239388728057, 0.964602324588378, 0.964602324588378, 0.955752905735473, 0.955752905735473,
    0.955752905735473, 0.955752905735473, 0.962389969875152, 0.964602324588378, 0.973451743441283, 0.977876452867736,
    0.982301162294189, 0.986725871720642, 0.991150581147094, 0.991150581147094, 0.993362935860321, 1, 1, 1, 1, 1,
  ],
  [
    0.23636360435262, 0.277272539779632, 0.315909036033063, 0.359090913663911, 0.395454467713506, 0.431818273278228,
    0.468181827327822, 0.504545381377417, 0.540909186942139, 0.579545431680442, 0.622727309311291, 0.661363554049594,
    0.704545180165316, 0.740909237245164, 0.772726906887087, 0.800000201212101, 0.82045441741048, 0.838635942920151,
    0.849999899393949, 0.859091165179038, 0.863636546556456, 0.865909237245164, 0.872727309311291, 0.872727309311291,
    0.872727309311291, 0.872727309311291, 0.881818072066126, 0.881818072066126, 0.890908834820961, 0.895454216198379,
    0.899999597575796, 0.909090360330631, 0.913636244738303, 0.918182129145975, 0.922727510523393, 0.92727289190081,
    0.934090963966936, 0.936363654655645, 0.94545441741048, 0.947727108099189, 0.954545180165316, 0.961363252231442,
    0.963635942920151, 0.970454014986277, 0.972726705674986, 0.977272590082658, 0.981818474490329, 0.981818474490329,
    0.981818474490329, 0.981818474490329, 0.981818474490329, 0.981818474490329, 0.981818474490329, 0.974999647878822,
    0.972726705674986, 0.972726705674986, 0.965908633608859, 0.963635942920151, 0.963635942920151, 0.963635942920151,
    0.963635942920151, 0.963635942920151, 0.963635942920151, 0.963635942920151, 0.970454014986277, 0.972726705674986,
    0.977272590082658, 0.981818474490329, 0.981818474490329, 0.990909237245164, 0.990909237245164, 0.995454618622582, 1,
    1, 1, 1,
  ],
  [
    0.233944961114382, 0.277522742502081, 0.316513696265762, 0.353210855483582, 0.389908014701403, 0.426605681564359,
    0.463302586959611, 0.5, 0.53669715921782, 0.57339431843564, 0.619266148191768, 0.65596305358702, 0.692660466627408,
    0.729357625845229, 0.766054785063049, 0.791284240664406, 0.811926614819176, 0.830274940605519, 0.83944910349869,
    0.848623266391861, 0.853210347838447, 0.860091731476028, 0.862385526021889, 0.862385526021889, 0.87155968891506,
    0.87155968891506, 0.878440311084939, 0.880733851808231, 0.88761447397811, 0.889908014701403, 0.896788636871281,
    0.899082177594574, 0.905962799764452, 0.908256340487745, 0.912843421934331, 0.917430503380916, 0.919724297926777,
    0.926605681564359, 0.931192763010944, 0.93577984445753, 0.940366925904115, 0.944954007350701, 0.95183462952058,
    0.954128170243872, 0.961008792413751, 0.963302333137043, 0.963302333137043, 0.963302333137043, 0.963302333137043,
    0.963302333137043, 0.963302333137043, 0.963302333137043, 0.963302333137043, 0.963302333137043, 0.963302333137043,
    0.963302333137043, 0.963302333137043, 0.963302333137043, 0.958715251690458, 0.954128170243872, 0.954128170243872,
    0.954128170243872, 0.954128170243872, 0.961008792413751, 0.963302333137043, 0.963302333137043, 0.965595873860336,
    0.972476496030215, 0.972476496030215, 0.9770635774768, 0.981650658923386, 0.983944453469246, 0.990825837106828,
    0.990825837106828, 0.997706459276707, 1,
  ],
  [
    0.238317938247969, 0.275700794421569, 0.320093663347699, 0.359813190438809, 0.397196305179512, 0.434579937054424,
    0.469626122310514, 0.500000517134207, 0.537383373307807, 0.574766746615615, 0.621495769325046, 0.658878625498646,
    0.696262257373558, 0.733645113547158, 0.768692074504559, 0.794392610359025, 0.813084684863585, 0.829439829883531,
    0.843458303985967, 0.850467541037185, 0.857476778088403, 0.859813190438809, 0.869158839840433, 0.869158839840433,
    0.876168852592962, 0.878505523510472, 0.88551476056169, 0.887851172912096, 0.894860409963314, 0.89719682231372,
    0.899533234664126, 0.906542471715344, 0.906542471715344, 0.911215296416156, 0.915888121116968, 0.915888121116968,
    0.925233770518592, 0.925233770518592, 0.93224300756981, 0.934579419920216, 0.941589432672746, 0.943926103590255,
    0.953271752991879, 0.953271752991879, 0.960280990043097, 0.962617402393503, 0.962617402393503, 0.962617402393503,
    0.962617402393503, 0.962617402393503, 0.962617402393503, 0.962617402393503, 0.962617402393503, 0.962617402393503,
    0.962617402393503, 0.962617402393503, 0.962617402393503, 0.962617402393503, 0.962617402393503, 0.962617402393503,
    0.962617402393503, 0.962617402393503, 0.962617402393503, 0.962617402393503, 0.96495381474391, 0.971963051795128,
    0.971963051795128, 0.971963051795128, 0.974299464145533, 0.981308701196752, 0.981308701196752, 0.990654350598375,
    0.990654350598375, 1, 1, 1,
  ],
  [
    0.238095162811777, 0.280952155101997, 0.319047844898002, 0.357142744217665, 0.395238170521556, 0.433333596825447,
    0.47142849614511, 0.507143271201892, 0.538095162811776, 0.576190852607781, 0.623809674376445, 0.661904837188222,
    0.700000263492113, 0.738095162811777, 0.769048108390115, 0.797619085260778, 0.819047581405888, 0.83333386031756,
    0.845238434013669, 0.854762092970557, 0.859523922449001, 0.866666666666666, 0.87142849614511, 0.876190325623554,
    0.878571240362775, 0.885713984580441, 0.888095162811777, 0.895238697505783, 0.895238697505783, 0.902381441723449,
    0.904762356462671, 0.904762356462671, 0.911905100680336, 0.914286015419558, 0.914286015419558, 0.923809674376445,
    0.923809674376445, 0.928571503854889, 0.933333333333333, 0.935714248072555, 0.94285699229022, 0.94285699229022,
    0.952380651247108, 0.952380651247108, 0.954761829478443, 0.96190536417245, 0.96190536417245, 0.96190536417245,
    0.96190536417245, 0.96190536417245, 0.96190536417245, 0.96190536417245, 0.96190536417245, 0.96190536417245,
    0.96190536417245, 0.96190536417245, 0.96190536417245, 0.96190536417245, 0.96190536417245, 0.96190536417245,
    0.96190536417245, 0.96190536417245, 0.96190536417245, 0.96190536417245, 0.966667193650893, 0.971429023129337,
    0.971429023129337, 0.971429023129337, 0.978571767347003, 0.980952682086225, 0.980952682086225, 0.990476341043112,
    0.990476341043112, 0.997619085260778, 1, 1,
  ],
  [
    0.237864004650148, 0.276698867439614, 0.32524284883902, 0.364077443020103, 0.402912574417952, 0.436893415119213,
    0.470873718603709, 0.509708850001558, 0.54854344418264, 0.587378844188872, 0.626213438369955, 0.665048569767804,
    0.70388343255727, 0.742718295346736, 0.771844576743026, 0.798543712791023, 0.820388020925667, 0.837378306972106,
    0.849514839538724, 0.859223420931899, 0.864077711628486, 0.873786293021661, 0.873786293021661, 0.881067729066543,
    0.883494874414836, 0.88592201976313, 0.893203455808011, 0.893203455808011, 0.900484891852892, 0.902912037201186,
    0.905339451157863, 0.912621693027893, 0.912621693027893, 0.912621693027893, 0.922330274421068, 0.922330274421068,
    0.924757419769362, 0.932038855814243, 0.932038855814243, 0.939320291859124, 0.941747437207418, 0.941747437207418,
    0.951456018600593, 0.951456018600593, 0.951456018600593, 0.951456018600593, 0.961164599993768, 0.961164599993768,
    0.961164599993768, 0.961164599993768, 0.961164599993768, 0.961164599993768, 0.961164599993768, 0.961164599993768,
    0.961164599993768, 0.961164599993768, 0.953883163948887, 0.951456018600593, 0.951456018600593, 0.951456018600593,
    0.951456018600593, 0.951456018600593, 0.951456018600593, 0.958737454645474, 0.961164599993768, 0.966018890690356,
    0.970873181386943, 0.970873181386943, 0.975728009300296, 0.98058283721365, 0.98058283721365, 0.987864273258531,
    0.990291418606825, 0.990291418606825, 0.992718563955118, 1,
  ],
  [
    0.242574105545274, 0.282177962879567, 0.321782094141133, 0.361385677548153, 0.400990082736993, 0.440593940071286,
    0.477722590563095, 0.509900827369936, 0.549504684704229, 0.589108815965796, 0.628712399372816, 0.668316804561656,
    0.707920387968675, 0.747524519230242, 0.779702482109809, 0.806930579158955, 0.826732233898828, 0.846533888638702,
    0.858910744632942, 0.868811845930152, 0.873762259615121, 0.881187880142573, 0.881187880142573, 0.89108870751251,
    0.89108870751251, 0.89108870751251, 0.898514328039962, 0.900989534882446, 0.900989534882446, 0.910890362252383,
    0.910890362252383, 0.915840775937351, 0.92079118962232, 0.92079118962232, 0.923266670392077, 0.93069311270135,
    0.93069311270135, 0.93069311270135, 0.940593940071286, 0.940593940071286, 0.940593940071286, 0.950494767441223,
    0.950494767441223, 0.950494767441223, 0.952969974283707, 0.96039559481116, 0.96039559481116, 0.96039559481116,
    0.96039559481116, 0.96039559481116, 0.96039559481116, 0.96039559481116, 0.96039559481116, 0.96039559481116,
    0.96039559481116, 0.957920387968675, 0.950494767441223, 0.950494767441223, 0.950494767441223, 0.943069146913771,
    0.940593940071286, 0.950494767441223, 0.950494767441223, 0.955445181126191, 0.96039559481116, 0.970296422181096,
    0.970296422181096, 0.97277162902358, 0.980197249551033, 0.980197249551033, 0.980197249551033, 0.990098076920969,
    0.990098076920969, 0.995049038460484, 1, 1,
  ],
  [
    0.245000077466705, 0.285000011066672, 0.325000221333445, 0.364999878266605, 0.405000365200185, 0.445000298800151,
    0.475000387333529, 0.515000320933495, 0.547500370733521, 0.58500034306684, 0.625000276666806, 0.672500370733521,
    0.715000265600134, 0.750000553333613, 0.782500049800025, 0.81000073040037, 0.830000420533546, 0.847500149400075,
    0.862500193666764, 0.870000907467126, 0.877500791267067, 0.880000752533714, 0.887500636333655, 0.890000597600302,
    0.890000597600302, 0.890000597600302, 0.90000044266689, 0.90000044266689, 0.902500403933538, 0.910000287733479,
    0.910000287733479, 0.91750017153342, 0.920000132800067, 0.920000132800067, 0.922500094066714, 0.929999977866655,
    0.929999977866655, 0.929999977866655, 0.929999977866655, 0.940000929600471, 0.940000929600471, 0.940000929600471,
    0.947500813400412, 0.950000774667059, 0.950000774667059, 0.955000697200353, 0.960000619733647, 0.960000619733647,
    0.960000619733647, 0.960000619733647, 0.960000619733647, 0.960000619733647, 0.960000619733647, 0.960000619733647,
    0.960000619733647, 0.952500735933706, 0.950000774667059, 0.950000774667059, 0.940000929600471, 0.940000929600471,
    0.940000929600471, 0.945000852133765, 0.950000774667059, 0.957500658467, 0.960000619733647, 0.970000464800235,
    0.970000464800235, 0.970000464800235, 0.972500426066882, 0.980000309866823, 0.980000309866823, 0.980000309866823,
    0.990000154933411, 0.990000154933411, 0.990000154933411, 1,
  ],
  [
    0.23979579737607, 0.290816245869761, 0.331632774052501, 0.372448737609283, 0.410714568027264, 0.443877591350833,
    0.484694119533573, 0.517857142857142, 0.556122690962145, 0.596938936831906, 0.637755182701667, 0.678571710884407,
    0.719387674441189, 0.760204484936908, 0.795918206025236, 0.823980116132266, 0.846938936831906, 0.862244817298332,
    0.874999717687021, 0.882653222546191, 0.887755747327625, 0.895408687560838, 0.897959667638576, 0.897959667638576,
    0.897959667638576, 0.903061627794051, 0.908163587949526, 0.908163587949526, 0.913265548105002, 0.918367508260477,
    0.920918488338215, 0.928571428571428, 0.928571428571428, 0.928571428571428, 0.933673388726904, 0.938775348882379,
    0.938775348882379, 0.938775348882379, 0.938775348882379, 0.938775348882379, 0.94897926919333, 0.94897926919333,
    0.954081793974763, 0.959184318756196, 0.961735298833934, 0.969388239067147, 0.969388239067147, 0.969388239067147,
    0.979592159378098, 0.979592159378098, 0.979592159378098, 0.979592159378098, 0.979592159378098, 0.974490199222622,
    0.969388239067147, 0.969388239067147, 0.961735298833934, 0.959184318756196, 0.959184318756196, 0.959184318756196,
    0.959184318756196, 0.959184318756196, 0.964286278911672, 0.969388239067147, 0.979592159378098, 0.979592159378098,
    0.979592159378098, 0.979592159378098, 0.979592159378098, 0.979592159378098, 0.989796079689049, 0.989796079689049,
    0.989796079689049, 1, 1, 1,
  ],
  [
    0.244791462528963, 0.286458321325233, 0.328124891927098, 0.369791462528963, 0.411458321325233, 0.453124891927098,
    0.484375252170103, 0.526041534577564, 0.55729189482057, 0.598958177228031, 0.6406250360243, 0.684895731264481,
    0.734374675781295, 0.770833573495337, 0.807291318431761, 0.833333429398134, 0.859374963975699, 0.872395587167279,
    0.885416210358859, 0.895832708912123, 0.898437121744844, 0.906250360243005, 0.906250360243005, 0.906250360243005,
    0.906250360243005, 0.906250360243005, 0.916666858796269, 0.916666858796269, 0.919270983434585, 0.927083357349533,
    0.929687481987849, 0.937499855902797, 0.937499855902797, 0.937499855902797, 0.937499855902797, 0.937499855902797,
    0.937499855902797, 0.942708105179429, 0.947916354456061, 0.947916354456061, 0.953124603732694, 0.958332853009326,
    0.963541102285958, 0.96874935156259, 0.976562590060751, 0.979167002893471, 0.981771127531787, 0.989583501446736,
    0.989583501446736, 0.989583501446736, 0.989583501446736, 0.989583501446736, 0.989583501446736, 0.989583501446736,
    0.981771127531788, 0.979167002893471, 0.979167002893471, 0.96874935156259, 0.96874935156259, 0.96874935156259,
    0.96874935156259, 0.97135376439531, 0.979167002893471, 0.984375252170103, 0.989583501446736, 0.989583501446736,
    0.989583501446736, 0.989583501446736, 0.989583501446736, 0.989583501446736, 0.989583501446736, 0.989583501446736,
    0.994791750723368, 1, 1, 1,
  ],
  [
    0.2499997056739, 0.292553078768728, 0.335106157537456, 0.377659236306183, 0.412233723178062, 0.452127271314507,
    0.486702346838584, 0.526595306322831, 0.561170381846908, 0.601063341331154, 0.64361700875208, 0.694148679417658,
    0.739361288818669, 0.781914661913496, 0.813829323826992, 0.843084749434305, 0.867021113777051, 0.880318766938467,
    0.893616420099882, 0.901595011996731, 0.904254542629014, 0.904254542629014, 0.914892665158147, 0.914892665158147,
    0.914892665158147, 0.914892665158147, 0.914892665158147, 0.922872140033294, 0.925531964991676, 0.930851026256242,
    0.936170087520808, 0.938829618153092, 0.946808210049941, 0.946808210049941, 0.946808210049941, 0.946808210049941,
    0.946808210049941, 0.946808210049941, 0.946808210049941, 0.952127271314507, 0.957446332579073, 0.962765393843639,
    0.968084455108206, 0.978722577637338, 0.981382108269621, 0.98936070016647, 0.98936070016647, 0.98936070016647, 1, 1,
    1, 1, 1, 0.992020525124853, 0.98936070016647, 0.98936070016647, 0.981382108269621, 0.978722577637338,
    0.978722577637338, 0.978722577637338, 0.978722577637338, 0.978722577637338, 0.986701169534187, 0.98936070016647,
    0.997340175041617, 1, 1, 1, 0.98936070016647, 0.98936070016647, 0.98936070016647, 0.98936070016647,
    0.98936070016647, 0.992020525124853, 1, 1,
  ],
  [
    0.244565001653986, 0.28804378552311, 0.331521667217995, 0.375000150362373, 0.418478332782004, 0.451087119959101,
    0.489130905482212, 0.527174089555829, 0.56250022554356, 0.603261059152557, 0.646739542296935, 0.69021742399182,
    0.744565603103479, 0.777174089555829, 0.817935223889573, 0.847826060806543, 0.869565753465852, 0.885870147054401,
    0.894022193486302, 0.902174239918202, 0.904891588728836, 0.913043635160737, 0.913043635160737, 0.913043635160737,
    0.913043635160737, 0.913043635160737, 0.913043635160737, 0.923913030403271, 0.923913030403271, 0.932065076835172,
    0.934782425645806, 0.940217724716567, 0.945653023787327, 0.945653023787327, 0.945653023787327, 0.945653023787327,
    0.945653023787327, 0.945653023787327, 0.945653023787327, 0.948370372597961, 0.956522419029862, 0.961957116651129,
    0.97010916308303, 0.978261209514931, 0.983695907136198, 0.989130604757465, 0.989130604757465, 0.991847953568099, 1,
    1, 1, 1, 1, 0.989130604757465, 0.989130604757465, 0.989130604757465, 0.986413255946832, 0.978261209514931,
    0.978261209514931, 0.978261209514931, 0.978261209514931, 0.978261209514931, 0.986413255946831, 0.989130604757465,
    0.989130604757465, 0.989130604757465, 0.989130604757465, 0.989130604757465, 0.989130604757465, 0.989130604757465,
    0.989130604757465, 0.989130604757465, 0.989130604757465, 0.989130604757465, 0.989130604757465, 1,
  ],
  [
    0.252808632495955, 0.297752969658686, 0.342696374237301, 0.384831457181332, 0.421348031687964, 0.460674171274668,
    0.497191056642672, 0.533707942010676, 0.570224516517308, 0.612359599461339, 0.657303314901326, 0.702247030341313,
    0.747191056642672, 0.789325517863959, 0.823033770736007, 0.853932458527984, 0.873595062029278, 0.890449033034616,
    0.898876795690715, 0.910112569120026, 0.910112569120026, 0.910112569120026, 0.918539399192009, 0.921348342549336,
    0.921348342549336, 0.921348342549336, 0.921348342549336, 0.926966229263992, 0.932584115978647, 0.932584115978647,
    0.943819889407958, 0.943819889407958, 0.943819889407958, 0.943819889407958, 0.943819889407958, 0.943819889407958,
    0.943819889407958, 0.943819889407958, 0.946628832765286, 0.955055662837269, 0.957864606194596, 0.966291436266579,
    0.974719198922678, 0.980337396498706, 0.988764226570689, 0.988764226570689, 0.997191056642672, 1, 1, 1, 1, 1, 1, 1,
    0.988764226570689, 0.988764226570689, 0.988764226570689, 0.988764226570689, 0.983146339856033, 0.977528453141378,
    0.983146339856034, 0.988764226570689, 0.988764226570689, 0.988764226570689, 1, 1, 1, 0.997191056642672,
    0.988764226570689, 0.988764226570689, 0.988764226570689, 0.988764226570689, 0.988764226570689, 0.988764226570689,
    0.988764226570689, 1,
  ],
  [
    0.261627727420126, 0.308139923923058, 0.34593003803847, 0.38953530268637, 0.430232348656814, 0.470930681449682,
    0.505814185415669, 0.540698332792867, 0.581395378763312, 0.62209371155618, 0.668604942942293, 0.706396022174524,
    0.750000321705605, 0.793605264647899, 0.825581515053248, 0.854652120425989, 0.877907575266243, 0.892442234541402,
    0.901163030106497, 0.90697689381656, 0.912790757526624, 0.918604621236687, 0.918604621236687, 0.918604621236687,
    0.918604621236687, 0.918604621236687, 0.930233635479238, 0.930233635479238, 0.930233635479238, 0.941861362899365,
    0.941861362899365, 0.941861362899365, 0.941861362899365, 0.941861362899365, 0.941861362899365, 0.941861362899365,
    0.941861362899365, 0.941861362899365, 0.944768294754397, 0.953489090319492, 0.953489090319492, 0.965116817739619,
    0.970930681449682, 0.976744545159746, 0.985465340724841, 0.988372272579873, 0.988372272579873, 0.988372272579873,
    0.988372272579873, 0.988372272579873, 0.988372272579873, 0.988372272579873, 0.988372272579873, 0.988372272579873,
    0.988372272579873, 0.988372272579873, 0.988372272579873, 0.988372272579873, 0.988372272579873, 0.988372272579873,
    0.988372272579873, 0.988372272579873, 0.988372272579873, 0.997093068144968, 1, 1, 1, 1, 1, 1, 0.991279204434904,
    0.988372272579873, 0.988372272579873, 0.988372272579873, 0.988372272579873, 1,
  ],
  [
    0.26506, 0.307229, 0.355421333333333, 0.400602666666666, 0.439758666666666, 0.478916, 0.51506, 0.554217333333333,
    0.590361333333333, 0.632530333333333, 0.671686666666666, 0.716867666666666, 0.759036, 0.798193, 0.831325333333333,
    0.861445333333333, 0.879518333333333, 0.894578666666666, 0.903614666666666, 0.912650666666666, 0.915662666666666,
    0.915662666666666, 0.915662666666666, 0.915662666666666, 0.927710666666666, 0.927710666666666, 0.927710666666666,
    0.927710666666666, 0.936746666666666, 0.939758666666666, 0.939758666666666, 0.939758666666666, 0.939758666666666,
    0.939758666666666, 0.939758666666666, 0.939758666666666, 0.939758666666666, 0.939758666666666, 0.942770666666666,
    0.951806666666666, 0.951806666666666, 0.963855999999999, 0.963855999999999, 0.975904, 0.975904, 0.978915999999999,
    0.987951999999999, 0.987951999999999, 0.987951999999999, 0.987951999999999, 0.987951999999999, 0.987951999999999,
    0.987951999999999, 0.987951999999999, 0.987951999999999, 0.987951999999999, 0.987951999999999, 0.987951999999999,
    0.987951999999999, 0.987951999999999, 0.987951999999999, 0.993976, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.26543184575532, 0.314814776863298, 0.361110655692909, 0.401234479347695, 0.444443989026243, 0.481481443529964,
    0.521604584057448, 0.558642038561169, 0.595678809937589, 0.635802292028724, 0.675925774119858, 0.722221994513121,
    0.759259107453192, 0.799382247980675, 0.833333333333333, 0.858024286541845, 0.879628870599293, 0.891974688767201,
    0.901234820911346, 0.907407559213474, 0.913580297515602, 0.913580297515602, 0.913580297515602, 0.913580297515602,
    0.913580297515602, 0.916666666666666, 0.925925774119858, 0.925925774119858, 0.925925774119858, 0.925925774119858,
    0.932098512421986, 0.938271250724114, 0.938271250724114, 0.938271250724114, 0.938271250724114, 0.938271250724114,
    0.938271250724114, 0.938271250724114, 0.938271250724114, 0.938271250724114, 0.950616727328371, 0.950616727328371,
    0.956789465630499, 0.962962203932627, 0.962962203932627, 0.966048573083691, 0.975307680536883, 0.975307680536883,
    0.975307680536883, 0.975307680536883, 0.975307680536883, 0.975307680536883, 0.975307680536883, 0.975307680536883,
    0.975307680536883, 0.975307680536883, 0.975307680536883, 0.975307680536883, 0.987654523395743, 0.987654523395743,
    0.987654523395743, 0.990740892546807, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.268749688750186, 0.315624948125031, 0.356249619583561, 0.403124878958406, 0.443749550416936, 0.481249619583561,
    0.518749688750186, 0.556249757916812, 0.593749827083437, 0.631249550416936, 0.674999861666749, 0.718749481250311,
    0.756249896250062, 0.796874221875467, 0.828124740625155, 0.85312467145853, 0.871874360208717, 0.884374152708841,
    0.893748997083935, 0.899998893333997, 0.899998893333997, 0.899998893333997, 0.899998893333997, 0.899998893333997,
    0.906249481250311, 0.912500069166625, 0.912500069166625, 0.912500069166625, 0.912500069166625, 0.912500069166625,
    0.912500069166625, 0.912500069166625, 0.918749965416687, 0.924999861666749, 0.924999861666749, 0.924999861666749,
    0.924999861666749, 0.924999861666749, 0.924999861666749, 0.931249757916811, 0.937499654166874, 0.937499654166874,
    0.943749550416936, 0.949999446666998, 0.949999446666998, 0.949999446666998, 0.949999446666998, 0.95624934291706,
    0.962499239167123, 0.962499239167123, 0.962499239167123, 0.962499239167123, 0.962499239167123, 0.962499239167123,
    0.962499239167123, 0.962499239167123, 0.974999031667247, 0.974999031667247, 0.974999031667247, 0.974999031667247,
    0.984373876042341, 0.987498824167372, 0.987498824167372, 0.987498824167372, 0.987498824167372, 0.987498824167372, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.272151907600282, 0.310126706403961, 0.360759538001414, 0.398734336805093, 0.443038152005659, 0.48101260059816,
    0.518987749613016, 0.553797690007074, 0.59177283902193, 0.629746937403254, 0.670886944827731, 0.715190059605942,
    0.753165208620798, 0.794304165411743, 0.822785089408913, 0.848102205629994, 0.867089254820656, 0.879747287614431,
    0.886076304011318, 0.898734336805093, 0.898734336805093, 0.898734336805093, 0.898734336805093, 0.898734336805093,
    0.898734336805093, 0.898734336805093, 0.898734336805093, 0.898734336805093, 0.898734336805093, 0.898734336805093,
    0.898734336805093, 0.898734336805093, 0.908227861400424, 0.911392369598868, 0.911392369598868, 0.911392369598868,
    0.911392369598868, 0.911392369598868, 0.920886944827731, 0.924051803237352, 0.924051803237352, 0.930380819634239,
    0.936709836031126, 0.936709836031126, 0.936709836031126, 0.936709836031126, 0.949367868824901, 0.949367868824901,
    0.949367868824901, 0.949367868824901, 0.949367868824901, 0.949367868824901, 0.958861393420232, 0.962025901618676,
    0.962025901618676, 0.962025901618676, 0.962025901618676, 0.962025901618676, 0.971519426214007, 0.97468393441245,
    0.97468393441245, 0.97468393441245, 0.97468393441245, 0.97468393441245, 0.984177459007781, 0.987341967206225,
    0.987341967206225, 0.987341967206225, 0.987341967206225, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.269480570810152, 0.318181818181818, 0.363636363636363, 0.409090909090909, 0.448052050711213, 0.487012833024089,
    0.5227276320347, 0.558441353123028, 0.597403213358187, 0.636363636363636, 0.678571839208488, 0.720779323438485,
    0.759740465058789, 0.795454904761973, 0.831168625850301, 0.857143319109549, 0.876623889919701, 0.886363995671064,
    0.896104101422426, 0.896104101422426, 0.896104101422426, 0.896104101422426, 0.896104101422426, 0.896104101422426,
    0.896104101422426, 0.896104101422426, 0.896104101422426, 0.896104101422426, 0.896104101422426, 0.896104101422426,
    0.896104101422426, 0.899350803339546, 0.909090909090909, 0.909090909090909, 0.909090909090909, 0.909090909090909,
    0.909090909090909, 0.922077716759391, 0.922077716759391, 0.922077716759391, 0.925324418676512, 0.935064524427874,
    0.935064524427874, 0.935064524427874, 0.941558646876971, 0.948052769326068, 0.948052769326068, 0.948052769326068,
    0.948052769326068, 0.95454617316031, 0.961039576994551, 0.961039576994551, 0.961039576994551, 0.961039576994551,
    0.961039576994551, 0.961039576994551, 0.961039576994551, 0.961039576994551, 0.967532980828792, 0.974026384663034,
    0.974026384663034, 0.974026384663034, 0.974026384663034, 0.974026384663034, 0.983766490414396, 0.987013192331517,
    0.987013192331517, 0.996753298082879, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.266233868893031, 0.314935116264697, 0.357142959802122, 0.399350444032119, 0.441558646876971, 0.48051906988242,
    0.516234228200459, 0.548701247371666, 0.587662748299397, 0.626623530612274, 0.668831374149699, 0.707792515770003,
    0.749999999999999, 0.788961500927731, 0.818181818181818, 0.844155792826211, 0.863637082251219, 0.873377188002581,
    0.883117293753943, 0.883117293753943, 0.883117293753943, 0.883117293753943, 0.883117293753943, 0.883117293753943,
    0.876623889919701, 0.87013048608546, 0.87013048608546, 0.87013048608546, 0.87013048608546, 0.873377188002581,
    0.883117293753943, 0.883117293753943, 0.883117293753943, 0.883117293753943, 0.892857399505305, 0.896104101422426,
    0.896104101422426, 0.896104101422426, 0.905844207173788, 0.909090909090909, 0.909090909090909, 0.909090909090909,
    0.922077716759391, 0.922077716759391, 0.922077716759391, 0.928571120593633, 0.935064524427874, 0.935064524427874,
    0.935064524427874, 0.935064524427874, 0.948052769326068, 0.948052769326068, 0.948052769326068, 0.948052769326068,
    0.948052769326068, 0.948052769326068, 0.935064524427874, 0.935064524427874, 0.935064524427874, 0.935064524427874,
    0.935064524427874, 0.94480570810152, 0.948052769326068, 0.948052769326068, 0.961039576994551, 0.961039576994551,
    0.974026384663034, 0.974026384663034, 0.983766490414396, 0.987013192331517, 0.987013192331517, 0.987013192331517,
    0.987013192331517, 0.987013192331517, 0.987013192331517, 1,
  ],
  [
    0.269736890004616, 0.312499726973689, 0.358552713007847, 0.40131554997692, 0.437500273026311, 0.47697332496538,
    0.513158048014771, 0.549342042993999, 0.585526402008308, 0.62500018201754, 0.667763018986613, 0.710526584025849,
    0.749999635964918, 0.789474144044313, 0.822368353993537, 0.848683721952917, 0.865131919032773, 0.878289967047544,
    0.881579388042466, 0.891447651027234, 0.888158230032311, 0.881579388042466, 0.881579388042466, 0.881579388042466,
    0.878289967047544, 0.868421704062777, 0.868421704062777, 0.868421704062777, 0.868421704062777, 0.878289967047544,
    0.881579388042466, 0.881579388042466, 0.888158230032311, 0.894737072022156, 0.894737072022156, 0.894737072022156,
    0.901315914012001, 0.907894756001846, 0.907894756001846, 0.907894756001846, 0.911184176996768, 0.921052439981536,
    0.921052439981536, 0.921052439981536, 0.934210123961225, 0.934210123961225, 0.934210123961225, 0.944078386945993,
    0.947367807940915, 0.947367807940915, 0.947367807940915, 0.947367807940915, 0.947367807940915, 0.947367807940915,
    0.944078386945993, 0.934210123961225, 0.934210123961225, 0.927631281971381, 0.921052439981536, 0.921052439981536,
    0.921052439981536, 0.934210123961225, 0.934210123961225, 0.944078386945993, 0.950657592970919, 0.96052694806093,
    0.970395211045698, 0.97368463204062, 0.98684231602031, 0.98684231602031, 0.98684231602031, 1, 1, 1, 1, 1,
  ],
  [
    0.263158048014771, 0.309210305978766, 0.348684450023079, 0.391446922957071, 0.430921431036466, 0.470394482975535,
    0.503289420994922, 0.539473780009231, 0.578947195983382, 0.618421340027695, 0.661183812961687, 0.703947742036004,
    0.743420793975073, 0.78618472304939, 0.815789512003692, 0.842104879963072, 0.861842134002769, 0.875000546052622,
    0.881579388042466, 0.881579388042466, 0.881579388042466, 0.881579388042466, 0.881579388042466, 0.868421704062777,
    0.868421704062777, 0.868421704062777, 0.868421704062777, 0.868421704062777, 0.868421704062777, 0.875000546052622,
    0.881579388042466, 0.881579388042466, 0.894737072022156, 0.894737072022156, 0.894737072022156, 0.894737072022156,
    0.907894756001846, 0.907894756001846, 0.907894756001846, 0.907894756001846, 0.907894756001846, 0.914473597991691,
    0.921052439981536, 0.921052439981536, 0.930920702966303, 0.934210123961225, 0.934210123961225, 0.944078386945993,
    0.947367807940915, 0.947367807940915, 0.947367807940915, 0.947367807940915, 0.944078386945993, 0.934210123961225,
    0.934210123961225, 0.921052439981536, 0.914473597991691, 0.907894756001846, 0.907894756001846, 0.907894756001846,
    0.907894756001846, 0.907894756001846, 0.917763018986613, 0.924341860976458, 0.937499544956148, 0.947367807940915,
    0.957237163030927, 0.963816369055853, 0.97368463204062, 0.97368463204062, 0.98684231602031, 0.98684231602031,
    0.98684231602031, 0.98684231602031, 0.996710579005077, 1,
  ],
  [
    0.26000020657773, 0.306666115792719, 0.350000221333282, 0.389999572088987, 0.426666750281462, 0.463333190696329,
    0.503332910340838, 0.53666680930367, 0.573332880829734, 0.613333707140654, 0.659999616355644, 0.70000007377776,
    0.746666351881554, 0.78666680930367, 0.819999970488895, 0.846666204326032, 0.863332600474243, 0.880000472177668,
    0.88333375140731, 0.893333589096237, 0.893333589096237, 0.88333375140731, 0.880000472177668, 0.880000472177668,
    0.880000472177668, 0.880000472177668, 0.880000472177668, 0.880000472177668, 0.880000472177668, 0.893333589096237,
    0.893333589096237, 0.903333426785163, 0.906666706014805, 0.906666706014805, 0.906666706014805, 0.906666706014805,
    0.916666543703732, 0.919999822933374, 0.919999822933374, 0.919999822933374, 0.919999822933374, 0.919999822933374,
    0.9299996606223, 0.933332939851942, 0.936666219081585, 0.946666056770511, 0.946666056770511, 0.946666056770511,
    0.953332615229795, 0.959999173689079, 0.949999336000153, 0.946666056770511, 0.946666056770511, 0.936666219081585,
    0.9299996606223, 0.919999822933374, 0.906666706014805, 0.900000147555521, 0.893333589096237, 0.893333589096237,
    0.893333589096237, 0.900000147555521, 0.906666706014805, 0.919999822933374, 0.9299996606223, 0.943332777540869,
    0.953332615229795, 0.959999173689079, 0.970000118044417, 0.973333766162862, 0.980000324622147, 0.986666883081431,
    0.986666883081431, 0.986666883081431, 0.993333441540715, 1,
  ],
  [
    0.263698780696714, 0.308218586226708, 0.349314928316852, 0.39041051241664, 0.428081875086221, 0.465753237755802,
    0.499999242009644, 0.534246383249019, 0.571917366923423, 0.613013330018388, 0.657533893538738, 0.705479057484472,
    0.746575020579438, 0.787670604679226, 0.8219177459186, 0.849314549321674, 0.873286752299363, 0.8869851540009,
    0.890409754426284, 0.90068469268797, 0.904109672108532, 0.904109672108532, 0.890409754426284, 0.890409754426284,
    0.890409754426284, 0.890409754426284, 0.890409754426284, 0.90068469268797, 0.904109672108532, 0.904109672108532,
    0.917808073810068, 0.917808073810068, 0.917808073810068, 0.931506475511605, 0.931506475511605, 0.931506475511605,
    0.931506475511605, 0.931506475511605, 0.931506475511605, 0.931506475511605, 0.931506475511605, 0.931506475511605,
    0.945204877213142, 0.945204877213142, 0.95205407806391, 0.958903278914679, 0.958903278914679, 0.958903278914679,
    0.972601680616215, 0.965752479765447, 0.958903278914679, 0.958903278914679, 0.95205407806391, 0.945204877213142,
    0.931506475511605, 0.921232674235453, 0.9109588729593, 0.904109672108532, 0.890409754426284, 0.890409754426284,
    0.890409754426284, 0.90068469268797, 0.907534272533916, 0.917808073810068, 0.931506475511605, 0.941780276787758,
    0.95205407806391, 0.958903278914679, 0.972601680616215, 0.972601680616215, 0.986300082317752, 0.986300082317752,
    0.986300082317752, 0.993150041158876, 1, 1,
  ],
  [
    0.264085066852046, 0.30633792937905, 0.348591571249099, 0.390845213119149, 0.426055931891652, 0.464789281227496,
    0.496478928122749, 0.531690036566775, 0.566901924353846, 0.609154786880851, 0.651409208093945, 0.697183142498199,
    0.739437174039771, 0.778169354361048, 0.816901534682324, 0.845071278714894, 0.866197709978396, 0.880281997487398,
    0.887324141241898, 0.897887356873649, 0.9014084287509, 0.9014084287509, 0.9014084287509, 0.9014084287509,
    0.9014084287509, 0.9014084287509, 0.9014084287509, 0.9014084287509, 0.90492950062815, 0.915492716259901,
    0.915492716259901, 0.922535639357447, 0.929578562454992, 0.929578562454992, 0.929578562454992, 0.929578562454992,
    0.929578562454992, 0.929578562454992, 0.929578562454992, 0.929578562454992, 0.929578562454992, 0.936620706209493,
    0.943662849963994, 0.950704993718495, 0.957747137472995, 0.957747137472995, 0.971831424981997, 0.971831424981997,
    0.971831424981997, 0.971831424981997, 0.964789281227496, 0.957747137472995, 0.954226065595745, 0.943662849963994,
    0.933099634332243, 0.922535639357447, 0.911971644382651, 0.9014084287509, 0.897887356873649, 0.887324141241898,
    0.890845213119149, 0.9014084287509, 0.90492950062815, 0.915492716259901, 0.92605710090622, 0.936620706209493,
    0.943662849963994, 0.957747137472995, 0.964789281227496, 0.971831424981997, 0.975352496859247, 0.985915712490998,
    0.985915712490998, 0.985915712490998, 0.992957856245499, 1,
  ],
  [
    0.264492922145201, 0.307970886648468, 0.351449252117903, 0.39130448729258, 0.423912659945404, 0.460144764825323,
    0.492753739410483, 0.525361912063307, 0.561594016943226, 0.597826121823145, 0.64130448729258, 0.684782852762015,
    0.724637286004356, 0.768116453406127, 0.800724626058951, 0.833332798711775, 0.851449653084071, 0.86956570552403,
    0.880435096408305, 0.884058226703063, 0.884058226703063, 0.894927617587338, 0.898550747882096, 0.898550747882096,
    0.898550747882096, 0.898550747882096, 0.898550747882096, 0.898550747882096, 0.898550747882096, 0.909420138766371,
    0.913043269061129, 0.913043269061129, 0.913043269061129, 0.913043269061129, 0.913043269061129, 0.913043269061129,
    0.913043269061129, 0.913043269061129, 0.913043269061129, 0.923912659945404, 0.927535790240162, 0.927535790240162,
    0.942028311419195, 0.945651842680122, 0.956522436462901, 0.956522436462901, 0.963768697052417, 0.971014957641934,
    0.971014957641934, 0.960145566757659, 0.956522436462901, 0.956522436462901, 0.952898905201974, 0.942028311419195,
    0.934782050829679, 0.927535790240162, 0.913043269061129, 0.909420138766371, 0.898550747882096, 0.898550747882096,
    0.898550747882096, 0.898550747882096, 0.913043269061129, 0.916666399355888, 0.927535790240162, 0.934782050829679,
    0.942028311419195, 0.956522436462901, 0.956522436462901, 0.971014957641934, 0.971014957641934, 0.985507478820966,
    0.985507478820966, 0.985507478820966, 0.985507478820966, 1,
  ],
  [
    0.264925126605904, 0.309701332293838, 0.347014148808599, 0.388059898715276, 0.425372715230037, 0.458954663028433,
    0.492537436697047, 0.522387689908856, 0.555969224772142, 0.593283693027342, 0.63432779119358, 0.675373128165147,
    0.716417639266495, 0.757462150367842, 0.791044924036457, 0.820895177248266, 0.843282867157123, 0.861939275414504,
    0.869402251652566, 0.880597335412323, 0.880597335412323, 0.880597335412323, 0.895522462018228, 0.895522462018228,
    0.895522462018228, 0.895522462018228, 0.895522462018228, 0.895522462018228, 0.895522462018228, 0.899253743669704,
    0.910447588624133, 0.910447588624133, 0.910447588624133, 0.910447588624133, 0.910447588624133, 0.910447588624133,
    0.910447588624133, 0.910447588624133, 0.910447588624133, 0.910447588624133, 0.925372715230037, 0.925372715230037,
    0.936566560184466, 0.940297841835942, 0.95149168679037, 0.955222968441847, 0.955222968441847, 0.955222968441847,
    0.955222968441847, 0.955222968441847, 0.955222968441847, 0.955222968441847, 0.955222968441847, 0.944029123487418,
    0.940297841835942, 0.93283527853299, 0.925372715230037, 0.921641433578561, 0.910447588624133, 0.910447588624133,
    0.910447588624133, 0.914178870275609, 0.925372715230037, 0.925372715230037, 0.936566560184466, 0.940297841835942,
    0.95149168679037, 0.955222968441847, 0.966416813396275, 0.970148095047751, 0.981343178807509, 0.985074873394095,
    0.985074873394095, 0.996268718348523, 1, 1,
  ],
  [
    0.265384805286124, 0.311538795503184, 0.349999744615211, 0.38846197065118, 0.423077250493318, 0.45384600978294,
    0.484615620355189, 0.523077420749843, 0.553846180039465, 0.59230798043412, 0.630769780828774, 0.67692334540452,
    0.719231666351692, 0.757692615463719, 0.792308320947171, 0.82307793151942, 0.842308406075433, 0.861538880631447,
    0.876923260276258, 0.876923260276258, 0.892307639921069, 0.892307639921069, 0.892307639921069, 0.892307639921069,
    0.892307639921069, 0.892307639921069, 0.892307639921069, 0.892307639921069, 0.896154160473585, 0.907693722131134,
    0.907693722131134, 0.907693722131134, 0.919232006864743, 0.923078101775945, 0.923078101775945, 0.923078101775945,
    0.923078101775945, 0.919232006864743, 0.907693722131134, 0.907693722131134, 0.919232006864743, 0.923078101775945,
    0.923078101775945, 0.934616386509553, 0.938462481420756, 0.938462481420756, 0.938462481420756, 0.938462481420756,
    0.938462481420756, 0.942308576331959, 0.953846861065567, 0.953846861065567, 0.953846861065567, 0.953846861065567,
    0.942308576331959, 0.938462481420756, 0.938462481420756, 0.930770291598351, 0.923078101775945, 0.923078101775945,
    0.923078101775945, 0.926924196687148, 0.938462481420756, 0.938462481420756, 0.946154671243162, 0.953846861065567,
    0.953846861065567, 0.969231240710378, 0.969231240710378, 0.980769525443986, 0.984615620355189, 0.992307810177594, 1,
    1, 1, 1,
  ],
  [
    0.269840956160182, 0.313492377173151, 0.349206098261479, 0.388888888888888, 0.420635297052225, 0.452380826908517,
    0.484126356764808, 0.515873643235191, 0.555555555555555, 0.591269276643883, 0.634921136810375, 0.678571240362775,
    0.718254470143708, 0.761905012849632, 0.793650542705923, 0.825397390022783, 0.849206976568524, 0.869047932728706,
    0.876984315192779, 0.888888888888888, 0.888888888888888, 0.888888888888888, 0.888888888888888, 0.888888888888888,
    0.888888888888888, 0.888888888888888, 0.892857080120925, 0.904761653817034, 0.904761653817034, 0.904761653817034,
    0.92063441874518, 0.92063441874518, 0.92063441874518, 0.92063441874518, 0.92063441874518, 0.92063441874518,
    0.92063441874518, 0.92063441874518, 0.904761653817034, 0.904761653817034, 0.904761653817034, 0.904761653817034,
    0.904761653817034, 0.904761653817034, 0.912698036281107, 0.92063441874518, 0.92063441874518, 0.92063441874518,
    0.92063441874518, 0.92063441874518, 0.928571679516298, 0.936508940287417, 0.936508940287417, 0.936508940287417,
    0.936508940287417, 0.936508940287417, 0.936508940287417, 0.936508940287417, 0.936508940287417, 0.936508940287417,
    0.936508940287417, 0.936508940287417, 0.940477131519453, 0.952381705215562, 0.952381705215562, 0.956349896447599,
    0.968254470143708, 0.968254470143708, 0.976190852607781, 0.984127235071854, 0.98809542630389, 1, 1, 1, 1, 1,
  ],
  [
    0.266392721401293, 0.311475766729256, 0.3524587041119, 0.389343801308043, 0.426229805607714, 0.454917861775564,
    0.483605917943414, 0.516393174953057, 0.557377019439228, 0.594261663083607, 0.639344254859807, 0.684425939532479,
    0.72540978401865, 0.766393628504821, 0.8032782721492, 0.827868034578786, 0.856557904953692, 0.868852786168485,
    0.885245961121542, 0.885245961121542, 0.885245961121542, 0.9016391360746, 0.9016391360746, 0.9016391360746,
    0.9016391360746, 0.9016391360746, 0.9016391360746, 0.9016391360746, 0.909835723551129, 0.918032311027657,
    0.918032311027657, 0.926228898504186, 0.934425485980715, 0.934425485980715, 0.934425485980715, 0.918032311027657,
    0.918032311027657, 0.9016391360746, 0.893442548598071, 0.885245961121542, 0.885245961121542, 0.868852786168485,
    0.868852786168485, 0.868852786168485, 0.868852786168485, 0.868852786168485, 0.868852786168485, 0.868852786168485,
    0.885245961121542, 0.885245961121542, 0.897540842336335, 0.9016391360746, 0.918032311027657, 0.922130604765922,
    0.934425485980715, 0.934425485980715, 0.934425485980715, 0.934425485980715, 0.934425485980715, 0.934425485980715,
    0.934425485980715, 0.938523779718979, 0.950818660933772, 0.950818660933772, 0.950818660933772, 0.967213650093885,
    0.967213650093885, 0.967213650093885, 0.979508531308678, 0.983606825046942, 0.987705118785207, 1, 1, 1, 1, 1,
  ],
  [
    0.271185614096294, 0.317796872450214, 0.355931821890597, 0.389829554726494, 0.423728694340811, 0.457626896102848,
    0.491524628938745, 0.525422830700782, 0.559321970315099, 0.597456919755483, 0.644066771330981, 0.686440344154273,
    0.728812510199143, 0.771186551948575, 0.805084284784472, 0.834744801015881, 0.855930884038317, 0.872880688308546,
    0.8813560593698, 0.898304925787749, 0.898304925787749, 0.898304925787749, 0.898304925787749, 0.898304925787749,
    0.898304925787749, 0.898304925787749, 0.91101657560121, 0.915253792205697, 0.915253792205697, 0.919491008810184,
    0.932202658623645, 0.932202658623645, 0.932202658623645, 0.927965442019158, 0.915253792205697, 0.91101657560121,
    0.898304925787749, 0.8813560593698, 0.868643002777918, 0.855930884038317, 0.847456450829342, 0.843219234224855,
    0.830507584411394, 0.830507584411394, 0.830507584411394, 0.830507584411394, 0.830507584411394, 0.830507584411394,
    0.830507584411394, 0.843219234224855, 0.855930884038317, 0.868643002777918, 0.885593275974287, 0.898304925787749,
    0.915253792205697, 0.915253792205697, 0.923728225414671, 0.932202658623645, 0.932202658623645, 0.932202658623645,
    0.932202658623645, 0.944914308437106, 0.949151525041593, 0.949151525041593, 0.95338874164608, 0.966100391459542,
    0.966100391459542, 0.966100391459542, 0.98304925787749, 0.98304925787749, 0.98304925787749, 1, 1, 1, 1, 1,
  ],
  [
    0.280701277521065, 0.324562186929678, 0.36403525802819, 0.403508329126703, 0.438596959577525, 0.469298722478934,
    0.5, 0.535087174309789, 0.570176290140954, 0.609649361239467, 0.64912243233798, 0.692983341746592,
    0.736842309633829, 0.771929969323962, 0.807018599774783, 0.837719877295849, 0.864035258028191, 0.877192948394361,
    0.894736535549256, 0.894736535549256, 0.903509299887391, 0.912282064225527, 0.912282064225527, 0.912282064225527,
    0.912282064225527, 0.916667961014251, 0.929825651380421, 0.929825651380421, 0.929825651380421, 0.929825651380421,
    0.929825651380421, 0.929825651380421, 0.929825651380421, 0.912282064225527, 0.907895682056459, 0.894736535549256,
    0.877192948394361, 0.859649361239467, 0.842105774084572, 0.828948083718401, 0.824562186929678, 0.807018599774783,
    0.807018599774783, 0.807018599774783, 0.793860909408612, 0.789475012619889, 0.789475012619889, 0.789475012619889,
    0.793860909408612, 0.807018599774783, 0.824562186929678, 0.846491670873296, 0.864035258028191, 0.881578845183085,
    0.894736535549256, 0.912282064225527, 0.912282064225527, 0.925439754591698, 0.929825651380421, 0.929825651380421,
    0.938597444957869, 0.947369238535316, 0.947369238535316, 0.951755135324039, 0.96491282569021, 0.96491282569021,
    0.96491282569021, 0.973684619267658, 0.982456412845105, 0.982456412845105, 0.982456412845105, 0.995614103211276, 1,
    1, 1, 1,
  ],
  [
    0.29629553726596, 0.337963057841754, 0.384259107453192, 0.425925090992556, 0.462963057841754, 0.495370446273403,
    0.527777322359576, 0.564813752172344, 0.597222677640423, 0.638888661179788, 0.675925090992556, 0.717592099222874,
    0.759259107453192, 0.79629553726596, 0.828703950388562, 0.861111338820211, 0.879629553726596, 0.89814776863298,
    0.907406876086172, 0.925925090992556, 0.925925090992556, 0.939813752172344, 0.94444330589894, 0.94444330589894,
    0.953703438043086, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.962963570187231, 0.949073371971013, 0.94444330589894, 0.930554644719152, 0.916665983539364, 0.902777322359576,
    0.884259107453192, 0.865740892546807, 0.851852231367019, 0.833334016460635, 0.819443818244417, 0.814813752172344,
    0.800925090992556, 0.79629553726596, 0.787036429812768, 0.777777322359576, 0.777777322359576, 0.777777322359576,
    0.787036429812768, 0.800925090992556, 0.819443818244417, 0.842593123913827, 0.865740892546808, 0.884259107453192,
    0.902777322359576, 0.92129553726596, 0.925925090992556, 0.94444330589894, 0.94444330589894, 0.949073371971013,
    0.962963570187231, 0.962963570187231, 0.967593123913827, 0.981481785093615, 0.981481785093615, 0.981481785093615,
    0.981481785093615, 0.995370446273404, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.302883714991061, 0.350961415680599, 0.399038052268664, 0.44230654635226, 0.480769026134332, 0.514422831361198,
    0.548076104537328, 0.581729377713458, 0.615384247041797, 0.653845662722397, 0.69230654635226, 0.730767962032859,
    0.769230441814931, 0.802883714991061, 0.836536988167191, 0.860576636588065, 0.879807610453732, 0.899038052268664,
    0.913460883629863, 0.923076104537328, 0.932691325444794, 0.94230654635226, 0.951921767259725, 0.961536988167191,
    0.961536988167191, 0.97595981952839, 0.980767429982123, 0.980767429982123, 0.980767429982123, 0.966344598620924,
    0.961536988167191, 0.956729377713458, 0.94230654635226, 0.932691325444794, 0.918268494083595, 0.903845662722397,
    0.884615220907465, 0.865384779092534, 0.850960351579126, 0.831729377713458, 0.822114156805993, 0.807691325444794,
    0.788460883629862, 0.78365327317613, 0.769230441814931, 0.769230441814931, 0.769230441814931, 0.769230441814931,
    0.769230441814931, 0.78365327317613, 0.802883714991061, 0.831729377713458, 0.850960351579126, 0.875,
    0.889422831361198, 0.903845662722397, 0.923076104537328, 0.932691325444794, 0.94230654635226, 0.947114156805993,
    0.961536988167191, 0.961536988167191, 0.97595981952839, 0.980767429982123, 0.980767429982123, 0.980767429982123,
    0.980767429982123, 0.990383714991061, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.314999546266436, 0.365000431600218, 0.410000287733479, 0.449999667999831, 0.490000154933411, 0.530000641866991,
    0.56500009960005, 0.599999557333109, 0.6300001992001, 0.67000068613368, 0.700000221333445, 0.739999601599798,
    0.77000024346679, 0.805000807867076, 0.83500034306684, 0.855000033200016, 0.874999723333193, 0.890000597600302,
    0.900001549334118, 0.920001239467294, 0.920001239467294, 0.940000929600471, 0.940000929600471, 0.960000619733647,
    0.960000619733647, 0.965000542266941, 0.980000309866823, 0.980000309866823, 0.975000387333529, 0.960000619733647,
    0.960000619733647, 0.955000697200353, 0.940000929600471, 0.935001007067176, 0.920001239467294, 0.905001471867412,
    0.890000597600302, 0.874999723333193, 0.85999995573331, 0.840000265600134, 0.825000498000252, 0.81000073040037,
    0.795000962800487, 0.780001195200605, 0.764999767599882, 0.759999291732974, 0.759999291732974, 0.759999291732974,
    0.759999291732974, 0.775000719333697, 0.790001040267193, 0.81000073040037, 0.830000420533546, 0.850000110666722,
    0.869999800799899, 0.885000121733394, 0.900001549334118, 0.915001316934, 0.925001162000588, 0.940000929600471,
    0.950000774667059, 0.960000619733647, 0.965000542266941, 0.980000309866823, 0.980000309866823, 0.980000309866823,
    0.980000309866823, 0.980000309866823, 0.980000309866823, 0.980000309866823, 0.980000309866823, 0.980000309866823,
    0.980000309866823, 0.980000309866823, 0.980000309866823, 1,
  ],
  [
    0.324467446356125, 0.382978886222948, 0.425531964991676, 0.478722577637338, 0.521276245058264, 0.553191789950058,
    0.590425218802022, 0.627658647653985, 0.659573603893581, 0.691489737437573, 0.734042227554103, 0.7659565951415,
    0.797870962728897, 0.824468035008323, 0.856382991247919, 0.877659236306183, 0.893616420099882, 0.904254542629015,
    0.914892665158147, 0.925530787687279, 0.936168910216412, 0.936168910216412, 0.95744750988347, 0.95744750988347,
    0.962766571148036, 0.978723754941735, 0.978723754941735, 0.978723754941735, 0.978723754941735, 0.978723754941735,
    0.973404693677169, 0.95744750988347, 0.95744750988347, 0.95744750988347, 0.936168910216412, 0.936168910216412,
    0.914892665158147, 0.909573603893581, 0.893616420099882, 0.872340175041617, 0.861702052512485, 0.845744868718786,
    0.829787684925088, 0.813828735174794, 0.803190023993463, 0.787232840199765, 0.787232840199765, 0.787232840199765,
    0.787232840199765, 0.797870962728897, 0.808509085258029, 0.824468035008323, 0.829787684925088, 0.851063929983352,
    0.861702052512485, 0.872340175041617, 0.893616420099882, 0.904254542629015, 0.920211726422713, 0.936168910216412,
    0.952127859966705, 0.962766571148036, 0.978723754941735, 0.978723754941735, 0.994680938735433, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1,
  ],
  [
    0.333332513581242, 0.388888342387495, 0.438888711275935, 0.48888785053624, 0.52222061004311, 0.566666748641875,
    0.605554968066557, 0.638887727573427, 0.677775946998108, 0.711110550947182, 0.74444392526812, 0.783332144692802,
    0.816664904199672, 0.849998278520609, 0.87222196263406, 0.888888342387495, 0.911110182058741, 0.911110182058741,
    0.922221101894365, 0.933332021729988, 0.933332021729988, 0.933332021729988, 0.933332021729988, 0.949998401483423,
    0.955553861401235, 0.955553861401235, 0.955553861401235, 0.955553861401235, 0.955553861401235, 0.955553861401235,
    0.955553861401235, 0.955553861401235, 0.955553861401235, 0.955553861401235, 0.955553861401235, 0.949998401483423,
    0.933332021729988, 0.933332021729988, 0.916665641976553, 0.911110182058741, 0.894443802305306, 0.888888342387495,
    0.866666502716248, 0.861110427984369, 0.84444220378873, 0.84444220378873, 0.827775824035295, 0.822220364117483,
    0.822220364117483, 0.822220364117483, 0.822220364117483, 0.84444220378873, 0.84444220378873, 0.84444220378873,
    0.84444220378873, 0.849998278520609, 0.866666502716248, 0.877777422551871, 0.894443802305306, 0.911110182058741,
    0.933332021729988, 0.949998401483423, 0.961109321319046, 0.977775701072481, 0.977775701072481, 0.983331775804361, 1,
    1, 1, 1, 1, 1, 0.977775701072481, 0.977775701072481, 0.977775701072481, 1,
  ],
  [
    0.343023106183439, 0.395348522985222, 0.441861362899365, 0.488372272579873, 0.53488318226038, 0.57558215846446,
    0.616279847846116, 0.651163030106497, 0.686046212366878, 0.732558408869809, 0.767442877952614, 0.808139923923058,
    0.848836969893502, 0.877906288443819, 0.906978180638984, 0.924419771769174, 0.930233635479238, 0.953489090319492,
    0.953489090319492, 0.953489090319492, 0.953489090319492, 0.953489090319492, 0.953489090319492, 0.953489090319492,
    0.953489090319492, 0.953489090319492, 0.953489090319492, 0.953489090319492, 0.953489090319492, 0.953489090319492,
    0.953489090319492, 0.953489090319492, 0.970930681449682, 0.976744545159746, 0.976744545159746, 0.976744545159746,
    0.970930681449682, 0.953489090319492, 0.953489090319492, 0.953489090319492, 0.941861362899365, 0.930233635479238,
    0.930233635479238, 0.906978180638984, 0.906978180638984, 0.906978180638984, 0.883720152153883, 0.883720152153883,
    0.883720152153883, 0.883720152153883, 0.883720152153883, 0.860464697313629, 0.860464697313629, 0.860464697313629,
    0.860464697313629, 0.860464697313629, 0.860464697313629, 0.866278561023692, 0.883720152153883, 0.906978180638984,
    0.924419771769174, 0.941861362899365, 0.953489090319492, 0.976744545159746, 0.976744545159746, 0.976744545159746, 1,
    1, 1, 1, 1, 0.982558408869809, 0.976744545159746, 0.976744545159746, 0.976744545159746, 1,
  ],
  [
    0.347560745191399, 0.402437905215368, 0.451220302200916, 0.5, 0.548779697799083, 0.585365145945012,
    0.621951943684173, 0.664634179258371, 0.701218952607684, 0.743901188181882, 0.786586122942545, 0.829268358516743,
    0.871950594090941, 0.908535367440253, 0.939024028157913, 0.957317764425802, 0.975610151100458, 0.975610151100458,
    0.975610151100458, 0.975610151100458, 0.975610151100458, 0.975610151100458, 0.975610151100458, 0.969512688875573,
    0.951220302200916, 0.951220302200916, 0.951220302200916, 0.951220302200916, 0.951220302200916, 0.957317764425802,
    0.975610151100458, 0.975610151100458, 0.975610151100458, 0.975610151100458, 1, 1, 1, 1, 1, 1, 0.975610151100458,
    0.975610151100458, 0.975610151100458, 0.975610151100458, 0.951220302200916, 0.951220302200916, 0.951220302200916,
    0.951220302200916, 0.926827754114909, 0.926827754114909, 0.926827754114909, 0.908535367440253, 0.902437905215368,
    0.890242980765597, 0.878048056315826, 0.878048056315826, 0.878048056315826, 0.878048056315826, 0.896340442990482,
    0.908535367440253, 0.926827754114909, 0.951220302200916, 0.963415226650687, 0.975610151100458, 0.975610151100458,
    0.993902537775114, 1, 1, 1, 1, 1, 0.987805075550229, 0.975610151100458, 0.975610151100458, 0.975610151100458, 1,
  ],
  [
    0.349999723333499, 0.412498685834121, 0.462500622499626, 0.5, 0.537499377500373, 0.581248651250809,
    0.612499515833623, 0.6500002766665, 0.687499654166874, 0.73124892791731, 0.774998893333997, 0.818750242083188,
    0.862499515833623, 0.906248789584059, 0.93749827083437, 0.956248651250809, 0.975000414999751, 0.981250311249813, 1,
    1, 0.981250311249813, 0.975000414999751, 0.975000414999751, 0.975000414999751, 0.949998063334495, 0.949998063334495,
    0.949998063334495, 0.949998063334495, 0.949998063334495, 0.949998063334495, 0.949998063334495, 0.968749827083437,
    0.975000414999751, 0.975000414999751, 0.975000414999751, 0.987500207499875, 1, 1, 1, 1, 1, 1, 0.987500207499875,
    0.975000414999751, 0.975000414999751, 0.975000414999751, 0.956248651250809, 0.949998063334495, 0.949998063334495,
    0.949998063334495, 0.93749827083437, 0.924998478334246, 0.918748582084184, 0.899998893333997, 0.899998893333997,
    0.88124920458381, 0.874999308333748, 0.893748997083935, 0.899998893333997, 0.906248789584059, 0.924998478334246,
    0.93749827083437, 0.949998063334495, 0.956248651250809, 0.975000414999751, 0.975000414999751, 0.975000414999751,
    0.975000414999751, 0.975000414999751, 0.975000414999751, 0.975000414999751, 0.968749827083437, 0.949998063334495,
    0.949998063334495, 0.949998063334495, 1,
  ],
  [
    0.358973922419908, 0.410255100593059, 0.461538406969155, 0.5, 0.544871030901506, 0.576921767259725,
    0.602562356346301, 0.634615220907465, 0.67307681393831, 0.711537697568173, 0.75640872846968, 0.794870321500524,
    0.839743480604977, 0.884614511506484, 0.916665247864703, 0.948715984222922, 0.967946426037853, 0.974356573309497,
    0.980767429982123, 1, 0.974356573309497, 0.974356573309497, 0.974356573309497, 0.974356573309497, 0.961536278766209,
    0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922,
    0.948715984222922, 0.967946426037853, 0.974356573309497, 0.974356573309497, 0.974356573309497, 0.974356573309497,
    0.987178286654748, 1, 1, 1, 1, 0.993589143327374, 0.974356573309497, 0.974356573309497, 0.974356573309497,
    0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922, 0.92948554240799, 0.923075395136346,
    0.923075395136346, 0.897434806049771, 0.897434806049771, 0.897434806049771, 0.897434806049771, 0.897434806049771,
    0.897434806049771, 0.916665247864703, 0.923075395136346, 0.935895689679634, 0.948715984222922, 0.948715984222922,
    0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922,
    0.948715984222922, 0.948715984222922, 0.948715984222922, 0.948715984222922, 0.92948554240799, 1,
  ],
  [
    0.355263809326883, 0.414473473453067, 0.460526890582544, 0.506580307712021, 0.539474565559901, 0.572368823407781,
    0.598684229686084, 0.631578487533964, 0.664475657666735, 0.697369915514615, 0.736843024932071, 0.776316134349526,
    0.822368823407781, 0.861844117038905, 0.90131722645636, 0.927632632734664, 0.947369187443392, 0.973684593721696,
    0.973684593721696, 0.973684593721696, 0.973684593721696, 0.973684593721696, 0.973684593721696, 0.973684593721696,
    0.973684593721696, 0.96710574215212, 0.947369187443392, 0.947369187443392, 0.947369187443392, 0.947369187443392,
    0.947369187443392, 0.947369187443392, 0.947369187443392, 0.953948039012968, 0.973684593721696, 0.973684593721696,
    0.973684593721696, 0.973684593721696, 0.973684593721696, 0.980263445291272, 1, 0.980263445291272, 0.973684593721696,
    0.973684593721696, 0.973684593721696, 0.947369187443392, 0.947369187443392, 0.947369187443392, 0.93421148430424,
    0.921053781165088, 0.921053781165088, 0.921053781165088, 0.90131722645636, 0.894738374886784, 0.894738374886784,
    0.894738374886784, 0.894738374886784, 0.894738374886784, 0.90131722645636, 0.921053781165088, 0.921053781165088,
    0.93421148430424, 0.947369187443392, 0.947369187443392, 0.947369187443392, 0.947369187443392, 0.947369187443392,
    0.947369187443392, 0.947369187443392, 0.947369187443392, 0.947369187443392, 0.947369187443392, 0.947369187443392,
    0.947369187443392, 0.940790335873816, 1,
  ],
  [
    0.375000384259501, 0.430555085905054, 0.479166218363915, 0.520834550155087, 0.555556238683557, 0.590277927212028,
    0.618055278034804, 0.645832628857581, 0.67361074819936, 0.708334742284837, 0.743056430813308, 0.791666794753167,
    0.826388483281637, 0.875000384259501, 0.909723609825976, 0.944445298354447, 0.965278311471529, 0.972222649177223, 1,
    1, 1, 1, 1, 1, 1, 1, 0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223,
    0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223, 0.986111324588611, 1,
    1, 1, 1, 1, 1, 0.986111324588611, 0.972222649177223, 0.972222649177223, 0.951389636060141, 0.944445298354447,
    0.944445298354447, 0.944445298354447, 0.91666794753167, 0.91666794753167, 0.91666794753167, 0.91666794753167,
    0.91666794753167, 0.91666794753167, 0.91666794753167, 0.91666794753167, 0.91666794753167, 0.944445298354447,
    0.944445298354447, 0.951389636060141, 0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223,
    0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223, 0.972222649177223,
    0.972222649177223, 0.972222649177223, 0.972222649177223, 1,
  ],
  [
    0.378571767347003, 0.428570976870662, 0.478570186394321, 0.521429813605678, 0.557143534694006, 0.585714511564668,
    0.614285488435331, 0.642856465305993, 0.671427442176655, 0.7, 0.735715302041009, 0.778571767347003,
    0.821428232652996, 0.86428469795899, 0.899999999999999, 0.928572557823344, 0.942858046258675, 0.971429023129337,
    0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337,
    0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337,
    0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337,
    0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337,
    0.971429023129337, 0.971429023129337, 0.964286278911672, 0.942858046258675, 0.942858046258675, 0.942858046258675,
    0.914287069388013, 0.914287069388013, 0.914287069388013, 0.914287069388013, 0.892856465305993, 0.885712930611986,
    0.885712930611986, 0.885712930611986, 0.914287069388013, 0.914287069388013, 0.914287069388013, 0.914287069388013,
    0.942858046258675, 0.942858046258675, 0.942858046258675, 0.95000079047634, 0.971429023129337, 0.971429023129337,
    0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.971429023129337, 0.985714511564668, 1,
    1, 0.985714511564668, 1,
  ],
  [
    0.382353228373623, 0.433822978950554, 0.485292729527486, 0.522059278258237, 0.558824199538455, 0.588235485582415,
    0.617646771626376, 0.639705236159346, 0.661763700692317, 0.698527808247268, 0.727941535467029, 0.772059278258237,
    0.808823385813188, 0.845587493368139, 0.88235160092309, 0.91176288696705, 0.941177427912078, 0.941177427912078,
    0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078,
    0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078,
    0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078,
    0.941177427912078, 0.941177427912078, 0.941177427912078, 0.970588713956039, 0.970588713956039, 0.955883070934059,
    0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.933823792675821, 0.91176288696705,
    0.91176288696705, 0.91176288696705, 0.89705724394507, 0.88235160092309, 0.88235160092309, 0.88235160092309,
    0.88235160092309, 0.88235160092309, 0.88235160092309, 0.88970442243408, 0.91176288696705, 0.91176288696705,
    0.91176288696705, 0.919116522203307, 0.941177427912078, 0.941177427912078, 0.941177427912078, 0.941177427912078,
    0.941177427912078, 0.970588713956039, 0.970588713956039, 0.970588713956039, 0.970588713956039, 0.970588713956039,
    0.977941535467029, 1, 0.977941535467029, 1,
  ],
  [
    0.390625162109165, 0.453124081381394, 0.492185905926538, 0.539060959962926, 0.570313013345691, 0.601562472981805,
    0.624999567708891, 0.656249027345006, 0.687498486981121, 0.710935581708206, 0.74999740625335, 0.789062689127359,
    0.828124513672503, 0.867186338217646, 0.898435797853761, 0.921872892580847, 0.937497622398904, 0.953122352216961,
    0.968747082035019, 0.968747082035019, 0.96093471712599, 0.937497622398904, 0.937497622398904, 0.937497622398904,
    0.937497622398904, 0.937497622398904, 0.937497622398904, 0.937497622398904, 0.937497622398904, 0.937497622398904,
    0.937497622398904, 0.937497622398904, 0.937497622398904, 0.937497622398904, 0.937497622398904, 0.953122352216961,
    0.968747082035019, 0.968747082035019, 0.968747082035019, 0.968747082035019, 0.968747082035019, 0.968747082035019,
    0.968747082035019, 0.968747082035019, 0.953122352216961, 0.937497622398904, 0.937497622398904, 0.937497622398904,
    0.937497622398904, 0.921872892580847, 0.906248162762789, 0.906248162762789, 0.906248162762789, 0.906248162762789,
    0.906248162762789, 0.906248162762789, 0.906248162762789, 0.906248162762789, 0.914060527671818, 0.937497622398904,
    0.937497622398904, 0.937497622398904, 0.937497622398904, 0.937497622398904, 0.953122352216961, 0.968747082035019,
    0.968747082035019, 0.968747082035019, 0.968747082035019, 0.976560311526264, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.408334685927609, 0.46666715851913, 0.516666420740434, 0.558332472591521, 0.5916680807425, 0.625001844446739,
    0.658334685927609, 0.683334317038261, 0.71666715851913, 0.75, 0.783332841480869, 0.816667527408478,
    0.850002213336087, 0.891668265187174, 0.916667896297826, 0.933334317038261, 0.958333948148913, 0.96666715851913,
    0.96666715851913, 0.96666715851913, 0.96666715851913, 0.96666715851913, 0.950000737778695, 0.933334317038261,
    0.933334317038261, 0.933334317038261, 0.933334317038261, 0.933334317038261, 0.933334317038261, 0.933334317038261,
    0.933334317038261, 0.933334317038261, 0.933334317038261, 0.950000737778696, 0.96666715851913, 0.96666715851913,
    0.96666715851913, 0.96666715851913, 1, 1, 0.975000368889348, 0.96666715851913, 0.96666715851913, 0.96666715851913,
    0.96666715851913, 0.96666715851913, 0.96666715851913, 0.96666715851913, 0.933334317038261, 0.933334317038261,
    0.933334317038261, 0.933334317038261, 0.933334317038261, 0.933334317038261, 0.933334317038261, 0.933334317038261,
    0.933334317038261, 0.933334317038261, 0.933334317038261, 0.958333948148913, 0.96666715851913, 0.96666715851913,
    0.96666715851913, 0.96666715851913, 0.96666715851913, 0.96666715851913, 0.96666715851913, 0.96666715851913,
    0.991666789629782, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.428572557823344, 0.491071569727918, 0.544642151360409, 0.589284302720819, 0.625, 0.66071569727918,
    0.696429418367508, 0.723214709183754, 0.758928430272082, 0.794642151360409, 0.830355872448737, 0.857141163264983,
    0.892858836735016, 0.928572557823344, 0.946429418367508, 0.964286278911672, 0.964286278911672, 0.964286278911672,
    0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672,
    0.964286278911672, 0.964286278911672, 0.937500988095426, 0.928572557823344, 0.928572557823344, 0.928572557823344,
    0.946429418367508, 0.964286278911672, 0.964286278911672, 0.964286278911672, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    0.982143139455836, 0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672,
    0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672, 0.964286278911672,
    0.964286278911672, 0.982143139455836, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.444445355280847, 0.5, 0.564813752172344, 0.611109289438305, 0.64814776863298, 0.685186247827655,
    0.722222677640423, 0.759259107453192, 0.787036429812768, 0.824072859625536, 0.851850181985113, 0.888886611797881,
    0.907406876086172, 0.925927140374463, 0.953704462734039, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.962963570187231, 0.962963570187231, 0.962963570187231, 0.925927140374463, 0.935186247827655, 0.962963570187231,
    0.962963570187231, 0.962963570187231, 0.962963570187231, 0.981481785093615, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 0.981481785093615, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.981481785093615,
    0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 1,
  ],
  [
    0.444445355280847, 0.5, 0.564813752172344, 0.611109289438305, 0.657407900777125, 0.694445355280847,
    0.731481785093615, 0.768518214906384, 0.79629553726596, 0.824072859625536, 0.851850181985113, 0.879627504344689,
    0.888886611797881, 0.907406876086172, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463,
    0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463,
    0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463,
    0.935186247827655, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.981481785093615, 1, 0.981481785093615, 0.962963570187231, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.962963570187231, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463,
    0.925927140374463, 0.925927140374463, 0.944445355280847, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.972222677640423, 1, 1, 1, 1, 0.981481785093615, 0.962963570187231, 0.962963570187231, 0.962963570187231,
    0.962963570187231, 0.962963570187231, 0.935186247827655, 0.925927140374463, 0.925927140374463, 0.925927140374463,
    0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463, 0.925927140374463, 1,
  ],
  [
    0.461539116370137, 0.528845662722397, 0.596152209074657, 0.644228313611986, 0.692308674555205, 0.730769558185068,
    0.769230441814931, 0.807691325444794, 0.836536988167191, 0.846152209074657, 0.88461309270452, 0.88461309270452,
    0.913458755426917, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383,
    0.951922831361198, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.971154337277602, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.971154337277602, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.980769558185068, 1, 1, 1, 1, 1, 1, 1,
    1, 0.990384779092534, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 1,
  ],
  [
    0.480769558185068, 0.548076104537328, 0.605767429982123, 0.66345981952839, 0.701923895462671, 0.740384779092534,
    0.778845662722397, 0.807691325444794, 0.836536988167191, 0.846152209074657, 0.874997871797054, 0.88461309270452,
    0.88461309270452, 0.88461309270452, 0.913458755426917, 0.923073976334383, 0.923073976334383, 0.923073976334383,
    0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383,
    0.923073976334383, 0.923073976334383, 0.94230654635226, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383,
    0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.951922831361198, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.971154337277602, 1, 1, 0.961539116370137, 0.961539116370137,
    0.961539116370137, 0.961539116370137, 0.961539116370137, 0.94230654635226, 0.923073976334383, 0.923073976334383,
    0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383, 0.923073976334383,
    0.923073976334383, 0.923073976334383, 1,
  ],
  [
    0.5, 0.579998583469084, 0.649997344004533, 0.699998672002266, 0.75, 0.789999291734542, 0.819998760535448,
    0.839998406402719, 0.879997698137261, 0.879997698137261, 0.899997344004533, 0.919996989871803, 0.919996989871803,
    0.919996989871803, 0.919996989871803, 0.919996989871803, 0.919996989871803, 0.919996989871803, 0.959996281606346,
    0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346,
    0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346,
    0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346,
    0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346,
    0.959996281606346, 0.959996281606346, 0.939996635739075, 0.919996989871803, 0.919996989871803, 0.919996989871803,
    0.919996989871803, 0.94999645867271, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346,
    0.969997211204759, 1, 1, 1, 1, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346,
    0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346,
    0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 0.959996281606346, 1,
  ],
  [
    0.541668395837079, 0.614584053821005, 0.687499711804931, 0.739585782991418, 0.791669548617355, 0.833335638893884,
    0.86458520660128, 0.875001729170413, 0.916667819446942, 0.916667819446942, 0.916667819446942, 0.958333909723471,
    0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471,
    0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471,
    0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 0.968750432292603, 0.958333909723471, 0.958333909723471, 0.958333909723471,
    0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471,
    0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.968750432292603, 1, 1, 1, 1, 1, 1, 1,
    0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471,
    0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471, 0.958333909723471,
    0.958333909723471, 1,
  ],
  [
    0.565218855704875, 0.652174122243553, 0.728259980464897, 0.782611833653304, 0.836958875239978, 0.869567100191982,
    0.913044733461322, 0.913044733461322, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.967391775047995, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    0.989130591682665, 0.956522366730661, 0.956522366730661, 0.956522366730661, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.586957672339545, 0.663043530560888, 0.728259980464897, 0.793481241970639, 0.836958875239978, 0.869567100191982,
    0.913044733461322, 0.913044733461322, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.989130591682665, 1, 1, 1, 1, 1, 1, 0.989130591682665, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.989130591682665, 1, 1, 1, 1,
    0.97826118336533, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661, 0.956522366730661,
    0.956522366730661, 1,
  ],
  [
    0.613637163912573, 0.70454488291959, 0.772726929751754, 0.829548026861842, 0.875001886365351, 0.909092280992982,
    0.954546140496491, 0.954546140496491, 0.988636535124122, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.613637163912573, 0.70454488291959, 0.772726929751754, 0.840911491737719, 0.886365351241228, 0.909092280992982,
    0.954546140496491, 0.954546140496491, 0.988636535124122, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.613637163912573, 0.70454488291959, 0.784090394627631, 0.840911491737719, 0.886365351241228, 0.909092280992982,
    0.954546140496491, 0.954546140496491, 0.977273070248245, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.613637163912573, 0.70454488291959, 0.784090394627631, 0.840911491737719, 0.886365351241228, 0.909092280992982,
    0.954546140496491, 0.954546140496491, 0.977273070248245, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.613637163912573, 0.70454488291959, 0.772726929751754, 0.840911491737719, 0.875001886365351, 0.909092280992982,
    0.954546140496491, 0.954546140496491, 0.954546140496491, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.613637163912573, 0.70454488291959, 0.761362207298976, 0.829548026861842, 0.875001886365351, 0.909092280992982,
    0.943182675620614, 0.954546140496491, 0.954546140496491, 0.965909605372368, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.590910234160819, 0.681817953167836, 0.761362207298976, 0.818184561985965, 0.863638421489474, 0.909092280992982,
    0.920455745868859, 0.954546140496491, 0.954546140496491, 0.954546140496491, 0.954546140496491, 0.954546140496491,
    0.954546140496491, 0.954546140496491, 0.988636535124122, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1,
  ],
  [
    0.619048371882229, 0.702380387754994, 0.785712403627759, 0.84523922449001, 0.892858836735016, 0.928572557823344,
    0.952381705215562, 0.976190852607781, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1,
  ],
  [
    0.595239224490011, 0.690475814058885, 0.77380782993165, 0.833333333333333, 0.880954263038907, 0.904763410431125,
    0.952381705215562, 0.952381705215562, 0.952381705215562, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.595239224490011, 0.690475814058885, 0.749998682539431, 0.821427442176655, 0.857145115646688, 0.904763410431125,
    0.916667984127234, 0.952381705215562, 0.952381705215562, 0.952381705215562, 0.952381705215562, 0.952381705215562,
    0.98809542630389, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.625000691666251, 0.699999446666998, 0.78749799416787, 0.849996956668492, 0.900001659999004, 0.937501037499377,
    0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.925001244999253,
    0.950000829999502, 0.975000414999751, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.925001244999253,
    0.950000829999502, 0.962500622499626, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.925001244999253,
    0.950000829999502, 0.950000829999502, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.975000414999751, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.975000414999751, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.699999446666998, 0.774998201667745, 0.837497164168368, 0.887500484166376, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.975000414999751, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.687499654166874, 0.774998201667745, 0.824997371668243, 0.874999308333748, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.975000414999751, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.687499654166874, 0.774998201667745, 0.824997371668243, 0.874999308333748, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.975000414999751, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.687499654166874, 0.774998201667745, 0.824997371668243, 0.874999308333748, 0.912501452499128,
    0.950000829999502, 0.950000829999502, 0.962500622499626, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.687499654166874, 0.762498409167621, 0.824997371668243, 0.874999308333748, 0.900001659999004,
    0.950000829999502, 0.950000829999502, 0.950000829999502, 0.987500207499875, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.600001106666002, 0.674999861666749, 0.762498409167621, 0.812497579168119, 0.86249813250112, 0.900001659999004,
    0.925001244999253, 0.950000829999502, 0.950000829999502, 0.950000829999502, 0.950000829999502, 0.950000829999502,
    0.950000829999502, 0.975000414999751, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.605264230838677, 0.697367884580661, 0.776313873502361, 0.828944532783495, 0.881575192064629, 0.921051098801889,
    0.947369340718866, 0.947369340718866, 0.986842335179716, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.583331796300053, 0.680555299383342, 0.763887095683395, 0.819441626550098, 0.8749961574168, 0.888884790133476,
    0.944439321000178, 0.944439321000178, 0.944439321000178, 0.944439321000178, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  [
    0.562499135414793, 0.656251296877809, 0.734378674487128, 0.812502593755619, 0.859376945316714, 0.875001729170413,
    0.937500864585206, 0.937500864585206, 0.937500864585206, 0.937500864585206, 0.937500864585206, 0.937500864585206,
    0.937500864585206, 0.937500864585206, 0.937500864585206, 0.937500864585206, 0.937500864585206, 0.937500864585206,
    0.937500864585206, 0.937500864585206, 0.937500864585206, 0.953125648438904, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1,
  ],
  [
    0.56249884722504, 0.666663592600107, 0.74999538890016, 0.833327185200213, 0.895832949075013, 0.916668203699946,
    0.958334101849973, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
];

export const dumperSideDataMatrix = [
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0.3251425175307664, 0.7094018874820252,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0.3601079134748787, 0.5644933492045497, 0.6691192149686084, 0.7688787849342209,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0.06771558535283213, 0.30230173230205426, 0.3675990835459475, 0.45466205086105366, 0.5610722324616825,
    0.6723192176336921, 0.783566370482453,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1813811399872834,
    0.2539335289114962, 0.27086234141132853, 0.3047201340877446, 0.3627622799745668, 0.4449882760415409,
    0.5465616540707892, 0.6626456104909306, 0.7714741938772499,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.21394249999999998,
    0.2283655, 0.24519233333333335, 0.2692306666666667, 0.293269, 0.331731, 0.38221150000000004, 0.45432700000000004,
    0.5456730000000001, 0.6490385, 0.7524038333333333,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.144231, 0.20192333333333334,
    0.20913483333333333, 0.22355783333333332, 0.24278850000000002, 0.2692306666666667, 0.3028845, 0.34855783333333334,
    0.4062498333333333, 0.471154, 0.5528846666666667, 0.6442306666666666, 0.7427885000000001,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.04836837106730947, 0.19347348426923788,
    0.19831028784061855, 0.20314709141199924, 0.20556549319768957, 0.21523910034045085, 0.2297495110545929,
    0.25151512712580587, 0.28537275212547053, 0.32648608551246044, 0.37969109247439914, 0.4449882760415409,
    0.5223774685371344, 0.6142774071003727, 0.7158507851296209,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0514099132862423, 0.2056396531449692,
    0.2056396531449692, 0.2056396531449692, 0.2056396531449692, 0.21543195291983389, 0.21788002786355004,
    0.2252242526946986, 0.23746462741327945, 0.24970500213186028, 0.27418575156902203, 0.3133552901366834,
    0.3721094282540743, 0.45044816592119447, 0.5532678227595778, 0.6707760989943593,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.10973569339243855, 0.2194713867848771,
    0.2194713867848771, 0.2194713867848771, 0.2194713867848771, 0.22196536409682502, 0.2294472960326688,
    0.2294472960326688, 0.2294472960326688, 0.2394232052804605, 0.2444111599043563, 0.2668569557118876,
    0.3142426975555806, 0.3915565129760136, 0.5012922063684523, 0.6259915907158958,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11042584393772877, 0.22085168787545753,
    0.22085168787545753, 0.22085168787545753, 0.2233613503282067, 0.2308903376864542, 0.2308903376864542,
    0.2308903376864542, 0.2308903376864542, 0.2308903376864542, 0.2308903376864542, 0.24845797485569832,
    0.28861257409968494, 0.3538643198837374, 0.45676100245902723, 0.5822448211132509,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11112473042513506, 0.22224946085027011,
    0.22224946085027011, 0.22224946085027011, 0.22224946085027011, 0.22224946085027011, 0.22224946085027011,
    0.22224946085027011, 0.22224946085027011, 0.22224946085027011, 0.22982609925958405, 0.24245382994177395,
    0.27781147585190563, 0.33589938720091106, 0.4242940272926384, 0.5429953961270874,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11183251978078136, 0.22366503956156272,
    0.22112340740479672, 0.21349851093449876, 0.21349851093449876, 0.21349851093449876, 0.21349851093449876,
    0.21349851093449876, 0.21349851093449876, 0.22366503956156272, 0.2287483038750947, 0.24399809681569073,
    0.2694144183833507, 0.32024723773943997, 0.40157999541826, 0.5134125151990414,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11327557721608279, 0.22397672572222516,
    0.21625343959240387, 0.21625343959240387, 0.21625343959240387, 0.21625343959240387, 0.21625343959240387,
    0.21625343959240387, 0.21625343959240387, 0.2188278683023443, 0.22655115443216559, 0.23942329798186782,
    0.2625931563713318, 0.30635844444031923, 0.37586873358741457, 0.48142066768432434,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1140111212835036, 0.22283995153661412,
    0.21765766050622104, 0.21765766050622104, 0.2150665149910245, 0.20729307844543485, 0.20729307844543485,
    0.20729307844543485, 0.21765766050622104, 0.21765766050622104, 0.22283995153661412, 0.23061338808220375,
    0.24875140668857965, 0.2850274439013313, 0.3523977662575832, 0.44567936411208664,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11475628015305273, 0.22429639863149442,
    0.2190802369568834, 0.2190802369568834, 0.21647215611957787, 0.2086479136076613, 0.2086479136076613,
    0.2086479136076613, 0.21647215611957787, 0.2190802369568834, 0.22429639863149442, 0.23212064114341097,
    0.25037720700454963, 0.27645801537760484, 0.33383615545413114, 0.4199031847410183,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11475628015305273, 0.22429639863149442,
    0.2190802369568834, 0.2190802369568834, 0.21647215611957787, 0.2086479136076613, 0.2086479136076613,
    0.21125599444496684, 0.2190802369568834, 0.2216883177941889, 0.23212064114341097, 0.24516104532993857,
    0.2608095303537717, 0.2868903387268269, 0.3312278937889232, 0.3990385380425741,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11551124358752203, 0.2257720091969823,
    0.2205215312189205, 0.2205215312189205, 0.2178962922298896, 0.2100205752627969, 0.2100205752627969,
    0.2152710532408587, 0.2205215312189205, 0.22839724818601317, 0.23889820414213675, 0.257274877065353,
    0.2730263109995384, 0.29402822291178554, 0.3307817507757588, 0.38853755458706046,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11705136850368372, 0.2287822537925458,
    0.22346177057772415, 0.22346177057772415, 0.22080152897031335, 0.21282080414808083, 0.21282080414808083,
    0.21814128736290248, 0.22346177057772415, 0.23410273700736745, 0.24740394504442162, 0.26336539468888653,
    0.2819870859407624, 0.3006087771926382, 0.32721119326674647, 0.369775796762927,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11944022229700378, 0.23345137780026243,
    0.2280223110065173, 0.2280223110065173, 0.2253077776096447, 0.21716417741902697, 0.21716417741902697,
    0.2280223110065173, 0.23073684440338985, 0.24159497799088014, 0.255167644975243, 0.274169378753351,
    0.29045657913458645, 0.3067437795158219, 0.32574551329393, 0.3637495454761039,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.12278115323924348, 0.23998138014454767,
    0.2344004538106084, 0.2344004538106084, 0.2344004538106084, 0.2232386011427298, 0.23160999064363874,
    0.2344004538106084, 0.2427718433115173, 0.25393369597939586, 0.2706764749812138, 0.284628790816062,
    0.3041620329848495, 0.31532388565272806, 0.32927620148757636, 0.35718102663041756,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.12631446532868798, 0.24688740024075972,
    0.2411458698241435, 0.2411458698241435, 0.2411458698241435, 0.229662808990911, 0.23827510461583534,
    0.2411458698241435, 0.24975816544906787, 0.26411199149060843, 0.2813365827404572, 0.2985611739903059,
    0.3157857652401546, 0.32726882607338714, 0.3416226521149277, 0.36171800857308456,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.129100768482142, 0.25233335720260786,
    0.24646517744093172, 0.24646517744093172, 0.24353108756009362, 0.2347288179175794, 0.23766290779841748,
    0.24646517744093172, 0.25233335720260786, 0.26700380660679823, 0.28460834589182665, 0.30514697505769317,
    0.3227515143427216, 0.3374219637469119, 0.35209241315110235, 0.36969695243613077,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.13201287727563576, 0.2580252070423953,
    0.2520246595335191, 0.2520246595335191, 0.24902438577908098, 0.24002356451576665, 0.24002356451576665,
    0.2520246595335191, 0.25802520704239534, 0.27002630206014777, 0.2880279445867764, 0.3090298608678431,
    0.3300317771489099, 0.34503314592110046, 0.360034514693291, 0.37503588346548156,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.13402830870084942, 0.2619644599509181,
    0.2558723025001373, 0.2558723025001373, 0.2528262237747469, 0.24368798759857568, 0.24368798759857568,
    0.2558723025001373, 0.2589183812255277, 0.2741487748524797, 0.29242524720482205, 0.3107017195571644,
    0.33202427063489715, 0.34725466426184914, 0.3594389791634107, 0.37162329406497235,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1371695455838399, 0.26810415112218544,
    0.2618692110766911, 0.2618692110766911, 0.25875174105394394, 0.24939933098570238, 0.24939933098570238,
    0.2587517410539439, 0.2618692110766911, 0.27745656119042694, 0.2930439113041629, 0.31174873144064597,
    0.33045355157712897, 0.3460409016908649, 0.3585107817818536, 0.3616282518046008,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.13934692601317109, 0.27235994076697284,
    0.2660260295076035, 0.2660260295076035, 0.2596921182482342, 0.2533582069888648, 0.2533582069888648,
    0.25652516261854946, 0.2660260295076035, 0.2755268963966575, 0.2913616745450809, 0.31036340832318887,
    0.3325320977309816, 0.3420329646200356, 0.35470078713877423, 0.35470078713877423,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.14046168365621442, 0.2745387855704823,
    0.2681542038285357, 0.2681542038285357, 0.26176962208658916, 0.2553850403446426, 0.2553850403446426,
    0.2553850403446426, 0.2681542038285357, 0.2745387855704823, 0.2905002399253487, 0.3128462760221617,
    0.32880773037702804, 0.3447691847318945, 0.3447691847318945, 0.3447691847318945,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.14159442097240293, 0.276752772467563,
    0.27031670299032023, 0.27031670299032023, 0.2638806335130774, 0.2574445640358346, 0.2574445640358346,
    0.2574445640358346, 0.27031670299032023, 0.276752772467563, 0.29284294616067014, 0.3153691893310201,
    0.3346773977627486, 0.3507675714558556, 0.36042167567171984, 0.3572036409330984,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.14274557648324795, 0.27900275856856743,
    0.272514364170639, 0.272514364170639, 0.2692701669716748, 0.25953757537478206, 0.25953757537478206,
    0.2627817725737463, 0.272514364170639, 0.28224695576753167, 0.29846794176235286, 0.3244215193540667,
    0.3471308997468163, 0.36659608294060175, 0.3763286745374944, 0.3763286745374944,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.14510510136979787, 0.28361455788646694,
    0.27701891303333814, 0.27701891303333814, 0.27372109060677374, 0.26382762332708054, 0.26382762332708054,
    0.26712544575364494, 0.27701891303333814, 0.29021020273959575, 0.30999713729898215, 0.33637971671149736,
    0.3627622961240126, 0.3858470531099634, 0.3957405203896566, 0.3957405203896566,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1500658797668244, 0.29331062617465087,
    0.286489492815653, 0.286489492815653, 0.28307892613615404, 0.27284722609765716, 0.27284722609765716,
    0.28307892613615404, 0.286489492815653, 0.30354232621314775, 0.3274162929696405, 0.3547008264056321,
    0.38198535984162374, 0.40244875991861745, 0.40926989327761537, 0.4058593265981164,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.15537801003639268, 0.30369342776892005,
    0.29663083546505475, 0.29663083546505475, 0.2930995393131221, 0.28250565085732415, 0.28250565085732415,
    0.2930995393131221, 0.29663083546505475, 0.314287316224718, 0.33194379698438126, 0.36019416619984246,
    0.37785064695950576, 0.39550712771916896, 0.39550712771916896, 0.39550712771916896,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1596155239160704, 0.3119758424750698,
    0.30472063711799885, 0.30472063711799885, 0.2974654317609279, 0.29021022640385685, 0.29021022640385685,
    0.29383782908239237, 0.30472063711799885, 0.3119758424750698, 0.33011385586774733, 0.3518794719389603,
    0.3700174853316378, 0.3772726906887088, 0.3772726906887088, 0.3772726906887088,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1610798627327553, 0.3148379596726704,
    0.30751619387983026, 0.30751619387983026, 0.3001944280869901, 0.2928726622941499, 0.2928726622941499,
    0.2928726622941499, 0.30751619387983026, 0.31117707677625034, 0.32948149125835074, 0.3477859057404512,
    0.3660903202225516, 0.37707296891181186, 0.38073385180823194, 0.3660903202225516,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.16409082117809376, 0.3207230156784749,
    0.31326438900076226, 0.31326438900076226, 0.30580576232304957, 0.29834713564533694, 0.29834713564533694,
    0.29834713564533694, 0.31326438900076226, 0.3207230156784749, 0.3356402690339002, 0.3542868357281818,
    0.3729334024224635, 0.3878506557778888, 0.3878506557778888, 0.3803920291001761,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1672163112158053, 0.3268319289203674,
    0.3192312354091242, 0.3192312354091242, 0.311630541897881, 0.30402984838663777, 0.30402984838663777,
    0.30402984838663777, 0.3192312354091242, 0.3268319289203674, 0.3420333159428538, 0.3610350497209619,
    0.38003678349906983, 0.39523817052155624, 0.39523817052155624, 0.38003678349906983,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.17046317755121557, 0.33317807768799096,
    0.3254298002735508, 0.3254298002735508, 0.3176815228591106, 0.30993324544467044, 0.30993324544467044,
    0.30993324544467044, 0.3254298002735508, 0.33317807768799096, 0.3486746325168713, 0.36804532605297174,
    0.3874160195890721, 0.40291257441795253, 0.3990384357107324, 0.3874160195890721,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1738386305389464, 0.3397755549492632,
    0.33187384882063353, 0.33187384882063353, 0.32397214269200386, 0.3160704365633742, 0.3160704365633742,
    0.3160704365633742, 0.33187384882063353, 0.33977555494926315, 0.3595298202708374, 0.3832349386567264,
    0.39903835091398576, 0.41089091010693024, 0.41089091010693024, 0.3950874978496709,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.17557718229243902, 0.34317363387464117,
    0.33519290316440425, 0.33519290316440425, 0.3272121724541674, 0.3192314417439305, 0.3192314417439305,
    0.3192314417439305, 0.33519290316440425, 0.34317363387464117, 0.3631254606502334, 0.38706765278094407,
    0.40302911420141785, 0.4150002102667732, 0.4150002102667732, 0.4110098449116547,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.17916033344550567, 0.3501770667003936,
    0.34203346650977595, 0.34203346650977595, 0.3338898663191582, 0.32574626612854046, 0.32574626612854046,
    0.32574626612854046, 0.34203346650977595, 0.3501770667003936, 0.36646426708162905, 0.38682326755817337,
    0.4112540681300265, 0.4234694684159531, 0.4234694684159531, 0.4234694684159531,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.18289278015378052, 0.3574723045177355,
    0.3491590487279099, 0.3491590487279099, 0.34084579293808437, 0.33253253714825876, 0.33253253714825876,
    0.33253253714825876, 0.3491590487279099, 0.3533156766228227, 0.37409881609738665, 0.3948819555719506,
    0.4115084671516018, 0.4322916066261658, 0.4322916066261658, 0.4322916066261658,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.1867840517637197, 0.36507797287019744,
    0.35658784221295553, 0.35658784221295553, 0.3480977115557136, 0.33960758089847165, 0.33960758089847165,
    0.33960758089847165, 0.35658784221295553, 0.3650779728701975, 0.3820582341846814, 0.40328356082778627,
    0.42450888747089116, 0.44148914878537504, 0.45422434477123796, 0.458469410099859,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.19084473581331007, 0.37301476558505997,
    0.36434005954349985, 0.36434005954349985, 0.3556653535019397, 0.3469906474603795, 0.3469906474603795,
    0.3469906474603795, 0.36434005954349985, 0.37301476558505997, 0.3903641776681803, 0.42072564881364094,
    0.44241241391754127, 0.4597618260006616, 0.4684365320422218, 0.4684365320422218,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.19727760044863513, 0.38558809376076364,
    0.3766209866242569, 0.3766209866242569, 0.3676538794877502, 0.3586867723512435, 0.3586867723512435,
    0.3586867723512435, 0.3766209866242569, 0.3855880937607636, 0.40800586160203034, 0.43490718301155035,
    0.4573249508528171, 0.47525916512583055, 0.4842262722623372, 0.47974271869408386,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2041595248022154, 0.39903912969625843,
    0.3897592097880861, 0.3897592097880861, 0.38047928987991375, 0.37119936997174136, 0.37119936997174136,
    0.37119936997174136, 0.3897592097880861, 0.39903912969625843, 0.4222389294666893, 0.45007868919120636,
    0.47327848896163727, 0.4825584088698096, 0.4825584088698096, 0.4825584088698096,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.21153866666666665, 0.413462,
    0.4038466666666667, 0.4038466666666667, 0.3942313333333333, 0.384616, 0.384616, 0.384616, 0.4038466666666667,
    0.413462, 0.4375003333333333, 0.46634633333333336, 0.4903846666666667, 0.5, 0.5, 0.5,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.21676175798712438, 0.4236707708954979,
    0.41381802581674704, 0.41381802581674704, 0.4039652807379961, 0.39411253565924514, 0.39411253565924514,
    0.39411253565924514, 0.41381802581674704, 0.4236707708954979, 0.4483026335923752, 0.47786086882862794,
    0.5024927315255052, 0.5123454766042561, 0.5221982216830071, 0.5123454766042561,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.21947123498392568, 0.42896656762005947,
    0.4189906652722675, 0.4189906652722675, 0.4090147629244756, 0.39903886057668364, 0.39903886057668364,
    0.39903886057668364, 0.4189906652722675, 0.4289665676200594, 0.44891837231564324, 0.483834030532915,
    0.5087737864023949, 0.5187496887501868, 0.5187496887501868, 0.5187496887501868,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2222496165187608, 0.4343970414159738,
    0.42429484979442605, 0.42429484979442605, 0.41419265817287826, 0.40409046655133046, 0.40409046655133046,
    0.40409046655133046, 0.41924375398365216, 0.42934594560519995, 0.4495503288482955, 0.474805807902165,
    0.5000612869560345, 0.525316766009904, 0.525316766009904, 0.5152145743883562,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2280222425670072, 0.44567990307322825,
    0.4353153210124421, 0.4353153210124421, 0.4249507389516559, 0.4145861568908697, 0.4145861568908697,
    0.4145861568908697, 0.4249507389516559, 0.4353153210124421, 0.4560444851340144, 0.4819559402859799,
    0.5026851044075523, 0.5182319774987316, 0.5182319774987316, 0.5182319774987316,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2280222425670072, 0.44567990307322825,
    0.4353153210124421, 0.4353153210124421, 0.4249507389516559, 0.4145861568908697, 0.4145861568908697,
    0.4145861568908697, 0.41976844792126283, 0.4353153210124421, 0.4508621941036213, 0.47159135822519377,
    0.5026851044075523, 0.5182319774987316, 0.5182319774987316, 0.5182319774987316,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.23102248717504406, 0.4515440183939646,
    0.441043062437841, 0.441043062437841, 0.4305421064817174, 0.4200411505255938, 0.4200411505255938,
    0.4200411505255938, 0.43579258445977925, 0.441043062437841, 0.4672954523281499, 0.48829736424039716,
    0.5092992761526443, 0.5250507100868296, 0.5250507100868296, 0.5092992761526443,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.23102248717504406, 0.4515440183939646,
    0.441043062437841, 0.441043062437841, 0.4305421064817174, 0.4200411505255938, 0.4200411505255938,
    0.4200411505255938, 0.441043062437841, 0.4462935404159028, 0.4672954523281499, 0.49354784221845893,
    0.5092992761526443, 0.5250507100868296, 0.5092992761526443, 0.4987983201965207,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.23410273700736745, 0.4575645075850916,
    0.4469235411554483, 0.4469235411554483, 0.436282574725805, 0.42564160829616166, 0.42564160829616166,
    0.42564160829616166, 0.4469235411554483, 0.4575645075850916, 0.4788464404443782, 0.5001283733036649,
    0.5107693397333082, 0.5214103061629515, 0.5107693397333082, 0.4948078900888432,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2405164036692797, 0.4701003124436245,
    0.45916781754868957, 0.45916781754868957, 0.4482353226537546, 0.4373028277588196, 0.4373028277588196,
    0.4373028277588196, 0.45916781754868957, 0.464634064996157, 0.4864990547860269, 0.5138302920233643,
    0.5247627869182993, 0.5247627869182993, 0.5247627869182993, 0.5028977971284293,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.24729178291867088, 0.4833431010994972,
    0.4721026363616526, 0.4721026363616526, 0.46086217162380805, 0.4496217068859634, 0.4496217068859634,
    0.4496217068859634, 0.4664824039927303, 0.4777228687305749, 0.5002037982062641, 0.517064495313031,
    0.5395454247887201, 0.5395454247887201, 0.5226847276819533, 0.517064495313031,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2544595457213702, 0.4973528213583451,
    0.48578655127394976, 0.48578655127394976, 0.4742202811895544, 0.46265401110515897, 0.46265401110515897,
    0.46265401110515897, 0.4800034162317521, 0.48578655127394976, 0.5089190914427404, 0.5320516316115311,
    0.5320516316115311, 0.5320516316115311, 0.5320516316115311, 0.5089190914427404,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.26205522759331507, 0.5121989290114994,
    0.5002874028363687, 0.5002874028363687, 0.48837587666123794, 0.4764643504861072, 0.4764643504861072,
    0.4764643504861072, 0.49433163974880334, 0.5062431659239341, 0.5300662182741954, 0.5479335075368916,
    0.5717565598871531, 0.5717565598871531, 0.553889270624457, 0.5419777444493262,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.40517818196676936, 0.5279595266187564,
    0.5156814772818206, 0.5156814772818206, 0.5034034279448846, 0.4911253786079486, 0.4911253786079486,
    0.4911253786079486, 0.5156814772818206, 0.5279595266187564, 0.5525156252926283, 0.5832107486349682,
    0.6077668473088402, 0.6139058719773082, 0.6077668473088401, 0.5832107486349682,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.41804077803951323, 0.551053792793315,
    0.532052059015207, 0.532052059015207, 0.5193842364964684, 0.5067164139777296, 0.5067164139777296,
    0.5067164139777296, 0.5257181477558377, 0.5383859702745764, 0.570055526571423, 0.6017250828682698,
    0.6333946391651164, 0.6523963729432245, 0.6460624616838551, 0.6270607279057471,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.4317468092633413, 0.5691208352609284,
    0.5494961039903485, 0.5494961039903485, 0.5364129498099619, 0.5233297956295753, 0.5233297956295753,
    0.5233297956295753, 0.5429545269001552, 0.5494961039903485, 0.5822039894413149, 0.6083702978020882,
    0.6345366061628614, 0.6541613374334413, 0.6541613374334413, 0.6410781832530548,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.44638204725650077, 0.5884127412859456,
    0.5681227761177793, 0.5681227761177793, 0.5545961326723351, 0.5410694892268909, 0.5410694892268909,
    0.5410694892268909, 0.547832810949613, 0.5681227761177793, 0.5816494195632235, 0.6019393847313897,
    0.622229349899556, 0.6425193150677224, 0.6492826367904445, 0.6425193150677224,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.4620451986176368, 0.6020589834194074,
    0.5880577020152993, 0.5880577020152993, 0.574056420611191, 0.5600551392070827, 0.5600551392070827,
    0.5600551392070827, 0.5600551392070827, 0.5600551392070827, 0.5880577020152993, 0.5880577020152993,
    0.6090596241214616, 0.6160602648235157, 0.6160602648235157, 0.6160602648235157,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.4877139554710299, 0.6355061563432469,
    0.6207270387251206, 0.6207270387251206, 0.6059479211069941, 0.5911688034888678, 0.5911688034888678,
    0.5911688034888678, 0.5911688034888678, 0.5911688034888678, 0.5911688034888678, 0.5911688034888678,
    0.6059479211069942, 0.6207270387251206, 0.6207270387251206, 0.6207270387251206,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5064718651570613, 0.659948284668426,
    0.6446007491274368, 0.6446007491274368, 0.6292532135864476, 0.6139056780454584, 0.6139056780454584,
    0.6139056780454584, 0.6139056780454584, 0.6139056780454584, 0.6139056780454584, 0.6139056780454584,
    0.6139056780454584, 0.6139056780454584, 0.6139056780454584, 0.6139056780454584,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.526731546877317, 0.6863472677492823,
    0.6703858063288085, 0.6703858063288085, 0.6544243449083348, 0.638462883487861, 0.638462883487861, 0.638462883487861,
    0.638462883487861, 0.638462883487861, 0.638462883487861, 0.638462883487861, 0.638462883487861, 0.638462883487861,
    0.638462883487861, 0.638462883487861,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5603521552911591, 0.7301559457403949,
    0.7131756844259111, 0.7131756844259111, 0.6961954231114272, 0.6792151617969433, 0.6792151617969433,
    0.6792151617969433, 0.6792151617969433, 0.6792151617969433, 0.6792151617969433, 0.6792151617969433,
    0.6792151617969433, 0.6792151617969433, 0.6792151617969433, 0.6792151617969433,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5852562667997944, 0.7714742257646442,
    0.7448718358593994, 0.7448718358593994, 0.7448718358593994, 0.7094019826524062, 0.7094019826524062,
    0.7094019826524062, 0.7094019826524062, 0.7094019826524062, 0.7094019826524062, 0.7271369092559028,
    0.7448718358593994, 0.7094019826524062, 0.7094019826524062, 0.7094019826524062,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.6124785744066461, 0.8166380992088615,
    0.7795184195761722, 0.7795184195761722, 0.7795184195761722, 0.7795184195761722, 0.7516786598516552,
    0.7423987399434827, 0.7423987399434827, 0.7423987399434827, 0.7423987399434827, 0.7423987399434827,
    0.7423987399434827, 0.7423987399434827, 0.7423987399434827, 0.7423987399434827,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.6423550941746158, 0.8564734588994877,
    0.8272756841088097, 0.817543092511917, 0.817543092511917, 0.817543092511917, 0.7980779093181316, 0.7786127261243462,
    0.7786127261243462, 0.7786127261243462, 0.7786127261243462, 0.7786127261243462, 0.7786127261243462,
    0.7786127261243462, 0.7786127261243462, 0.7786127261243462,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.6584137049517771, 0.8579331352401189,
    0.837981330544535, 0.837981330544535, 0.837981330544535, 0.8180295258489512, 0.7980777211533673, 0.7980777211533673,
    0.7980777211533673, 0.7980777211533673, 0.7980777211533673, 0.7980777211533673, 0.7980777211533673,
    0.7980777211533673, 0.7980777211533673, 0.7980777211533673,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.675295820209415, 0.8696993558639086,
    0.859467665503249, 0.859467665503249, 0.8390042847819301, 0.8185409040606112, 0.8185409040606112,
    0.8185409040606112, 0.8185409040606112, 0.8185409040606112, 0.8185409040606112, 0.8185409040606112,
    0.8185409040606112, 0.8185409040606112, 0.8185409040606112, 0.8185409040606112,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.6930684707300807, 0.8925883805657405,
    0.8820874093187293, 0.8820874093187293, 0.8505844955776954, 0.8400835243306841, 0.8400835243306841,
    0.8400835243306841, 0.8400835243306841, 0.8400835243306841, 0.8400835243306841, 0.8400835243306841,
    0.8400835243306841, 0.8400835243306841, 0.8400835243306841, 0.8400835243306841,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.7315716828414298, 0.9421758617403573,
    0.9310915121687299, 0.9310915121687299, 0.8978384634538473, 0.8867541138822198, 0.8867541138822198,
    0.8867541138822198, 0.8867541138822198, 0.8867541138822198, 0.8867541138822198, 0.8867541138822198,
    0.8867541138822198, 0.8867541138822198, 0.8867541138822198, 0.8867541138822198,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9659093057849156,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9659093057849156,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9659093057849156,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9659093057849156,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9204550468314697, 0.9545457410465542, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9545457410465542, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9545457410465542, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9431821763081928, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9204550468314698, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.7272728705232772, 0.9545457410465542,
    0.9545457410465542, 0.9545457410465542, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
    0.9090914820931083, 0.9090914820931083, 0.9090914820931083, 0.9090914820931083,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 1, 1, 0.9880953167043598,
    0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439,
    0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.75, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.23809531670435974, 0.952381266817439,
    0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439,
    0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439,
    0.952381266817439, 0.952381266817439, 0.952381266817439, 0.9642859501130792, 1, 1,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.4000005546665484, 0.8000011093330968, 0.8000011093330968,
    0.8000011093330968, 0.8000011093330968, 0.8000011093330968, 0.8000011093330968, 0.8000011093330968,
    0.8000011093330968, 0.8000011093330968, 0.8000011093330968, 0.8000011093330968, 0.8000011093330968,
    0.8000011093330968, 0.8000011093330968, 0.820000998399787, 0.8400008874664774, 0.8400008874664774,
    0.8500008319998226, 0.880000665599858, 0.8900006101332032, 0.9200004437332387, 0.9600002218666195,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5172424922717198, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6982773047966739,
    0.7241392500998162, 0.7241392500998162, 0.7241392500998162, 0.7327598985341969, 0.7586218438373391,
    0.7758631407061007, 0.7931044375748622, 0.8275870313123851, 0.8534489766155273, 0.8879315703530504,
    0.9137935156561926, 0.9396554609593348, 0.965517406262477,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.7155186016654355, 0.7241392500998162, 0.7241392500998162,
    0.7413805469685776, 0.7586218438373391, 0.7758631407061007, 0.8017250860092429, 0.8275870313123851,
    0.862069625049908, 0.8879315703530504, 0.9224141640905733, 0.9482761093937155, 0.965517406262477, 1, 1, 1, 1, 1,
  ],
  [
    0, 0, 0, 0, 0, 0, 0, 0.17241416409057328, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.7155186016654355, 0.7241392500998162, 0.7241392500998162, 0.7413805469685776,
    0.7586218438373391, 0.7844837891404814, 0.8017250860092429, 0.8275870313123851, 0.862069625049908,
    0.8965522187874311, 0.9224141640905733, 0.9482761093937155, 0.965517406262477, 1, 1, 1, 1, 1, 0.9827587031312385,
    0.965517406262477, 0.9310348125249541, 0.9051728672218118, 0.8793109219186696,
  ],
  [
    0, 0, 0, 0, 0.34482832818114656, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.7068979532310546, 0.7241392500998162,
    0.7241392500998162, 0.7500011954029583, 0.7586218438373391, 0.7844837891404814, 0.8103457344436236,
    0.8362076797467658, 0.862069625049908, 0.8965522187874311, 0.9310348125249541, 0.9568967578280962,
    0.9741380546968577, 1, 1, 1, 1, 1, 0.9741380546968577, 0.9568967578280962, 0.9310348125249541, 0.8965522187874311,
    0.8706902734842888, 0.8448283281811466, 0.8189663828780043, 0.7931044375748622, 0.7586218438373391,
    0.7413805469685777,
  ],
  [
    0, 0, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.7068979532310546, 0.7241392500998162, 0.7500011954029583,
    0.7758631407061007, 0.8017250860092429, 0.8362076797467658, 0.8706902734842887, 0.8965522187874311,
    0.9310348125249541, 0.9568967578280962, 0.9741380546968577, 1, 1, 1, 1, 1, 0.9741380546968577, 0.9568967578280962,
    0.9310348125249541, 0.8965522187874311, 0.8706902734842888, 0.8362076797467659, 0.8103457344436236,
    0.7844837891404814, 0.7586218438373391, 0.7413805469685777, 0.7241392500998162, 0.18103481252495404, 0, 0, 0,
  ],
  [
    0, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931, 0.6896566563622931,
    0.6896566563622931, 0.6896566563622931, 0.7241392500998162, 0.7413805469685776, 0.7844837891404814,
    0.8448283281811465, 0.8879315703530504, 0.9396554609593348, 0.9741380546968577, 1, 1, 1, 1, 1, 0.965517406262477,
    0.9482761093937155, 0.9224141640905734, 0.8965522187874311, 0.862069625049908, 0.8362076797467659,
    0.8103457344436236, 0.7844837891404814, 0.7586218438373391, 0.732759898534197, 0.7241392500998162,
    0.17241416409057328, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  [
    0.17857178231305992, 0.7142871292522397, 0.7142871292522397, 0.7142871292522397, 0.7142871292522397,
    0.7142871292522397, 0.7142871292522397, 0.7321441836739748, 0.7500012380957098, 0.7946438741500473,
    0.8482150374152524, 0.9107147278913248, 0.9553573639456625, 1, 1, 1, 0.9732144183673975, 0.9553573639456626,
    0.9196432551021924, 0.8928576734695899, 0.85714356462612, 0.8303579829935173, 0.8035724013609149,
    0.7857153469391798, 0.7500012380957098, 0.7500012380957098, 0.17857178231305992, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ],
  [
    0.41666714814793415, 0.8333342962958683, 0.8333342962958683, 0.8333342962958683, 0.8333342962958683,
    0.8333342962958683, 0.8333342962958683, 0.8437509027773766, 0.8750007222219013, 0.8958339351849177,
    0.9270837546294424, 0.9687501805554753, 1, 1, 1, 0.9895833935184918, 0.958333574073967, 0.9270837546294423,
    0.916667148147934, 0.8750007222219013, 0.8645841157403931, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  [
    0.23809531670435974, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439,
    0.952381266817439, 0.952381266817439, 0.9880953167043598, 1, 1, 1, 1, 1, 1, 0.9642859501130792, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  [
    0.23809531670435974, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439, 0.952381266817439,
    0.952381266817439, 0.952381266817439, 0.952381266817439, 1, 0.9642859501130792, 0.7142859501130792, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  [
    0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0.75, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0.75, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0.75, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0.5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0.5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0.25, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0.25, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0.25, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0.75, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0.75, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0, 0.75, 1, 1, 1, 1, 1, 1, 1, 0.75, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 0.75, 1, 1, 1, 1, 1, 1, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 0.5, 1, 1, 1, 1, 1, 1, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 0.5, 1, 1, 1, 1, 1, 1, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 0.5, 1, 1, 1, 1, 1, 1, 1, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 0.25, 1, 1, 1, 1, 1, 1, 1, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 0.25, 1, 1, 1, 1, 1, 1, 1, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 0.25, 1, 1, 1, 1, 1, 1, 1, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
  [
    0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ],
  [
    0, 0.5, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0,
  ],
  [
    0, 0, 0, 0, 0, 0.75, 0.25, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0,
  ],
];
