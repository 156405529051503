import React, { ReactNode, createRef } from 'react';
import styled from 'styled-components';
import { ApplicationStateConsumer, ApplicationState } from '../../state/applicationState';
import { CalculationModelState } from '../../state/calculationModels';
import * as docx from 'docx-reporting';
import fsTypes from 'fs';
import FileSaver from 'file-saver';
import { RelativeServiceLifeChart } from '../chart/relative/RelativeServiceLifeChart';
import { ThicknessChart } from '../chart/thickness/ThicknessChart';
import { WearRateChart } from '../chart/wearRate/WearRateChart';
import { RelativeMiningServiceLifeChart } from '../chart/relative/RelativeMiningServiceLifeChart';
import { getSlidingServiceLifeDataPoints } from '../../view/sliding/Sliding';
import { getImpactServiceLifeDataPoints } from '../../view/impact/Impact';
import { getErosionServiceLifeDataPoints } from '../../view/erosion/Erosion';
import { doTippingCalculations, getTipperServiceLifeDataPoints } from '../../view/tipping/Tipping';
import { Steel, Abrasive, UnitSystemOption, TipperType, CalculationType } from '../../types';
import {
  ErosionPropertiesState,
  ImpactPropertiesState,
  SlidingPropertiesState,
  TippingPropertiesState,
} from '../../state/calculationModels';
import { formatPercentageChange } from '../../utils/numberFormatting';
import { getRockSizeText, getTipperTypeName, getSharpnessText } from './DocxProviderHelpers';
import { constructSteelArray } from '../../utils/steelHelpers';
import { HeatMap, HeatMapSide } from '../chart/heatMap/HeatMap';
import { HeatMapLegend } from '../chart/heatMap/HeatMapLegend';
import { generateTippingHeatMapMatrix } from '../../view/tipping/Tipping';
import {
  generateMiningHeatMapMatrix,
  doMiningCalculation,
  SectionIndex,
  SectionResult,
} from '../../view/mining/Mining';
import { duroxiteThicknessChange, isDuroxite } from '../../utils/duroxite';

const isElectron = import.meta.env.VITE_REACT_APP_IS_ELECTRON === 'true';
const fsPromise: Promise<typeof fsTypes> | undefined = isElectron ? import('fs') : undefined;

function toArrayBuffer(buffer: Buffer) {
  const ab = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
}

async function readTemplate(templateFileName: string) {
  if (isElectron) {
    if (!fsPromise) {
      return;
    }
    const fs = await fsPromise;
    return new Promise<ArrayBuffer>((resolve, reject) => {
      fs.readFile(`${__dirname}/${templateFileName}`, (err, result) => {
        if (err) {
          reject(err);
        } else resolve(toArrayBuffer(result));
      });
    });
  } else {
    const response = await fetch('/' + templateFileName);
    return response.arrayBuffer();
  }
}

function blobToArrayBuffer(blob: Blob) {
  const fileReader = new FileReader();

  return new Promise<ArrayBuffer>((resolve, reject) => {
    fileReader.onload = event => resolve((event.currentTarget as FileReader).result as ArrayBuffer);
    fileReader.onerror = reject;

    fileReader.readAsArrayBuffer(blob);
  });
}

function download(arrayBuffer: ArrayBuffer) {
  const blob = new Blob([arrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });
  FileSaver.saveAs(blob, 'wearcalc.docx');
}

async function retry<T>(
  options: { times: number; delay: number },
  action: () => T | undefined,
): Promise<T | undefined> {
  for (let attempt = 0; attempt < options.times; attempt++) {
    const result = action();
    if (result) return result;
    await new Promise(resolve => setTimeout(resolve, options.delay));
  }
  return undefined;
}

const htmlChartSize = { width: 700, height: 500 };
const docxChartSize = { width: 583, height: 381 };
const htmlMiningChartSize = { width: 700, height: 200 };
const docxMiningChartSize = { width: 583, height: 152 };
const docxHeatmapSideSize = { width: 200, height: 50 };
const docxHeatmapMiddleSize = { width: 200, height: 100 };
const docxHeatmapLegendSmall = { width: 40, height: 100 };
const docxHeatmapLegendMedium = { width: 30, height: 180 };
const docxHeatmapLegendLarge = { width: 40, height: 240 };

type ChartWrapperProps = {
  width: number;
  height: number;
};

const ChartWrapper = styled.div<ChartWrapperProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

// Charts

type ToRender = {
  calculationModel: 'sliding' | 'impact' | 'erosion' | 'tipping' | 'mining';
  chartType:
    | 'serviceLife'
    | 'thickness'
    | 'wearRate'
    | 'serviceLifeA'
    | 'serviceLifeB'
    | 'serviceLifeC'
    | 'tippingHeatmapCurrentTop'
    | 'tippingHeatmapCurrent'
    | 'tippingHeatmapCurrentBottom'
    | 'tippingHeatmapUpgradeTop'
    | 'tippingHeatmapUpgrade'
    | 'tippingHeatmapUpgradeBottom'
    | 'tippingHeatmapLegendSmall'
    | 'tippingHeatmapLegendLarge'
    | 'miningHeatmapCurrentTop'
    | 'miningHeatmapCurrent'
    | 'miningHeatmapCurrentBottom'
    | 'miningHeatmapUpgradeTop'
    | 'miningHeatmapUpgrade'
    | 'miningHeatmapUpgradeBottom'
    | 'miningHeatmapLegendSmall'
    | 'miningHeatmapLegend';
  index?: number;
};

type ChartRendererState = {
  toRender?: ToRender;
  miningCalculationResult?: {
    sectionResults: Map<SectionIndex, SectionResult>;
    maxServiceLife: number;
    currentSideWearData: Array<Array<number>>;
    upgradeSideWearData: Array<Array<number>>;
    currentWearData: Array<Array<number>>;
    upgradeWearData: Array<Array<number>>;
  };
  tippingCalculationResult?: {
    currentWearData: Array<number>;
    upgradeWearData: Array<number>;
    normalizedTippingsForSteels: Array<number>;
  };
  canvasRef?: HTMLCanvasElement;
};

class ChartRenderer extends React.Component<unknown, ChartRendererState> {
  state: ChartRendererState = {};

  chartWrapperRef = createRef<HTMLDivElement>();

  public async renderCharts(state: ApplicationState) {
    const charts = {
      sliding: state.docxSettings.sliding
        ? {
            serviceLife: await this.renderChart(state, {
              calculationModel: 'sliding',
              chartType: 'serviceLife',
            }),
            thickness: await this.renderChart(state, {
              calculationModel: 'sliding',
              chartType: 'thickness',
            }),
            wearRate: await this.renderChart(state, {
              calculationModel: 'sliding',
              chartType: 'wearRate',
            }),
          }
        : undefined,
      impact: state.docxSettings.impact
        ? {
            serviceLife: await this.renderChart(state, {
              calculationModel: 'impact',
              chartType: 'serviceLife',
            }),
            thickness: await this.renderChart(state, {
              calculationModel: 'impact',
              chartType: 'thickness',
            }),
          }
        : undefined,
      erosion: state.docxSettings.erosion
        ? {
            serviceLife: await this.renderChart(state, {
              calculationModel: 'erosion',
              chartType: 'serviceLife',
            }),
            thickness: await this.renderChart(state, {
              calculationModel: 'erosion',
              chartType: 'thickness',
            }),
          }
        : undefined,
      tipping: state.docxSettings.tipping
        ? {
            serviceLife: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'serviceLife',
            }),
            tippingHeatmapCurrentTop: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapCurrentTop',
            }),
            tippingHeatmapCurrent: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapCurrent',
            }),
            tippingHeatmapCurrentBottom: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapCurrentBottom',
            }),
            tippingHeatmapUpgradeTop: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapUpgradeTop',
            }),
            tippingHeatmapUpgrade: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapUpgrade',
            }),
            tippingHeatmapUpgradeBottom: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapUpgradeBottom',
            }),
            tippingHeatmapLegendSmall: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapLegendSmall',
            }),
            tippingHeatmapLegendLarge: await this.renderChart(state, {
              calculationModel: 'tipping',
              chartType: 'tippingHeatmapLegendLarge',
            }),
          }
        : undefined,
      mining: state.docxSettings.mining
        ? {
            serviceLifeA: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'serviceLifeA',
              index: 0,
            }),
            serviceLifeB: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'serviceLifeB',
              index: 1,
            }),
            serviceLifeC: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'serviceLifeC',
              index: 2,
            }),
            miningHeatmapCurrentTop: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'miningHeatmapCurrentTop',
            }),
            miningHeatmapCurrent: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'miningHeatmapCurrent',
            }),
            miningHeatmapCurrentBottom: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'miningHeatmapCurrentBottom',
            }),
            miningHeatmapUpgradeTop: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'miningHeatmapUpgradeTop',
            }),
            miningHeatmapUpgrade: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'miningHeatmapUpgrade',
            }),
            miningHeatmapUpgradeBottom: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'miningHeatmapUpgradeBottom',
            }),
            miningHeatmapLegend: await this.renderChart(state, {
              calculationModel: 'mining',
              chartType: 'miningHeatmapLegend',
            }),
          }
        : undefined,
    };
    await new Promise(resolve => this.setState({ toRender: undefined }, resolve as () => void));
    return charts;
  }

  async renderChart(state: ApplicationState, toRender: ToRender) {
    if (toRender.calculationModel === 'mining') {
      const miningSteels = state.steels.filter(steel => steel.includeInMining);

      const miningCalculationResult = await doMiningCalculation(
        miningSteels,
        state.miningProperties,
        state.selectedMiningAbrasive,
        state.selectedUnitSystem,
      );

      if (!miningCalculationResult) {
        throw Error('Invalid mining state');
      }

      this.setState({ miningCalculationResult });
    }

    if (toRender.calculationModel === 'tipping') {
      const tipperSteels = state.steels.filter(steel => steel.includeInTipping);

      const tippingData = await doTippingCalculations(
        tipperSteels,
        state.tippingProperties,
        state.selectedTippingAbrasive,
        state.selectedUnitSystem,
      );

      this.setState({
        tippingCalculationResult: {
          currentWearData: tippingData?.currentWearData || [],
          upgradeWearData: tippingData?.upgradeWearData || [],
          normalizedTippingsForSteels: tippingData?.normalizedTippingsForSteels || [],
        },
      });
    }

    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.setState({ toRender }, async () => {
        const chartCanvas = await retry({ times: 10, delay: 30 }, () => {
          return this.chartWrapperRef.current?.querySelector('canvas');
        });

        if (!chartCanvas) throw new Error('No chart canvas found');

        chartCanvas.toBlob(blob => {
          if (!blob) return reject(new Error('Can not generate image from canvas'));

          blobToArrayBuffer(blob!).then(buffer => {
            resolve(buffer);
          });
        });
      });
    });
  }

  render() {
    const { toRender } = this.state;

    return (
      <ApplicationStateConsumer>
        {({
          state: {
            slidingSteels,
            impactSteels,
            erosionSteels,
            slidingProperties,
            impactProperties,
            erosionProperties,
            tippingProperties,
            miningProperties,
            steels,
            selectedSlidingAbrasive,
            selectedImpactAbrasive,
            selectedErosionAbrasive,
            selectedTippingAbrasive,
            selectedMiningAbrasive,
            useTimeUnit,
            selectedUnitSystem,
            selectedSteelTab,
          },
        }) => {
          if (toRender === undefined) return null;

          if (!['sliding', 'impact', 'erosion', 'tipping', 'mining'].includes(toRender.calculationModel)) {
            return null;
          }

          let properties:
            | SlidingPropertiesState
            | ImpactPropertiesState
            | ErosionPropertiesState
            | TippingPropertiesState
            | undefined;
          switch (toRender.calculationModel) {
            case 'sliding':
              properties = slidingProperties;
              break;
            case 'impact':
              properties = impactProperties;
              break;
            case 'erosion':
              properties = erosionProperties;
              break;
            case 'tipping':
              properties = tippingProperties;
              break;
          }

          let selectedAbrasive: Abrasive | undefined;
          switch (toRender.calculationModel) {
            case 'sliding':
              selectedAbrasive = selectedSlidingAbrasive;
              break;
            case 'impact':
              selectedAbrasive = selectedImpactAbrasive;
              break;
            case 'erosion':
              selectedAbrasive = selectedErosionAbrasive;
              break;
            case 'tipping':
              selectedAbrasive = selectedTippingAbrasive;
              break;
            case 'mining':
              selectedAbrasive = selectedMiningAbrasive;
          }

          let filteredSteels;
          switch (toRender.calculationModel) {
            case 'sliding':
              filteredSteels = constructSteelArray(
                slidingSteels.filter(steel => steel.includeInSliding),
                properties!.currentSteelState.currentSteel,
              );
              break;
            case 'impact':
              filteredSteels = constructSteelArray(
                impactSteels.filter(steel => steel.includeInAbrasiveImpact),
                properties!.currentSteelState.currentSteel,
              );
              break;
            case 'erosion':
              filteredSteels = constructSteelArray(
                erosionSteels.filter(steel => steel.includeInErosion),
                properties!.currentSteelState.currentSteel,
              );
              break;
            case 'tipping':
              filteredSteels = steels.filter(steel => steel.includeInTipping);
              break;
            case 'mining':
              filteredSteels = steels.filter(steel => steel.includeInMining);
              break;
            default:
              filteredSteels = steels;
          }

          let data;
          let reference;
          if (toRender.calculationModel !== 'mining') {
            const serviceLifeDataPoints = getServiceLifeDataPoints(
              toRender.calculationModel,
              filteredSteels.filter(s => !s.hiddenInCharts),
              selectedAbrasive,
              properties!.currentSteelState.currentSteel,
              properties!,
              selectedUnitSystem,
            );
            data = serviceLifeDataPoints.data;
            reference = serviceLifeDataPoints.reference;
          }

          return (
            <ChartWrapper
              ref={this.chartWrapperRef}
              width={toRender.calculationModel !== 'mining' ? htmlChartSize.width : htmlMiningChartSize.width}
              height={toRender.calculationModel !== 'mining' ? htmlChartSize.height : htmlMiningChartSize.height}
            >
              {/* TIPPING */}
              {toRender.chartType === 'tippingHeatmapCurrentTop' && (
                <HeatMapSide
                  data={
                    selectedTippingAbrasive !== undefined
                      ? generateTippingHeatMapMatrix(
                          this.state.tippingCalculationResult?.currentWearData || [],
                          tippingProperties,
                          true,
                          'left',
                        )
                      : []
                  }
                  heightInPixels={55}
                  side={'left'}
                  calculationType={CalculationType.Tipping}
                />
              )}
              {toRender.chartType === 'tippingHeatmapCurrent' && (
                <HeatMap
                  data={
                    selectedTippingAbrasive !== undefined
                      ? generateTippingHeatMapMatrix(
                          this.state.tippingCalculationResult?.currentWearData || [],
                          tippingProperties,
                          false,
                        )
                      : []
                  }
                  heightInPixels={tippingProperties.tipperType === TipperType.Box ? 80 : 150}
                  virtualSides={tippingProperties.tipperType === TipperType.UShape}
                  renderSides={tippingProperties.tipperType === TipperType.Box}
                />
              )}
              {toRender.chartType === 'tippingHeatmapCurrentBottom' && (
                <HeatMapSide
                  data={
                    selectedTippingAbrasive !== undefined
                      ? generateTippingHeatMapMatrix(
                          this.state.tippingCalculationResult?.currentWearData || [],
                          tippingProperties,
                          true,
                          'right',
                        )
                      : []
                  }
                  heightInPixels={55}
                  side={'right'}
                  calculationType={CalculationType.Tipping}
                />
              )}
              {toRender.chartType === 'tippingHeatmapUpgradeTop' && (
                <HeatMapSide
                  data={
                    selectedTippingAbrasive !== undefined &&
                    tippingProperties.upgradeSteelState.upgradeSteel !== undefined
                      ? generateTippingHeatMapMatrix(
                          this.state.tippingCalculationResult?.upgradeWearData || [],
                          tippingProperties,
                          true,
                          'left',
                        )
                      : []
                  }
                  heightInPixels={55}
                  side={'left'}
                  calculationType={CalculationType.Tipping}
                />
              )}
              {toRender.chartType === 'tippingHeatmapUpgrade' && (
                <HeatMap
                  data={
                    selectedTippingAbrasive !== undefined &&
                    tippingProperties.upgradeSteelState.upgradeSteel !== undefined
                      ? generateTippingHeatMapMatrix(
                          this.state.tippingCalculationResult?.upgradeWearData || [],
                          tippingProperties,

                          false,
                        )
                      : []
                  }
                  heightInPixels={tippingProperties.tipperType === TipperType.Box ? 80 : 150}
                  virtualSides={tippingProperties.tipperType === TipperType.UShape}
                  renderSides={tippingProperties.tipperType === TipperType.Box}
                />
              )}
              {toRender.chartType === 'tippingHeatmapUpgradeBottom' && (
                <HeatMapSide
                  data={
                    selectedTippingAbrasive !== undefined &&
                    tippingProperties.upgradeSteelState.upgradeSteel !== undefined
                      ? generateTippingHeatMapMatrix(
                          this.state.tippingCalculationResult?.upgradeWearData || [],
                          tippingProperties,

                          true,
                          'right',
                        )
                      : []
                  }
                  heightInPixels={55}
                  side={'right'}
                  calculationType={CalculationType.Tipping}
                />
              )}
              {toRender.chartType === 'tippingHeatmapLegendSmall' && (
                <HeatMapLegend heightInPixels={docxHeatmapLegendSmall.height} />
              )}
              {toRender.chartType === 'tippingHeatmapLegendLarge' && (
                <HeatMapLegend heightInPixels={docxHeatmapLegendLarge.height} />
              )}
              {/* MINING */}
              {toRender.chartType === 'miningHeatmapCurrentTop' && (
                <HeatMapSide
                  data={
                    selectedMiningAbrasive !== undefined
                      ? generateMiningHeatMapMatrix(
                          this.state.miningCalculationResult?.currentSideWearData || [],
                          miningProperties.currentMiningSteelsState,
                          true,
                          'left',
                        )
                      : []
                  }
                  heightInPixels={45}
                  side={'left'}
                  calculationType={CalculationType.Mining}
                />
              )}
              {toRender.chartType === 'miningHeatmapCurrent' && (
                <HeatMap
                  data={
                    selectedMiningAbrasive !== undefined
                      ? generateMiningHeatMapMatrix(
                          this.state.miningCalculationResult?.currentWearData || [],
                          miningProperties.currentMiningSteelsState,
                          false,
                        )
                      : []
                  }
                  heightInPixels={100}
                  renderSides={true}
                />
              )}
              {toRender.chartType === 'miningHeatmapCurrentBottom' && (
                <HeatMapSide
                  data={
                    selectedMiningAbrasive !== undefined
                      ? generateMiningHeatMapMatrix(
                          this.state.miningCalculationResult?.currentSideWearData || [],
                          miningProperties.currentMiningSteelsState,
                          true,
                          'right',
                        )
                      : []
                  }
                  heightInPixels={45}
                  side={'right'}
                  calculationType={CalculationType.Mining}
                />
              )}
              {toRender.chartType === 'miningHeatmapUpgradeTop' && (
                <HeatMapSide
                  data={
                    selectedMiningAbrasive !== undefined
                      ? generateMiningHeatMapMatrix(
                          this.state.miningCalculationResult?.upgradeSideWearData || [],
                          miningProperties.upgradeMiningSteelsState,
                          true,
                          'left',
                        )
                      : []
                  }
                  heightInPixels={45}
                  side={'left'}
                  calculationType={CalculationType.Mining}
                />
              )}
              {toRender.chartType === 'miningHeatmapUpgrade' && (
                <HeatMap
                  data={
                    selectedMiningAbrasive !== undefined
                      ? generateMiningHeatMapMatrix(
                          this.state.miningCalculationResult?.upgradeWearData || [],
                          miningProperties.upgradeMiningSteelsState,
                          false,
                        )
                      : []
                  }
                  heightInPixels={100}
                  renderSides={true}
                />
              )}
              {toRender.chartType === 'miningHeatmapUpgradeBottom' && (
                <HeatMapSide
                  data={
                    selectedMiningAbrasive !== undefined
                      ? generateMiningHeatMapMatrix(
                          this.state.miningCalculationResult?.upgradeSideWearData || [],
                          miningProperties.upgradeMiningSteelsState,
                          true,
                          'right',
                        )
                      : []
                  }
                  heightInPixels={45}
                  side={'right'}
                  calculationType={CalculationType.Mining}
                />
              )}
              {toRender.chartType === 'serviceLife' && properties && (
                <RelativeServiceLifeChart
                  disableAnimations
                  currentSteelState={properties!.currentSteelState}
                  upgradeSteelState={properties!.upgradeSteelState}
                  steels={filteredSteels.filter(s => !s.hiddenInCharts)}
                  useTimeUnit={useTimeUnit}
                  data={data as Array<number>}
                  reference={reference as number}
                  selectedSteelTab={selectedSteelTab}
                />
              )}
              {toRender.chartType === 'thickness' && (
                <ThicknessChart
                  disableAnimations
                  currentSteelState={(properties as CalculationModelState).currentSteelState}
                  upgradeSteelState={(properties as CalculationModelState).upgradeSteelState}
                  steels={filteredSteels.filter(s => !s.hiddenInCharts)}
                  data={
                    toRender.calculationModel === 'tipping'
                      ? this.state.tippingCalculationResult?.normalizedTippingsForSteels || []
                      : (data as Array<number>)
                  }
                  reference={reference as number}
                  selectedUnitSystem={selectedUnitSystem}
                  selectedSteelTab={selectedSteelTab}
                />
              )}
              {toRender.chartType === 'wearRate' && (
                <WearRateChart selectedAbrasive={selectedAbrasive} disableAnimations />
              )}
              {toRender.chartType === 'miningHeatmapLegend' && (
                <HeatMapLegend heightInPixels={docxHeatmapLegendMedium.height} />
              )}
              {(toRender.chartType === 'serviceLifeA' ||
                toRender.chartType === 'serviceLifeB' ||
                toRender.chartType === 'serviceLifeC') &&
                (() => {
                  const sectionIndex = toRender.index ? toRender.index : 0;

                  const miningSteels = steels.filter(steel => steel.includeInMining);
                  return (
                    <RelativeMiningServiceLifeChart
                      disableAnimations
                      key={sectionIndex}
                      currentMiningSteelState={miningProperties.currentMiningSteelsState[sectionIndex]}
                      upgradeMiningSteelState={miningProperties.upgradeMiningSteelsState[sectionIndex]}
                      miningProperties={miningProperties}
                      steels={miningSteels}
                      reference={this.state
                        .miningCalculationResult!.sectionResults.get(sectionIndex)!
                        .numberOfTippingsPerSteel.get(
                          miningProperties.currentMiningSteelsState[sectionIndex].steel!.id,
                        )}
                      useTimeUnit={useTimeUnit}
                      data={[
                        ...this.state
                          .miningCalculationResult!.sectionResults.get(sectionIndex)!
                          .normalizedNumberOfTippingsPerSteel.values(),
                      ]}
                      hideXAxis={sectionIndex === 2 ? false : true}
                      setCurrentSteel={(_steel: Steel) => {}}
                      setUpgradeSteel={(_steel: Steel | undefined) => {}}
                      selectedSteelTab={selectedSteelTab}
                      currentServiceLife={
                        this.state.miningCalculationResult!.sectionResults.get(sectionIndex)!.serviceLife
                      }
                      suggestedMaxValue={this.state.miningCalculationResult!.maxServiceLife}
                      chartTitleId={'steel'}
                      hideChartTypeToggle={sectionIndex === 0 ? false : true}
                    />
                  );
                })()}
            </ChartWrapper>
          );
        }}
      </ApplicationStateConsumer>
    );
  }
}

// Docx Provider

type DocxProviderProps = {
  children: (props: { renderDocx: () => Promise<void>; canRenderDocx: boolean; isRendering: boolean }) => ReactNode;
};

export class DocxProvider extends React.Component<DocxProviderProps, { isRendering: boolean }> {
  chartRenderer: ChartRenderer | null = null;

  constructor(props: DocxProviderProps) {
    super(props);

    this.state = { isRendering: false };
  }
  render() {
    const { children } = this.props;
    return (
      <>
        <ApplicationStateConsumer>
          {({ state }) =>
            children({
              canRenderDocx:
                state.selectedErosionAbrasive !== undefined ||
                state.selectedImpactAbrasive !== undefined ||
                state.selectedSlidingAbrasive !== undefined ||
                state.selectedTippingAbrasive !== undefined ||
                state.selectedMiningAbrasive !== undefined,
              isRendering: this.state.isRendering,
              renderDocx: async () => {
                this.setState({ isRendering: true });

                const renderTippingHeatMapInfo =
                  (state.tippingProperties.currentSteelState.currentTippings &&
                    state.tippingProperties.heatMapTippings >
                      state.tippingProperties.currentSteelState.currentTippings) ||
                  (state.tippingProperties.upgradeSteelState.upgradeTippings &&
                    state.tippingProperties.heatMapTippings >
                      state.tippingProperties.upgradeSteelState.upgradeTippings &&
                    state.tippingProperties.upgradeSteelState.upgradeTippings > 0);

                const renderMiningHeatMapInfo =
                  state.miningProperties.heatMapTippings > state.miningProperties.currentTippings ||
                  (state.miningProperties.heatMapTippings > state.miningProperties.upgradeTippings &&
                    state.miningProperties.upgradeTippings > 0);

                const [template, media] = await Promise.all([
                  readTemplate(
                    state.docxSettings.sliding
                      ? 'template-sliding.docx'
                      : state.docxSettings.impact
                        ? 'template-impact.docx'
                        : state.docxSettings.erosion
                          ? 'template-erosion.docx'
                          : state.docxSettings.tipping
                            ? 'template-tipping.docx'
                            : 'template-mining.docx',
                  ),
                  this.chartRenderer!.renderCharts(state),
                ]);
                const units = {
                  isMetric: state.selectedUnitSystem === 'metric' ? true : null,
                  isImperial: state.selectedUnitSystem === 'imperial' ? true : null,
                };

                const rendered = await docx.generate(
                  template!,
                  {
                    ...state,
                    selectedErosionAbrasive: state.docxSettings.erosion ? state.selectedErosionAbrasive : null,
                    selectedImpactAbrasive: state.docxSettings.impact ? state.selectedImpactAbrasive : null,
                    selectedSlidingAbrasive: state.docxSettings.sliding ? state.selectedSlidingAbrasive : null,
                    selectedTippingAbrasive: state.docxSettings.tipping ? state.selectedTippingAbrasive : null,
                    selectedMiningAbrasive: state.docxSettings.mining ? state.selectedMiningAbrasive : null,
                    sliding: state.docxSettings.sliding
                      ? {
                          data: {
                            ...units,
                            properties: state.slidingProperties,
                            isDuroxite: isDuroxite(state.slidingProperties.upgradeSteelState.upgradeSteel),
                            isNotDuroxite: !isDuroxite(state.slidingProperties.upgradeSteelState.upgradeSteel),
                            serviceLifeIncrease:
                              state.slidingProperties.upgradeSteelState.upgradeServiceLife >=
                              state.slidingProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.slidingProperties.upgradeSteelState.upgradeServiceLife /
                                      state.slidingProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            serviceLifeDecrease:
                              state.slidingProperties.upgradeSteelState.upgradeServiceLife <
                              state.slidingProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.slidingProperties.upgradeSteelState.upgradeServiceLife /
                                      state.slidingProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            weightReduction: isDuroxite(state.slidingProperties.upgradeSteelState.upgradeSteel)
                              ? state.slidingProperties.upgradeSteelState.upgradeThickness +
                                  (state.slidingProperties.upgradeSteelState.upgradeBaseThickness || 0) <=
                                state.slidingProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    duroxiteThicknessChange(
                                      state.slidingProperties.upgradeSteelState.upgradeBaseThickness,
                                      state.slidingProperties.upgradeSteelState.upgradeThickness,
                                      state.slidingProperties.currentSteelState.currentThickness,
                                    ),
                                  )
                                : null
                              : state.slidingProperties.upgradeSteelState.upgradeThickness <=
                                  state.slidingProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.slidingProperties.upgradeSteelState.upgradeThickness /
                                      state.slidingProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            weightIncrease: isDuroxite(state.slidingProperties.upgradeSteelState.upgradeSteel)
                              ? state.slidingProperties.upgradeSteelState.upgradeThickness +
                                  (state.slidingProperties.upgradeSteelState.upgradeBaseThickness || 0) >
                                state.slidingProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    duroxiteThicknessChange(
                                      state.slidingProperties.upgradeSteelState.upgradeBaseThickness,
                                      state.slidingProperties.upgradeSteelState.upgradeThickness,
                                      state.slidingProperties.currentSteelState.currentThickness,
                                    ),
                                  )
                                : null
                              : state.slidingProperties.upgradeSteelState.upgradeThickness >
                                  state.slidingProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.slidingProperties.upgradeSteelState.upgradeThickness /
                                      state.slidingProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            serviceLifeChart: {
                              name: 'sliding-serviceLife-chart.png',
                              size: docxChartSize,
                            },
                            thicknessChart: {
                              name: 'sliding-thickness-chart.png',
                              size: docxChartSize,
                            },
                            wearRateChart: {
                              name: 'sliding-wearRate-chart.png',
                              size: docxChartSize,
                            },
                          },
                          showDuroxite:
                            state.slidingProperties.currentSteelState.currentSteel.id === 11 ||
                            (state.slidingProperties.upgradeSteelState.upgradeSteel &&
                              state.slidingProperties.upgradeSteelState.upgradeSteel.id === 11) ||
                            false,
                          summary: state.docxSettings.reportType === 'summary',
                          details: state.docxSettings.reportType === 'details',
                        }
                      : null,
                    impact: state.docxSettings.impact
                      ? {
                          data: {
                            ...units,
                            properties: {
                              ...state.impactProperties,
                              abrasiveProperties: {
                                ...state.impactProperties.abrasiveProperties,
                                sharpnessText: getSharpnessText(state.impactProperties.abrasiveProperties.sharpness),
                              },
                            },
                            serviceLifeIncrease:
                              state.impactProperties.upgradeSteelState.upgradeServiceLife >=
                              state.impactProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.impactProperties.upgradeSteelState.upgradeServiceLife /
                                      state.impactProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            serviceLifeDecrease:
                              state.impactProperties.upgradeSteelState.upgradeServiceLife <
                              state.impactProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.impactProperties.upgradeSteelState.upgradeServiceLife /
                                      state.impactProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            weightReduction:
                              state.impactProperties.upgradeSteelState.upgradeThickness <=
                              state.impactProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.impactProperties.upgradeSteelState.upgradeThickness /
                                      state.impactProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            weightIncrease:
                              state.impactProperties.upgradeSteelState.upgradeThickness >
                              state.impactProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.impactProperties.upgradeSteelState.upgradeThickness /
                                      state.impactProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            serviceLifeChart: {
                              name: 'impact-serviceLife-chart.png',
                              size: docxChartSize,
                            },
                            thicknessChart: {
                              name: 'impact-thickness-chart.png',
                              size: docxChartSize,
                            },
                          },
                          summary: state.docxSettings.reportType === 'summary',
                          details: state.docxSettings.reportType === 'details',
                        }
                      : null,
                    erosion: state.docxSettings.erosion
                      ? {
                          data: {
                            ...units,
                            properties: state.erosionProperties,
                            isDuroxite: isDuroxite(state.erosionProperties.upgradeSteelState.upgradeSteel),
                            isNotDuroxite: !isDuroxite(state.erosionProperties.upgradeSteelState.upgradeSteel),
                            serviceLifeIncrease:
                              state.erosionProperties.upgradeSteelState.upgradeServiceLife >=
                              state.erosionProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.erosionProperties.upgradeSteelState.upgradeServiceLife /
                                      state.erosionProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            serviceLifeDecrease:
                              state.erosionProperties.upgradeSteelState.upgradeServiceLife <
                              state.erosionProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.erosionProperties.upgradeSteelState.upgradeServiceLife /
                                      state.erosionProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            weightReduction: isDuroxite(state.erosionProperties.upgradeSteelState.upgradeSteel)
                              ? state.erosionProperties.upgradeSteelState.upgradeThickness +
                                  (state.erosionProperties.upgradeSteelState.upgradeBaseThickness || 0) <=
                                state.erosionProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    duroxiteThicknessChange(
                                      state.erosionProperties.upgradeSteelState.upgradeBaseThickness,
                                      state.erosionProperties.upgradeSteelState.upgradeThickness,
                                      state.erosionProperties.currentSteelState.currentThickness,
                                    ),
                                  )
                                : null
                              : state.erosionProperties.upgradeSteelState.upgradeThickness <=
                                  state.erosionProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.erosionProperties.upgradeSteelState.upgradeThickness /
                                      state.erosionProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            weightIncrease: isDuroxite(state.erosionProperties.upgradeSteelState.upgradeSteel)
                              ? state.erosionProperties.upgradeSteelState.upgradeThickness +
                                  (state.erosionProperties.upgradeSteelState.upgradeBaseThickness || 0) >
                                state.erosionProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    duroxiteThicknessChange(
                                      state.erosionProperties.upgradeSteelState.upgradeBaseThickness,
                                      state.erosionProperties.upgradeSteelState.upgradeThickness,
                                      state.erosionProperties.currentSteelState.currentThickness,
                                    ),
                                  )
                                : null
                              : state.erosionProperties.upgradeSteelState.upgradeThickness >
                                  state.erosionProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.erosionProperties.upgradeSteelState.upgradeThickness /
                                      state.erosionProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            serviceLifeChart: {
                              name: 'erosion-serviceLife-chart.png',
                              size: docxChartSize,
                            },
                            thicknessChart: {
                              name: 'erosion-thickness-chart.png',
                              size: docxChartSize,
                            },
                          },
                          showDuroxite:
                            state.erosionProperties.currentSteelState.currentSteel.id === 11 ||
                            (state.erosionProperties.upgradeSteelState.upgradeSteel &&
                              state.erosionProperties.upgradeSteelState.upgradeSteel.id === 11) ||
                            false,
                          summary: state.docxSettings.reportType === 'summary',
                          details: state.docxSettings.reportType === 'details',
                        }
                      : null,
                    tipping: state.docxSettings.tipping
                      ? {
                          data: {
                            ...units,
                            properties: state.tippingProperties,
                            heatMapTippings: state.tippingProperties.heatMapTippings,
                            renderHeatMapInfo: renderTippingHeatMapInfo,
                            serviceLifeIncrease:
                              state.tippingProperties.upgradeSteelState.upgradeServiceLife >=
                              state.tippingProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.tippingProperties.upgradeSteelState.upgradeServiceLife /
                                      state.tippingProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            serviceLifeDecrease:
                              state.tippingProperties.upgradeSteelState.upgradeServiceLife <
                              state.tippingProperties.currentSteelState.currentServiceLife
                                ? formatPercentageChange(
                                    state.tippingProperties.upgradeSteelState.upgradeServiceLife /
                                      state.tippingProperties.currentSteelState.currentServiceLife,
                                  )
                                : null,
                            weightReduction:
                              state.tippingProperties.upgradeSteelState.upgradeThickness <=
                              state.tippingProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.tippingProperties.upgradeSteelState.upgradeThickness /
                                      state.tippingProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            weightIncrease:
                              state.tippingProperties.upgradeSteelState.upgradeThickness >
                              state.tippingProperties.currentSteelState.currentThickness
                                ? formatPercentageChange(
                                    state.tippingProperties.upgradeSteelState.upgradeThickness /
                                      state.tippingProperties.currentSteelState.currentThickness,
                                  )
                                : null,
                            rockSizeText: getRockSizeText(state.tippingProperties.rockSizeId, units),
                            useStiffenerText: state.tippingProperties.useStiffener ? 'Yes' : 'No',
                            tipperTypeText: getTipperTypeName(state.tippingProperties.tipperType),
                            serviceLifeChart: {
                              name: 'tipping-serviceLife-chart.png',
                              size: docxChartSize,
                            },
                            tippingHeatmapCurrentTop:
                              getTipperTypeName(state.tippingProperties.tipperType) === 'Box'
                                ? {
                                    name: 'tipping-tippingHeatmapCurrentTop-chart.png',
                                    size: docxHeatmapSideSize,
                                  }
                                : undefined,
                            tippingHeatmapCurrent: {
                              name: 'tipping-tippingHeatmapCurrent-chart.png',
                              size: docxHeatmapMiddleSize,
                            },
                            tippingHeatmapCurrentBottom:
                              getTipperTypeName(state.tippingProperties.tipperType) === 'Box'
                                ? {
                                    name: 'tipping-tippingHeatmapCurrentBottom-chart.png',
                                    size: docxHeatmapSideSize,
                                  }
                                : undefined,
                            tippingHeatmapUpgradeTop:
                              getTipperTypeName(state.tippingProperties.tipperType) === 'Box'
                                ? {
                                    name: 'tipping-tippingHeatmapUpgradeTop-chart.png',
                                    size: docxHeatmapSideSize,
                                  }
                                : undefined,
                            tippingHeatmapUpgrade: {
                              name: 'tipping-tippingHeatmapUpgrade-chart.png',
                              size: docxHeatmapMiddleSize,
                            },
                            tippingHeatmapUpgradeBottom:
                              getTipperTypeName(state.tippingProperties.tipperType) === 'Box'
                                ? {
                                    name: 'tipping-tippingHeatmapUpgradeBottom-chart.png',
                                    size: docxHeatmapSideSize,
                                  }
                                : undefined,
                            tippingHeatmapLegendSmall:
                              getTipperTypeName(state.tippingProperties.tipperType) === 'U-shape'
                                ? {
                                    name: 'tipping-tippingHeatmapLegendSmall-chart.png',
                                    size: docxHeatmapLegendSmall,
                                  }
                                : undefined,
                            tippingHeatmapLegendLarge:
                              getTipperTypeName(state.tippingProperties.tipperType) === 'Box'
                                ? {
                                    name: 'tipping-tippingHeatmapLegendLarge-chart.png',
                                    size: docxHeatmapLegendLarge,
                                  }
                                : undefined,
                          },
                        }
                      : null,
                    mining: state.docxSettings.mining
                      ? {
                          data: {
                            ...units,
                            heatMapTippings: state.miningProperties.heatMapTippings,
                            renderHeatMapInfo: renderMiningHeatMapInfo,
                            properties: {
                              ...state.miningProperties,
                              upgradeMiningSteelsState: [
                                ...state.miningProperties.upgradeMiningSteelsState.map(s => ({ ...s, ...units })),
                              ],
                              currentMiningSteelsState: [
                                {
                                  ...units,
                                  ...state.miningProperties.currentMiningSteelsState[0],
                                  wearProtectionText: state.miningProperties.currentMiningSteelsState[0].wearProtection
                                    ? 'Yes'
                                    : 'No',
                                },
                                {
                                  ...units,
                                  ...state.miningProperties.currentMiningSteelsState[1],
                                  wearProtectionText: state.miningProperties.currentMiningSteelsState[1].wearProtection
                                    ? 'Yes'
                                    : 'No',
                                },
                                {
                                  ...units,
                                  ...state.miningProperties.currentMiningSteelsState[2],
                                  wearProtectionText: state.miningProperties.currentMiningSteelsState[2].wearProtection
                                    ? 'Yes'
                                    : 'No',
                                },
                              ],
                            },

                            rockSizeText: getRockSizeText(state.miningProperties.rockSizeId, units),
                            serviceLifeA: {
                              name: 'mining-serviceLifeA-chart.png',
                              size: docxMiningChartSize,
                            },
                            serviceLifeB: {
                              name: 'mining-serviceLifeB-chart.png',
                              size: docxMiningChartSize,
                            },
                            serviceLifeC: {
                              name: 'mining-serviceLifeC-chart.png',
                              size: docxMiningChartSize,
                            },
                            miningHeatmapCurrentTop: {
                              name: 'mining-miningHeatmapCurrentTop-chart.png',
                              size: docxHeatmapSideSize,
                            },
                            miningHeatmapCurrent: {
                              name: 'mining-miningHeatmapCurrent-chart.png',
                              size: docxHeatmapMiddleSize,
                            },
                            miningHeatmapCurrentBottom: {
                              name: 'mining-miningHeatmapCurrentBottom-chart.png',
                              size: docxHeatmapSideSize,
                            },
                            miningHeatmapUpgradeTop: {
                              name: 'mining-miningHeatmapUpgradeTop-chart.png',
                              size: docxHeatmapSideSize,
                            },
                            miningHeatmapUpgrade: {
                              name: 'mining-miningHeatmapUpgrade-chart.png',
                              size: docxHeatmapMiddleSize,
                            },
                            miningHeatmapUpgradeBottom: {
                              name: 'mining-miningHeatmapUpgradeBottom-chart.png',
                              size: docxHeatmapSideSize,
                            },
                            miningHeatmapLegend: {
                              name: 'mining-miningHeatmapLegend-chart.png',
                              size: docxHeatmapLegendMedium,
                            },
                          },
                        }
                      : null,
                  },
                  Object.entries(media).reduce((media, [calculationModel, charts]) => {
                    if (charts) {
                      return {
                        ...media,
                        ...Object.entries(charts).reduce(
                          (media, [chartType, chart]) => ({
                            ...media,
                            [`${calculationModel}-${chartType}-chart.png`]: chart,
                          }),
                          {},
                        ),
                      };
                    } else {
                      return media;
                    }
                  }, {}),
                );
                this.setState({ isRendering: false });
                download(rendered);
              },
            })
          }
        </ApplicationStateConsumer>
        <ChartRenderer ref={chartRenderer => (this.chartRenderer = chartRenderer)} />
      </>
    );
  }
}

const getServiceLifeDataPoints = (
  calculationModel: 'sliding' | 'impact' | 'erosion' | 'tipping' | 'mining',
  steels: Array<Steel>,
  selectedAbrasive: Abrasive | undefined,
  currentSteel: Steel | undefined,
  properties: SlidingPropertiesState | ImpactPropertiesState | ErosionPropertiesState | TippingPropertiesState,
  selectedUnitSystem: UnitSystemOption,
) => {
  switch (calculationModel) {
    case 'sliding':
      return getSlidingServiceLifeDataPoints(steels, selectedAbrasive, currentSteel);
    case 'impact':
      return getImpactServiceLifeDataPoints(
        steels,
        selectedAbrasive,
        properties as ImpactPropertiesState,
        selectedUnitSystem,
      );
    case 'erosion':
      return getErosionServiceLifeDataPoints(steels, selectedAbrasive, properties as ErosionPropertiesState);
    case 'tipping':
      return getTipperServiceLifeDataPoints(
        steels,
        properties as TippingPropertiesState,
        selectedAbrasive,
        properties.currentSteelState.currentThickness,
        selectedUnitSystem,
      );
  }

  return { data: [], reference: null };
};
