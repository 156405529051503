import { ApplicationStateConsumer } from '../../state/applicationState';
import { PropertiesRow, RightDropdownWrapper } from '../../components/containers/PropertiesRow';
import { Column } from '../../components/containers/Column';
import { TranslatedInputLabel } from '../../components/labels/Labels';

import { ChartType } from '../../types';
import { NumberInput } from '../../components/form/NumberInput';
import { Toggle, DirectionalHint } from '@fluentui/react';
import { getLengthUnitShort } from '../../utils/unitSystem/unitSystemHelpers';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { convertMillimetersToInches } from '../../utils/convertUnits/convertUnits';
import { RockSizeDropdown } from '../../components/form/RockSizeDropdown';
import { RouteStateConsumer } from '../../state/routing/routeState';
import { TipperTypeDropdown } from '../../components/form/TipperTypeDropdown';
import { TippingValues } from '../../data/calculationValues';
import { AbrasiveInfoButton } from '../../components/abrasive/AbrasiveInfo';

export const TippingProperties = injectIntl((props: WrappedComponentProps) => (
  <RouteStateConsumer>
    {({ state: { currentRoute } }) => (
      <ApplicationStateConsumer>
        {({
          state: { tippingProperties, selectedUnitSystem, selectedChart, useTimeUnit },
          setTippingProperty,
          setTippingPropertyInstant,
        }) => (
          <>
            <PropertiesRow>
              <Column $size={6}>
                <Column $size={12}>
                  <TranslatedInputLabel translationId="tipperLength" />
                </Column>

                <NumberInput
                  value={tippingProperties.tipperLength}
                  suffix={getLengthUnitShort(props.intl, selectedUnitSystem)}
                  onChange={tipperLength => {
                    setTippingProperty({
                      tipperLength: Number(tipperLength),
                    });
                  }}
                  minValue={
                    selectedUnitSystem === 'imperial'
                      ? convertMillimetersToInches(TippingValues.tipperLength.minValue)
                      : TippingValues.tipperLength.minValue
                  }
                  maxValue={
                    selectedUnitSystem === 'imperial'
                      ? convertMillimetersToInches(TippingValues.tipperLength.maxValue)
                      : TippingValues.tipperLength.maxValue
                  }
                  chartsToHighlight={[
                    (useTimeUnit && ChartType.ServiceLife) as ChartType,
                    ChartType.HeatMap,
                    ChartType.Summary,
                  ]}
                  numberOfDecimals={0}
                />
              </Column>
              <Column $size={4}>
                <RightDropdownWrapper>
                  <Column $size={12}>
                    <TranslatedInputLabel translationId="stiffener" />
                  </Column>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 30,
                    }}
                  >
                    <Toggle
                      defaultChecked={tippingProperties.useStiffener}
                      onText={props.intl.formatMessage({ id: 'yes' })}
                      offText={props.intl.formatMessage({ id: 'no' })}
                      onChange={(_, useStiffener) => {
                        setTippingPropertyInstant({
                          useStiffener: useStiffener,
                        });
                      }}
                      styles={{
                        root: {
                          marginBottom: 0,
                        },
                        text: {
                          paddingTop: '1px !important',
                        },
                      }}
                    />
                  </div>
                </RightDropdownWrapper>
              </Column>
              <Column $size={2}>
                <div
                  style={{
                    marginTop: '15px',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <AbrasiveInfoButton directionalHint={DirectionalHint.bottomLeftEdge}>
                    {props.intl.formatMessage({ id: 'stiffenerHelperText' })}
                  </AbrasiveInfoButton>
                </div>
              </Column>
            </PropertiesRow>
            <PropertiesRow>
              <Column $size={6}>
                <Column $size={12}>
                  <TranslatedInputLabel translationId="unloadsPerDay" />
                </Column>

                <NumberInput
                  value={tippingProperties.unloadsPerDay}
                  suffix={''}
                  onChange={unloadsPerDay => {
                    setTippingProperty({
                      unloadsPerDay: Number(unloadsPerDay),
                    });
                  }}
                  minValue={TippingValues.unloadsPerDay.minValue}
                  maxValue={TippingValues.unloadsPerDay.maxValue}
                  chartsToHighlight={[
                    (useTimeUnit && ChartType.ServiceLife) as ChartType,
                    ChartType.HeatMap,
                    ChartType.Summary,
                  ]}
                  numberOfDecimals={1}
                />
              </Column>
              <Column $size={6}>
                <RightDropdownWrapper>
                  <Column $size={12}>
                    <TranslatedInputLabel translationId="workingDaysPerYear" />
                  </Column>

                  <NumberInput
                    value={tippingProperties.workingDaysPerYear}
                    suffix={props.intl.formatMessage({ id: 'days' })}
                    onChange={workingDaysPerYear => {
                      setTippingProperty({
                        workingDaysPerYear: Number(workingDaysPerYear),
                      });
                    }}
                    chartsToHighlight={[
                      (useTimeUnit && ChartType.ServiceLife) as ChartType,
                      ChartType.HeatMap,
                      ChartType.Summary,
                    ]}
                    minValue={TippingValues.workingDaysPerYear.minValue}
                    maxValue={TippingValues.workingDaysPerYear.maxValue}
                    numberOfDecimals={1}
                  />
                </RightDropdownWrapper>
              </Column>
            </PropertiesRow>
            <PropertiesRow>
              <Column $size={6}>
                <Column $size={12}>
                  <TranslatedInputLabel translationId="rockSize" />
                </Column>
                <RockSizeDropdown
                  rockSizeId={tippingProperties.rockSizeId}
                  onChange={rockSize => {
                    setTippingPropertyInstant({
                      rockSizeId: rockSize,
                    });
                  }}
                  selectedUnitSystem={selectedUnitSystem}
                  chartsToHighlight={[ChartType.ServiceLife, ChartType.HeatMap, ChartType.Summary]}
                  selectedChart={selectedChart}
                  calculationRoute={currentRoute}
                />
              </Column>
              <Column $size={6}>
                <RightDropdownWrapper>
                  <Column $size={12}>
                    <TranslatedInputLabel translationId="tipperType" />
                  </Column>
                  <TipperTypeDropdown
                    tipperType={tippingProperties.tipperType}
                    onChange={tipperType => {
                      setTippingPropertyInstant({
                        tipperType,
                      });
                    }}
                    chartsToHighLight={[ChartType.ServiceLife, ChartType.HeatMap, ChartType.Summary]}
                    selectedChart={selectedChart}
                    calculationRoute={currentRoute}
                  />
                </RightDropdownWrapper>
              </Column>
            </PropertiesRow>
          </>
        )}
      </ApplicationStateConsumer>
    )}
  </RouteStateConsumer>
));
