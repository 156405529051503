import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Steel, CurrentMiningSteelState, ChartType, UpgradeMiningSteelState } from '../../types';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { PropertiesRow, RightDropdownWrapper } from '../../components/containers/PropertiesRow';
import { SteelSelector } from '../../components/form/SteelSelector';
import { Column } from '../../components/containers/Column';
import { TranslatedInputLabel, InputLabel } from '../../components/labels/Labels';
import { LengthInput } from '../../components/form/LengthInput';
import { TimeUnit } from '../../state/timeUnits';
import { TimeUnitDropdown } from '../../components/form/TimeUnitDropdown';
import { NumberInput } from '../../components/form/NumberInput';
import { calculateDecimals } from '../../components/form/calculateDecimals';
import { Checkbox } from '../../components/checkbox/Checkbox';
import styled from 'styled-components';
import { setStateArrayElement } from '../../utils/MiningSteelStateHelper';
import { MiningValues } from '../../data/calculationValues';
import { convertMillimetersToInches } from '../../utils/convertUnits/convertUnits';
import { TextField } from '@fluentui/react';
import { AbrasiveInfoButton } from '../../components/abrasive/AbrasiveInfo';

const WearProtectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const MiningCurrentSteelProperties = injectIntl(
  (
    props: WrappedComponentProps & {
      currentMiningSteelsState: CurrentMiningSteelState[];
      upgradeMiningSteelsState: UpgradeMiningSteelState[];
      calculationSteels: Steel[];
      includeCustomSteel: boolean;
      currentServiceLife: number;
      serviceLifeTimeUnit: TimeUnit;
      wornOutThickness: number;
      steelTippings: number;
    },
  ) => {
    const minCurrentSteelThickness = Math.min(...props.currentMiningSteelsState.map(s => s.steelThickness));
    const minUpgradeSteelThickness = Math.min(...props.upgradeMiningSteelsState.map(s => s.steelThickness));

    const wornOutThicknessMaxValue =
      minCurrentSteelThickness < minUpgradeSteelThickness
        ? minCurrentSteelThickness - 0.001
        : minUpgradeSteelThickness - 0.001;

    return (
      <ApplicationStateConsumer>
        {({
          state: { selectedChart, selectedUnitSystem, useTimeUnit },
          setMiningProperty,
          setMiningPropertyInstant,
        }) => (
          <>
            {props.currentMiningSteelsState.map((currentSteelState, i) => {
              const steelTitle = `${props.intl.formatMessage({
                id: 'steel',
              })} ${currentSteelState.name} (${props.intl.formatMessage({
                id: 'steel' + currentSteelState.name + 'section',
              })})`;

              const decimals = calculateDecimals(undefined, selectedUnitSystem);

              return (
                <PropertiesRow key={i}>
                  <Column $size={5} style={{ flex: 1 }}>
                    <InputLabel title={steelTitle}>{steelTitle}</InputLabel>

                    <SteelSelector
                      selectedChart={selectedChart}
                      currentSteel={currentSteelState.steel}
                      setCurrentSteel={steel => {
                        setMiningPropertyInstant({
                          currentMiningSteelsState: setStateArrayElement(
                            props.currentMiningSteelsState,
                            {
                              ...currentSteelState,
                              steel: steel,
                            },
                            i,
                          ),
                        });
                      }}
                      steels={props.calculationSteels}
                      includeCustomSteel={props.includeCustomSteel}
                    />
                  </Column>
                  <Column $size={3}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="thickness" />
                      <NumberInput
                        value={currentSteelState.steelThickness}
                        onChange={thickness => {
                          setMiningProperty({
                            currentMiningSteelsState: setStateArrayElement(
                              props.currentMiningSteelsState,
                              {
                                ...currentSteelState,
                                steelThickness: thickness,
                              },
                              i,
                            ),
                          });
                        }}
                        minValue={Math.max(
                          selectedUnitSystem === 'imperial'
                            ? convertMillimetersToInches(props.wornOutThickness + 0.001)
                            : props.wornOutThickness + 0.001,
                          selectedUnitSystem === 'imperial'
                            ? convertMillimetersToInches(MiningValues.currentSteels[i].currentThickness.minValue)
                            : MiningValues.currentSteels[i].currentThickness.minValue,
                        )}
                        maxValue={Math.min(
                          selectedUnitSystem === 'imperial'
                            ? convertMillimetersToInches(MiningValues.currentSteels[i].currentThickness.maxValue)
                            : MiningValues.currentSteels[i].currentThickness.maxValue,
                          selectedUnitSystem === 'imperial'
                            ? convertMillimetersToInches(MiningValues.currentSteels[i].currentThickness.maxValue)
                            : MiningValues.currentSteels[i].currentThickness.maxValue,
                        )}
                        suffix={''}
                        chartsToHighlight={[ChartType.HeatMap, (useTimeUnit && ChartType.ServiceLife) as ChartType]}
                        numberOfDecimals={decimals}
                      />
                    </RightDropdownWrapper>
                  </Column>
                  <Column $size={4}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="wearProtection" style={{ textAlign: 'center' }} />
                      <WearProtectionWrapper>
                        <Checkbox
                          label={''}
                          disabled={false}
                          checked={currentSteelState.wearProtection}
                          onClick={() => {
                            setMiningPropertyInstant({
                              currentMiningSteelsState: setStateArrayElement(
                                props.currentMiningSteelsState,
                                {
                                  ...currentSteelState,
                                  wearProtection: !currentSteelState.wearProtection,
                                },
                                i,
                              ),
                            });
                          }}
                          styles={{
                            wrapper: {
                              paddingTop: 2,
                              marginBottom: 0,
                            },
                          }}
                        />
                        <AbrasiveInfoButton>{props.intl.formatMessage({ id: 'barsGridDeadbed' })}</AbrasiveInfoButton>
                      </WearProtectionWrapper>
                    </RightDropdownWrapper>
                  </Column>
                </PropertiesRow>
              );
            })}

            <PropertiesRow>
              <Column $size={6}>
                <TranslatedInputLabel translationId="wornoutThickness" />

                <LengthInput
                  value={props.wornOutThickness}
                  onChange={wornOutThickness => {
                    setMiningProperty({
                      wornOutThickness: wornOutThickness,
                    });
                  }}
                  chartsToHighlight={[(useTimeUnit && ChartType.ServiceLife) as ChartType, ChartType.HeatMap]}
                  selectedUnitSystem={selectedUnitSystem}
                  minValue={MiningValues.wornOutThickness.minValue}
                  maxValue={wornOutThicknessMaxValue}
                />
              </Column>
              <Column $size={6}>
                <RightDropdownWrapper>
                  <TranslatedInputLabel translationId="unloads" />
                  <NumberInput
                    value={props.steelTippings}
                    suffix={props.intl.formatMessage({
                      id: 'unloads',
                    })}
                    onChange={() => {}}
                    chartsToHighlight={[ChartType.HeatMap]}
                    numberOfDecimals={0}
                    disabled
                  />
                </RightDropdownWrapper>
              </Column>
            </PropertiesRow>

            <PropertiesRow>
              <Column $size={6}>
                <Column $size={12}>
                  <TranslatedInputLabel translationId="units" />
                </Column>
                <Column $size={12}>
                  <TimeUnitDropdown
                    value={props.serviceLifeTimeUnit}
                    onChange={serviceLifeTimeUnit => {
                      setMiningPropertyInstant({
                        serviceLifeTimeUnit: serviceLifeTimeUnit,
                      });
                    }}
                    chartsToHighlight={[ChartType.Summary]}
                  />
                </Column>
              </Column>
              <Column $size={6}>
                <RightDropdownWrapper>
                  <TranslatedInputLabel translationId="serviceLife" />
                  <TextField
                    readOnly={true}
                    disabled={true}
                    value={props.currentServiceLife.toString()}
                    suffix={props.intl.formatMessage({
                      id: props.serviceLifeTimeUnit.name.toLowerCase(),
                    })}
                    styles={{
                      suffix: {
                        textTransform: 'lowercase',
                        maxWidth: '40%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </RightDropdownWrapper>
              </Column>
            </PropertiesRow>
          </>
        )}
      </ApplicationStateConsumer>
    );
  },
);
