export const repeatStep = (start: number, stop: number, step: number): Array<number> => {
  function recursive(current: number, result: Array<number>): Array<number> {
    if (current > stop) {
      return result;
    }

    return recursive(current + step, result.concat(current));
  }

  return recursive(start, []);
};

export function minBy<T>(fn: (arg: T) => number, collection: Array<T>): number | null {
  if (collection.length === 0) {
    return null;
  }

  return collection.reduce((result: number, val) => (fn(val) < result ? fn(val) : result), Infinity);
}

export function maxBy<T>(fn: (arg: T) => number, collection: Array<T>): number | null {
  if (collection.length === 0) {
    return null;
  }

  return collection.reduce((result: number, val) => (fn(val) > result ? fn(val) : result), -Infinity);
}
