import { ApplicationStateConsumer } from '../../state/applicationState';
import { PropertiesRow, RightDropdownWrapper } from '../../components/containers/PropertiesRow';
import { Column } from '../../components/containers/Column';
import { TranslatedInputLabel } from '../../components/labels/Labels';
import { NumberInput } from '../../components/form/NumberInput';
import { ChartType } from '../../types';
import { getLengthUnitShort } from '../../utils/unitSystem/unitSystemHelpers';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RockSizeDropdown } from '../../components/form/RockSizeDropdown';
import { RouteStateConsumer } from '../../state/routing/routeState';
import { convertMillimetersToInches } from '../../utils/convertUnits/convertUnits';
import { MiningValues } from '../../data/calculationValues';

export const MiningProperties = injectIntl((props: WrappedComponentProps) => (
  <RouteStateConsumer>
    {({ state: { currentRoute } }) => (
      <ApplicationStateConsumer>
        {({
          state: { miningProperties, selectedUnitSystem, selectedChart, useTimeUnit },
          setMiningProperty,
          setMiningPropertyInstant,
        }) => (
          <>
            <PropertiesRow>
              <Column $size={6}>
                <Column $size={12}>
                  <TranslatedInputLabel translationId="tipperLength" />
                </Column>
                <Column $size={12}>
                  <NumberInput
                    value={miningProperties.tipperLength}
                    suffix={getLengthUnitShort(props.intl, selectedUnitSystem)}
                    onChange={tipperLength => {
                      setMiningProperty({
                        tipperLength: Number(tipperLength),
                      });
                    }}
                    minValue={
                      selectedUnitSystem === 'imperial'
                        ? convertMillimetersToInches(MiningValues.tipperLength.minValue)
                        : MiningValues.tipperLength.minValue
                    }
                    maxValue={
                      selectedUnitSystem === 'imperial'
                        ? convertMillimetersToInches(MiningValues.tipperLength.maxValue)
                        : MiningValues.tipperLength.maxValue
                    }
                    chartsToHighlight={[(useTimeUnit && ChartType.ServiceLife) as ChartType, ChartType.HeatMap]}
                    numberOfDecimals={0}
                  />
                </Column>
              </Column>
              <Column $size={6}>
                <RightDropdownWrapper>
                  <Column $size={12}>
                    <TranslatedInputLabel translationId="rockSize" />
                  </Column>
                  <RockSizeDropdown
                    rockSizeId={miningProperties.rockSizeId}
                    onChange={rockSize => {
                      setMiningPropertyInstant({
                        rockSizeId: rockSize,
                      });
                    }}
                    selectedUnitSystem={selectedUnitSystem}
                    chartsToHighlight={[(useTimeUnit && ChartType.ServiceLife) as ChartType, ChartType.HeatMap]}
                    selectedChart={selectedChart}
                    calculationRoute={currentRoute}
                  />
                </RightDropdownWrapper>
              </Column>
            </PropertiesRow>
            <PropertiesRow>
              <Column $size={6}>
                <Column $size={12}>
                  <TranslatedInputLabel translationId="unloadsPerHour" />
                </Column>
                <Column $size={12}>
                  <NumberInput
                    value={miningProperties.unloadsPerHour}
                    suffix={''}
                    onChange={newValue => {
                      setMiningProperty({
                        unloadsPerHour: Number(newValue),
                      });
                    }}
                    minValue={0.1}
                    maxValue={100}
                    chartsToHighlight={[(useTimeUnit && ChartType.ServiceLife) as ChartType, ChartType.HeatMap]}
                    numberOfDecimals={1}
                  />
                </Column>
              </Column>
            </PropertiesRow>
          </>
        )}
      </ApplicationStateConsumer>
    )}
  </RouteStateConsumer>
));
