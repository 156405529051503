import styled from 'styled-components';
import { redHardox } from '../constants/colors';

export const ErrorMessage = styled.p`
  margin: 10px 0 0 0;
  font-size: 11px;
  color: ${redHardox};
  font-family: 'Roboto', 'Calibri', sans-serif !important;
  font-weight: 600;
`;
