import { getColors, adjustDataPoint } from '../chartHelpers';
import * as R from 'ramda';
import { Steel } from '../../../types';
import { IntlShape } from 'react-intl';
import { neutralTertiary, neutralPrimary, white } from '../../../utils/constants/colors';
import { formatPercentageChange, roundTo } from '../../../utils/numberFormatting';
import { ChartOptions } from 'chart.js';

export const getRelativeChartData = (
  steels: Array<Steel>,
  selectedCurrentSteel: Steel | undefined,
  selectedUpgradeSteel: Steel | undefined,
  dataPoints: Array<number>,
  referencePoint: number | undefined,
  currentServiceLife: number,
) => {
  const colors = getColors(steels, selectedCurrentSteel, selectedUpgradeSteel);
  const labels = steels.map(steel => steel.name);

  return {
    labels,
    datasets: [
      {
        label: 'Relative service life',
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 0,
        hoverBackgroundColor: colors,
        hoverBorderColor: colors,
        data: steels.length > 0 ? dataPoints.map(adjustDataPoint(currentServiceLife, referencePoint)) : [],
      },
    ],
  };
};

export const getRelativeChartOptions = (
  intl: IntlShape,
  useTimeUnit: boolean,
  timeUnitId: string,
  xAxesLabel: string,
  disableAnimations: boolean,
  steels: Array<Steel>,
  selectedCurrentSteel: Steel | undefined,
  selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab',
  hideXAxisTicks: boolean,
  suggestedMaxValue: number,
  setCurrentSteel?: (steel: Steel) => void,
  setUpgradeSteel?: (steel: Steel | undefined) => void,
): ChartOptions<'bar'> => {
  return {
    ...(disableAnimations ? { animation: false } : undefined),
    indexAxis: 'y',
    datasets: {
      bar: {
        maxBarThickness: 60,
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        backgroundColor: white,
        titleColor: neutralPrimary,
        bodyColor: neutralPrimary,
        cornerRadius: 0,
        borderColor: neutralTertiary,
        borderWidth: 1,
        callbacks: {
          label: tooltipItem => {
            if (useTimeUnit) {
              const roundedValue = roundTo(+tooltipItem.raw!, 1);
              return `${roundedValue} ${intl.formatMessage(
                {
                  id: timeUnitId + '.plural',
                },
                { value: roundedValue },
              )}`;
            }

            return ` ${formatPercentageChange(parseFloat(tooltipItem.formattedValue.replace(',', '.')))}`;
          },
          labelColor: tooltipItem => {
            const colors = getColors(steels, selectedCurrentSteel, undefined);
            const labelColor = colors[tooltipItem.dataIndex];
            return {
              borderColor: labelColor,
              backgroundColor: labelColor,
            };
          },
        },
      },
    },
    layout: {
      padding: {
        top: 11,
      },
    },

    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        beginAtZero: true,
        min: 0,
        suggestedMin: 0,
        suggestedMax: suggestedMaxValue > 0 ? suggestedMaxValue : 0,
        ticks: {
          display: !hideXAxisTicks,
          callback: (value: number | string) => {
            if (useTimeUnit) {
              return roundTo(+value, 1);
            }

            return formatPercentageChange(+value);
          },
        },
        title: {
          display: true,
          text: xAxesLabel,
          font: {
            size: 13,
            style: 'normal',
          },
          color: neutralPrimary,
        },
      },
    },
    maintainAspectRatio: false,

    onClick: (e, _, chart) => {
      if (!setCurrentSteel || !setUpgradeSteel || !e.native) {
        return;
      }

      const elements = chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, false);
      if (elements === undefined || !elements[0]) {
        return;
      }
      if (elements[0] === undefined) {
        return;
      }

      const steel = R.clone(steels[elements[0].index]);

      if (selectedSteelTab === 'currentSteelTab') {
        setCurrentSteel(steel);
      } else if (selectedSteelTab === 'upgradeSteelTab') {
        if (selectedCurrentSteel === undefined || selectedCurrentSteel.hardnessHV > steel.hardnessHV) {
          setUpgradeSteel(undefined);
        } else {
          setUpgradeSteel(steel);
        }
      }
    },
  };
};
