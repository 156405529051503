import React from 'react';
import ReactDOM from 'react-dom/client';
import { WearCalc } from './view/WearCalc.tsx';
import { Chart as ChartJS, registerables } from 'chart.js';
import { loadTheme, registerIcons } from '@fluentui/react';
import { grayHardox100, grayHardox80, grayHardox60 } from './utils/constants/colors';
import './index.css';

import { IntlProvider } from 'react-intl';

import { startWorker } from './workerManager';

startWorker();

ChartJS.defaults.font.family = 'Calibri';
ChartJS.register(...registerables);

loadTheme({
  palette: {
    themePrimary: grayHardox100,
    themeDark: grayHardox80,
    themeDarkAlt: grayHardox60,
  },
});

registerIcons({
  icons: {
    ChevronDown: <i className="material-icons">expand_more</i>,
    PageRight: (
      <i
        className="material-icons"
        style={{
          border: '1px solid white',
          borderRadius: '50%',
          fontSize: 13,
          height: 13,
        }}
      >
        navigate_next
      </i>
    ),
    Download: <i className="material-icons">insert_drive_file</i>,
    Home: <i className="material-icons">home</i>,
    Settings: <i className="material-icons">settings</i>,
    Delete: <i className="material-icons">delete</i>,
    AddCircle: <i className="material-icons">add_circle</i>,
    Add: <i className="material-icons">add</i>,
    Info: <i className="material-icons">info</i>,
    Undo: <i className="material-icons">undo</i>,
    CircleAdditionSolid: <i className="material-icons">add_circle</i>,
    Search: <i className="material-icons">search</i>,
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <IntlProvider locale="sv" defaultLocale="sv">
      <WearCalc />
    </IntlProvider>
  </React.StrictMode>,
);
