import React, { PropsWithChildren, createContext } from 'react';
import { Middleware } from '../applicationState';
import { Route } from '../../types';

export type RouteState = {
  routes: Array<Route>;
  currentRoute: Route;
};
export type RouteStateContext = {
  state: RouteState;
  previousRoute: Route | undefined;
  setRoute: (newRoute: Route) => void;
};

const routeState = createContext<RouteStateContext>(undefined as unknown as RouteStateContext);

type RouteStateProviderProps = {
  initialState: RouteState;
  middlewares: Array<Middleware<RouteState>>;
};

declare global {
  interface Window {
    routeState: RouteStateProvider;
  }
}

export class RouteStateProvider extends React.Component<
  PropsWithChildren<RouteStateProviderProps>,
  {
    context: RouteStateContext;
  }
> {
  previousRoute: Route | undefined = undefined;
  contextFunctions = {
    setRoute: (newRoute: Route) => this.setRouteState({ currentRoute: newRoute }),
  };

  constructor(props: RouteStateProviderProps) {
    super(props);
    const state = this.props.middlewares.reduce(
      (state, middleware) => middleware(state, { isInitialLoad: true, hasCrashed: false }),
      props.initialState,
    );

    this.state = {
      context: {
        ...this.contextFunctions,
        state,
        previousRoute: undefined,
      },
    };

    if (import.meta.env.DEV) {
      /**
       * Very primitive replacement of Redux Devtools.
       * You can access the state and contextFunctions via
       * the global routeState variable
       */
      window.routeState = this;
    }
  }

  setRouteState = (stateUpdate: Partial<RouteState>) => {
    const oldState = this.state.context.state;
    const newState = this.props.middlewares.reduce(
      (state, middleware) => middleware(state, { isInitialLoad: false, hasCrashed: false }),
      {
        ...oldState,
        ...stateUpdate,
      },
    );

    this.setState({
      context: {
        ...this.contextFunctions,
        state: newState,
        previousRoute: oldState.currentRoute,
      },
    });
  };

  render() {
    return <routeState.Provider value={this.state.context}>{this.props.children}</routeState.Provider>;
  }
}

export const RouteStateConsumer = routeState.Consumer;
