import React from 'react';
import * as R from 'ramda';

import { wearToColorLegend } from './HeatMap';

function drawLabels(ctx: CanvasRenderingContext2D, height: number) {
  ctx.fillStyle = '#000000';
  ctx.font = 'italic 10px';
  ctx.fillText('100%', 12, 8);
  ctx.fillText('50%', 12, 5 + Math.round(height / 2));
  ctx.fillText('0%', 12, height - 2);
}

const renderHeatMap = (canvas: HTMLCanvasElement) => {
  //Creates an array from 0 to 1 with steps of 0.1.
  const wearValueArray = R.reverse(R.range(0, 11).map(x => x / 10));

  canvas.width = canvas.getBoundingClientRect().width;
  canvas.height = canvas.getBoundingClientRect().height;

  const colorMap = wearValueArray.map(wear => {
    return wearToColorLegend(wear);
  });

  const { width, height } = canvas;
  const ctx = canvas.getContext('2d')!;
  ctx.clearRect(0, 0, width, height);

  const ySize = height / colorMap.length;
  const xSize = width / 4;

  colorMap.forEach((color, xIndex) => {
    const y = xIndex * ySize;

    ctx.fillStyle = color;
    ctx.fillRect(0, y, xSize, ySize);
  });

  drawLabels(ctx, height);
};

export class HeatMapLegend extends React.Component<{
  heightInPixels: number;
}> {
  canvas: HTMLCanvasElement | null = null;
  drawOnNextRender = false;

  renderHeatMap() {
    if (this.canvas) {
      this.drawOnNextRender = false;
      renderHeatMap(this.canvas);
    } else {
      this.drawOnNextRender = true;
    }
  }

  componentDidMount() {
    this.renderHeatMap();
  }

  componentDidUpdate() {
    this.renderHeatMap();
  }

  render() {
    return (
      <canvas
        ref={e => {
          this.canvas = e;

          if (this.drawOnNextRender) {
            this.renderHeatMap();
          }
        }}
        style={{ height: this.props.heightInPixels, width: 40 }}
      />
    );
  }
}
