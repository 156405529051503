import { Component, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Callout, DirectionalHint } from '@fluentui/react';
import { InfoButton } from '../buttons/InfoButton';

const CalloutBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
`;

export const InfoImage = styled.img`
  height: 200px;
`;

type AbrasiveInfoButtonProps = {
  directionalHint?: DirectionalHint;
};

type AbrasiveInfoButtonState = {
  showCallout: boolean;
};

export class AbrasiveInfoButton extends Component<PropsWithChildren<AbrasiveInfoButtonProps>, AbrasiveInfoButtonState> {
  // Init

  infoButtonWrapper: HTMLDivElement | null = null;

  constructor(props: AbrasiveInfoButtonProps) {
    super(props);

    this.state = {
      showCallout: false,
    };
  }

  // Render

  render() {
    const { showCallout } = this.state;

    const { directionalHint } = this.props;
    return (
      <>
        <div
          ref={element => {
            this.infoButtonWrapper = element;
          }}
        >
          <InfoButton
            onClick={() => {
              this.setState({ showCallout: !showCallout });
            }}
            style={{ height: 22, fontSize: 22 }}
          />
        </div>
        {showCallout && (
          <Callout
            gapSpace={5}
            target={this.infoButtonWrapper}
            isBeakVisible={false}
            directionalHint={directionalHint || DirectionalHint.bottomRightEdge}
            onDismiss={() => {
              this.setState({ showCallout: false });
            }}
          >
            {/* TODO: add information about velocity, angle, sharpness, size etc with illustatrions. */}
            <CalloutBody>
              {this.props.children}

              {/*<Placeholder>Placeholder</Placeholder>*/}
            </CalloutBody>
          </Callout>
        )}
      </>
    );
  }
}
