import styled from 'styled-components';
import { UnitSystemOption, CurrentSteelState, UpgradeSteelState } from '../../types';
import { formatPercentageChange } from '../../utils/numberFormatting';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Fade } from '../fade/Fade';
import { ssabGray, wearCalcGreen, wearCalcError, grayHardox100, grayHardox80 } from '../../utils/constants/colors';
import { Row } from '../containers/Row';
import { Column } from '../containers/Column';
import { fontSizeL, fontSizeXL, fontSizeSU } from '../../utils/constants/typography';
import { translateSteel } from '../../utils/steelHelpers';
import { duroxiteThicknessChange, isDuroxite } from '../../utils/duroxite';

const SummaryContainer = styled.div`
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  padding: 40px;
`;

const RowCenter = styled(Row)`
  display: flex;
  align-items: center;
`;

const FullHeightColumn = styled(Column)`
  height: 100%;
`;

const RowLabel = styled.div<{ $borderBottom?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${grayHardox100};
  border-right: 1px solid ${ssabGray};
  border-bottom: ${props => (props.$borderBottom ? `1px solid ${ssabGray}` : '1px solid white')};
  font-size: ${fontSizeL};
  text-align: center;
  letter-spacing: 0.5px;
`;

export const Summary = injectIntl(
  (
    props: WrappedComponentProps & {
      currentSteelState: CurrentSteelState;
      upgradeSteelState: UpgradeSteelState;
      selectedUnitSystem: UnitSystemOption;
    },
  ) => {
    const { currentSteelState, upgradeSteelState, selectedUnitSystem, intl } = props;
    const upgradeSteelSelected = upgradeSteelState.upgradeSteel !== undefined;

    /*
    Set steel names to selected language. Otherwise it is being displayed incorrectly if
    selecting a current steel and then changeing language.
    */
    const translatedCurrentSteelState = {
      ...currentSteelState,
      currentSteel: translateSteel(intl, currentSteelState.currentSteel),
    };

    const translatedUpgradeSteelState = {
      ...upgradeSteelState,
      upgradeSteel: upgradeSteelState.upgradeSteel ? translateSteel(intl, upgradeSteelState.upgradeSteel) : undefined,
    };
    return (
      <>
        <Fade in={!upgradeSteelSelected} style={{ width: '100%', height: '100%' }}>
          <SummaryContainer>
            <SelectAnUpgradeSteel />
          </SummaryContainer>
        </Fade>
        <Fade in={upgradeSteelSelected} style={{ width: '100%', height: '100%' }}>
          <SummaryContainer>
            <SelectedSteelNames
              currentSteelState={translatedCurrentSteelState}
              upgradeSteelState={translatedUpgradeSteelState}
            />
            <ServiceLifeRow
              currentSteelState={translatedCurrentSteelState}
              upgradeSteelState={translatedUpgradeSteelState}
            />
            <ThicknessRow
              currentSteelState={translatedCurrentSteelState}
              upgradeSteelState={translatedUpgradeSteelState}
              selectedUnitSystem={selectedUnitSystem}
            />
          </SummaryContainer>
        </Fade>
      </>
    );
  },
);

const SelectUpgradeSteelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SelectUpgradeSteelText = styled.div`
  font-size: ${fontSizeSU};
  color: ${grayHardox80};
`;

const SelectAnUpgradeSteel = () => (
  <SelectUpgradeSteelContainer>
    <SelectUpgradeSteelText>
      <FormattedMessage id={'selectAnUpgradeSteel'} />
    </SelectUpgradeSteelText>
  </SelectUpgradeSteelContainer>
);

const SelectedSteelNamesText = styled.div`
  font-size: ${fontSizeXL};
  letter-spacing: 0;
  color: ${grayHardox100};
  text-align: center;
`;

type SelectedSteelNamesProps = {
  currentSteelState: CurrentSteelState;
  upgradeSteelState: UpgradeSteelState;
};

const SelectedSteelNames = ({ currentSteelState, upgradeSteelState }: SelectedSteelNamesProps) => (
  <RowCenter style={{ height: '25%' }}>
    <Column $size={12}>
      <SelectedSteelNamesText>
        <FormattedMessage id={'materialUpgradeFrom'} /> <strong>{currentSteelState.currentSteel.name}</strong>{' '}
        <FormattedMessage id={'to'} />{' '}
        <strong>{upgradeSteelState.upgradeSteel && upgradeSteelState.upgradeSteel.name}</strong>
      </SelectedSteelNamesText>
    </Column>
  </RowCenter>
);

type ValueCellProps = {
  value: string;
  label: string;
  valueColor: string;
  borderBottom?: boolean;
  hidden?: boolean;
};

const ValueCell = ({ value, label, valueColor, borderBottom, hidden = false }: ValueCellProps) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      borderBottom: borderBottom === true ? `1px solid ${ssabGray}` : '1px solid white',
    }}
  >
    <div style={{ height: '30%' }} />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40%',
      }}
    >
      <div
        style={{
          fontSize: 56,
          transition: 'color 200ms linear 0ms',
          color: valueColor,
          marginBottom: -2,
        }}
      >
        <Fade in={hidden === false}>{value}</Fade>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '30%',
        color: grayHardox80,
        textTransform: 'uppercase',
        fontSize: 15,
      }}
    >
      <Fade in={hidden === false}>{label}</Fade>
    </div>
  </div>
);

type ServiceLifeRowProps = {
  currentSteelState: CurrentSteelState;
  upgradeSteelState: UpgradeSteelState;
};

const ServiceLifeRow = injectIntl(
  ({ currentSteelState, upgradeSteelState, intl }: ServiceLifeRowProps & WrappedComponentProps) => {
    const serviceLifePercentageChange = formatPercentageChange(
      upgradeSteelState.upgradeServiceLife / currentSteelState.currentServiceLife,
    );

    const serviceLifeIncreased = upgradeSteelState.upgradeServiceLife - currentSteelState.currentServiceLife >= 0;

    return (
      <RowCenter style={{ height: '37.5%' }}>
        <FullHeightColumn $size={(1 * 12) / 5} $sizeSmall={(1 * 12) / 4}>
          <RowLabel $borderBottom>
            <div style={{ padding: 20 }}>
              <FormattedMessage id={'estimatedServiceLife'} />
            </div>
          </RowLabel>
        </FullHeightColumn>
        <FullHeightColumn $size={(4 * 12) / 5} $sizeSmall={(3 * 12) / 4}>
          <Row style={{ height: '100%' }}>
            <FullHeightColumn $size={6}>
              <ValueCell
                value={String(upgradeSteelState.upgradeServiceLife)}
                valueColor={wearCalcGreen}
                label={intl.formatMessage({
                  id: currentSteelState.serviceLifeTimeUnit.id,
                })}
                borderBottom
              />
            </FullHeightColumn>
            <FullHeightColumn $size={6}>
              <ValueCell
                value={String(serviceLifePercentageChange)}
                valueColor={serviceLifeIncreased ? wearCalcGreen : wearCalcError}
                label={intl.formatMessage({
                  id: serviceLifeIncreased ? 'increasedServiceLife' : 'decreasedServiceLife',
                })}
                borderBottom
                hidden={upgradeSteelState.upgradeServiceLife <= 0}
              />
            </FullHeightColumn>
          </Row>
        </FullHeightColumn>
      </RowCenter>
    );
  },
);

type ThicknessRowProps = {
  currentSteelState: CurrentSteelState;
  upgradeSteelState: UpgradeSteelState;
  selectedUnitSystem: UnitSystemOption;
};

const ThicknessRow = injectIntl(
  ({ currentSteelState, upgradeSteelState, selectedUnitSystem, intl }: ThicknessRowProps & WrappedComponentProps) => {
    const thicknessPercentageChange = formatPercentageChange(
      isDuroxite(upgradeSteelState.upgradeSteel)
        ? duroxiteThicknessChange(
            upgradeSteelState.upgradeBaseThickness,
            upgradeSteelState.upgradeThickness,
            currentSteelState.currentThickness,
          )
        : upgradeSteelState.upgradeThickness / currentSteelState.currentThickness,
    );

    const thicknessDecreased = isDuroxite(upgradeSteelState.upgradeSteel)
      ? currentSteelState.currentThickness -
          (upgradeSteelState.upgradeThickness + upgradeSteelState.upgradeBaseThickness!) >=
        0
      : currentSteelState.currentThickness - upgradeSteelState.upgradeThickness >= 0;

    const correspondingThickness =
      isDuroxite(upgradeSteelState.upgradeSteel) && upgradeSteelState.upgradeBaseThickness
        ? upgradeSteelState.upgradeBaseThickness + upgradeSteelState.upgradeThickness
        : upgradeSteelState.upgradeThickness;

    return (
      <RowCenter style={{ height: '37.5%' }}>
        <FullHeightColumn $size={(1 * 12) / 5} $sizeSmall={(1 * 12) / 4}>
          <RowLabel>
            <div style={{ padding: 20 }}>
              <FormattedMessage id={'correspondingThickness'} />
            </div>
          </RowLabel>
        </FullHeightColumn>
        <FullHeightColumn $size={(4 * 12) / 5} $sizeSmall={(3 * 12) / 4}>
          <Row style={{ height: '100%' }}>
            <FullHeightColumn $size={6}>
              <ValueCell
                value={String(correspondingThickness)}
                valueColor={wearCalcGreen}
                label={intl.formatMessage({
                  id: selectedUnitSystem === 'imperial' ? 'inches' : 'millimeters',
                })}
              />
            </FullHeightColumn>
            <FullHeightColumn $size={6}>
              <ValueCell
                value={String(thicknessPercentageChange)}
                valueColor={thicknessDecreased ? wearCalcGreen : wearCalcError}
                label={intl.formatMessage({
                  id: thicknessDecreased ? 'decreasedWeight' : 'increasedWeight',
                })}
                hidden={upgradeSteelState.upgradeThickness <= 0}
              />
            </FullHeightColumn>
          </Row>
        </FullHeightColumn>
      </RowCenter>
    );
  },
);
