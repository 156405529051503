import styled from 'styled-components';
import { ssabWhite } from '../../utils/constants/colors';
import { ReactNode } from 'react';
import { CardHeader } from './CardHeader';
import { FormattedMessage } from 'react-intl';
import { Pivot, PivotItem } from '@fluentui/react';
import { injectIntl, IntlShape } from 'react-intl';
import { smallSizeMaxWidth } from '../../utils/constants/layout';

export const Card = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${ssabWhite};

  @media (max-width: ${smallSizeMaxWidth}) {
    margin-right: 0px;
  }
`;

export const FlexPivot = styled(Pivot)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  & > div[role='tabpanel'],
  & > div[role='tabpanel'] > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  padding-right: 20px;
  @media (max-width: ${smallSizeMaxWidth}) {
    padding-right: 0px;
  }
`;

export const PropertiesCardWrapper = styled(Card)`
  padding-top: 0;
  flex: 1 1 auto;
`;

export const SteelCard = injectIntl(
  ({
    intl,
    calculationProperties,
    upgradeSteelProperties,
    setSelectedSteelTab,
    selectedSteelTab,
  }: {
    intl: IntlShape;
    calculationProperties: ReactNode;
    upgradeSteelProperties: ReactNode;
    setSelectedSteelTab: (selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab') => void;
    selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab';
  }) => (
    <FlexPivot
      linkFormat={'tabs'}
      defaultSelectedKey={selectedSteelTab}
      className={'steel-pivot'}
      onLinkClick={pivotItem => {
        const clickedKey = pivotItem!.props.itemKey;

        if (clickedKey === 'currentSteelTab') {
          setSelectedSteelTab('currentSteelTab');
        } else if (clickedKey === 'upgradeSteelTab') {
          setSelectedSteelTab('upgradeSteelTab');
        }
      }}
      styles={{
        linkIsSelected: { width: '50%' },
        link: {
          width: '50%',
        },
        root: {
          marginRight: -1,
        },
        text: {
          fontSize: '12px',
          color: 'black',
          letterSpacing: '0.5',
          textTransform: 'uppercase',
        },
        linkContent: {
          margin: '0 auto',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      }}
    >
      <PivotItem itemKey={'currentSteelTab'} headerText={intl.formatMessage({ id: 'currentSteel' })}>
        <PropertiesCardWrapper>{calculationProperties}</PropertiesCardWrapper>
      </PivotItem>
      <PivotItem itemKey={'upgradeSteelTab'} headerText={intl.formatMessage({ id: 'upgradeSteel' })}>
        <PropertiesCardWrapper>{upgradeSteelProperties}</PropertiesCardWrapper>
      </PivotItem>
    </FlexPivot>
  ),
);

const AbrasiveCardWrapper = styled(Card)`
  padding-bottom: 30px;

  margin-right: 20px;
  @media (max-width: ${smallSizeMaxWidth}) {
    margin-right: 0;
    margin-top: 0;
  }
`;

const AbrasiveCardBodyWrapper = styled.div`
  margin-top: -10px;
`;

export const AbrasiveCard = ({ children }: { children: ReactNode }) => (
  <AbrasiveCardWrapper>
    <CardHeader>
      <FormattedMessage id="abrasive" />
    </CardHeader>
    <AbrasiveCardBodyWrapper>{children}</AbrasiveCardBodyWrapper>
  </AbrasiveCardWrapper>
);

export const PropertiesCard = ({ calculationProperties }: { calculationProperties: ReactNode }) => (
  <AbrasiveCardWrapper>
    <CardHeader>
      <FormattedMessage id="properties" />
    </CardHeader>
    <AbrasiveCardBodyWrapper>{calculationProperties}</AbrasiveCardBodyWrapper>
  </AbrasiveCardWrapper>
);
