import { grayHardox80, grayHardox100 } from '../../utils/constants/colors';
import { IconButton } from '@fluentui/react';

// Remove button

type InfoButtonProps = {
  color?: string;
  colorHover?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

export const InfoButton = ({ onClick, color, colorHover, style }: InfoButtonProps) => {
  return (
    <IconButton
      style={style}
      iconProps={{ iconName: 'Info' }}
      ariaLabel="Info"
      className={'material-icon-large'}
      styles={{
        icon: {
          color: color ? color : grayHardox100,
          fontSize: 22,
        },
        iconHovered: { color: colorHover ? colorHover : grayHardox80 },
        rootHovered: {
          backgroundColor: 'transparent',
        },
        rootPressed: {
          backgroundColor: 'transparent',
        },
        rootFocused: {
          backgroundColor: 'transparent',
        },
      }}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
      tabIndex={-1}
    />
  );
};
