import styled from 'styled-components';

export const ChartWrapper = styled.div<{
  $paddingTop?: number;
  $minHeight?: number;
}>`
  flex: 1;
  padding-top: ${props => (props.$paddingTop ? props.$paddingTop : 10)}px;
  padding-bottom: 10px;
  min-height: ${props => props.$minHeight || 400}px;

  cursor: pointer;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
`;
