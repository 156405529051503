import { WrappedComponentProps, injectIntl } from 'react-intl';
import { getLengthUnitShort } from '../../utils/unitSystem/unitSystemHelpers';
import { ChartType, Route, UnitSystemOption } from '../../types';
import { NumberInput } from './NumberInput';
import { calculateDecimals } from './calculateDecimals';

type LengthInputProps = WrappedComponentProps & {
  value: number;
  onChange: (value: number) => void;
  chartsToHighlight: Array<ChartType>;
  routesToHighlight?: Array<Route>;
  numberOfDecimals?: number;
  minValue?: number;
  maxValue?: number;
  selectedUnitSystem: UnitSystemOption;
  disabled?: boolean;
};

export const LengthInput = injectIntl(
  ({
    value,
    onChange,
    intl,
    chartsToHighlight,
    routesToHighlight,
    minValue = 0,
    maxValue = Number.MAX_SAFE_INTEGER,
    numberOfDecimals,
    selectedUnitSystem,
    disabled,
  }: LengthInputProps) => {
    const decimals = calculateDecimals(numberOfDecimals, selectedUnitSystem);

    const lengthSuffix = getLengthUnitShort(intl, selectedUnitSystem).toLowerCase();

    return (
      <NumberInput
        value={value}
        suffix={lengthSuffix}
        onChange={onChange}
        chartsToHighlight={chartsToHighlight}
        routesToHighlight={routesToHighlight}
        numberOfDecimals={decimals}
        minValue={minValue}
        maxValue={maxValue}
        disabled={!!disabled}
      />
    );
  },
);
