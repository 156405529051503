import { ssabPrimary, ssabPrimaryDark, redHardox } from '../constants/colors';
import { loadTheme } from '@fluentui/react';

// Primary theme

export const themePrimary = loadTheme({
  palette: {
    themePrimary: ssabPrimary,
    themeDark: ssabPrimaryDark,
    themeDarkAlt: ssabPrimaryDark,
  },
});

// Hardox theme

export const themeHardox = loadTheme({
  palette: {
    themePrimary: redHardox,
    themeDark: redHardox,
    themeDarkAlt: redHardox,
  },
});
