import { Dropdown } from '@fluentui/react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { TimeUnit } from '../../state/timeUnits';
import { getHighlighStyles } from '../../utils/formHighlightHelper';
import { ChartType } from '../../types';

export const TimeUnitDropdown = injectIntl(
  ({
    intl,
    value,
    onChange,
    chartsToHighlight,
  }: WrappedComponentProps & {
    value: TimeUnit;
    onChange: (value: TimeUnit) => void;
    chartsToHighlight: Array<ChartType>;
  }) => (
    <ApplicationStateConsumer>
      {({ state: { timeUnits, selectedChart } }) => (
        <Dropdown
          selectedKey={value.name}
          onChange={(_event, option) => onChange(timeUnits.find(timeUnit => timeUnit.name === option!.key)!)}
          options={timeUnits.map(timeUnit => ({
            key: timeUnit.name,
            text: intl.formatMessage({
              id: timeUnit.name.toLowerCase(),
            }),
          }))}
          styles={{
            title: getHighlighStyles(selectedChart, chartsToHighlight.filter(Boolean)),
          }}
        />
      )}
    </ApplicationStateConsumer>
  ),
);
