import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Steel, UpgradeMiningSteelState, CurrentMiningSteelState, ChartType } from '../../types';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { PropertiesRow, RightDropdownWrapper } from '../../components/containers/PropertiesRow';
import { SteelSelector } from '../../components/form/SteelSelector';
import { Column } from '../../components/containers/Column';
import { TranslatedInputLabel, InputLabel } from '../../components/labels/Labels';
import { NumberInput } from '../../components/form/NumberInput';
import { TimeUnit } from '../../state/timeUnits';
import { getHarderSteels } from '../../utils/steelHelpers';
import { setStateArrayElement } from '../../utils/MiningSteelStateHelper';
import { MiningValues } from '../../data/calculationValues';
import { convertMillimetersToInches } from '../../utils/convertUnits/convertUnits';
import { TextField } from '@fluentui/react';
import { calculateDecimals } from '../../components/form/calculateDecimals';
import { TimeUnitDropdown } from '../../components/form/TimeUnitDropdown';
import { LengthInput } from '../../components/form/LengthInput';

export const MiningUpgradeSteelProperties = injectIntl(
  (
    props: WrappedComponentProps & {
      upgradeMiningSteelsState: UpgradeMiningSteelState[];
      currentMiningSteelsState: CurrentMiningSteelState[];
      calculationSteels: Steel[];
      includeCustomSteel: boolean;
      upgradeServiceLife: number;
      serviceLifeTimeUnit: TimeUnit;
      wornOutThickness: number;
      steelTippings: number;
    },
  ) => {
    const minCurrentSteelThickness = Math.min(...props.currentMiningSteelsState.map(s => s.steelThickness));
    const minUpgradeSteelThickness = Math.min(...props.upgradeMiningSteelsState.map(s => s.steelThickness));

    const wornOutThicknessMaxValue =
      minCurrentSteelThickness < minUpgradeSteelThickness
        ? minCurrentSteelThickness - 0.001
        : minUpgradeSteelThickness - 0.001;
    return (
      <ApplicationStateConsumer>
        {({
          state: { selectedChart, selectedUnitSystem, useTimeUnit },
          setMiningProperty,
          setMiningPropertyInstant,
        }) => (
          <>
            {props.upgradeMiningSteelsState.map((upgradeSteelState, i) => {
              const steelTitle = `${props.intl.formatMessage({
                id: 'steel',
              })} ${upgradeSteelState.name} (${props.intl.formatMessage({
                id: 'steel' + upgradeSteelState.name + 'section',
              })})`;

              const decimals = calculateDecimals(undefined, selectedUnitSystem);

              return (
                <PropertiesRow key={i}>
                  <Column $size={7} style={{ flex: 1 }}>
                    <InputLabel title={steelTitle}>{steelTitle}</InputLabel>

                    <SteelSelector
                      selectedChart={selectedChart}
                      currentSteel={upgradeSteelState.steel}
                      setCurrentSteel={steel => {
                        setMiningPropertyInstant({
                          upgradeMiningSteelsState: setStateArrayElement(
                            props.upgradeMiningSteelsState,
                            { ...upgradeSteelState, steel },
                            i,
                          ),
                        });
                      }}
                      steels={getHarderSteels(props.calculationSteels, props.currentMiningSteelsState[i].steel!)}
                      includeCustomSteel={props.includeCustomSteel}
                    />
                  </Column>
                  <Column $size={5}>
                    <RightDropdownWrapper style={{ width: 90 }}>
                      <TranslatedInputLabel translationId="thickness" />
                      <NumberInput
                        value={upgradeSteelState.steelThickness}
                        onChange={thickness => {
                          setMiningProperty({
                            upgradeMiningSteelsState: setStateArrayElement(
                              props.upgradeMiningSteelsState,
                              {
                                ...upgradeSteelState,
                                steelThickness: thickness,
                              },
                              i,
                            ),
                          });
                        }}
                        suffix={''}
                        minValue={props.wornOutThickness + 0.001}
                        maxValue={
                          selectedUnitSystem === 'imperial'
                            ? convertMillimetersToInches(MiningValues.upgradeSteels[i].upgradeThickness.maxValue)
                            : MiningValues.upgradeSteels[i].upgradeThickness.maxValue
                        }
                        chartsToHighlight={[ChartType.HeatMap]}
                        numberOfDecimals={decimals}
                      />
                    </RightDropdownWrapper>
                  </Column>
                </PropertiesRow>
              );
            })}

            {props.upgradeServiceLife > 0 && (
              <>
                <PropertiesRow>
                  <Column $size={6}>
                    <TranslatedInputLabel translationId="wornoutThickness" />
                    <LengthInput
                      value={props.wornOutThickness}
                      onChange={wornOutThickness => {
                        setMiningProperty({
                          wornOutThickness: wornOutThickness,
                        });
                      }}
                      chartsToHighlight={[(useTimeUnit && ChartType.ServiceLife) as ChartType, ChartType.HeatMap]}
                      selectedUnitSystem={selectedUnitSystem}
                      minValue={MiningValues.wornOutThickness.minValue}
                      maxValue={wornOutThicknessMaxValue}
                      disabled={true}
                    />
                  </Column>
                  <Column $size={6}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="unloads" />
                      <NumberInput
                        value={props.steelTippings}
                        suffix={props.intl.formatMessage({
                          id: 'unloads',
                        })}
                        onChange={() => {}}
                        chartsToHighlight={[ChartType.HeatMap]}
                        numberOfDecimals={0}
                        disabled
                      />
                    </RightDropdownWrapper>
                  </Column>
                </PropertiesRow>
                <PropertiesRow>
                  <Column $size={6}>
                    <Column $size={12}>
                      <TranslatedInputLabel translationId="units" />
                    </Column>
                    <Column $size={12}>
                      <TimeUnitDropdown
                        value={props.serviceLifeTimeUnit}
                        onChange={serviceLifeTimeUnit => {
                          setMiningPropertyInstant({
                            serviceLifeTimeUnit: serviceLifeTimeUnit,
                          });
                        }}
                        chartsToHighlight={[ChartType.Summary]}
                      />
                    </Column>
                  </Column>
                  <Column $size={6}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="serviceLife" />
                      <TextField
                        readOnly={true}
                        disabled={true}
                        value={props.upgradeServiceLife.toString()}
                        suffix={props.intl.formatMessage({
                          id: props.serviceLifeTimeUnit.name.toLowerCase(),
                        })}
                        styles={{
                          suffix: {
                            textTransform: 'lowercase',
                            maxWidth: '40%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          },
                        }}
                      />
                    </RightDropdownWrapper>
                  </Column>
                </PropertiesRow>
              </>
            )}
          </>
        )}
      </ApplicationStateConsumer>
    );
  },
);
