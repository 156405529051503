import React, { createContext, ErrorInfo, ReactNode } from 'react';
import { mergeDeepRight } from 'ramda';
import { Steel, Abrasive, AbrasiveComponentWithoutFraction, UnitSystemOption, ChartType, DeepPartial } from '../types';
import { TimeUnit } from './timeUnits';
import {
  CalculationModelStateUpdater,
  TippingPropertiesState,
  ErosionPropertiesState,
  ImpactPropertiesState,
  SlidingPropertiesState,
  createNextErosionPropertiesState,
  createNextImpactPropertiesState,
  createNextSlidingPropertiesState,
  createNextTippingPropertiesState,
  CalculationModelState,
} from './calculationModels';
import { DocxSettings, DocxSettingsProperty, createNextDocxSettings } from './docxSettings';
import { MiningPropertiesState, createNextMiningPropertiesState } from './miningProperties';
import { getClearedCalculationState } from './initialState';

export type Middleware<S> = (state: S, meta: { isInitialLoad: boolean; hasCrashed: boolean }) => S;

export type ApplicationState = {
  version: string;
  abrasives: Array<Abrasive>;
  abrasiveComponents: Array<AbrasiveComponentWithoutFraction>;

  selectedSlidingAbrasive?: Abrasive;
  selectedImpactAbrasive?: Abrasive;
  selectedErosionAbrasive?: Abrasive;
  selectedTippingAbrasive?: Abrasive;
  selectedMiningAbrasive?: Abrasive;

  steels: Array<Steel>;
  slidingSteels: Array<Steel>;
  impactSteels: Array<Steel>;
  erosionSteels: Array<Steel>;
  timeUnits: Array<TimeUnit>;
  useTimeUnit: boolean;
  unitSystems: Array<UnitSystemOption>;
  selectedUnitSystem: UnitSystemOption;
  slidingProperties: SlidingPropertiesState;
  impactProperties: ImpactPropertiesState;
  erosionProperties: ErosionPropertiesState;
  tippingProperties: TippingPropertiesState;
  miningProperties: MiningPropertiesState;
  selectedChart: ChartType;
  docxSettings: DocxSettings;
  selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab';
};

declare global {
  interface Window {
    applicationState: ApplicationStateProvider;
  }
}

export type ApplicationStateContext = {
  state: ApplicationState;
  getState: () => ApplicationState;
  clearState: () => void;
  setSlidingAbrasive: (abrasive: Abrasive) => void;
  setImpactAbrasive: (abrasive: Abrasive) => void;
  setErosionAbrasive: (abrasive: Abrasive) => void;
  setTippingAbrasive: (abrasive: Abrasive) => void;
  setMiningAbrasive: (abrasive: Abrasive) => void;

  setSlidingSteels: (steels: Array<Steel>) => void;
  setImpactSteels: (steels: Array<Steel>) => void;
  setErosionSteels: (steels: Array<Steel>) => void;

  setSlidingProperty: CalculationModelStateUpdater<SlidingPropertiesState>;
  setImpactProperty: CalculationModelStateUpdater<ImpactPropertiesState>;
  setErosionProperty: CalculationModelStateUpdater<ErosionPropertiesState>;
  setTippingProperty: CalculationModelStateUpdater<TippingPropertiesState>;
  setMiningProperty: CalculationModelStateUpdater<MiningPropertiesState>;

  setSlidingPropertyInstant: CalculationModelStateUpdater<SlidingPropertiesState>;
  setImpactPropertyInstant: CalculationModelStateUpdater<ImpactPropertiesState>;
  setErosionPropertyInstant: CalculationModelStateUpdater<ErosionPropertiesState>;
  setTippingPropertyInstant: CalculationModelStateUpdater<TippingPropertiesState>;
  setMiningPropertyInstant: CalculationModelStateUpdater<MiningPropertiesState>;

  setSelectedChart: (selectedChart: ChartType) => void;
  setUseTimeUnit: (useTimeUnit: boolean) => void;
  setUnitSystem: (unitSystem: UnitSystemOption) => void;
  setDocxSettingsProperty: (property: DocxSettingsProperty) => void;
  setSelectedSteelTab: (selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab') => void;
};

export type ApplicationStateSubscriber = (context: ApplicationStateContext) => void;
export type ApplicationStateUnsubscribe = () => void;
export type ApplicationStateSubscribe = (cb: ApplicationStateSubscriber) => ApplicationStateUnsubscribe;

const applicationState = createContext<ApplicationStateContext>(undefined as unknown as ApplicationStateContext);

type ApplicationStateProviderProps = {
  initialState: ApplicationState;
  middlewares: Array<Middleware<ApplicationState>>;
  children: (subscribe: ApplicationStateSubscribe) => ReactNode;
};

export class ApplicationStateProvider extends React.Component<
  ApplicationStateProviderProps,
  {
    context: ApplicationStateContext;
  }
> {
  /**
   * NOTE: contextFunctions must NOT be a function as that causes
   * these funcitons to be recreated on update, preventing sCU
   * optimizations.
   */
  contextFunctions: Pick<ApplicationStateContext, Exclude<keyof ApplicationStateContext, 'state'>> = {
    getState: () => this.state.context.state,
    clearState: () => {
      this.setApplicationState(getClearedCalculationState());
      location.reload();
    },
    setSlidingAbrasive: (abrasive: Abrasive) => {
      this.setApplicationState({ selectedSlidingAbrasive: abrasive });
      // Make sure all upgrade steel properties are recalculated
      this.state.context.setSlidingPropertyInstant({});
    },

    setImpactAbrasive: (abrasive: Abrasive) => {
      this.setApplicationState({ selectedImpactAbrasive: abrasive });
      // Make sure all upgrade steel properties are recalculated
      this.state.context.setImpactPropertyInstant({});
    },

    setErosionAbrasive: (abrasive: Abrasive) => {
      this.setApplicationState({ selectedErosionAbrasive: abrasive });
      // Make sure all upgrade steel properties are recalculated
      this.state.context.setErosionPropertyInstant({});
    },

    setTippingAbrasive: (abrasive: Abrasive) => {
      this.setApplicationState({ selectedTippingAbrasive: abrasive });
      // Make sure all upgrade steel properties are recalculated
      this.state.context.setTippingPropertyInstant({});
    },

    setMiningAbrasive: (abrasive: Abrasive) => {
      this.setApplicationState({ selectedMiningAbrasive: abrasive });
      // Make sure all upgrade steel properties are recalculated
      this.state.context.setMiningPropertyInstant({});
    },

    setSlidingSteels: (steels: Array<Steel>) => {
      this.setApplicationState({ slidingSteels: steels });
    },

    setImpactSteels: (steels: Array<Steel>) => {
      this.setApplicationState({ impactSteels: steels });
    },

    setErosionSteels: (steels: Array<Steel>) => {
      this.setApplicationState({ erosionSteels: steels });
    },

    setSelectedChart: (selectedChart: ChartType) =>
      this.setApplicationState({
        selectedChart: selectedChart,
      }),
    setErosionProperty: (stateUpdate: DeepPartial<ErosionPropertiesState>) => {
      const updatedState = mergeDeepRight(this.state.context.state.erosionProperties, stateUpdate);
      return this.setApplicationState({
        erosionProperties: createNextErosionPropertiesState(
          updatedState as ErosionPropertiesState,
          this.state.context.state.erosionProperties,
          this.state.context.state,
        ),
      });
    },

    setErosionPropertyInstant: stateUpdate => {
      const updatedState = mergeDeepRight(this.state.context.state.erosionProperties, stateUpdate);

      return this.setApplicationState({
        erosionProperties: createNextErosionPropertiesState(
          updatedState as ErosionPropertiesState,
          this.state.context.state.erosionProperties,
          this.state.context.state,
        ),
      });
    },

    setImpactProperty: (stateUpdate: DeepPartial<ImpactPropertiesState>) => {
      const updatedState = mergeDeepRight(this.state.context.state.impactProperties, stateUpdate);

      this.setApplicationState({
        impactProperties: createNextImpactPropertiesState(
          updatedState as ImpactPropertiesState,
          this.state.context.state.impactProperties,
          this.state.context.state,
        ),
      });
    },

    setImpactPropertyInstant: stateUpdate => {
      const updatedState = mergeDeepRight(this.state.context.state.impactProperties, stateUpdate);

      this.setApplicationState({
        impactProperties: createNextImpactPropertiesState(
          updatedState as ImpactPropertiesState,
          this.state.context.state.impactProperties,
          this.state.context.state,
        ),
      });
    },

    setMiningProperty: (stateUpdate: DeepPartial<MiningPropertiesState>) =>
      this.setApplicationState({
        miningProperties: createNextMiningPropertiesState(
          stateUpdate,
          this.state.context.state.miningProperties,
          this.state.context.state,
        ),
      }),

    setMiningPropertyInstant: stateUpdate =>
      this.setApplicationState({
        miningProperties: createNextMiningPropertiesState(
          stateUpdate,
          this.state.context.state.miningProperties,
          this.state.context.state,
        ),
      }),
    setSlidingProperty: (stateUpdate: DeepPartial<SlidingPropertiesState>) => {
      const updatedState = mergeDeepRight(this.state.context.state.slidingProperties, stateUpdate);
      this.setApplicationState({
        slidingProperties: createNextSlidingPropertiesState(
          updatedState as CalculationModelState,
          this.state.context.state.slidingProperties,
          this.state.context.state,
        ),
      });
    },
    setSlidingPropertyInstant: stateUpdate => {
      const updatedState = mergeDeepRight(this.state.context.state.slidingProperties, stateUpdate);
      this.setApplicationState({
        slidingProperties: createNextSlidingPropertiesState(
          updatedState as CalculationModelState,
          this.state.context.state.slidingProperties,
          this.state.context.state,
        ),
      });
    },
    setTippingProperty: (stateUpdate: DeepPartial<TippingPropertiesState>) =>
      this.setApplicationState({
        tippingProperties: createNextTippingPropertiesState(
          stateUpdate,
          this.state.context.state.tippingProperties,
          this.state.context.state,
        ) as TippingPropertiesState,
      }),

    setTippingPropertyInstant: stateUpdate =>
      this.setApplicationState({
        tippingProperties: createNextTippingPropertiesState(
          stateUpdate,
          this.state.context.state.tippingProperties,
          this.state.context.state,
        ) as TippingPropertiesState,
      }),

    setUseTimeUnit: (useTimeUnit: boolean) => this.setApplicationState({ useTimeUnit }),
    setUnitSystem: (unitSystem: UnitSystemOption) => this.setApplicationState({ selectedUnitSystem: unitSystem }),
    setDocxSettingsProperty: (property: DocxSettingsProperty) =>
      this.setApplicationState({
        docxSettings: createNextDocxSettings(property, this.state.context.state.docxSettings),
      }),

    setSelectedSteelTab: (selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab') =>
      this.setApplicationState({ selectedSteelTab }),
  };
  subscribers: Array<ApplicationStateSubscriber> = [];

  constructor(props: ApplicationStateProviderProps) {
    super(props);
    const state = this.props.middlewares.reduce(
      (state, middleware) => middleware(state, { isInitialLoad: true, hasCrashed: false }),
      props.initialState,
    );

    this.state = {
      context: {
        ...this.contextFunctions,
        state,
      },
    };

    if (import.meta.env.DEV) {
      /**
       * Very primitive replacement of Redux Devtools.
       * You can access the state and contextFunctions via
       * the global applicationState variable
       */
      window.applicationState = this;
    }
  }

  setApplicationState = (stateUpdate: Partial<ApplicationState>) => {
    const oldState = this.state.context.state;
    const newState = this.props.middlewares.reduce(
      (state, middleware) => middleware(state, { isInitialLoad: false, hasCrashed: false }),
      {
        ...oldState,
        ...stateUpdate,
      },
    );

    Object.assign(this.state, {
      context: {
        ...this.contextFunctions,
        state: newState,
      },
    });

    this.setState({}, () => {
      this.subscribers.forEach(subscriber => {
        subscriber(this.state.context);
      });
    });
  };

  subscribe = (cb: ApplicationStateSubscriber) => {
    this.subscribers.push(cb);
    return () => {
      this.subscribers = this.subscribers.filter(s => s !== cb);
    };
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    /**
     * Avoid having to clear localStorage after changes in state
     */
    if (import.meta.env.DEV) {
      console.error('ApplicationState caught error', info, error);

      const state = this.props.middlewares.reduce(
        (state, middleware) => middleware(state, { isInitialLoad: true, hasCrashed: true }),
        this.props.initialState,
      );

      this.state = {
        context: {
          ...this.contextFunctions,
          state,
        },
      };

      if (!sessionStorage.getItem('hasReloaded')) {
        sessionStorage.setItem('hasReloaded', 'true');
        window.location.reload();
      }
    } else throw error;
  }

  render() {
    return (
      <applicationState.Provider value={this.state.context}>
        {this.props.children(this.subscribe)}
      </applicationState.Provider>
    );
  }
}

export const ApplicationStateConsumer = applicationState.Consumer;
