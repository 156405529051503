import React, { PropsWithChildren, createContext } from 'react';
import { Language } from './languages';
import { Middleware } from '../applicationState';

declare global {
  interface Window {
    languageState: LanguageStateProvider;
  }
}

export type LanguageState = {
  version: string;
  languages: Array<Language>;
  selectedLanguage: Language;
};
export type LanguageStateContext = {
  state: LanguageState;
  setLanguage: (languages: Language) => void;
};

const languageState = createContext<LanguageStateContext>(undefined as unknown as LanguageStateContext);

type LanguageStateProviderProps = {
  initialState: LanguageState;
  middlewares: Array<Middleware<LanguageState>>;
};

export class LanguageStateProvider extends React.Component<
  PropsWithChildren<LanguageStateProviderProps>,
  {
    context: LanguageStateContext;
  }
> {
  contextFunctions = {
    setLanguage: (language: Language) => this.setLanguageState({ selectedLanguage: language }),
  };

  constructor(props: LanguageStateProviderProps) {
    super(props);
    const state = this.props.middlewares.reduce(
      (state, middleware) => middleware(state, { isInitialLoad: true, hasCrashed: false }),
      props.initialState,
    );

    this.state = {
      context: {
        ...this.contextFunctions,
        state,
      },
    };

    if (import.meta.env.DEV) {
      /**
       * Very primitive replacement of Redux Devtools.
       * You can access the state and contextFunctions via
       * the global languageState variable
       */
      window.languageState = this;
    }
  }

  setLanguageState = (stateUpdate: Partial<LanguageState>) => {
    const oldState = this.state.context.state;
    const newState = this.props.middlewares.reduce(
      (state, middleware) => middleware(state, { isInitialLoad: false, hasCrashed: false }),
      {
        ...oldState,
        ...stateUpdate,
      },
    );

    this.setState({
      context: {
        ...this.contextFunctions,
        state: newState,
      },
    });
  };

  render() {
    return <languageState.Provider value={this.state.context}>{this.props.children}</languageState.Provider>;
  }
}

export const LanguageStateConsumer = languageState.Consumer;
