import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Dropdown } from '@fluentui/react';
import { UnitSystemOption, ChartType, Route } from '../../types';
import { getHighlighStyles } from '../../utils/formHighlightHelper';
import { rockSizes } from '../../utils/constants/values';

const generateRockSizeDropdownOptions = (selectedUnitSystem: UnitSystemOption, calculationRoute: Route) => {
  let calculationRocks = rockSizes;

  if (calculationRoute.toString() === 'tipping') {
    calculationRocks = rockSizes.filter(size => size.includeInTipping);
  }

  if (selectedUnitSystem === 'metric') {
    return calculationRocks.map(rock => {
      return {
        key: rock.id.toString(),
        text: rock.textMillimeters,
      };
    });
  } else {
    return calculationRocks.map(rock => {
      return {
        key: rock.id.toString(),
        text: rock.textInches,
      };
    });
  }
};

export const RockSizeDropdown = injectIntl(
  (
    props: {
      rockSizeId: number;
      onChange: (rockSizeId: number) => void;
      selectedUnitSystem: UnitSystemOption;
      chartsToHighlight: ChartType[];
      selectedChart: ChartType;
      calculationRoute: Route;
    } & WrappedComponentProps,
  ) => (
    <>
      <Dropdown
        selectedKey={props.rockSizeId.toString()}
        onChange={(_event, option) => {
          props.onChange(+option!.key);
        }}
        options={generateRockSizeDropdownOptions(props.selectedUnitSystem, props.calculationRoute)}
        calloutProps={{
          calloutMaxHeight: 385,
          directionalHintFixed: true,
        }}
        styles={{
          title: getHighlighStyles(props.selectedChart, props.chartsToHighlight),
        }}
      />
    </>
  ),
);
