/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErosionValues, SlidingValues } from '../data/calculationValues';
import { getAbrasiveComponents, getAbrasives, getSteels } from '../data/data';
import { ApplicationState } from './applicationState';
import { getInitialState } from './initialState';

export const runStateMigrations = (state: any) => {
  const version = state.version;

  /* Always make sure latest constants are set. */
  const updatedState = {
    ...state,
    abrasives: getAbrasives(),
    abrasiveComponents: getAbrasiveComponents(),
    steels: getSteels(),
  };

  if (!version) return updatedState;

  const currentStateIndex = migrations.findIndex(m => m.version === version);

  const migrationsToRun = migrations.slice(currentStateIndex + 1, migrations.length);

  return migrationsToRun.length > 0 ? migrationsToRun.reduce((acc, curr) => curr.script(acc), updatedState) : state;
};

const migrations = [
  {
    version: '3.2.2',
    script: (state: ApplicationState) => ({
      ...state,
      version: '3.2.2',
      slidingProperties: {
        ...state.slidingProperties,
        upgradeSteelState: {
          ...state.slidingProperties.upgradeSteelState,
          upgradeBaseThickness: SlidingValues.upgradeBaseThickness.defaultValue,
        },
      },
      erosionProperties: {
        ...state.erosionProperties,
        upgradeSteelState: {
          ...state.erosionProperties.upgradeSteelState,
          upgradeBaseThickness: ErosionValues.upgradeBaseThickness.defaultValue,
        },
      },
      slidingSteels: getSteels().filter(s => s.includeInSliding),
      impactSteels: getSteels().filter(s => s.includeInAbrasiveImpact),
      erosionSteels: getSteels().filter(s => s.includeInErosion),
    }),
  },
  {
    version: '3.2.5',
    script: (_state: ApplicationState) => ({
      ...getInitialState(),
      version: '3.2.5',
    }),
  },
];
