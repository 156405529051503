import { Middleware } from './applicationState';

export function localStorageMiddleware<T extends { version?: string }>(
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  migrate?: (state: any) => T,
): Middleware<T> {
  return (state, { isInitialLoad, hasCrashed }) => {
    if (isInitialLoad && !hasCrashed) {
      try {
        const loadedState: T = JSON.parse(window.localStorage.getItem(key)!) || state;

        if (migrate) {
          return migrate(loadedState);
        } else {
          if (loadedState.version !== state.version) {
            localStorage.removeItem(key);
            return state;
          }
          return loadedState;
        }
      } catch (_) {
        return state;
      }
    } else {
      window.localStorage.setItem(key, JSON.stringify(state));
      return state;
    }
  };
}
