import styled from 'styled-components';
import { ssabWhite, ssabDarkGray, redHardox } from '../../utils/constants/colors';
import { fontSizeM } from '../../utils/constants/typography';
import logo from './SSAB.svg';
import { Settings } from './settings/Settings';
import { Links } from './links/Links';
import { IconButton } from '@fluentui/react';
import { RouteStateConsumer } from '../../state/routing/routeState';

import { APP_VERSION } from '../../view/WearCalc';

const NavbarWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 64px;
  width: 100%;
  z-index: 10;
  background-color: ${ssabWhite};
`;

const NavbarContent = styled.div`
  display: flex;
  align-items: center;
  min-width: 1000px;
  padding-left: 20px;
  padding-right: 20px;
`;

const Logo = styled.img`
  max-height: 30px;
  max-width: 89px;
  padding-right: 10px;
  padding-left: 10px;
`;

const AppName = styled.div`
  display: flex;
  align-items: center;
  color: ${ssabDarkGray};
  font-size: ${fontSizeM};
  padding-right: 10px;
  padding-left: 10px;
`;

export const NavbarIconButton = ({ icon, label, onClick }: { icon: string; label: string; onClick?: () => void }) => (
  <IconButton
    onClick={onClick}
    iconProps={{ iconName: icon }}
    title={label}
    ariaLabel={label}
    className="material-icon-large"
    styles={{
      icon: {
        color: ssabDarkGray,
        fontSize: 22,
        height: 22,
      },
      iconHovered: {
        color: redHardox,
      },
    }}
  />
);

export const Navbar = () => (
  <>
    <NavbarWrapper>
      <NavbarContent>
        <Logo src={logo} alt="SSAB" />
        {/* Version is read from package.json in root */}
        <AppName>Hardox® WearCalc {APP_VERSION}</AppName>
        <RouteStateConsumer>
          {({ state: { currentRoute } }) => (currentRoute === 'start' ? null : <Links />)}
        </RouteStateConsumer>
      </NavbarContent>
      <Settings />
    </NavbarWrapper>
    <div style={{ flexShrink: 0, height: 64 }} />
  </>
);
