import { CurrentSteelState, UpgradeSteelState, DeepPartial, TipperType, Steel } from '../types';
import { mergeDeepRight } from 'ramda';
import * as steelHelpers from '../utils/steelHelpers';
import { ApplicationState } from './applicationState';
import * as slidingCalculations from '../calculations/slidingCalculations';
import * as impactCalculations from '../calculations/impactCalculations';
import {
  calculateTippingServiceLifeForSteels,
  calculateThicknessFromServiceLife,
} from '../miningAndTippingCalculations';

import { validateTippingValues } from '../utils/validation/validateTippingCalculations';
import { getSlidingServiceLifeDataPoints } from '../view/sliding/Sliding';
import { roundTo } from '../utils/numberFormatting';
import { getServiceLifeFromTippings, normalizeCalculationServiceLifeWithSlidingData } from '../view/tipping/Tipping';

export type CalculationModelState = {
  currentSteelState: CurrentSteelState;
  upgradeSteelState: UpgradeSteelState;
};

export type CalculationModelStateUpdater<T> = (stateUpdate: DeepPartial<T>) => void;

const createNextCalculationModelState =
  <T extends CalculationModelState>(
    getServiceLifeFromThickness: (
      steel: Steel | undefined,
      thickness: number,
      calculationModelState: T,
      applicationState: ApplicationState,
    ) => null | number,
    getThicknessFromServiceLife: (
      steel: Steel | undefined,
      serviceLife: number,
      calculationModelState: T,
      applicationState: ApplicationState,
    ) => null | number,
    getTippingsFromThickness?: (
      steel: Steel | undefined,
      thickness: number,
      calculationModelState: T,
      applicationState: ApplicationState,
    ) => undefined | number,
  ) =>
  (updatedState: T, currentState: T, applicationState: ApplicationState): T => {
    if (
      updatedState.upgradeSteelState.upgradeSteel &&
      updatedState.currentSteelState.currentSteel.hardnessHV > updatedState.upgradeSteelState.upgradeSteel.hardnessHV
    ) {
      updatedState = {
        ...(updatedState as CalculationModelState),
        upgradeSteelState: {
          upgradeSteel: undefined,
          upgradeServiceLife: 0,
          upgradeThickness: currentState.upgradeSteelState.upgradeThickness,
        },
      } as T;
    }

    if (updatedState.upgradeSteelState.upgradeSteel) {
      if (updatedState.upgradeSteelState.upgradeServiceLife !== currentState.upgradeSteelState.upgradeServiceLife) {
        updatedState.upgradeSteelState.upgradeThickness =
          getThicknessFromServiceLife(
            updatedState.upgradeSteelState.upgradeSteel,
            updatedState.upgradeSteelState.upgradeServiceLife,
            updatedState,
            applicationState,
          ) || 0;
      } else if (updatedState.upgradeSteelState.upgradeThickness !== currentState.upgradeSteelState.upgradeThickness) {
        updatedState.upgradeSteelState.upgradeServiceLife =
          getServiceLifeFromThickness(
            updatedState.upgradeSteelState.upgradeSteel,
            updatedState.upgradeSteelState.upgradeThickness,
            updatedState,
            applicationState,
          ) || 0;
      } else {
        updatedState.upgradeSteelState.upgradeThickness =
          updatedState.upgradeSteelState.upgradeThickness || updatedState.currentSteelState.currentThickness;
        updatedState.upgradeSteelState.upgradeServiceLife =
          getServiceLifeFromThickness(
            updatedState.upgradeSteelState.upgradeSteel,
            updatedState.upgradeSteelState.upgradeThickness,
            updatedState,
            applicationState,
          ) || 0;
      }
      if (getTippingsFromThickness) {
        updatedState.upgradeSteelState.upgradeTippings = getTippingsFromThickness(
          updatedState.upgradeSteelState.upgradeSteel,
          updatedState.upgradeSteelState.upgradeThickness,
          updatedState,
          applicationState,
        );
      }
    }

    return updatedState;
  };

export type ErosionAbrasiveProperties = {
  velocity: number;
  sharpness: number;
  angle: number;
};

export type ErosionPropertiesState = CalculationModelState & {
  abrasiveProperties: ErosionAbrasiveProperties;
};
export const createNextErosionPropertiesState = createNextCalculationModelState<ErosionPropertiesState>(
  steelHelpers.serviceLifeFromThickness((steel, { abrasiveProperties }, { selectedErosionAbrasive }) =>
    selectedErosionAbrasive
      ? impactCalculations.calcErosionWareRateForSteel(selectedErosionAbrasive, abrasiveProperties, steel)
      : null,
  ),
  steelHelpers.thicknessFromServiceLife((steel, { abrasiveProperties }, { selectedErosionAbrasive }) =>
    selectedErosionAbrasive
      ? impactCalculations.calcErosionWareRateForSteel(selectedErosionAbrasive, abrasiveProperties, steel)
      : null,
  ),
);

export type ImpactAbrasiveProperties = {
  size: number;
  sharpness: number;
  angle: number;
};

export type ImpactPropertiesState = CalculationModelState & {
  abrasiveProperties: ImpactAbrasiveProperties;
};
export const createNextImpactPropertiesState = createNextCalculationModelState<ImpactPropertiesState>(
  steelHelpers.serviceLifeFromThickness(
    (steel, { abrasiveProperties }, { selectedImpactAbrasive, selectedUnitSystem }) =>
      selectedImpactAbrasive
        ? impactCalculations.calcImpactWareRateForSteel(
            selectedImpactAbrasive,
            abrasiveProperties,
            steel,
            selectedUnitSystem,
          )
        : null,
  ),
  steelHelpers.thicknessFromServiceLife(
    (steel, { abrasiveProperties }, { selectedImpactAbrasive, selectedUnitSystem }) =>
      selectedImpactAbrasive
        ? impactCalculations.calcImpactWareRateForSteel(
            selectedImpactAbrasive,
            abrasiveProperties,
            steel,
            selectedUnitSystem,
          )
        : null,
  ),
);

export type SlidingPropertiesState = CalculationModelState;
export const createNextSlidingPropertiesState = createNextCalculationModelState<CalculationModelState>(
  steelHelpers.serviceLifeFromThickness((steel, _, { selectedSlidingAbrasive }) =>
    selectedSlidingAbrasive ? slidingCalculations.calcWareRateForSteel(selectedSlidingAbrasive, steel) : null,
  ),
  steelHelpers.thicknessFromServiceLife((steel, _, { selectedSlidingAbrasive }) =>
    selectedSlidingAbrasive ? slidingCalculations.calcWareRateForSteel(selectedSlidingAbrasive, steel) : null,
  ),
);

export const createNextTippingPropertiesState = (
  stateUpdate: DeepPartial<TippingPropertiesState>,
  currentState: TippingPropertiesState,
  applicationState: ApplicationState,
) => {
  const calculationSteels = applicationState.steels.filter(s => s.includeInTipping);

  const hardox450 = steelHelpers.getHardox450ReferenceSteel();

  const { data: slidingData, reference: slidingRef } = getSlidingServiceLifeDataPoints(
    calculationSteels,
    applicationState.selectedTippingAbrasive,
    hardox450,
  );
  const normalizedSlidingData = slidingData.map(d => (slidingRef ? d / slidingRef : d));

  const calculateSteelServiceLifeAndTippingsFromThicknessForTipping = (
    steel: Steel | undefined,
    thickness: number,
    calculationModelState: TippingPropertiesState,
    applicationState: ApplicationState,
  ) => {
    if (!steel || !applicationState.selectedTippingAbrasive) {
      return {
        updatedServiceLifeFromThickness: 0,
        updatedTippingsFromThickness: 0,
      };
    }

    const hardox450Tippings = calculateTippingServiceLifeForSteels(
      hardox450,
      calculationModelState,
      applicationState.selectedTippingAbrasive,
      thickness,
      applicationState.selectedUnitSystem,
    );

    const normalizedTippings = normalizeCalculationServiceLifeWithSlidingData(
      calculationSteels,
      applicationState.selectedTippingAbrasive,
      hardox450Tippings,
    );

    const serviceLifeData = normalizedTippings.map(tippings =>
      getServiceLifeFromTippings(
        tippings,
        calculationModelState.unloadsPerDay,
        calculationModelState.workingDaysPerYear,
        calculationModelState.currentSteelState.serviceLifeTimeUnit,
      ),
    );

    const selectedSteelIndex = calculationSteels.findIndex(s => s.id === steel.id);
    const updatedServiceLifeFromThickness = serviceLifeData[selectedSteelIndex];
    const updatedTippingsFromThickness = roundTo(normalizedSlidingData[selectedSteelIndex] * hardox450Tippings, 0);

    return { updatedServiceLifeFromThickness, updatedTippingsFromThickness };
  };

  const calculateSteelServiceLifeFromThicknessForTipping = (
    steel: Steel | undefined,
    thickness: number,
    calculationModelState: TippingPropertiesState,
    applicationState: ApplicationState,
  ) => {
    const { updatedServiceLifeFromThickness } = calculateSteelServiceLifeAndTippingsFromThicknessForTipping(
      steel,
      thickness,
      calculationModelState,
      applicationState,
    );
    return updatedServiceLifeFromThickness;
  };

  const calculatedTippingsFromThicknessForTipping = (
    steel: Steel | undefined,
    thickness: number,
    calculationModelState: TippingPropertiesState,
    applicationState: ApplicationState,
  ) => {
    const { updatedTippingsFromThickness } = calculateSteelServiceLifeAndTippingsFromThicknessForTipping(
      steel,
      thickness,
      calculationModelState,
      applicationState,
    );
    return updatedTippingsFromThickness;
  };

  const common = createNextCalculationModelState<TippingPropertiesState>(
    calculateSteelServiceLifeFromThicknessForTipping,
    calculateThicknessFromServiceLife,
    calculatedTippingsFromThicknessForTipping,
  );

  let updatedState = mergeDeepRight(currentState, stateUpdate);

  if (!validateTippingValues(updatedState as TippingPropertiesState, applicationState.selectedUnitSystem)) {
    return updatedState;
  }

  updatedState = common(updatedState as TippingPropertiesState, currentState, applicationState);

  const {
    updatedServiceLifeFromThickness: currentServiceLifeFromThickness,
    updatedTippingsFromThickness: currentTippingsFromThickness,
  } = calculateSteelServiceLifeAndTippingsFromThicknessForTipping(
    updatedState.currentSteelState.currentSteel,
    updatedState.currentSteelState.currentThickness,
    updatedState as TippingPropertiesState,
    applicationState,
  );

  updatedState.currentSteelState.currentServiceLife = roundTo(currentServiceLifeFromThickness, 1);
  updatedState.currentSteelState.currentTippings = currentTippingsFromThickness;

  if (updatedState.upgradeSteelState.upgradeSteel !== undefined) {
    const {
      updatedServiceLifeFromThickness: upgradeServiceLifeFromThickness,
      updatedTippingsFromThickness: upgradeTippingsFromThickness,
    } = calculateSteelServiceLifeAndTippingsFromThicknessForTipping(
      updatedState.upgradeSteelState.upgradeSteel as Steel,
      updatedState.upgradeSteelState.upgradeThickness,
      updatedState as TippingPropertiesState,
      applicationState,
    );
    updatedState.upgradeSteelState.upgradeServiceLife = roundTo(upgradeServiceLifeFromThickness, 1);
    updatedState.upgradeSteelState.upgradeTippings = roundTo(upgradeTippingsFromThickness, 0);
  }

  const heatMapMax = Math.max(
    updatedState.upgradeSteelState.upgradeTippings || 0,
    updatedState.currentSteelState.currentTippings,
  );

  if (updatedState.heatMapTippings > heatMapMax) {
    updatedState.heatMapTippings = heatMapMax;
  }

  return updatedState;
};

export type TippingPropertiesState = CalculationModelState & {
  workingDaysPerYear: number;
  unloadsPerDay: number;
  tipperType: TipperType;
  rockSizeId: number;
  tipperLength: number;
  useStiffener: boolean;
  heatMapTippings: number;
};
