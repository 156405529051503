import React from 'react';
import { SteelCard, AbrasiveCard, PropertiesCard } from '../../components/card/Card';
import { ChartCard, ChartColumn } from '../../components/chart/ChartCard';
import { HeatMap, HeatMapSide } from '../../components/chart/heatMap/HeatMap';
import { SelectAbrasive } from '../../components/abrasive/Abrasive';
import { TippingProperties } from './TippingProperties';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { CalculationPageWrapper, PropertiesColumn } from '../../components/containers/Container';
import { calculateTippingServiceLifeForSteels } from '../../miningAndTippingCalculations';
import { TippingPropertiesState } from '../../state/calculationModels';
import { Abrasive, Steel, ChartType, UnitSystemOption, TipperType, CalculationType } from '../../types';
import { PivotItem } from '@fluentui/react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RelativeServiceLifeChart } from '../../components/chart/relative/RelativeServiceLifeChart';
import { constructSteelArray, getHardox450ReferenceSteel, getTranslatedSteels } from '../../utils/steelHelpers';
import styled from 'styled-components';
import { HeatMapInputs } from '../../components/chart/heatMap/HeatMapInputs';
import { TippingCurrentSteelProperties } from './TippingCurrentSteelProperties';
import { TippingUpgradeSteelProperties } from './TippingUpgradeSteelProperties';
import { ChartPivot } from '../../components/chart/ChartPivot';
import { HeatMapLegend } from '../../components/chart/heatMap/HeatMapLegend';
import {
  HeatMapHeader,
  HeatMapHeaderText,
  HeatMapWrapper,
  HeatMapContentWrapper,
  HeatMapLegendWrapper,
  HeatMapHeaderWrapper,
  HeatMapHeaderRow,
  BottomLabel,
} from '../../components/chart/heatMap/HeatMapUtils';
import {
  getUShapedWearMatrix,
  getBoxShapedWearMatrix,
  getBoxShapedSideWearMatrix,
} from '../../data/WearDistribution/matrixDataHelper';
import { ThrottleChanges } from '../../components/ThrottleChanges';
import { LanguageStateConsumer } from '../../state/language/languageState';
import { HeatMapComponent } from '../../components/chart/heatMap/HeatMapComponent';
import { Summary } from '../../components/summary/Summary';
import { neutralLighter } from '../../utils/constants/colors';
import { calculateTippingWearDistribution } from '../../workerManager';

import { caluclateTippingServiceLifeForSteels } from '../../workerManager';
import { getSelectedUnitText } from '../../utils/convertUnits/convertUnits';
import { validateTippingValues } from '../../utils/validation/validateTippingCalculations';
import { getNormalizedSlidingServiceLifePoints } from '../sliding/Sliding';
import { TimeUnit, timeUnits } from '../../state/timeUnits';
import { roundTo } from '../../utils/numberFormatting';

export const FlexRow = styled.div`
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  margin-top: 10px;
`;

const CanvasWrapper = styled.div`
  background-color: ${neutralLighter};
  padding: 10px;
  position: relative;
  z-index: 1;
`;

/* Convert years to selected time unit */
const convertYearsToSelectedTimeUnit = (years: number, timeUnit: TimeUnit) => {
  switch (timeUnit.id) {
    //Hours
    case timeUnits[0].id:
      return years * (365 * 24);
    //Days
    case timeUnits[1].id:
      return years * 365;
    //Months
    case timeUnits[2].id:
      return years * 12;
    //Years
    default:
      return years;
  }
};

export const getServiceLifeFromTippings = (
  tippings: number,
  unloadsPerDay: number,
  workingDaysPerYear: number,
  serviceLifeTimeUnit: TimeUnit,
) => {
  const tippingsPerYear = unloadsPerDay * workingDaysPerYear;
  const numberOfYears = tippings / tippingsPerYear;
  return roundTo(convertYearsToSelectedTimeUnit(numberOfYears, serviceLifeTimeUnit), 1);
};

export const getTipperServiceLifeDataPoints = (
  steels: Steel[],
  tippingProperties: TippingPropertiesState,
  selectedAbrasive: Abrasive | undefined,
  plateThickness: number,
  selectedUnitSystem: UnitSystemOption,
) => {
  const hardox450 = steels.find(s => s.id === getHardox450ReferenceSteel().id);

  if (selectedAbrasive === undefined || hardox450 === undefined) {
    return { data: [], reference: null };
  }

  const hardox450Tippings = calculateTippingServiceLifeForSteels(
    hardox450,
    tippingProperties,
    selectedAbrasive,
    plateThickness,
    selectedUnitSystem,
  );

  const tippingData = normalizeCalculationServiceLifeWithSlidingData(steels, selectedAbrasive, hardox450Tippings);

  const serviceLifeData = tippingData.map(td =>
    getServiceLifeFromTippings(
      td,
      tippingProperties.unloadsPerDay,
      tippingProperties.workingDaysPerYear,
      tippingProperties.currentSteelState.serviceLifeTimeUnit,
    ),
  );

  const selectedSteelIndex = steels.map(steel => steel.id).indexOf(tippingProperties.currentSteelState.currentSteel.id);

  const reference = serviceLifeData[selectedSteelIndex];

  return { data: serviceLifeData, reference };
};

export const generateTippingHeatMapMatrix = (
  data: Array<number>,
  tippingProperties: TippingPropertiesState,
  isTipperSide: boolean,
  side?: 'left' | 'right',
) => {
  if (tippingProperties.tipperType === TipperType.Box && isTipperSide && side !== undefined) {
    return [getBoxShapedSideWearMatrix(data, side)];
  }

  if (tippingProperties.tipperType === TipperType.Box) {
    return [getBoxShapedWearMatrix(data)];
  } else {
    return [getUShapedWearMatrix(data)];
  }
};

export const normalizeCalculationServiceLifeWithSlidingData = (
  calculationSteels: Steel[],
  selectedAbrasive: Abrasive,
  hardox450ServiceLife: number,
) => {
  const normalizedSlidingData = getNormalizedSlidingServiceLifePoints(calculationSteels, selectedAbrasive);
  return normalizedSlidingData.map((d: number) => roundTo(d * hardox450ServiceLife, 0));
};

/*
  Calculates WearDistribution and ServiceLife with web-worker.

  Service life for each steel is calculated using a mix of tipping calculations and sliding calculations.
  - Do tipping calculations to get the service life for hardox 450.
  - Do sliding calculations to get the relation between hardox 400, hardox 450 and hardox 500.
  - Normalize array from sliding calculations so hardox 450 is value 1. (Sliding relations may look like [0.72, 1, 1.23] when normalized).
  - Multiply each value in the normalized relation array with the service life for hardox 450 to get an array containing the service life for each of the 3 steels.
*/
export const doTippingCalculations = async (
  calculationSteels: Array<Steel>,
  tippingProperties: TippingPropertiesState,
  selectedAbrasive: Abrasive | undefined,
  selectedUnitSystem: UnitSystemOption,
) => {
  if (!validateTippingValues(tippingProperties, selectedUnitSystem)) {
    return;
  }

  const hardox450 = calculationSteels.find(s => s.id === getHardox450ReferenceSteel().id);
  if (!selectedAbrasive || !hardox450) {
    return;
  }

  /*
    Getting service life data for tipping. We are only interested in value for hardox 450 since we use sliding calculations
    to get the relations between all steels.
  */
  const hardox450TippingsCurrent = (await caluclateTippingServiceLifeForSteels({
    steel: hardox450,
    tippingProperties,
    selectedAbrasive,
    plateThickness: tippingProperties.currentSteelState.currentThickness,
    selectedUnitSystem,
  })) as number;

  const hardox450TippingsUpgrade = (await caluclateTippingServiceLifeForSteels({
    steel: hardox450,
    tippingProperties,
    selectedAbrasive,
    plateThickness: tippingProperties.upgradeSteelState.upgradeThickness,
    selectedUnitSystem,
  })) as number;

  const numberOfTippings = tippingProperties.heatMapTippings || 1;

  const hardox450WearDistributionCurrent = (await calculateTippingWearDistribution({
    steel: hardox450,
    tippingProperties,
    selectedAbrasive,
    steelThickness: tippingProperties.currentSteelState.currentThickness,
    numberOfTippings,
    selectedUnitSystem,
  })) as Array<number>;

  const hardox450WearDistributionUpgrade = (await calculateTippingWearDistribution({
    steel: hardox450,
    tippingProperties,
    selectedAbrasive,
    steelThickness: tippingProperties.upgradeSteelState.upgradeThickness,
    numberOfTippings,
    selectedUnitSystem,
  })) as Array<number>;

  const normalizedSlidingData = getNormalizedSlidingServiceLifePoints(calculationSteels, selectedAbrasive);
  const normalizedTippingsForSteels = normalizedSlidingData.map((d: number) =>
    roundTo(d * hardox450TippingsCurrent, 0),
  );

  const currentSteelIndex = calculationSteels.findIndex(
    s => s.id === tippingProperties.currentSteelState.currentSteel.id,
  );
  const currentSteelSlidingValue = normalizedSlidingData[currentSteelIndex];
  const currentSteelMaxTippings = roundTo(currentSteelSlidingValue * hardox450TippingsCurrent, 0);
  const currentWearData = hardox450WearDistributionCurrent.map(d => d * (1 / currentSteelSlidingValue));

  const upgradeSteelIndex = calculationSteels.findIndex(
    s => s.id === tippingProperties.upgradeSteelState.upgradeSteel?.id,
  );
  const upgradeSteelSliding = normalizedSlidingData[upgradeSteelIndex];
  const upgradeSteelMaxTippings = roundTo(upgradeSteelSliding ? upgradeSteelSliding * hardox450TippingsUpgrade : 0, 0);
  const upgradeWearData = upgradeSteelSliding
    ? hardox450WearDistributionUpgrade.map(d => d * (1 / upgradeSteelSliding))
    : [];

  /*
    Used to lower the wear for the least worn plate to make wear distribution look better.
  */
  const normlizeWearData = (wear: Array<number>) => {
    const maxValue = Math.max(...wear);
    if (maxValue > 1) {
      return wear.map(wd => wd / maxValue);
    }

    return wear;
  };

  const tweakedUpgradeWearData =
    currentWearData[0] > upgradeWearData[0] ? normlizeWearData(upgradeWearData) : upgradeWearData;

  const tweakedCurrentWearData =
    currentWearData[0] < upgradeWearData[0] ? normlizeWearData(currentWearData) : currentWearData;

  return {
    currentWearData: tweakedCurrentWearData,
    upgradeWearData: tweakedUpgradeWearData,
    normalizedTippingsForSteels,
    currentSteelMaxTippings,
    upgradeSteelMaxTippings,
  };
};

export const Tipping = injectIntl(
  class TippingClass extends React.Component<
    WrappedComponentProps,
    {
      normalizedTippingsForSteels: Array<number>;
      currentSteelMaxTippings: number;
      upgradeSteelMaxTippings: number;
      wearData: {
        currentWearData: Array<number>;
        upgradeWearData: Array<number>;
      };
    }
  > {
    tippingProperties: TippingPropertiesState | undefined;

    constructor(props: WrappedComponentProps) {
      super(props);

      this.state = {
        normalizedTippingsForSteels: [],
        currentSteelMaxTippings: 0,
        upgradeSteelMaxTippings: 0,
        wearData: {
          currentWearData: [],
          upgradeWearData: [],
        },
      };
    }

    shouldComponentUpdate(nextProps: WrappedComponentProps, nextState: this['state']) {
      return nextProps.intl !== this.props.intl || nextState.wearData !== this.state.wearData;
    }

    render() {
      const { intl } = this.props;
      return (
        <ApplicationStateConsumer>
          {({
            state: {
              steels,
              tippingProperties,
              selectedSteelTab,
              useTimeUnit,
              selectedUnitSystem,
              selectedTippingAbrasive,
            },
            setTippingAbrasive,
            setTippingProperty,
            setTippingPropertyInstant,
            setSelectedSteelTab,
          }) => {
            return (
              <LanguageStateConsumer>
                {({ state: { selectedLanguage } }) => {
                  /* Tipping calculations */

                  const tipperSteels = steels.filter(steel => steel.includeInTipping);

                  const calculationSteels = getTranslatedSteels(
                    intl,
                    constructSteelArray(tipperSteels, tippingProperties.currentSteelState.currentSteel),
                  );

                  const displayCurrentHeatMap =
                    selectedTippingAbrasive !== undefined &&
                    tippingProperties.currentSteelState.currentSteel !== undefined;

                  const displayUpgradeHeatMap =
                    selectedTippingAbrasive !== undefined &&
                    tippingProperties.upgradeSteelState.upgradeSteel !== undefined;

                  /* Tipping calculations, service life and heatmap. */
                  if (tippingProperties !== this.tippingProperties) {
                    doTippingCalculations(
                      calculationSteels,
                      tippingProperties,
                      selectedTippingAbrasive,
                      selectedUnitSystem,
                    ).then(result => {
                      if (!result) {
                        return;
                      }
                      const {
                        currentWearData,
                        upgradeWearData,
                        normalizedTippingsForSteels,
                        currentSteelMaxTippings,
                        upgradeSteelMaxTippings,
                      } = result;

                      this.setState({
                        normalizedTippingsForSteels,
                        currentSteelMaxTippings,
                        upgradeSteelMaxTippings,
                        wearData: {
                          currentWearData,
                          upgradeWearData,
                        },
                      });
                    });

                    this.tippingProperties = tippingProperties;
                  }
                  const steelsServiceLife = this.state.normalizedTippingsForSteels.map(td =>
                    getServiceLifeFromTippings(
                      td,
                      tippingProperties.unloadsPerDay,
                      tippingProperties.workingDaysPerYear,
                      tippingProperties.currentSteelState.serviceLifeTimeUnit,
                    ),
                  );

                  const currentSteelIndex = calculationSteels
                    .map(steel => steel.id)
                    .indexOf(tippingProperties.currentSteelState.currentSteel.id);

                  const serviceLifeReference = steelsServiceLife[currentSteelIndex];

                  const heatMapMax = Math.max(
                    this.state.currentSteelMaxTippings,
                    this.state.upgradeSteelMaxTippings,
                    1,
                  );

                  return (
                    <CalculationPageWrapper>
                      <PropertiesColumn>
                        <AbrasiveCard>
                          <SelectAbrasive
                            selectedAbrasive={selectedTippingAbrasive}
                            setCalculationModeAbrasive={setTippingAbrasive}
                            calculationType={CalculationType.Tipping}
                          />
                        </AbrasiveCard>

                        {selectedTippingAbrasive && (
                          <>
                            <PropertiesCard calculationProperties={<TippingProperties />} />
                            <SteelCard
                              calculationProperties={
                                <TippingCurrentSteelProperties
                                  upgradeSteelState={tippingProperties.upgradeSteelState}
                                  currentSteelState={tippingProperties.currentSteelState}
                                  setCurrentSteelState={currentSteelState =>
                                    setTippingProperty({
                                      currentSteelState,
                                    })
                                  }
                                  setCurrentSteelStateInstant={currentSteelState =>
                                    setTippingPropertyInstant({
                                      currentSteelState,
                                    })
                                  }
                                  calculationSteels={calculationSteels}
                                  includeCustomSteel={false}
                                  rockSizeId={tippingProperties.rockSizeId}
                                />
                              }
                              upgradeSteelProperties={
                                <TippingUpgradeSteelProperties
                                  upgradeSteelState={tippingProperties.upgradeSteelState}
                                  currentSteelState={tippingProperties.currentSteelState}
                                  setUpgradeSteelState={upgradeSteelState =>
                                    setTippingProperty({
                                      upgradeSteelState,
                                    })
                                  }
                                  setUpgradeSteelStateInstant={upgradeSteelState =>
                                    setTippingPropertyInstant({
                                      upgradeSteelState,
                                    })
                                  }
                                  setCurrentSteelState={currentSteelState =>
                                    setTippingProperty({
                                      currentSteelState,
                                    })
                                  }
                                  setCurrentSteelStateInstant={currentSteelState =>
                                    setTippingPropertyInstant({
                                      currentSteelState,
                                    })
                                  }
                                  calculationSteels={calculationSteels}
                                  includeCustomSteel={false}
                                  rockSizeId={tippingProperties.rockSizeId}
                                />
                              }
                              selectedSteelTab={selectedSteelTab}
                              setSelectedSteelTab={setSelectedSteelTab}
                            />
                          </>
                        )}
                      </PropertiesColumn>
                      <ChartColumn>
                        <ChartPivot displayDownloadDocX={selectedTippingAbrasive !== undefined}>
                          <PivotItem
                            itemKey={ChartType.ServiceLife}
                            headerText={intl.formatMessage({
                              id: 'serviceLife',
                            })}
                          >
                            <ChartCard style={{ minHeight: 667 }}>
                              <RelativeServiceLifeChart
                                currentSteelState={tippingProperties.currentSteelState}
                                upgradeSteelState={tippingProperties.upgradeSteelState}
                                steels={calculationSteels}
                                reference={serviceLifeReference}
                                useTimeUnit={useTimeUnit}
                                data={selectedTippingAbrasive ? steelsServiceLife : []}
                                setCurrentSteel={(steel: Steel) => {
                                  setTippingPropertyInstant({
                                    currentSteelState: { currentSteel: steel },
                                  });
                                }}
                                selectedSteelTab={selectedSteelTab}
                                setUpgradeSteel={steel =>
                                  setTippingPropertyInstant({
                                    upgradeSteelState: { upgradeSteel: steel },
                                  })
                                }
                                chartTitleSuffix={
                                  tippingProperties.currentSteelState.currentThickness +
                                  ' ' +
                                  getSelectedUnitText(selectedUnitSystem, intl)
                                }
                              />
                            </ChartCard>
                          </PivotItem>

                          <PivotItem itemKey={ChartType.HeatMap} headerText={intl.formatMessage({ id: 'heatMap' })}>
                            <ChartCard style={{ minHeight: 665 }}>
                              <div>
                                {selectedTippingAbrasive && (
                                  <HeatMapInputs
                                    tippings={tippingProperties.heatMapTippings}
                                    maxNumberOfTippings={heatMapMax}
                                    setHeatMapTippings={tippings => {
                                      setTippingProperty({
                                        heatMapTippings: tippings,
                                      });
                                    }}
                                  />
                                )}
                              </div>
                              {validateTippingValues(tippingProperties, selectedUnitSystem) && (
                                <FlexRow style={{ flexGrow: 1, flexShrink: 1 }}>
                                  <HeatMapHeaderRow>
                                    <HeatMapHeaderWrapper>
                                      <HeatMapHeader>
                                        <HeatMapHeaderText>
                                          {intl.formatMessage({
                                            id: 'currentSteel',
                                          })}
                                        </HeatMapHeaderText>
                                      </HeatMapHeader>
                                    </HeatMapHeaderWrapper>
                                    <HeatMapHeaderWrapper>
                                      <HeatMapHeader>
                                        <HeatMapHeaderText>
                                          {intl.formatMessage({
                                            id: 'upgradeSteel',
                                          })}
                                        </HeatMapHeaderText>
                                      </HeatMapHeader>
                                    </HeatMapHeaderWrapper>
                                  </HeatMapHeaderRow>
                                  <HeatMapWrapper>
                                    <HeatMapComponent
                                      calculationProperties={tippingProperties}
                                      steelType={'current'}
                                      steelSelected={tippingProperties.currentSteelState.currentSteel !== undefined}
                                      selectedAbrasive={selectedTippingAbrasive}
                                      minHeight={tippingProperties.tipperType === TipperType.Box ? 420 : 320}
                                      wearData={selectedTippingAbrasive ? this.state.wearData : {}}
                                      hasData={this.state.wearData.currentWearData.length > 0}
                                      isValid={true}
                                    >
                                      <HeatMapContentWrapper>
                                        {displayCurrentHeatMap ? (
                                          tippingProperties.tipperType === TipperType.Box ? (
                                            <ThrottleChanges
                                              selectedLanguage={selectedLanguage}
                                              data={[tippingProperties] as [TippingPropertiesState]}
                                            >
                                              {([tippingProperties]) => {
                                                return (
                                                  <CanvasWrapper
                                                    style={{
                                                      paddingTop: 10,
                                                      paddingBottom: 4,
                                                    }}
                                                  >
                                                    <HeatMapSide
                                                      data={
                                                        selectedTippingAbrasive
                                                          ? generateTippingHeatMapMatrix(
                                                              this.state.wearData.currentWearData,
                                                              tippingProperties,
                                                              true,
                                                              'left',
                                                            )
                                                          : []
                                                      }
                                                      heightInPixels={110}
                                                      side={'left'}
                                                      calculationType={CalculationType.Tipping}
                                                    />
                                                  </CanvasWrapper>
                                                );
                                              }}
                                            </ThrottleChanges>
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                        <ThrottleChanges
                                          selectedLanguage={selectedLanguage}
                                          data={[tippingProperties] as [TippingPropertiesState]}
                                        >
                                          {([tippingProperties]) => {
                                            return (
                                              <CanvasWrapper
                                                style={{
                                                  paddingTop: tippingProperties.tipperType === TipperType.Box ? 0 : 10,
                                                  paddingBottom: 4,
                                                }}
                                              >
                                                <HeatMap
                                                  data={
                                                    selectedTippingAbrasive
                                                      ? generateTippingHeatMapMatrix(
                                                          this.state.wearData.currentWearData,
                                                          tippingProperties,
                                                          false,
                                                        )
                                                      : []
                                                  }
                                                  heightInPixels={
                                                    tippingProperties.tipperType === TipperType.Box ? 160 : 300
                                                  }
                                                  virtualSides={tippingProperties.tipperType === TipperType.UShape}
                                                  renderSides={tippingProperties.tipperType === TipperType.Box}
                                                />
                                              </CanvasWrapper>
                                            );
                                          }}
                                        </ThrottleChanges>

                                        {displayCurrentHeatMap ? (
                                          tippingProperties.tipperType === TipperType.Box ? (
                                            <ThrottleChanges
                                              selectedLanguage={selectedLanguage}
                                              data={[tippingProperties] as [TippingPropertiesState]}
                                            >
                                              {([tippingProperties]) => {
                                                return (
                                                  <CanvasWrapper
                                                    style={{
                                                      paddingTop: 4,
                                                      paddingBottom: 4,
                                                    }}
                                                  >
                                                    <HeatMapSide
                                                      data={
                                                        selectedTippingAbrasive
                                                          ? generateTippingHeatMapMatrix(
                                                              this.state.wearData.currentWearData,
                                                              tippingProperties,
                                                              true,
                                                              'right',
                                                            )
                                                          : []
                                                      }
                                                      heightInPixels={110}
                                                      side={'right'}
                                                      calculationType={CalculationType.Tipping}
                                                    />
                                                  </CanvasWrapper>
                                                );
                                              }}
                                            </ThrottleChanges>
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </HeatMapContentWrapper>
                                    </HeatMapComponent>
                                    <HeatMapComponent
                                      calculationProperties={tippingProperties}
                                      steelType={'upgrade'}
                                      steelSelected={tippingProperties.upgradeSteelState.upgradeSteel !== undefined}
                                      selectedAbrasive={selectedTippingAbrasive}
                                      minHeight={tippingProperties.tipperType === TipperType.Box ? 420 : 320}
                                      wearData={selectedTippingAbrasive ? this.state.wearData : {}}
                                      hasData={this.state.wearData.upgradeWearData.length > 0}
                                      isValid={true}
                                    >
                                      <HeatMapContentWrapper>
                                        {displayUpgradeHeatMap ? (
                                          tippingProperties.tipperType === TipperType.Box ? (
                                            <ThrottleChanges
                                              selectedLanguage={selectedLanguage}
                                              data={[tippingProperties] as [TippingPropertiesState]}
                                            >
                                              {([tippingProperties]) => {
                                                return (
                                                  <CanvasWrapper
                                                    style={{
                                                      paddingTop: 10,
                                                      paddingBottom: 4,
                                                    }}
                                                  >
                                                    <HeatMapSide
                                                      data={
                                                        selectedTippingAbrasive
                                                          ? generateTippingHeatMapMatrix(
                                                              this.state.wearData.upgradeWearData,
                                                              tippingProperties,
                                                              true,
                                                              'left',
                                                            )
                                                          : []
                                                      }
                                                      heightInPixels={110}
                                                      side={'left'}
                                                      calculationType={CalculationType.Tipping}
                                                    />
                                                  </CanvasWrapper>
                                                );
                                              }}
                                            </ThrottleChanges>
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                        <ThrottleChanges
                                          selectedLanguage={selectedLanguage}
                                          data={[tippingProperties] as [TippingPropertiesState]}
                                        >
                                          {([tippingProperties]) => {
                                            return (
                                              <CanvasWrapper
                                                style={{
                                                  paddingTop: tippingProperties.tipperType === TipperType.Box ? 0 : 10,
                                                  paddingBottom: 4,
                                                }}
                                              >
                                                <HeatMap
                                                  data={
                                                    selectedTippingAbrasive
                                                      ? generateTippingHeatMapMatrix(
                                                          this.state.wearData.upgradeWearData,
                                                          tippingProperties,
                                                          false,
                                                        )
                                                      : []
                                                  }
                                                  heightInPixels={
                                                    tippingProperties.tipperType === TipperType.Box ? 160 : 300
                                                  }
                                                  virtualSides={tippingProperties.tipperType === TipperType.UShape}
                                                  renderSides={tippingProperties.tipperType === TipperType.Box}
                                                />
                                              </CanvasWrapper>
                                            );
                                          }}
                                        </ThrottleChanges>

                                        {displayUpgradeHeatMap ? (
                                          tippingProperties.tipperType === TipperType.Box ? (
                                            <ThrottleChanges
                                              selectedLanguage={selectedLanguage}
                                              data={[tippingProperties] as [TippingPropertiesState]}
                                            >
                                              {([tippingProperties]) => {
                                                return (
                                                  <CanvasWrapper
                                                    style={{
                                                      paddingTop: 4,
                                                      paddingBottom: 7,
                                                      position: 'relative',
                                                    }}
                                                  >
                                                    <HeatMapSide
                                                      data={
                                                        selectedTippingAbrasive
                                                          ? generateTippingHeatMapMatrix(
                                                              this.state.wearData.upgradeWearData,
                                                              tippingProperties,
                                                              true,
                                                              'right',
                                                            )
                                                          : []
                                                      }
                                                      heightInPixels={110}
                                                      side={'right'}
                                                      calculationType={CalculationType.Tipping}
                                                    />
                                                  </CanvasWrapper>
                                                );
                                              }}
                                            </ThrottleChanges>
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </HeatMapContentWrapper>
                                    </HeatMapComponent>
                                    <HeatMapLegendWrapper>
                                      <div style={{ paddingTop: 10 }}>
                                        <HeatMapLegend
                                          heightInPixels={tippingProperties.tipperType === TipperType.Box ? 400 : 300}
                                        />
                                      </div>
                                    </HeatMapLegendWrapper>
                                  </HeatMapWrapper>

                                  <BottomLabel style={{ marginTop: 20 }}>
                                    {intl.formatMessage({
                                      id: 'reducedThickness',
                                    })}{' '}
                                    [%]
                                  </BottomLabel>

                                  {(tippingProperties.heatMapTippings > this.state.currentSteelMaxTippings ||
                                    (tippingProperties.heatMapTippings > this.state.upgradeSteelMaxTippings &&
                                      this.state.upgradeSteelMaxTippings > 0)) && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginTop: 10,
                                        width: '100%',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: 15,
                                          height: 15,
                                          background: `repeating-linear-gradient(
                                45deg,
                                #e62e00,
                                #e62e00 2px,
                                #000000 2px,
                                #000000 4px
                              )`,
                                        }}
                                      />
                                      <span style={{ marginLeft: 8 }}>
                                        {intl.formatMessage({
                                          id: 'dashedAreaIndicates',
                                        })}
                                      </span>
                                    </div>
                                  )}
                                </FlexRow>
                              )}
                            </ChartCard>
                          </PivotItem>
                          <PivotItem itemKey={ChartType.Summary} headerText={intl.formatMessage({ id: 'summary' })}>
                            <ChartCard style={{ userSelect: 'auto', minHeight: 665 }}>
                              <Summary
                                currentSteelState={tippingProperties.currentSteelState}
                                upgradeSteelState={tippingProperties.upgradeSteelState}
                                selectedUnitSystem={selectedUnitSystem}
                              />
                            </ChartCard>
                          </PivotItem>
                        </ChartPivot>
                      </ChartColumn>
                    </CalculationPageWrapper>
                  );
                }}
              </LanguageStateConsumer>
            );
          }}
        </ApplicationStateConsumer>
      );
    }
  },
);
