import { injectIntl, WrappedComponentProps } from 'react-intl';
import { PropertiesRow } from '../../containers/PropertiesRow';
import { NumberInput } from '../../form/NumberInput';
import { ChartType } from '../../../types';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Slider } from '@fluentui/react';

export const HeatmapInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const HeatMapInput = styled.div`
  display: flex;
  margin: 0 10px;
  flex-direction: column;
`;

export const HeatMapInputs = injectIntl(
  (
    props: {
      tippings: number;
      maxNumberOfTippings: number;
      setHeatMapTippings: (tippings: number) => void;
    } & WrappedComponentProps,
  ) => {
    const [tippings, setTippings] = useState(props.tippings);
    const maxNoOffTippings = props.maxNumberOfTippings;

    const handleTippingChange = (tippings: number) => {
      props.setHeatMapTippings(tippings);
    };

    useEffect(() => {
      if (maxNoOffTippings > 1 && maxNoOffTippings < tippings) {
        setTippings(maxNoOffTippings);
        props.setHeatMapTippings(maxNoOffTippings);
      }
      if (tippings <= maxNoOffTippings && props.tippings !== tippings) {
        props.setHeatMapTippings(tippings);
      }
    }, [maxNoOffTippings, tippings, props]);

    return (
      <>
        <PropertiesRow>
          <HeatmapInputWrapper>
            <HeatMapInput style={{ maxWidth: 250 }}>
              <NumberInput
                value={tippings < maxNoOffTippings ? tippings : maxNoOffTippings}
                suffix={props.intl.formatMessage({
                  id: 'unloads',
                })}
                onChange={tippings => {
                  setTippings(tippings);
                }}
                minValue={1}
                maxValue={maxNoOffTippings}
                chartsToHighlight={[ChartType.HeatMap]}
                numberOfDecimals={0}
              />
            </HeatMapInput>
          </HeatmapInputWrapper>
        </PropertiesRow>
        <PropertiesRow style={{ paddingTop: 10 }}>
          <HeatmapInputWrapper>
            <Slider
              value={tippings}
              min={1}
              max={maxNoOffTippings}
              className="heat-map-range-slider"
              onChange={tippings => {
                setTippings(tippings);
              }}
              onChanged={(_, tippings) => {
                handleTippingChange(tippings);
              }}
            />
          </HeatmapInputWrapper>
        </PropertiesRow>
      </>
    );
  },
);
