import { ChartType, Route } from '../types';
import { inputHighlightColor } from './constants/colors';

export function LabelShouldHighlight(
  chartsToHighlight?: Array<ChartType>,
  selectedChart?: ChartType,
  routesToHighlight?: Array<Route>,
  currentRoute?: Route,
) {
  if (chartsToHighlight === undefined || selectedChart === undefined) {
    return false;
  }

  if (routesToHighlight !== undefined && currentRoute && !routesToHighlight.includes(currentRoute)) {
    return false;
  }

  if (chartsToHighlight.includes(selectedChart)) {
    return true;
  }

  return false;
}

export const getHighlighStyles = (
  selectedChart: ChartType,
  chartToHighlight: Array<ChartType>,
  routesToHighlight?: Array<Route>,
  currentRoute?: Route,
) => {
  if (LabelShouldHighlight(chartToHighlight, selectedChart, routesToHighlight, currentRoute)) {
    return {
      backgroundColor: inputHighlightColor,
      transition: 'background-color 0.5s',
    };
  }

  return {
    backgroundColor: 'white',
    transition: 'background-color 0.3s',
  };
};
