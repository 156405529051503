import { getColors, adjustDataPoint } from '../chartHelpers';
import * as R from 'ramda';
import { Steel, UpgradeSteelState } from '../../../types';
import {
  neutralTertiary,
  neutralPrimary,
  neutralSecondary,
  white,
  wearCalcGreen,
  wearCalcGreenDark,
  transparentGrayDark,
  transparentWearCalcGreen,
  transparentWearCalcGreenDark,
  neutralSecondaryDark,
} from '../../../utils/constants/colors';
import { roundTo } from '../../../utils/numberFormatting';
import { isDuroxite } from '../../../utils/duroxite';
import { ChartOptions } from 'chart.js';

export const getThicknessChartData = (
  steels: Array<Steel>,
  selectedCurrentSteel: Steel | undefined,
  selectedUpgradeSteelState: UpgradeSteelState,
  dataPoints: Array<number>,
  referencePoint: number | null,
  currentThickness: number,
  wornOutThickness: number,
) => {
  /* If no reference return empty dataset. */
  if (referencePoint === null) {
    return {
      labels: [],
      datasets: [],
    };
  }

  const colors = getColors(steels, selectedCurrentSteel, selectedUpgradeSteelState.upgradeSteel);

  const wornOutColors = colors.map(color => {
    if (color === neutralSecondary) {
      return neutralSecondaryDark;
    } else if (color === wearCalcGreen) {
      return wearCalcGreenDark;
    } else if (color === transparentWearCalcGreen) {
      return transparentWearCalcGreenDark;
    } else {
      return transparentGrayDark;
    }
  });

  /* For duroxite worn out thickness is the thickness of the base plate. */
  const thicknessWornOut = steels.map(s => {
    if (isDuroxite(s) && selectedUpgradeSteelState.upgradeBaseThickness !== undefined) {
      return selectedUpgradeSteelState.upgradeBaseThickness;
    }
    return wornOutThickness;
  });

  const labels = steels.map(steel => steel.name);

  let currentThicknessWornout = currentThickness - wornOutThickness;
  if (currentThicknessWornout < 0) {
    currentThicknessWornout = 0;
  }
  const steelThicknessData = dataPoints.map(v => adjustDataPoint(currentThicknessWornout, referencePoint, v));

  const invertedSteelThicknessData = steelThicknessData.map(
    value => (currentThicknessWornout * currentThicknessWornout) / value,
  );

  const formattedThicknessData = invertedSteelThicknessData.map(value => {
    const roundedValue = roundTo(value, 3);
    return roundedValue;
  });

  return {
    labels,
    datasets: [
      {
        label: 'Thickness when worn out',
        data: steels.length > 0 ? thicknessWornOut : [],
        fill: false,
        backgroundColor: wornOutColors,
        borderColor: wornOutColors,
        borderWidth: 0,
      },
      {
        label: 'Thickness',
        data: steels.length > 0 ? formattedThicknessData : [],
        fill: false,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 0,
      },
    ],
  };
};

export const getThicknessChartOptions = (
  xAxesLabel: string,
  xAxesLabelWornOut: string,
  xAxesLabelBaseThickness: string,
  wornoutThickness: number,
  upgradeBaseThickness: number,
  disableAnimations: boolean,
  lengthUnit: string,
  steels: Array<Steel>,
  selectedCurrentSteel: Steel | undefined,
  selectedUpgradeSteel: Steel | undefined,
  selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab',
  setCurrentSteel?: (steelId: Steel) => void,
  setUpgradeSteel?: (steel: Steel | undefined) => void,
): ChartOptions => {
  return {
    ...(disableAnimations ? { animation: false } : undefined),
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: white,
        titleColor: neutralPrimary,
        bodyColor: neutralPrimary,
        cornerRadius: 0,
        borderColor: neutralTertiary,
        borderWidth: 1,
        callbacks: {
          label: tooltipItem => {
            const label =
              tooltipItem.datasetIndex === 0
                ? tooltipItem.label.indexOf('Duroxite') !== -1
                  ? xAxesLabelBaseThickness
                  : xAxesLabelWornOut
                : xAxesLabel;
            const formattedValue = parseFloat(tooltipItem.formattedValue.replace(',', '.'));
            const value =
              tooltipItem.datasetIndex === 0
                ? formattedValue
                : +tooltipItem.label.indexOf('Duroxite') !== -1
                  ? formattedValue + upgradeBaseThickness
                  : formattedValue + wornoutThickness;

            return ` ${label}: ${roundTo(value, 3)} ${lengthUnit.toLowerCase()}`;
          },
          labelColor: tooltipItem => {
            const colors = getColors(steels, selectedCurrentSteel, selectedUpgradeSteel);
            const labelColor = colors[tooltipItem.dataIndex]; // TODO dataIndex or datasetIndex
            return {
              borderColor: labelColor,
              backgroundColor: labelColor,
            };
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: `${xAxesLabel.toUpperCase()}[${lengthUnit}]`,
          font: {
            size: 13,
            style: 'normal',
          },
          color: neutralPrimary,
        },
      },
      x: {
        stacked: true,
        beginAtZero: true,
        ticks: { autoSkip: false },
      },
    },
    maintainAspectRatio: false,
    onClick: (e, _, chart) => {
      if (!setCurrentSteel || !setUpgradeSteel || !e.native) {
        return;
      }

      const elements = chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, false);
      if (elements === undefined || !elements[0]) {
        return;
      }
      if (elements[0] === undefined) {
        return;
      }

      const steel = R.clone(steels[elements[0].index]);

      if (selectedSteelTab === 'currentSteelTab') {
        setCurrentSteel(steel);
      } else if (selectedSteelTab === 'upgradeSteelTab') {
        if (selectedCurrentSteel === undefined || selectedCurrentSteel.hardnessHV > steel.hardnessHV) {
          setUpgradeSteel(undefined);
        } else {
          setUpgradeSteel(steel);
        }
      }
    },
  };
};
