import styled from 'styled-components';
import { grayHardox100 } from '../../../utils/constants/colors';
import { fontSizeSPlus } from '../../../utils/constants/typography';

export const HeatMapHeader = styled.div`
  font-size: 21px;
  flex: 1 1 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const HeatMapHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 65px;
  margin-left: 5px;
  margin-top: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: space-around;
`;

export const HeatMapHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeatMapHeaderDescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 10px;

  margin-top: -8px;
  font-style: italic;
`;

export const HeatMapHeaderText = styled.span`
  font-size: 18px;
  color: ${grayHardox100};
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

export const HeatMapWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeatMapContentWrapper = styled.div`
  flex: 4 4 100%;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  margin-left: 5px;
`;

export const HeatMapLegendWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
`;

export const HeatMapContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const BottomLabel = styled.div`
  color: ${grayHardox100};
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-right: 64px;
  font-size: ${fontSizeSPlus};
  text-transform: uppercase;
`;
