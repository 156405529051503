import { Dropdown, IDropdownOption } from '@fluentui/react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Steel, ChartType } from '../../types';
import { convertHBtoHV } from '../../utils/hardness/convertHardness';
import { CUSTOM_STEEL_ID } from '../../utils/constants/values';
import { getHighlighStyles } from '../../utils/formHighlightHelper';

const generateDropdownOptions = (steels: Array<Steel>, customSteelName: string, includeCustomSteel: boolean) => {
  const steelOptions = steels.map(steel => ({
    key: steel.id,
    text: steel.name,
  }));

  if (includeCustomSteel && steels.every(s => s.id !== CUSTOM_STEEL_ID)) {
    // Add Custom Steel option.
    steelOptions.unshift({
      key: CUSTOM_STEEL_ID,
      text: customSteelName,
    });
  }

  return steelOptions.sort((a, b) => {
    return a.key - b.key;
  });
};

const handleCurrentSteelChanged = (
  option: IDropdownOption,
  steels: Array<Steel>,
  currentSteel: Steel | undefined,
  setCurrentSteel: (steel: Steel) => void,
  customSteelName: string,
) => {
  if (option.key === CUSTOM_STEEL_ID) {
    setCurrentSteel({
      id: CUSTOM_STEEL_ID,
      name: customSteelName,
      hardness: currentSteel!.hardness,
      hardnessHV: convertHBtoHV(currentSteel!.hardness),
      includeInAbrasiveImpact: true,
    });
  } else {
    setCurrentSteel(steels.find(steel => steel.id === option.key)!);
  }
};

export const SteelSelector = injectIntl(
  ({
    intl,
    currentSteel,
    setCurrentSteel,
    steels,
    selectedChart,
    includeCustomSteel,
  }: WrappedComponentProps & {
    currentSteel: Steel | undefined;
    setCurrentSteel: (steel: Steel) => void;
    steels: Steel[];
    selectedChart: ChartType;
    includeCustomSteel: boolean;
  }) => (
    <Dropdown
      selectedKey={currentSteel === undefined ? CUSTOM_STEEL_ID : currentSteel.id}
      onChange={(_event, option) => {
        handleCurrentSteelChanged(
          option!,
          steels,
          currentSteel,
          setCurrentSteel,
          intl.formatMessage({ id: 'customSteel' }),
        );
      }}
      options={generateDropdownOptions(steels, intl.formatMessage({ id: 'customSteel' }), includeCustomSteel)}
      calloutProps={{
        calloutMaxHeight: 385,
        directionalHintFixed: true,
      }}
      styles={{
        title: getHighlighStyles(selectedChart, [
          ChartType.ServiceLife,
          ChartType.Thickness,
          ChartType.Summary,
          ChartType.HeatMap,
        ]),
      }}
      placeholder={intl.formatMessage({ id: 'selectSteel' })}
    />
  ),
);
