export const CUSTOM_STEEL_ID = -1;

export const rockSizes = [
  {
    id: 1,
    textMillimeters: '1-10 mm',
    textInches: '< 3/8 in',
    uShapeCorrectionValue: 0.7,
    uShapeEnergy: 98.2,
    boxCorrectionValue: 0.7,
    boxEnergy: 120,
    miningCorrectionValue: 0.7,
    miningEnergy: 120,
    includeInMining: true,
    includeInTipping: true,
  },
  {
    id: 2,
    textMillimeters: '11-50 mm',
    textInches: '3/8-2 in',

    uShapeCorrectionValue: 0.78,
    uShapeEnergy: 253,
    boxCorrectionValue: 0.77,
    boxEnergy: 350,
    miningCorrectionValue: 0.77,
    miningEnergy: 350,
    includeInMining: true,
    includeInTipping: true,
  },
  {
    id: 3,
    textMillimeters: '51-200 mm',
    textInches: '2-8 in',

    uShapeCorrectionValue: 0.9,
    uShapeEnergy: 353,
    boxCorrectionValue: 0.9,
    boxEnergy: 540,
    miningCorrectionValue: 0.9,
    miningEnergy: 540,
    includeInMining: true,
    includeInTipping: true,
  },
  {
    id: 4,
    textMillimeters: '201-350mm',
    textInches: '8-14 in',
    uShapeCorrectionValue: 1,
    uShapeEnergy: 589,
    boxCorrectionValue: 1,
    boxEnergy: 820,
    miningCorrectionValue: 1,
    miningEnergy: 810,
    includeInMining: true,
    includeInTipping: true,
  },
  {
    id: 5,
    textMillimeters: '351-500 mm',
    textInches: '14-20 in',
    uShapeCorrectionValue: 1.19,
    uShapeEnergy: 982,
    boxCorrectionValue: 1.24,
    boxEnergy: 1100,
    miningCorrectionValue: 1.24,
    miningEnergy: 1100,
    includeInMining: true,
    includeInTipping: true,
  },
  {
    id: 6,
    textMillimeters: '501-1000 mm',
    textInches: '20-40 in',
    uShapeCorrectionValue: 0,
    uShapeEnergy: 0,
    boxCorrectionValue: 0,
    boxEnergy: 0,
    miningCorrectionValue: 1.38,
    miningEnergy: 1390,
    includeInMining: true,
    includeInTipping: false,
  },
];
