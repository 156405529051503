export const hb = {
  min: 143,
  max: 702,
};

export const convertHBtoHV = (convertedHardnessHB: number) => {
  const cappedValue = Math.max(hb.min, Math.min(hb.max, convertedHardnessHB));

  const calculatedValue = 95515e4 / 59729 - (4e3 / 59729) * Math.pow(57026861620 - 7466125 * cappedValue, 1 / 2);

  /* This is a quick fix until we have a better conversion formula. The formula above returns a value that is to low to work with sliding calculations
  (if user enters 143). It returns 149.3 for HB value 143. Value has to be above 150. */
  if (calculatedValue > 149 && calculatedValue < 150) {
    return 150;
  }

  return calculatedValue;
};
