import { SteelCard, AbrasiveCard } from '../../components/card/Card';
import { ChartCard, ChartColumn } from '../../components/chart/ChartCard';
import { RelativeServiceLifeChart } from '../../components/chart/relative/RelativeServiceLifeChart';
import { ThicknessChart } from '../../components/chart/thickness/ThicknessChart';
import { WearRateChart } from '../../components/chart/wearRate/WearRateChart';
import { PivotItem } from '@fluentui/react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { SelectAbrasive } from '../../components/abrasive/Abrasive';
import { AbrasiveWarnings } from './AbrasiveWarnings';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { calcRelativeServiceLifeCurve, getInterpolatedServiceLife } from '../../calculations/slidingCalculations';
import { Steel, Abrasive, ChartType, CalculationType } from '../../types';
import { CalculationPageWrapper, PropertiesColumn } from '../../components/containers/Container';
import { constructSteelArray, getHardox450ReferenceSteel, getTranslatedSteels } from '../../utils/steelHelpers';
import { UpgradeSteelProperties } from '../../components/UpgradeSteelProperties';
import { Summary } from '../../components/summary/Summary';
import { CurrentSteelProperties } from '../../components/CurrentSteelProperties';
import { ChartPivot } from '../../components/chart/ChartPivot';
import { getSelectedUnitText } from '../../utils/convertUnits/convertUnits';

export const Sliding = injectIntl(({ intl }: WrappedComponentProps) => {
  return (
    <ApplicationStateConsumer>
      {({
        state: {
          slidingSteels,
          slidingProperties,
          useTimeUnit,
          selectedUnitSystem,
          selectedSteelTab,
          selectedSlidingAbrasive,
        },
        setSlidingSteels,
        setSlidingAbrasive,
        setSelectedSteelTab,
        setSlidingProperty,
        setSlidingPropertyInstant,
      }) => {
        const calculationSteels = getTranslatedSteels(
          intl,
          constructSteelArray(slidingSteels, slidingProperties.currentSteelState.currentSteel),
        );

        const { data, reference } = getSlidingServiceLifeDataPoints(
          calculationSteels.filter(s => !s.hiddenInCharts),
          selectedSlidingAbrasive,
          slidingProperties.currentSteelState.currentSteel,
        );

        return (
          <CalculationPageWrapper>
            <PropertiesColumn>
              <AbrasiveCard>
                <SelectAbrasive
                  selectedAbrasive={selectedSlidingAbrasive}
                  setCalculationModeAbrasive={setSlidingAbrasive}
                  calculationType={CalculationType.Sliding}
                />
              </AbrasiveCard>
              {selectedSlidingAbrasive && (
                <SteelCard
                  calculationProperties={
                    <CurrentSteelProperties
                      upgradeSteelState={slidingProperties.upgradeSteelState}
                      currentSteelState={slidingProperties.currentSteelState}
                      setCurrentSteelState={currentSteelState =>
                        setSlidingProperty({
                          currentSteelState,
                        })
                      }
                      setCurrentSteelStateInstant={currentSteelState =>
                        setSlidingPropertyInstant({
                          currentSteelState,
                        })
                      }
                      calculationSteels={calculationSteels}
                      includeCustomSteel={true}
                      calculationType="Sliding"
                    />
                  }
                  upgradeSteelProperties={
                    <UpgradeSteelProperties
                      upgradeSteelState={slidingProperties.upgradeSteelState}
                      setUpgradeSteelState={upgradeSteelState => setSlidingProperty({ upgradeSteelState })}
                      setUpgradeSteelStateInstant={upgradeSteelState =>
                        setSlidingPropertyInstant({ upgradeSteelState })
                      }
                      currentSteelState={slidingProperties.currentSteelState}
                      calculationSteels={calculationSteels}
                      calculationType={CalculationType.Sliding}
                    />
                  }
                  setSelectedSteelTab={setSelectedSteelTab}
                  selectedSteelTab={selectedSteelTab}
                />
              )}
            </PropertiesColumn>
            <ChartColumn>
              <ChartPivot
                displayDownloadDocX={selectedSlidingAbrasive !== undefined}
                steels={calculationSteels}
                setCalculationSteels={(steels: Array<Steel>) => {
                  setSlidingSteels(steels);
                }}
              >
                <PivotItem itemKey={ChartType.ServiceLife} headerText={intl.formatMessage({ id: 'serviceLife' })}>
                  <ChartCard>
                    <RelativeServiceLifeChart
                      currentSteelState={slidingProperties.currentSteelState}
                      upgradeSteelState={slidingProperties.upgradeSteelState}
                      data={data}
                      reference={reference}
                      steels={calculationSteels.filter(s => !s.hiddenInCharts)}
                      useTimeUnit={useTimeUnit}
                      setCurrentSteel={steel =>
                        setSlidingPropertyInstant({
                          currentSteelState: { currentSteel: steel },
                        })
                      }
                      selectedSteelTab={selectedSteelTab}
                      setUpgradeSteel={steel =>
                        setSlidingPropertyInstant({
                          upgradeSteelState: { upgradeSteel: steel },
                        })
                      }
                      chartTitleSuffix={
                        slidingProperties.currentSteelState.currentThickness +
                        ' ' +
                        getSelectedUnitText(selectedUnitSystem, intl)
                      }
                    />
                  </ChartCard>
                </PivotItem>
                <PivotItem itemKey={ChartType.Thickness} headerText={intl.formatMessage({ id: 'thickness' })}>
                  <ChartCard>
                    <ThicknessChart
                      currentSteelState={slidingProperties.currentSteelState}
                      upgradeSteelState={slidingProperties.upgradeSteelState}
                      data={data}
                      reference={reference}
                      steels={calculationSteels.filter(s => !s.hiddenInCharts)}
                      selectedUnitSystem={selectedUnitSystem}
                      setCurrentSteel={steel =>
                        setSlidingProperty({
                          currentSteelState: { currentSteel: steel },
                        })
                      }
                      selectedSteelTab={selectedSteelTab}
                      setUpgradeSteel={steel =>
                        setSlidingProperty({
                          upgradeSteelState: { upgradeSteel: steel },
                        })
                      }
                    />
                  </ChartCard>
                </PivotItem>
                <PivotItem itemKey={ChartType.WearRate} headerText={intl.formatMessage({ id: 'wearRate' })}>
                  <ChartCard>
                    <WearRateChart selectedAbrasive={selectedSlidingAbrasive} />
                  </ChartCard>
                </PivotItem>
                <PivotItem itemKey={ChartType.Summary} headerText={intl.formatMessage({ id: 'summary' })}>
                  <ChartCard style={{ userSelect: 'auto' }}>
                    <Summary
                      currentSteelState={slidingProperties.currentSteelState}
                      upgradeSteelState={slidingProperties.upgradeSteelState}
                      selectedUnitSystem={selectedUnitSystem}
                    />
                  </ChartCard>
                </PivotItem>
              </ChartPivot>

              <AbrasiveWarnings selectedAbrasive={selectedSlidingAbrasive} />
            </ChartColumn>
          </CalculationPageWrapper>
        );
      }}
    </ApplicationStateConsumer>
  );
});

// Helpers

export const getSlidingServiceLifeDataPoints = (
  steels: Array<Steel>,
  selectedAbrasive: Abrasive | undefined,
  currentSteel: Steel | undefined,
): { data: Array<number>; reference: number | null } => {
  if (selectedAbrasive === undefined || currentSteel === undefined) {
    return { data: [], reference: null };
  }

  const curve = calcRelativeServiceLifeCurve(selectedAbrasive);
  const reference = getInterpolatedServiceLife(curve, currentSteel.hardnessHV);
  const data = steels.map(s => getInterpolatedServiceLife(curve, s.hardnessHV)).map(d => (d !== null ? d : 0));

  return { data, reference };
};

export const getNormalizedSlidingServiceLifePoints = (calculationSteels: Steel[], selectedAbrasive: Abrasive) => {
  const { data: slidingData, reference: slidingRef } = getSlidingServiceLifeDataPoints(
    calculationSteels,
    selectedAbrasive,
    getHardox450ReferenceSteel(),
  );

  /* Normalize sliding data. */
  return slidingData.map(d => (slidingRef ? d / slidingRef : d));
};
