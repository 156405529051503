import React, { Component } from 'react';
import { TextField } from '@fluentui/react';
import { getHighlighStyles } from '../../utils/formHighlightHelper';
import { RouteStateConsumer } from '../../state/routing/routeState';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { ChartType, Route } from '../../types';
import { inputErrorColor } from '../../utils/constants/colors';

type NumberInputProps = {
  value: number;
  numberOfDecimals?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  onChange: (value: number) => void;
  chartsToHighlight: Array<ChartType>;
  routesToHighlight?: Array<Route>;
  suffix: string;
};
export class NumberInput extends Component<NumberInputProps, { currentValue: string | undefined }> {
  constructor(props: NumberInputProps) {
    super(props);

    this.state = {
      currentValue: (this.props.value || 0).toString(),
    };
  }

  inputRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: NumberInputProps) {
    if (this.props.value != null) {
      if (this.props.value !== prevProps.value) {
        const inputHasFocus = this.inputRef.current && this.inputRef.current.querySelector('input:focus');

        if (!inputHasFocus) {
          this.setState({
            currentValue: undefined,
          });
        }
      }
    }
  }

  isOutOfBounds(currentValue: number, minValue: number, maxValue: number) {
    return maxValue! < currentValue || minValue! > currentValue;
  }

  render() {
    const {
      numberOfDecimals = 0,
      minValue = 0,
      maxValue = Number.MAX_SAFE_INTEGER,
      suffix,
      disabled = false,
    } = this.props;

    const inputValue = this.state.currentValue === undefined ? this.props.value.toString() : this.state.currentValue;

    const isOutOfBounds = isNaN(+inputValue) || this.isOutOfBounds(+inputValue, minValue, maxValue);

    return (
      <RouteStateConsumer>
        {({ state: { currentRoute } }) => (
          <ApplicationStateConsumer>
            {({ state: { selectedChart } }) => (
              <div ref={this.inputRef}>
                <TextField
                  value={inputValue}
                  disabled={disabled}
                  suffix={suffix || undefined}
                  onChange={(_, inputValue) => {
                    let formattedInputValue = inputValue!
                      .replace(/[^0-9$.$,]/g, '')
                      .replace(',', '.')
                      .replace(/^([^.]*\.)(.*)$/, function (_fullString, beforeDot, afterDot) {
                        return beforeDot + afterDot.replace(/\./g, '').slice(0, numberOfDecimals);
                      })
                      .replace('.', function () {
                        return numberOfDecimals === 0 ? '' : '.';
                      });

                    if (formattedInputValue.startsWith('.')) {
                      formattedInputValue = formattedInputValue.replace('.', '0.');
                    }

                    if (
                      isNaN(+formattedInputValue) ||
                      formattedInputValue === '' ||
                      formattedInputValue.endsWith('.') ||
                      (formattedInputValue.includes('.') && formattedInputValue.endsWith('0'))
                    ) {
                      this.setState({
                        currentValue: formattedInputValue,
                      });
                    } else {
                      this.props.onChange(+formattedInputValue);
                      this.setState({
                        currentValue: undefined,
                      });
                    }
                  }}
                  styles={{
                    suffix: {
                      textTransform: 'lowercase',
                      maxWidth: '30%',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    },
                    fieldGroup: !isOutOfBounds
                      ? getHighlighStyles(
                          selectedChart,
                          this.props.chartsToHighlight,
                          this.props.routesToHighlight,
                          currentRoute,
                        )
                      : { backgroundColor: inputErrorColor },

                    errorMessage: {
                      visibility: !isOutOfBounds ? '' : 'hidden',
                      position: 'absolute',
                    },
                  }}
                />
              </div>
            )}
          </ApplicationStateConsumer>
        )}
      </RouteStateConsumer>
    );
  }
}
