import styled from 'styled-components';
import { Column } from '../../../components/containers/Column';
import { Row } from '../../../components/containers/Row';
import { CalloutHeader } from '../../labels/Labels';
import { InputLabel } from '../../../components/labels/Labels';
import { Dropdown } from '@fluentui/react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { LanguageStateConsumer } from '../../../state/language/languageState';
import { ApplicationStateConsumer } from '../../../state/applicationState';
import { UnitSystemOption, CurrentMiningSteelState, UpgradeMiningSteelState } from '../../../types';
import { starLanguageId } from '../../../state/language/languages';
import {
  CalculationModelStateUpdater,
  CalculationModelState,
  ImpactPropertiesState,
} from '../../../state/calculationModels';
import { MiningPropertiesState } from '../../../state/miningProperties';

const ContentWrapper = styled.div`
  width: 300px;
  padding: 20px;
`;

const SettingInputLabel = styled(InputLabel)`
  margin-top: 10px;
`;

export const SettingsCalloutContent = injectIntl(({ intl }: WrappedComponentProps) => (
  <ContentWrapper>
    <CalloutHeader>
      <FormattedMessage id="settings" />
    </CalloutHeader>
    <Row>
      <Column $size={12}>
        <SettingInputLabel style={{ marginTop: 0 }}>
          <FormattedMessage id="language" />
        </SettingInputLabel>
      </Column>
      <Column $size={12}>
        <LanguageStateConsumer>
          {({ state: { languages, selectedLanguage }, setLanguage }) => (
            <Dropdown
              selectedKey={selectedLanguage && selectedLanguage.id}
              onChange={(_, option) => {
                if (!option) {
                  return;
                }
                setLanguage(languages.find(language => language.id === option.key)!);
              }}
              options={languages
                .filter(language => (import.meta.env.DEV ? true : language.id !== starLanguageId))
                .map(language => ({
                  key: language.id,
                  text: language.name,
                }))}
              calloutProps={{
                calloutMaxHeight: 385,
                directionalHintFixed: true,
              }}
            />
          )}
        </LanguageStateConsumer>
      </Column>
      <Column $size={12}>
        <SettingInputLabel>
          <FormattedMessage id="unitSystem" />
        </SettingInputLabel>
      </Column>
      <Column $size={12}>
        <ApplicationStateConsumer>
          {({
            state: {
              unitSystems,
              selectedUnitSystem,
              slidingProperties,
              impactProperties,
              erosionProperties,
              tippingProperties,
              miningProperties,
            },
            setUnitSystem,
            setSlidingPropertyInstant,
            setImpactPropertyInstant,
            setErosionPropertyInstant,
            setTippingPropertyInstant,
            setMiningPropertyInstant,
          }) => (
            <>
              <Dropdown
                selectedKey={selectedUnitSystem}
                onChange={(_, option) => {
                  if (!option) {
                    return;
                  }
                  setUnitSystem(option.key as UnitSystemOption);

                  resetMetricValues(
                    option.key as UnitSystemOption,
                    setSlidingPropertyInstant,
                    slidingProperties,
                    setImpactPropertyInstant,
                    impactProperties,
                    setErosionPropertyInstant,
                    erosionProperties,
                    setTippingPropertyInstant,
                    tippingProperties,
                    setMiningPropertyInstant,
                    miningProperties,
                  );
                }}
                options={unitSystems.map(unitSystem => ({
                  key: unitSystem,
                  text: intl.formatMessage({ id: unitSystem }),
                }))}
                calloutProps={{
                  calloutMaxHeight: 385,
                  directionalHintFixed: true,
                }}
              />
            </>
          )}
        </ApplicationStateConsumer>
      </Column>
    </Row>
  </ContentWrapper>
));

// Helpers

const round = (value: number) => Math.round(10 * value) / 10;

const resetMetricValues = (
  newUnitSystem: UnitSystemOption,
  setSlidingPropertyInstant: CalculationModelStateUpdater<CalculationModelState>,
  slidingProperties: CalculationModelState,
  setImpactPropertyInstant: CalculationModelStateUpdater<CalculationModelState & ImpactPropertiesState>,
  impactProperties: CalculationModelState & ImpactPropertiesState,
  setErosionPropertyInstant: CalculationModelStateUpdater<CalculationModelState>,
  erosionProperties: CalculationModelState,
  setTippingPropertyInstant: CalculationModelStateUpdater<CalculationModelState>,
  tippingProperties: CalculationModelState,
  setMiningPropertyInstant: CalculationModelStateUpdater<MiningPropertiesState>,
  miningProperties: MiningPropertiesState,
) => {
  if (newUnitSystem !== 'metric') {
    return;
  }

  // Round all metric values to 1 decimal

  // Sliding
  setSlidingPropertyInstant({
    currentSteelState: {
      currentThickness: round(slidingProperties.currentSteelState.currentThickness),
      wornOutThickness: round(slidingProperties.currentSteelState.wornOutThickness),
    },
    upgradeSteelState: {
      upgradeThickness: round(slidingProperties.upgradeSteelState.upgradeThickness),
    },
  });

  // Impact
  setImpactPropertyInstant({
    currentSteelState: {
      currentThickness: round(impactProperties.currentSteelState.currentThickness),
      wornOutThickness: round(impactProperties.currentSteelState.wornOutThickness),
    },
    upgradeSteelState: {
      upgradeThickness: round(impactProperties.upgradeSteelState.upgradeThickness),
    },
    abrasiveProperties: {
      size: round(impactProperties.abrasiveProperties.size),
    },
  });

  // Erosion
  setErosionPropertyInstant({
    currentSteelState: {
      currentThickness: round(erosionProperties.currentSteelState.currentThickness),
      wornOutThickness: round(erosionProperties.currentSteelState.wornOutThickness),
    },
    upgradeSteelState: {
      upgradeThickness: round(erosionProperties.upgradeSteelState.upgradeThickness),
    },
  });

  // Tipping
  setTippingPropertyInstant({
    currentSteelState: {
      currentThickness: round(tippingProperties.currentSteelState.currentThickness),
      wornOutThickness: round(tippingProperties.currentSteelState.wornOutThickness),
    },
    upgradeSteelState: {
      upgradeThickness: round(tippingProperties.upgradeSteelState.upgradeThickness),
    },
  });

  // Mining
  setMiningPropertyInstant({
    currentMiningSteelsState: miningProperties.currentMiningSteelsState.map((steel: CurrentMiningSteelState) => {
      return { ...steel, steelThickness: round(steel.steelThickness) };
    }),
    upgradeMiningSteelsState: miningProperties.upgradeMiningSteelsState.map((steel: UpgradeMiningSteelState) => {
      return { ...steel, steelThickness: round(steel.steelThickness) };
    }),
    wornOutThickness: round(miningProperties.wornOutThickness),
  });
};
