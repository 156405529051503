import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Dropdown } from '@fluentui/react';
import { ChartType, Route, TipperType } from '../../types';
import { getHighlighStyles } from '../../utils/formHighlightHelper';

const generateTipperTypeDropdownOptions = () => {
  return [
    { key: TipperType.UShape, text: 'U-Shape' },
    { key: TipperType.Box, text: 'Box' },
  ];
};

export const TipperTypeDropdown = injectIntl(
  (
    props: {
      tipperType: TipperType;
      onChange: (tipperType: TipperType) => void;
      chartsToHighLight: ChartType[];
      selectedChart: ChartType;
      calculationRoute: Route;
    } & WrappedComponentProps,
  ) => (
    <>
      <Dropdown
        selectedKey={props.tipperType.toString()}
        onChange={(_event, option) => {
          if (option!.key === TipperType.UShape) {
            props.onChange(TipperType.UShape);
          } else if (option!.key === TipperType.Box) {
            props.onChange(TipperType.Box);
          }
        }}
        options={generateTipperTypeDropdownOptions()}
        calloutProps={{
          calloutMaxHeight: 385,
          directionalHintFixed: true,
        }}
        styles={{
          title: getHighlighStyles(props.selectedChart, props.chartsToHighLight),
        }}
      />
    </>
  ),
);
