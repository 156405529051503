import * as React from 'react';
import * as R from 'ramda';
import { CalculationType } from '../../../types';
import { neutralLighter } from '../../../utils/constants/colors';

const createStripedPattern = (slope: number = 1) => {
  const can = document.createElement('canvas');
  const ctx = can.getContext('2d');

  const spacing = 4;
  const color = 'red';
  const lineWidth = 2;

  const len = Math.hypot(1, slope);

  const w = (can.width = (1 / len + spacing + 0.5) | 0); // round to nearest pixel
  const h = (can.height = (slope / len + spacing * slope + 0.5) | 0);
  if (ctx) {
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, can.width, can.height);

    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();

    // Line through top left and bottom right corners
    ctx.moveTo(0, 0);
    ctx.lineTo(w, h);
    // Line through top right corner to add missing pixels
    ctx.moveTo(0, -h);
    ctx.lineTo(w * 2, h);
    // Line through bottom left corner to add missing pixels
    ctx.moveTo(-w, 0);
    ctx.lineTo(w, h * 2);

    ctx.stroke();
    return ctx.createPattern(can, 'repeat');
  }
};

const fillWithPattern = (canvas: HTMLCanvasElement, pattern: CanvasPattern, inset = 0) => {
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.clearRect(inset, inset, canvas.width - inset * 2, canvas.height - inset * 2);
    ctx.fillStyle = pattern;
    ctx.fillRect(inset, inset, canvas.width - inset * 2, canvas.height - inset * 2);
  }
  return canvas;
};

type HeatMapMatrix = number[][];

const lerp = (fromRange: [number, number], toRange: [number, number]) => (value: number) => {
  const [fromMin, fromMax] = fromRange;
  const [toMin, toMax] = toRange;
  const cappedValue = Math.max(fromMin, Math.min(fromMax, value));
  const normalizedValue = (cappedValue - fromMin) / (fromMax - fromMin);
  return Math.round(normalizedValue * (toMax - toMin) + toMin);
};
function select<T>(breakPoint: number, lower: (value: number) => T, upper: (value: number) => T) {
  return (value: number) => (value < breakPoint ? lower(value) : upper(value));
}
const lerpWearToRed = lerp([0, 1], [0, 255]);
const lerpWearToGreen = select(0.6, lerp([0.25, 0.6], [0, 255]), lerp([0.6, 1], [255, 0]));
const lerpWearToBlue = select(0.5, lerp([0, 0.5], [80, 255]), () => 0);
export const wearToColor =
  () =>
  (wear: number): string => {
    if (wear < 0) {
      return `rgb(255, 255, 255)`;
    }

    if (wear >= 1) {
      return 'rgba(0,0,0,0)';
    }
    return `rgb(${lerpWearToRed(wear)}, ${lerpWearToGreen(wear)}, ${lerpWearToBlue(wear)})`;
  };

export const wearToColorLegend = (wear: number) => {
  return `rgb(${lerpWearToRed(wear)}, ${lerpWearToGreen(wear)}, ${lerpWearToBlue(wear)})`;
};

export const antializeValue = (nextRatio: number, value: number, nextValue: number) =>
  value * (1 - nextRatio) + nextValue * nextRatio;

export function antializeTimes<T>(times: number, getValue: (nextRatio: number) => T) {
  const newValues: T[] = [];

  for (let time = 0; time < times; time++) {
    const nextRatio = time / times;
    newValues.push(getValue(nextRatio));
  }
  return newValues;
}
export const antialize1d = (times: number, data: number[]) =>
  data.reduce((antialized: number[], value, i) => {
    if (i === data.length - 1) {
      antialized.push(value);
    } else {
      const nextValue = data[i + 1];

      return [...antialized, ...antializeTimes(times, ratio => antializeValue(ratio, value, nextValue))];
    }
    return antialized;
  }, []);
export const antialize2d = (times: number, data: HeatMapMatrix) => {
  const halfAntiAlized = data.reduce((antialized: HeatMapMatrix, col, i) => {
    if (i === data.length - 1) {
      antialized.push(col);
    } else {
      const nextCol = data[i + 1];

      return [
        ...antialized,
        ...antializeTimes(times, ratio => col.map((value, j) => antializeValue(ratio, value, nextCol[j]))),
      ];
    }
    return antialized;
  }, []);

  return halfAntiAlized.map(col => antialize1d(times, col));
};

function renderHeatMap(canvas: HTMLCanvasElement, data: Array<HeatMapMatrix>) {
  if (data.length === 0) {
    return;
  }

  let antilizedArray = [];
  for (const matrix of data) {
    antilizedArray.push(antialize2d(2, matrix));
  }
  antilizedArray = antilizedArray.flat();
  const colorMap = antilizedArray.map(col => col.map(wearToColor()));

  const { width, height } = canvas;
  const ctx = canvas.getContext('2d')!;
  ctx.clearRect(0, 0, width, height);
  const canvasPattern = createStripedPattern();
  if (canvasPattern) {
    fillWithPattern(canvas, canvasPattern);
  }

  if (colorMap.length === 0 || colorMap[0].length === 0) return;

  const yCenter = height / 2;
  const xSize = width / colorMap.length;
  const ySize = yCenter / colorMap[0].length;

  colorMap.forEach((colors, xIndex) => {
    colors.forEach((color, yIndex) => {
      const x = xIndex * xSize;
      const y = yIndex * ySize;

      ctx.fillStyle = color;
      ctx.fillRect(x, y, xSize + 1, ySize + 1);
      ctx.fillRect(x, height - y - ySize, xSize + 1, ySize + 1);
    });
  });
}

function renderVirtualSideLines(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d')!;
  ctx.beginPath();
  ctx.strokeStyle = 'rgb(255, 255, 255)';
  ctx.lineWidth = 1;
  ctx.setLineDash([7, 14]);
  ctx.moveTo(0, canvas.height / 5);
  ctx.lineTo(canvas.width, canvas.height / 5);
  ctx.moveTo(0, canvas.height - canvas.height / 5);
  ctx.lineTo(canvas.width, canvas.height - canvas.height / 5);
  ctx.stroke();
}

function renderTipperHeatMapSide(canvas: HTMLCanvasElement, data: Array<HeatMapMatrix>) {
  if (data.length === 0) {
    return;
  }

  let antilizedArray = [];
  for (const matrix of data) {
    antilizedArray.push(antialize2d(4, matrix));
  }
  antilizedArray = antilizedArray.flat();
  const colorMap = antilizedArray.map(col => col.map(wearToColor()));

  const { width, height } = canvas;
  const ctx = canvas.getContext('2d')!;
  ctx.clearRect(0, 0, width, height);
  const canvasPattern = createStripedPattern(2);
  if (canvasPattern) {
    fillWithPattern(canvas, canvasPattern);
  }

  if (colorMap.length === 0 || colorMap[0].length === 0) return;

  const xSize = width / colorMap.length;
  const ySize = height / colorMap[0].length;

  colorMap.forEach((colors, xIndex) => {
    const x = xIndex * xSize;

    colors.forEach((color, yIndex) => {
      const y = yIndex * ySize;

      ctx.fillStyle = color;

      ctx.fillRect(x, y, xSize + 1, ySize + 1);
    });
  });
}

function renderMiningHeatMapSide(canvas: HTMLCanvasElement, data: Array<HeatMapMatrix>, side: 'left' | 'right') {
  if (data.length === 0) {
    return;
  }

  let antilizedArray = [];
  for (const matrix of data) {
    antilizedArray.push(antialize2d(4, matrix));
  }
  antilizedArray = antilizedArray.flat();
  const colorMap = antilizedArray.map(col => col.map(wearToColor()));

  const { width, height } = canvas;
  const ctx = canvas.getContext('2d')!;
  ctx.clearRect(0, 0, width, height);
  const canvasPattern = createStripedPattern(2);
  if (canvasPattern) {
    fillWithPattern(canvas, canvasPattern);
  }

  if (colorMap.length === 0 || colorMap[0].length === 0) return;

  const xSize = width / colorMap.length;
  const ySize = height / colorMap[0].length;
  const inclination = canvas.clientHeight / canvas.clientWidth;

  let rowCounter = 0;
  let highestYMax = 0;
  colorMap.forEach((colors, xIndex) => {
    const x = xIndex * xSize;

    let valueCounter = 0;
    // If side is left, start at array end and decrease valueCounter to 0.
    if (side === 'left') {
      valueCounter = colors.length;
    }

    colors.forEach((color, yIndex) => {
      const y = yIndex * ySize;

      if (rowCounter > 30) {
        // Does /2.5 here to decrease the inclination.
        const yMax = (inclination / 2.5) * xIndex + highestYMax;

        if (valueCounter > yMax) {
          ctx.fillStyle = neutralLighter;
        } else {
          ctx.fillStyle = color;
        }
      } else {
        // Does *5 here to increase the inclination for the first part.
        const yMax = inclination * xIndex * 5;

        // Gets set to the last value before rowCounter > 30 and uses that value to start the new line.
        highestYMax = yMax;

        if (valueCounter > yMax) {
          ctx.fillStyle = neutralLighter;
        } else {
          ctx.fillStyle = color;
        }
      }

      ctx.fillRect(x, y, xSize + 1, ySize + 1);
      if (side === 'left') {
        valueCounter--;
      } else {
        valueCounter++;
      }
    });
    rowCounter++;
  });
}

export class HeatMap extends React.Component<{
  data: Array<HeatMapMatrix>;
  heightInPixels: number;
  renderSides: boolean;
  virtualSides?: boolean;
}> {
  canvas: HTMLCanvasElement | null = null;
  drawOnNextRender = false;

  renderHeatMap() {
    if (this.canvas) {
      this.drawOnNextRender = false;
      renderHeatMap(this.canvas, this.props.data);
    } else {
      this.drawOnNextRender = true;
    }
  }

  renderVirtualSideLines() {
    if (this.props.virtualSides) {
      if (this.canvas && this.props.heightInPixels) {
        renderVirtualSideLines(this.canvas);
      }
    }
  }

  componentDidMount() {
    this.renderHeatMap();
    this.renderVirtualSideLines();
  }

  componentDidUpdate(prevProps: this['props']) {
    if (!R.equals(this.props.data, prevProps.data)) {
      this.renderHeatMap();
      this.renderVirtualSideLines();
    }
  }

  render() {
    return (
      <canvas
        ref={e => {
          this.canvas = e;
          if (this.drawOnNextRender) {
            this.renderHeatMap();
            this.renderVirtualSideLines();
          }
        }}
        style={{
          width: '100%',
          height: this.props.heightInPixels,
        }}
      />
    );
  }
}

export class HeatMapSide extends React.Component<{
  data: Array<HeatMapMatrix>;
  heightInPixels: number;
  side: 'left' | 'right';
  calculationType: CalculationType.Mining | CalculationType.Tipping;
}> {
  canvas: HTMLCanvasElement | null = null;
  drawOnNextRender = false;

  renderHeatMap() {
    if (this.canvas) {
      this.drawOnNextRender = false;

      if (this.props.calculationType === CalculationType.Tipping) {
        renderTipperHeatMapSide(this.canvas, this.props.data);
      } else {
        renderMiningHeatMapSide(this.canvas, this.props.data, this.props.side);
      }
    } else {
      this.drawOnNextRender = true;
    }
  }

  componentDidMount() {
    this.renderHeatMap();
  }

  componentDidUpdate(prevProps: this['props']) {
    if (!R.equals(this.props.data, prevProps.data)) {
      this.renderHeatMap();
    }
  }

  render() {
    return (
      <canvas
        ref={e => {
          this.canvas = e;
          if (this.drawOnNextRender) {
            this.renderHeatMap();
          }
        }}
        style={{
          width: '100%',
          height: this.props.heightInPixels,
        }}
      />
    );
  }
}
