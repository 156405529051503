import * as React from 'react';
import { Language } from '../state/language/languages';

type LocalState = {
  heatMap: React.JSX.Element | null;
};

type ThrottleChangesProps<T> = {
  data: T;
  selectedLanguage: Language;
  children: (properties: T) => React.JSX.Element;
};

export class ThrottleChanges<T extends Array<unknown>> extends React.Component<ThrottleChangesProps<T>, LocalState> {
  renderTimer: NodeJS.Timeout | undefined;

  constructor(props: ThrottleChangesProps<T>) {
    super(props);

    this.state = {
      heatMap: null,
    };
  }

  componentDidMount() {
    this.setState({
      heatMap: this.props.children(this.props.data),
    });
  }

  componentDidUpdate(prevProps: this['props']) {
    if (this.props.selectedLanguage !== prevProps.selectedLanguage) {
      this.setState({
        heatMap: this.props.children(this.props.data),
      });
    }

    if (this.props.data.some((element, idx) => element !== prevProps.data[idx])) {
      if (this.renderTimer !== undefined) {
        clearTimeout(this.renderTimer);
      }

      this.renderTimer = setTimeout(() => {
        this.setState({
          heatMap: this.props.children(this.props.data),
        });
        this.renderTimer = undefined;
      }, 500);
    }
  }

  componentWillUnmount() {}

  render() {
    return this.state.heatMap;
  }
}
