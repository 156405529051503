import { ReactChild, Children, isValidElement } from 'react';
import { Pivot, IPivotItemProps } from '@fluentui/react';
import { ChartType, Steel } from '../../types';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { DownloadDocx } from '../../components/docx/DownloadDocx';
import { SteelToggle } from './SteelToggle';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export const ChartPivot = injectIntl(
  ({
    intl,
    displayDownloadDocX,
    steels,
    setCalculationSteels,
    children,
  }: WrappedComponentProps & {
    displayDownloadDocX?: boolean;
    steels?: Array<Steel>;
    setCalculationSteels?: (steels: Array<Steel>) => void;
    children: ReactChild | ReactChild[];
  }) => (
    <ApplicationStateConsumer>
      {({ state: { selectedChart }, setSelectedChart }) => {
        let hasSelectedChart = false;
        let firstChartType: ChartType | undefined = undefined;
        Children.map(children, child => {
          if (isValidElement(child)) {
            if ((child.props as IPivotItemProps).itemKey === selectedChart) {
              hasSelectedChart = true;
            }
            if (firstChartType === undefined && (child.props as IPivotItemProps).itemKey) {
              firstChartType = (child.props as IPivotItemProps).itemKey as ChartType;
            }
          }
          return child;
        });

        if (!hasSelectedChart && firstChartType) {
          if (firstChartType !== selectedChart) {
            setTimeout(() => setSelectedChart(firstChartType!));
          }

          selectedChart = firstChartType;
        }

        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: -32,
                height: 32,
              }}
            >
              <div style={{ display: 'flex' }}>
                {steels && setCalculationSteels && (
                  <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                    <SteelToggle
                      steelsTranslation={intl.formatMessage({ id: 'steels' })}
                      steels={steels}
                      setCalculationSteels={setCalculationSteels}
                    />
                  </div>
                )}
                {displayDownloadDocX && (
                  <div>
                    <DownloadDocx />
                  </div>
                )}
              </div>
            </div>
            <Pivot
              className="chart-pivot"
              onLinkClick={pivotItem => {
                const clickedKey = pivotItem!.props.itemKey;

                setSelectedChart(clickedKey! as ChartType);
              }}
              linkFormat={'tabs'}
              defaultSelectedKey={selectedChart}
              styles={{
                root: {
                  width: 100,
                },
              }}
            >
              {children}
            </Pivot>
          </>
        );
      }}
    </ApplicationStateConsumer>
  ),
);
