import * as R from 'ramda';
import {
  calcRelativeWareRateCurve,
  getRelativeWareRateEndCurveVector,
} from '../../../calculations/slidingCalculations';
import { PLATE_HARDNESS_HB_CHART, PLASTIC_CURVE_CHART, CUTTING_CURVE_CHART } from '../../../calculations/constants';
import { redHardox, grayHardox100 } from '../../../utils/constants/colors';
import { Abrasive } from '../../../types';
import { neutralTertiary, neutralPrimary, white } from '../../../utils/constants/colors';
import { ChartOptions } from 'chart.js';
import { roundTo } from '../../../utils/numberFormatting';

// Round all values in chart to 2 decimals.
const addXValues = (values: number[]) =>
  R.zipWith((value, hardness) => ({ y: roundTo(value, 2), x: hardness }), values, PLATE_HARDNESS_HB_CHART);

export const getWearRateData = (selectedAbrasive: Abrasive | undefined) => {
  const wearRateDataArray = getWearRateDataArray(selectedAbrasive);

  return {
    datasets: [
      {
        label: 'Wear Rate',
        data: addXValues(wearRateDataArray),
        fill: false,
        borderColor: '#333333',
        borderWidth: 2,
        lineTension: 0.1,
      },
      {
        label: 'Cutting',
        data: addXValues(CUTTING_CURVE_CHART),
        fill: false,
        borderColor: redHardox,
        borderWidth: 2,
        borderDash: [5, 5],
        lineTension: 0.1,
        pointHitRadius: 0,
      },
      {
        label: 'Plastic',
        data: addXValues(PLASTIC_CURVE_CHART),
        fill: false,
        borderColor: grayHardox100,
        borderWidth: 2,
        borderDash: [5, 5],
        lineTension: 0.1,
        pointHitRadius: 0,
      },
    ],
  };
};

export const getWearRateOptions = (
  xAxesLabel: string,
  yAxesLabel: string,
  disableAnimations: boolean,
): ChartOptions => {
  return {
    ...(disableAnimations ? { animation: false } : undefined),
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: white,
        titleColor: neutralPrimary,
        bodyColor: neutralPrimary,
        cornerRadius: 0,
        borderColor: neutralTertiary,
        borderWidth: 1,

        callbacks: {
          title: tooltipItem => {
            // TODO: avrunda till heltal för HB
            return `${xAxesLabel}[HB]: ${tooltipItem[0].label}`;
          },
          label: tooltipItem => {
            return `${yAxesLabel}: ${tooltipItem.formattedValue}`;
          },
        },
      },
    },
    hover: {
      mode: 'nearest',
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 10,
      },
    },
    scales: {
      x: {
        type: 'linear',
        min: 140,
        max: 850,
        ticks: {
          stepSize: 50,
        },
        title: {
          display: true,
          text: `${xAxesLabel.toUpperCase()}[HB]`,
          font: {
            size: 13,
            style: 'normal',
          },
          color: neutralPrimary,
        },
      },
      y: {
        min: 0,
        max: 3,
      },
    },
    maintainAspectRatio: false,
  };
};

// Helpers

const getWearRateDataArray = (selectedAbrasive: Abrasive | undefined) => {
  if (selectedAbrasive === undefined) {
    return [];
  }

  const wareRateCurve = calcRelativeWareRateCurve(selectedAbrasive);
  // Interpolate result for chart.
  return getRelativeWareRateEndCurveVector(wareRateCurve);
};
