import { ChartWrapper } from '../ChartWrapper';
import { Chart } from 'react-chartjs-2';
import { getThicknessChartData, getThicknessChartOptions } from './thicknessChartHelpers';
import { Steel, UnitSystemOption, CurrentSteelState, UpgradeSteelState } from '../../../types';
import { injectIntl, IntlShape } from 'react-intl';
import { ChartCardHeader } from '../ChartCardHeader';
import { getLengthUnit } from '../../../utils/unitSystem/unitSystemHelpers';

// // Workaround to missing 'id' interface prop bug.
// declare module "react-chartjs-2" {
//   export interface ChartComponentProps {
//     id?: string;
//   }
// }

type ThicknessChartProps = {
  currentSteelState: CurrentSteelState;
  upgradeSteelState: UpgradeSteelState;
  intl: IntlShape;
  data: Array<number>;
  reference: number | null;
  steels: Array<Steel>;
  disableAnimations?: boolean;
  selectedUnitSystem: UnitSystemOption;
  selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab';
  setUpgradeSteel?: (steel: Steel | undefined) => void;
  setCurrentSteel?: (steel: Steel) => void;
};

export const ThicknessChart = injectIntl(
  ({
    currentSteelState,
    upgradeSteelState,
    intl,
    data,
    reference,
    steels,
    disableAnimations = false,
    selectedUnitSystem,
    setCurrentSteel,
    selectedSteelTab,
    setUpgradeSteel,
  }: ThicknessChartProps) => {
    const { currentSteel, currentThickness, wornOutThickness } = currentSteelState;
    const xAxesLabel = `${intl.formatMessage({ id: 'thickness' })}`;
    const xAxesLabelWornOut = `${intl.formatMessage({
      id: 'wornoutThickness',
    })}`;
    const xAxesLabelBaseThickness = `${intl.formatMessage({
      id: 'baseThickness',
    })}`;
    const lengthUnit = getLengthUnit(intl, selectedUnitSystem);

    return (
      <>
        <ChartCardHeader messageId="thickness" />
        <ChartWrapper>
          {/* <ForceRerenderOnSizeChange> */}
          <Chart
            type="bar"
            id="thickness-chart"
            data={getThicknessChartData(
              steels,
              currentSteel,
              upgradeSteelState,
              data,
              reference,
              currentThickness,
              wornOutThickness,
            )}
            options={getThicknessChartOptions(
              xAxesLabel,
              xAxesLabelWornOut,
              xAxesLabelBaseThickness,
              wornOutThickness,
              upgradeSteelState.upgradeBaseThickness || 0,
              disableAnimations,
              lengthUnit,
              steels,
              currentSteel,
              upgradeSteelState.upgradeSteel,
              selectedSteelTab,
              setCurrentSteel,
              setUpgradeSteel,
            )}
          />
          {/* </ForceRerenderOnSizeChange> */}
        </ChartWrapper>
      </>
    );
  },
);
