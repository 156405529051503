import { ChartWrapper } from '../ChartWrapper';
import { Chart } from 'react-chartjs-2';
import { getWearRateData, getWearRateOptions } from './wearRateChartHelpers';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ChartCardHeader } from '../ChartCardHeader';
import { Legends } from './Legends';
import { Abrasive } from '../../../types';

type ThicknessChartProps = WrappedComponentProps & {
  disableAnimations?: boolean;
  selectedAbrasive: Abrasive | undefined;
};
//TODO remove ForceRerender
export const WearRateChart = injectIntl(
  ({ intl, disableAnimations = false, selectedAbrasive }: ThicknessChartProps) => {
    const xAxesLabel = `${intl.formatMessage({ id: 'hardness' })}`;
    const yAxesLabel = `${intl.formatMessage({ id: 'wearRate' })}`;

    return (
      <>
        <ChartCardHeader messageId="wearRate" rightSideComponent={<Legends />} />
        <ChartWrapper>
          {/* <ForceRerenderOnSizeChange> */}
          <Chart
            type="line"
            id="wear-rate-chart"
            data={getWearRateData(selectedAbrasive)}
            options={getWearRateOptions(xAxesLabel, yAxesLabel, disableAnimations)}
          />
          {/* </ForceRerenderOnSizeChange> */}
        </ChartWrapper>
      </>
    );
  },
);
