import styled from 'styled-components';
import { Card } from '../../components/card/Card';
import { FlexColumn } from '../containers/Column';

export const ChartColumn = styled(FlexColumn)`
  position: relative;
  flex: 1 1 auto;

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    & > [role='tabpanel'] {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      & > div {
        display: flex;
        flex: 1 1 auto;
        height: 100%;
      }
    }
  }
`;

export const ChartCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 0;
  min-height: 645px;
  user-select: none;
`;
