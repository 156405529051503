import * as R from 'ramda';
import { Abrasive, AbrasiveComponent, Point, Steel } from '../types';
import {
  PLATE_HARDNESS_HV,
  CUTTING_LIMIT,
  CUTTING_CURVE,
  PLASTIC_LIMIT,
  PLASTIC_CURVE,
  K_WEIGTH,
  M_WEIGHT,
  PLATE_HARDNESS_HV_END_CURVE,
} from './constants';
import { repeatStep, minBy, maxBy } from './utils';
import { splitAbrasiveComponentsBasedOnHardness } from '../utils/abrasiveComponentHelper';

export const HARDNESS_LIMIT = 150;

const calcMaterial = (material: AbrasiveComponent) => {
  return {
    ...material,
    fraction: material.fraction / 100,
  };
};

export const calcMinerals = (abrasive: Abrasive): Array<AbrasiveComponent> => {
  return abrasive.abrasiveComponents.map(calcMaterial);
};

export const getAdjustedFraction = (minerals: Array<AbrasiveComponent>): number => {
  const materialsBelow151Hardness = minerals.filter(m => m.hardness1 <= HARDNESS_LIMIT).map(m => m.fraction);
  const volume = R.sum(materialsBelow151Hardness);

  const denominator = minerals.length - materialsBelow151Hardness.length;

  return denominator > 0 ? volume / denominator : 0;
};

export const getHrMatrix = (minerals: Array<AbrasiveComponent>): Array<Array<number>> => {
  return PLATE_HARDNESS_HV.reduce((matrix: Array<Array<number>>, hv) => {
    const mMatrix = minerals.reduce((mm: Array<number>, m) => mm.concat(m.hardness1 / hv), []);
    return matrix.concat([mMatrix]);
  }, []);
};

const getH1Value = (hrMatrix: Array<Array<number>>, hvIndex: number, materialIndex: number) => {
  const hrValue = hrMatrix[hvIndex][materialIndex];

  if (hrValue >= CUTTING_LIMIT) {
    return CUTTING_CURVE[hvIndex];
  } else if (hrValue <= PLASTIC_LIMIT) {
    return PLASTIC_CURVE[hvIndex];
  }

  const test =
    ((hrValue - PLASTIC_LIMIT) / (CUTTING_LIMIT - PLASTIC_LIMIT)) *
      (K_WEIGTH * hrValue + M_WEIGHT) *
      (CUTTING_CURVE[hvIndex] - PLASTIC_CURVE[hvIndex]) +
    PLASTIC_CURVE[hvIndex];

  const result = CUTTING_CURVE[hvIndex] - test * (K_WEIGTH * hrValue + M_WEIGHT);
  return result;
};

export const getH1Matrix = (
  abrasiveComponents: Array<AbrasiveComponent>,
  hrMatrix: Array<Array<number>>,
): Array<Array<number>> => {
  return PLATE_HARDNESS_HV.reduce((matrix: Array<Array<number>>, _, hvIndex) => {
    const mMatrix = abrasiveComponents.reduce(
      (mm: Array<number>, _, materialIndex) => mm.concat(getH1Value(hrMatrix, hvIndex, materialIndex)),
      [],
    );
    return matrix.concat([mMatrix]);
  }, []);
};

export const getH2Matrix = (
  abrasiveComponents: Array<AbrasiveComponent>,
  h1Matrix: Array<Array<number>>,
  addFraction: number,
): Array<Array<number>> => {
  return PLATE_HARDNESS_HV.reduce((matrix: Array<Array<number>>, _, h1Index) => {
    const mMatrix = abrasiveComponents.reduce(
      (mm: Array<number>, material, materialIndex) =>
        mm.concat((material.fraction + addFraction) * h1Matrix[h1Index][materialIndex]),
      [],
    );
    return matrix.concat([mMatrix]);
  }, []);
};

export const getRelativeWareRateVector = (
  abrasiveComponents: Array<AbrasiveComponent>,
  h2Matrix: Array<Array<number>>,
): Array<number> => {
  return PLATE_HARDNESS_HV.reduce((vector: Array<number>, _, hvIndex) => {
    const materialValues = abrasiveComponents.map((m, materialIndex) =>
      m.hardness1 > HARDNESS_LIMIT ? h2Matrix[hvIndex][materialIndex] : 0,
    );
    return vector.concat(R.sum(materialValues));
  }, []);
};

export const getVectorPoint = (index: number, rwrVector: Array<number>, hbBig: number, hbSmall: number) => {
  return rwrVector[index] - ((rwrVector[index + 1] - rwrVector[index]) / 50) * (hbBig - (hbBig + hbSmall));
};

export const getRelativeWareRateEndCurveVector = (rwrVector: Array<number>): Array<number> => {
  const hbBigIntervall = repeatStep(150, 850, 50); // Calculate up to 900, 850 last big intervall step.
  const hbSmallIntervall = repeatStep(0, 40, 10);

  const curve = hbBigIntervall.reduce((result: Array<number>, hbBig, index) => {
    const innerCurve = hbSmallIntervall.map(hbSmall => getVectorPoint(index, rwrVector, hbBig, hbSmall));
    return result.concat(innerCurve);
  }, []);

  return curve.concat(rwrVector[rwrVector.length - 1]); // Last point
};

export const getRelativeWareRateCurve = (relativeWareRateEndCurve: Array<number>): Array<Point> => {
  return R.zipWith((x: number, y: number) => ({ x, y: 1 / y }), PLATE_HARDNESS_HV_END_CURVE, relativeWareRateEndCurve);
};

export const calcRelativeWareRateCurve = (abrasive: Abrasive): Array<number> => {
  const minerals = calcMinerals(splitAbrasiveComponentsBasedOnHardness(abrasive));
  const addFraction = getAdjustedFraction(minerals);
  const hrMatrix = getHrMatrix(minerals); // mineralHardness / hardnessOfSteel
  const h1Matrix = getH1Matrix(minerals, hrMatrix);
  const h2Matrix = getH2Matrix(minerals, h1Matrix, addFraction);
  const rwrVector = getRelativeWareRateVector(minerals, h2Matrix);
  return rwrVector;
};

export const calcRelativeServiceLifeCurve = (abrasive: Abrasive): Array<Point> => {
  const rwrVector = calcRelativeWareRateCurve(abrasive);
  const relativeWareRateEndCurve = getRelativeWareRateEndCurveVector(rwrVector);
  const wareRateCurve = getRelativeWareRateCurve(relativeWareRateEndCurve);
  return wareRateCurve;
};

const getSortedValues = (serviceLifeCurve: Array<Point>, predicate: (p: Point) => boolean): Array<Point> => {
  return R.sortBy(p => p.x, serviceLifeCurve.filter(predicate));
};

export const getInterpolatedServiceLife = (serviceLifeCurve: Array<Point>, plateHardness: number): number | null => {
  if (serviceLifeCurve.length < 2) {
    return null;
  }

  if (plateHardness < minBy(p => p.x, serviceLifeCurve)! || plateHardness > maxBy(p => p.x, serviceLifeCurve)!) {
    return null;
  }

  const p1 = R.last(getSortedValues(serviceLifeCurve, p => p.x <= plateHardness))!;
  const p2 = R.head(getSortedValues(serviceLifeCurve, p => p.x >= plateHardness))!;

  if (R.equals(p1, p2)) {
    return p1.y;
  }

  const interpolatedValue = ((plateHardness - p1.x) / (p2.x - p1.x)) * (p2.y - p1.y) + p1.y;
  return interpolatedValue;
};

export const calcWareRateForSteel = (abrasive: Abrasive, steel: Steel): number => {
  const wearRateCurve = calcRelativeServiceLifeCurve(abrasive);
  return getInterpolatedServiceLife(wearRateCurve, steel.hardnessHV)!;
};
