import { getSteels } from './data';
import { timeUnits } from '../state/timeUnits';

export const SlidingValues = {
  currentSteel: {
    defaultValue: getSteels().filter(steel => steel.includeInSliding)[0],
  },
  currentThickness: {
    defaultValue: 10,
    minValue: 1,
    maxValue: 160,
  },
  thicknessWhenWornOut: {
    defaultValue: 1,
    minValue: 0,
    maxValue: 160,
  },
  timeUnit: {
    defaultValue: timeUnits[2],
  },
  currentServiceLife: {
    defaultValue: 20,
  },
  upgradeSteel: {
    defaultValue: undefined,
  },
  upgradeThickness: {
    defaultValue: 10,
    minValue: 1,
    maxValue: 160,
  },
  upgradeBaseThickness: {
    defaultValue: 2,
    minValue: 0,
    maxValue: 160,
  },
  upgradeOverlayThickness: {
    defaultValue: 5,
    minValue: 1,
    maxValue: 160,
  },
  upgradeServiceLife: {
    defaultValue: 0,
  },
};

export const ImpactValues = {
  sharpness: {
    defaultValue: 2,
  },
  angle: {
    defaultValue: 45,
    minValue: 1,
    maxValue: 90,
  },
  size: {
    defaultValue: 50,
    minValue: 1,
    maxValue: 1000,
  },
  currentSteel: {
    defaultValue: getSteels().filter(steel => steel.includeInAbrasiveImpact)[0],
  },
  currentThickness: {
    defaultValue: 10,
    minValue: 1,
    maxValue: 160,
  },
  thicknessWhenWornOut: {
    defaultValue: 1,
    minValue: 0,
    maxValue: 160,
  },
  timeUnit: {
    defaultValue: timeUnits[2],
  },
  serviceLife: {
    defaultValue: 20,
  },
  upgradeSteel: {
    defaultValue: undefined,
  },
  upgradeSteelThickness: {
    defaultValue: 10,
    minValue: 1,
    maxValue: 160,
  },
  upgradeServiceLife: {
    defaultValue: 0,
  },
};

export const ErosionValues = {
  sharpness: {
    defaultValue: 2,
  },
  angle: {
    defaultValue: 45,
    minValue: 1,
    maxValue: 90,
  },
  velocity: {
    defaultValue: 20,
    minValue: 10,
    maxValue: 81,
  },
  currentSteel: {
    defaultValue: getSteels().filter(steel => steel.includeInErosion)[0],
  },
  currentThickness: {
    defaultValue: 10,
    minValue: 1,
    maxValue: 160,
  },
  thicknessWhenWornOut: {
    defaultValue: 1,
    minValue: 0,
    maxValue: 160,
  },
  timeUnit: {
    defaultValue: timeUnits[2],
  },
  serviceLife: {
    defaultValue: 20,
  },
  upgradeSteel: {
    defaultValue: undefined,
  },
  upgradeServiceLife: {
    defaultValue: 0,
  },
  upgradeSteelThickness: {
    defaultValue: 10,
    minValue: 1,
    maxValue: 160,
  },
  upgradeBaseThickness: {
    defaultValue: 2,
    minValue: 0,
    maxValue: 160,
  },
  upgradeOverlayThickness: {
    defaultValue: 5,
    minValue: 1,
    maxValue: 160,
  },
};

export const TippingValues = {
  tipperLength: {
    defaultValue: 6000,
    minValue: 3500,
    maxValue: 8500,
  },
  workingDaysPerYear: {
    defaultValue: 250,
    minValue: 1,
    maxValue: 365,
  },
  unloadsPerDay: {
    defaultValue: 24,
    minValue: 1,
    maxValue: 720,
  },
  rockSizeId: {
    defaultValue: 1,
  },
  stiffener: {
    defaultValue: false,
  },
  currentSteel: {
    defaultValue: getSteels().filter(steel => steel.includeInTipping)[0],
  },
  currentServiceLife: {
    defaultValue: 0,
  },
  serviceLifeTimeUnit: {
    defaultValue: timeUnits[2],
  },
  currentThickness: {
    defaultValue: 6,
    minValue: 0,
    maxValue: 20,
  },
  currentWornOutThickness: {
    defaultValue: 2,
    minValue: 0,
    maxValue: 20,
  },

  upgradeSteel: {
    defaultValue: undefined,
  },
  upgradeServiceLife: {
    defaultValue: 0,
  },
  upgradeThickness: {
    defaultValue: 6,
    maxValue: 20,
    minValue: 1,
  },
  heatMapTippings: {
    defaultValue: 10,
  },
};

export const MiningValues = {
  unloadsPerHour: {
    defaultValue: 3,
    minValue: 0.1,
    maxValue: 30,
  },

  tipperLength: {
    defaultValue: 8000,
    minValue: 5000,
    maxValue: 10500,
  },
  rockSizeId: {
    defaultValue: 1,
  },
  wornOutThickness: {
    defaultValue: 6,
    minValue: 0,
    maxValue: 40,
  },

  currentSteels: [
    {
      steel: {
        defaultValue: getSteels().filter(steel => steel.includeInMining)[0],
      },
      wearProtection: {
        defaultValue: false,
      },
      currentThickness: {
        defaultValue: 20,
        minValue: 5,
        maxValue: 40,
      },
    },
    {
      steel: {
        defaultValue: getSteels().filter(steel => steel.includeInMining)[0],
      },
      wearProtection: {
        defaultValue: false,
      },
      currentThickness: {
        defaultValue: 20,
        minValue: 5,
        maxValue: 40,
      },
    },
    {
      steel: {
        defaultValue: getSteels().filter(steel => steel.includeInMining)[0],
      },
      wearProtection: {
        defaultValue: false,
      },
      currentThickness: {
        defaultValue: 20,
        minValue: 5,
        maxValue: 40,
      },
    },
  ],

  upgradeSteels: [
    {
      steel: {
        defaultValue: undefined,
      },
      upgradeThickness: {
        defaultValue: 20,
        minValue: 5,
        maxValue: 40,
      },
    },
    {
      steel: {
        defaultValue: undefined,
      },
      upgradeThickness: {
        defaultValue: 20,
        minValue: 5,
        maxValue: 40,
      },
    },
    {
      steel: {
        defaultValue: undefined,
      },
      upgradeThickness: {
        defaultValue: 20,
        minValue: 5,
        maxValue: 40,
      },
    },
  ],
};
