import { neutralTertiary } from '../../../utils/constants/colors';
import { fontSizeS } from '../../../utils/constants/typography';
import styled from 'styled-components';
import { ApplicationStateConsumer } from '../../../state/applicationState';
import { RouteStateConsumer } from '../../../state/routing/routeState';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { timeUnits } from '../../../state/timeUnits';

const TogglePillWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 22px;
`;

const TogglePill = styled.div`
  display: flex;
  align-items: center;

  padding-top: 0px;
  padding-right: 0.2em;
  padding-bottom: 0px;
  padding-left: 0.2em;

  margin-bottom: 0px;

  width: 2.2em;
  height: 1em;
  cursor: pointer;

  border-radius: 1em;
  transition: all 0.1s ease 0s;
  border-width: 1px;
  border-style: solid;
  background: rgb(255, 255, 255);
  border-color: rgb(102, 102, 102);
`;

const ToggleThumb = styled.div<{ $toggled?: boolean }>`
  width: 0.6em;
  height: 0.6em;
  background-color: rgb(33, 33, 33);
  border-radius: 0.6em;

  margin-left: ${props => (props.$toggled ? '25' : '0')}px;

  transition: margin-left 100ms ease-out 0s;
`;

const Toggle = ({ toggled, onToggle }: { toggled: boolean; onToggle: () => void }) => {
  return (
    <TogglePillWrapper onClick={onToggle}>
      <TogglePill>
        <ToggleThumb $toggled={toggled} />
      </TogglePill>
    </TogglePillWrapper>
  );
};

const TypeToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -2px;
`;

const TextLabel = styled.div<{ $fadeText: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props => (props.$fadeText ? neutralTertiary : 'initial')};
  font-size: ${fontSizeS};
  height: 22px;
  padding-left: 9px;
  padding-right: 10px;
  padding-top: 1px;
  transition: color 100ms ease-out 0s;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  user-select: none;
`;

export const TypeToggle = injectIntl(({ intl }: WrappedComponentProps) => {
  return (
    <TypeToggleWrapper>
      <ApplicationStateConsumer>
        {({
          state: { slidingProperties, impactProperties, erosionProperties, tippingProperties, useTimeUnit },
          setUseTimeUnit,
        }) => (
          <RouteStateConsumer>
            {({ state: { currentRoute } }) => {
              const timeUnitId = (() => {
                switch (currentRoute) {
                  case 'sliding':
                    return slidingProperties.currentSteelState.serviceLifeTimeUnit.id;
                  case 'impact':
                    return impactProperties.currentSteelState.serviceLifeTimeUnit.id;
                  case 'erosion':
                    return erosionProperties.currentSteelState.serviceLifeTimeUnit.id;
                  case 'tipping':
                    return tippingProperties.currentSteelState.serviceLifeTimeUnit.id;
                  case 'mining':
                    return timeUnits[0].id;
                  default:
                    return 'months';
                }
              })();

              const timeTypeText = intl.formatMessage({
                id: timeUnitId,
              });

              return (
                <>
                  <TextLabel
                    $fadeText={useTimeUnit}
                    onClick={() => {
                      setUseTimeUnit(false);
                    }}
                  >
                    %
                  </TextLabel>
                  <Toggle
                    toggled={useTimeUnit}
                    onToggle={() => {
                      setUseTimeUnit(!useTimeUnit);
                    }}
                  />
                  <TextLabel
                    $fadeText={!useTimeUnit}
                    onClick={() => {
                      setUseTimeUnit(true);
                    }}
                  >
                    {timeTypeText}
                  </TextLabel>
                </>
              );
            }}
          </RouteStateConsumer>
        )}
      </ApplicationStateConsumer>
    </TypeToggleWrapper>
  );
});
