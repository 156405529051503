// import debounce from "just-debounce-it";
import { curry } from 'ramda';
// import * as React from "react";
// import { withMedia } from "react-with-media";
import {
  transparentGray,
  neutralSecondary,
  wearCalcGreen,
  transparentWearCalcGreen,
} from '../../utils/constants/colors';
import { Steel } from '../../types';

// Data

export const getColors = (
  steels: Array<Steel>,
  selectedCurrentSteel: Steel | undefined,
  selectedUpgradeSteel: Steel | undefined,
) => {
  if (!selectedCurrentSteel) {
    return Array(steels.length).fill(transparentGray);
  }

  let currentSteelFound = false;
  return steels.map((steel: Steel) => {
    if (steel.id === selectedCurrentSteel.id) {
      currentSteelFound = true;
      return neutralSecondary;
    }

    if (currentSteelFound && !selectedUpgradeSteel) {
      return wearCalcGreen;
    }

    if (currentSteelFound && selectedUpgradeSteel !== undefined && steel.id === selectedUpgradeSteel.id) {
      return wearCalcGreen;
    }

    if (currentSteelFound && selectedUpgradeSteel !== undefined && steel.id !== selectedUpgradeSteel.id) {
      return transparentWearCalcGreen;
    }

    return transparentGray;
  });
};

// Calculations

export const adjustDataPoint = curry(
  (currentServiceLife: number, reference: number | null, dataPoint: number): number => {
    if (reference === null || reference === undefined) {
      return 0;
    }

    return (dataPoint / reference) * (currentServiceLife > 0 ? currentServiceLife : 1);
  },
);

// TODO investigate what this does, (upgrade deps 2024)
/**
 * ChartJS does not correctly rerender on viewport size changes and get
 * incorrect size. However after a rerender (like changing chart tab)
 * this is corrected.
 *
 * This component will force a rerender directly on orientetaion change
 * and with a delay when dragging window
 */
// export const ForceRerenderOnSizeChange = withMedia("(orientation: landscape)")(
//   class ForceRerenderOnSizeChange extends React.Component<
//     { matches: boolean },
//     { sizeHaveChanged: boolean }
//   > {
//     state = { sizeHaveChanged: false };
//     disableDebounce = false;

//     onResize = debounce(
//       () => {
//         if (!this.disableDebounce) {
//           this.setState({ sizeHaveChanged: true }, () =>
//             this.setState({ sizeHaveChanged: false }),
//           );
//         }
//       },
//       500,
//       false,
//     );
//     componentDidMount() {
//       window.addEventListener("orientationchange", this.onResize);
//     }

//     componentWillUnmount() {
//       window.removeEventListener("orientationchange", this.onResize);
//     }

//     componentDidUpdate(prevProps: this["props"]) {
//       if (this.props.matches !== prevProps.matches) {
//         this.disableDebounce = true;
//         this.setState({ sizeHaveChanged: true }, () =>
//           this.setState({ sizeHaveChanged: false }),
//         );
//         setTimeout(() => {
//           this.disableDebounce = false;
//         }, 1000);
//       }
//     }

//     render() {
//       if (this.state.sizeHaveChanged) {
//         return null;
//       }
//       const { children } = this.props;
//       return children;
//     }
//   },
// );
