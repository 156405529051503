import React from 'react';
import { SteelCard, AbrasiveCard } from '../../components/card/Card';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { SelectAbrasive } from '../../components/abrasive/Abrasive';
import { ImpactAbrasiveProperties } from './ImpactProperties';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { ChartCard, ChartColumn } from '../../components/chart/ChartCard';
import { PivotItem } from '@fluentui/react';
import { RelativeServiceLifeChart } from '../../components/chart/relative/RelativeServiceLifeChart';
import { ThicknessChart } from '../../components/chart/thickness/ThicknessChart';
import { calcImpactWareRateForSteel } from '../../calculations/impactCalculations';
import { Steel, Abrasive, ChartType, UnitSystemOption, CalculationType } from '../../types';
import { CalculationPageWrapper, PropertiesColumn } from '../../components/containers/Container';
import { constructSteelArray, getTranslatedSteels } from '../../utils/steelHelpers';
import { UpgradeSteelProperties } from '../../components/UpgradeSteelProperties';
import { Summary } from '../../components/summary/Summary';
import { ImpactPropertiesState } from '../../state/calculationModels';
import { CurrentSteelProperties } from '../../components/CurrentSteelProperties';
import { ChartPivot } from '../../components/chart/ChartPivot';
import { getSelectedUnitText } from '../../utils/convertUnits/convertUnits';

export const Impact = injectIntl(
  class ImpactClass extends React.Component<WrappedComponentProps> {
    render() {
      const { intl } = this.props;

      return (
        <ApplicationStateConsumer>
          {({
            state: {
              impactSteels,
              impactProperties,
              useTimeUnit,
              selectedUnitSystem,
              selectedChart,
              selectedSteelTab,
              selectedImpactAbrasive,
            },
            setImpactSteels,
            setImpactAbrasive,
            setSelectedSteelTab,
            setImpactProperty,
            setImpactPropertyInstant,
            setSelectedChart,
          }) => {
            const calculationSteels = getTranslatedSteels(
              intl,
              constructSteelArray(impactSteels, impactProperties.currentSteelState.currentSteel),
            );

            const { data, reference } = getImpactServiceLifeDataPoints(
              calculationSteels.filter(s => !s.hiddenInCharts),
              selectedImpactAbrasive,
              impactProperties,
              selectedUnitSystem,
            );

            //Wear rate does not exist on Impact so change to ServiceLife.
            if (selectedChart === ChartType.WearRate) {
              setSelectedChart(ChartType.ServiceLife);
            }
            return (
              <CalculationPageWrapper>
                <PropertiesColumn>
                  <AbrasiveCard>
                    <SelectAbrasive
                      selectedAbrasive={selectedImpactAbrasive}
                      setCalculationModeAbrasive={setImpactAbrasive}
                      abrasiveProperties={selectedImpactAbrasive && <ImpactAbrasiveProperties />}
                      calculationType={CalculationType.Impact}
                    />
                  </AbrasiveCard>

                  {selectedImpactAbrasive && (
                    <SteelCard
                      calculationProperties={
                        <CurrentSteelProperties
                          upgradeSteelState={impactProperties.upgradeSteelState}
                          currentSteelState={impactProperties.currentSteelState}
                          setCurrentSteelState={currentSteelState =>
                            setImpactProperty({
                              currentSteelState,
                            })
                          }
                          setCurrentSteelStateInstant={currentSteelState =>
                            setImpactPropertyInstant({
                              currentSteelState,
                            })
                          }
                          calculationSteels={calculationSteels}
                          includeCustomSteel={true}
                          calculationType="Impact"
                        />
                      }
                      upgradeSteelProperties={
                        <UpgradeSteelProperties
                          calculationType={CalculationType.Impact}
                          upgradeSteelState={impactProperties.upgradeSteelState}
                          setUpgradeSteelState={upgradeSteelState => {
                            setImpactProperty({
                              upgradeSteelState,
                            });
                          }}
                          currentSteelState={impactProperties.currentSteelState}
                          calculationSteels={calculationSteels}
                          setUpgradeSteelStateInstant={upgradeSteelState => {
                            setImpactPropertyInstant({
                              upgradeSteelState,
                            });
                          }}
                        />
                      }
                      selectedSteelTab={selectedSteelTab}
                      setSelectedSteelTab={setSelectedSteelTab}
                    />
                  )}
                </PropertiesColumn>
                <ChartColumn>
                  <ChartPivot
                    displayDownloadDocX={selectedImpactAbrasive !== undefined}
                    steels={calculationSteels}
                    setCalculationSteels={(steels: Array<Steel>) => {
                      setImpactSteels(steels);
                    }}
                  >
                    <PivotItem itemKey={ChartType.ServiceLife} headerText={intl.formatMessage({ id: 'serviceLife' })}>
                      <ChartCard>
                        <RelativeServiceLifeChart
                          steels={calculationSteels.filter(s => !s.hiddenInCharts)}
                          currentSteelState={impactProperties.currentSteelState}
                          upgradeSteelState={impactProperties.upgradeSteelState}
                          data={data}
                          reference={reference}
                          useTimeUnit={useTimeUnit}
                          setCurrentSteel={(steel: Steel) => {
                            setImpactPropertyInstant({
                              currentSteelState: { currentSteel: steel },
                            });
                          }}
                          selectedSteelTab={selectedSteelTab}
                          setUpgradeSteel={steel =>
                            setImpactPropertyInstant({
                              upgradeSteelState: { upgradeSteel: steel },
                            })
                          }
                          chartTitleSuffix={
                            impactProperties.currentSteelState.currentThickness +
                            ' ' +
                            getSelectedUnitText(selectedUnitSystem, intl)
                          }
                        />
                      </ChartCard>
                    </PivotItem>
                    <PivotItem itemKey={ChartType.Thickness} headerText={intl.formatMessage({ id: 'thickness' })}>
                      <ChartCard>
                        <ThicknessChart
                          currentSteelState={impactProperties.currentSteelState}
                          upgradeSteelState={impactProperties.upgradeSteelState}
                          data={data}
                          reference={reference}
                          steels={calculationSteels.filter(s => !s.hiddenInCharts)}
                          selectedUnitSystem={selectedUnitSystem}
                          setCurrentSteel={(steel: Steel) => {
                            setImpactProperty({
                              currentSteelState: { currentSteel: steel },
                            });
                          }}
                          selectedSteelTab={selectedSteelTab}
                          setUpgradeSteel={steel =>
                            setImpactProperty({
                              upgradeSteelState: { upgradeSteel: steel },
                            })
                          }
                        />
                      </ChartCard>
                    </PivotItem>
                    <PivotItem itemKey={ChartType.Summary} headerText={intl.formatMessage({ id: 'summary' })}>
                      <ChartCard style={{ userSelect: 'auto' }}>
                        <Summary
                          currentSteelState={impactProperties.currentSteelState}
                          upgradeSteelState={impactProperties.upgradeSteelState}
                          selectedUnitSystem={selectedUnitSystem}
                        />
                      </ChartCard>
                    </PivotItem>
                  </ChartPivot>
                </ChartColumn>
              </CalculationPageWrapper>
            );
          }}
        </ApplicationStateConsumer>
      );
    }
  },
);

// Helpers

export const getImpactServiceLifeDataPoints = (
  steels: Array<Steel>,
  selectedAbrasive: Abrasive | undefined,
  impactProperties: ImpactPropertiesState,
  selectedUnitSystem: UnitSystemOption,
): { data: Array<number>; reference: number | null } => {
  if (selectedAbrasive === undefined || impactProperties.currentSteelState.currentSteel === undefined) {
    return { data: [], reference: null };
  }

  const data = steels
    .filter(steel => steel.includeInAbrasiveImpact)
    .map(steel =>
      calcImpactWareRateForSteel(selectedAbrasive, impactProperties.abrasiveProperties, steel, selectedUnitSystem),
    );

  const reference = calcImpactWareRateForSteel(
    selectedAbrasive,
    impactProperties.abrasiveProperties,
    impactProperties.currentSteelState.currentSteel,
    selectedUnitSystem,
  );

  return { data, reference };
};
