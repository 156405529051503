// SSAB colors
export const ssabWhite = '#ffffff';
export const ssabPrimary = '#002664';
export const ssabPrimaryDark = '#002052';
export const ssabSecondaryLighter = '#eaf4f7';
export const ssabSecondaryLight = '#D5E9F0';
export const ssabSecondary = '#72b5cc';
export const ssabSecondaryDark = '#39859f';
export const ssabGray = '#e7e8e8';
export const ssabDarkGray = '#919294';
export const ssabDarkerGray = '#d0d1d1';

// SSAB brand colors
export const tealStrenx = '#006983';
export const tealStrenxDark = '#035a70';
export const redHardox = '#97233f';
export const redHardoxDark = '#7c152e';
export const greenArmox = '#00693C';
export const grayDocol = '#616365';
export const yellowDocol = '#fff265';
export const greenDuroxite = '#b6bf00';

export const grayHardox100 = 'rgba(97, 99, 101, 1.0)';
export const grayHardox80 = 'rgba(97, 99, 101, 0.8)';
export const grayHardox60 = 'rgba(97, 99, 101, 0.6)';
export const grayHardox40 = 'rgba(97, 99, 101, 0.4)';
export const grayHardox20 = 'rgba(97, 99, 101, 0.2)';

// SSAB WearCalc colors
export const wearCalcGreen = '#249742';
export const wearCalcGreenDark = '#1e7b37';
export const transparentWearCalcGreenDark = 'rgba(30,123,55,0.5)';
export const transparentWearCalcGreen = 'rgba(36,151,66,0.5)';

export const wearCalcError = '#a80000';

export const transparentGray = 'rgba(150,150,150,0.3)';
export const transparentGrayDark = 'rgba(150,150,150,0.5)';

// Fabric UI colors: https://developer.microsoft.com/en-us/fabric#/styles/colors

// Theme colors
export const themeDarker = '#004578';
export const themeDark = '#005a9e';
export const themeDarkAlt = '#106ebe';
export const themePrimary = '#0078d7';
export const themeSecondary = '#2b88d8';
export const themeTertiary = '#71afe5';
export const themeLight = '#c7e0f4';
export const themeLighter = '#deecf9';
export const themeLighterAlt = '#eff6fc';

// Neutral colors
export const black = '#000000';
export const neutralDark = '#212121';
export const neutralPrimary = '#333333';
export const neutralPrimaryAlt = '#3c3c3c';

export const neutralSecondary = '#666666';
export const neutralSecondaryDark = '#4d4d4d';

export const neutralTertiary = '#a6a6a6';
export const neutralTertiaryAlt = '#c8c8c8';
export const neutralLight = '#eaeaea';
export const neutralLighter = '#f4f4f4';
export const neutralLighterAlt = '#f8f8f8';
export const white = '#ffffff';

export const inputHighlightColorGreen = 'rgba(40, 164, 71, 0.1)';
export const inputHighlightColor = 'rgba(97, 99, 101, 0.04)';
export const inputErrorColor = 'rgba(164, 0, 0, 0.16)';
