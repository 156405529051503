import { Abrasive, AbrasiveComponent } from '../types';
import * as R from 'ramda';

function splitAbrasiveComponents(abrasiveComponent: AbrasiveComponent) {
  const id = abrasiveComponent.id;
  const name = abrasiveComponent.name;
  const hardness1 = abrasiveComponent.hardness1;
  const hardness2 = abrasiveComponent.hardness2;
  const fraction = abrasiveComponent.fraction;
  const density = abrasiveComponent.density;
  const type = abrasiveComponent.type;

  if (hardness2) {
    return [
      {
        id: id,
        name: name,
        hardness1: hardness1,
        hardness2: undefined,
        fraction: fraction / 2,
        density: density,
        type: type,
      },
      {
        id: id,
        name: name,
        hardness1: hardness2,
        hardness2: undefined,
        fraction: fraction / 2,
        density: density,
        type: type,
      },
    ];
  } else {
    return [
      {
        id: id,
        name: name,
        hardness1: hardness1,
        hardness2: undefined,
        fraction: fraction,
        density: density,
        type: type,
      },
    ];
  }
}

export const splitAbrasiveComponentsBasedOnHardness = (abrasive: Abrasive) => {
  const newAbrasive = {
    ...abrasive,
    abrasiveComponents: R.chain(splitAbrasiveComponents, abrasive.abrasiveComponents),
  };

  return newAbrasive;
};
