import { Component } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { ModalHeader, ModalBodyContainer, ModalFooterRow } from '../modal/Modal';
import { Modal, TextField, Callout, DirectionalHint } from '@fluentui/react';
import { neutralLighterAlt, wearCalcError, redHardox } from '../../utils/constants/colors';
import { fontWeightSemiBold } from '../../utils/constants/typography';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Abrasive, AbrasiveComponent, AbrasiveComponentWithoutFraction, AbrasiveType } from '../../types';
import { Row } from '../containers/Row';
import { Column } from '../containers/Column';
import { RemoveButton } from '../buttons/RemoveButton';
import { InfoButton } from '../buttons/InfoButton';
import { ComponentSearch, ComponentSearchProps } from './ComponentSearch';
import { TranslatedInputLabel } from '../labels/Labels';

// Row

const StyledComponentRow = styled(Row)`
  padding: 10px 0;

  :nth-of-type(even) {
    background-color: ${neutralLighterAlt};
  }
`;

const HoverButtonWrapper = styled.div`
  text-align: right;

  @media (hover) {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.23, 1) 0ms;

    ${StyledComponentRow}:hover & {
      opacity: 1;
    }
  }
`;

// Total fraction

const TotalFraction = styled.span<{ $hasError?: boolean }>`
  color: ${props => (props.$hasError === true ? wearCalcError : 'inherit')};
  transition: ${props => (props.$hasError === true ? 'color 100ms ease-out' : 'initial')};
`;

// Header

const ComponentHeaderRow = styled(Row)`
  padding: 10px 0;
  font-weight: ${fontWeightSemiBold};
`;

const ComponentListHeader = ({ totalFraction }: { totalFraction: number }) => (
  <ComponentHeaderRow>
    <Column $size={8}>
      <FormattedMessage id="mineral" />
    </Column>
    <Column $size={4}>
      <TotalFraction $hasError={totalFraction !== 100}>
        <FormattedMessage id="fraction" /> ({totalFraction}
        %)
      </TotalFraction>
    </Column>
  </ComponentHeaderRow>
);

// Component search row

const ComponentSearchRow = (props: ComponentSearchProps) => (
  <Row style={{ paddingTop: 10 }}>
    <Column $size={6}>
      <ComponentSearch {...props} />
    </Column>
  </Row>
);

// Info tooltip

type InfoTooltipProps = {
  component: AbrasiveComponent;
};

type InfoTooltipState = {
  showCallout: boolean;
};

class InfoTooltip extends Component<InfoTooltipProps, InfoTooltipState> {
  // Init

  infoButtonWrapper: HTMLDivElement | null = null;

  constructor(props: InfoTooltipProps) {
    super(props);

    this.state = {
      showCallout: false,
    };
  }

  // Render

  render() {
    const { component } = this.props;
    const { showCallout } = this.state;

    const hardness1 = component.hardness1;
    const hardness2 = component.hardness2;
    const hardnessValue = hardness2 ? `${hardness1} - ${hardness2}` : `${hardness1}`;

    return (
      <div style={{ marginLeft: 10 }}>
        <HoverButtonWrapper>
          <div
            ref={element => {
              this.infoButtonWrapper = element;
            }}
            onMouseEnter={() => {
              this.setState({ showCallout: true });
            }}
            onMouseLeave={() => {
              this.setState({ showCallout: false });
            }}
          >
            <InfoButton
              style={{ height: 22, marginTop: -5 }}
              onClick={() => {
                this.setState({ showCallout: true });
              }}
            />
          </div>
        </HoverButtonWrapper>
        {showCallout ? (
          <Callout
            gapSpace={5}
            target={this.infoButtonWrapper}
            isBeakVisible={false}
            directionalHint={DirectionalHint.bottomLeftEdge}
            onDismiss={() => {
              this.setState({ showCallout: false });
            }}
          >
            <div style={{ minWidth: 130, padding: '0 10px' }}>
              <Row>
                <Column $size={6}>
                  <span style={{ fontWeight: fontWeightSemiBold }}>
                    <FormattedMessage id={'density'} />:
                  </span>
                </Column>
                <Column $size={6}>{component.density}</Column>
              </Row>
              <Row>
                <Column $size={6}>
                  <span style={{ fontWeight: fontWeightSemiBold }}>
                    <FormattedMessage id={'hardness'} />:
                  </span>
                </Column>
                <Column $size={6}>{hardnessValue}</Column>
              </Row>
            </div>
          </Callout>
        ) : null}
      </div>
    );
  }
}

type ComponentRowProperties = {
  component: AbrasiveComponent;
  updateComponent: (mineralId: number, newFraction: number) => void;
  removeComponent: (componentId: number) => void;
};

const ComponentRow = ({ component, updateComponent, removeComponent }: ComponentRowProperties) => {
  return (
    <StyledComponentRow>
      <Column $size={8}>
        <div style={{ display: 'flex' }}>
          {component.name} <InfoTooltip component={component} />
        </div>
      </Column>
      <Column $size={2}>
        <TextField
          value={String(component.fraction)}
          onChange={(_, newValue) => {
            updateComponent(component.id, Number(newValue));
          }}
          suffix="%"
          styles={{
            fieldGroup: {
              marginTop: -5,
              marginBottom: -5,
              height: 28,
            },
            field: {
              paddingLeft: 10,
              paddingRight: 10,
            },
            suffix: {
              minWidth: 'initial !important',
            },
          }}
          type="number"
          pattern="\d*"
        />
      </Column>
      <Column $size={2}>
        <HoverButtonWrapper>
          <RemoveButton
            style={{ marginTop: -10 }}
            onClick={() => {
              removeComponent(component.id);
            }}
          />
        </HoverButtonWrapper>
      </Column>
    </StyledComponentRow>
  );
};

type AbrasiveModalProperties = {
  intl: IntlShape;
  showModal: boolean;
  closeModal: () => void;
  selectedAbrasive: Abrasive | undefined;
  selectedAbrasiveComponents: Array<AbrasiveComponent>;
  abrasives: Array<Abrasive>;
  abrasiveComponents: Array<AbrasiveComponentWithoutFraction>;
  setSelectedAbrasiveComponents: (_abrasiveComponents: Array<AbrasiveComponent>) => void;
  setAbrasive: (abrasive: Abrasive) => void;
  customAbrasiveName: string;
  setCustomAbrasiveName: (customAbrasiveName: string) => void;
};

export class AbrasiveModal extends Component<AbrasiveModalProperties, unknown> {
  state = {
    abrasiveIsValid: false,
  };

  componentDidMount() {
    const { selectedAbrasive, setSelectedAbrasiveComponents } = this.props;

    if (selectedAbrasive !== undefined) {
      setSelectedAbrasiveComponents(selectedAbrasive.abrasiveComponents);
    }
  }

  componentDidUpdate(prevProps: AbrasiveModalProperties) {
    const { selectedAbrasive, setSelectedAbrasiveComponents } = this.props;

    const initialSetAbrasive = prevProps.selectedAbrasive === undefined && this.props.selectedAbrasive !== undefined;

    const changedAbrasive =
      prevProps.selectedAbrasive !== undefined &&
      this.props.selectedAbrasive !== undefined &&
      prevProps.selectedAbrasive.id !== this.props.selectedAbrasive.id;

    if ((initialSetAbrasive || changedAbrasive) && selectedAbrasive !== undefined) {
      setSelectedAbrasiveComponents(selectedAbrasive.abrasiveComponents);
    }
  }

  updateComponent = (componentId: number, newFraction: number) => {
    const { selectedAbrasiveComponents, setSelectedAbrasiveComponents } = this.props;

    const newAbrasiveComponents = selectedAbrasiveComponents.map(component => {
      if (component.id === componentId) {
        const updatedComponent = R.clone(component);
        updatedComponent.fraction = newFraction;
        return updatedComponent;
      }

      return component;
    });
    this.validateComponentsHardness(newAbrasiveComponents);
    setSelectedAbrasiveComponents(newAbrasiveComponents);
  };

  addComponent = (componentId: number) => {
    const { abrasiveComponents, selectedAbrasiveComponents, setSelectedAbrasiveComponents } = this.props;

    const newAbrasiveComponent = R.clone(abrasiveComponents.find(component => component.id === componentId));

    if (newAbrasiveComponent === undefined) {
      return;
    }

    (newAbrasiveComponent as AbrasiveComponent).fraction = 0;

    const newAbrasiveComponents = R.clone(selectedAbrasiveComponents);
    newAbrasiveComponents.push(newAbrasiveComponent as AbrasiveComponent);
    this.validateComponentsHardness(newAbrasiveComponents);
    setSelectedAbrasiveComponents(newAbrasiveComponents);
  };

  removeComponent = (componentId: number) => {
    const { selectedAbrasiveComponents, setSelectedAbrasiveComponents } = this.props;

    const newAbrasiveComponent = selectedAbrasiveComponents.filter(component => component.id !== componentId);

    setSelectedAbrasiveComponents(newAbrasiveComponent);
  };

  validateComponentsHardness = (abrasiveComponents: Array<AbrasiveComponent>) => {
    const valid = abrasiveComponents.some(ac => ac.hardness1 > 150 && ac.fraction > 0);
    this.state.abrasiveIsValid = valid;
    return valid;
  };

  submit = (isCustom: boolean) => {
    const { selectedAbrasive, selectedAbrasiveComponents, setAbrasive } = this.props;

    if (this.validateComponentsHardness(selectedAbrasiveComponents)) {
      if (isCustom || selectedAbrasive === undefined) {
        const customAbrasive = {
          id: 0,
          name: this.props.customAbrasiveName + ' (Custom)',
          abrasiveComponents: selectedAbrasiveComponents,
          type: AbrasiveType.Custom,
        };

        setAbrasive(customAbrasive);
      } else {
        setAbrasive(selectedAbrasive);
      }
    }
  };

  // Render

  render() {
    const { intl, showModal, closeModal, selectedAbrasive, selectedAbrasiveComponents, abrasives, abrasiveComponents } =
      this.props;

    const totalFraction = selectedAbrasiveComponents.reduce((sum, selectedComponent) => {
      return sum + selectedComponent.fraction;
    }, 0);

    const isCustom = isCustomAbrasive(selectedAbrasive, selectedAbrasiveComponents, abrasives);

    //Filter out already selected minerals. Preventing selecting the same again.
    const componentOptions = abrasiveComponents.filter(component =>
      selectedAbrasiveComponents.every(selectedComponent => selectedComponent.id !== component.id),
    );

    return (
      <Modal
        isOpen={showModal}
        isDarkOverlay={false}
        onDismiss={closeModal}
        isBlocking={false}
        containerClassName="ms-modalMedium-container"
      >
        <ModalHeader color={redHardox}>
          {isCustom || selectedAbrasive === undefined ? (
            <FormattedMessage id="customAbrasive" />
          ) : (
            selectedAbrasive.name
          )}
        </ModalHeader>
        <ModalBodyContainer>
          {(isCustom || selectedAbrasive === undefined) && (
            <div style={{ width: '50%', marginBottom: '15px' }}>
              <TranslatedInputLabel translationId="name" />
              <TextField
                value={this.props.customAbrasiveName}
                onChange={(_, newValue) => {
                  this.props.setCustomAbrasiveName(newValue ? newValue : '');
                }}
              />
            </div>
          )}

          <ComponentListHeader totalFraction={totalFraction} />
          {selectedAbrasiveComponents.map(selectedAbrasiveComponent => (
            <ComponentRow
              key={selectedAbrasiveComponent.id}
              component={selectedAbrasiveComponent}
              updateComponent={this.updateComponent}
              removeComponent={this.removeComponent}
            />
          ))}
          <ComponentSearchRow
            placeholder={intl.formatMessage({ id: 'addMineral' })}
            componentOptions={componentOptions}
            addComponent={this.addComponent}
          />
          {isCustom && totalFraction === 100 && !this.state.abrasiveIsValid && (
            <div style={{ marginTop: 20 }}>
              <span style={{ fontWeight: 'bold', color: 'red' }}>
                {intl.formatMessage({ id: 'compositionTooSoft' })}
              </span>
            </div>
          )}
        </ModalBodyContainer>
        <ModalFooterRow
          disableUse={totalFraction !== 100 || !this.state.abrasiveIsValid}
          cancelButtonText={intl.formatMessage({ id: 'cancel' })}
          submitButtonText={intl.formatMessage({ id: 'confirm' })}
          closeModal={closeModal}
          onSubmit={() => {
            this.submit(isCustom);
          }}
        />
      </Modal>
    );
  }
}

// Helpers

const isCustomAbrasive = (
  selectedAbrasive: Abrasive | undefined,
  selectedAbrasiveComponents: Array<AbrasiveComponent>,
  abrasives: Array<Abrasive>,
) => {
  if (selectedAbrasive === undefined) {
    return true;
  }

  const existingAbrasive = abrasives.find(abrasive => abrasive.id === selectedAbrasive.id);

  if (existingAbrasive === undefined) {
    return true;
  }

  const isEqual = R.equals(selectedAbrasiveComponents, existingAbrasive.abrasiveComponents);

  return !isEqual;
};
