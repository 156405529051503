import styled from 'styled-components';
import { smallSizeMaxWidth } from '../../utils/constants/layout';

export const PropertiesRow = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  break-inside: avoid;
`;

export const RightDropdownWrapper = styled.div`
  padding-left: 10px;
`;

type PaddingWrapperProps = {
  $paddingLeft?: boolean;
  $paddingLeftSmall?: boolean;
};

export const PaddingWrapper = styled.div<PaddingWrapperProps>`
  padding-top: 20px;
  padding-left: ${props => (props.$paddingLeft === true ? 10 : 0)}px;

  @media (max-width: ${smallSizeMaxWidth}) {
    padding-left: ${props =>
      props.$paddingLeftSmall === true || (props.$paddingLeftSmall === undefined && props.$paddingLeft === true)
        ? 10
        : 0}px;
  }
`;
