import { Modal, PrimaryButton } from '@fluentui/react';
import styled from 'styled-components';
import { ssabPrimary } from '../../utils/constants/colors';
import { ModalBodyContainer, ModalFooter, ModalHeader } from './Modal';
import { Row } from '../containers/Row';
import { Column } from '../containers/Column';
import { themePrimary } from '../../utils/themes/themes';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import React from 'react';

const TermsOfUseParagraph = styled.p`
  margin-bottom: 20px;
`;

const TermsOfUseLink = styled.a`
  color: ${ssabPrimary};
  text-decoration: underline;
`;

export const TermsOfUseModal = injectIntl(
  class TermsOfUseModal extends React.Component<WrappedComponentProps & { closeModal: () => void }> {
    render() {
      const { closeModal } = this.props;
      return (
        <Modal
          isOpen={true}
          isDarkOverlay={false}
          onDismiss={() => closeModal()}
          containerClassName="ms-modalMedium-container"
        >
          {/* ToDo (jrt) - Update with translated text */}
          <ModalHeader color={ssabPrimary}>Terms of Use</ModalHeader>
          <ModalBodyContainer>
            <TermsOfUseParagraph>
              The information in this report is only applicable to SSAB's products and should not be applied to any
              other products than original SSAB products.
            </TermsOfUseParagraph>
            <TermsOfUseParagraph>
              This report provides general results and recommendations for SSAB steel products. This report is subject
              to{' '}
              <TermsOfUseLink target="_blank" href="https://www.ssab.com/en/terms-of-use/10-2022">
                SSAB's Terms of Use
              </TermsOfUseLink>
              . It shall be the user's responsibility to verify that the information contained herein is correct and is
              suitable to be used for the particular purpose and application of the user. The report is intended to be
              used by professional users only who possess adequate expertise, qualification and knowledge for the safe
              and correct use of the results and recommendations in this report.
            </TermsOfUseParagraph>
            <TermsOfUseParagraph style={{ marginBottom: 0 }}>
              This report is provided “as is”. The use of the report is at user's own discretion and risk and that users
              will be solely responsible for any use of this report. SSAB disclaims any liability for the content or
              potential errors of this report, including but not limited to warranties and condition of merchantability
              or fitness for a particular purpose or suitability for individual applications. SSAB shall not be liable
              for any kind of direct or indirect damages and/or costs related to or arising therefrom, whether special,
              incidental, consequential or directly or indirectly related to the use of, or the inability to use, the
              report or the content, information or results included therein.
            </TermsOfUseParagraph>
          </ModalBodyContainer>
          <Row>
            <Column $size={12}>
              <ModalFooter style={{ padding: 20 }}>
                <PrimaryButton
                  text={'Ok'}
                  onClick={() => {
                    closeModal();
                  }}
                  iconProps={{ iconName: 'PageRight' }}
                  theme={themePrimary}
                  styles={{
                    root: {
                      transition: `background-color 100ms ease-out, color 100ms ease-out`,
                    },
                  }}
                />
              </ModalFooter>
            </Column>
          </Row>
        </Modal>
      );
    }
  },
);
