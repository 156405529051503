import styled from 'styled-components';
import { HARDNESS_LIMIT } from '../../calculations/slidingCalculations';
import { FormattedMessage } from 'react-intl';
import { Abrasive } from '../../types';

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 20px;
  bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(200, 200, 200, 0.7);
`;

const MessageBox = styled.div`
  padding: 20px;
  max-width: 300px;

  background-color: white;
`;

export const AbrasiveWarnings = ({ selectedAbrasive }: { selectedAbrasive: Abrasive | undefined }) =>
  selectedAbrasive && selectedAbrasive.abrasiveComponents.every(mineral => mineral.hardness1 <= HARDNESS_LIMIT) ? (
    <Backdrop>
      <MessageBox>
        <FormattedMessage id="mineralCompositionTooSoft" />
      </MessageBox>
    </Backdrop>
  ) : null;
