import { UnitSystemOption } from '../../types';
import { IntlShape } from 'react-intl';

export const convertMillimetersToInches = (millimeters: number) => {
  return millimeters * 0.0393700787;
};

export const convertInchesToMillimeters = (inches: number) => {
  return inches * 25.4;
};

export const convertLength = (length: number, selectedUnitSystem: UnitSystemOption) => {
  if (selectedUnitSystem === 'imperial') {
    return convertInchesToMillimeters(length);
  }

  return length;
};

export const getSelectedUnitText = (selectedUnitSystem: UnitSystemOption, intl: IntlShape) => {
  if (selectedUnitSystem === 'metric') {
    return intl.formatMessage({ id: 'millimetersShort' });
  }

  return intl.formatMessage({ id: 'inchesShort' });
};
