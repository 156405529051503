import * as R from 'ramda';
import { DbAbrasiveComponent, Steel, DbAbrasive, AbrasiveType, Abrasive, AbrasiveComponentType } from '../types';
import db from './wearCalcDb.json';
type Db = {
  wearCalcDb: {
    material: Array<DbAbrasive>;
    minerals: Array<DbAbrasiveComponent>;
    steel: Array<Steel>;
    otherMaterial: Array<DbAbrasiveComponent>;
    otherMaterialCombo: Array<DbAbrasive>;
  };
};

const getFilteredDbData = (db: Db) => {
  if (import.meta.env.VITE_REACT_APP_IS_ELECTRON === 'true') {
    // Return all data for SSAB (electron) version
    return db;
  } else {
    // Only return public data for Web version
    return {
      wearCalcDb: {
        ...db.wearCalcDb,
        steel: db.wearCalcDb.steel.filter(s => s.public),
      },
    };
  }
};

const parseDb = R.memoizeWith(
  (db: Db) => {
    const filteredDb = getFilteredDbData(db);
    return `${filteredDb.wearCalcDb.material}/${filteredDb.wearCalcDb.minerals}/${filteredDb.wearCalcDb.steel}/${filteredDb.wearCalcDb.otherMaterial}/${filteredDb.wearCalcDb.otherMaterialCombo}`;
  },
  (db: Db) => {
    const filteredDb = getFilteredDbData(db);

    return {
      material: filteredDb.wearCalcDb.material as Array<DbAbrasive>,
      mineral: filteredDb.wearCalcDb.minerals as Array<DbAbrasiveComponent>,
      steel: filteredDb.wearCalcDb.steel as Array<Steel>,
      otherMaterial: filteredDb.wearCalcDb.otherMaterial as Array<DbAbrasiveComponent>,
      otherMaterialCombo: filteredDb.wearCalcDb.otherMaterialCombo as Array<DbAbrasive>,
    };
  },
);

export const getAbrasives = (): Array<Abrasive> => {
  const dbMinerals = parseDb(db as Db).mineral;

  const dbMaterials = parseDb(db as Db).material;

  const abrasivesFromMaterials = dbMaterials.map(dbMat => {
    return {
      id: dbMat.id,
      name: dbMat.name,
      abrasiveComponents: dbMat.minerals.map(abrCom => {
        const abrComMineral = dbMinerals.find(min => min.id === abrCom.id)!;

        return {
          id: abrComMineral.id,
          name: abrComMineral.name,
          hardness1: abrComMineral.hardness1,
          hardness2: abrComMineral.hardness2,
          density: abrComMineral.density,
          fraction: abrCom.volFrac,
          type: AbrasiveComponentType.Mineral,
        };
      }),
      type: AbrasiveType.CommonRock,
      primaryInSliding: dbMat.primaryInSliding,
      primaryInImpact: dbMat.primaryInImpact,
      primaryInErosion: dbMat.primaryInErosion,
      primaryInTipping: dbMat.primaryInTipping,
      primaryInMining: dbMat.primaryInMining,
      excludeInSliding: dbMat.excludeInSliding,
      excludeInImpact: dbMat.excludeInImpact,
      excludeInErosion: dbMat.excludeInErosion,
      excludeInTipping: dbMat.excludeInTipping,
      excludeInMining: dbMat.excludeInMining,
    };
  });

  const dbOtherMaterials = parseDb(db as Db).otherMaterial;

  const abrasivesFromOtherMaterials = dbOtherMaterials.map(dbOth => {
    return {
      id: dbOth.id,
      name: dbOth.name,
      abrasiveComponents: [
        {
          id: dbOth.id,
          name: dbOth.name,
          hardness1: dbOth.hardness1,
          hardness2: dbOth.hardness2,
          density: dbOth.density,
          fraction: 100,
          type: AbrasiveComponentType.OtherMaterial,
        },
      ],

      type: AbrasiveType.OtherMaterial,
      excludeInSliding: dbOth.excludeInSliding,
      excludeInImpact: dbOth.excludeInImpact,
      excludeInErosion: dbOth.excludeInErosion,
      excludeInTipping: dbOth.excludeInTipping,
      excludeInMining: dbOth.excludeInMining,
      primaryInSliding: dbOth.primaryInSliding,
      primaryInImpact: dbOth.primaryInImpact,
      primaryInErosion: dbOth.primaryInErosion,
      primaryInTipping: dbOth.primaryInTipping,
      primaryInMining: dbOth.primaryInMining,
    };
  });

  const dbOtherMaterialCombos = parseDb(db as Db).otherMaterialCombo;

  const abrasivesFromOtherMaterialCombos = dbOtherMaterialCombos.map(dbMat => {
    return {
      id: dbMat.id,
      name: dbMat.name,
      abrasiveComponents: dbMat.minerals.map(abrCom => {
        const abrComMineral = dbOtherMaterials.find(min => min.id === abrCom.id)!;

        return {
          id: abrComMineral.id,
          name: abrComMineral.name,
          hardness1: abrComMineral.hardness1,
          hardness2: abrComMineral.hardness2,
          density: abrComMineral.density,
          fraction: abrCom.volFrac,
          type: AbrasiveComponentType.Mineral,
        };
      }),
      type: AbrasiveType.OtherMaterialCombo,
    };
  });

  return [...abrasivesFromMaterials, ...abrasivesFromOtherMaterials, ...abrasivesFromOtherMaterialCombos];
};

export const getAbrasiveComponents = () => {
  const dbMinerals = parseDb(db as Db).mineral;
  const dbOtherMaterials = parseDb(db as Db).otherMaterial;

  const abrasiveComponentsFromMinerals = dbMinerals.map((dbMin: DbAbrasiveComponent) => {
    return {
      id: dbMin.id,
      name: dbMin.name,
      hardness1: dbMin.hardness1,
      hardness2: dbMin.hardness2,
      density: dbMin.density,
      type: AbrasiveComponentType.Mineral,
    };
  });

  const abrasiveComponentsFromOtherMaterials = dbOtherMaterials.map(dbOth => {
    return {
      id: dbOth.id,
      name: dbOth.name,
      hardness1: dbOth.hardness1,
      hardness2: dbOth.hardness2,
      density: dbOth.density,
      type: AbrasiveComponentType.OtherMaterial,
    };
  });

  return [...abrasiveComponentsFromMinerals, ...abrasiveComponentsFromOtherMaterials];
};

export const getSteels = () => {
  const steels = parseDb(db as Db).steel;
  return steels.map(s => {
    return { ...s, hiddenInCharts: false };
  }) as Array<Steel & { hiddenInCharts: boolean }>;
};
