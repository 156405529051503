import { SlidingValues, ImpactValues, ErosionValues, TippingValues, MiningValues } from '../data/calculationValues';
import { getAbrasiveComponents, getAbrasives, getSteels } from '../data/data';
import { TipperType, ChartType } from '../types';
import { APP_VERSION } from '../view/WearCalc';
import { timeUnits } from './timeUnits';
import { unitSystems } from '../state/unitSystems';
import { ApplicationState } from './applicationState';

export const getClearedCalculationState = (): Partial<ApplicationState> => {
  return {
    ...getInitialCalculationState(),
    slidingSteels: getSteels().filter(s => s.includeInSliding),
    impactSteels: getSteels().filter(s => s.includeInAbrasiveImpact),
    erosionSteels: getSteels().filter(s => s.includeInErosion),
    selectedSlidingAbrasive: undefined,
    selectedImpactAbrasive: undefined,
    selectedErosionAbrasive: undefined,
    selectedTippingAbrasive: undefined,
    selectedMiningAbrasive: undefined,
    selectedSteelTab: 'currentSteelTab',
  };
};

const getInitialCalculationState = () => {
  return {
    slidingProperties: {
      currentSteelState: {
        currentSteel: SlidingValues.currentSteel.defaultValue,
        currentServiceLife: SlidingValues.currentServiceLife.defaultValue,
        currentThickness: SlidingValues.currentThickness.defaultValue,
        serviceLifeTimeUnit: SlidingValues.timeUnit.defaultValue,
        wornOutThickness: SlidingValues.thicknessWhenWornOut.defaultValue,
      },
      upgradeSteelState: {
        upgradeSteel: SlidingValues.upgradeSteel.defaultValue,
        upgradeServiceLife: SlidingValues.upgradeServiceLife.defaultValue,
        upgradeThickness: SlidingValues.upgradeThickness.defaultValue,
        upgradeBaseThickness: SlidingValues.upgradeBaseThickness.defaultValue,
      },
    },
    /* *************************** */
    impactProperties: {
      currentSteelState: {
        currentSteel: ImpactValues.currentSteel.defaultValue,
        currentServiceLife: ImpactValues.serviceLife.defaultValue,
        currentThickness: ImpactValues.currentThickness.defaultValue,
        serviceLifeTimeUnit: ImpactValues.timeUnit.defaultValue,
        wornOutThickness: ImpactValues.thicknessWhenWornOut.defaultValue,
      },
      upgradeSteelState: {
        upgradeSteel: ImpactValues.upgradeSteel.defaultValue,
        upgradeServiceLife: ImpactValues.upgradeServiceLife.defaultValue,
        upgradeThickness: ImpactValues.upgradeSteelThickness.defaultValue,
      },
      abrasiveProperties: {
        size: ImpactValues.size.defaultValue,
        sharpness: ImpactValues.sharpness.defaultValue,
        angle: ImpactValues.angle.defaultValue,
      },
    },
    /* *************************** */
    erosionProperties: {
      currentSteelState: {
        currentSteel: ErosionValues.currentSteel.defaultValue,
        currentThickness: ErosionValues.currentThickness.defaultValue,
        currentServiceLife: ErosionValues.serviceLife.defaultValue,
        serviceLifeTimeUnit: ErosionValues.timeUnit.defaultValue,
        wornOutThickness: ErosionValues.thicknessWhenWornOut.defaultValue,
      },
      upgradeSteelState: {
        upgradeSteel: ErosionValues.upgradeSteel.defaultValue,
        upgradeServiceLife: ErosionValues.upgradeServiceLife.defaultValue,
        upgradeThickness: ErosionValues.upgradeSteelThickness.defaultValue,
        upgradeBaseThickness: ErosionValues.upgradeBaseThickness.defaultValue,
      },
      abrasiveProperties: {
        velocity: ErosionValues.velocity.defaultValue,
        sharpness: ErosionValues.sharpness.defaultValue,
        angle: ErosionValues.angle.defaultValue,
      },
    },
    /* *************************** */
    tippingProperties: {
      currentSteelState: {
        currentSteel: TippingValues.currentSteel.defaultValue,
        currentServiceLife: TippingValues.currentServiceLife.defaultValue,
        currentThickness: TippingValues.currentThickness.defaultValue,
        wornOutThickness: TippingValues.currentWornOutThickness.defaultValue,
        serviceLifeTimeUnit: TippingValues.serviceLifeTimeUnit.defaultValue,
      },

      upgradeSteelState: {
        upgradeSteel: TippingValues.upgradeSteel.defaultValue,
        upgradeServiceLife: TippingValues.upgradeServiceLife.defaultValue,
        upgradeThickness: TippingValues.upgradeThickness.defaultValue,
      },
      workingDaysPerYear: TippingValues.workingDaysPerYear.defaultValue,
      unloadsPerDay: TippingValues.unloadsPerDay.defaultValue,

      tipperType: TipperType.UShape,
      rockSizeId: TippingValues.rockSizeId.defaultValue,
      tipperLength: TippingValues.tipperLength.defaultValue,
      useStiffener: TippingValues.stiffener.defaultValue,
      heatMapTippings: TippingValues.heatMapTippings.defaultValue,
    },
    miningProperties: {
      currentMiningSteelsState: [
        {
          steel: MiningValues.currentSteels[0].steel.defaultValue,
          steelThickness: MiningValues.currentSteels[0].currentThickness.defaultValue,
          wearProtection: MiningValues.currentSteels[0].wearProtection.defaultValue,
          name: 'A',
        },
        {
          steel: MiningValues.currentSteels[1].steel.defaultValue,
          steelThickness: MiningValues.currentSteels[1].currentThickness.defaultValue,
          wearProtection: MiningValues.currentSteels[1].wearProtection.defaultValue,
          name: 'B',
        },
        {
          steel: MiningValues.currentSteels[2].steel.defaultValue,
          steelThickness: MiningValues.currentSteels[2].currentThickness.defaultValue,
          wearProtection: MiningValues.currentSteels[2].wearProtection.defaultValue,
          name: 'C',
        },
      ],
      upgradeMiningSteelsState: [
        {
          steel: MiningValues.upgradeSteels[0].steel.defaultValue,
          steelThickness: MiningValues.upgradeSteels[0].upgradeThickness.defaultValue,
          name: 'A',
        },
        {
          steel: MiningValues.upgradeSteels[1].steel.defaultValue,
          steelThickness: MiningValues.upgradeSteels[1].upgradeThickness.defaultValue,
          name: 'B',
        },
        {
          steel: MiningValues.upgradeSteels[2].steel.defaultValue,
          steelThickness: MiningValues.upgradeSteels[2].upgradeThickness.defaultValue,
          name: 'C',
        },
      ],

      heatMapTippings: 3000,
      currentServiceLife: 10,
      currentTippings: 0,
      upgradeServiceLife: 10,
      upgradeTippings: 0,
      serviceLifeTimeUnit: timeUnits[2],
      wornOutThickness: MiningValues.wornOutThickness.defaultValue,
      tipperType: TipperType.Mining,
      rockSizeId: MiningValues.rockSizeId.defaultValue,
      tipperLength: MiningValues.tipperLength.defaultValue,
      unloadsPerHour: MiningValues.unloadsPerHour.defaultValue,
    },
  };
};

export const getInitialState = (): ApplicationState => {
  return {
    version: APP_VERSION,
    abrasives: getAbrasives(),
    abrasiveComponents: getAbrasiveComponents(),
    steels: getSteels(),

    slidingSteels: getSteels().filter(s => s.includeInSliding),
    impactSteels: getSteels().filter(s => s.includeInAbrasiveImpact),
    erosionSteels: getSteels().filter(s => s.includeInErosion),
    ...getInitialCalculationState(),
    timeUnits,
    useTimeUnit: false,
    unitSystems,
    selectedUnitSystem: 'metric',
    selectedChart: ChartType.ServiceLife,
    docxSettings: {
      sliding: true,
      impact: false,
      erosion: false,
      tipping: false,
      mining: false,
      reportType: 'details',
    },
    selectedSteelTab: 'currentSteelTab',
  };
};
