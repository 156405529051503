import { MiningPropertiesState } from '../../state/miningProperties';
import { UnitSystemOption } from '../../types';
import { convertMillimetersToInches } from '../convertUnits/convertUnits';
import { MiningValues } from '../../data/calculationValues';

export const validateMiningInputs = (miningProperties: MiningPropertiesState, selectedUnitSystem: UnitSystemOption) => {
  return (
    validateCurrentMiningInputs(miningProperties, selectedUnitSystem) &&
    validateUpgradeMiningInputs(miningProperties, selectedUnitSystem)
  );
};

export const validateCurrentMiningInputs = (
  miningProperties: MiningPropertiesState,
  selectedUnitSystem: UnitSystemOption,
) => {
  /* Input values */
  const wornOutThickness = miningProperties.wornOutThickness;
  const tipperLength = miningProperties.tipperLength;

  const steelAThickness = miningProperties.currentMiningSteelsState[0].steelThickness;
  const steelBThickness = miningProperties.currentMiningSteelsState[1].steelThickness;
  const steelCThickness = miningProperties.currentMiningSteelsState[2].steelThickness;

  /* Max / Min values */

  const maxWornoutThickness = Math.min(steelAThickness, steelBThickness, steelCThickness);

  const steelAMinThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.currentSteels[0].currentThickness.minValue
      : convertMillimetersToInches(MiningValues.currentSteels[0].currentThickness.minValue);

  const steelAMaxThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.currentSteels[0].currentThickness.maxValue
      : convertMillimetersToInches(MiningValues.currentSteels[0].currentThickness.maxValue);

  const steelBMinThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.currentSteels[1].currentThickness.minValue
      : convertMillimetersToInches(MiningValues.currentSteels[1].currentThickness.minValue);

  const steelBMaxThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.currentSteels[1].currentThickness.maxValue
      : convertMillimetersToInches(MiningValues.currentSteels[1].currentThickness.maxValue);

  const steelCMinThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.currentSteels[2].currentThickness.minValue
      : convertMillimetersToInches(MiningValues.currentSteels[2].currentThickness.minValue);

  const steelCMaxThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.currentSteels[2].currentThickness.maxValue
      : convertMillimetersToInches(MiningValues.currentSteels[2].currentThickness.maxValue);

  const tipperMinLength =
    selectedUnitSystem === 'metric'
      ? MiningValues.tipperLength.minValue
      : convertMillimetersToInches(MiningValues.tipperLength.minValue);
  const tipperMaxLength =
    selectedUnitSystem === 'metric'
      ? MiningValues.tipperLength.maxValue
      : convertMillimetersToInches(MiningValues.tipperLength.maxValue);

  if (miningProperties.heatMapTippings > 1000000) {
    return false;
  }

  if (tipperLength > tipperMaxLength || tipperLength < tipperMinLength) {
    return false;
  }

  if (wornOutThickness >= maxWornoutThickness) {
    return false;
  }

  if (steelAThickness > steelAMaxThickness || steelAThickness < steelAMinThickness) {
    return false;
  }

  if (steelBThickness > steelBMaxThickness || steelBThickness < steelBMinThickness) {
    return false;
  }

  if (steelCThickness > steelCMaxThickness || steelCThickness < steelCMinThickness) {
    return false;
  }

  return true;
};

export const validateUpgradeMiningInputs = (
  miningProperties: MiningPropertiesState,
  selectedUnitSystem: UnitSystemOption,
) => {
  const wornOutThickness = miningProperties.wornOutThickness;
  const tipperLength = miningProperties.tipperLength;
  const upgradeSteelAThickness = miningProperties.upgradeMiningSteelsState[0].steelThickness;
  const upgradeSteelBThickness = miningProperties.upgradeMiningSteelsState[1].steelThickness;
  const upgradeSteelCThickness = miningProperties.upgradeMiningSteelsState[2].steelThickness;

  const maxWornoutThickness = Math.min(upgradeSteelAThickness, upgradeSteelBThickness, upgradeSteelCThickness);

  const maxTipperLength =
    selectedUnitSystem === 'metric'
      ? MiningValues.tipperLength.maxValue
      : convertMillimetersToInches(MiningValues.tipperLength.maxValue);
  const minTipperLength =
    selectedUnitSystem === 'metric'
      ? MiningValues.tipperLength.minValue
      : convertMillimetersToInches(MiningValues.tipperLength.minValue);

  const upgradeSteelAMaxThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.upgradeSteels[0].upgradeThickness.maxValue
      : convertMillimetersToInches(MiningValues.upgradeSteels[0].upgradeThickness.maxValue);

  const upgradeSteelAMinThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.upgradeSteels[0].upgradeThickness.minValue
      : convertMillimetersToInches(MiningValues.upgradeSteels[0].upgradeThickness.minValue);

  const upgradeSteelBMaxThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.upgradeSteels[1].upgradeThickness.maxValue
      : convertMillimetersToInches(MiningValues.upgradeSteels[1].upgradeThickness.maxValue);

  const upgradeSteelBMinThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.upgradeSteels[1].upgradeThickness.minValue
      : convertMillimetersToInches(MiningValues.upgradeSteels[1].upgradeThickness.minValue);

  const upgradeSteelCMaxThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.upgradeSteels[2].upgradeThickness.maxValue
      : convertMillimetersToInches(MiningValues.upgradeSteels[2].upgradeThickness.maxValue);

  const upgradeSteelCMinThickness =
    selectedUnitSystem === 'metric'
      ? MiningValues.upgradeSteels[2].upgradeThickness.minValue
      : convertMillimetersToInches(MiningValues.upgradeSteels[2].upgradeThickness.minValue);

  if (wornOutThickness >= maxWornoutThickness) {
    return false;
  }

  if (tipperLength > maxTipperLength || tipperLength < minTipperLength) {
    return false;
  }

  if (upgradeSteelAThickness > upgradeSteelAMaxThickness || upgradeSteelAThickness < upgradeSteelAMinThickness) {
    return false;
  }

  if (upgradeSteelBThickness > upgradeSteelBMaxThickness || upgradeSteelBThickness < upgradeSteelBMinThickness) {
    return false;
  }
  if (upgradeSteelCThickness > upgradeSteelCMaxThickness || upgradeSteelCThickness < upgradeSteelCMinThickness) {
    return false;
  }

  return true;
};
