import React from 'react';
import styled from 'styled-components';
import { ssabDarkGray, redHardox } from '../../utils/constants/colors';
import { PrimaryButton, IconButton, Modal } from '@fluentui/react';
import { DocxProvider } from './DocxProvider';
import { ModalController, ModalHeader, ModalBodyContainer, ModalFooterRow } from '../modal/Modal';
import { Radio } from '../radio/Radio';
import { GroupHeader } from '../labels/Labels';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { Row } from '../containers/Row';
import { Column } from '../containers/Column';
import { RouteStateConsumer } from '../../state/routing/routeState';
import { Route } from '../../types';
import { DocxSettings } from '../../state/docxSettings';
import { timeUnits } from '../../state/timeUnits';
import { LoadingSpinner } from '../LoadingSpinner';
import { themeHardox } from '../../utils/themes/themes';
import {
  CalculationModelState,
  ErosionPropertiesState,
  ImpactPropertiesState,
  TippingPropertiesState,
} from '../../state/calculationModels';

const ModalGroupHeader = styled(GroupHeader)<{ $marginTop?: boolean }>`
  margin-top: ${props => (props.$marginTop ? '12px' : '2px')};
`;

export const NavbarIconButton = ({ icon, label, onClick }: { icon: string; label: string; onClick?: () => void }) => (
  <IconButton
    onClick={onClick}
    iconProps={{ iconName: icon }}
    title={label}
    ariaLabel={label}
    className="material-icon-large"
    styles={{
      root: {
        width: 48,
        height: 48,
      },
      icon: {
        color: ssabDarkGray,
        fontSize: 22,
      },
      iconHovered: {
        color: redHardox,
      },
    }}
  />
);

export const DownloadDocx = injectIntl(({ intl }: WrappedComponentProps) => (
  <DocxProvider>
    {({ renderDocx, canRenderDocx, isRendering }) => {
      return (
        <>
          {isRendering && <LoadingSpinner />}

          <ModalController>
            {({ showModal, openModal, closeModal }) => (
              <>
                {canRenderDocx ? (
                  <PrimaryButton onClick={openModal} theme={themeHardox}>
                    {intl.formatMessage({ id: 'generateReport' })}
                  </PrimaryButton>
                ) : null}
                <Modal
                  isOpen={showModal}
                  isDarkOverlay={false}
                  onDismiss={closeModal}
                  isBlocking={false}
                  containerClassName="ms-modalMedium-container"
                >
                  <ModalHeader color={redHardox}>
                    <FormattedMessage id="generateReport" />
                  </ModalHeader>
                  <ApplicationStateConsumer>
                    {({
                      state: {
                        docxSettings,
                        slidingProperties,
                        impactProperties,
                        erosionProperties,
                        tippingProperties,
                        useTimeUnit,
                      },
                      setDocxSettingsProperty,
                      setUseTimeUnit,
                    }) => (
                      <RouteStateConsumer>
                        {({ state: { currentRoute } }) => (
                          <ModalBodyContainer>
                            <ModalBodySelectDocxType
                              currentRoute={currentRoute}
                              setDocxSettingsProperty={setDocxSettingsProperty}
                            />
                            {(docxSettings.sliding || docxSettings.impact || docxSettings.erosion) && (
                              <>
                                <ModalGroupHeader $marginTop={true}>
                                  <FormattedMessage id="reportType" />
                                </ModalGroupHeader>
                                <Row>
                                  <Column $size={6}>
                                    <Radio
                                      label={intl.formatMessage({
                                        id: 'wearcalcReport',
                                      })}
                                      checked={docxSettings.reportType === 'summary'}
                                      disabled={false}
                                      onClick={() => {
                                        setDocxSettingsProperty({
                                          reportType: 'summary',
                                        });
                                      }}
                                    />
                                  </Column>
                                  <Column $size={6}>
                                    <Radio
                                      label={intl.formatMessage({
                                        id: 'allDetails',
                                      })}
                                      checked={docxSettings.reportType === 'details'}
                                      disabled={false}
                                      onClick={() => {
                                        setDocxSettingsProperty({
                                          reportType: 'details',
                                        });
                                      }}
                                    />
                                  </Column>
                                </Row>
                              </>
                            )}
                            <ServiceLifeFormat
                              currentRoute={currentRoute}
                              applicationState={{
                                slidingProperties,
                                impactProperties,
                                erosionProperties,
                                tippingProperties,
                                useTimeUnit,
                              }}
                              setUseTimeUnit={setUseTimeUnit}
                            />
                          </ModalBodyContainer>
                        )}
                      </RouteStateConsumer>
                    )}
                  </ApplicationStateConsumer>
                  <ModalFooterRow
                    disableUse={false}
                    cancelButtonText={intl.formatMessage({ id: 'cancel' })}
                    submitButtonText={intl.formatMessage({ id: 'download' })}
                    closeModal={closeModal}
                    onSubmit={renderDocx}
                  />
                </Modal>
              </>
            )}
          </ModalController>
        </>
      );
    }}
  </DocxProvider>
));

type ModalBodySelectDocxTypeProps = {
  currentRoute: Route;
  setDocxSettingsProperty: (property: Partial<DocxSettings>) => void;
};

class ModalBodySelectDocxType extends React.Component<ModalBodySelectDocxTypeProps> {
  componentDidMount() {
    if (this.props.currentRoute === 'start') return;

    this.props.setDocxSettingsProperty({
      ...{
        sliding: false,
        impact: false,
        erosion: false,
        tipping: false,
        mining: false,
      },
      [this.props.currentRoute]: true,
    });
  }

  render() {
    return null;
  }
}

type ServiceLifeFormatType = {
  currentRoute: Route;
  applicationState: {
    slidingProperties: CalculationModelState;
    impactProperties: ImpactPropertiesState;
    erosionProperties: ErosionPropertiesState;
    tippingProperties: TippingPropertiesState;
    useTimeUnit: boolean;
  };
  setUseTimeUnit: (useTimeUnit: boolean) => void;
};

const ServiceLifeFormat = injectIntl(
  ({ intl, currentRoute, applicationState, setUseTimeUnit }: ServiceLifeFormatType & WrappedComponentProps) => {
    const timeUnitId = (() => {
      switch (currentRoute) {
        case 'sliding':
          return applicationState.slidingProperties.currentSteelState.serviceLifeTimeUnit.id;
        case 'impact':
          return applicationState.impactProperties.currentSteelState.serviceLifeTimeUnit.id;
        case 'erosion':
          return applicationState.erosionProperties.currentSteelState.serviceLifeTimeUnit.id;
        case 'tipping':
          return applicationState.tippingProperties.currentSteelState.serviceLifeTimeUnit.id;
        case 'mining':
          return timeUnits[0].id;
        default:
          return 'months';
      }
    })();

    const timeTypeText = intl.formatMessage({
      id: timeUnitId,
    });

    return (
      <>
        <ModalGroupHeader $marginTop={true}>
          <FormattedMessage id="serviceLifeFormat" />
        </ModalGroupHeader>
        <Row>
          <Column $size={6}>
            <Radio
              label={
                intl.formatMessage({
                  id: 'relative',
                }) + ' [%]'
              }
              checked={!applicationState.useTimeUnit}
              disabled={false}
              onClick={() => setUseTimeUnit(false)}
            />
          </Column>
          <Column $size={3}>
            <Radio
              label={timeTypeText}
              checked={applicationState.useTimeUnit}
              disabled={false}
              onClick={() => setUseTimeUnit(true)}
            />
          </Column>
        </Row>
      </>
    );
  },
);
