import React from 'react';
import { ChartWrapper } from '../ChartWrapper';
import { Chart } from 'react-chartjs-2';
import { getRelativeChartData, getRelativeChartOptions } from './relativeChartHelpers';
import { Steel, CurrentMiningSteelState, UpgradeMiningSteelState } from '../../../types';
import { injectIntl, WrappedComponentProps, IntlShape } from 'react-intl';
import { ChartCardHeader } from '../ChartCardHeader';
import { TypeToggle } from './TypeToggle';
import { MiningPropertiesState } from '../../../state/miningProperties';
import { timeUnits } from '../../../state/timeUnits';

// Workaround to missing 'id' interface prop bug.
// declare module "react-chartjs-2" {
//   export interface ChartComponentProps {
//     id?: string;
//   }
// }

type RelativeMiningServiceLifeChartProps = {
  currentMiningSteelState: CurrentMiningSteelState;
  upgradeMiningSteelState: UpgradeMiningSteelState;
  miningProperties: MiningPropertiesState;
  data: Array<number>;
  reference: number | undefined;
  steels: Array<Steel>;
  useTimeUnit: boolean;
  selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab';
  disableAnimations?: boolean;
  hideXAxis: boolean;
  currentServiceLife: number | undefined;
  suggestedMaxValue: number | undefined;
  chartTitleId: string;
  hideChartTypeToggle: boolean;
  chartTitleSuffix?: string;
  setCurrentSteel?: (steel: Steel) => void;
  setUpgradeSteel?: (steel: Steel | undefined) => void;
  miningIndex?: number;
} & WrappedComponentProps;

export const RelativeMiningServiceLifeChart = React.memo(
  injectIntl(
    ({
      currentMiningSteelState,
      upgradeMiningSteelState,
      intl,
      data,
      reference,
      steels,
      useTimeUnit,
      disableAnimations = false,
      hideXAxis,
      setCurrentSteel,
      currentServiceLife,
      suggestedMaxValue,
      selectedSteelTab,
      setUpgradeSteel,
      chartTitleId,
      hideChartTypeToggle,
      chartTitleSuffix,
    }: RelativeMiningServiceLifeChartProps) => {
      let xAxesLabel = '';
      if (!hideXAxis) {
        xAxesLabel = getXAxesLabel(intl, useTimeUnit, timeUnits[0].id);
      }
      return (
        <>
          <ChartCardHeader
            messageId={chartTitleId}
            titleSuffix={chartTitleSuffix}
            rightSideComponent={hideChartTypeToggle ? '' : <TypeToggle />}
          />
          <ChartWrapper $paddingTop={0} $minHeight={100 + steels.length * 25}>
            <Chart
              type="bar"
              id="sliding-chart-canvas"
              data={getRelativeChartData(
                steels,
                currentMiningSteelState.steel,
                upgradeMiningSteelState.steel,
                data,
                reference || 0,
                useTimeUnit && currentServiceLife ? currentServiceLife : 1,
              )}
              options={getRelativeChartOptions(
                intl,
                useTimeUnit,
                timeUnits[0].id,
                xAxesLabel,
                disableAnimations,
                steels,
                currentMiningSteelState.steel,
                selectedSteelTab,
                hideXAxis,
                useTimeUnit && suggestedMaxValue ? suggestedMaxValue : 0,
                setCurrentSteel,
                setUpgradeSteel,
              )}
            />
          </ChartWrapper>
        </>
      );
    },
  ),
  (prev, next) => {
    return (
      prev.currentServiceLife === next.currentServiceLife &&
      prev.useTimeUnit === next.useTimeUnit &&
      arraysEqual(prev.data, next.data) &&
      prev.upgradeMiningSteelState === next.upgradeMiningSteelState
    );
  },
);

// Helpers

function arraysEqual(a: Array<number>, b: Array<number>) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const getXAxesLabel = (intl: IntlShape, useTimeUnit: boolean, timeUnitId: string) => {
  if (useTimeUnit) {
    return `${intl.formatMessage({ id: 'serviceLife' }).toUpperCase()}[${intl.formatMessage({
      id: timeUnitId,
    })}]`;
  }

  return `${intl.formatMessage({ id: 'relativeServiceLife' }).toUpperCase()}`;
};
