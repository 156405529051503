// TODO  react-hero-transition
import { Navbar } from '../components/navbar/Navbar';
import { Container } from '../components/containers/Container';
import { ApplicationStateProvider } from '../state/applicationState';
import { LanguageStateProvider, LanguageStateConsumer } from '../state/language/languageState';
import { RouteStateProvider, RouteStateConsumer } from '../state/routing/routeState';
import { localStorageMiddleware } from '../state/localStorageMiddleware';
import { sessionStorageMiddleware } from '../state/sessionStorageMiddleware';
import { routes } from '../state/routing/routes';
import { languages } from '../state/language/languages';
import { IntlProvider } from 'react-intl';
import messages_en from '../translations/en.json';
import messages_sv from '../translations/sv.json';
import messages_st from '../translations/st.json';
import messages_zh from '../translations/zh.json';

import messages_es from '../translations/es.json';
import messages_fi from '../translations/fi.json';
import messages_fr from '../translations/fr.json';
import messages_it from '../translations/it.json';
import messages_pt from '../translations/pt.json';
import messages_ru from '../translations/ru.json';

import messages_pl from '../translations/pl.json';

import messages_de from '../translations/de.json';

const messages: Record<string, Record<string, string>> = {
  sv: messages_sv,
  en: messages_en,
  st: messages_st,
  pl: messages_pl,
  zh: messages_zh,
  ru: messages_ru,
  es: messages_es,
  fi: messages_fi,
  fr: messages_fr,
  it: messages_it,
  pt: messages_pt,
  de: messages_de,
};
import { version } from '../../package.json';
import { getInitialState } from '../state/initialState';
import { runStateMigrations } from '../state/migrations';

export const APP_VERSION = version;

export const WearCalc = () => {
  return (
    <ApplicationStateProvider
      initialState={getInitialState()}
      middlewares={[localStorageMiddleware('state', runStateMigrations)]}
    >
      {subscribe => (
        <LanguageStateProvider
          initialState={{
            version: '2',
            languages,
            selectedLanguage: languages[0], // 'English'
          }}
          middlewares={[localStorageMiddleware('language')]}
        >
          <LanguageStateConsumer>
            {({ state }) => (
              <IntlProvider locale={state.selectedLanguage.id} messages={messages[state.selectedLanguage.id]}>
                <RouteStateProvider
                  initialState={{
                    routes,
                    currentRoute: 'start',
                  }}
                  middlewares={[sessionStorageMiddleware('routing')]}
                >
                  {/* <TransitionProvider
                    renderer={
                      cssTransition({
                        duration: 500
                      } as any) as any
                    }
                  > */}
                  <>
                    <Navbar />
                    <RouteStateConsumer>
                      {({ state: { currentRoute } }) => <Container currentRoute={currentRoute} subscribe={subscribe} />}
                    </RouteStateConsumer>
                  </>
                  {/* </TransitionProvider> */}
                </RouteStateProvider>
              </IntlProvider>
            )}
          </LanguageStateConsumer>
        </LanguageStateProvider>
      )}
    </ApplicationStateProvider>
  );
};
