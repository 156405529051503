import React, { Component, ReactNode, createRef } from 'react';
import { Column } from '../../components/containers/Column';
import { Row } from '../../components/containers/Row';
import { PrimaryButton, Dropdown, DropdownMenuItemType } from '@fluentui/react';
import styled from 'styled-components';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { AbrasiveType, Abrasive, AbrasiveComponent, Route, CalculationType } from '../../types';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { getAbrasives } from '../../data/data';
import { RouteStateConsumer } from '../../state/routing/routeState';
import { inputHighlightColor } from '../../utils/constants/colors';
import { ModalController, ModalControllerRenderProps } from '../modal/Modal';
import { AbrasiveModal } from './AbrasiveModal';
import { themeHardox } from '../../utils/themes/themes';

const SelectAbrasiveWrapper = styled.div`
  padding-top: 20px;
`;

const generateAbrasiveDropdownOptions = (abrasives: Abrasive[]) => {
  return abrasives.map(a => ({
    key: a.type!.toString() + '-' + a.id,
    text: a.name,
  }));
};

class AbrasiveDropdown extends React.PureComponent<
  {
    previousRoute: Route | undefined;
    abrasives: Array<Abrasive>;
    selectedAbrasive: Abrasive | undefined;
    setAbrasive: (abrasive: Abrasive) => void;
    selectCustomAbrasive: () => void;
    placeholder: string;
    calculationType: CalculationType;
    customAbrasiveName: string;
    setCustomAbrasiveName: (customAbrasiveName: string) => void;
    customAbrasiveTranslation: string;
  },
  { renderDropdownOptions: boolean }
> {
  state = {
    renderDropdownOptions: false,
  };

  /* Generate options for abrasive dropdown. */
  generateAbrasiveOptions = (selectedAbrasive: Abrasive | undefined) => {
    if (!this.state.renderDropdownOptions) {
      return this.props.selectedAbrasive === undefined
        ? [
            {
              key: 'Placeholder',
              text: this.props.placeholder,
              itemType: DropdownMenuItemType.Normal,
            },
          ]
        : [
            {
              key: this.getSelectedKey(this.props.selectedAbrasive),
              text:
                this.props.selectedAbrasive.name !== ''
                  ? this.props.selectedAbrasive.name
                  : this.props.customAbrasiveName,
              itemType: DropdownMenuItemType.Normal,
            },
          ];
    }

    const abrasives = getAbrasives().sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    let calculationAbrasives = Array<Abrasive>();
    let primaryInCalculation = Array<Abrasive>();
    const calculationAbrasiveHeader = [];
    switch (this.props.calculationType) {
      case CalculationType.Sliding:
        calculationAbrasives = abrasives.filter(abrasive => abrasive.excludeInSliding !== true);
        primaryInCalculation = abrasives.filter(x => x.primaryInSliding);
        break;
      case CalculationType.Impact:
        calculationAbrasives = abrasives.filter(abrasive => abrasive.excludeInImpact !== true);
        primaryInCalculation = abrasives.filter(x => x.primaryInImpact);

        break;
      case CalculationType.Erosion:
        primaryInCalculation = abrasives.filter(x => x.primaryInErosion);
        if (primaryInCalculation.length > 0) {
          calculationAbrasiveHeader.push({
            key: 'Erosion',
            text: 'Erosion Abrasives',
            itemType: DropdownMenuItemType.Header,
          });
        }

        calculationAbrasives = abrasives.filter(
          abrasive => abrasive.excludeInErosion !== true && abrasive.primaryInErosion !== true,
        );

        break;
      case CalculationType.Tipping:
        calculationAbrasives = abrasives.filter(abrasive => abrasive.excludeInTipping !== true);
        primaryInCalculation = abrasives.filter(x => x.primaryInTipping);
        break;
      case CalculationType.Mining:
        calculationAbrasives = abrasives.filter(abrasive => abrasive.excludeInMining !== true);
        primaryInCalculation = abrasives.filter(x => x.primaryInMining);
        break;
    }

    const calculationPrimary = generateAbrasiveDropdownOptions(primaryInCalculation);

    const commonRocksDropdownOptions = generateAbrasiveDropdownOptions(
      calculationAbrasives.filter(ab => {
        return ab.type === AbrasiveType.CommonRock;
      }),
    );
    const otherMaterialDropdownOptions = generateAbrasiveDropdownOptions(
      calculationAbrasives.filter(ab => {
        return ab.type === AbrasiveType.OtherMaterial;
      }),
    );

    const otherMaterialComboDropdownOptions = generateAbrasiveDropdownOptions(
      calculationAbrasives.filter(ab => {
        return ab.type === AbrasiveType.OtherMaterialCombo;
      }),
    );

    let customAbrasiveText = this.props.customAbrasiveTranslation;

    if (selectedAbrasive && selectedAbrasive.id === 0) {
      if (selectedAbrasive.name !== '') {
        customAbrasiveText = selectedAbrasive.name;
      }
    }

    /* Structure of abrasive dropdown options. */
    const abrasiveDropdownOptions = [
      {
        key: AbrasiveType.Custom + '-0',
        text: customAbrasiveText,
        itemType: DropdownMenuItemType.Normal,
      },
      ...calculationAbrasiveHeader,
      ...calculationPrimary,

      {
        key: 'CommonRocksHeader',
        text: 'Common Rocks',
        itemType: DropdownMenuItemType.Header,
      },
      ...commonRocksDropdownOptions,

      {
        key: 'OtherMaterialsHeader',
        text: 'Other Materials',
        itemType: DropdownMenuItemType.Header,
      },
      ...otherMaterialDropdownOptions,
      ...otherMaterialComboDropdownOptions,
    ];

    /* Placeholder just needed on first select because otherwise Ui Fabric auto-selects Custom abrasive which results in modal open etc. */
    if (selectedAbrasive === undefined) {
      abrasiveDropdownOptions.unshift({
        key: 'Placeholder',
        text: this.props.placeholder,
        itemType: DropdownMenuItemType.Normal,
      });
    }
    return abrasiveDropdownOptions;
  };

  /* Determine which abrasive is currently selected. */
  getSelectedKey = (selectedAbrasive: Abrasive | undefined) => {
    let selectedKey = '';

    if (selectedAbrasive) {
      const selectedAbrasiveType = selectedAbrasive.type;

      selectedKey = selectedAbrasiveType + '-' + selectedAbrasive.id;
    }

    return selectedKey;
  };

  componentDidMount() {
    /**
     * Rendering the dropdown options takes quite a lot of time,
     * causing a noticable delay when selecting for example "sliding"
     *
     * As these are only visible when opening the dropdown they are
     * not immediately needed on the first frame so we can render them
     * a bit later for faster performance.
     *
     * Using a double rAF to get to the next frame and then a small
     * delay so that animations surely have started.
     */
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        if (window.requestIdleCallback === undefined) {
          setTimeout(() => {
            this.setState({ renderDropdownOptions: true });
          }, 50);
        } else {
          window.requestIdleCallback(() => {
            this.setState({ renderDropdownOptions: true });
          });
        }
      });
    });
  }

  render() {
    const { abrasives, selectedAbrasive, selectCustomAbrasive, setAbrasive, placeholder } = this.props;

    return (
      <Dropdown
        selectedKey={this.getSelectedKey(selectedAbrasive)}
        onChange={(_event, option) => {
          const optionSplit = option!.key.toString().split('-');
          const optionType = optionSplit[0];
          const optionId = optionSplit[1];

          if (optionType !== AbrasiveType.Custom) {
            setAbrasive(abrasives.find(abr => abr.id === +optionId)!);
            this.props.setCustomAbrasiveName(this.props.customAbrasiveTranslation);
          } else {
            selectCustomAbrasive();
          }
        }}
        placeholder={placeholder}
        options={this.generateAbrasiveOptions(selectedAbrasive)}
        styles={{
          title: {
            backgroundColor: inputHighlightColor,
            borderRadius: '2px 0 0 2px',
            borderRight: 'none',
          },
        }}
        calloutProps={{
          calloutMaxHeight: 385,
          directionalHintFixed: true,
        }}
      />
    );
  }
}
type InnerSelectAbrasiveProps = WrappedComponentProps &
  ModalControllerRenderProps & {
    previousRoute: Route | undefined;
    currentRoute: Route;
    abrasiveProperties?: ReactNode;
    calculationType: CalculationType;
    setCalculationModeAbrasive: (abrasive: Abrasive) => void;
    selectedAbrasive: Abrasive | undefined;
  };
const InnerSelectAbrasive = injectIntl(
  class AbrasiveClass extends Component<
    InnerSelectAbrasiveProps,
    {
      selectedAbrasiveComponents: Array<AbrasiveComponent>;
      modalTitle: string;
      abrasiveWrappers: Array<Abrasive>;
      customAbrasiveName: string;
    }
  > {
    constructor(props: InnerSelectAbrasiveProps) {
      super(props);

      this.state = {
        selectedAbrasiveComponents: [],
        modalTitle: this.props.intl.formatMessage({ id: 'customAbrasive' }),
        abrasiveWrappers: [],
        customAbrasiveName: this.props.intl.formatMessage({
          id: 'customAbrasive',
        }),
      };
    }

    setCustomAbrasiveName = (customAbrasiveName: string) => {
      this.setState({
        customAbrasiveName,
      });
    };

    setSelectedAbrasiveComponents = (abrasivesComponentsToSelect: Array<AbrasiveComponent>) => {
      this.setState({
        selectedAbrasiveComponents: abrasivesComponentsToSelect,
      });
    };

    setCustomAbrasive = () => {
      //Clear minerals if custom abrasive.
      this.setSelectedAbrasiveComponents([]);
    };

    setModalTitle = (newModalTitle: string) => {
      this.setState({
        modalTitle: newModalTitle,
      });
    };

    selectCustomAbrasive = () => {
      this.setCustomAbrasive();
      this.props.openModal();
    };

    render() {
      const { intl, openModal, showModal, closeModal } = this.props;

      return (
        <>
          <ApplicationStateConsumer>
            {({ state: { abrasives, abrasiveComponents } }) => (
              <SelectAbrasiveWrapper>
                <Row>
                  <Column $size={10}>
                    <AbrasiveDropdown
                      previousRoute={this.props.previousRoute}
                      abrasives={abrasives}
                      selectedAbrasive={this.props.selectedAbrasive}
                      setAbrasive={this.props.setCalculationModeAbrasive}
                      selectCustomAbrasive={this.selectCustomAbrasive}
                      placeholder={intl.formatMessage({
                        id: 'selectAnAbrasive',
                      })}
                      calculationType={this.props.calculationType}
                      customAbrasiveName={this.state.customAbrasiveName}
                      setCustomAbrasiveName={this.setCustomAbrasiveName}
                      customAbrasiveTranslation={this.props.intl.formatMessage({
                        id: 'customAbrasive',
                      })}
                    />
                  </Column>
                  <Column $size={2}>
                    <PrimaryButton
                      theme={themeHardox}
                      styles={{
                        root: {
                          minWidth: '100%',
                          borderRadius: '0 2px 2px 0',
                        },
                      }}
                      onClick={openModal}
                    >
                      ...
                    </PrimaryButton>
                    <AbrasiveModal
                      intl={intl}
                      showModal={showModal}
                      closeModal={closeModal}
                      selectedAbrasive={this.props.selectedAbrasive}
                      selectedAbrasiveComponents={this.state.selectedAbrasiveComponents}
                      abrasives={abrasives}
                      abrasiveComponents={abrasiveComponents}
                      setSelectedAbrasiveComponents={this.setSelectedAbrasiveComponents}
                      setAbrasive={this.props.setCalculationModeAbrasive}
                      customAbrasiveName={this.state.customAbrasiveName}
                      setCustomAbrasiveName={this.setCustomAbrasiveName}
                    />
                  </Column>
                </Row>
                {this.props.abrasiveProperties}
              </SelectAbrasiveWrapper>
            )}
          </ApplicationStateConsumer>
        </>
      );
    }
  },
);

export const SelectAbrasive = (props: {
  abrasiveProperties?: ReactNode;
  calculationType: CalculationType;
  selectedAbrasive: Abrasive | undefined;
  setCalculationModeAbrasive: (abrasive: Abrasive) => void;
}) => {
  const modalRef = createRef<ModalController>();
  return (
    <RouteStateConsumer>
      {({ previousRoute, state: { currentRoute } }) => (
        <ModalController ref={modalRef}>
          {modalProps => (
            <InnerSelectAbrasive
              {...props}
              {...modalProps}
              previousRoute={previousRoute}
              currentRoute={currentRoute}
              calculationType={props.calculationType}
              setCalculationModeAbrasive={props.setCalculationModeAbrasive}
            />
          )}
        </ModalController>
      )}
    </RouteStateConsumer>
  );
};
