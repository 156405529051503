import { UnitSystemOption } from '../../types';

export const calculateDecimals = (
  numberOfDecimals: number | undefined,
  selectedUnitSystem: UnitSystemOption | undefined,
) => {
  if (numberOfDecimals !== undefined) {
    return numberOfDecimals;
  }
  if (selectedUnitSystem === 'imperial') {
    return 3;
  }
  if (selectedUnitSystem === 'metric') {
    return 1;
  }
  return 0;
};
