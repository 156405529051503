import styled from 'styled-components';
import { neutralSecondary, neutralTertiary, redHardox } from '../../utils/constants/colors';
import { fontSizeS, fontSizeM, fontSizeL, fontWeightSemiBold } from '../../utils/constants/typography';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export const CalloutHeader = styled.div`
  margin-bottom: 10px;
  font-size: ${fontSizeL};
  font-weight: ${fontWeightSemiBold};
  color: ${redHardox};
`;

export const InputLabel = styled.div<{ labelHighlight?: boolean }>`
  font-size: ${fontSizeS};

  font-weight: ${props => (props.labelHighlight ? 600 : 100)};

  color: ${(props: { labelHighlight?: boolean }) => (props.labelHighlight ? redHardox : neutralTertiary)};

  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TranslatedInputLabel = injectIntl(
  ({
    intl,
    translationId,
    style,
  }: WrappedComponentProps & {
    translationId: string;
    style?: React.CSSProperties;
  }) => (
    <InputLabel title={intl.formatMessage({ id: translationId })} style={style}>
      {intl.formatMessage({ id: translationId })}
    </InputLabel>
  ),
);

export const GroupHeader = styled.div`
  font-size: ${fontSizeM};
  font-weight: ${fontWeightSemiBold};
  color: ${neutralSecondary};
  margin-bottom: 10px;
`;
