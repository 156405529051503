if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
     // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true
  });
}


/*

1. Calculate RHtot. RhTot is the sum of all minerals [(Mineral1.hardness * Mineral1.fraction) / steel.hardness]+[...]
    RH = Mineral.Hardness / Steel.Hardness
    RHtot = Sum[Mineral1 * Volym1 + Mineral2 * Volym2]

2. Calculate Bcoef from RHtot according to:
    ekv 1	Wear per tipping=12,188*RHtot+11,805			if Rhtot < 1,33
    Ekv 2	Wear per tipping=100,4*RHtot-104,53			if 1,85 < Rhtot > 1,33
    Ekv 3	Wear per tipping=43,658*RHtot+0,715			if Rhtot > 1,85

    Bcoef = (Wear per tipping) * 10^(-9) * (Length Tipper)

3. Tweak Bcoef according to selected rock and dumper type. CorrectedBCoef = Bcoef * rocksize.DumperEnergy.

4. Simulate x number of tippings and for each round calulates dent factor according to Dfac = (RockEnergy) / (PlateThickness * Hardness).

    4.1
    If stiffener is being used, energy is approximated to 2000. Else, use current rocksize energy based on dumper type.
        rockEnergy = useStiffener ? 2000 : rockSize.uShapeEnergy;
        DentFactor = rockEnergy / plateThickness / steelHardness;

    4.2
    Calculate Dent Componesation depending on value of DentFactor (Dfac).
        If DentFactor < 0,2  =>  DentCompensation = 1
        If 0,2 < DentFactor < 0,36 => DentCompensation = 0,3125xDfac+0,9325
        If 0,36 < DentFactor < 0,68 => DentComponesation = 0,46875xDfac+0,88125
        If 0,68 < DentFactor => DentComponsation = 0,9375xDfac+0,5625

    4.3
    Wear for each part of the simulation is calculated with
    TippingWear = correctedBcoef * dentWear.dentComp * TIPPINGS_EACH_ROUND;

    4.4
    Total wear is the sum of each TippingWear.
 */

const timeUnits = [
  { id: "hours", name: "Hours" },
  { id: "days", name: "Days" },
  { id: "months", name: "Months" },
  { id: "years", name: "Years" },
];

function roundTo(value, decimals) {
  const roundingFactor = Math.pow(10, decimals);
  return Math.round(value * roundingFactor) / roundingFactor;
}
if (typeof self !== "undefined") {
  self.addEventListener(
    "message",
    function(e) {
      switch (e.data.cmd) {

        case "calculateNumberOfTippingsUntilWornOut":
          var calcResult = calculateNumberOfTippingsUntilWornOut(
            e.data.data.selectedAbrasive,
            e.data.data.steel,
            e.data.data.steelThickness,
            e.data.data.wearProtection,
            e.data.data.miningProperties,
            e.data.data.selectedUnitSystem,
            e.data.data.sectionIndex
          );
          self.postMessage({result: calcResult, messageId: e.data.messageId});
          break;

        case "calculateMiningWearDistribution":
          var wearResult = calculateMiningWearDistribution(
            e.data.data.selectedAbrasive,
            e.data.data.miningSteelStates,
            e.data.data.miningProperties,
            e.data.data.currentMiningSteels,
            e.data.data.selectedUnitSystem,
            e.data.data.isSide
          );
          self.postMessage({ result: wearResult, messageId: e.data.messageId });
          break;
        case "calculateTippingWearDistribution":
          var tippingWearResult = calculateTippingWearDistribution(
            e.data.data.steel,
            e.data.data.tippingProperties,
            e.data.data.selectedAbrasive,
            e.data.data.steelThickness,
            e.data.data.numberOfTippings,
            e.data.data.selectedUnitSystem
          );
          self.postMessage({
            result: tippingWearResult,
            messageId: e.data.messageId
          });
          break;
        case "caluclateTippingServiceLifeForSteels":
          var serviceLifeForSteelsResult = calculateTippingServiceLifeForSteels(
            e.data.data.steel,
            e.data.data.tippingProperties,
            e.data.data.selectedAbrasive,
            e.data.data.plateThickness,
            e.data.data.selectedUnitSystem
          );
          self.postMessage({
            result: serviceLifeForSteelsResult,
            messageId: e.data.messageId
          });
          break;
      }
    },
    false
  );
}

/**
 * @param {number} hours
 * @param {*} timeUnit
 * @returns {number}
 */
export function convertHoursToSelectedTimeUnit(hours, timeUnit) {
  switch (timeUnit.id) {
    //Hours
    case timeUnits[0].id:
      return hours;
    //Days
    case timeUnits[1].id:
      return hours / 24;
    //Months
    case timeUnits[2].id:
      return hours / 730;
    //Years
    default:
      return hours / 8760;
  }
}

/* Takes the entered time and time unit and converts to years. */
function convertTimeToYears(time, timeUnit) {
  switch (timeUnit.id) {
    case timeUnits[0].id:
      return time / (365 * 24);
    case timeUnits[1].id:
      return time / 365;
    case timeUnits[2].id:
      return time / 12;
    default:
      return time;
  }
}

function calculateWearDistribution(
  wear,
  startLength,
  endLength,
  thicknessDelta
) {
  var wearValues = [];
  for (var i = startLength; i < endLength; i += 100) {
    const calcWear = wear * Math.exp(-0.00028 * i);
    wearValues.push(calcWear / thicknessDelta);
  }
  return wearValues;
}

function calculateWearDistributionWithStiffener(
  wear,
  wearWithStiffener,
  startLength,
  endLength,
  thicknessDelta
) {
  var wearValues = [];
  for (var i = startLength; i < endLength; i += 100) {
    if (i === 1500) {
      wearValues.push(wearWithStiffener / thicknessDelta);
    } else {
      const calcWear = wear * Math.exp(-0.00028 * i);
      wearValues.push(calcWear / thicknessDelta);
    }
  }
  return wearValues;
}

function getCalculatedBCoef(
  selectedAbrasive,
  steelHardness,
  tipperLength,
  rockSize,
  tipperType
) {
  const rhTot = calculateRHtot(selectedAbrasive, steelHardness);
  const bCoef = calculateBcoef(rhTot, tipperLength);

  return correctBcoefByRockSize(bCoef, rockSize, tipperType);
}

function calculateRHtot(selectedAbrasive, steelHardness) {
  return selectedAbrasive.abrasiveComponents.reduce(function(ac, min) {
    const mineralHardness = min.hardness2 ? (min.hardness1 + min.hardness2) / 2 : min.hardness1;
    const vfH = (mineralHardness * min.fraction) / 100 / steelHardness;
    return ac + vfH;
  }, 0);
}

/* Bcoef = (Wear per tipping) * 10^(-9) * (Length Tipper). Constants from Excel sheet.  */
function calculateBcoef(rhTot, tipperLength) {
  if (rhTot <= 1.33) {
    return (12.188 * rhTot + 11.805) * Math.pow(10, -9) * tipperLength;
  } else if (rhTot <= 1.85) {
    return (100.4 * rhTot - 104.53) * Math.pow(10, -9) * tipperLength;
  } else {
    return (43.658 * rhTot + 0.715) * Math.pow(10, -9) * tipperLength;
  }
}

/* Correction for bCoef depending on rock size and DumperType. Constants from Excel sheet. */
function correctBcoefByRockSize(bCoef, rockSize, tipperType) {


  if (tipperType === "uShape") {
    return bCoef * rockSize.uShapeCorrectionValue;
  } else if (tipperType === "box") {
    return bCoef * rockSize.boxCorrectionValue;
  } else if (tipperType === "mining") {
    return bCoef * rockSize.miningCorrectionValue;
  }
}

function simulateTippings(
  correctedBcoef,
  steelHardness,
  steelThickness,
  useStiffener,
  useWearProtection,
  rockSize,
  tipperType,
  shouldContinueSimulation
) {
  var dynamicThickness = steelThickness;

  const rockEnergy = calculateRockEnergy(
    rockSize,
    tipperType,
    useStiffener
  );

  var totalWear = 0;
  var numberOfTippings = 0;

  // Prevent inifnite loops if abrasive is to soft.
  var maxCycles = 3_000_000;
  while (shouldContinueSimulation(totalWear, numberOfTippings) && numberOfTippings < maxCycles) {
    const dentComp = calculateDentWear(
      dynamicThickness,
      steelHardness,
      rockEnergy
    );
    var calculatedWear = correctedBcoef * dentComp;

    //If stiffener is used, we only care about the wear at the stiffener at 1500mm from rear.
    if(useStiffener){
      calculatedWear = calculatedWear * Math.exp(-0.00028 * 1500)
    }
    if (useWearProtection) {
      calculatedWear = calculatedWear / 2.1;
    }


    dynamicThickness -= calculatedWear;
    totalWear += calculatedWear;

    numberOfTippings += 1;
  }


  return {
    count: Math.round(numberOfTippings),
    totalWear: totalWear
  }
}


function calculateRockEnergy(rockSize, selectedTipperType, useStiffener) {
  var rockEnergy = 0;
  if (selectedTipperType === "uShape") {
    rockEnergy = useStiffener ? 2000 : rockSize.uShapeEnergy;
  } else if (selectedTipperType === "box") {
    rockEnergy = useStiffener ? 1500 : rockSize.boxEnergy;
  } else if (selectedTipperType === "mining") {
    rockEnergy = rockSize.miningEnergy;
  }

  return rockEnergy;
}

function calculateDentWear(plateThickness, steelHardness, rockEnergy) {
  const dentFactor = rockEnergy / plateThickness / steelHardness;

  return calculateWearWithDent(dentFactor);
}

function calculateWearWithDent(dentFactor) {
  if (dentFactor > 0.2 && dentFactor <= 0.36) {
    return 0.3125 * dentFactor + 0.9325;
  } else if (dentFactor > 0.36 && dentFactor <= 0.68) {
    return 0.46875 * dentFactor + 0.88125;
  } else if (dentFactor > 0.68) {
    return 0.9375 * dentFactor + 0.5625;
  } else {
    return 1;
  }
}

/********************************************************** */

function calculateMiningServiceLifeForSteels(
  steels,
  tipperLength,
  miningProperties,
  selectedAbrasive,
  plateThickness,
  selectedUnitSystem,
  useWearProtection,
  sectionIndex
) {
  var serviceLifeResult = [];

  const tipperLengthConverted = convertLength(tipperLength, selectedUnitSystem);
  const plateThicknessConverted = convertLength(
    plateThickness,
    selectedUnitSystem
  );
  const wornOutThicknessConverted = convertLength(
    miningProperties.wornOutThickness,
    selectedUnitSystem
  );

  const rockSize = rockSizes.find(function(s){
    return s.id === miningProperties.rockSizeId
  });

  for (var i = 0; i < steels.length; i++) {
    const correctedBCoef = getCalculatedBCoef(
      selectedAbrasive,
      steels[i].hardnessHV,
      tipperLengthConverted,
      rockSize,
      miningProperties.tipperType
    );

    const result = simulateTippings(
      correctedBCoef,
      steels[i].hardnessHV,
      plateThickness,
      false,
      useWearProtection,
      rockSize,
      miningProperties.tipperType,
      function(wear, _tippingCount) {
        return (
          wear *
            Math.exp(-0.00028 * (sectionIndex * (tipperLengthConverted / 3))) <
          plateThicknessConverted - wornOutThicknessConverted
        );
      }
    );

    serviceLifeResult.push(result.count);
  }

  return serviceLifeResult;
}


export function calculateNumberOfTippingsUntilWornOut(
  selectedAbrasive,
  steel,
  steelThickness,
  wearProtection,
  miningProperties,
  selectedUnitSystem,
  sectionIndex
){

  if(!steel){
    return 0;
  }

  const tipperLengthConverted = convertLength(
    miningProperties.tipperLength,
    selectedUnitSystem
  );

  const wornOutThicknessConverted = convertLength(
    miningProperties.wornOutThickness,
    selectedUnitSystem
  );

  const rockSize = rockSizes.find(function(s){
    return s.id === miningProperties.rockSizeId
  });


  const steelThicknessConverted = convertLength(steelThickness,selectedUnitSystem);

  const steelHardnessHV = steel.hardnessHV;

  const correctedBCoef = getCalculatedBCoef(
    selectedAbrasive,
    steelHardnessHV,
    tipperLengthConverted,
    rockSize,
    miningProperties.tipperType
  );



  const result = simulateTippings(
    correctedBCoef,
    steelHardnessHV,
    steelThicknessConverted,
    false,
    wearProtection,
    rockSize,
    miningProperties.tipperType,
    function(wear, _tippingCount) {
      return (
        wear * Math.exp(-0.00028 * (sectionIndex * (tipperLengthConverted / 3))) <
        steelThicknessConverted - wornOutThicknessConverted
      );
    }
  );


  return result.count;
}



/* Used for both mining truck bottom and sides. If isSide is set to true, caluclations are made using only steel B, steelB thickness and wear protection is set to false. */
export function calculateMiningWearDistribution(
  selectedAbrasive,
  miningSteelStates,
  miningProperties,
  currentMiningSteels,
  selectedUnitSystem,
  isSide
) {
  if (selectedAbrasive === undefined) {
    return [];
  }

  var totalNumberOfTippings =
    miningProperties.heatMapTippings;

  const tipperLength = convertLength(
    miningProperties.tipperLength,
    selectedUnitSystem
  );

  //Check if any of steels are undefined
  if (
    miningSteelStates.some(function(steelState) {
      return steelState.steel === undefined;
    })
  ) {
    return [];
  }

  const rockSize = rockSizes.find(function(s){
    return s.id === miningProperties.rockSizeId
  });


  var wearDistributionArray = [];
  miningSteelStates.forEach(function(steelState, i) {
    if (steelState.steel !== undefined) {
      var steel = steelState.steel;
      var steelThickness = convertLength(steelState.steelThickness, selectedUnitSystem);

      /*
      if (isSide && miningSteelStates[1].steel !== undefined) {
        steel = miningSteelStates[1].steel;
        steelThickness = miningSteelStates[1].steelThickness;
      }*/

      const correctedBCoef = getCalculatedBCoef(
        selectedAbrasive,
        steel.hardnessHV,
        tipperLength,
        rockSize,
        miningProperties.tipperType
      );

      const result = simulateTippings(
        correctedBCoef,
        steel.hardnessHV,
        steelThickness,
        false,
        isSide ? false : currentMiningSteels[i].wearProtection,
        rockSize,
        miningProperties.tipperType,
        function(_wear, tippingCount) {
          return (
            totalNumberOfTippings === 0 || tippingCount < totalNumberOfTippings
          );
        }
      );

      const delta = steelThickness - convertLength(miningProperties.wornOutThickness, selectedUnitSystem);

      wearDistributionArray.push(
        calculateWearDistribution(
          result.totalWear,
          (tipperLength / 3) * i,
          (tipperLength / 3) * (i + 1),
          delta
        )
      );
    }
  });

  return wearDistributionArray;
}



/************************************************************************** */

export function calculateTippingWearDistribution(
  steel,
  tippingProperties,
  selectedAbrasive,
  steelThickness,
  numberOfTippings,
  selectedUnitSystem
) {
  if (selectedAbrasive === undefined || steel === undefined) {
    return [];
  }

  const tipperLengthConverted = convertLength(
    tippingProperties.tipperLength,
    selectedUnitSystem
  );

  const wornOutThicknessConverted = convertLength(
    tippingProperties.currentSteelState.wornOutThickness,
    selectedUnitSystem
  );

  const plateThicknessConverted = convertLength(
    steelThickness,
    selectedUnitSystem
  );



  const rockSize = rockSizes.find(function(s){
    return s.id === tippingProperties.rockSizeId
  });



  /* Thickness that can be used before it should be regarded as worn out. */
  const thicknessDelta = plateThicknessConverted - wornOutThicknessConverted;

  const correctedBCoef = getCalculatedBCoef(
    selectedAbrasive,
    steel.hardnessHV,
    tipperLengthConverted,
    rockSize,
    tippingProperties.tipperType
  );

  const simulationResult = simulateTippings(
    correctedBCoef,
    steel.hardnessHV,
    plateThicknessConverted,
    false,
    false,
    rockSize,
    tippingProperties.tipperType,
    function(_wear, tippingCount) {
      return numberOfTippings === 0 || tippingCount < numberOfTippings;
    }
  );

  if (tippingProperties.useStiffener) {
    const simulationResultWithStiffener = simulateTippings(
      correctedBCoef,
      steel.hardnessHV,
      plateThicknessConverted,
      true,
      false,
      rockSize,
      tippingProperties.tipperType,
      function(_wear, tippingCount) {
        return numberOfTippings === 0 || tippingCount < numberOfTippings;
      }
    );

    const wearDistributionWithStiffener = calculateWearDistributionWithStiffener(
      simulationResult.totalWear,
      simulationResultWithStiffener.totalWear,
      0,
      tipperLengthConverted,
      thicknessDelta
    );
    return wearDistributionWithStiffener;
  }

  return calculateWearDistribution(
    simulationResult.totalWear,
    0,
    tipperLengthConverted,
    thicknessDelta
  );
}

function calculateNumberOfTippings(
  steel,
  tippingProperties,
  selectedAbrasive,
  plateThickness,
  selectedUnitSystem
) {
  if (selectedAbrasive === undefined) {
    return 0;
  }

  const tipperLengthConverted = convertLength(
    tippingProperties.tipperLength,
    selectedUnitSystem
  );

  const plateThicknessConverted = convertLength(
    plateThickness,
    selectedUnitSystem
  );

  const wornOutThicknessConverted = convertLength(
    tippingProperties.currentSteelState.wornOutThickness,
    selectedUnitSystem
  );


  const rockSize = rockSizes.find(function(s){
    return s.id === tippingProperties.rockSizeId
  });


  const correctedBCoef = getCalculatedBCoef(
    selectedAbrasive,
    steel.hardnessHV,
    tipperLengthConverted,
    rockSize,
    tippingProperties.tipperType
  );

  const result = simulateTippings(
    correctedBCoef,
    steel.hardnessHV,
    plateThicknessConverted,
    false,
    false,
    rockSize,
    tippingProperties.tipperType,
    function(wear, _tippingCount) {
      return wear < plateThicknessConverted - wornOutThicknessConverted;
    }
  );

  var lowestResultCount = result.count;

  if (tippingProperties.useStiffener) {
    const resultWithStiffener = simulateTippings(
      correctedBCoef,
      steel.hardnessHV,
      plateThicknessConverted,
      true,
      false,
      rockSize,
      tippingProperties.tipperType,
      function(wear, _tippingCount) {
        return wear < plateThicknessConverted - wornOutThicknessConverted;
      }
    );

    if (resultWithStiffener.count < result.count) {
      lowestResultCount = resultWithStiffener.count;
    }
  }

  return lowestResultCount;
}

export function calculateTippingServiceLifeForSteels(
  steel,
  tippingProperties,
  selectedAbrasive,
  plateThickness,
  selectedUnitSystem
) {

  if (selectedAbrasive !== undefined) {
    const calculatedTippings = calculateNumberOfTippings(
      steel,
      tippingProperties,
      selectedAbrasive,
      plateThickness,
      selectedUnitSystem
    );
    return calculatedTippings;
  }

  return 0;
}

export function calculateThicknessFromServiceLife(
  steel,
  serviceLife,
  tippingProperties,
  applicationState
) {
  const tipperLengthConverted = convertLength(
    tippingProperties.tipperLength,
    applicationState.selectedUnitSystem
  );
  const plateThicknessConverted = convertLength(
    tippingProperties.currentSteelState.currentThickness,
    applicationState.selectedUnitSystem
  );

  const numberOfTippingsPerYear =
    tippingProperties.unloadsPerDay * tippingProperties.workingDaysPerYear;

  if (
    steel === undefined ||
    applicationState.selectedTippingAbrasive === undefined
  ) {
    return 0;
  }

  if (tippingProperties.upgradeSteelState.upgradeSteel === undefined) {
    return 0;
  }

  const numberOfYears = convertTimeToYears(
    serviceLife,
    tippingProperties.currentSteelState.serviceLifeTimeUnit
  );

  const targetNumberOfTippings = Math.round(
    numberOfTippingsPerYear * numberOfYears
  );

  if (targetNumberOfTippings === 0) {
    return 0;
  }



  const rockSize = rockSizes.find(function(s){
    return s.id === tippingProperties.rockSizeId
  });

  const correctedBCoef = getCalculatedBCoef(
    applicationState.selectedTippingAbrasive,
    steel.hardnessHV,
    tipperLengthConverted,
    rockSize,
    tippingProperties.tipperType
  );

  var thicknessToTest = plateThicknessConverted;
  var shouldContinue = true;

  //Test plate thickness until it matches the wear for entered service life.
  while (shouldContinue) {
    const result = simulateTippings(
      correctedBCoef,
      steel.hardnessHV,
      thicknessToTest,
      false,
      false,
      rockSize,
      tippingProperties.tipperType,
      function(_wear, tippingCount) {
        return targetNumberOfTippings > tippingCount;
      }
    );

    const totalWear = result.totalWear;

    shouldContinue = Math.abs(thicknessToTest - totalWear) > 0.1;

    if (thicknessToTest - totalWear > 0) {
      thicknessToTest -= Math.abs(thicknessToTest - totalWear) / 2;
    } else {
      thicknessToTest += Math.abs(thicknessToTest - totalWear) / 2;
    }
  }

  return roundTo(thicknessToTest, 1);
}


function convertLength(length, selectedUnitSystem) {
  if (selectedUnitSystem === "imperial") {
    return length * 25.4;
  }

  return length;
}

const rockSizes = [
  {
    id: 1,
    textMillimeters: "1-10 mm",
    textInches: "< 3/8 in",
    uShapeCorrectionValue: 0.7,
    uShapeEnergy: 98.2,
    boxCorrectionValue: 0.7,
    boxEnergy: 120,
    miningCorrectionValue: 0.7,
    miningEnergy: 120,
    includeInMining: true,
    includeInTipping: true
  },
  {
    id: 2,
    textMillimeters: "11-50 mm",
    textInches: "3/8-2 in",

    uShapeCorrectionValue: 0.78,
    uShapeEnergy: 253,
    boxCorrectionValue: 0.77,
    boxEnergy: 350,
    miningCorrectionValue: 0.77,
    miningEnergy: 350,
    includeInMining: true,
    includeInTipping: true
  },
  {
    id: 3,
    textMillimeters: "51-200 mm",
    textInches: "2-8 in",

    uShapeCorrectionValue: 0.9,
    uShapeEnergy: 353,
    boxCorrectionValue: 0.9,
    boxEnergy: 540,
    miningCorrectionValue: 0.9,
    miningEnergy: 540,
    includeInMining: true,
    includeInTipping: true
  },
  {
    id: 4,
    textMillimeters: "201-350mm",
    textInches: "8-14 in",
    uShapeCorrectionValue: 1,
    uShapeEnergy: 589,
    boxCorrectionValue: 1,
    boxEnergy: 820,
    miningCorrectionValue: 1,
    miningEnergy: 810,
    includeInMining: true,
    includeInTipping: true
  },
  {
    id: 5,
    textMillimeters: "351-500 mm",
    textInches: "14-20 in",
    uShapeCorrectionValue: 1.19,
    uShapeEnergy: 982,
    boxCorrectionValue: 1.24,
    boxEnergy: 1100,
    miningCorrectionValue: 1.24,
    miningEnergy: 1100,
    includeInMining: true,
    includeInTipping: true
  },
  {
    id: 6,
    textMillimeters: "501-1000 mm",
    textInches: "20-40 in",
    uShapeCorrectionValue: 0,
    uShapeEnergy: 0,
    boxCorrectionValue: 0,
    boxEnergy: 0,
    miningCorrectionValue: 1.38,
    miningEnergy: 1390,
    includeInMining: true,
    includeInTipping: false
  },
];