import { TimeUnit } from './state/timeUnits';

export type Point = {
  x: number;
  y: number;
};

export type Steel = {
  id: number;
  name: string;
  hardness: number;
  hardnessHV: number;
  includeInAbrasiveImpact?: boolean;
  includeInSliding?: boolean;
  includeInErosion?: boolean;
  includeInTipping?: boolean;
  includeInMining?: boolean;
  hiddenInCharts?: boolean;
  public?: boolean;
};

export type Abrasive = {
  id: number;
  name: string;
  abrasiveComponents: Array<AbrasiveComponent>;
  type: AbrasiveType.CommonRock | AbrasiveType.OtherMaterial | AbrasiveType.Custom | AbrasiveType.OtherMaterialCombo;
  excludeInSliding?: boolean;
  excludeInImpact?: boolean;
  excludeInErosion?: boolean;
  excludeInTipping?: boolean;
  excludeInMining?: boolean;
  primaryInSliding?: boolean;
  primaryInImpact?: boolean;
  primaryInErosion?: boolean;
  primaryInTipping?: boolean;
  primaryInMining?: boolean;
};

export type AbrasiveComponent = {
  id: number;
  name: string;
  hardness1: number;
  hardness2?: number;
  density: number;
  fraction: number;
  type: AbrasiveComponentType.Mineral | AbrasiveComponentType.OtherMaterial;
};

export type AbrasiveComponentWithoutFraction = {
  id: number;
  name: string;
  hardness1: number;
  hardness2?: number;
  density: number;
  type: AbrasiveComponentType.Mineral | AbrasiveComponentType.OtherMaterial;
};

export type DbAbrasiveComponent = {
  id: number;
  name: string;
  hardness1: number;
  hardness2?: number;
  density: number;
  excludeInSliding?: boolean;
  excludeInImpact?: boolean;
  excludeInErosion?: boolean;
  excludeInTipping?: boolean;
  excludeInMining?: boolean;
  primaryInSliding?: boolean;
  primaryInImpact?: boolean;
  primaryInErosion?: boolean;
  primaryInTipping?: boolean;
  primaryInMining?: boolean;
};

export type DbAbrasive = {
  id: number;
  name: string;
  minerals: Array<{
    id: number;
    volFrac: number;
    hardness1frac?: number;
  }>;
  type: AbrasiveType.CommonRock | AbrasiveType.OtherMaterial;
  excludeInSliding?: boolean;
  excludeInImpact?: boolean;
  excludeInErosion?: boolean;
  excludeInTipping?: boolean;
  excludeInMining?: boolean;
  primaryInSliding?: boolean;
  primaryInImpact?: boolean;
  primaryInErosion?: boolean;
  primaryInTipping?: boolean;
  primaryInMining?: boolean;
};

export enum AbrasiveComponentType {
  Mineral = 'mineral',
  OtherMaterial = 'otherMaterial',
}

export enum AbrasiveType {
  CommonRock = 'commonRock',
  OtherMaterial = 'otherMaterial',
  Custom = 'custom',
  OtherMaterialCombo = 'otherMaterialCombo',
}

export type Route = 'start' | 'sliding' | 'impact' | 'erosion' | 'tipping' | 'mining';

export type UpgradeMiningSteelState = {
  steel: Steel | undefined;
  steelThickness: number;
  name: string;
};

export type CurrentMiningSteelState = {
  steel: Steel | undefined;
  steelThickness: number;
  wearProtection: boolean;
  name: string;
};

export type CurrentSteelState = {
  currentSteel: Steel;
  currentThickness: number;
  currentServiceLife: number;
  currentTippings?: number;
  serviceLifeTimeUnit: TimeUnit;
  wornOutThickness: number;
};

export type UpgradeSteelState = {
  upgradeSteel: Steel | undefined;
  upgradeServiceLife: number;
  upgradeTippings?: number;
  upgradeThickness: number;
  upgradeBaseThickness?: number;
};

export type UnitSystemOption = 'metric' | 'imperial';

export enum ChartType {
  ServiceLife = 'serviceLife',
  Thickness = 'thickness',
  WearRate = 'wearRate',
  Summary = 'summary',
  HeatMap = 'heatMap',
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>;
};

export type RockSize = {
  id: number;
  textMillimeters: string;
  textInches: string;
  uShapeCorrectionValue: number;
  uShapeEnergy: number;
  boxCorrectionValue: number;
  boxEnergy: number;
  miningCorrectionValue: number;
  miningEnergy: number;
};
export enum TipperType {
  UShape = 'uShape',
  Box = 'box',
  Mining = 'mining',
}

export enum CalculationType {
  Sliding = 'sliding',
  Impact = 'impact',
  Erosion = 'erosion',
  Tipping = 'tipping',
  Mining = 'mining',
}
