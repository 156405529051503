export const PLATE_HARDNESS_HV = [150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900];

// TODO
// Vi använder dubbla arrayer, de korta för beräkningar och de långa enbart för att visa i grafern för sliding.
// Se till att avrunda värden i PLATE_HARDNESS_HB till närmaste heltal.
/*
export const PLATE_HARDNESS_HV = [
  150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370,
  380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590, 600,
  610, 620, 630, 640, 650, 660, 670, 680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790, 800, 810, 820, 830,
  840, 850, 860, 870, 880, 890, 900,
];*/

export const PLATE_HARDNESS_HB = [
  142.7, 189.9, 237.1, 284.1, 331, 377.8, 424.5, 471, 517.5, 563.8, 610.1, 656.2, 702.2, 748.3, 794.3, 840.3,
];

export const PLATE_HARDNESS_HB_CHART = [
  143, 152, 162, 171, 181, 190, 200, 209, 219, 228, 238, 247, 256, 265, 275, 284, 294, 303, 313, 322, 332, 341, 351,
  360, 370, 379, 388, 397, 406, 415, 424, 433, 443, 452, 462, 471, 480, 488, 498, 507, 516, 525, 535, 545, 555, 564,
  574, 584, 593, 601, 611, 620, 629, 638, 648, 657, 667, 676, 685, 695, 704, 713, 723, 732, 741, 751, 760, 769, 779,
  788, 797, 807, 816, 825, 835, 844,
];

export const PLASTIC_CURVE = [
  1.0, 0.92, 0.88, 0.79, 0.69, 0.59, 0.48, 0.35, 0.28, 0.22, 0.2, 0.19, 0.17, 0.15, 0.13, 0.12,
];

export const PLASTIC_CURVE_CHART = [
  1.0, 0.98, 0.97, 0.95, 0.94, 0.92, 0.91, 0.9, 0.9, 0.89, 0.88, 0.86, 0.84, 0.83, 0.81, 0.79, 0.77, 0.75, 0.73, 0.71,
  0.69, 0.67, 0.65, 0.63, 0.61, 0.59, 0.57, 0.55, 0.52, 0.5, 0.48, 0.45, 0.43, 0.4, 0.38, 0.35, 0.34, 0.32, 0.31, 0.29,
  0.28, 0.27, 0.26, 0.24, 0.23, 0.22, 0.22, 0.21, 0.21, 0.2, 0.2, 0.2, 0.2, 0.19, 0.19, 0.19, 0.19, 0.18, 0.18, 0.17,
  0.17, 0.17, 0.16, 0.16, 0.15, 0.15, 0.15, 0.14, 0.14, 0.13, 0.13, 0.13, 0.13, 0.12, 0.12, 0.12,
];

export const CUTTING_CURVE = [3.0, 2.65, 2.4, 2.15, 2.0, 1.75, 1.55, 1.35, 1.15, 0.95, 0.8, 0.7, 0.6, 0.55, 0.5, 0.5];

export const CUTTING_CURVE_CHART = [
  3.0, 2.93, 2.86, 2.79, 2.72, 2.65, 2.6, 2.55, 2.5, 2.45, 2.4, 2.35, 2.3, 2.25, 2.2, 2.15, 2.12, 2.09, 2.06, 2.03, 2.0,
  1.95, 1.9, 1.85, 1.8, 1.75, 1.71, 1.67, 1.63, 1.59, 1.55, 1.51, 1.47, 1.43, 1.39, 1.35, 1.31, 1.27, 1.23, 1.19, 1.15,
  1.11, 1.07, 1.03, 0.99, 0.95, 0.92, 0.89, 0.86, 0.83, 0.8, 0.78, 0.76, 0.74, 0.72, 0.7, 0.68, 0.66, 0.64, 0.62, 0.6,
  0.59, 0.58, 0.57, 0.56, 0.55, 0.54, 0.53, 0.52, 0.51, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
];

export const PLATE_HARDNESS_HV_END_CURVE = [
  150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370,
  380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590, 600,
  610, 620, 630, 640, 650, 660, 670, 680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790, 800, 810, 820, 830,
  840, 850, 860, 870, 880, 890, 900,
];

export const CUTTING_LIMIT = 2.1;

export const PLASTIC_LIMIT = 1.52;

export const M_WEIGHT = 3.6206897;

export const K_WEIGTH = -1.724138;
