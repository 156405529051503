import styled from 'styled-components';
import { grayHardox100 } from '../../utils/constants/colors';
import { fontWeightRegular } from '../../utils/constants/typography';

export const CardHeader = styled.div`
  color: ${grayHardox100};
  font-size: 18px;
  font-weight: ${fontWeightRegular};
  letter-spacing: 0.5px;
  display: flex;
  text-transform: uppercase;

  align-items: baseline;
`;
