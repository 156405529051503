import * as React from 'react';
import { HeatMapContentWrapper } from './HeatMapUtils';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Abrasive } from '../../../types';
import { TippingPropertiesState } from '../../../state/calculationModels';
import { MiningPropertiesState } from '../../../state/miningProperties';
import { grayHardox100 } from '../../../utils/constants/colors';
import { Spinner, SpinnerSize } from '@fluentui/react';
import debounce from 'just-debounce-it';

type HeatMapComponentProps<T> = React.PropsWithChildren<{
  calculationProperties: TippingPropertiesState | MiningPropertiesState;
  steelType: 'current' | 'upgrade';
  selectedAbrasive: Abrasive | undefined;
  steelSelected: boolean;
  minHeight?: number;
  wearData: T;
  hasData: boolean;
  isValid: boolean;
}> &
  WrappedComponentProps;
export const HeatMapComponent = injectIntl(
  class HeatMapComponentClass<T> extends React.Component<
    HeatMapComponentProps<T>,
    {
      isReady: boolean;
    }
  > {
    constructor(props: HeatMapComponentProps<T>) {
      super(props);

      this.state = {
        isReady: false,
      };
    }

    debouncedRenderHeatmap = debounce(this.renderHeatMap, 500);

    renderHeatMap() {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          window.requestIdleCallback(() => {
            if (this.props.selectedAbrasive !== undefined && this.props.steelSelected) {
              if (this.state.isReady === false) {
                this.setState({
                  isReady: true,
                });
              }
            }
          });
        });
      });
    }

    componentDidMount() {
      this.renderHeatMap();
    }

    componentDidUpdate(prevProps: this['props']) {
      if (this.props.wearData !== prevProps.wearData) {
        this.setState({
          isReady: false,
        });
      }
      this.debouncedRenderHeatmap();
    }

    render() {
      if (this.state.isReady && this.props.hasData && this.props.isValid) {
        return <>{this.props.children}</>;
      } else {
        const { selectedAbrasive, steelType, steelSelected, intl } = this.props;

        let calculating = false;
        let infoText = '';
        if (selectedAbrasive === undefined && !steelSelected) {
          infoText =
            steelType === 'current'
              ? intl.formatMessage({
                  id: 'selectAbrasiveAndCurrentSteel',
                })
              : intl.formatMessage({ id: 'selectAbrasiveAndUpgradeSteel' });
        } else if (selectedAbrasive === undefined) {
          infoText = intl.formatMessage({ id: 'selectAbrasive' });
        } else if (!steelSelected) {
          infoText =
            steelType === 'current'
              ? intl.formatMessage({ id: 'selectCurrentSteel' })
              : intl.formatMessage({ id: 'selectUpgradeSteel' });
        } else if (!this.props.isValid) {
          infoText = '';
        } else {
          infoText = intl.formatMessage({ id: 'calculating' });
          calculating = true;
        }

        return (
          <HeatMapContentWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                textAlign: 'center',
                minHeight: this.props.minHeight,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '25px',
                  color: grayHardox100,
                }}
              >
                {calculating && (
                  <div style={{ paddingBottom: 10 }}>
                    <Spinner size={SpinnerSize.large} />
                  </div>
                )}
                {infoText}
              </div>
            </div>
          </HeatMapContentWrapper>
        );
      }
    }
  },
);
