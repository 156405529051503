import styled from 'styled-components';
import { fontSizeM } from '../../utils/constants/typography';
import { neutralSecondary, neutralLight, neutralLighterAlt } from '../../utils/constants/colors';

// Wrapper

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  margin-bottom: 13px;
  cursor: ${props => {
    return props.disabled ? 'default' : 'pointer';
  }};
`;

// Label

const Label = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 3px;
  font-size: ${fontSizeM};
  color: ${(props: { disabled: boolean }) => {
    return props.disabled ? '#d0d0d0' : neutralSecondary;
  }};
  user-select: none;
`;

// Radio container

type RadioContainerProps = {
  checked: boolean;
  disabled: boolean;
};

const RadioContainer = ({ checked, disabled }: RadioContainerProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: 3,
        marginRight: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 3,
          backgroundColor: disabled ? neutralLight : neutralLight,
          borderRadius: '100%',
        }}
      >
        <div
          style={{
            display: 'block',
            position: 'relative',
            padding: 3,
            backgroundColor: disabled ? neutralLighterAlt : '#ffffff',
            borderRadius: '100%',
          }}
        >
          <div
            style={{
              opacity: !checked || disabled ? 0 : 1,
              display: 'block',
              position: 'relative',
              padding: 5,
              backgroundColor: disabled ? neutralLighterAlt : '#000000',
              borderRadius: '100%',
            }}
          />
        </div>
      </div>
    </div>
  );
};

// Radio

type RadioProps = {
  label: string;
  checked: boolean;
  disabled: boolean;
  onClick: () => void;
};

export const Radio = ({ label, checked, disabled = false, onClick }: RadioProps) => {
  return (
    <Wrapper disabled={disabled} onClick={onClick}>
      <RadioContainer checked={checked} disabled={disabled} />
      <Label disabled={disabled}>{label}</Label>
    </Wrapper>
  );
};
