import React from 'react';
import { TextField, ITextFieldStyleProps, ITextFieldStyles, IStyleFunctionOrObject } from '@fluentui/react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Steel } from '../../types';
import { hb, convertHBtoHV } from '../../utils/hardness/convertHardness';
import { CUSTOM_STEEL_ID } from '../../utils/constants/values';
import { inputErrorColor, inputHighlightColor } from '../../utils/constants/colors';

const hardnessInputStyles = (currentSteel: Steel | undefined, hasError: boolean) => {
  let inputStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
    field: { minWidth: '48px' },
  };

  if (currentSteel && currentSteel.id === CUSTOM_STEEL_ID) {
    inputStyles = {
      ...inputStyles,
      field: Object.assign(
        {
          backgroundColor: hasError ? inputErrorColor : inputHighlightColor,
        },
        inputStyles.field,
      ),
    };
  } else {
    inputStyles = {
      ...inputStyles,
      field: Object.assign(
        {
          backgroundColor: 'white',
        },
        inputStyles.field,
      ),
      fieldGroup: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#ffffff',
      },
    };
  }

  return inputStyles;
};

type Props = {
  currentSteel: Steel | undefined;
  setCurrentSteel: (steel: Steel) => void;
} & WrappedComponentProps;
type State = {
  inputValue: string;
  prevHardness: number;
};

export const CustomHardnessInput = injectIntl(
  class CustomHardnessInput extends React.Component<Props, State> {
    state = { inputValue: '', prevHardness: 0 };

    static getDerivedStateFromProps(props: Props, state: State | null): State | null {
      return state !== null && props.currentSteel && props.currentSteel.hardness === state.prevHardness
        ? null
        : {
            inputValue: props.currentSteel ? props.currentSteel.hardness.toString() : '',
            prevHardness: props.currentSteel ? props.currentSteel.hardness : 0,
          };
    }

    render() {
      const { currentSteel, setCurrentSteel } = this.props;
      return (
        <TextField
          type={currentSteel && currentSteel.id === CUSTOM_STEEL_ID ? 'number' : 'text'}
          value={this.state.inputValue}
          min={hb.min}
          max={hb.max}
          onChange={(_, input) => {
            if (+input! >= hb.min && +input! <= hb.max) {
              setCurrentSteel({
                id: CUSTOM_STEEL_ID,
                name: this.props.intl.formatMessage({ id: 'customSteel' }),
                hardness: +input!,
                hardnessHV: convertHBtoHV(+input!),
                includeInAbrasiveImpact: true,
              });
              this.setState({ inputValue: input! });
            } else {
              this.setState({ inputValue: input! });
            }
          }}
          suffix="HB"
          disabled={!(currentSteel && currentSteel.id === CUSTOM_STEEL_ID)}
          styles={hardnessInputStyles(
            currentSteel,
            +this.state.inputValue! < hb.min || +this.state.inputValue! > hb.max,
          )}
        />
      );
    }
  },
);
