import { Middleware } from './applicationState';

export function sessionStorageMiddleware<T>(key: string): Middleware<T> {
  return (state, { isInitialLoad, hasCrashed }) => {
    if (isInitialLoad && !hasCrashed) {
      try {
        return JSON.parse(window.sessionStorage.getItem(key)!) || state;
      } catch (_) {
        return state;
      }
    } else {
      window.sessionStorage.setItem(key, JSON.stringify(state));
      return state;
    }
  };
}
