import * as R from 'ramda';

export type DocxSettings = {
  sliding: boolean;
  impact: boolean;
  erosion: boolean;
  tipping: boolean;
  mining: boolean;
  reportType: 'details' | 'summary';
};

export type DocxSettingsProperty = Partial<DocxSettings>;

export const createNextDocxSettings = (property: DocxSettingsProperty, currentSettings: DocxSettings): DocxSettings =>
  R.mergeRight(currentSettings, property); //TODO changed from merge to mergeRight (possible errors)
