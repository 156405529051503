import React from 'react';
import { AbrasiveCard, PropertiesCard, SteelCard } from '../../components/card/Card';
import { ChartCard, ChartColumn } from '../../components/chart/ChartCard';
import { HeatMap, HeatMapSide } from '../../components/chart/heatMap/HeatMap';
import { SelectAbrasive } from '../../components/abrasive/Abrasive';
import { PropertiesColumn } from '../../components/containers/Container';
import {
  ChartType,
  Steel,
  CurrentMiningSteelState,
  UpgradeMiningSteelState,
  CalculationType,
  Abrasive,
  UnitSystemOption,
} from '../../types';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { CalculationPageWrapper } from '../../components/containers/Container';
import { PivotItem } from '@fluentui/react';
import { MiningCurrentSteelProperties } from './MiningCurrentSteelProperties';
import { MiningProperties } from './MiningProperties';
import { MiningUpgradeSteelProperties } from './MiningUpgradeSteelProperties';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ChartPivot } from '../../components/chart/ChartPivot';

import {
  HeatMapHeader,
  HeatMapHeaderText,
  HeatMapContentWrapper,
  HeatMapLegendWrapper,
  HeatMapWrapper,
  HeatMapContent,
  HeatMapHeaderRow,
  HeatMapHeaderWrapper,
  BottomLabel,
} from '../../components/chart/heatMap/HeatMapUtils';
import { constructSteelArray, getHardox450ReferenceSteel, getTranslatedSteels } from '../../utils/steelHelpers';
import { RelativeMiningServiceLifeChart } from '../../components/chart/relative/RelativeMiningServiceLifeChart';
import { timeUnits } from '../../state/timeUnits';
import { setStateArrayElement } from '../../utils/MiningSteelStateHelper';
import { HeatMapLegend } from '../../components/chart/heatMap/HeatMapLegend';
import { FlexRow } from '../tipping/Tipping';
import styled from 'styled-components';
import { getDumperWearMatrix, getDumperSideWearMatrix } from '../../data/WearDistribution/matrixDataHelper';
import { ThrottleChanges } from '../../components/ThrottleChanges';
import { LanguageStateConsumer } from '../../state/language/languageState';
import { HeatMapComponent } from '../../components/chart/heatMap/HeatMapComponent';
import { neutralLighter, ssabDarkGray } from '../../utils/constants/colors';
import { convertHoursToSelectedTimeUnit } from '../../miningAndTippingCalculations';
import { calculateMiningWearDistribution, calculateNumberOfTippingsUntilWornOut } from '../../workerManager';
import { MiningPropertiesState } from '../../state/miningProperties';
import { getSelectedUnitText } from '../../utils/convertUnits/convertUnits';
import {
  validateCurrentMiningInputs,
  validateMiningInputs,
  validateUpgradeMiningInputs,
} from '../../utils/validation/validateMiningCalculations';
import { getNormalizedSlidingServiceLifePoints, getSlidingServiceLifeDataPoints } from '../sliding/Sliding';
import { HeatMapInputs } from '../../components/chart/heatMap/HeatMapInputs';
import { roundTo } from '../../utils/numberFormatting';

const SectionDescriptionWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-around;
`;

const SectionDescription = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-family: 'Calibri';
`;

const HeatmapTopWrapper = styled.div`
  background-color: ${neutralLighter};
  padding: 10px;
  padding-bottom: 4px;
  padding-top: 10px;
  position: relative;
  z-index: 1;
`;

const HeatmapMainWrapper = styled.div`
  background-color: ${neutralLighter};
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  z-index: 1;
`;

const HeatmapBottomWrapper = styled.div`
  background-color: ${neutralLighter};
  padding: 10px;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  z-index: 1;
`;

const OverlayLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const OverlayLines = () => (
  <OverlayLayer style={{ zIndex: 10, paddingTop: 0 }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        height: '100%',
        paddingTop: 2,
      }}
    >
      <div
        style={{
          width: '100%',
          borderRight: `2px dotted ${ssabDarkGray}`,
          marginRight: -5,
        }}
      />
      <div style={{ width: '100%' }} />
      <div
        style={{
          width: '100%',
          borderLeft: `2px dotted ${ssabDarkGray}`,
          marginLeft: -5,
        }}
      />
    </div>
  </OverlayLayer>
);

export const generateMiningHeatMapMatrix = (
  data: number[][],
  miningSteelStates: Array<CurrentMiningSteelState | UpgradeMiningSteelState>,
  isDumperSide: boolean,
  side?: 'left' | 'right',
) => {
  if (data.length > 0) {
    let wearResultArray = Array<Array<number>>();
    miningSteelStates.forEach((_steelState, i) => {
      const steelData = data[i];

      let mergedSectionData = new Array<number[]>();

      if (isDumperSide && side !== undefined) {
        mergedSectionData = getDumperSideWearMatrix(steelData, i, side);
      } else {
        mergedSectionData = getDumperWearMatrix(steelData, i);
      }

      wearResultArray = wearResultArray.concat(mergedSectionData);
    });
    return [wearResultArray];
  } else {
    return [];
  }
};

const miningPropertiesHasChanged = (props: MiningPropertiesState, prevProps: MiningPropertiesState | undefined) => {
  if (
    !prevProps ||
    props.rockSizeId !== prevProps.rockSizeId ||
    props.tipperLength !== prevProps.tipperLength ||
    props.tipperType !== prevProps.tipperType ||
    props.unloadsPerHour !== prevProps.unloadsPerHour ||
    props.currentMiningSteelsState !== prevProps.currentMiningSteelsState ||
    props.upgradeMiningSteelsState !== prevProps.upgradeMiningSteelsState ||
    props.wornOutThickness !== prevProps.wornOutThickness ||
    props.heatMapTippings !== prevProps.heatMapTippings
  ) {
    return true;
  }
  return false;
};

export type SectionIndex = number;
type SteelId = number;
export type SectionResult = {
  numberOfTippingsPerSteel: Map<SteelId, number>;
  normalizedNumberOfTippingsPerSteel: Map<SteelId, number>;
  serviceLife: number;
};

/* 
  Calculates number of tippings for each steel in each section (A, B, C) and returns the result.
*/
const getSteelStateSectionResults = async (
  calculationSteels: Array<Steel>,
  selectedMiningAbrasive: Abrasive,
  steelStates: Array<{
    id: number;
    thickness: number;
    wearProtection: boolean;
  }>,
  miningProperties: MiningPropertiesState,
  selectedUnitSystem: UnitSystemOption,
) => {
  const sectionResults = new Map<SectionIndex, SectionResult>();
  let sectionIndex = 0;
  const hardox450 = getHardox450ReferenceSteel();
  let maxNumberOfTippings = 1;
  const { data: slidingData, reference: slidingRef } = getSlidingServiceLifeDataPoints(
    calculationSteels,
    selectedMiningAbrasive,
    hardox450,
  );
  const normalizedSlidingData = slidingData.map(d => (slidingRef ? d / slidingRef : d));

  /* Normalize sliding data to Hardox 450. */
  for (const steelState of steelStates) {
    const numberOfTippingsPerSteel = new Map(
      await Promise.all(
        calculationSteels.map(
          async steel =>
            [
              steel.id,
              await calculateNumberOfTippingsUntilWornOut({
                selectedAbrasive: selectedMiningAbrasive,
                steel,
                steelThickness: steelState.thickness,
                wearProtection: steelState.wearProtection,
                miningProperties,
                selectedUnitSystem,
                sectionIndex,
              }),
            ] as [number, number],
        ),
      ),
    );

    const numberOfTippingsHardox450 = numberOfTippingsPerSteel.get(hardox450.id);

    /*
      Update each tipping entry in NumberOfTippings according to normalized sliding data.
    */
    const normalizedNumberOfTippingsPerSteel = new Map();
    let i = 0;
    for (const key of numberOfTippingsPerSteel.keys()) {
      normalizedNumberOfTippingsPerSteel.set(key, roundTo(numberOfTippingsHardox450! * normalizedSlidingData[i], 0));
      i++;
    }

    const reference = normalizedNumberOfTippingsPerSteel.get(steelState.id)!;

    const serviceLife = convertHoursToSelectedTimeUnit(reference! / miningProperties.unloadsPerHour, timeUnits[0]);

    maxNumberOfTippings = Math.max(maxNumberOfTippings, ...normalizedNumberOfTippingsPerSteel.values());

    sectionResults.set(sectionIndex, {
      numberOfTippingsPerSteel,
      normalizedNumberOfTippingsPerSteel,
      serviceLife,
    });

    sectionIndex++;
  }

  return { sectionResults, maxNumberOfTippings };
};

/* 
  This is only tweaks since wear is not being calculated entirely correct (Using sliding data in Mining calculations). 
  There is a diff between mining calculations and sliding calculations. In a later version we might include better 
  calculations for minging but until then this is used to make wear distribution look a little better in most cases.

*/
const modifyWearDataAccordingToTippings = (
  steelStates: Array<CurrentMiningSteelState | UpgradeMiningSteelState>,
  wearData: Array<Array<number>>,
  sectionResult: Map<number, SectionResult>,
  heatMapTippings: number,
) => {
  let i = 0;
  const modifiedCurrentWearData = [];
  for (const steelState of steelStates) {
    const sectionWearData = wearData[i];
    if (sectionWearData) {
      if (steelState.steel) {
        const steelSectionResult = sectionResult.get(i);
        const steelNumberOfTippings = steelSectionResult?.normalizedNumberOfTippingsPerSteel.get(steelState.steel.id);

        if (heatMapTippings >= steelNumberOfTippings!) {
          const biggestWear = sectionWearData[0];
          const diff = heatMapTippings / steelNumberOfTippings!;
          const modWearData = sectionWearData.map(d => (d / biggestWear) * diff);
          modifiedCurrentWearData.push(modWearData);
          i++;
          continue;
        } else {
          const biggestWear = sectionWearData[0];
          if (biggestWear > 1) {
            const modWearData = sectionWearData.map(d => d / (biggestWear + 0.01));
            modifiedCurrentWearData.push(modWearData);
            i++;
            continue;
          }
        }
      }
      modifiedCurrentWearData.push(sectionWearData);
    } else {
      modifiedCurrentWearData.push([]);
    }

    i++;
  }

  return modifiedCurrentWearData;
};

/*
  As for tipping calculations we have decided to use mining calculations to get the service life for hardox 450 and then use sliding caluclations to get
  the relation between hardox 450 and the other steels.
*/
export const doMiningCalculation = async (
  calculationSteels: Array<Steel>,
  miningProperties: MiningPropertiesState,
  selectedMiningAbrasive: Abrasive | undefined,
  selectedUnitSystem: UnitSystemOption,
): Promise<
  | {
      sectionResults: Map<SectionIndex, SectionResult>;
      maxServiceLife: number;
      currentSideWearData: Array<Array<number>>;
      upgradeSideWearData: Array<Array<number>>;
      currentWearData: Array<Array<number>>;
      upgradeWearData: Array<Array<number>>;
      maxNumberOfTippings: number;
      currentSteelMaxTippings: number;
      upgradeSteelMaxTippings: number;
    }
  | false
> => {
  if (!validateCurrentMiningInputs(miningProperties, selectedUnitSystem)) {
    return false;
  }

  if (!selectedMiningAbrasive) {
    return false;
  }

  const hardox450 = getHardox450ReferenceSteel();

  /* We only care for number of tippings for Hardox 450. */
  const { sectionResults: currentSectionResult, maxNumberOfTippings: maxNumberOfCurrentTippings } =
    await getSteelStateSectionResults(
      calculationSteels,
      selectedMiningAbrasive,
      miningProperties.currentMiningSteelsState.map(s => ({
        id: s.steel!.id,
        thickness: s.steelThickness,
        wearProtection: s.wearProtection,
      })),
      miningProperties,
      selectedUnitSystem,
    );

  const { sectionResults: upgradeSectionResults, maxNumberOfTippings: maxNumberOfUpgradeTippings } =
    miningProperties.upgradeMiningSteelsState.every(s => s.steel)
      ? await getSteelStateSectionResults(
          calculationSteels,
          selectedMiningAbrasive,
          miningProperties.upgradeMiningSteelsState.map((s, index) => ({
            id: s.steel!.id,
            thickness: s.steelThickness,
            wearProtection: miningProperties.currentMiningSteelsState[index].wearProtection,
          })),
          miningProperties,
          selectedUnitSystem,
        )
      : { sectionResults: new Map(), maxNumberOfTippings: undefined };

  const maxCurrentSteelTippingsForSteels = miningProperties.currentMiningSteelsState
    .map((steelState, index) => {
      if (!steelState.steel) {
        return undefined;
      }
      const section = currentSectionResult.get(index);
      const selectedSteelTippings = section?.normalizedNumberOfTippingsPerSteel.get(steelState.steel.id) || 0;
      return selectedSteelTippings;
    })
    .filter(s => s !== undefined) as Array<number>;

  const maxCurrentSteelTippings =
    maxCurrentSteelTippingsForSteels.length > 0 ? Math.min(...maxCurrentSteelTippingsForSteels) : 0;

  const maxUpgradeSteelTippingsForSteels = (
    miningProperties.upgradeMiningSteelsState.every(s => s.steel)
      ? miningProperties.upgradeMiningSteelsState
          .map((steelState, index) => {
            if (!steelState.steel) {
              return undefined;
            }
            const section = upgradeSectionResults.get(index);
            const selectedSteelTippings = section?.normalizedNumberOfTippingsPerSteel.get(steelState.steel.id) || 0;
            return selectedSteelTippings;
          })
          .filter(s => s !== undefined)
      : []
  ) as Array<number>;

  const maxUpgradeSteelTippings =
    maxUpgradeSteelTippingsForSteels.length > 0 ? Math.min(...maxUpgradeSteelTippingsForSteels) : 0;

  const maxNumberOfHeatmapTippings = Math.max(maxCurrentSteelTippings, maxUpgradeSteelTippings);

  const maxNumberOfTippings = maxNumberOfUpgradeTippings
    ? Math.min(maxNumberOfCurrentTippings, maxNumberOfUpgradeTippings)
    : maxNumberOfCurrentTippings;

  const maxServiceLife = convertHoursToSelectedTimeUnit(
    maxNumberOfTippings / miningProperties.unloadsPerHour,
    timeUnits[0],
  );

  const hardox450CurrentSteelState = [
    ...miningProperties.currentMiningSteelsState.map(ss => ({
      ...ss,
      steel: hardox450,
    })),
  ];

  const hardox450UpgradeSteelState = [
    ...miningProperties.upgradeMiningSteelsState.map(ss => ({
      ...ss,
      steel: hardox450,
    })),
  ];

  /* This is to use only B steel for calculation on sides. */
  const currentSideWearData = (await calculateMiningWearDistribution({
    selectedAbrasive: selectedMiningAbrasive,
    miningSteelStates: hardox450CurrentSteelState,
    currentMiningSteels: hardox450CurrentSteelState,
    selectedUnitSystem,
    miningProperties,
    isSide: false,
  })) as Array<Array<number>>;

  const upgradeSideWearData = validateUpgradeMiningInputs(miningProperties, selectedUnitSystem)
    ? ((await calculateMiningWearDistribution({
        selectedAbrasive: selectedMiningAbrasive,
        miningSteelStates: hardox450UpgradeSteelState,
        currentMiningSteels: hardox450CurrentSteelState,
        selectedUnitSystem,
        miningProperties,
        isSide: false,
      })) as Array<Array<number>>)
    : [];

  const currentWearData = (await calculateMiningWearDistribution({
    selectedAbrasive: selectedMiningAbrasive,
    miningSteelStates: hardox450CurrentSteelState,
    currentMiningSteels: hardox450CurrentSteelState,
    selectedUnitSystem,
    miningProperties,
    isSide: false,
  })) as Array<Array<number>>;

  const upgradeWearData = validateUpgradeMiningInputs(miningProperties, selectedUnitSystem)
    ? ((await calculateMiningWearDistribution({
        selectedAbrasive: selectedMiningAbrasive,
        miningSteelStates: hardox450UpgradeSteelState,
        currentMiningSteels: hardox450CurrentSteelState,
        selectedUnitSystem,
        miningProperties,
        isSide: false,
      })) as Array<Array<number>>)
    : [];

  const normalizedSlidingData = getNormalizedSlidingServiceLifePoints(calculationSteels, selectedMiningAbrasive);

  const normalizedCurrentWearData = miningProperties.currentMiningSteelsState.map((umss, index) => {
    const steelIndex = calculationSteels.findIndex(s => s.id === umss.steel?.id);
    if (steelIndex === undefined) {
      return [];
    }

    const steelWearData = currentWearData[index];
    return steelWearData.map(data => data * (1 / normalizedSlidingData[steelIndex]));
  });

  const normalizedCurrentSideWearData = miningProperties.currentMiningSteelsState.map((umss, index) => {
    const steelIndex = calculationSteels.findIndex(s => s.id === umss.steel?.id);
    if (steelIndex === undefined) {
      return [];
    }

    const steelWearData = currentSideWearData[index];
    return steelWearData.map(data => data * (1 / normalizedSlidingData[steelIndex]));
  });

  const normalizedUpgradeWearData = validateUpgradeMiningInputs(miningProperties, selectedUnitSystem)
    ? miningProperties.upgradeMiningSteelsState.map((umss, index) => {
        const steelIndex = calculationSteels.findIndex(s => s.id === umss.steel?.id);
        if (steelIndex === undefined) {
          return [];
        }

        const steelWearData = upgradeWearData[index];
        return steelWearData.map(data => data * (1 / normalizedSlidingData[steelIndex]));
      })
    : [];

  const normalizedUpgradeSideWearData = validateUpgradeMiningInputs(miningProperties, selectedUnitSystem)
    ? miningProperties.upgradeMiningSteelsState.map((umss, index) => {
        const steelIndex = calculationSteels.findIndex(s => s.id === umss.steel?.id);
        if (steelIndex === undefined) {
          return [];
        }

        const steelWearData = upgradeSideWearData[index];
        return steelWearData.map(data => data * (1 / normalizedSlidingData[steelIndex]));
      })
    : [];

  const modifiedCurrentWearData = modifyWearDataAccordingToTippings(
    miningProperties.currentMiningSteelsState,
    normalizedCurrentWearData,
    currentSectionResult,
    miningProperties.heatMapTippings,
  );
  const modifiedUpgradeWearData = normalizedUpgradeWearData
    ? modifyWearDataAccordingToTippings(
        miningProperties.upgradeMiningSteelsState,
        normalizedUpgradeWearData,
        upgradeSectionResults,
        miningProperties.heatMapTippings,
      )
    : [];
  /* 
    Used to normalize the biggest wear current or upgrade to make wear distribution look better.
  */
  const tweakWearData = (wear: Array<Array<number>>, sideWear: Array<Array<number>>) => {
    const maxValue = Math.max(...wear.reduce((acc, curr) => acc.concat(curr), []));
    if (maxValue > 1) {
      return {
        tweakedWearData: wear.map(wd => wd.map(d => d / maxValue)),
        tweakedSideWearData: sideWear.map(wd => wd.map(d => d / maxValue)),
      };
    }

    return {
      tweakedWearData: wear,
      tweakedSideWearData: sideWear,
    };
  };

  const getHighestWearValue = (wearData: Array<Array<number>>) => {
    let highest = 0;
    for (const wear of wearData) {
      if (highest < wear[0]) {
        highest = wear[0];
      }
    }
    return highest;
  };

  const { tweakedWearData: tweakedUpgradeWearData, tweakedSideWearData: tweakedUpgradeSideWearData } =
    validateMiningInputs(miningProperties, selectedUnitSystem)
      ? getHighestWearValue(modifiedUpgradeWearData) < getHighestWearValue(modifiedCurrentWearData)
        ? tweakWearData(modifiedUpgradeWearData, normalizedUpgradeSideWearData)
        : {
            tweakedWearData: modifiedUpgradeWearData,
            tweakedSideWearData: normalizedUpgradeSideWearData,
          }
      : { tweakedWearData: modifiedUpgradeWearData, tweakedSideWearData: normalizedUpgradeSideWearData };

  const { tweakedWearData: tweakedCurrentWearData, tweakedSideWearData: tweakedCurrentSideWearData } =
    validateMiningInputs(miningProperties, selectedUnitSystem)
      ? getHighestWearValue(modifiedUpgradeWearData) > getHighestWearValue(modifiedCurrentWearData)
        ? tweakWearData(modifiedCurrentWearData, normalizedCurrentSideWearData)
        : {
            tweakedWearData: modifiedCurrentWearData,
            tweakedSideWearData: normalizedCurrentSideWearData,
          }
      : { tweakedWearData: modifiedCurrentWearData, tweakedSideWearData: normalizedCurrentSideWearData };
  return {
    sectionResults: currentSectionResult,
    maxServiceLife,
    currentSideWearData: tweakedCurrentSideWearData,
    upgradeSideWearData: tweakedUpgradeSideWearData,
    currentWearData: tweakedCurrentWearData,
    upgradeWearData: tweakedUpgradeWearData,
    maxNumberOfTippings: maxNumberOfHeatmapTippings,
    currentSteelMaxTippings: maxCurrentSteelTippings,
    upgradeSteelMaxTippings: maxUpgradeSteelTippings,
  };
};

export const Mining = injectIntl(
  class MiningClass extends React.Component<
    WrappedComponentProps,
    {
      serviceLifeData?: {
        sectionResults: Map<SectionIndex, SectionResult>;
        maxServiceLife: number;
      };
      wearData: {
        currentSideWearData: Array<Array<number>>;
        upgradeSideWearData: Array<Array<number>>;
        currentWearData: Array<Array<number>>;
        upgradeWearData: Array<Array<number>>;
      };
      maxNumberOfTippings: number;
      currentSteelMaxTippings: number;
      upgradeSteelMaxTippings: number;
    }
  > {
    miningProperties: MiningPropertiesState | undefined;
    calculationSteel: Steel | undefined;
    selectedMiningAbrasive: Abrasive | undefined;
    selectedUnitSystem: UnitSystemOption | undefined;

    constructor(props: WrappedComponentProps) {
      super(props);

      this.state = {
        wearData: {
          currentSideWearData: [],
          upgradeSideWearData: [],
          currentWearData: [],
          upgradeWearData: [],
        },
        maxNumberOfTippings: 1,
        currentSteelMaxTippings: 0,
        upgradeSteelMaxTippings: 0,
      };
    }

    shouldComponentUpdate(nextProps: WrappedComponentProps, nextState: this['state']) {
      return (
        nextProps.intl !== this.props.intl ||
        nextState.serviceLifeData !== this.state.serviceLifeData ||
        nextState.wearData !== this.state.wearData ||
        nextState.maxNumberOfTippings !== this.state.maxNumberOfTippings
      );
    }

    render() {
      const { intl } = this.props;

      return (
        <ApplicationStateConsumer>
          {({
            state: {
              miningProperties,
              selectedSteelTab,
              selectedUnitSystem,
              steels,
              useTimeUnit,
              selectedMiningAbrasive,
            },
            setMiningAbrasive,
            setSelectedSteelTab,
            setMiningProperty,
            setMiningPropertyInstant,
          }) => {
            return (
              <LanguageStateConsumer>
                {({ state: { selectedLanguage } }) => {
                  const miningSteels = steels.filter(steel => steel.includeInMining);

                  const calculationSteels = getTranslatedSteels(
                    intl,
                    constructSteelArray(miningSteels, miningProperties.currentMiningSteelsState[0].steel!),
                  );

                  if (
                    miningPropertiesHasChanged(miningProperties, this.miningProperties) ||
                    this.calculationSteel !== miningProperties.currentMiningSteelsState[0].steel! ||
                    this.selectedMiningAbrasive !== selectedMiningAbrasive ||
                    this.selectedUnitSystem !== selectedUnitSystem
                  ) {
                    doMiningCalculation(
                      calculationSteels,
                      miningProperties,
                      selectedMiningAbrasive,
                      selectedUnitSystem,
                    ).then(result => {
                      if (!result) {
                        return;
                      }

                      const {
                        sectionResults,
                        maxServiceLife,
                        currentSideWearData,
                        upgradeSideWearData,
                        currentWearData,
                        upgradeWearData,
                        maxNumberOfTippings,
                        currentSteelMaxTippings,
                        upgradeSteelMaxTippings,
                      } = result;

                      if (
                        !miningPropertiesHasChanged(miningProperties, this.miningProperties) ||
                        this.calculationSteel === miningProperties.currentMiningSteelsState[0].steel! ||
                        this.selectedMiningAbrasive === selectedMiningAbrasive ||
                        this.selectedUnitSystem === selectedUnitSystem ||
                        this.state.maxNumberOfTippings === maxNumberOfTippings
                      ) {
                        this.setState({
                          serviceLifeData: {
                            sectionResults,
                            maxServiceLife,
                          },
                          wearData: {
                            currentSideWearData,
                            upgradeSideWearData,
                            currentWearData,
                            upgradeWearData,
                          },
                          maxNumberOfTippings,
                          currentSteelMaxTippings,
                          upgradeSteelMaxTippings,
                        });
                      }
                    });
                    this.calculationSteel = miningProperties.currentMiningSteelsState[0].steel!;
                    this.selectedMiningAbrasive = selectedMiningAbrasive;
                    this.selectedUnitSystem = selectedUnitSystem;
                    this.miningProperties = miningProperties;
                  }

                  const currentMiningSteelsIsUndefined = miningProperties.currentMiningSteelsState.some(
                    x => x.steel === undefined,
                  );

                  const upgradeMiningSteelsIsUndefined = miningProperties.upgradeMiningSteelsState.some(
                    x => x.steel === undefined,
                  );

                  const currentHeatMapShouldRender = miningProperties.currentMiningSteelsState.every(
                    x => x.steelThickness > miningProperties.wornOutThickness,
                  );

                  const upgradeHeatMapShouldRender = miningProperties.upgradeMiningSteelsState.every(
                    x => x.steelThickness > miningProperties.wornOutThickness,
                  );

                  return (
                    <CalculationPageWrapper>
                      <PropertiesColumn>
                        <AbrasiveCard>
                          <SelectAbrasive
                            selectedAbrasive={selectedMiningAbrasive}
                            setCalculationModeAbrasive={setMiningAbrasive}
                            calculationType={CalculationType.Mining}
                          />
                        </AbrasiveCard>
                        {selectedMiningAbrasive && (
                          <>
                            <PropertiesCard calculationProperties={<MiningProperties />} />
                            <SteelCard
                              calculationProperties={
                                <MiningCurrentSteelProperties
                                  upgradeMiningSteelsState={miningProperties.upgradeMiningSteelsState}
                                  currentMiningSteelsState={miningProperties.currentMiningSteelsState}
                                  calculationSteels={miningSteels}
                                  includeCustomSteel={false}
                                  wornOutThickness={miningProperties.wornOutThickness}
                                  currentServiceLife={miningProperties.currentServiceLife}
                                  serviceLifeTimeUnit={miningProperties.serviceLifeTimeUnit}
                                  steelTippings={this.state.currentSteelMaxTippings}
                                />
                              }
                              upgradeSteelProperties={
                                <MiningUpgradeSteelProperties
                                  upgradeMiningSteelsState={miningProperties.upgradeMiningSteelsState}
                                  currentMiningSteelsState={miningProperties.currentMiningSteelsState}
                                  calculationSteels={miningSteels}
                                  includeCustomSteel={false}
                                  upgradeServiceLife={miningProperties.upgradeServiceLife}
                                  serviceLifeTimeUnit={miningProperties.serviceLifeTimeUnit}
                                  wornOutThickness={miningProperties.wornOutThickness}
                                  steelTippings={this.state.upgradeSteelMaxTippings}
                                />
                              }
                              selectedSteelTab={selectedSteelTab}
                              setSelectedSteelTab={setSelectedSteelTab}
                            />
                          </>
                        )}
                      </PropertiesColumn>
                      <ChartColumn>
                        <ChartPivot displayDownloadDocX={selectedMiningAbrasive !== undefined}>
                          <PivotItem
                            itemKey={ChartType.ServiceLife}
                            headerText={intl.formatMessage({
                              id: 'serviceLife',
                            })}
                          >
                            <ChartCard style={{ minHeight: 667 }}>
                              {miningProperties.currentMiningSteelsState.map((currentSteelState, sectionIndex) => {
                                return (
                                  <RelativeMiningServiceLifeChart
                                    key={sectionIndex}
                                    currentMiningSteelState={currentSteelState}
                                    upgradeMiningSteelState={miningProperties.upgradeMiningSteelsState[sectionIndex]}
                                    miningProperties={miningProperties}
                                    steels={miningSteels}
                                    reference={
                                      this.state.serviceLifeData &&
                                      this.state.serviceLifeData.sectionResults
                                        .get(sectionIndex)!
                                        .normalizedNumberOfTippingsPerSteel.get(currentSteelState.steel!.id)!
                                    }
                                    useTimeUnit={useTimeUnit}
                                    data={
                                      selectedMiningAbrasive && this.state.serviceLifeData
                                        ? [
                                            ...this.state.serviceLifeData.sectionResults
                                              .get(sectionIndex)!
                                              .normalizedNumberOfTippingsPerSteel.values(),
                                          ]
                                        : []
                                    }
                                    hideXAxis={sectionIndex === 2 ? false : true}
                                    setCurrentSteel={(steel: Steel) => {
                                      setMiningPropertyInstant({
                                        currentMiningSteelsState: setStateArrayElement(
                                          miningProperties.currentMiningSteelsState,
                                          {
                                            ...currentSteelState,
                                            steel: steel,
                                          },
                                          sectionIndex,
                                        ),
                                      });
                                    }}
                                    setUpgradeSteel={(steel: Steel | undefined) => {
                                      setMiningPropertyInstant({
                                        upgradeMiningSteelsState: setStateArrayElement(
                                          miningProperties.upgradeMiningSteelsState,
                                          {
                                            ...miningProperties.upgradeMiningSteelsState[sectionIndex],
                                            steel: steel,
                                          },
                                          sectionIndex,
                                        ),
                                      });
                                    }}
                                    selectedSteelTab={selectedSteelTab}
                                    currentServiceLife={
                                      this.state.serviceLifeData &&
                                      this.state.serviceLifeData.sectionResults.get(sectionIndex)!.serviceLife
                                    }
                                    suggestedMaxValue={
                                      this.state.serviceLifeData && this.state.serviceLifeData.maxServiceLife
                                    }
                                    chartTitleId={'steel'}
                                    chartTitleSuffix={`${currentSteelState.name} (${intl.formatMessage({
                                      id: 'steel' + currentSteelState.name + 'section',
                                    })}) - ${intl.formatMessage({
                                      id: 'relativeServiceLifePlateThickness',
                                    })} ${currentSteelState.steelThickness} ${getSelectedUnitText(
                                      selectedUnitSystem,
                                      intl,
                                    )}`}
                                    hideChartTypeToggle={sectionIndex === 0 ? false : true}
                                  />
                                );
                              })}
                            </ChartCard>
                          </PivotItem>

                          <PivotItem itemKey={ChartType.HeatMap} headerText={intl.formatMessage({ id: 'heatMap' })}>
                            <ChartCard style={{ minHeight: 667 }}>
                              <>
                                <div>
                                  {selectedMiningAbrasive && (
                                    <HeatMapInputs
                                      tippings={miningProperties.heatMapTippings}
                                      maxNumberOfTippings={this.state.maxNumberOfTippings}
                                      setHeatMapTippings={tippings => {
                                        setMiningProperty({
                                          heatMapTippings: tippings,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                                <FlexRow style={{ flexGrow: 2, flexShrink: 2 }}>
                                  <HeatMapHeaderRow>
                                    <HeatMapHeaderWrapper>
                                      <HeatMapHeader>
                                        <HeatMapHeaderText>
                                          {intl.formatMessage({
                                            id: 'currentSteels',
                                          })}
                                        </HeatMapHeaderText>
                                      </HeatMapHeader>
                                    </HeatMapHeaderWrapper>
                                    <HeatMapHeaderWrapper>
                                      <HeatMapHeader>
                                        <HeatMapHeaderText>
                                          {intl.formatMessage({
                                            id: 'upgradeSteels',
                                          })}
                                        </HeatMapHeaderText>
                                      </HeatMapHeader>
                                    </HeatMapHeaderWrapper>
                                  </HeatMapHeaderRow>

                                  <HeatMapWrapper>
                                    <HeatMapComponent
                                      calculationProperties={miningProperties}
                                      steelType={'current'}
                                      steelSelected={!currentMiningSteelsIsUndefined}
                                      selectedAbrasive={selectedMiningAbrasive}
                                      wearData={selectedMiningAbrasive ? this.state.wearData : {}}
                                      hasData={this.state.wearData.currentWearData.length > 0}
                                      isValid={validateCurrentMiningInputs(miningProperties, selectedUnitSystem)}
                                    >
                                      <HeatMapContentWrapper>
                                        <HeatMapContent style={{ position: 'relative' }}>
                                          <OverlayLayer>
                                            {selectedMiningAbrasive !== undefined &&
                                            currentMiningSteelsIsUndefined === false &&
                                            currentHeatMapShouldRender ? (
                                              <ThrottleChanges
                                                selectedLanguage={selectedLanguage}
                                                data={
                                                  [miningProperties, this.state.wearData.currentSideWearData] as [
                                                    MiningPropertiesState,
                                                    Array<Array<number>>,
                                                  ]
                                                }
                                              >
                                                {([miningProperties, currentSideWearData]) => {
                                                  return (
                                                    <HeatmapTopWrapper>
                                                      <HeatMapSide
                                                        data={
                                                          selectedMiningAbrasive !== undefined
                                                            ? generateMiningHeatMapMatrix(
                                                                currentSideWearData,
                                                                miningProperties.currentMiningSteelsState,
                                                                true,
                                                                'left',
                                                              )
                                                            : []
                                                        }
                                                        heightInPixels={90}
                                                        side={'left'}
                                                        calculationType={CalculationType.Mining}
                                                      />
                                                    </HeatmapTopWrapper>
                                                  );
                                                }}
                                              </ThrottleChanges>
                                            ) : (
                                              ''
                                            )}

                                            <ThrottleChanges
                                              selectedLanguage={selectedLanguage}
                                              data={
                                                [miningProperties, this.state.wearData.currentWearData] as [
                                                  MiningPropertiesState,
                                                  Array<Array<number>>,
                                                ]
                                              }
                                            >
                                              {([miningProperties, currentWearData]) => {
                                                return (
                                                  <HeatmapMainWrapper>
                                                    <HeatMap
                                                      data={
                                                        selectedMiningAbrasive !== undefined &&
                                                        currentWearData.every(d => d[0])
                                                          ? generateMiningHeatMapMatrix(
                                                              currentWearData,
                                                              miningProperties.currentMiningSteelsState,
                                                              false,
                                                            )
                                                          : []
                                                      }
                                                      heightInPixels={200}
                                                      renderSides={true}
                                                    />
                                                  </HeatmapMainWrapper>
                                                );
                                              }}
                                            </ThrottleChanges>
                                            {selectedMiningAbrasive !== undefined &&
                                            currentMiningSteelsIsUndefined === false &&
                                            currentHeatMapShouldRender ? (
                                              <ThrottleChanges
                                                selectedLanguage={selectedLanguage}
                                                data={
                                                  [miningProperties, this.state.wearData.currentSideWearData] as [
                                                    MiningPropertiesState,
                                                    Array<Array<number>>,
                                                  ]
                                                }
                                              >
                                                {([miningProperties, currentSideWearData]) => {
                                                  return (
                                                    <HeatmapBottomWrapper>
                                                      <HeatMapSide
                                                        data={
                                                          selectedMiningAbrasive !== undefined
                                                            ? generateMiningHeatMapMatrix(
                                                                currentSideWearData,
                                                                miningProperties.currentMiningSteelsState,
                                                                true,
                                                                'right',
                                                              )
                                                            : []
                                                        }
                                                        heightInPixels={90}
                                                        side={'right'}
                                                        calculationType={CalculationType.Mining}
                                                      />
                                                    </HeatmapBottomWrapper>
                                                  );
                                                }}
                                              </ThrottleChanges>
                                            ) : (
                                              ''
                                            )}

                                            {selectedMiningAbrasive !== undefined &&
                                            miningProperties.currentMiningSteelsState.every(
                                              x => x.steel !== undefined,
                                            ) &&
                                            currentHeatMapShouldRender ? (
                                              <SectionDescriptionWrapper>
                                                <SectionDescription>
                                                  {intl.formatMessage({
                                                    id: 'steel',
                                                  })}{' '}
                                                  A
                                                </SectionDescription>
                                                <SectionDescription>
                                                  {intl.formatMessage({
                                                    id: 'steel',
                                                  })}{' '}
                                                  B
                                                </SectionDescription>
                                                <SectionDescription>
                                                  {intl.formatMessage({
                                                    id: 'steel',
                                                  })}{' '}
                                                  C
                                                </SectionDescription>
                                              </SectionDescriptionWrapper>
                                            ) : (
                                              ''
                                            )}
                                          </OverlayLayer>
                                          <OverlayLines />
                                        </HeatMapContent>
                                      </HeatMapContentWrapper>
                                    </HeatMapComponent>
                                    <HeatMapComponent
                                      calculationProperties={miningProperties}
                                      steelType={'upgrade'}
                                      steelSelected={!upgradeMiningSteelsIsUndefined}
                                      selectedAbrasive={selectedMiningAbrasive}
                                      wearData={selectedMiningAbrasive ? this.state.wearData : {}}
                                      hasData={this.state.wearData.upgradeWearData.length > 0}
                                      isValid={validateUpgradeMiningInputs(miningProperties, selectedUnitSystem)}
                                    >
                                      <HeatMapContentWrapper>
                                        <HeatMapContent style={{ position: 'relative' }}>
                                          <OverlayLayer>
                                            {selectedMiningAbrasive !== undefined &&
                                            upgradeMiningSteelsIsUndefined === false &&
                                            upgradeHeatMapShouldRender ? (
                                              <ThrottleChanges
                                                selectedLanguage={selectedLanguage}
                                                data={
                                                  [miningProperties, this.state.wearData.upgradeSideWearData] as [
                                                    MiningPropertiesState,
                                                    Array<Array<number>>,
                                                  ]
                                                }
                                              >
                                                {([miningProperties, upgradeSideWearData]) => {
                                                  return (
                                                    <HeatmapTopWrapper>
                                                      <HeatMapSide
                                                        data={
                                                          selectedMiningAbrasive !== undefined
                                                            ? generateMiningHeatMapMatrix(
                                                                upgradeSideWearData,
                                                                miningProperties.upgradeMiningSteelsState,
                                                                true,
                                                                'left',
                                                              )
                                                            : []
                                                        }
                                                        heightInPixels={90}
                                                        side={'left'}
                                                        calculationType={CalculationType.Mining}
                                                      />
                                                    </HeatmapTopWrapper>
                                                  );
                                                }}
                                              </ThrottleChanges>
                                            ) : (
                                              ''
                                            )}

                                            <ThrottleChanges
                                              selectedLanguage={selectedLanguage}
                                              data={
                                                [miningProperties, this.state.wearData.upgradeWearData] as [
                                                  MiningPropertiesState,
                                                  Array<Array<number>>,
                                                ]
                                              }
                                            >
                                              {([miningProperties, upgradeWearData]) => {
                                                return (
                                                  <HeatmapMainWrapper>
                                                    <HeatMap
                                                      data={
                                                        selectedMiningAbrasive !== undefined &&
                                                        upgradeWearData.every(d => d[0])
                                                          ? generateMiningHeatMapMatrix(
                                                              upgradeWearData,
                                                              miningProperties.upgradeMiningSteelsState,
                                                              false,
                                                            )
                                                          : []
                                                      }
                                                      heightInPixels={200}
                                                      renderSides={true}
                                                    />
                                                  </HeatmapMainWrapper>
                                                );
                                              }}
                                            </ThrottleChanges>

                                            {selectedMiningAbrasive !== undefined &&
                                            upgradeMiningSteelsIsUndefined === false &&
                                            upgradeHeatMapShouldRender ? (
                                              <ThrottleChanges
                                                selectedLanguage={selectedLanguage}
                                                data={
                                                  [miningProperties, this.state.wearData.upgradeSideWearData] as [
                                                    MiningPropertiesState,
                                                    Array<Array<number>>,
                                                  ]
                                                }
                                              >
                                                {([miningProperties, upgradeSideWearData]) => {
                                                  return (
                                                    <HeatmapBottomWrapper>
                                                      <HeatMapSide
                                                        data={
                                                          selectedMiningAbrasive !== undefined
                                                            ? generateMiningHeatMapMatrix(
                                                                upgradeSideWearData,
                                                                miningProperties.upgradeMiningSteelsState,
                                                                true,
                                                                'right',
                                                              )
                                                            : []
                                                        }
                                                        heightInPixels={90}
                                                        side={'right'}
                                                        calculationType={CalculationType.Mining}
                                                      />
                                                    </HeatmapBottomWrapper>
                                                  );
                                                }}
                                              </ThrottleChanges>
                                            ) : (
                                              ''
                                            )}

                                            {selectedMiningAbrasive !== undefined &&
                                            miningProperties.upgradeMiningSteelsState.every(
                                              x => x.steel !== undefined,
                                            ) &&
                                            upgradeHeatMapShouldRender ? (
                                              <SectionDescriptionWrapper>
                                                <SectionDescription>
                                                  {intl.formatMessage({
                                                    id: 'steel',
                                                  })}{' '}
                                                  A
                                                </SectionDescription>
                                                <SectionDescription>
                                                  {intl.formatMessage({
                                                    id: 'steel',
                                                  })}{' '}
                                                  B
                                                </SectionDescription>
                                                <SectionDescription>
                                                  {intl.formatMessage({
                                                    id: 'steel',
                                                  })}{' '}
                                                  C
                                                </SectionDescription>
                                              </SectionDescriptionWrapper>
                                            ) : (
                                              ''
                                            )}
                                          </OverlayLayer>
                                          <OverlayLines />
                                        </HeatMapContent>
                                      </HeatMapContentWrapper>
                                    </HeatMapComponent>
                                    <HeatMapLegendWrapper>
                                      <div style={{ paddingTop: 10 }}>
                                        <HeatMapLegend heightInPixels={400} />
                                      </div>
                                    </HeatMapLegendWrapper>
                                  </HeatMapWrapper>
                                  <BottomLabel>
                                    {intl.formatMessage({
                                      id: 'reducedThickness',
                                    })}{' '}
                                    [%]
                                  </BottomLabel>
                                  {(miningProperties.heatMapTippings >= this.state.currentSteelMaxTippings ||
                                    (miningProperties.heatMapTippings >= this.state.upgradeSteelMaxTippings &&
                                      this.state.upgradeSteelMaxTippings > 0)) && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginTop: 10,
                                        width: '100%',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: 15,
                                          height: 15,
                                          background: `repeating-linear-gradient(
                                    45deg,
                                    #e62e00,
                                    #e62e00 2px,
                                    #000000 2px,
                                    #000000 4px
                                  )`,
                                        }}
                                      />
                                      <span style={{ marginLeft: 8 }}>
                                        {intl.formatMessage({
                                          id: 'dashedAreaIndicates',
                                        })}
                                      </span>
                                    </div>
                                  )}
                                </FlexRow>
                              </>
                            </ChartCard>
                          </PivotItem>
                        </ChartPivot>
                      </ChartColumn>
                    </CalculationPageWrapper>
                  );
                }}
              </LanguageStateConsumer>
            );
          }}
        </ApplicationStateConsumer>
      );
    }
  },
);
