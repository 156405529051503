import { injectIntl, WrappedComponentProps } from 'react-intl';
import { CurrentSteelState, Steel, ChartType, UpgradeSteelState } from '../../types';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { PropertiesRow, RightDropdownWrapper } from '../../components/containers/PropertiesRow';
import { SteelSelector } from '../../components/form/SteelSelector';
import { Column } from '../../components/containers/Column';
import { TranslatedInputLabel } from '../../components/labels/Labels';
import { CustomHardnessInput } from '../../components/form/CustomHardnessInput';
import { LengthInput } from '../../components/form/LengthInput';
import { TimeUnitDropdown } from '../../components/form/TimeUnitDropdown';
import { convertMillimetersToInches } from '../../utils/convertUnits/convertUnits';
import { TippingValues } from '../../data/calculationValues';
import { TextField } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { ErrorMessage } from '../../utils/styledComponents/ErrorMessage';
import { NumberInput } from '../../components/form/NumberInput';
import { roundTo } from '../../utils/numberFormatting';

export const TippingUpgradeSteelProperties = injectIntl(
  (
    props: WrappedComponentProps & {
      currentSteelState: CurrentSteelState;
      upgradeSteelState: UpgradeSteelState;
      setUpgradeSteelState: (upgradeSteelState: Partial<UpgradeSteelState>) => void;
      setUpgradeSteelStateInstant: (UpgradeSteelState: Partial<UpgradeSteelState>) => void;
      setCurrentSteelState: (currentSteelState: Partial<CurrentSteelState>) => void;
      setCurrentSteelStateInstant: (CurrentSteelState: Partial<CurrentSteelState>) => void;
      calculationSteels: Steel[];
      includeCustomSteel: boolean;
      rockSizeId: number;
    },
  ) => {
    const [showPoorSettingsError, setShowPoorSettingsError] = useState(false);

    useEffect(() => {
      setShowPoorSettingsError(props.rockSizeId === 5 && props.upgradeSteelState.upgradeThickness <= 4);
    }, [props.rockSizeId, props.upgradeSteelState.upgradeThickness]);

    return (
      <ApplicationStateConsumer>
        {({ state: { selectedChart, selectedUnitSystem, useTimeUnit } }) => (
          <>
            <PropertiesRow>
              <div style={{ width: 'calc(100% - 90px)' }}>
                <TranslatedInputLabel translationId="steel" />

                <SteelSelector
                  selectedChart={selectedChart}
                  currentSteel={props.upgradeSteelState.upgradeSteel}
                  setCurrentSteel={steel => {
                    props.setUpgradeSteelStateInstant({
                      upgradeSteel: steel,
                    });
                  }}
                  steels={props.calculationSteels.filter(
                    steel => steel.hardnessHV >= props.currentSteelState.currentSteel.hardnessHV,
                  )}
                  includeCustomSteel={props.includeCustomSteel}
                />
              </div>
              {props.upgradeSteelState.upgradeSteel && (
                <RightDropdownWrapper style={{ width: 90 }}>
                  <TranslatedInputLabel translationId="hardness" />
                  <CustomHardnessInput
                    currentSteel={props.upgradeSteelState.upgradeSteel}
                    setCurrentSteel={upgradeSteelState => {
                      props.setUpgradeSteelState({
                        upgradeSteel: upgradeSteelState,
                      });
                    }}
                  />
                </RightDropdownWrapper>
              )}
            </PropertiesRow>

            {props.upgradeSteelState.upgradeSteel && (
              <>
                <PropertiesRow style={showPoorSettingsError ? { flexWrap: 'wrap' } : undefined}>
                  <Column $size={6}>
                    <TranslatedInputLabel translationId="thickness" />

                    <LengthInput
                      value={props.upgradeSteelState.upgradeThickness}
                      onChange={upgradeThickness => {
                        props.setUpgradeSteelState({
                          upgradeThickness: upgradeThickness,
                        });
                      }}
                      minValue={props.currentSteelState.wornOutThickness + 0.001}
                      maxValue={
                        selectedUnitSystem === 'imperial'
                          ? convertMillimetersToInches(TippingValues.upgradeThickness.maxValue)
                          : TippingValues.upgradeThickness.maxValue
                      }
                      selectedUnitSystem={selectedUnitSystem}
                      chartsToHighlight={[ChartType.HeatMap, ChartType.Summary, ChartType.ServiceLife]}
                    />
                  </Column>
                  <Column $size={6}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="wornoutThickness" />

                      <LengthInput
                        value={props.currentSteelState.wornOutThickness}
                        onChange={wornOutThickness => {
                          props.setCurrentSteelState({
                            wornOutThickness: wornOutThickness,
                          });
                        }}
                        chartsToHighlight={[ChartType.HeatMap, ChartType.Summary, ChartType.ServiceLife]}
                        maxValue={props.currentSteelState.currentThickness - 0.001}
                        selectedUnitSystem={selectedUnitSystem}
                        disabled={true}
                      />
                    </RightDropdownWrapper>
                  </Column>
                  {showPoorSettingsError && (
                    <Column $size={12}>
                      <ErrorMessage>
                        {props.intl.formatMessage({
                          id: 'noteThatThickness',
                        })}
                      </ErrorMessage>
                    </Column>
                  )}
                </PropertiesRow>
                <PropertiesRow>
                  <Column $size={12}>
                    <NumberInput
                      value={props.upgradeSteelState.upgradeTippings || 0}
                      suffix={props.intl.formatMessage({
                        id: 'unloads',
                      })}
                      onChange={() => {}}
                      chartsToHighlight={[ChartType.HeatMap]}
                      disabled
                    />
                  </Column>
                </PropertiesRow>
                <PropertiesRow>
                  <Column $size={6}>
                    <Column $size={12}>
                      <TranslatedInputLabel translationId="units" />
                    </Column>
                    <Column $size={12}>
                      <TimeUnitDropdown
                        value={props.currentSteelState.serviceLifeTimeUnit}
                        onChange={serviceLifeTimeUnit => {
                          props.setCurrentSteelStateInstant({
                            serviceLifeTimeUnit,
                          });
                        }}
                        chartsToHighlight={[ChartType.Summary, (useTimeUnit && ChartType.ServiceLife) as ChartType]}
                      />
                    </Column>
                  </Column>
                  <Column $size={6}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="serviceLife" />

                      <TextField
                        readOnly={true}
                        disabled={true}
                        value={roundTo(props.upgradeSteelState.upgradeServiceLife, 1).toString()}
                        suffix={props.intl.formatMessage({
                          id: props.currentSteelState.serviceLifeTimeUnit.name.toLowerCase(),
                        })}
                      />
                    </RightDropdownWrapper>
                  </Column>
                </PropertiesRow>
              </>
            )}
          </>
        )}
      </ApplicationStateConsumer>
    );
  },
);
