import { CSSProperties } from 'react';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms linear`,
  opacity: 0,
};

const transitionStyles: Record<TransitionStatus, CSSProperties> = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: {},
  exited: {},
  unmounted: {},
};

export const Fade = ({
  in: inProp,
  style: customStyle,
  children,
}: React.PropsWithChildren<{
  in: boolean;
  style?: React.CSSProperties;
}>) => (
  <Transition in={inProp} timeout={0} exit={false} unmountOnExit={true}>
    {state => (
      <div
        style={{
          ...defaultStyle,
          ...customStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);
