import { rockSizes } from '../../utils/constants/values';
import { TipperType } from '../../types';

export const getRockSizeText = (
  rockSizeId: number,
  units: { isMetric: boolean | null; isImperial: boolean | null },
) => {
  const rockSize = rockSizes.find(rockSize => rockSize.id === rockSizeId);

  if (!rockSize) {
    return 'N/A';
  }

  if (units.isMetric === true) {
    return rockSize.textMillimeters;
  }

  if (units.isImperial === true) {
    return rockSize.textInches;
  }

  return 'N/A';
};

export const getTipperTypeName = (tipperTypeId: TipperType) => {
  switch (tipperTypeId) {
    case TipperType.UShape:
      return 'U-shape';
    case TipperType.Box:
      return 'Box';
    case TipperType.Mining:
      return 'Mining';
    default:
      return 'N/A';
  }
};

export const getSharpnessText = (sharpness: number) => {
  switch (sharpness) {
    case 1:
      return 'Sharp';
    case 2:
      return 'Medium';
    case 3:
      return 'Smooth';
    default:
      return 'N/A';
  }
};
