import { CSSProperties } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { RouteStateConsumer } from '../../state/routing/routeState';
import { Route } from '../../types';
import { ssabGray, white, grayHardox100 } from '../../utils/constants/colors';

const ButtonWrapper = styled.div<{ selected: boolean }>`
  border: 1px solid white;
  width: 172px;
  background-color: ${(props: { selected?: boolean }) => (props.selected ? ssabGray : white)};
  transition: background-color 100ms ease-in 0s;
  cursor: pointer;
`;

const Button = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 1;
  -ms-flex-preferred-size: auto;
  padding-left: 32px;
  padding-right: 32px;
  height: 64px;
  z-index: 100;

  text-align: center;

  color: ${grayHardox100};
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 900px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ButtonBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ButtonText = styled.div`
  z-index: 1;
  pointer-events: none;
  font-size: 18px;
  letter-spacing: 0.5px;
`;

const ButtonImage = styled.img`
  max-width: 140px;
  max-height: 140px;
  min-height: 130px;

  @media (max-width: 900px) {
    max-width: 120px;
    max-height: 120px;
    min-height: 110px;
  }
`;

export const RouteButton = ({ route, image, style }: { route: Route; image: string; style?: CSSProperties }) => (
  <RouteStateConsumer>
    {({ state: { currentRoute }, setRoute }) => (
      <ButtonWrapper selected={currentRoute === route} style={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            textAlign: 'center',
          }}
        >
          <ButtonImage
            src={image}
            onClick={e => {
              e.preventDefault();
              setRoute(route);
            }}
          />
        </div>
        <Button
          href=""
          onClick={e => {
            e.preventDefault();
            setRoute(route);
          }}
        >
          <ButtonBackground />
          <ButtonText>
            <FormattedMessage id={route === 'tipping' ? 'tippers' : route} />
          </ButtonText>
        </Button>
      </ButtonWrapper>
    )}
  </RouteStateConsumer>
);
