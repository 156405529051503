// TODO force rerender
import { ChartWrapper } from '../ChartWrapper';
import { Chart } from 'react-chartjs-2';
import { getRelativeChartData, getRelativeChartOptions } from './relativeChartHelpers';
import { Steel, CurrentSteelState, UpgradeSteelState } from '../../../types';
import { injectIntl, WrappedComponentProps, IntlShape } from 'react-intl';
import { ChartCardHeader } from '../ChartCardHeader';
import { TypeToggle } from './TypeToggle';

type RelativeServiceLifeChartProps = {
  currentSteelState: CurrentSteelState;
  upgradeSteelState: UpgradeSteelState;
  data: Array<number>;
  reference: number | null;
  steels: Array<Steel>;
  useTimeUnit: boolean;
  selectedSteelTab: 'currentSteelTab' | 'upgradeSteelTab';
  disableAnimations?: boolean;
  setCurrentSteel?: (steel: Steel) => void;
  setUpgradeSteel?: (steel: Steel | undefined) => void;
  chartTitleSuffix?: string;
} & WrappedComponentProps;

export const RelativeServiceLifeChart = injectIntl(
  ({
    currentSteelState,
    upgradeSteelState,
    intl,
    data,
    reference,
    steels,
    useTimeUnit,
    disableAnimations = false,
    setCurrentSteel,
    selectedSteelTab,
    setUpgradeSteel,
    chartTitleSuffix,
  }: RelativeServiceLifeChartProps) => {
    const xAxesLabel = getXAxesLabel(intl, useTimeUnit, currentSteelState.serviceLifeTimeUnit.id);
    return (
      <>
        <ChartCardHeader
          messageId="relativeServiceLifePlateThickness"
          rightSideComponent={<TypeToggle />}
          titleSuffix={chartTitleSuffix}
        />
        <ChartWrapper $paddingTop={0} $minHeight={100 + steels.length * 30}>
          {/* <ForceRerenderOnSizeChange> */}
          <Chart
            type="bar"
            id="sliding-chart-canvas"
            data={getRelativeChartData(
              steels,
              currentSteelState.currentSteel,
              upgradeSteelState.upgradeSteel,
              data,
              reference ?? undefined,
              useTimeUnit ? currentSteelState.currentServiceLife : 1,
            )}
            options={getRelativeChartOptions(
              intl,
              useTimeUnit,
              currentSteelState.serviceLifeTimeUnit.id,
              xAxesLabel,
              disableAnimations,
              steels,
              currentSteelState.currentSteel,
              selectedSteelTab,
              false,
              0,
              setCurrentSteel,
              setUpgradeSteel,
            )}
          />
          {/* </ForceRerenderOnSizeChange> */}
        </ChartWrapper>
      </>
    );
  },
);

// Helpers

const getXAxesLabel = (intl: IntlShape, useTimeUnit: boolean, timeUnitId: string) => {
  if (useTimeUnit) {
    return `${intl.formatMessage({ id: 'serviceLife' }).toUpperCase()}[${intl.formatMessage({
      id: timeUnitId,
    })}]`;
  }

  return `${intl.formatMessage({ id: 'relativeServiceLife' }).toUpperCase()}`;
};
