import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Dropdown } from '@fluentui/react';
import { getHighlighStyles } from '../../utils/formHighlightHelper';
import { ChartType, Route } from '../../types';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { RouteStateConsumer } from '../../state/routing/routeState';

export const SharpnessDropdown = injectIntl(
  (
    props: {
      value: number;
      onChange: (sharpness: number) => void;
      routesToHighlight?: Array<Route>;
    } & WrappedComponentProps,
  ) => (
    <RouteStateConsumer>
      {({ state: { currentRoute } }) => (
        <ApplicationStateConsumer>
          {({ state: { selectedChart } }) => (
            <Dropdown
              selectedKey={props.value.toString()}
              onChange={(_event, option) => {
                props.onChange(+option!.key);
              }}
              options={[
                { key: '1', text: props.intl.formatMessage({ id: 'sharp' }) },
                { key: '2', text: props.intl.formatMessage({ id: 'medium' }) },
                { key: '3', text: props.intl.formatMessage({ id: 'smooth' }) },
              ]}
              calloutProps={{
                calloutMaxHeight: 385,
                directionalHintFixed: true,
              }}
              styles={{
                title: getHighlighStyles(
                  selectedChart,
                  [ChartType.ServiceLife, ChartType.Thickness, ChartType.Summary],
                  props.routesToHighlight,
                  currentRoute,
                ),
              }}
            />
          )}
        </ApplicationStateConsumer>
      )}
    </RouteStateConsumer>
  ),
);
