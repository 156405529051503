import { Steel, Abrasive, UnitSystemOption, CurrentMiningSteelState, UpgradeMiningSteelState } from './types';
import { MiningPropertiesState } from './state/miningProperties';
import { TippingPropertiesState } from './state/calculationModels';
import path from 'path';

let worker: Worker;
export const startWorker = () => {
  let workerPath = '/miningAndTippingCalculations.js';

  if (import.meta.env.VITE_REACT_APP_IS_ELECTRON === 'true') {
    workerPath = path.resolve(__dirname, 'miningAndTippingCalculations.js');
  }

  worker = new Worker(workerPath);

  worker.addEventListener('message', e => {
    return e.data;
  });
};

export const calculateNumberOfTippingsUntilWornOut = async (data: {
  selectedAbrasive: Abrasive | undefined;
  steel: Steel;
  steelThickness: number;
  wearProtection: boolean;
  miningProperties: MiningPropertiesState;
  selectedUnitSystem: UnitSystemOption;
  sectionIndex: number;
}) => {
  const messageId = Date.now() + ':' + Math.random();

  const promise = new Promise(resolve => {
    worker.postMessage({
      cmd: 'calculateNumberOfTippingsUntilWornOut',
      data,
      messageId,
    });

    const messageListener = (e: MessageEvent) => {
      if (e.data.messageId === messageId) {
        resolve(e.data.result);
        worker.removeEventListener('message', messageListener);
      }
    };

    worker.addEventListener('message', messageListener);
  });

  return promise;
};

export const calculateMiningWearDistribution = async (data: {
  selectedAbrasive: Abrasive | undefined;
  miningSteelStates: Array<CurrentMiningSteelState | UpgradeMiningSteelState>;
  currentMiningSteels: CurrentMiningSteelState[];
  selectedUnitSystem: UnitSystemOption;
  miningProperties: MiningPropertiesState;
  isSide: boolean;
}) => {
  const messageId = Date.now() + ':' + Math.random();
  const promise = new Promise(resolve => {
    worker.postMessage({
      cmd: 'calculateMiningWearDistribution',
      data,
      messageId,
    });

    const messageListener = (e: MessageEvent) => {
      if (e.data.messageId === messageId) {
        resolve(e.data.result);
        worker.removeEventListener('message', messageListener);
      }
    };

    worker.addEventListener('message', messageListener);
  });

  return promise;
};

export const calculateTippingWearDistribution = async (data: {
  steel: Steel | undefined;
  tippingProperties: TippingPropertiesState;
  selectedAbrasive: Abrasive | undefined;
  steelThickness: number;
  numberOfTippings: number;
  selectedUnitSystem: UnitSystemOption;
}) => {
  const messageId = Date.now() + ':' + Math.random();
  const promise = new Promise(resolve => {
    worker.postMessage({
      cmd: 'calculateTippingWearDistribution',
      data,
      messageId,
    });
    const messageListener = (e: MessageEvent) => {
      if (e.data.messageId === messageId) {
        resolve(e.data.result);
        worker.removeEventListener('message', messageListener);
      }
    };

    worker.addEventListener('message', messageListener);
  });

  return promise;
};

export const caluclateTippingServiceLifeForSteels = async (data: {
  steel: Steel;
  tippingProperties: TippingPropertiesState;
  selectedAbrasive: Abrasive | undefined;
  plateThickness: number;
  selectedUnitSystem: UnitSystemOption;
}) => {
  const messageId = Date.now() + ':' + Math.random();
  const promise = new Promise(resolve => {
    worker.postMessage({
      cmd: 'caluclateTippingServiceLifeForSteels',
      data,
      messageId,
    });

    const messageListener = (e: MessageEvent) => {
      if (e.data.messageId === messageId) {
        resolve(e.data.result);
        worker.removeEventListener('message', messageListener);
      }
    };

    worker.addEventListener('message', messageListener);
  });

  return promise;
};
