import { Abrasive, Steel, UnitSystemOption } from '../types';
import { splitAbrasiveComponentsBasedOnHardness } from '../utils/abrasiveComponentHelper';
import { convertLength } from '../utils/convertUnits/convertUnits';

const calc_C = (velocity: number, sharpness: number) => {
  let C;

  if (velocity < 6)
    C =
      -0.013395844014483 * Math.pow(velocity, 4) +
      0.259100936133623 * Math.pow(velocity, 3) -
      1.868506390318657 * Math.pow(velocity, 2) +
      5.963031941020597 * velocity -
      7.01895854071215 -
      0.08 * sharpness;
  else C = 0.004131216216216 * velocity + 0.069502702702703 - sharpness * 0.1;
  return C;
};

const calc_U = (abrasive: Abrasive, size: number) => {
  let Ucheck = 0;
  let Udel: number;
  let SU: number;
  // let U: number;

  for (const mtrl of abrasive.abrasiveComponents) {
    if (mtrl.hardness1 < 940 && mtrl.hardness1 > 150) {
      SU = 0.8 * Math.sin(((mtrl.hardness1 - 150) / 790.0) * Math.PI);
      Udel = SU * mtrl.fraction * 0.01;
    } else Udel = 0;
    if (size <= 30) Ucheck += 0.4 * Udel;
    else Ucheck += Udel;
  }

  if (size <= 30) Ucheck += (size / 30) * 0.3;
  else Ucheck += 0.3;
  if (Ucheck > 0.7) Ucheck = 0.7;
  return Ucheck + 0.3;
};

// TODO: check if this is correct.
export const calcImpactWareRateForSteel = (
  abrasive: Abrasive,
  abrasiveProperties: {
    size: number;
    sharpness: number;
    angle: number;
  },
  steel: Steel,
  selectedUnitSystem: UnitSystemOption,
) => {
  abrasive = splitAbrasiveComponentsBasedOnHardness(abrasive);
  const angle = Math.max(5, Math.min(90, abrasiveProperties.angle));
  const { hardnessHV } = steel;
  const { sharpness } = abrasiveProperties;
  const size = convertLength(abrasiveProperties.size, selectedUnitSystem);
  // Speed is hardcoded to 6 m/s for impact
  const speed = 6;

  let y;
  const var_hog =
    0.000002295409192 * Math.pow(angle, 3) -
    0.000454883723937 * Math.pow(angle, 2) +
    0.02530028673383 * angle -
    0.01252531076818; // var_hog och var_lag är de stötkurvor som motsvarar högläges slitage (var_hog) och låg läge slitage (var_lag). var_hog motsvara högläge slitage för mild steel medan var_lag motsvarar lågläges slitage för Hxe3m. Differensen mellan var_hog och var lag motsvarar på så vis hela den möjliga potentialen.
  const var_lag =
    -0.00000000314119 * Math.pow(angle, 4) +
    0.000000866517986 * Math.pow(angle, 3) -
    0.000088081821202 * Math.pow(angle, 2) +
    0.003662068793519 * angle -
    0.010387971948485;
  if (hardnessHV <= 420) y = ((0.96 - 1) / 270.0) * (hardnessHV - 420) + 0.96;
  else if (hardnessHV <= 550) y = ((0.71 - 0.96) / 130.0) * (hardnessHV - 550) + 0.71;
  else y = ((0.65 - 0.71) / 150.0) * (hardnessHV - 700) + 0.65;

  const ycc = var_hog - (var_hog - var_lag) * (1 - y); // ycc anger för varje vinkel med start vid var_hog(som anger MS) och varje hårdhet (var 10HV) ges ett värde som motsvarar crater skada. På samma sätt bildas ypp. ypp motsvarar den kurva som skulle bildas om alla skaror var plastiska. var_lag är den sista punken i den kurvan och motsvarar 750HV
  const yop = -0.000134615384 * hardnessHV + 0.11981;
  const ypp = var_hog - (var_hog - var_lag) * (1 - yop);

  const HrS = 1.5 + sharpness * 0.12; // Surface damage factors
  const HrC = 2.1 + sharpness * 0.12;
  const Hrc = 1.2;
  const Hrp = 0.8;

  const C = calc_C(speed, sharpness);
  let D: number;
  let G: number;

  if (C < 0) {
    D = -C;
    G = 0;
  } else {
    G = C;
    D = 0;
  }

  const U = calc_U(abrasive, size);

  const impactSum = abrasive.abrasiveComponents.reduce((sum: number, abrasiveComponent) => {
    const test = abrasiveComponent.hardness1 / hardnessHV;

    // Micro damage
    let J = 1;
    let tem1;
    if (test < Hrp) {
      tem1 = ypp + G * (ycc - ypp);
    } else if (test > Hrc) {
      tem1 = ycc - D * (ycc - ypp);
    } else {
      J = (test - Hrp) / (Hrc - Hrp);
      if (G > 0) tem1 = ycc - (ycc - (ycc - (1 - G) * (ycc - ypp))) * (1 - J);
      else tem1 = ypp + (ypp + (ycc - ypp) * (1 - D) - ypp) * J;
    }
    const tem4 = tem1 * abrasiveComponent.fraction * 0.01;

    let tem5;
    // Macro damage
    J = 1;
    if (test > HrC) {
      tem5 = ycc - D * (ycc - ypp);
    } else if (test < HrS) {
      tem5 = ypp + G * (ycc - ypp);
    } else {
      J = (test - HrS) / (HrC - HrS);
      if (G > 0) tem5 = ycc - (ycc - (ycc - (1 - G) * (ycc - ypp))) * (1 - J);
      else tem5 = ypp + (ypp + (ycc - ypp) * (1 - D) - ypp) * J;
    }
    const tem8 = tem5 * abrasiveComponent.fraction * 0.01;

    const impactValue = (1 - U) * tem4 + tem8 * U;

    return sum + impactValue;
  }, 0);

  return 1 / impactSum;
};

// TODO: check if this is correct.
export const calcErosionWareRateForSteel = (
  abrasive: Abrasive,
  abrasiveProperties: {
    velocity: number;
    sharpness: number;
    angle: number;
  },
  steel: Steel,
) => {
  abrasive = splitAbrasiveComponentsBasedOnHardness(abrasive);
  const angle = Math.max(5, Math.min(90, abrasiveProperties.angle));
  const { hardnessHV } = steel;
  const { velocity } = abrasiveProperties;
  const sharpnessInRadians = (20 * Math.PI) / 180;
  const v = 0.3;
  const E = 211000000000;
  let Vdv = 0;
  let Ve;
  let Hrc;
  let Hrp;
  let alfa_corr;
  let test;
  let damageLevel;
  let adjDamLev;
  let pdl;
  let res_for_mineral;

  abrasive.abrasiveComponents.forEach(mtrl => {
    Vdv += mtrl.density * mtrl.fraction * 0.01;
  });

  const TETA = 0.000030357142857 * Math.pow(hardnessHV, 2) - 0.010178571428572 * hardnessHV + 23.714285714285719;
  const GAMA = -0.000047619047619 * Math.pow(hardnessHV, 2) + 0.007142857142857 * hardnessHV + 44.761904761904788;

  const k = TETA / GAMA;

  const n_ =
    0.000000006439309 * Math.pow(hardnessHV, 3) -
    0.000007915878369 * Math.pow(hardnessHV, 2) +
    0.001086767696554 * hardnessHV +
    2.726429905341083;
  const alfa_noll_var =
    0.000006832604636 * Math.pow(hardnessHV, 2) + 0.025373801506591 * hardnessHV + 28.315576277618696;

  const erosionSum = abrasive.abrasiveComponents.reduce((sum: number, abrasiveComponent) => {
    Ve =
      ((Math.PI * Math.PI * Math.pow(1 - Math.pow(v, 2), 2)) / (2 * Math.sqrt(10))) * //((pi^2*(1-v^2)^2) / (2*sqrt(10)) *
      Math.pow((hardnessHV * 9.8 * 1000000) / E, 2) * //((x.*9.8*10^6)./E).^2).*
      Math.sqrt((hardnessHV * 9.81 * 1000000) / Vdv); // (x.*9.81*10^6./Vdv).^0.5;
    test = abrasiveComponent.hardness1 / hardnessHV;

    if (angle > alfa_noll_var) {
      // För vinklar över alfa_noll skall sin(alfa) bli 1
      alfa_corr = (alfa_noll_var * Math.PI) / 180;
    } else {
      alfa_corr = (angle * Math.PI) / 180;
    }

    const lambda = 6.0 / (Math.PI * Math.tan(sharpnessInRadians));

    Hrp = 0.8;
    Hrc = 1.2;

    if (test <= Hrp) {
      damageLevel = 0.02;
    } else if (test >= Hrc) {
      damageLevel = 1;
    } else {
      damageLevel = 1 - (1 - 0.02) * (1 - (test - Hrp) / (Hrc - Hrp));
    }

    adjDamLev =
      (((abrasiveComponent.density * abrasiveComponent.fraction * 0.01) / 1000.0) *
        Math.pow(velocity, 2) *
        lambda *
        damageLevel) /
      (TETA * 9.81 * 2); // Adjusted damage level according to Volume and Density
    pdl = Math.sin((angle * Math.PI) / 180.0) - Ve / velocity; // Plastic deformation limit

    if (pdl < 0) {
      pdl = 0;
    }
    res_for_mineral =
      adjDamLev * (Math.pow(Math.cos((angle * Math.PI) / 180.0), 2) * Math.sin(alfa_corr * n_) + k * Math.pow(pdl, 2));

    return sum + res_for_mineral / 122.7238679451609; // vid erosion så om abrasiva partiklarna är 2500HV, vinkel 90grader, storlek 0.2mm M=1 1.227238679451609e+002
  }, 0);

  return 1 / erosionSum;
};
