import styled from 'styled-components';
import { redHardox, grayHardox100, neutralPrimary } from '../../../utils/constants/colors';
import { fontSizeS } from '../../../utils/constants/typography';
import { FormattedMessage } from 'react-intl';

const LegendsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1px;
`;

const LegendColor = styled.div<{ color?: string }>`
  background-color: ${props => (props.color ? props.color : neutralPrimary)};
  width: 10px;
  height: 10px;
`;

const TextLabel = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: 6px;
  padding-right: 24px;
  font-size: ${fontSizeS};
  -webkit-font-smoothing: antialiased;
`;

export const Legends = () => {
  return (
    <LegendsWrapper>
      <LegendColor color={redHardox} />
      <TextLabel>
        <FormattedMessage id="cutting" />
      </TextLabel>
      <LegendColor color={grayHardox100} />
      <TextLabel>
        <FormattedMessage id="plastic" />
      </TextLabel>
    </LegendsWrapper>
  );
};
