import React, { Component } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { TextField, Callout, DirectionalHint, List, IList, DefaultButton } from '@fluentui/react';
import { white, neutralLighter, neutralSecondary } from '../../utils/constants/colors';
import { fontWeightRegular } from '../../utils/constants/typography';
import { AbrasiveComponentWithoutFraction } from '../../types';
import { FormattedMessage } from 'react-intl';

const NoMineralsFoundWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 7px;
  padding-left: 22px;
  color: ${neutralSecondary};
  font-style: italic;
`;

const OptionButton = styled(DefaultButton)`
  width: 100%;
  border: none;
  background-color: ${(props: { selected?: boolean }) => (props.selected === true ? neutralLighter : white)};
`;

export type ComponentSearchProps = {
  placeholder: string;
  componentOptions: Array<AbrasiveComponentWithoutFraction>;
  addComponent: (componentId: number) => void;
};

type ComponentSearchState = {
  showCallout: boolean;
  searchText: string | undefined;
  selectedOptionId: number | undefined;
  scrollPositionStart: number | null;
  scrollStartIndex: number | null;
};

export class ComponentSearch extends Component<ComponentSearchProps, ComponentSearchState> {
  // Init

  searchInputWrapper: HTMLDivElement | null = null;
  list: IList | null = null;

  constructor(props: ComponentSearchProps) {
    super(props);

    this.state = {
      showCallout: false,
      searchText: undefined,
      selectedOptionId: undefined,
      scrollPositionStart: null,
      scrollStartIndex: null,
    };
  }

  // Lifecycles

  componentDidUpdate(prevProps: ComponentSearchProps, prevState: ComponentSearchState) {
    const { componentOptions } = this.props;
    const { searchText, selectedOptionId } = this.state;
    if (
      selectedOptionId !== undefined &&
      (R.equals(prevProps.componentOptions, componentOptions) || prevState.searchText !== searchText)
    ) {
      const filteredComponentOptions = getFilteredComponentOptions(searchText, componentOptions);

      const selectedIsSelectable = filteredComponentOptions.some(componentOption => {
        return selectedOptionId === componentOption.id;
      });

      if (selectedOptionId !== undefined && selectedIsSelectable === false) {
        this.setState({
          selectedOptionId: undefined,
        });
      }
    }
  }

  // Methods

  submitSelectedComponent = () => {
    const { addComponent } = this.props;
    const { selectedOptionId } = this.state;

    if (selectedOptionId !== undefined) {
      addComponent(selectedOptionId);
    }

    this.setState({ searchText: undefined, showCallout: false });
  };

  handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    filteredComponentOptions: Array<AbrasiveComponentWithoutFraction>,
    selectedOptionId: number | undefined,
  ) => {
    if (event.key === 'Enter') {
      this.submitSelectedComponent();
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();

      let newIndexSteps = 0;
      if (event.key === 'ArrowDown') {
        newIndexSteps = 1;
      } else if (event.key === 'ArrowUp') {
        newIndexSteps = -1;
      }

      const { id: newSelectedOptionId, index: newIndex } = getNextComponentOption(
        filteredComponentOptions,
        selectedOptionId,
        newIndexSteps,
      );

      this.setState({
        showCallout: true,
        selectedOptionId: newSelectedOptionId,
      });

      if (this.list !== null && newIndex !== undefined) {
        this.list.scrollToIndex(newIndex);
      }
    }
  };

  // Render

  render() {
    const { placeholder, componentOptions } = this.props;
    const { searchText, showCallout, selectedOptionId } = this.state;

    const filteredComponentOptions = getFilteredComponentOptions(searchText, componentOptions);

    return (
      <>
        <div
          ref={element => {
            this.searchInputWrapper = element;
          }}
        >
          <TextField
            value={searchText}
            onChange={(_, newValue: string | undefined) => {
              this.setState({ searchText: newValue, showCallout: true });
            }}
            onFocus={() => {
              this.setState({ showCallout: true });
            }}
            onClick={() => {
              this.setState({ showCallout: true });
            }}
            placeholder={placeholder}
            iconProps={{ iconName: 'ChevronDown' }}
            styles={{
              icon: {
                top: 7,
                userSelect: 'none',
              },
            }}
            onKeyDown={event => {
              this.handleKeyDown(event, filteredComponentOptions, selectedOptionId);
            }}
          />
        </div>
        {showCallout && (
          <Callout
            gapSpace={1}
            target={this.searchInputWrapper}
            isBeakVisible={false}
            directionalHint={DirectionalHint.bottomLeftEdge}
            onDismiss={() => {
              this.setState({ showCallout: false });
            }}
            calloutMaxHeight={320}
            calloutWidth={265}
            onTouchStart={event => {
              const changedTouch = event.changedTouches[0];
              const scrollStartIndex = this.list ? this.list.getStartItemIndexInView() : null;

              this.setState({
                scrollPositionStart: changedTouch.clientY,
                scrollStartIndex,
              });
            }}
            onTouchMove={event => {
              const changedTouch = event.changedTouches[0];
              const { scrollPositionStart, scrollStartIndex } = this.state;
              if (scrollPositionStart === null || scrollStartIndex === null) {
                return;
              }

              const diffClientY = scrollPositionStart - changedTouch.clientY;
              const diffIndexes = Math.floor(diffClientY / 32);

              if (this.list) {
                this.list.scrollToIndex(scrollStartIndex + diffIndexes * 10);
              }
            }}
            onTouchEnd={() => {
              this.setState({
                scrollPositionStart: null,
                scrollStartIndex: null,
              });
            }}
          >
            <List
              style={{ border: '1px solid black' }}
              ref={element => {
                this.list = element;
              }}
              onShouldVirtualize={() => {
                return true;
              }}
              items={filteredComponentOptions}
              onRenderCell={(componentOption: AbrasiveComponentWithoutFraction | undefined): JSX.Element => {
                return (
                  <OptionButton
                    selected={componentOption?.id === selectedOptionId}
                    styles={{
                      textContainer: { textAlign: 'left' },
                      label: { fontWeight: fontWeightRegular },
                    }}
                    onClick={() => {
                      this.setState(
                        {
                          searchText: undefined,
                          selectedOptionId: Number(componentOption?.id),
                        },
                        () => {
                          this.submitSelectedComponent();
                        },
                      );
                    }}
                  >
                    {componentOption?.name}
                  </OptionButton>
                );
              }}
            />
            {filteredComponentOptions.length === 0 && (
              <NoMineralsFoundWrapper>
                <FormattedMessage id="noMineralsFound" />
              </NoMineralsFoundWrapper>
            )}
          </Callout>
        )}
      </>
    );
  }
}

// Helpers

export const getFilteredComponentOptions = (
  searchText: string | undefined,
  componentOptions: Array<AbrasiveComponentWithoutFraction>,
) => {
  const clonedComponentOptions = R.clone(componentOptions);

  if (searchText === undefined) {
    return clonedComponentOptions;
  }

  return clonedComponentOptions.filter(componentOption => {
    return componentOption.name.substring(0, searchText.length).toLowerCase() === searchText.toLowerCase();
  });
};

export const getNextComponentOption = (
  componentOptions: Array<AbrasiveComponentWithoutFraction>,
  selectedOptionId: number | undefined,
  newIndexSteps: number,
) => {
  if (componentOptions.length === 0) {
    return { id: undefined, index: undefined };
  }

  if (selectedOptionId === undefined) {
    return { id: componentOptions[0].id, index: 0 };
  }

  const selectedOptionIndex = componentOptions.findIndex(componentOption => {
    return componentOption.id === selectedOptionId;
  });
  const newSelectedOptionIndex = selectedOptionIndex + newIndexSteps;
  const newSelectedOption = componentOptions[newSelectedOptionIndex];

  if (newSelectedOption === undefined) {
    return { id: selectedOptionId, index: selectedOptionIndex };
  } else {
    return { id: newSelectedOption.id, index: newSelectedOptionIndex };
  }
};
