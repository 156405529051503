import { injectIntl, WrappedComponentProps, IntlShape } from 'react-intl';
import { ApplicationStateConsumer } from '../state/applicationState';
import { CalculationType, Steel, UnitSystemOption } from '../types';
import { PropertiesRow, RightDropdownWrapper } from '../components/containers/PropertiesRow';
import { TranslatedInputLabel } from '../components/labels/Labels';
import { SteelSelector } from '../components/form/SteelSelector';
import { Row } from '../components/containers/Row';
import { Column } from '../components/containers/Column';
import { DuroxiteIllustration } from '../components/illustrations/duroxiteIllustration';
import { CustomHardnessInput } from '../components/form/CustomHardnessInput';
import { UpgradeSteelState, ChartType, CurrentSteelState } from '../types';
import { LengthInput } from '../components/form/LengthInput';
import { NumberInput } from '../components/form/NumberInput';
import { convertMillimetersToInches } from '../utils/convertUnits/convertUnits';
import { ErosionValues, ImpactValues, SlidingValues, TippingValues } from '../data/calculationValues';
import { UglyWorkaroundWrapper } from '../components/containers/UglyWorkarounWrapper';
import { isDuroxite } from '../utils/duroxite';

const getUpgradeBaseThicknessMaxValue = (calculationType: CalculationType) => {
  switch (calculationType) {
    case CalculationType.Sliding:
      return SlidingValues.upgradeBaseThickness.maxValue;
    case CalculationType.Erosion:
      return ErosionValues.upgradeBaseThickness.maxValue;
    default:
      return 0;
  }
};

const getUpgradeThicknessMaxValue = (calculationType: CalculationType) => {
  switch (calculationType) {
    case CalculationType.Sliding:
      return SlidingValues.upgradeThickness.maxValue;
    case CalculationType.Impact:
      return ImpactValues.upgradeSteelThickness.maxValue;
    case CalculationType.Erosion:
      return ErosionValues.upgradeSteelThickness.maxValue;
    case CalculationType.Tipping:
      return TippingValues.upgradeThickness.maxValue;
    default:
      return 0;
  }
};

const DoubleLayerSteelInput = (props: {
  intl: IntlShape;
  calculationType: CalculationType;
  selectedUnitSystem: UnitSystemOption;
  currentSteelState: CurrentSteelState;
  upgradeSteelState: UpgradeSteelState;
  setUpgradeSteelState: (upgradeSteelState: Partial<UpgradeSteelState>) => void;
}) => {
  const { intl, calculationType, selectedUnitSystem, currentSteelState, upgradeSteelState, setUpgradeSteelState } =
    props;

  return (
    <>
      <PropertiesRow>
        <Column $size={6}>
          <TranslatedInputLabel translationId="overlayThickness" />

          <LengthInput
            value={upgradeSteelState.upgradeThickness}
            onChange={upgradeThickness => {
              setUpgradeSteelState({
                upgradeThickness,
              });
            }}
            minValue={0.001}
            maxValue={
              selectedUnitSystem === 'imperial'
                ? convertMillimetersToInches(getUpgradeBaseThicknessMaxValue(calculationType))
                : getUpgradeBaseThicknessMaxValue(calculationType)
            }
            selectedUnitSystem={selectedUnitSystem}
            numberOfDecimals={3}
            chartsToHighlight={[ChartType.Summary, ChartType.HeatMap]}
          />
        </Column>
        <Column $size={6}>
          <RightDropdownWrapper>
            <TranslatedInputLabel translationId="baseThickness" />

            <LengthInput
              value={upgradeSteelState.upgradeBaseThickness || 0}
              onChange={upgradeBaseThickness => {
                setUpgradeSteelState({
                  upgradeBaseThickness,
                });
              }}
              selectedUnitSystem={selectedUnitSystem}
              numberOfDecimals={3}
              chartsToHighlight={[ChartType.Summary, ChartType.HeatMap]}
            />
          </RightDropdownWrapper>
        </Column>
      </PropertiesRow>
      <PropertiesRow>
        <Column $size={6}>
          <TranslatedInputLabel translationId="serviceLife" />

          <NumberInput
            value={upgradeSteelState.upgradeServiceLife}
            onChange={newValue => {
              setUpgradeSteelState({
                upgradeServiceLife: Number(newValue),
              });
            }}
            suffix={intl.formatMessage({
              id: currentSteelState.serviceLifeTimeUnit.name.toLowerCase(),
            })}
            chartsToHighlight={[ChartType.Summary, ChartType.HeatMap]}
            numberOfDecimals={1}
          />
        </Column>
      </PropertiesRow>
    </>
  );
};

export const UpgradeSteelProperties = injectIntl(
  (
    props: WrappedComponentProps & {
      upgradeSteelState: UpgradeSteelState;
      currentSteelState: CurrentSteelState;
      setUpgradeSteelState: (upgradeSteelState: Partial<UpgradeSteelState>) => void;
      setUpgradeSteelStateInstant: (UpgradeSteelState: Partial<UpgradeSteelState>) => void;
      calculationSteels: Steel[];
      calculationType: CalculationType;
    },
  ) => {
    return (
      <ApplicationStateConsumer>
        {({ state: { selectedChart, selectedUnitSystem } }) => (
          <>
            <UglyWorkaroundWrapper>
              <PropertiesRow>
                <div style={{ flex: 1 }}>
                  <TranslatedInputLabel translationId="steel" />

                  <SteelSelector
                    selectedChart={selectedChart}
                    currentSteel={props.upgradeSteelState.upgradeSteel}
                    setCurrentSteel={steel => {
                      props.setUpgradeSteelStateInstant({
                        upgradeSteel: steel,
                      });
                    }}
                    steels={props.calculationSteels.filter(
                      steel => steel.hardnessHV >= props.currentSteelState.currentSteel.hardnessHV,
                    )}
                    includeCustomSteel={false}
                  />
                </div>
                <RightDropdownWrapper style={{ width: 90 }}>
                  {props.upgradeSteelState.upgradeSteel && (
                    <>
                      <TranslatedInputLabel translationId="hardness" />
                      <CustomHardnessInput
                        currentSteel={props.upgradeSteelState.upgradeSteel}
                        setCurrentSteel={() => {
                          // It's not possible to select custom steel in upgrade
                        }}
                      />
                    </>
                  )}
                </RightDropdownWrapper>
              </PropertiesRow>
              {props.upgradeSteelState.upgradeSteel ? (
                !isDuroxite(props.upgradeSteelState.upgradeSteel) ? (
                  <PropertiesRow>
                    <Column $size={6}>
                      <TranslatedInputLabel translationId="thickness" />

                      <LengthInput
                        value={props.upgradeSteelState.upgradeThickness}
                        onChange={upgradeThickness => {
                          props.setUpgradeSteelState({
                            upgradeThickness,
                          });
                        }}
                        maxValue={
                          selectedUnitSystem === 'imperial'
                            ? convertMillimetersToInches(getUpgradeThicknessMaxValue(props.calculationType))
                            : getUpgradeThicknessMaxValue(props.calculationType)
                        }
                        selectedUnitSystem={selectedUnitSystem}
                        minValue={props.currentSteelState.wornOutThickness + 0.001}
                        numberOfDecimals={3}
                        chartsToHighlight={[ChartType.Summary, ChartType.HeatMap]}
                      />
                    </Column>
                    <Column $size={6}>
                      <RightDropdownWrapper>
                        <TranslatedInputLabel translationId="serviceLife" />

                        <NumberInput
                          value={props.upgradeSteelState.upgradeServiceLife}
                          onChange={newValue => {
                            props.setUpgradeSteelState({
                              upgradeServiceLife: Number(newValue),
                            });
                          }}
                          suffix={props.intl.formatMessage({
                            id: props.currentSteelState.serviceLifeTimeUnit.name.toLowerCase(),
                          })}
                          chartsToHighlight={[ChartType.Summary, ChartType.HeatMap]}
                          numberOfDecimals={1}
                        />
                      </RightDropdownWrapper>
                    </Column>
                  </PropertiesRow>
                ) : (
                  <DoubleLayerSteelInput
                    intl={props.intl}
                    calculationType={props.calculationType}
                    selectedUnitSystem={selectedUnitSystem}
                    currentSteelState={props.currentSteelState}
                    upgradeSteelState={props.upgradeSteelState}
                    setUpgradeSteelState={props.setUpgradeSteelState}
                  />
                )
              ) : null}
            </UglyWorkaroundWrapper>
            <Row>
              <Column $size={12} $sizeSmall={6}>
                <DuroxiteIllustration
                  show={
                    (props.upgradeSteelState.upgradeSteel && props.upgradeSteelState.upgradeSteel.id === 11) || false
                  }
                />
              </Column>
            </Row>
          </>
        )}
      </ApplicationStateConsumer>
    );
  },
);
