import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ApplicationStateConsumer } from '../state/applicationState';
import { PropertiesRow, RightDropdownWrapper } from '../components/containers/PropertiesRow';
import { TranslatedInputLabel } from '../components/labels/Labels';
import { SteelSelector } from '../components/form/SteelSelector';
import { Row } from '../components/containers/Row';
import { Column } from '../components/containers/Column';
import { DuroxiteIllustration } from '../components/illustrations/duroxiteIllustration';
import { CustomHardnessInput } from '../components/form/CustomHardnessInput';
import { ChartType, CurrentSteelState, Steel, UpgradeSteelState } from '../types';
import { LengthInput } from '../components/form/LengthInput';
import { TimeUnitDropdown } from '../components/form/TimeUnitDropdown';
import { NumberInput } from '../components/form/NumberInput';
import { SlidingValues, ImpactValues, ErosionValues } from '../data/calculationValues';
import { convertMillimetersToInches } from '../utils/convertUnits/convertUnits';
import { UglyWorkaroundWrapper } from '../components/containers/UglyWorkarounWrapper';

export const CurrentSteelProperties = injectIntl(
  class CurrentSteelPropertiesClass extends React.Component<
    WrappedComponentProps & {
      currentSteelState: CurrentSteelState;
      upgradeSteelState: UpgradeSteelState;
      setCurrentSteelState: (currentSteelState: Partial<CurrentSteelState>) => void;
      setCurrentSteelStateInstant: (currentSteelState: Partial<CurrentSteelState>) => void;
      calculationSteels: Steel[];
      includeCustomSteel: boolean;
      calculationType: 'Sliding' | 'Impact' | 'Erosion';
    }
  > {
    render() {
      const {
        currentSteelState,
        setCurrentSteelState,
        setCurrentSteelStateInstant,
        calculationSteels,
        includeCustomSteel,
        calculationType,
        upgradeSteelState,
        intl,
      } = this.props;

      return (
        <ApplicationStateConsumer>
          {({ state: { selectedChart, useTimeUnit, selectedUnitSystem } }) => (
            <>
              <UglyWorkaroundWrapper>
                <PropertiesRow>
                  <div style={{ flex: 1, maxWidth: '66%' }}>
                    <TranslatedInputLabel translationId="steel" />

                    <SteelSelector
                      selectedChart={selectedChart}
                      currentSteel={currentSteelState.currentSteel}
                      setCurrentSteel={steel => {
                        setCurrentSteelStateInstant({
                          currentSteel: steel,
                        });
                      }}
                      steels={calculationSteels.filter(s => s.id !== 11)}
                      includeCustomSteel={includeCustomSteel}
                    />
                  </div>
                  <RightDropdownWrapper style={{ width: 90 }}>
                    <TranslatedInputLabel translationId="hardness" />
                    <CustomHardnessInput
                      currentSteel={currentSteelState.currentSteel}
                      setCurrentSteel={currentSteel => {
                        setCurrentSteelState({
                          currentSteel: currentSteel,
                        });
                      }}
                    />
                  </RightDropdownWrapper>
                </PropertiesRow>

                <PropertiesRow>
                  <Column $size={6}>
                    <TranslatedInputLabel translationId="thickness" />

                    <LengthInput
                      value={currentSteelState.currentThickness}
                      onChange={currentThickness =>
                        setCurrentSteelState({
                          currentThickness: currentThickness,
                        })
                      }
                      chartsToHighlight={[ChartType.Thickness, ChartType.Summary, ChartType.HeatMap]}
                      minValue={currentSteelState.wornOutThickness + 0.001}
                      maxValue={
                        selectedUnitSystem === 'imperial'
                          ? convertMillimetersToInches(
                              calculationType === 'Sliding'
                                ? SlidingValues.currentThickness.maxValue
                                : calculationType === 'Impact'
                                  ? ImpactValues.currentThickness.maxValue
                                  : calculationType === 'Erosion'
                                    ? ErosionValues.currentThickness.maxValue
                                    : 0,
                            )
                          : calculationType === 'Sliding'
                            ? SlidingValues.currentThickness.maxValue
                            : calculationType === 'Impact'
                              ? ImpactValues.currentThickness.maxValue
                              : calculationType === 'Erosion'
                                ? ErosionValues.currentThickness.maxValue
                                : 0
                      }
                      selectedUnitSystem={selectedUnitSystem}
                    />
                  </Column>
                  <Column $size={6}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="wornoutThickness" />

                      <LengthInput
                        value={currentSteelState.wornOutThickness}
                        onChange={wornOutThickness => {
                          setCurrentSteelState({
                            wornOutThickness: wornOutThickness,
                          });
                        }}
                        chartsToHighlight={[ChartType.Thickness, ChartType.Summary, ChartType.HeatMap]}
                        minValue={
                          selectedUnitSystem === 'imperial'
                            ? convertMillimetersToInches(
                                calculationType === 'Sliding'
                                  ? SlidingValues.thicknessWhenWornOut.minValue
                                  : calculationType === 'Impact'
                                    ? ImpactValues.thicknessWhenWornOut.minValue
                                    : calculationType === 'Erosion'
                                      ? ErosionValues.thicknessWhenWornOut.minValue
                                      : 0,
                              )
                            : calculationType === 'Sliding'
                              ? SlidingValues.thicknessWhenWornOut.minValue
                              : calculationType === 'Impact'
                                ? ImpactValues.thicknessWhenWornOut.minValue
                                : calculationType === 'Erosion'
                                  ? ErosionValues.thicknessWhenWornOut.minValue
                                  : 0
                        }
                        maxValue={
                          currentSteelState.currentThickness <
                          upgradeSteelState.upgradeThickness + (upgradeSteelState.upgradeBaseThickness || 0)
                            ? currentSteelState.currentThickness - 0.001
                            : upgradeSteelState.upgradeThickness + (upgradeSteelState.upgradeBaseThickness || 0) - 0.001
                        }
                        selectedUnitSystem={selectedUnitSystem}
                      />
                    </RightDropdownWrapper>
                  </Column>
                </PropertiesRow>
                <PropertiesRow>
                  <Column $size={6}>
                    <Column $size={12}>
                      <TranslatedInputLabel translationId="units" />
                    </Column>
                    <Column $size={12}>
                      <TimeUnitDropdown
                        value={currentSteelState.serviceLifeTimeUnit}
                        onChange={serviceLifeTimeUnit =>
                          setCurrentSteelStateInstant({
                            serviceLifeTimeUnit,
                          })
                        }
                        chartsToHighlight={[ChartType.Summary, (useTimeUnit && ChartType.ServiceLife) as ChartType]}
                      />
                    </Column>
                  </Column>
                  <Column $size={6}>
                    <RightDropdownWrapper>
                      <TranslatedInputLabel translationId="serviceLife" />

                      <NumberInput
                        value={currentSteelState.currentServiceLife}
                        suffix={intl.formatMessage({
                          id: currentSteelState.serviceLifeTimeUnit.name.toLowerCase(),
                        })}
                        onChange={newValue => {
                          setCurrentSteelState({
                            currentServiceLife: Number(newValue),
                          });
                        }}
                        chartsToHighlight={[
                          ChartType.Summary,
                          ChartType.HeatMap,
                          (useTimeUnit && ChartType.ServiceLife) as ChartType,
                        ].filter(Boolean)}
                        numberOfDecimals={1}
                      />
                    </RightDropdownWrapper>
                  </Column>
                </PropertiesRow>
              </UglyWorkaroundWrapper>
              <Row>
                <Column $size={12} $sizeSmall={6}>
                  <DuroxiteIllustration show={currentSteelState.currentSteel.id === 11} />
                </Column>
              </Row>
            </>
          )}
        </ApplicationStateConsumer>
      );
    }
  },
);
