// Fabric UI typography: https://developer.microsoft.com/en-us/fabric#/styles/typography

// Font sizes
export const fontSizeSU = '42px';
export const fontSizeXXL = '28px';
export const fontSizeXL = '21px';
export const fontSizeL = '17px';
export const fontSizeMPlus = '15px';
export const fontSizeM = '14px';
export const fontSizeSPlus = '13px';
export const fontSizeS = '12px';
export const fontSizeXS = '11px';
export const fontSizeMI = '10px';

// Font weights
export const fontWeightLight = 100;
export const fontWeightSemiLight = 300;
export const fontWeightRegular = 400;
export const fontWeightSemiBold = 600;
