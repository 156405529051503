import styled from 'styled-components';
import { fontSizeM } from '../../utils/constants/typography';
import { neutralSecondary, neutralLight, neutralLighterAlt } from '../../utils/constants/colors';

// Wrapper

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  margin-bottom: 13px;
  cursor: ${props => {
    return props.disabled ? 'default' : 'pointer';
  }};
`;

// Label

const Label = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 3px;
  font-size: ${fontSizeM};
  color: ${props => {
    return props.disabled ? '#d0d0d0' : neutralSecondary;
  }};
  user-select: none;
`;

// Checkmark container

type CheckmarkContainerProps = {
  checked: boolean;
  disabled: boolean;
};

const CheckmarkContainer = ({ checked, disabled }: CheckmarkContainerProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: 3,
        marginRight: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 3,
          backgroundColor: disabled ? neutralLight : neutralLight,
        }}
      >
        <div
          style={{
            display: 'block',
            position: 'relative',
            padding: 8,
            backgroundColor: disabled ? neutralLighterAlt : '#ffffff',
          }}
        >
          <div
            style={{
              opacity: !checked || disabled ? 0 : 1,
              position: 'absolute',
              borderBottom: '4px solid #000000',
              borderRight: '4px solid #000000',
              width: 5,
              height: 14,
              left: 7,
              top: -5,
              transform: 'rotate(45deg)',
            }}
          />
        </div>
      </div>
    </div>
  );
};

// Checkbox

type CheckboxProps = {
  label: string;
  checked: boolean;
  disabled: boolean;
  onClick: () => void;
  styles?: {
    wrapper?: React.CSSProperties;
    label?: React.CSSProperties;
  };
};

export const Checkbox = ({ label, checked, disabled = false, onClick, styles }: CheckboxProps) => {
  const { wrapper: wrapperStyle = undefined, label: labelStyle = undefined } = styles ? styles : {};

  return (
    <Wrapper disabled={disabled} onClick={onClick} style={wrapperStyle}>
      <CheckmarkContainer checked={checked} disabled={disabled} />
      <Label disabled={disabled} style={labelStyle}>
        {label}
      </Label>
    </Wrapper>
  );
};
