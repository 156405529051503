import { TippingPropertiesState } from '../../state/calculationModels';
import { TippingValues } from '../../data/calculationValues';
import { UnitSystemOption } from '../../types';
import { convertMillimetersToInches } from '../convertUnits/convertUnits';

export const validateTippingValues = (
  tippingProperties: TippingPropertiesState,
  selectedUnitSystem: UnitSystemOption,
) => {
  const minWorkingDaysPerYear = TippingValues.workingDaysPerYear.minValue;
  const maxWorkingDaysPerYear = TippingValues.workingDaysPerYear.maxValue;

  const minUnloadsPerDay = TippingValues.unloadsPerDay.minValue;
  const maxUnloadsPerDay = TippingValues.unloadsPerDay.maxValue;

  const minTipperLength =
    selectedUnitSystem === 'metric'
      ? TippingValues.tipperLength.minValue
      : convertMillimetersToInches(TippingValues.tipperLength.minValue);

  const maxTipperLength =
    selectedUnitSystem === 'metric'
      ? TippingValues.tipperLength.maxValue
      : convertMillimetersToInches(TippingValues.tipperLength.maxValue);

  const workingDaysPerYear = tippingProperties.workingDaysPerYear;

  const unloadPerDay = tippingProperties.unloadsPerDay;

  const minCurrentSteelThickness =
    selectedUnitSystem === 'metric'
      ? TippingValues.currentThickness.minValue
      : convertMillimetersToInches(TippingValues.currentThickness.minValue);
  const maxCurrentSteelThickness =
    selectedUnitSystem === 'metric'
      ? TippingValues.currentThickness.maxValue
      : convertMillimetersToInches(TippingValues.currentThickness.maxValue);

  const minUpgradeSteelThickness =
    selectedUnitSystem === 'metric'
      ? TippingValues.upgradeThickness.minValue
      : convertMillimetersToInches(TippingValues.upgradeThickness.minValue);
  const maxUpgradeSteelThickness =
    selectedUnitSystem === 'metric'
      ? TippingValues.upgradeThickness.maxValue
      : convertMillimetersToInches(TippingValues.upgradeThickness.maxValue);

  const currentSteelState = tippingProperties.currentSteelState;
  const upgradeSteelState = tippingProperties.upgradeSteelState;

  if (minWorkingDaysPerYear > workingDaysPerYear || maxWorkingDaysPerYear < workingDaysPerYear) {
    return false;
  }

  if (minUnloadsPerDay > unloadPerDay || maxUnloadsPerDay < unloadPerDay) {
    return false;
  }

  if (minTipperLength > tippingProperties.tipperLength || maxTipperLength < tippingProperties.tipperLength) {
    return false;
  }

  if (
    currentSteelState.currentThickness <= currentSteelState.wornOutThickness ||
    upgradeSteelState.upgradeThickness <= currentSteelState.wornOutThickness
  ) {
    return false;
  }

  if (
    currentSteelState.currentThickness < minCurrentSteelThickness ||
    currentSteelState.currentThickness > maxCurrentSteelThickness
  ) {
    return false;
  }

  if (upgradeSteelState.upgradeSteel !== undefined) {
    if (
      upgradeSteelState.upgradeThickness < minUpgradeSteelThickness ||
      upgradeSteelState.upgradeThickness > maxUpgradeSteelThickness
    ) {
      return false;
    }
  }

  return true;
};
