import { Row } from '../../components/containers/Row';
import { Column } from '../../components/containers/Column';
import { TranslatedInputLabel } from '../../components/labels/Labels';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { LengthInput } from '../../components/form/LengthInput';
import { PaddingWrapper } from '../../components/containers/PropertiesRow';
import { ChartType } from '../../types';
import { SharpnessDropdown } from '../../components/form/SharpnessDropdown';
import { AbrasiveInfoButton, InfoImage } from '../../components/abrasive/AbrasiveInfo';
import { ImpactValues } from '../../data/calculationValues';
import { NumberInput } from '../../components/form/NumberInput';
import AbrasiveImpactAngleImage from '../../images/abrasiveImpactAngle.jpg';

export const ImpactAbrasiveProperties = () => (
  <ApplicationStateConsumer>
    {({
      state: {
        impactProperties: { abrasiveProperties },
        selectedUnitSystem,
      },
      setImpactProperty,
      setImpactPropertyInstant,
    }) => (
      <>
        <Row>
          <Column $size={6} $sizeSmall={3}>
            <PaddingWrapper>
              <TranslatedInputLabel translationId="sharpness" />
              <SharpnessDropdown
                value={abrasiveProperties.sharpness}
                onChange={value =>
                  setImpactPropertyInstant({
                    abrasiveProperties: { sharpness: value },
                  })
                }
              />
            </PaddingWrapper>
          </Column>
          <Column $size={6} $sizeSmall={3}>
            <PaddingWrapper $paddingLeft={true}>
              <TranslatedInputLabel translationId="angle" />
              <Row>
                <Column $size={10}>
                  <NumberInput
                    value={abrasiveProperties.angle}
                    suffix="°"
                    onChange={angle => {
                      setImpactProperty({
                        abrasiveProperties: { angle: Number(angle) },
                      });
                    }}
                    chartsToHighlight={[ChartType.ServiceLife, ChartType.Thickness, ChartType.Summary]}
                    numberOfDecimals={1}
                    minValue={ImpactValues.angle.minValue}
                    maxValue={ImpactValues.angle.maxValue}
                  />
                </Column>
                <Column $size={2}>
                  <AbrasiveInfoButton>
                    <InfoImage src={AbrasiveImpactAngleImage} />
                  </AbrasiveInfoButton>
                </Column>
              </Row>
            </PaddingWrapper>
          </Column>
          <Column $size={6} $sizeSmall={3}>
            <PaddingWrapper $paddingLeftSmall={true}>
              <TranslatedInputLabel translationId="size" />

              <LengthInput
                value={abrasiveProperties.size}
                onChange={newValue => {
                  setImpactProperty({
                    abrasiveProperties: { size: newValue },
                  });
                }}
                chartsToHighlight={[ChartType.ServiceLife, ChartType.Thickness, ChartType.Summary]}
                minValue={ImpactValues.size.minValue}
                maxValue={ImpactValues.size.maxValue}
                selectedUnitSystem={selectedUnitSystem}
              />
            </PaddingWrapper>
          </Column>
        </Row>
      </>
    )}
  </ApplicationStateConsumer>
);
