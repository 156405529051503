import { Fade } from '../fade/Fade';
import duroxite from '../../images/duroxite.png';
import styled from 'styled-components';
import { smallSizeMaxWidth } from '../../utils/constants/layout';

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  max-height: 120px;
  margin-left: -16px;
  margin-right: -12px;

  @media (max-width: ${smallSizeMaxWidth}) {
    margin-left: -20px;
    margin-right: -6px;
  }
`;

type DuroxiteIllustrationProps = {
  show: boolean;
};

export const DuroxiteIllustration = ({ show }: DuroxiteIllustrationProps) => {
  return (
    <>
      <Fade in={show}>
        <ImageWrapper>
          <img style={{ width: '100%' }} src={duroxite} alt="Duroxite" />
        </ImageWrapper>
      </Fade>
    </>
  );
};
