import { Spinner, SpinnerSize } from '@fluentui/react';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding-top: 20rem;
`;

export const LoadingSpinner = () => {
  return (
    <SpinnerWrapper>
      <Spinner size={SpinnerSize.large} />
    </SpinnerWrapper>
  );
};
