import { SteelCard, AbrasiveCard } from '../../components/card/Card';
import { SelectAbrasive } from '../../components/abrasive/Abrasive';
import { ErosionAbrasiveProperties } from './ErosionProperties';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ApplicationStateConsumer } from '../../state/applicationState';
import { ChartCard, ChartColumn } from '../../components/chart/ChartCard';
import { PivotItem } from '@fluentui/react';
import { RelativeServiceLifeChart } from '../../components/chart/relative/RelativeServiceLifeChart';
import { ThicknessChart } from '../../components/chart/thickness/ThicknessChart';
import { calcErosionWareRateForSteel } from '../../calculations/impactCalculations';
import { Steel, Abrasive, ChartType, CalculationType } from '../../types';
import { CalculationPageWrapper, PropertiesColumn } from '../../components/containers/Container';
import { constructSteelArray, getTranslatedSteels } from '../../utils/steelHelpers';
import { UpgradeSteelProperties } from '../../components/UpgradeSteelProperties';
import { Summary } from '../../components/summary/Summary';
import { ErosionPropertiesState } from '../../state/calculationModels';
import { CurrentSteelProperties } from '../../components/CurrentSteelProperties';
import { ChartPivot } from '../../components/chart/ChartPivot';
import { getSelectedUnitText } from '../../utils/convertUnits/convertUnits';

export const Erosion = injectIntl(({ intl }: WrappedComponentProps) => {
  return (
    <ApplicationStateConsumer>
      {({
        state: {
          erosionSteels,
          erosionProperties,
          useTimeUnit,
          selectedUnitSystem,
          selectedChart,
          selectedSteelTab,
          selectedErosionAbrasive,
        },
        setErosionSteels,
        setErosionAbrasive,
        setSelectedSteelTab,
        setSelectedChart,
        setErosionProperty,
        setErosionPropertyInstant,
      }) => {
        const calculationSteels = getTranslatedSteels(
          intl,
          constructSteelArray(erosionSteels, erosionProperties.currentSteelState.currentSteel),
        );
        const { data, reference } = getErosionServiceLifeDataPoints(
          calculationSteels.filter(s => !s.hiddenInCharts),
          selectedErosionAbrasive,
          erosionProperties,
        );

        //Wear rate does not exist on Erosion so change to ServiceLife.
        if (selectedChart === ChartType.WearRate) {
          setSelectedChart(ChartType.ServiceLife);
        }

        return (
          <CalculationPageWrapper>
            <PropertiesColumn>
              <AbrasiveCard>
                <SelectAbrasive
                  selectedAbrasive={selectedErosionAbrasive}
                  setCalculationModeAbrasive={setErosionAbrasive}
                  abrasiveProperties={selectedErosionAbrasive && <ErosionAbrasiveProperties />}
                  calculationType={CalculationType.Erosion}
                />
              </AbrasiveCard>

              {selectedErosionAbrasive && (
                <SteelCard
                  calculationProperties={
                    <CurrentSteelProperties
                      upgradeSteelState={erosionProperties.upgradeSteelState}
                      currentSteelState={erosionProperties.currentSteelState}
                      setCurrentSteelState={currentSteelState =>
                        setErosionProperty({
                          currentSteelState,
                        })
                      }
                      setCurrentSteelStateInstant={currentSteelState =>
                        setErosionPropertyInstant({
                          currentSteelState,
                        })
                      }
                      calculationSteels={calculationSteels}
                      includeCustomSteel={true}
                      calculationType="Erosion"
                    />
                  }
                  upgradeSteelProperties={
                    <UpgradeSteelProperties
                      calculationType={CalculationType.Erosion}
                      upgradeSteelState={erosionProperties.upgradeSteelState}
                      setUpgradeSteelState={upgradeSteelState =>
                        setErosionProperty({
                          upgradeSteelState,
                        })
                      }
                      setUpgradeSteelStateInstant={upgradeSteelState =>
                        setErosionPropertyInstant({
                          upgradeSteelState,
                        })
                      }
                      currentSteelState={erosionProperties.currentSteelState}
                      calculationSteels={calculationSteels}
                    />
                  }
                  selectedSteelTab={selectedSteelTab}
                  setSelectedSteelTab={setSelectedSteelTab}
                />
              )}
            </PropertiesColumn>
            <ChartColumn>
              <ChartPivot
                displayDownloadDocX={selectedErosionAbrasive !== undefined}
                steels={calculationSteels}
                setCalculationSteels={(steels: Array<Steel>) => {
                  setErosionSteels(steels);
                }}
              >
                <PivotItem itemKey={ChartType.ServiceLife} headerText={intl.formatMessage({ id: 'serviceLife' })}>
                  <ChartCard>
                    <RelativeServiceLifeChart
                      steels={calculationSteels.filter(s => !s.hiddenInCharts)}
                      currentSteelState={erosionProperties.currentSteelState}
                      upgradeSteelState={erosionProperties.upgradeSteelState}
                      data={data}
                      reference={reference}
                      useTimeUnit={useTimeUnit}
                      setCurrentSteel={currentSteel => {
                        setErosionPropertyInstant({
                          currentSteelState: { currentSteel: currentSteel },
                        });
                      }}
                      selectedSteelTab={selectedSteelTab}
                      setUpgradeSteel={steel =>
                        setErosionPropertyInstant({
                          upgradeSteelState: { upgradeSteel: steel },
                        })
                      }
                      chartTitleSuffix={
                        erosionProperties.currentSteelState.currentThickness +
                        ' ' +
                        getSelectedUnitText(selectedUnitSystem, intl)
                      }
                    />
                  </ChartCard>
                </PivotItem>
                <PivotItem itemKey={ChartType.Thickness} headerText={intl.formatMessage({ id: 'thickness' })}>
                  <ChartCard>
                    <ThicknessChart
                      steels={calculationSteels.filter(s => !s.hiddenInCharts)}
                      currentSteelState={erosionProperties.currentSteelState}
                      upgradeSteelState={erosionProperties.upgradeSteelState}
                      data={data}
                      reference={reference}
                      selectedUnitSystem={selectedUnitSystem}
                      setCurrentSteel={(steel: Steel) => {
                        setErosionProperty({
                          currentSteelState: { currentSteel: steel },
                        });
                      }}
                      selectedSteelTab={selectedSteelTab}
                      setUpgradeSteel={steel =>
                        setErosionProperty({
                          upgradeSteelState: { upgradeSteel: steel },
                        })
                      }
                    />
                  </ChartCard>
                </PivotItem>
                <PivotItem itemKey={ChartType.Summary} headerText={intl.formatMessage({ id: 'summary' })}>
                  <ChartCard style={{ userSelect: 'auto' }}>
                    <Summary
                      currentSteelState={erosionProperties.currentSteelState}
                      upgradeSteelState={erosionProperties.upgradeSteelState}
                      selectedUnitSystem={selectedUnitSystem}
                    />
                  </ChartCard>
                </PivotItem>
              </ChartPivot>
            </ChartColumn>
          </CalculationPageWrapper>
        );
      }}
    </ApplicationStateConsumer>
  );
});

// Helpers

export const getErosionServiceLifeDataPoints = (
  steels: Array<Steel>,
  selectedAbrasive: Abrasive | undefined,
  erosionProperties: ErosionPropertiesState,
): { data: Array<number>; reference: number | null } => {
  if (selectedAbrasive === undefined || erosionProperties.currentSteelState.currentSteel === undefined) {
    return { data: [], reference: null };
  }

  const data = steels.map(steel =>
    calcErosionWareRateForSteel(selectedAbrasive, erosionProperties.abrasiveProperties, steel),
  );

  const reference = calcErosionWareRateForSteel(
    selectedAbrasive,
    erosionProperties.abrasiveProperties,
    erosionProperties.currentSteelState.currentSteel,
  );

  return { data, reference };
};
