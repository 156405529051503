import { smallSizeMaxWidth } from '../../utils/constants/layout';
import styled from 'styled-components';

/*
/* This is a fugly workaround... This was on the Card component earlier for some reason...
/* Responsiveness in cards should be refactored from the ground up and use Column size and sizeSmall instead.
*/
export const UglyWorkaroundWrapper = styled.div`
  @media (max-width: ${smallSizeMaxWidth}) {
    columns: 2; /* Yuck... */
  }
`;
