export type Language = {
  id: string;
  name: string;
};

export const starLanguageId = 'st';

export const languages: Array<Language> = [
  { id: 'en', name: 'English' },
  { id: 'de', name: 'German' },
  { id: 'fr', name: 'French' },
  { id: 'ru', name: 'Russian' },
  { id: 'zh', name: 'Chinese' },
  { id: 'es', name: 'Spanish' },
  { id: 'sv', name: 'Svenska' },
  { id: 'fi', name: 'Finnish' },
  { id: 'it', name: 'Italian' },
  { id: 'pl', name: 'Polish' },
  { id: 'pt', name: 'Portuguese' },
  //{ id: starLanguageId, name: "✶✶✶✶✶" },
];
